import * as colorTokens from 'tokens/colors';
import * as spacingTokens from 'tokens/spacing';
import * as typographyTokens from 'tokens/typography';

const tokens = {
  ...colorTokens,
  typography: {
    header: `
      font-size: ${typographyTokens.typographyHeaderFontSize}px;
      font-weight: ${typographyTokens.typographyHeaderFontWeight};
      line-height: ${typographyTokens.typographyHeaderLineHeight}px;
    `,
    subheaderLg: `
      font-size: ${typographyTokens.typographySubheaderLargeFontSize}px;
      font-weight: ${typographyTokens.typographySubheaderLargeFontWeight};
      line-height: ${typographyTokens.typographySubheaderLargeLineHeight}px;
    `,
    subheaderSm: `
      font-size: ${typographyTokens.typographySubheaderSmallFontSize}px;
      font-weight: ${typographyTokens.typographySubheaderSmallFontWeight};
      line-height: ${typographyTokens.typographySubheaderSmallLineHeight}px;
    `,
    body: `
      font-size: ${typographyTokens.typographyBodyFontSize}px;
      line-height: ${typographyTokens.typographyBodyLineHeight}px;
    `,
    label: `
      font-size: ${typographyTokens.typographyLabelFontSize}px;
      font-weight: ${typographyTokens.typographyLabelFontWeight};
      line-height: ${typographyTokens.typographyLabelLineHeight}px;
    `,
    note: `
      font-size: ${typographyTokens.typographyNoteFontSize}px;
      line-height: ${typographyTokens.typographyNoteLineHeight}px;
    `,
  },
  spacing: { ...spacingTokens },
};

// uncomment this once we're ready to add dark mode support. for now leave commented.
// if (
//   window.matchMedia &&
//   window.matchMedia('(prefers-color-scheme: dark)').matches
// ) {
//
// }

export default tokens;

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Groups2Icon from '@mui/icons-material/Groups2';
import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { KDSPageNames } from 'constants/index';
import {
  LocalOffer,
  QuestionAnswer,
  SupervisedUserCircleRounded,
} from '@material-ui/icons';
import {
  NavigationItem,
  NavigationItemLabel,
  NavigationSubItemLabel,
  NavigationWrapper,
  SubNavItem,
  SubNavItemWrapper,
} from './index.styles';
import { useAppSelector } from 'redux/hooks';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { AB_TESTING, AUDIENCE_MANAGER, CREATIVE_ROTATIONS, OFFER_WALL_MANAGER, ORDERING, PLACEMENTS, QUESTIONS, CUSTOM_AUDIENCES, QUICK_QUESTIONS } from '../../content';

const Navigation: React.FC = () => {
  const location = useLocation();
  const { url } = useRouteMatch();
  const [isQQToggle, setIsQQToggle] = useState(false);
  const [isOWMToggle, setIsOWMToggle] = useState(false);

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );
  const isAudienceLoading = audienceLoading.state === 'loading';

  const isOfferWallManagerOpen = location.pathname.includes(
    `${url}/offer-wall-manager`
  );
  const isQuestionManagerOpen = location.pathname.includes(
    `${url}/quick-questions-manager`
  );

  const isQuestionsActive =
    location.pathname === `${url}/${KDSPageNames.QUICKQUESTIONS}`;

  const isOrderingActive =
    location.pathname === `${url}/${KDSPageNames.QUICKQUESTIONS}/ordering`;

  const isOffersActive =
    location.pathname === `${url}/${KDSPageNames.OFFERWALLMANAGER}`;

  const isPlacementActive =
    location.pathname === `${url}/${KDSPageNames.OFFERWALLMANAGER}/preview`;

  const isRotationActive =
    location.pathname ===
    `${url}/${KDSPageNames.OFFERWALLMANAGER}/creative-rotations`;

  const isABTestActive =
    location.pathname === `${url}/${KDSPageNames.OFFERWALLMANAGER}/ab-tests`;

  return (
    <NavigationWrapper>
      <NavigationItem
        to={`${url}/${KDSPageNames.AUDIENCEMANAGER}`}
        onClick={() => {
          setIsOWMToggle(false);
          setIsQQToggle(false);
        }}
      >
        <SupervisedUserCircleRounded style={{ color: 'white' }} />
        <NavigationItemLabel>{AUDIENCE_MANAGER}</NavigationItemLabel>
      </NavigationItem>
      <NavigationItem
        to={`${url}/${KDSPageNames.CUSTOMAUDIENCES}`}
        onClick={() => {
          setIsOWMToggle(false);
          setIsQQToggle(false);
        }}
      >
        <Groups2Icon style={{ color: 'white' }} />
        <NavigationItemLabel>{CUSTOM_AUDIENCES}</NavigationItemLabel>
      </NavigationItem>
      <NavigationItem
        to={`${url}/${KDSPageNames.QUICKQUESTIONS}`}
        onClick={() => {
          setIsQQToggle(!isQQToggle);
          setIsOWMToggle(false);
        }}
      >
        <QuestionAnswer style={{ color: 'white' }} />
        <NavigationItemLabel>
          {QUICK_QUESTIONS}
          {isQuestionManagerOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </NavigationItemLabel>
      </NavigationItem>
      <Collapse in={isQQToggle} timeout="auto" unmountOnExit>
        <SubNavItemWrapper>
          <SubNavItem
            to={`${url}/${KDSPageNames.QUICKQUESTIONS}`}
            active={isQuestionsActive}
          >
            <NavigationSubItemLabel>{QUESTIONS}</NavigationSubItemLabel>
          </SubNavItem>
          <SubNavItem
            to={`${url}/${KDSPageNames.QUICKQUESTIONS}/ordering`}
            active={isOrderingActive}
          >
            <NavigationSubItemLabel>{ORDERING}</NavigationSubItemLabel>
          </SubNavItem>
        </SubNavItemWrapper>
      </Collapse>
      <NavigationItem
        to={`${url}/${KDSPageNames.OFFERWALLMANAGER}`}
        onClick={() => {
          setIsOWMToggle(!isOWMToggle);
          setIsQQToggle(false);
        }}
      >
        <LocalOffer style={{ color: 'white' }} />
        <NavigationItemLabel>
          {OFFER_WALL_MANAGER}
          {isOfferWallManagerOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </NavigationItemLabel>
      </NavigationItem>
      <Collapse in={isOWMToggle} timeout="auto" unmountOnExit>
        <SubNavItemWrapper>
          <SubNavItem
            to={`${url}/${KDSPageNames.OFFERWALLMANAGER}`}
            active={isOffersActive}
          >
            <NavigationSubItemLabel>Offers</NavigationSubItemLabel>
          </SubNavItem>
          <SubNavItem
            to={`${url}/${KDSPageNames.OFFERWALLMANAGER}/preview`}
            active={isPlacementActive}
          >
            <NavigationSubItemLabel>{PLACEMENTS}</NavigationSubItemLabel>
          </SubNavItem>
          <SubNavItem
            to={`${url}/${KDSPageNames.OFFERWALLMANAGER}/creative-rotations`}
            active={isRotationActive}
          >
            <NavigationSubItemLabel>{CREATIVE_ROTATIONS}</NavigationSubItemLabel>
          </SubNavItem>
          <SubNavItem
            to={`${url}/${KDSPageNames.OFFERWALLMANAGER}/ab-tests`}
            active={isABTestActive}
          >
            <NavigationSubItemLabel>{AB_TESTING}</NavigationSubItemLabel>
          </SubNavItem>
        </SubNavItemWrapper>
      </Collapse>
    </NavigationWrapper>
  );
};

export default Navigation;

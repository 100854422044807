import React, { useState } from 'react';
import _ from 'lodash';
import { Button, IconButton } from '@klover/attain-design-system';
import {
  saveSalesLiftStudy,
  setCurrentSalesLiftStudy,
  setDraftState,
  setHasChanged,
  setHasNotChanged,
  setSalesLiftDeleteDialogOpen,
  setSalesLiftExportDialogOpen,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router';

// Material UI Components
import ArrowBack from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

//components
import BootstrapTooltip from 'components/bootstrapTooltip';
import DraftStateSvg from '../../offerWallManager/draftStateIndicator';
import ExportDisabledButtonMessage from '../exportDisabledButtonMessage';
import SaveDisabledButtonMessage from '../saveDisabledButtonMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    draftStateSvgPadding: {
      paddingTop: '0.3em',
      paddingLeft: '1em',
    },
    exportSpinner: {
      width: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      margin: theme.spacing(0, 1),
    },
    backButton: {
      paddingRight: '1em',
    },
    topBarContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px',
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    topNameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    editButton: {
      marginLeft: `${theme.spacing(4)} !important`,
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const SalesLiftName = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [editMode, setEditMode] = useState(false);

  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  const originalSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.originalSalesLiftStudy
  );
  const salesLiftStudyLoading = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.salesLiftStudyLoading
  );
  const isInvalid = useAppSelector(
    (state) => state.salesLiftStudiesReducer.isInvalid
  );
  const hasChanged = useAppSelector(
    (state) => state.salesLiftStudiesReducer.hasChanged
  );

  const targetTransactionConditions =
    originalSalesLiftStudy &&
    originalSalesLiftStudy.dimensions &&
    originalSalesLiftStudy.dimensions.filter(
      (x) => x.category == 'targetTransactions'
    ).length &&
    originalSalesLiftStudy.dimensions.filter(
      (x) => x.category == 'targetTransactions'
    )[0].conditions &&
    originalSalesLiftStudy.dimensions
      .filter((x) => x.category == 'targetTransactions')[0]
      .conditions.filter((x) => x.qualifier)
      ? originalSalesLiftStudy.dimensions
          .filter((x) => x.category == 'targetTransactions')[0]
          .conditions.filter((x) => x.qualifier)
      : [];

  const currentTargetTransactionConditions =
    currentSalesLiftStudy &&
    currentSalesLiftStudy.dimensions &&
    currentSalesLiftStudy.dimensions.filter(
      (x) => x.category == 'targetTransactions'
    ).length &&
    currentSalesLiftStudy.dimensions.filter(
      (x) => x.category == 'targetTransactions'
    )[0].conditions &&
    currentSalesLiftStudy.dimensions
      .filter((x) => x.category == 'targetTransactions')[0]
      .conditions.filter((x) => x.qualifier)
      ? currentSalesLiftStudy.dimensions
          .filter((x) => x.category == 'targetTransactions')[0]
          .conditions.filter((x) => x.qualifier)
      : [];

  const salesLiftStudyExporting = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.salesLiftStudyExporting
  );

  const handleCancel = () => {
    if (originalSalesLiftStudy) {
      const updatedSalesLiftStudy = {
        ...currentSalesLiftStudy,
        name: originalSalesLiftStudy.name,
      };
      dispatch(setHasNotChanged({ keyName: 'name' }));
      dispatch(setCurrentSalesLiftStudy(updatedSalesLiftStudy));
      setEditMode(false);
    } else {
      const updatedSalesLift = {
        ...currentSalesLiftStudy,
        name: '',
      };
      dispatch(setCurrentSalesLiftStudy(updatedSalesLift));
    }
  };

  const handleEditName = () => {
    setEditMode(true);
  };

  const handleExport = () => {
    dispatch(setSalesLiftExportDialogOpen(true));
    dispatch(setDraftState({ open: false }));
  };

  const handleNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const updatedSalesLift = {
      ...currentSalesLiftStudy,
      name: e.target.value,
    };
    if (
      originalSalesLiftStudy &&
      updatedSalesLift &&
      originalSalesLiftStudy.name == updatedSalesLift.name
    ) {
      dispatch(setHasNotChanged({ keyName: 'name' }));
    } else {
      dispatch(setHasChanged({ keyName: 'name' }));
    }
    dispatch(setCurrentSalesLiftStudy(updatedSalesLift));
  };

  const handleSave = () => {
    if (originalSalesLiftStudy) {
      dispatch(saveSalesLiftStudy(currentSalesLiftStudy));
      setEditMode(false);
    } else {
      setEditMode(false);
    }
  };

  const handleDelete = () => {
    dispatch(setSalesLiftDeleteDialogOpen(true));
  };
  const doneLoading =
    salesLiftStudyLoading && salesLiftStudyLoading.state === 'done'
      ? true
      : false;
  const originalIsDisabled =
    !originalSalesLiftStudy?.name ||
    !originalSalesLiftStudy.startdate ||
    !originalSalesLiftStudy.enddate ||
    !originalSalesLiftStudy.attributionwindow;
  const currentIsDisabled =
    !currentSalesLiftStudy?.name ||
    !currentSalesLiftStudy.startdate ||
    !currentSalesLiftStudy.enddate ||
    !currentSalesLiftStudy.attributionwindow;
  const isSaveDisabled =
    !currentSalesLiftStudy?.name || (isInvalid && !_.isEmpty(isInvalid));
  const showDraftState =
    doneLoading &&
    originalSalesLiftStudy &&
    hasChanged &&
    !_.isEmpty(hasChanged)
      ? true
      : false;
  const isExportDisabled =
    originalIsDisabled ||
    currentIsDisabled ||
    salesLiftStudyExporting ||
    !targetTransactionConditions.length ||
    targetTransactionConditions.length > 3 ||
    showDraftState;
  const saveTitle =
    !currentSalesLiftStudy?.name || isSaveDisabled ? (
      <SaveDisabledButtonMessage />
    ) : (
      ''
    );
  const exportTitle = (
    <ExportDisabledButtonMessage
      targetTransactionConditions={currentTargetTransactionConditions}
      hasSaved={showDraftState}
    />
  );
  const handleNavigateToManager = (e) => {
    const id = currentSalesLiftStudy?.offers[0]?.offergroupid || 'all';
    if (showDraftState) {
      dispatch(
        setDraftState({
          open: true,
          path: `/main/sales-lift-studies?groupId=${id}`,
        })
      );
    }

    history.push(`/main/sales-lift-studies?groupId=${id || 'all'}`);
  };
  return (
    <>
      <ThemeProvider theme={inputs}>
        <div className={classes.topBarContainer}>
          <div className={classes.backButton}>
            <IconButton onClick={handleNavigateToManager} color="primary">
              <ArrowBack />
            </IconButton>
          </div>
          <div>
            {editMode ? (
              <div className={classes.topNameContainer}>
                <div>
                  <TextField
                    id="offer-name"
                    label="Sales Lift Name"
                    onChange={handleNameChange}
                    value={currentSalesLiftStudy?.name}
                    variant="outlined"
                    style={{ marginRight: 8 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="default"
                            onClick={handleSave}
                            size="small"
                            disabled={currentIsDisabled}
                          >
                            <SalesLiftNameSaveIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <IconButton onClick={handleCancel}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className={classes.topNameContainer}>
                <div style={{ minWidth: 200 }}>
                  <Typography component="h2" variant="h6">
                    {currentSalesLiftStudy?.name}
                  </Typography>
                </div>
                <div>
                  <IconButton
                    className={classes.editButton}
                    color="default"
                    onClick={handleEditName}
                  >
                    <CreateIcon />
                  </IconButton>
                </div>
                <IconButton color="default" onClick={handleDelete}>
                  <DeleteForeverIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div>
            <BootstrapTooltip title={saveTitle}>
              <span>
                <Button
                  className={classes.button}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={isSaveDisabled}
                >
                  Save
                </Button>
              </span>
            </BootstrapTooltip>
            <BootstrapTooltip title={exportTitle}>
              <span>
                <Button
                  className={classes.button}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleExport}
                  disabled={isExportDisabled}
                >
                  {!salesLiftStudyExporting ? (
                    'Export'
                  ) : (
                    <div className={classes.exportSpinner}>
                      <CircularProgress size={24} color={'inherit'} />
                    </div>
                  )}
                </Button>
              </span>
            </BootstrapTooltip>
          </div>
          <div className={classes.draftStateSvgPadding}>
            {showDraftState ? <DraftStateSvg /> : null}
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

const SalesLiftNameSaveIcon = () => {
  return (
    <>{false ? <CircularProgress color="primary" size={20} /> : <SaveIcon />}</>
  );
};

export default SalesLiftName;

import React from 'react';
import { Typography } from '@material-ui/core';

const Copyright: React.FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © {new Date().getFullYear()} Klover Holdings, Inc. All rights reserved
    </Typography>
  );
};

export default Copyright;

import React from 'react';
import { Button, IconButton } from '@klover/attain-design-system';
import { deleteSalesLiftStudy, setSalesLiftDeleteDialogOpen } from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    divider: {
      borderLeft: '0.2em solid #ccc',
    },
    inputs: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
  },
});

const SalesLiftDeleteDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const open = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.salesLiftDeleteDialogOpen
  );
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );

  const dispatchClose = () => {
    dispatch(setSalesLiftDeleteDialogOpen(false));
  };

  const handleDelete = () => {
    dispatch(deleteSalesLiftStudy(currentSalesLiftStudy));
    dispatch(setSalesLiftDeleteDialogOpen(false));
    history.push('/main/sales-lift-studies/');
  };

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          onClose={handleClose}
          open={open}
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.title}>
                  Delete <strong>{currentSalesLiftStudy?.name}</strong>
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <DialogContent style={{ padding: '24px 32px 0px 32px' }}>
            <DialogContentText
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              Are you sure you want to delete{' '}
              <strong>{currentSalesLiftStudy?.name}</strong>
            </DialogContentText>
          </DialogContent>
          <Grid container spacing={0} style={{ marginTop: 50 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Box m={1}>
            <DialogActions>
              <Button color="primary" onClick={handleClose} variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                disableElevation
                onClick={handleDelete}
                variant="contained"
              >
                DELETE
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default SalesLiftDeleteDialog;

import Field from 'components/Field';
import React, { useContext, useState } from 'react';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Button } from '@klover/attain-design-system';
import { CircularProgress, Grid } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { OrderQuestionsContext } from '../orderQuestions';
import { Question } from 'interfaces/quickQuestionsManager';
import * as Styled from './index.styles';

type Props = {
  allQuestions: Question[];
  questionsLoading: boolean;
};

const FilterPanel = ({ allQuestions, questionsLoading }: Props) => {
  const { setQuestions, setDragDisabled } = useContext(OrderQuestionsContext);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      idSearch: '',
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        if (values.idSearch) {
          const axiosInstance = await getAuthorizedAxiosInstance();
          if (axiosInstance) {
            const res = await axiosInstance.post(
              `${API_BASE_ADDRESS}/questions/target/${values.idSearch}`,
              allQuestions
            );
            setDragDisabled(true);
            setQuestions(res.data);
            setIsLoading(false);
          }
        } else {
          setQuestions(allQuestions);
          setDragDisabled(false);
          setIsLoading(false);
        }
      } catch (e) {
        setQuestions(allQuestions);
        setDragDisabled(false);
        setIsLoading(false);
      }
    },
  });
  return (
    <Styled.InnerContainer>
      <FormikProvider value={formik}>
        <Grid container direction="row" columnGap={2}>
          <Grid item>
            <Field
              type="text"
              name="idSearch"
              placeholder="Userid"
              value={formik.values.idSearch}
              onChange={formik.handleChange}
              style={{ height: 44, width: 415 }}
            ></Field>
          </Grid>
          <Grid item>
            <Grid container columnGap={2}>
              <Grid item>
                <Button
                  type="submit"
                  onClick={formik.handleSubmit}
                  variant="contained"
                  disabled={questionsLoading || isLoading}
                >
                  {isLoading ? <CircularProgress size={20} /> : 'Filter'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    setQuestions(allQuestions);
                    setDragDisabled(false);
                    setIsLoading(false);
                    formik.resetForm();
                  }}
                  variant="outlined"
                  disabled={questionsLoading}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </Styled.InnerContainer>
  );
};

export default FilterPanel;

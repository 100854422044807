import nanoid from 'utils/nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';

// Material UI Components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import NotificationRow from 'components/Notifications/NotificationRow';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOptionsBar from 'components/Notifications/NotificationsOptionsBar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Tooltip } from '@mui/material';
import { IconButton } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { DefaultPaginationValues } from 'constants/index';
import { Notification } from 'interfaces/notifications';
import { fetchNotifications, updateNotifications } from './slice';
import { useUser } from 'reactfire';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      alignItems: 'center',
      display: 'flex',
      height: '80px',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      width: '100%',
    },
    notificationHeaders: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    table: {
      width: '100%',
      height: '100vh',
      borderRadius: 0,
    },
    tableHead: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    title: {
      fontSize: '1.3rem',
      fontWeight: 400,
      display: 'flex',
      justifyContent: 'center',
    },
    noNotificationsMessage: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6,
      textAlign: 'center',
    },
    noNotificationContainer: {
      justifyContent: 'center',
      color: '#202020',
      padding: '20em',
    },
    notificationsIconContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

const Notifications = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentNotifications = useAppSelector((state) => {
    if (
      state.notificationsReducer &&
      state.notificationsReducer.currentNotifications
    ) {
      return state.notificationsReducer.currentNotifications;
    }
    return [];
  });
  const user = useUser();
  const userEmail = user?.data?.email ? user.data.email : '';
  const [filterRead, setFilterRead] = useState(false);
  const [filterEmail, setFilterEmail] = useState(false);

  const handleLoadMoreClick = () => {
    dispatch(
      fetchNotifications({
        currentNotifications: currentNotifications,
        filterRead: filterRead,
        filterEmail: filterEmail,
        userEmail: userEmail,
        limit: DefaultPaginationValues.limit,
        loadMore: true,
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchNotifications({
        currentNotifications: currentNotifications,
        filterRead: filterRead,
        filterEmail: filterEmail,
        userEmail: userEmail,
        limit: DefaultPaginationValues.limit,
        loadMore: false,
      })
    );
    return () => {
      dispatch(updateNotifications(userEmail));
    };
  }, [filterRead, filterEmail]);

  return (
    <div>
      <div className={classes.header}>
        <h1 className={classes.title}>
          <div>Notifications</div>
        </h1>
      </div>
      <NotificationsOptionsBar
        totalNotifications={currentNotifications.length}
        filterRead={filterRead}
        setFilterRead={setFilterRead}
        filterEmail={filterEmail}
        setFilterEmail={setFilterEmail}
      />
      <Table aria-label="simple table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.notificationHeaders}>
              <Tooltip
                title="Timestamps are in your local timezone"
                placement="bottom"
                arrow
              >
                <span>Date</span>
              </Tooltip>
            </TableCell>

            <TableCell className={classes.notificationHeaders}>
              Message
            </TableCell>
            <TableCell className={classes.notificationHeaders}>User</TableCell>
          </TableRow>
        </TableHead>
        {currentNotifications?.length ? (
          <TableBody>
            {currentNotifications.map((notification: Notification) => (
              <NotificationRow notification={notification} key={nanoid()} />
            ))}
          </TableBody>
        ) : null}
      </Table>
      {currentNotifications?.length ? (
        <Box sx={{ p: 2, textAlign: 'center', width: '100%' }}>
          <Tooltip title="Load Older">
            <IconButton size="large" onClick={handleLoadMoreClick}>
              <ExpandMoreIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <div className={classes.noNotificationContainer}>
          <div className={classes.notificationsIconContainer}>
            <NotificationsIcon />
          </div>
          <div className={classes.noNotificationsMessage}>
            There are no notifications.
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;

export const LOGIN = 'LOGIN';
export const LOGIN_PROGRESS = 'LOGIN_PROGRESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_PROGRESS = 'LOGOUT_PROGRESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_PROGRESS = 'RESET_PASSWORD_PROGRESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const RESET = 'RESET';
export const COMMON_ERROR = 'COMMON_ERROR';

import React from 'react';
import { UploadStates } from 'constants/index';
import * as Styled from './index.styles';

// Material UI Components
import FileOpenIcon from '@mui/icons-material/FileOpen';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 450,
    },
  })
);

type DeleteProps = {
  setOpenDialog: (value: boolean) => void;
  zipCount: number | undefined;
  zipStatus: UploadStates | undefined;
};
const DeleteZipsType = (props: DeleteProps) => {
  const { setOpenDialog, zipCount, zipStatus } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      wrap="nowrap"
      className={classes.formControl}
    >
      <Grid item>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <FileOpenIcon color="disabled" />
          </Grid>
          <Typography>
            {zipCount ? zipCount.toLocaleString() : 0}
            {' Zip Codes'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Styled.StyledButton
          onClick={() => {
            setOpenDialog(true);
          }}
          disabled={zipStatus !== UploadStates.DONE}
        >
          Delete Zips
        </Styled.StyledButton>
      </Grid>
    </Grid>
  );
};

export default DeleteZipsType;

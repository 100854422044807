import styled from 'styled-components';
import tokens from 'tokens';
import { theme } from '@klover/attain-design-system';

export const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const TableBodyRow = styled.div`
  font-family: Calibre-Regular, sans-serif;
  font-size: 16px;
  border: 1px solid lightgray;
  border-radius: 10px;
  &:hover {
    color: ${(props) => (props.$disableHover ? '' : tokens.colorButtonPrimary)};
  }
`;

export const MainBodyWithSpacing = styled.div`
  margin-bottom: ${tokens.spacing.lg};
`;

export const LoadingWrapper = styled.div`
  ${theme.mixins.card};
  overflow: hidden;
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

export const LoadingBody = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
`;

export const TableCell = styled.td`
  padding: 15px 0px 15px 5px;
  display: flex;

  a {
    color: ${tokens.colorBrandprimary};
    font-weight: bold;
    min-width: 0;
    padding: 0;
  }
`;

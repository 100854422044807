import React from 'react';

import { Button, IconButton } from '@klover/attain-design-system';
import { deleteZipCodes } from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import tokens from 'tokens';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

interface Props {
  isOpen: boolean;
  setOpenDialog: any;
  user: string;
}

const DeleteZipsDialog = ({ isOpen, setOpenDialog, user }: Props) => {
  const dispatch = useAppDispatch();
  const zipCount = useAppSelector(
    (state) => state.offerWallReducer.currentOffer?.zipCount
  );
  const offerId = useAppSelector(
    (state) => state.offerWallReducer.currentOffer?.offerid
  );

  const handleDeleteZips = () => {
    const data = { offerId: offerId, user };
    dispatch(deleteZipCodes(data));
    setOpenDialog(false);
  };

  return (
    <ThemeProvider theme={dialog}>
      <Styled.StyledDialog fullWidth={true} open={isOpen}>
        <Box m={1}>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Styled.StyledTypographyTitle>
                Delete Zip Codes
              </Styled.StyledTypographyTitle>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={2}>
              <IconButton onClick={() => setOpenDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Styled.StyledBodyDialogContent>
          <DialogContentText>
            Are you sure you want to permanently delete{' '}
            <Styled.StyledBoldSpan>
              {zipCount?.toLocaleString()}
            </Styled.StyledBoldSpan>{' '}
            zip codes?
          </DialogContentText>
        </Styled.StyledBodyDialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        {/* Actions */}
        <Box m={1}>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setOpenDialog(false)}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disableElevation
              onClick={handleDeleteZips}
              variant="contained"
            >
              Delete Zips
            </Button>
          </DialogActions>
        </Box>
      </Styled.StyledDialog>
    </ThemeProvider>
  );
};

export default DeleteZipsDialog;

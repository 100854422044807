import { LoadingProps } from 'interfaces/ui';
import { LoadingStates } from 'constants/index';
import { NotificationOptions } from 'interfaces/notifications';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NotificationState {
  currentNotifications?: any;
  originalNotifications?: any;
  ui: {
    notificationLoading: LoadingProps;
    notifications: Array<LoadingProps>;
    notificationSaving: LoadingProps;
  };
}

const initialState: NotificationState = {
  ui: {
    notificationLoading: { state: LoadingStates.IDLE },
    notifications: [],
    notificationSaving: { state: LoadingStates.IDLE },
  },
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.notifications.push(action.payload);
    },
    fetchNotifications: (state, action: PayloadAction<NotificationOptions>) => {
      // Skeleton
    },
    setNotificationsLoadingState: (
      state,
      action: PayloadAction<LoadingProps>
    ) => {
      state.ui.notificationLoading = action.payload;
    },
    setNotificationSavingState: (
      state,
      action: PayloadAction<LoadingProps>
    ) => {
      state.ui.notificationSaving = action.payload;
    },
    updateCurrentNotifications: (state, action) => {
      state.currentNotifications = action.payload;
    },
    updateNotifications: (state, action: PayloadAction<string>) => {
      // Skeleton
    },
    updateCurrentNotification: () => {
      // Skeleton
    },
    deleteNotification: (state, action: PayloadAction<string>) => {
      // Skeleton
    },
  },
});

export const {
  addNotification,
  fetchNotifications,
  setNotificationsLoadingState,
  setNotificationSavingState,
  updateCurrentNotifications,
  updateNotifications,
  deleteNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;

import ABTest from '../../assets/images/ab-test.svg';

import Field from 'components/Field';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Button, IconButton } from '@klover/attain-design-system';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import Link from 'components/Link';
import {
  Offer,
  OfferGroup,
  ToggleGroupNameDialogAction,
  fetchGroup,
  fetchGroups,
  fetchOffer,
  loadOriginalOffer,
  searchOffers,
  setGroupDeletingState,
  setGroupSavingState,
  toggleGroupNameDialog,
  updateCurrentGroup,
  updateCurrentOffer,
} from './slice';
import { SelectAutoCompleteGroup } from '../customAudiences/index.styles';
import { UploadStates } from 'constants/index';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';
import { CaretDown, Plus } from '@phosphor-icons/react';
import TextFieldSearch from 'components/TextFieldSearch/TextFieldSearch';
import { MenuItem, Select } from '@klover/attain-design-system';
import DataTable from 'components/DataTable/DataTable';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { LocalOfferRounded } from '@material-ui/icons';
import { RowingOutlined } from '@mui/icons-material';
import CapFullFilledIcon from '../../assets/images/trophy.png';
import CapInProgressIcon from '@mui/icons-material/QueryStats';

import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Page Components
import BootstrapTooltip from 'components/bootstrapTooltip';
import GroupDeleteDialog from './groupDeleteDialog';
import GroupName from './groupName';
import GroupNameDialog from './groupNameDialog';
import Header from 'components/Header';
import Option from 'components/Option';
import Table from 'components/Table';
import _ from 'lodash';
import allOptions from './offerOptions';
import axios from 'axios';
import tokens from 'tokens';
import { OfferWallContext } from 'context';
import { TimeRangeofConversionLabel } from './offerOptions/conversionCap';
import {
  CREATE_OFFER,
  CREATE_ROTATION,
  OFFER_STATUS,
  OFFER_WALL_MANAGER,
  OFFER_WALL_MANAGER_COLUMN_TITLES,
  SEARCH_OFFERS,
  SELECT_OFFER_GROUP,
} from '../../content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    offerActive: {
      backgroundColor: tokens.colorMessage,
      color: tokens.colorDefault,
      float: 'left',
      height: 24,
      width: 24,
    },
    offerInactive: {
      backgroundColor: 'transparent',
      float: 'left',
      height: 24,
      width: 24,
    },
    offerIcon: {
      height: 18,
      width: 18,
    },
    offerNameButton: {
      flexWrap: 'wrap',
      marginLeft: 8,
    },
    placementLabel: {
      color: 'grey',
    },
    createGroup: {
      backgroundColor: tokens.colorButtonLight,
      bottom: 0,
      height: 68,
      padding: 16,
      position: 'fixed',
      width: 248,
    },
    divider: {
      height: 2,
    },
    groupList: {
      marginBottom: 'auto',
      maxHeight: '500px',
      minHeight: '400px',
      overflow: 'scroll',
    },
    header: {
      display: 'flex',
      height: '80px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '100%',
    },
    noOffers: {
      minHeight: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    searchOffer: {
      margin: 16,
    },
    searchGroup: {
      margin: 8,
      marginTop: 16,
    },
    verticalDivider: {
      borderRight: '2px solid rgb(232 232 232)',
    },
    conversionValue: {
      display: 'flex',
      textAlign: 'end',
      marginLeft: '10px',
      alignItems: 'center',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,
        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const OfferWallManager = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { setShowCreativeRotation } = React.useContext(OfferWallContext);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const [allGroups, setAllGroups] = React.useState(false);
  const [groupId, setGroupId] = React.useState(
    searchParams.get('groupId') || 'all'
  );
  const [oprations, setOprations] = useState({
    page: 0,
    search: '',
    rowsPerPage: 25,
    sortBy: 'updatedat',
    sortOrder: 'DESC',
    isActive: true,
  });

  const offerWallReducer = useAppSelector((state) => {
    return state.offerWallReducer;
  });

  const openGroupDeleteDialog = useAppSelector(
    (state) => state.offerWallReducer.ui.openGroupDeleteDialog
  );

  const openGroupNameDialog = useAppSelector(
    (state) => state.offerWallReducer.ui.openGroupNameDialog
  );

  const { currentGroup } = offerWallReducer;

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  const stableOperations = useMemo(
    () => oprations,
    [JSON.stringify(oprations)]
  );

  useEffect(() => {
    dispatch(searchOffers({ ...stableOperations, search, groupId }));
  }, [stableOperations]);

  useEffect(() => {
    if (openGroupNameDialog) return;
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('groupId');
    const currGroup = offerWallReducer.entities.groups.byId[id];
    if (currGroup) {
      dispatch(updateCurrentGroup(currGroup));
      setGroupId(currGroup.id);
    } else {
      setGroupId('all');
      setAllGroups(true);
    }
  }, [window.location.search, openGroupNameDialog]);

  useEffect(() => {
    if (groupId && groupId != 'all') {
      const currentGroup = offerWallReducer.entities.groups.byId[groupId];
      if (currentGroup) {
        setGroupId(currentGroup.id);
        setAllGroups(false);
      }
    } else {
      setAllGroups(true);
      dispatch(updateCurrentGroup(undefined));
    }

    updateURL(groupId);
  }, [groupId]);

  const offers = useAppSelector((state) => {
    return state.offerWallReducer.entities.offers.allIds.map((id) => {
      return state.offerWallReducer.entities.offers.byId[id];
    });
  });

  const groups = useAppSelector((state) => {
    return state.offerWallReducer.entities.groups.allIds
      .map((id) => state.offerWallReducer.entities.groups.byId[id])
      .filter((group) => {
        if (name !== '') {
          return group.name.toLowerCase().includes(name);
        } else {
          return true;
        }
      });
  });

  const groupLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.groupLoading
  );

  const groupsLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.groupsLoading
  );

  const addOfferHandler = () => {
    const newOffer = {
      label: '',
      offername: '',
      successurl: [null],
      onofferseenurltofire: [null],
      actionforpoints: 'ACTION_CONV',
      placementid: 1,
      devices: 'ALL_DEVICES',
      zip_upload_status: UploadStates.DONE,
      zipCount: 0,
    };
    dispatch(updateCurrentOffer(newOffer));
    dispatch(loadOriginalOffer(null));
  };

  const handleCreateGroup = () => {
    const actionProps: ToggleGroupNameDialogAction = {
      open: true,
    };
    dispatch(toggleGroupNameDialog(actionProps));
  };

  const updateURL = (groupId) => {
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?groupId=${groupId}`
    );
  };

  const handleShowGroup = (id: number) => {
    setGroupId(id);
    setAllGroups(id ? false : true);
    setOprations({
      ...oprations,
      page: 0,
      groupId: id,
    });
  };

  const offerGroupsOptions = useMemo(() => {
    if (groups) {
      const data = [
        { label: 'All Groups', id: 'all' },
        ...groups
          ?.sort((a, b) => b.updated_at - a.updated_at)
          ?.map((a) => {
            return { label: a.name, id: `${a.id}` };
          }),
      ];
      return data;
    }
    return [];
  }, [groups]);

  const isOffersLoading = groupLoading.state === 'loading';
  const isGroupsLoading = groupsLoading.state === 'loading';

  const handleChangePage = (newPage: number) => {
    setOprations({
      ...oprations,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (value: number) => {
    const newPageSize = parseInt(value, 10);
    setOprations({
      ...oprations,
      page: 0,
      rowsPerPage: newPageSize,
    });
  };

  const handleOnSortChange = (sortInfo) => {
    let sortOrder = 'DESC';
    if (oprations.sortBy === sortInfo.column) {
      sortOrder = oprations.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }

    setOprations({
      ...oprations,
      sortBy: sortInfo.column,
      sortOrder: sortOrder,
      page: 0,
    });
  };

  const handleOnStatusFilterChange = (e: any) => {
    if (e.target.value !== 'all') {
      setOprations({
        ...oprations,
        page: 0,
        isActive: e.target.value === 'active' ? true : false,
      });
    } else {
      const updatedOperations = oprations;
      delete updatedOperations.isActive;

      setOprations({
        ...updatedOperations,
        page: 0,
      });
    }
  };

  const debounceFn = useCallback(
    _debounce((value) => {
      setOprations({
        ...oprations,
        page: 0,
        search: value,
      });
    }, 500),
    [oprations]
  );

  return (
    <>
      <Box className={classes.root}>
        <Header
          title={OFFER_WALL_MANAGER}
          centerBorder={true}
          center={!allGroups ? currentGroup && <GroupName /> : null}
          action={
            <Styled.ButtonSpacing>
              {currentGroup && !allGroups ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push(`/main/offer-wall-manager/editor`);
                    addOfferHandler();
                  }}
                  startIcon={<Plus />}
                >
                  {CREATE_OFFER}
                </Button>
              ) : null}
              <Button
                variant="contained"
                onClick={() => setShowCreativeRotation(true)}
                startIcon={<Plus />}
              >
                {CREATE_ROTATION}
              </Button>
            </Styled.ButtonSpacing>
          }
        />

        <Box height="100%" padding={2}>
          <Styled.SearchArea>
            <>
              <ThemeProvider theme={inputs}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateGroup}
                  startIcon={<Plus />}
                  style={{ fontSize: '16px' }}
                >
                  Create Group
                </Button>
              </ThemeProvider>

              <Formik>
                {() => (
                  <Styled.Form onSubmit={(e) => e.preventDefault()}>
                    <Field
                      name="search"
                      placeholder={SEARCH_OFFERS}
                      value={search}
                      style={{ height: 44, width: 415 }}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        debounceFn(e.target.value);
                      }}
                      disabled={isGroupsLoading}
                    />

                  </Styled.Form>
                )}
              </Formik>

              <SelectAutoCompleteGroup
                disablePortal
                id="select-offer-group"
                options={offerGroupsOptions}
                popupIcon={<CaretDown />}
                disabled={isGroupsLoading}
                value={
                  offerGroupsOptions.find((offer) => offer.id == groupId)
                    ?.label || ''
                }
                renderInput={(params) => (
                  <TextFieldSearch
                    {...params}
                    placeholder={SELECT_OFFER_GROUP}
                    variant="outlined"
                  />
                )}
                onChange={(e, newValue) => {
                  if (newValue?.id) {
                    handleShowGroup(newValue.id);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )}
              />

              <Select
                name="selectField"
                id="questionsFilter"
                disabled={isGroupsLoading}
                value={
                  oprations?.isActive === true
                    ? 'active'
                    : oprations?.isActive === false
                      ? 'inactive'
                      : 'all'
                }
                onChange={(e) => {
                  handleOnStatusFilterChange(e);
                }}
                style={{ width: 175, marginLeft: 16 }}
              >
                <MenuItem key="all" value="all">
                  {OFFER_STATUS.ACTIVE_INACTIVE}
                </MenuItem>
                <MenuItem key="active" value="active">
                  {OFFER_STATUS.ACTIVE}
                </MenuItem>
                <MenuItem key="inactive" value="inactive">
                  {OFFER_STATUS.INACTIVE}
                </MenuItem>
              </Select>
            </>
          </Styled.SearchArea>
          <OfferTable
            offers={offers}
            selectedGroupId={groupId}
            groups={groups}
            handleShowGroup={handleShowGroup}
            oprations={oprations}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleOnSortChange={handleOnSortChange}
            isOffersLoading={isOffersLoading || isGroupsLoading}
          />
        </Box>
      </Box>

      <GroupDeleteDialog open={openGroupDeleteDialog} />
      <GroupNameDialog open={openGroupNameDialog} />
    </>
  );
};

interface Props {
  offers: Offer[];
  groups: any;
  handleShowGroup: any;
  selectedGroupId: any;
  oprations: {
    page: number;
    rowsPerPage: number;
    isActive?: string;
    sortBy: string;
    sortOrder: string;
  };
  isOffersLoading: boolean;
  handleChangePage: () => void;
  handleChangeRowsPerPage: () => void;
  handleOnSortChange: () => void;
}

const OfferTable = ({
  offers,
  selectedGroupId,
  groups,
  handleShowGroup,
  oprations,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOnSortChange,
  isOffersLoading,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [abTests, setAbTests] = React.useState([]);
  const { page, rowsPerPage, sortBy, sortOrder } = oprations;

  const data = React.useMemo(() => offers && offers.filter((x) => x), [offers]);

  const states = useAppSelector((state) => {
    return state.offerWallReducer.ui.states;
  });

  const handleShowOffer = (offer: Offer) => {
    dispatch(setGroupDeletingState({ state: 'idle' }));
    dispatch(setGroupSavingState({ state: 'idle' }));
    dispatch(fetchOffer(offer));
  };

  const currentDate = Date.now();

  useEffect(() => {
    axios
      .get(`${API_BASE_ADDRESS}/offer-manager/offers?list=true`)
      .then((res) => {
        setAbTests(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const offerIDWithAbTests: string[] = [];
  const abTestObject = {};

  abTests.map((offer) => {
    if (offer.abtests) {
      offerIDWithAbTests.push(offer.offerid);
      abTestObject[offer.offerid] = offer.abtests[0].label;
    }
  });

  const count =
    data.length > 0
      ? typeof data[0].totalCount === 'string'
        ? Number(data[0].totalCount)
        : data[0].totalCount
      : 1;

  const columns = [
    {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.GROUP,
      column: 'offergroupid',
      isSorted: sortBy === 'offergroupid',
      sortOrder: sortOrder,
      width: '200px',
    },
    {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.OFFER_LABEL,
      column: 'label',
      isSorted: sortBy === 'label',
      sortOrder: sortOrder,
      width: '350px',
    },
    {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.OFFER_ID,
      column: 'offerid',
      isSorted: sortBy === 'offerid',
      sortOrder: sortOrder,
      width: '5%',
      rightAlign: true,
      paddingRight: '32px'
    },
    {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.PLACEMENT,
      column: 'placementid',
      isSorted: sortBy === 'placementid',
      sortOrder: sortOrder,
      width: '80px',
    },
    {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.LAST_MODIFIED,
      column: 'updatedAt',
      isSorted: sortBy?.toLowerCase() === 'updatedat',
      sortOrder: sortOrder,
      width: '105px',
    },
    {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.START_DATE,
      column: 'startdate',
      isSorted: sortBy === 'startdate',
      sortOrder: sortOrder,
      width: '90px',
    }, {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.END_DATE,
      column: 'enddate',
      isSorted: sortBy === 'enddate',
      sortOrder: sortOrder,
      width: '90px',
    }, {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.POINTS,
      column: 'points',
      isSorted: sortBy === 'points',
      sortOrder: sortOrder,
      width: '5%',
      rightAlign: true,
      paddingRight: '32px'
    }, {
      title: OFFER_WALL_MANAGER_COLUMN_TITLES.CONVERSION_CAP,
      column: 'offertunegoals',
      isSortDisabled: true,
      width: '100px',
    }
  ];


  const formattedRows = data?.map((x) => {
    const groupName = x.offergroupid ? groups.find((a: any) => a.id === x.offergroupid)?.name
      : groups.find((a: any) => a.id === selectedGroupId)?.name;
    const goals = x.offertunegoals || [];
    const activegoals = goals?.filter(
      (x) => x?.isconversioncapactive === 1
    );
    let bestConversionGoal, icon;
    if (activegoals?.length > 0) {
      // Find best conversion rate object within the current row's offertunegolas
      const result = findBestConversionRate(
        activegoals || []
      );
      bestConversionGoal = result.bestConversionGoal;
      icon = result.icon;
    }
    return (
      <>
        <td>
          <Link
            to={`/main/offer-wall-manager?groupId=${x.offergroupid}`}
            onClick={() => handleShowGroup(x.offergroupid)}
            disabled={
              states &&
                RowingOutlined &&
                RowingOutlined.id &&
                states[RowingOutlined.id]
                ? true
                : false
            }
          >
            {groupName}
          </Link>
        </td>
        <td>
          <Styled.OfferLabel>
            {x.isactive ? (
              <Avatar className={classes.offerActive} title="Status: ON">
                <LocalOfferRounded className={classes.offerIcon} />
              </Avatar>
            ) : (
              <Avatar className={classes.offerInactive} title="Status: OFF">
                <LocalOfferRounded color="disabled" fontSize="small" />
              </Avatar>
            )}
            {x &&
              x.offerid &&
              offerIDWithAbTests.includes(x.offerid) ? (
              <BootstrapTooltip
                title={`A/B Test: ${abTestObject[x.offerid]
                  }`}
              >
                <Styled.ActiveAbTestIcon
                  src={ABTest}
                  alt="Active A/B Test icon"
                />
              </BootstrapTooltip>
            ) : (
              <Styled.InactiveAbTestIcon
                src={ABTest}
                alt="Inactive A/B Test icon"
              />
            )}
            <Link
              onClick={() => handleShowOffer(x)}
              to={
                states[x.offerid]
                  ? '#'
                  : `/main/offer-wall-manager/editor/${x.offerid}`
              }
            >
              {x.label}
            </Link>
          </Styled.OfferLabel>
        </td>
        <td>
          <Styled.OfferId>{x.offerid}</Styled.OfferId>
        </td>
        <td>
          <Styled.Placement>
            {allOptions && allOptions.placementid
              ? _.startCase(allOptions.placementid[x.placementid])
              : ''}
          </Styled.Placement>
        </td>
        <td>
          <Styled.DateFormatter>
            {x.updatedat
              ? new Date(Number(x.updatedat)).toLocaleDateString('en-US')
              : ''}
          </Styled.DateFormatter>
        </td>
        <td>
          <Styled.DateFormatter>
            {x.startdate
              ? new Date(Number(x.startdate)).toLocaleDateString('en-US')
              : ''}
          </Styled.DateFormatter>
        </td>
        <td>
          <Styled.DateFormatter>
            {x.enddate
              ? new Date(Number(x.enddate)).toLocaleDateString('en-US')
              : ''}
          </Styled.DateFormatter>
        </td>
        <td>
          <Styled.Points>
            {x.points ? x.points.toLocaleString() : ''}
          </Styled.Points>
        </td>
        <td>
          <Styled.ConversionCap>
            {bestConversionGoal &&
              <div className={classes.conversionValue}>
                <BootstrapTooltip
                  title={activegoals?.map((goal) => (
                    <div>
                      {goal?.currentconversionvalue?.toLocaleString() || 0} /{' '}
                      {goal?.conversioncapvalue?.toLocaleString() || 0}{' '}
                      {goal?.conversiontimerange &&
                        `${TimeRangeofConversionLabel[goal.conversiontimerange]
                        }`}
                      {', '} Goal ID: {goal?.goalid}
                    </div>
                  ))}
                >
                  <IconButton size="small" style={{ left: '5px' }}>
                    {icon}
                  </IconButton>
                </BootstrapTooltip>
              </div>
            }
          </Styled.ConversionCap>
        </td>
      </>
    );
  });

  return (
    <>
      <DataTable
        loading={isOffersLoading}
        columns={columns}
        rows={formattedRows || []}
        showRowsPerPageDropdown
        customRowsPerPageOptions={[25, 50, 100]}
        defaultRowsPerPage={25}
        onPaginationClick={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onSortChange={handleOnSortChange}
        totalRows={count}
      />
    </>
  );
};

export const NoOffers = ({
  message,
  subline,
}: {
  message: string;
  subline?: string;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.noOffers}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <LocalOfferRounded />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            {message}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            {subline}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const NoCurrentGroup = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noOffers}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <LocalOfferRounded />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            Create or select a group
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            Start by clicking &quot;Create Group&quot;.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const LoadingOffers = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noOffers}>
      <CircularProgress color="primary" size={40} />
    </Box>
  );
};

/**
 * Retrieves offer tune goal object which have best conversion ratio.
 * @param {Array} data - The array of offer tune goals with active conversion cap.
 * @returns {Object} - An object containing best conversion ratio.
 */
export const findBestConversionRate = (goals) => {
  let bestConversionGoal = null;

  //Filter conversion active goals
  const activeGoals = goals.filter((x) => x?.isconversioncapactive);

  // Check if all filtered objects have currentconversionvalue = 0
  const allRatiosZero = activeGoals.every(
    (goal) => goal.currentconversionvalue === 0
  );

  if (allRatiosZero || activeGoals.length === 1) {
    // If all ratios are zero, return the first object from filtered data || Return the first object if only one exists
    bestConversionGoal = activeGoals[0];
  } else {
    //Find Goal with best conversion cap ratio
    bestConversionGoal = activeGoals.reduce((prev, current) => {
      const prevRatio = prev
        ? prev.currentconversionvalue / prev.conversioncapvalue
        : 0;
      const currentRatio =
        current.currentconversionvalue / current.conversioncapvalue;
      return currentRatio > prevRatio ? current : prev;
    }, null);
  }

  //Check if conversion cap is full filled
  const isConversionCapFullFilled = bestConversionGoal
    ? (bestConversionGoal?.currentconversionvalue || 0) /
        (bestConversionGoal?.conversioncapvalue || 0) >=
      1
      ? true
      : false
    : false;

  //Set icon to display conversioncapfullfill status
  const icon = isConversionCapFullFilled ? (
    <img src={CapFullFilledIcon} style={{ height: 22, width: 22 }} />
  ) : (
    <CapInProgressIcon style={{ height: 22, width: 22, color: '#4f41aaff' }} />
  );

  return {
    bestConversionGoal,
    icon,
  };
};

export default OfferWallManager;

import allValidations from '../validationObjects';
import fromStringWithBreakOrCommaToArray from 'utils/fromStringWithBreakOrCommaToArray';
import isNumeric from 'utils/isNumeric';
import { StateNames } from '../validationObjects/states';
import { ValidationErrorMessages } from 'constants/index';
import { retry } from 'redux-saga/effects';
import setSaveOfferButton from './setSaveOfferButton';

const validateOfferInputs = (value: string, keyName: string) => {
  const returnObject = { isValid: true, helperText: '' };
  if (!value) {
    return returnObject;
  }
  if (keyName === 'states' || keyName === 'zips') {
    if (value.includes(',') && value.includes('\n')) {
      returnObject.isValid = false;
      returnObject.helperText = 'You cannot have both commas and line breaks.';
      return returnObject;
    }
    let arrayOfStrings = null;
    if (value && value.includes('\n')) {
      arrayOfStrings = fromStringWithBreakOrCommaToArray(value);
    } else if (value && value.includes(',')) {
      arrayOfStrings = fromStringWithBreakOrCommaToArray(value);
    } else {
      arrayOfStrings = null;
      if (keyName === 'states') {
        const state = value.split(' ').join('');
        if (state.length) {
          if (!allValidations.states[state] && !StateNames[state]) {
            returnObject.isValid = false;
            returnObject.helperText = 'Invalid state.';
          }
        }
      }
      isNumeric;
      if (keyName === 'zips') {
        const zipCode = value.split(' ').join('');
        if (zipCode.length) {
          if (!isNumeric(zipCode) || !(zipCode.length === 5)) {
            returnObject.isValid = false;
            returnObject.helperText = 'Invalid zip code.';
          }
        }
      }
    }
    if (arrayOfStrings) {
      if (keyName === 'states') {
        for (let i = 0; i < arrayOfStrings.length; i++) {
          if (
            !allValidations.states[arrayOfStrings[i]] &&
            !StateNames[arrayOfStrings[i]]
          ) {
            returnObject.isValid = false;
            returnObject.helperText = 'Invalid states.';
            break;
          }
        }
      }
      if (keyName === 'zips') {
        if (arrayOfStrings.length > 50) {
          returnObject.isValid = false;
          returnObject.helperText =
            'Too many zip codes. Please upload the zip codes in a file';
        }
        for (let i = 0; i < arrayOfStrings.length; i++) {
          const zipCode = arrayOfStrings[i];
          if (!isNumeric(zipCode) || !(zipCode.length === 5)) {
            returnObject.isValid = false;
            returnObject.helperText = 'Invalid zip codes.';
            break;
          }
        }
      }
    }
  } else if (
    [
      'mediarate',
      'cap',
      'points',
      'pointsawarded',
      'goalid',
      'conversioncapvalue',
    ].includes(keyName)
  ) {
    if (value && (isNaN(Number(value)) || Number(value) < 0)) {
      returnObject.isValid = false;
      returnObject.helperText = ValidationErrorMessages.NON_NEGATIVE_NUMBER;
    }
  }
  return returnObject;
};

/**
 * Validates if the provided field is required for a given offer placement type.
 * @param field - The field to validate.
 * @param offer - The offer object containing placement information.
 * @returns A boolean indicating whether the field is required.
 */
export const validateOfferRequiredFields = (field: string, offer) => {
  // Define required fields for different offer placement types
  const offerWallFields = [
    'label',
    'logourl',
    'headline',
    'description',
    'ctatext',
    'goalid',
  ];
  const dashboardFields = [
    'label',
    'logourl',
    'headline',
    'description',
    'ctatext',
    'offername',
    'backgroundimgurl',
    'goalid',
  ];
  const kloverPlusFields = [
    'label',
    'logourl',
    'headline',
    'description',
    'ctatext',
    'goalid',
  ];
  const onBoardingFields = [
    'label',
    'logourl',
    'headline',
    'description',
    'ctatext',
    'backgroundimgurl',
    'offername',
    'goalid',
  ];

  // Determine the placement type of the offer
  const placementid = offer.placementid;
  switch (placementid) {
    case '1': // Offer Wall
      return offerWallFields.includes(field);
    case '2': // Dashboard
      // If the field is included in dashboardFields, it's required for Dashboard placement
      if (dashboardFields.includes(field)) return true;

      // If comparisonemoji exists in the offer, only comparisontext is required
      if (offer?.comparisonemoji) {
        return ['comparisontext'].includes(field);
      }

      // Otherwise, the field is not required
      return false;
    case '3': // Klover Plus
      return kloverPlusFields.includes(field);
    case '4': // Onboarding
      return onBoardingFields.includes(field);
    default:
      // For unknown placement types, the field is not required
      return false;
  }
};

/**
 * Validates the start and end dates of an offer.
 * @param {Object} offer - The offer object to validate.
 * @returns {boolean} - A boolean indicating whether the start and end dates are valid.
 */
export const validateStartEndDate = (offer) => {
  let isValidDate = false;
  try {
    const { isValidStartDate, isValidEndDate } = setSaveOfferButton(offer);
    const isSelectedValidStartDate = offer?.startdate && isValidStartDate;
    const isSelectedValidEndDate = offer?.enddate ? isValidEndDate : true;
    isValidDate = isSelectedValidStartDate && isSelectedValidEndDate;
    return isValidDate;
  } catch (err) {
    console.error(`validateStartEndDate() `, err);
  } finally {
    return isValidDate;
  }
};

export default validateOfferInputs;

import nanoid from 'utils/nanoid';
import { Step, StepLabel, Stepper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  stepperStyling: {
    fontSize: 13,
  },
  stepperContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface Dimension {
  stepperDimensionName: string;
}

const CustomStepper = ({
  activeStep,
  dimensions,
}: {
  activeStep: number;
  dimensions: Array<Dimension>;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.stepperContainer}>
      <Stepper
        activeStep={activeStep}
        style={{ background: 'transparent', width: '80%' }}
      >
        {dimensions.map((dimension) => (
          <Step key={nanoid()}>
            <StepLabel>
              <div className={classes.stepperStyling}>
                {dimension.stepperDimensionName}
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomStepper;

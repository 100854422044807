import React, { useState } from 'react';
import _ from 'lodash';
import numberFormatter from 'utils/formatNumber';
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@klover/attain-design-system';
import {
  ConditionBankingKeys,
  ConditionBankingOptions,
  ConditionComparisonOperators,
  ConditionDemographicKeys,
  ConditionDemographicOptions,
  ConditionMerchantOptions,
  ConditionOperator,
  ConditionOperators,
  ConditionOption,
  ConditionPatternKeys,
  ConditionPatternOperators,
  ConditionProductKeys,
  ConditionProductOptions,
  ConditionSurveyKeys,
  ConditionSurveyOptions,
  ConditionTransactionKeys,
  ConditionTransactionOptions,
  DimensionCategory,
  DimensionCategoryCondition,
  IsAndIsNotConditionPatternOperatorsKeys,
  deleteCondition,
  query,
  queryTotalAudienceSize,
  updateCondition,
} from '../slice';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import {
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Page Components
import AutoCompleteModule from '../autoComplete';

//qualifiers that do not need autocomplete
import tokens from 'tokens';
import { DotsThreeVertical, TrashSimple, Users } from '@phosphor-icons/react';
import { MULTIPLIER } from 'pages/variables';
import { StringMap } from 'interfaces';
import { omitFromAutoComplete } from '../omitFromAutoComplete';
import { getDefaultOperator } from '../../../helper/helper';

const useStyles = makeStyles(() =>
  createStyles({
    audienceSize: {
      height: 18,
      width: 18,
    },
    conditionControl: {
      minWidth: 150,
      maxHeight: 48,
    },
    operatorControl: {
      minWidth: 50,
      maxHeight: 48,
    },
    textFieldControl: {
      width: 223,
      minWidth: 75,
      maxHeight: 48,
    },
    icon: {
      height: 24,
      width: 24,
    },
    rowCondition: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    spinner: {
      display: 'flex',

      '& > * + *': {
        marginLeft: 10,
      },
    },
    qualifierWidth: {
      width: 223,
    },
    gridMargin: { margin: '0px 4px' },
  })
);

const dialog = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
  },
});

const spinner = createTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        maxHeight: 20,
        maxWidth: 20,
      },
      colorPrimary: {
        color: tokens.colorSpinnerDefault,
      },
    },
  },
});

interface Props {
  condition: DimensionCategoryCondition;
}

const ALTERNATIVE_NAMES_MAP: StringMap = {
  upc: 'Product Name',
  productMerchantName: 'Merchant Name',
};

const DimensionCondition = ({ condition }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [updatingCondition, setUpdatingCondition] = useState(false);

  const dimension = useAppSelector(
    (state) => state.audienceReducer.currentDimension
  );

  const dimensionUpdating = useAppSelector(
    (state) => state.audienceReducer.ui.dimensionUpdating
  );

  const currentCondition = useAppSelector((state) => {
    if (state.audienceReducer.currentConditions.byId[condition.id]) {
      return state.audienceReducer.currentConditions.byId[condition.id];
    } else {
      return condition;
    }
  });

  const modeled = useAppSelector((state) => {
    return state.audienceReducer.ui.modeled;
  });

  React.useEffect(() => {
    // if the dimension is not updating and the current condition's audience size is zero then set updating false
    if (!dimensionUpdating && currentCondition.audienceSize >= 0)
      setUpdatingCondition(false);
  }, [currentCondition.audienceSize, dimensionUpdating]);

  const isOperatorType = (operatorType: string, option: string | StringMap) => {
    if (typeof option === 'object') {
      return option.operatorType && option.operatorType === operatorType;
    }

    return false;
  };

  const currentConditionOption = () => {
    if (!dimension) {
      return '';
    }

    switch (dimension.category) {
      case 'bankData':
        return ConditionBankingOptions[currentCondition.option];
      case 'demographics':
        return ConditionDemographicOptions[currentCondition.option];
      case 'transactions':
        return ConditionTransactionOptions[currentCondition.option];
      case 'product':
        return ConditionProductOptions[currentCondition.option];
      case 'survey':
        return ConditionSurveyOptions[currentCondition.option];
      default:
        return '';
    }
  };

  const conditionOperators = useAppSelector((state) => {
    if (!state.audienceReducer.currentDimension) {
      return ConditionOperators;
    }

    const conditionOption: string | StringMap = currentConditionOption();

    if (isOperatorType('comparison', conditionOption)) {
      return ConditionComparisonOperators;
    }

    if (isOperatorType('pattern', conditionOption)) {
      return ConditionPatternKeys;
    }

    if (isOperatorType('pattern2', conditionOption)) {
      return IsAndIsNotConditionPatternOperatorsKeys;
    }

    return ConditionOperators;
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const conditionOptions = (category?: DimensionCategory) => {
    if (!category) {
      return;
    }

    switch (category) {
      case 'bankData':
        return ConditionBankingKeys;
      case 'demographics':
        return ConditionDemographicKeys;
      case 'merchant':
        return ConditionMerchantOptions;
      case 'product':
        return ConditionProductKeys;
      case 'transactions':
        return ConditionTransactionKeys;
      case 'survey':
        return ConditionSurveyKeys;
      default:
        return [];
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConditionChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const option = event.target.value as ConditionOption;
    const defaultOperator = getDefaultOperator(option);
    const updatedCondition = {
      ...currentCondition,
      option: option,
      operator: defaultOperator,
      qualifiers: '',
      audienceSize: 0,
    };
    dispatch(updateCondition(updatedCondition));
    dispatch(query());
  };

  const handleDelete = () => {
    dispatch(deleteCondition(currentCondition));
    dispatch(query());
  };

  const handleOperatorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const operator = event.target.value as ConditionOperator;
    const updatedCondition = {
      ...currentCondition,
      operator: operator,
    };
    if (currentCondition.qualifiers && currentCondition.option)
      setUpdatingCondition(true); // spinner only if all inputs are there
    dispatch(updateCondition(updatedCondition));
    dispatch(query());
  };

  const handleQualifierBlur = (qualifiers: string) => {
    const updatedCondition = {
      ...currentCondition,
      qualifiers: qualifiers,
      audienceSize: 0,
    };

    if (
      qualifiers !== '' &&
      currentCondition.operator &&
      currentCondition.option
    )
      setUpdatingCondition(true);
    dispatch(updateCondition(updatedCondition));
    dispatch(query());
    dispatch(queryTotalAudienceSize());
  };

  const handleQualifierChange = (qualifiers: string) => {
    const updatedCondition = {
      ...currentCondition,
      qualifiers: qualifiers,
      audienceSize: 0,
    };
    dispatch(updateCondition(updatedCondition));
  };

  const isAutoComplete = () => {
    if (!dimension || dimension.category == 'survey') {
      return false;
    }

    const conditionOption: string | StringMap = currentConditionOption();
    if (isOperatorType('pattern', conditionOption)) {
      const operator = ConditionPatternOperators[currentCondition.operator];
      return operator.autoComplete;
    }

    return (
      !omitFromAutoComplete[currentCondition.option] || !currentCondition.option
    );
  };

  const isDeletable =
    currentCondition.option !== '' || currentCondition.qualifiers !== '';

  return (
    <>
      <Box
        flexGrow={1}
        style={{ marginBottom: 6, marginTop: 6, marginRight: 24 }}
      >
        <Grid container className={classes.rowCondition}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ThemeProvider theme={dialog}>
              <Grid item className={classes.gridMargin}>
                <FormControl
                  color="primary"
                  variant="outlined"
                  className={classes.conditionControl}
                >
                  <Select
                    labelId="condition-label"
                    id="condition-select"
                    value={currentCondition.option}
                    onChange={handleConditionChange}
                    style={{ maxHeight: 48, width: 200, marginTop: 4 }}
                  >
                    {conditionOptions(dimension?.category)?.map((option) => {
                      return (
                        <MenuItem key={_.kebabCase(option)} value={option}>
                          {_.startCase(
                            ALTERNATIVE_NAMES_MAP[option]
                              ? ALTERNATIVE_NAMES_MAP[option]
                              : option
                          )}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item className={classes.gridMargin}>
                <FormControl
                  color="primary"
                  variant="outlined"
                  className={classes.operatorControl}
                >
                  <Select
                    labelId="operator-label"
                    id="operator-select"
                    value={currentCondition.operator}
                    onChange={handleOperatorChange}
                    style={{ maxHeight: 48, width: 100, marginTop: 4 }}
                  >
                    {conditionOperators.map((operator) => {
                      return (
                        <MenuItem key={operator} value={operator}>
                          {operator.replace(/\w+/g, _.lowerCase)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item className={classes.gridMargin}>
                {currentCondition.option && isAutoComplete() ? (
                  <AutoCompleteModule
                    condition={condition}
                    selectedClasses={`${classes.qualifierWidth} ${classes.textFieldControl}`}
                    onBlurFunction={handleQualifierBlur}
                    currentConditionValueString={'qualifiers'}
                    customLabel={
                      currentCondition.option === 'upc' // when on upc we use product name as the label
                        ? 'Product Name'
                        : undefined
                    }
                  />
                ) : (
                  <FormControl
                    color="primary"
                    variant="outlined"
                    className={`${classes.qualifierWidth} ${classes.textFieldControl}`}
                  >
                    <TextField
                      id={nanoid()}
                      label={
                        _.startCase(
                          ALTERNATIVE_NAMES_MAP[currentCondition.option]
                        ) ||
                        _.startCase(currentCondition.option) ||
                        ''
                      }
                      onBlur={(e) => handleQualifierBlur(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.code === 'Enter') {
                          handleQualifierBlur(e.target.value);
                          e.target.blur(); // can turn off blur if needed by removing this line
                        }
                      }}
                      onChange={(e) => handleQualifierChange(e.target.value)}
                      value={currentCondition.qualifiers}
                      variant="outlined"
                    />
                  </FormControl>
                )}
              </Grid>
            </ThemeProvider>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid item>
              <Grid container alignItems="center">
                <ThemeProvider theme={spinner}>
                  <Button
                    variant="contained"
                    disableElevation
                    disabled
                    size="small"
                    style={{
                      padding: '4px 6px 4px 6px',
                      borderRadius: 4,
                      backgroundColor: `${
                        dimensionUpdating && updatingCondition
                          ? tokens.colorButtonDark
                          : currentCondition.audienceSize > 0
                          ? tokens.colorButtonDark
                          : tokens.colorButtonGray10
                      }`,
                    }}
                  >
                    <Users size={20} weight="fill" color="white" />
                    <Box
                      marginLeft={1}
                      style={{
                        color: tokens.colorFlesh,
                        fontFamily: 'Calibre-Regular, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                    >
                      {updatingCondition ? (
                        <div className={classes.spinner}>
                          <CircularProgress />
                        </div>
                      ) : (
                        numberFormatter(currentCondition.audienceSize, 1)
                      )}
                    </Box>
                  </Button>
                </ThemeProvider>
                <Grid item>
                  {isDeletable ? (
                    <>
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        style={{ marginLeft: 10 }}
                      >
                        <DotsThreeVertical size={22} weight="bold" />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleDelete}>
                          <TrashSimple size={22} /> Delete
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <div style={{ width: 46 }} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default DimensionCondition;

import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const AudienceContainer = styled.div`
  height: 100vh;
`;

export const AudienceUnknown = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AudienceUnknownText = styled.div`
  font-size: 20px;
  text-align: center;
`;

export const Wrapper = styled.div`
  ${theme.mixins.card};
  overflow: hidden;
  margin: 0% 2% 4% 2%;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const CardBody = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
`;

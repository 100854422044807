import { Color } from '@material-ui/lab';
import { LoadingStates } from 'constants/index';

export interface LoadingProps {
  state: LoadingStates;
  message?: string;
}

export interface LoadingPropsObject {
  [key: string]: LoadingProps;
}

export interface SnackBarOptions {
  open: boolean;
  severity: Color;
  message: string;
}

export const defaultSnackBarOptions: SnackBarOptions = {
  open: false,
  severity: 'info',
  message: '',
};

import {
  COMMON_ERROR,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_PROGRESS,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_PROGRESS,
  LOGOUT_SUCCESS,
  RESET,
  RESET_PASSWORD,
  RESET_PASSWORD_PROGRESS,
  RESET_PASSWORD_SUCCESS,
} from './constants';
import { ILoginAction, IResetPassword } from 'types/types';
import { getAuthentication, logOutUser, resetPassword } from './apis';
import { put, takeLatest } from 'redux-saga/effects';

export function* loginSaga(payload: ILoginAction): any {
  try {
    yield put({ type: LOGIN_PROGRESS });
    const data = yield getAuthentication(payload.payload);
    if (data.code === 200) {
      yield put({ type: LOGIN_SUCCESS, payload: data });
    } else {
      yield put({
        type: LOGIN_FAILURE,
        payload: { message: COMMON_ERROR },
      });
    }
  } catch (error) {
    yield put({
      type: LOGIN_FAILURE,
      payload: { message: 'NETWORK ERROR ' + error },
    });
  }
}

export function* logOutSaga(): any {
  try {
    yield put({ type: LOGOUT_PROGRESS });
    const data = yield logOutUser();
    if (data.code === 200) {
      yield put({ type: LOGOUT_SUCCESS, payload: {} });
    }
  } catch (error) {
    yield put({
      type: RESET,
      payload: { message: 'NETWORK ERROR ' + error },
    });
  }
}

export function* resetPasswordSaga({ payload }: IResetPassword): any {
  try {
    yield put({ type: RESET_PASSWORD_PROGRESS });
    const data = yield resetPassword(payload.email);
    if (data.code === 200) {
      yield put({ type: RESET_PASSWORD_SUCCESS });
    }
  } catch (error) {
    yield put({
      type: RESET,
      payload: { message: 'NETWORK ERROR ' + error },
    });
  }
}

export default function* watchAll() {
  yield takeLatest<any>(LOGIN, loginSaga);
  yield takeLatest<any>(LOGOUT, logOutSaga);
  yield takeLatest<any>(RESET_PASSWORD, resetPasswordSaga);
}

import React from 'react';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import UserIcon from 'assets/images/supervisor-account.svg';
import numberFormatter from 'utils/formatNumber';
import { MULTIPLIER } from 'pages/variables';
import * as Styled from './index.styles';

interface AudienceCountProps {
  audienceSize: number;
  modeled: boolean;
}

const AudienceCountDisplay = ({
  audienceSize = 0,
  modeled,
}: AudienceCountProps) => {
  return (
    <div>
      <Styled.StyledPaper elevation={0}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <img src={UserIcon} alt="" />
          </Grid>
          <Grid item>
            <Styled.StyledTypography component="h5" variant="subtitle1">
              {modeled
                ? numberFormatter(audienceSize * MULTIPLIER, 1)
                : numberFormatter(audienceSize, 1)}
            </Styled.StyledTypography>
          </Grid>
        </Grid>
      </Styled.StyledPaper>
    </div>
  );
};

export default AudienceCountDisplay;

import {
  Alert,
  Button,
  Dialog,
  Grid,
  theme,
} from '@klover/attain-design-system';
import { deleteAudience } from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';

// Material UI Components
import DialogActions from '@material-ui/core/DialogActions';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import * as Styled from './index.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

interface Props {
  isOpen: boolean;
  setOpenDialog: any;
  user: any;
}

const AudienceDeleteDialog = ({ isOpen, setOpenDialog, user }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const currentAudience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );

  const audiencesById = useAppSelector(
    (state) => state.audienceReducer.entities.audiences.byId
  );

  const handleDeleteAudience = async () => {
    if (currentAudience && audiencesById[currentAudience.id]) {
      const audience = { ...currentAudience, updatedByEmail: user };
      dispatch(deleteAudience(audience));
    }
    history.push('/main/audience-manager');
  };

  return (
    <>
      <Dialog
        className={classes.root}
        fullWidth={true}
        onClose={() => setOpenDialog(false)}
        open={isOpen}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        headerID="deleteAudienceHeader"
        header={
          <Styled.CardHeader>
            <Styled.CardTitle>Permanently delete audience?</Styled.CardTitle>
          </Styled.CardHeader>
        }
        bodyID="deleteAudienceBody"
        body={
          <Styled.StyledBodyContainer
            container
            spacing={theme.spacing.lg}
            direction="column"
          >
            <Grid item>
              <Styled.BodyHeader>Audience name</Styled.BodyHeader>
              <Styled.BodyText>{currentAudience?.name}</Styled.BodyText>
            </Grid>
            <Grid item>
              <Alert severity="warning">
                You will not be able to recover any information about this
                audience once it is deleted
              </Alert>
            </Grid>
          </Styled.StyledBodyContainer>
        }
        footer={
          <>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} variant="outlined">
                Cancel
              </Button>
              <Button
                color="error"
                disableElevation
                onClick={handleDeleteAudience}
                variant="contained"
              >
                Delete Audience
              </Button>
            </DialogActions>
          </>
        }
      />
    </>
  );
};

export default AudienceDeleteDialog;

import Field from 'components/Field';
import Header from 'components/Header';
import React from 'react';
import Table from 'components/Table';
import { Box, CircularProgress } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { FormikProvider, useFormik } from 'formik';
import { NoOffers } from '..';
import { Offer, searchOffers } from '../slice';
import { OfferWallContext } from 'context';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';
import { Button, IconButton } from '@klover/attain-design-system';
import Stack from 'components/Stack';
import tokens from 'tokens';
import * as Grid from 'components/Grid';

const CreativeRotations: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    setShowCreativeRotation,
    setRemoveCreativeRotation,
    setExistingCreativeRotation,
  } = React.useContext(OfferWallContext);

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const { search } = formik.values;

  const groupLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.groupLoading
  );

  const offers = useAppSelector((state) => {
    return state.offerWallReducer.entities.offers.allIds
      .map((id) => {
        return state.offerWallReducer.entities.offers.byId[id];
      })
      .filter(
        ({ creativerotationenabled, parentofferid, label }: Offer) =>
          !parentofferid &&
          creativerotationenabled &&
          label?.toUpperCase().includes(search.toUpperCase())
      );
  });

  const offersLoading = groupLoading.state === 'loading';

  React.useEffect(() => {
    dispatch(searchOffers(''));
  }, []);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Offer Label',
        accessor: 'label',
        Cell: (row) => <Styled.OfferLabel>{row.cell.value}</Styled.OfferLabel>,
      },
      {
        Header: 'Actions',
        Cell: (row) => {
          const creativerotationenabled =
            row.cell.row.original.creativerotationenabled;
          const parentofferid = row.cell.row.original.parentofferid;
          return (
            <>
              {creativerotationenabled && !parentofferid ? (
                <Styled.ButtonSpacing>
                  <IconButton
                    onClick={() => {
                      setExistingCreativeRotation(row.cell.row.original);
                      setShowCreativeRotation(true);
                    }}
                    title="Edit Rotation"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => {
                      setExistingCreativeRotation(row.cell.row.original);
                      setRemoveCreativeRotation(true);
                    }}
                    title="Delete Rotation"
                  >
                    <Delete />
                  </IconButton>
                </Styled.ButtonSpacing>
              ) : null}
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <FormikProvider value={formik}>
      <Header
        title="Creative Rotations"
        action={
          <Button variant="contained" onClick={() => setShowCreativeRotation(true)}>
            Create Rotation
          </Button>
        }
      />
      <Box height="100%" padding={2}>
        <Grid.Container>
          <Stack spacing={tokens.spacing.md}>
            <Grid.Row>
              <Field name="search" placeholder="Search Offers" />
            </Grid.Row>
            <Grid.Row>
              {offersLoading ? (
                <CircularProgress size={20} />
              ) : offers.length ? (
                <Table columns={columns} data={offers} />
              ) : (
                <NoOffers
                  message="No offers with creative rotation."
                  subline={`Start by clicking "Create Rotation".`}
                />
              )}
            </Grid.Row>
          </Stack>
        </Grid.Container>
      </Box>
    </FormikProvider>
  );
};

export default CreativeRotations;

import styled from 'styled-components';

export const SalesLiftContainer = styled.div`
  height: 100vh;
`;

export const SalesLiftUnknown = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SalesLiftUnknownText = styled.div`
  font-size: 20px;
  text-align: center;
`;

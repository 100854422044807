import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  Grid,
  theme,
} from '@klover/attain-design-system';
import { SnackBarOptions, defaultSnackBarOptions } from 'interfaces/ui';
import {
  ToggleGroupDeleteDialogAction,
  deleteGroup,
  setGroupDeletingState,
  toggleGroupDeleteDialog,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
  })
);

interface Props {
  open: boolean;
}

const GroupDeleteDialog = ({ open }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>(
    defaultSnackBarOptions
  );

  const currentGroup = useAppSelector(
    (state) => state.audienceReducer.currentGroup
  );

  const groupDeleting = useAppSelector(
    (state) => state.audienceReducer.ui.groupDeleting
  );

  useEffect(() => {
    let snackBarOptions: SnackBarOptions;
    switch (groupDeleting.state) {
      case 'done':
        dispatchClose();

        snackBarOptions = {
          open: true,
          severity: 'success',
          message: groupDeleting.message || '',
        };
        break;
      case 'failed':
        snackBarOptions = {
          open: true,
          severity: 'error',
          message: groupDeleting.message || '',
        };
        break;
      default:
        snackBarOptions = {
          open: false,
          severity: 'success',
          message: '',
        };
        break;
    }
    setSnackBarOptions(snackBarOptions);
  }, [groupDeleting]);

  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    const updatedSnackBarOptions = {
      ...snackBarOptions,
      open: false,
    };
    setSnackBarOptions(updatedSnackBarOptions);
    dispatch(setGroupDeletingState({ state: 'idle' }));
  };

  const deleting = groupDeleting.state === 'loading';

  const dispatchClose = () => {
    const actionProps: ToggleGroupDeleteDialogAction = {
      open: false,
    };
    setSnackBarOptions(defaultSnackBarOptions);
    dispatch(toggleGroupDeleteDialog(actionProps));
  };

  const handleDeleteGroup = () => {
    if (currentGroup) {
      dispatch(deleteGroup(currentGroup));
    }
  };

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <>
      <Dialog
        className={classes.root}
        fullWidth={true}
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        headerID="deleteAudienceGroupHeader"
        header={
          <Styled.CardHeader>
            <Styled.CardTitle>
              Permanently delete audience group?
            </Styled.CardTitle>
          </Styled.CardHeader>
        }
        bodyID="deleteAudienceGroupBody"
        body={
          <Styled.StyledBodyContainer
            container
            spacing={theme.spacing.lg}
            direction="column"
          >
            <Grid item>
              <Styled.BodyHeader>Group name</Styled.BodyHeader>
              <Styled.BodyText>{currentGroup?.name}</Styled.BodyText>
            </Grid>
            <Grid item>
              <Alert severity="warning">
                You will not be able to recover any information about this
                audience group once it is deleted
              </Alert>
            </Grid>
          </Styled.StyledBodyContainer>
        }
        footer={
          <>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>

              {deleting ? (
                <Box
                  width={142}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                <Button
                  color="error"
                  disableElevation
                  onClick={handleDeleteGroup}
                  variant="contained"
                >
                  Delete Group
                </Button>
              )}
            </DialogActions>
          </>
        }
      />

      <Snackbar
        open={snackBarOptions.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity={snackBarOptions.severity}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupDeleteDialog;

const creativeDetailsOfferWall = {
  imageUrl: 'logourl',
  subline: 'headline',
  body: 'description',
  callToAction: 'ctatext',
  clickthroughUrl: 'ctaurl',
  zIndex: 'zindex',
};

export default creativeDetailsOfferWall;

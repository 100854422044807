// All components leveraging react-grid-system. Docs and examples here... https://sealninja.github.io/react-grid-system/

import React from 'react';
import * as Styled from './index.styles';

interface StackProps {
  children: React.ReactNode | string;
  spacing: string;
}

const Stack = ({ spacing, ...props }: StackProps) => {
  return <Styled.Stack spacing={spacing}>{props.children}</Styled.Stack>;
};

export default Stack;

import axios from 'axios';
import { API_BASE_ADDRESS } from './variables';
import { auth } from 'utils/firebase';
import { call, put, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { fillUPCSearchResults, searchQuery } from './slice';

interface SearchProps {
  category: string;
  query: string;
}

function handleSearch(params: SearchProps) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      `${API_BASE_ADDRESS}/search?category=${params.category}&query=${params.query}`
    );
  });
}

function* watchSearchQuery(action: PayloadAction<SearchProps>) {
  try {
    const result = yield call(handleSearch, action.payload);
    yield put(fillUPCSearchResults(result.data));
    return;
  } catch (e) {
    console.log(e);
  }
}

export default function* watchAll() {
  yield takeLatest<any>(searchQuery.type, watchSearchQuery);
}

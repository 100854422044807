import React, { useEffect, useState } from 'react';
import _, { zip } from 'lodash';
import { Button, Select } from '@klover/attain-design-system';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Page Components
import tokens from 'tokens';
import { States } from '../validationObjects/states';
import {
  clearCurrentTargeting,
  clearOffers,
  fetchGroup,
  setInitialPlacementOffers,
  updateCurrentTargeting,
  updateSearchQuery,
} from '../slice';
import { CLEAR_FILTERS, TARGETING_TESTING, TEST_TARGETING, USER_ID_EMAIL } from '../../../content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filter: {
      paddingRight: '6px',
      flex: '3'
    },
    clearFilter: {
      paddingRight: '6px',
      flex: '2'
    },
    filterContainer: {
      padding: '1em 0em 0em',
      display: 'flex'
    },
    fullWidth: {
      width: '100%',
    },
    mainContainer: {
      width: '100%'
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      height: '80px',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      width: '100%',
    },
    icon: {
      height: 24,
      width: 24,
    },
    menu: {
      paddingTop: '1em',
    },
    title: {
      marginTop: '-12px',
      background: 'white',
      width: '130px',
      paddingLeft: 8,
      paddingRight: 8,
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    singleColumn: {
      padding: '1em',
      width: '100%',
      minWidth: 300,
      height: '100%',
    },
    topDivider: {
      height: 2,
    },
    userForm: {
      padding: '0 1.5em 1.5em 1.5em',
      width: '100%',
      backgroundColor: tokens.colorDefault,
      borderRadius: '10px',
      border: '1px solid ' + tokens.colorSuccess,
      boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
        borderRadius: 10,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const Selector = ({ inputLabel, targetKey, setTargetingTesting, targetingTesting }) => {
  const devices = ['None', 'iOS', 'Android'];
  const classes = useStyles();
  return (
    <FormControl className={classes.fullWidth}>
      <InputLabel id="device-selector" style={{ paddingLeft: 12, marginTop: -8 }}>
        {inputLabel}
      </InputLabel>
      <Select
        labelId="device-selector"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        value={targetingTesting.device}
        onChange={(e) =>
          setTargetingTesting({ ...targetingTesting, device: e.target.value })
        }
      >
        {targetKey === 'state'
          ? ['None'].concat(Object.keys(States)).map((keyName) => {
            return (
              <MenuItem
                key={nanoid()}
                style={{ backgroundColor: 'white' }}
                value={keyName == 'None' ? '' : keyName}
              >
                {States[keyName] ? States[keyName] : 'None'}
              </MenuItem>
            );
          })
          : null}
        {targetKey === 'device'
          ? devices.map((device) => {
            return (
              <MenuItem
                key={nanoid()}
                style={{ backgroundColor: 'white' }}
                value={device == 'None' ? '' : device}
              >
                {device}
              </MenuItem>
            );
          })
          : null}
      </Select>
    </FormControl>
  );
};

const TargetingFilters: React.FC = () => {
  const classes = useStyles();
  const currentGroup = useAppSelector((state) =>
    state.offerWallReducer && state.offerWallReducer.currentGroup
      ? state.offerWallReducer.currentGroup
      : {}
  );
  const dispatch = useAppDispatch();
  const [targetingTesting, setTargetingTesting] = useState({
    state: '',
    zip: '',
    device: '',
    userid: ''
  })
  useEffect(() => {
    dispatch(clearOffers());
    dispatch(setInitialPlacementOffers());
    dispatch(clearCurrentTargeting());
    return () => {
      dispatch(clearCurrentTargeting());
      dispatch(updateSearchQuery(''));
      // fetching group again for better UI
      if (!_.isEmpty(currentGroup)) dispatch(fetchGroup(currentGroup));
    };
  }, []);
  const currentTargeting = useAppSelector((state) =>
    state.offerWallReducer && state.offerWallReducer.currentTargeting
      ? state.offerWallReducer.currentTargeting
      : {}
  );

  const searchQuery = useAppSelector((state) =>
    state.offerWallReducer &&
      state.offerWallReducer.ui &&
      state.offerWallReducer.ui.searchQuery
      ? state.offerWallReducer.ui.searchQuery
      : ''
  );

  const handleTestTargeting = () => {
    const cleanedTargetingTesting = Object.fromEntries(
      Object.entries(targetingTesting).filter(([key, value]) => value !== '' && value !== null)
    );
    dispatch(
      updateCurrentTargeting(cleanedTargetingTesting)
    )
  };

  const handleClearFilters = () => {
    setTargetingTesting({
      state: '',
      zip: '',
      device: '',
      userid: ''
    });
    dispatch(
      updateCurrentTargeting(null)
    )
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.singleColumn}>
        <div className={classes.userForm}>
          <div className={classes.title}>{TARGETING_TESTING}</div>
          <div className={classes.filterContainer}>
            <ThemeProvider theme={inputs}>
              <div className={classes.filter}>
                <TextField
                  className={classes.fullWidth}
                  id="user-offer-state"
                  label="State"
                  value={targetingTesting.state}
                  onChange={(e) =>
                    setTargetingTesting({ ...targetingTesting, state: e.target.value })
                  }
                  variant="outlined"
                />
              </div>
              <div className={classes.filter}>
                <TextField
                  className={classes.fullWidth}
                  id="user-offer-zips"
                  label="Zip"
                  value={targetingTesting.zip}
                  onChange={(e) =>
                    setTargetingTesting({ ...targetingTesting, zip: e.target.value })
                  }
                  variant="outlined"
                />
              </div>
              <div className={classes.filter}>
                <Selector
                  inputLabel={'Device'}
                  targetKey={'device'}
                  targetingTesting={targetingTesting}
                  setTargetingTesting={setTargetingTesting}
                />
              </div>
              <div className={classes.filter}>
                <TextField
                  className={classes.fullWidth}
                  id="user-offer-userid"
                  label={USER_ID_EMAIL}
                  value={targetingTesting.userid}
                  onChange={(e) =>
                    setTargetingTesting({ ...targetingTesting, userid: e.target.value })
                  }
                  variant="outlined"
                />
              </div>
              <div className={classes.filter}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleTestTargeting}
                >
                  {TEST_TARGETING}
                </Button>
              </div>
              <div className={classes.filter}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={handleClearFilters}
                >
                  {CLEAR_FILTERS}
                </Button>
              </div>

            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetingFilters;

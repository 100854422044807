import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KloverPiggyLogo from 'assets/images/KloverPiggyLogo.png';
import KloverPlusLogo from 'assets/images/Klover+.svg';
import OpenLinkIcon from 'assets/images/open-new-window.png';
import PreviewInfo from 'assets/images/preview_info.svg';
import React from 'react';
import Reward from 'assets/images/reward.svg';
import { ArrowForward } from '@material-ui/icons';
import * as Styled from './index.styles';

export enum OfferPlacements {
  Dashboard = 'dashboard',
  KloverPlus = 'kloverPlus',
  OfferWall = 'offerWall',
  Onboarding = 'Onboarding',
}

interface OfferPreviewProps {
  backgroundImgUrl?: string;
  comparisonText?: string;
  ctaText: string;
  description?: string;
  headline: string;
  logoUrl: string;
  offerName?: string;
  placement: OfferPlacements;
  points?: number;
  disclosure_required?: boolean;
  subLine?: string;
  body?: string;
  ctaurl?: string;
}

const OfferPreview = ({
  backgroundImgUrl,
  comparisonText,
  ctaText,
  description,
  headline,
  logoUrl,
  offerName,
  placement,
  points,
  disclosure_required,
  subLine,
  body,
  ctaurl
}: OfferPreviewProps): React.ReactElement => {
  return (
    <>
      {placement === OfferPlacements.OfferWall && (
        <Styled.Preview>
          <Styled.DisclosureContainer>
            <Styled.Disclosure>
              {Boolean(disclosure_required) && (
                <InfoIcon style={{ transform: 'translate(-15px,15px)' }} />
              )}
            </Styled.Disclosure>
          </Styled.DisclosureContainer>
          <Styled.OfferWallImageWrapper>
            {logoUrl ? <Styled.OfferWallPreviewImage src={logoUrl} alt="" /> : null}
          </Styled.OfferWallImageWrapper>

          <Styled.PreviewContent>
            <Styled.OfferWallHeadline>
              <span>{headline}</span>
            </Styled.OfferWallHeadline>

            <Styled.OfferWallBody>
              <span>{description}</span>
            </Styled.OfferWallBody>

            <Styled.OfferWallPreviewFooter>
              <Styled.PreviewCTA>
                <Styled.ClickThroughContainer>
                  {ctaText}
                  <Styled.ClickThroughLink href={ctaurl} target="_blank">
                    <Styled.LinkImg src={OpenLinkIcon} alt="Go" />
                  </Styled.ClickThroughLink>
                </Styled.ClickThroughContainer>
                <div>{points ? points.toLocaleString() + ' points' : ''}</div>                
              </Styled.PreviewCTA>
            </Styled.OfferWallPreviewFooter>
          </Styled.PreviewContent>
        </Styled.Preview>
      )}

      {placement === OfferPlacements.Dashboard && (
        <Styled.Preview>
          <Styled.DisclosureContainer>
            <Styled.Disclosure>
              <Styled.DashboardPoints>
                <img src={Reward} alt="" style={{ height: 18, width: 18 }} />
                {points ? points.toLocaleString() : ''}
              </Styled.DashboardPoints>
            </Styled.Disclosure>
          </Styled.DisclosureContainer>
          <Styled.PreviewImageWrapper>
            <Styled.GradientOverImage />
            {backgroundImgUrl ? (
              <Styled.PreviewImage src={backgroundImgUrl} alt="" />
            ) : null}
          </Styled.PreviewImageWrapper>
          <Styled.PreviewContent>
            <Styled.DashboardHeaderWrapper isDashBoard={true}>
              <Styled.DashboardPreviewAvatar>
                {logoUrl ? <Styled.LogoImage src={logoUrl} alt="" /> : null}
              </Styled.DashboardPreviewAvatar>
              <Styled.DashboardHeader>
                <Styled.DashboardHeadline>{offerName}</Styled.DashboardHeadline>
                <Styled.DashboardSubline>{headline}</Styled.DashboardSubline>
              </Styled.DashboardHeader>
            </Styled.DashboardHeaderWrapper>
            <Styled.DashboardPreviewBody>              
              <Styled.DashboardBody>
                {(description || "") + " "}
              </Styled.DashboardBody>   
              {comparisonText || ''}
            </Styled.DashboardPreviewBody>
            <Styled.DashboardPreviewFooter>
              <Styled.DashboardFooterCTA>{ctaText}</Styled.DashboardFooterCTA>
              {Boolean(disclosure_required) && (
                <Styled.PreviewInfoCOntainer>
                  <InfoOutlinedIcon />
                </Styled.PreviewInfoCOntainer>
              )}
            </Styled.DashboardPreviewFooter>
          </Styled.PreviewContent>
        </Styled.Preview>
      )}

      {placement === OfferPlacements.KloverPlus && (
        <Styled.KloverPlusPreview>
          <Styled.KloverPlusPreviewContent>
            <Styled.KloverPlusBody>
              <Styled.KloverPlusPreviewAvatar>
                {logoUrl ? <Styled.LogoImage src={logoUrl} alt="" /> : null}
              </Styled.KloverPlusPreviewAvatar>
              <Styled.KloverPlusContent>
                <Styled.KloverPlusSubline>{headline}</Styled.KloverPlusSubline>
                <Styled.KloverPlusBodyText>{description}</Styled.KloverPlusBodyText>
              </Styled.KloverPlusContent>
              <Styled.KloverPlusPoints>
                <img src={Reward} alt="" style={{ height: 16, width: 16 }} />
                {points ? points.toLocaleString() : ''}
              </Styled.KloverPlusPoints>
            </Styled.KloverPlusBody>
            <Styled.KloverPlusFooter>
              <Styled.KloverPlusCTA>{ctaText}</Styled.KloverPlusCTA>
              {Boolean(disclosure_required) && (
                <Styled.KloverPlusInfoContainer>
                  <InfoOutlinedIcon />
                </Styled.KloverPlusInfoContainer>
              )}
            </Styled.KloverPlusFooter>
          </Styled.KloverPlusPreviewContent>
        </Styled.KloverPlusPreview>
      )}

      {placement === OfferPlacements.Onboarding && (
        <Styled.Preview>
          <Styled.DisclosureContainer>
            <Styled.Disclosure>
              <Styled.OnboardingPoints>
                <img src={Reward} alt="" style={{ height: 18, width: 18 }} />
                {points ? points.toLocaleString() : ''}
              </Styled.OnboardingPoints>
            </Styled.Disclosure>
          </Styled.DisclosureContainer>
          <Styled.OnboardingPreviewImageWrapper>
            <Styled.OnboardingGradientOverImage />
            {backgroundImgUrl ? (
              <Styled.PreviewImage src={backgroundImgUrl} alt="" />
            ) : null}
          </Styled.OnboardingPreviewImageWrapper>
          <Styled.PreviewContent>
            <Styled.OnboardingHeaderWrapper>
              <Styled.OnboardingPreviewAvatarWrapper>
                {logoUrl ? <Styled.LogoImage src={logoUrl} alt="" /> : null}
              </Styled.OnboardingPreviewAvatarWrapper>
              <Styled.OnboardingHeader>
                <Styled.OnboardingHeadline>
                  {headline}
                </Styled.OnboardingHeadline>
              </Styled.OnboardingHeader>
            </Styled.OnboardingHeaderWrapper>
            <Styled.OnboardingPreviewBody>
              <Styled.OnboardingSubLine>
                {subLine}&nbsp;
                <Styled.OnboardingBody>{body}</Styled.OnboardingBody>
              </Styled.OnboardingSubLine>
            </Styled.OnboardingPreviewBody>
            <Styled.OnboardingPreviewCTA>{ctaText}</Styled.OnboardingPreviewCTA>
            <Styled.SkipPreviewCTA>Skip</Styled.SkipPreviewCTA>
            <Styled.HowPointsWorks>
              {' '}
              <img
                src={PreviewInfo}
                alt=""
                style={{ height: 18, width: 18 }}
              />{' '}
              Find out how points work
            </Styled.HowPointsWorks>
          </Styled.PreviewContent>
        </Styled.Preview>
      )}
    </>
  );
};

export default OfferPreview;

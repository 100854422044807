import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  Grid,
  theme,
} from '@klover/attain-design-system';
import { SnackBarOptions, defaultSnackBarOptions } from 'interfaces/ui';
import * as Styled from './index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from '../variables';
import { AnyKindOfDictionary } from 'lodash';
import {
  CustomAudienceGroup,
  deleteGropProps,
  toggleGroupDeleteProps,
} from '../groupName';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { auth } from 'utils/firebase';

const API_ENDPOINT = `${API_BASE_ADDRESS}/custom-audience-manager/groups`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
  })
);

interface Props {
  currentGroup: CustomAudienceGroup;
  groupDeleting: deleteGropProps;
  toggleGroupDeleteDialog: toggleGroupDeleteProps;
  setGroupDeletingState: () => void;
  settoggleGroupDeleteDialog: () => void;
  handleSelectGroup: AnyKindOfDictionary;
  reFetchGroups: () => void;
}

const GroupDeleteDialog = ({
  currentGroup,
  groupDeleting,
  toggleGroupDeleteDialog,
  setGroupDeletingState,
  settoggleGroupDeleteDialog,
  handleSelectGroup,
  reFetchGroups,
}: Props) => {
  const classes = useStyles();

  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>(
    defaultSnackBarOptions
  );

  useEffect(() => {
    let snackBarOptions: SnackBarOptions;
    switch (groupDeleting.state) {
      case 'done':
        dispatchClose();

        snackBarOptions = {
          open: true,
          severity: 'success',
          message: groupDeleting.message || '',
        };
        break;
      case 'failed':
        snackBarOptions = {
          open: true,
          severity: 'error',
          message: groupDeleting.message || '',
        };
        break;
      default:
        snackBarOptions = {
          open: false,
          severity: 'success',
          message: '',
        };
        break;
    }
    setSnackBarOptions(snackBarOptions);
  }, [groupDeleting]);

  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    const updatedSnackBarOptions = {
      ...snackBarOptions,
      open: false,
    };
    setSnackBarOptions(updatedSnackBarOptions);
    setGroupDeletingState({ state: 'idle' });
  };

  const deleting = groupDeleting.state === 'loading';

  const dispatchClose = () => {
    setSnackBarOptions(defaultSnackBarOptions);
    settoggleGroupDeleteDialog({ open: false });
  };

  const handleDeleteGroup = async () => {
    if (currentGroup && auth.currentUser) {
      auth.currentUser?.getIdTokenResult().then(async (res) => {
        setGroupDeletingState({ state: 'loading' });

        try {
          const authorizedAxios = await getAuthorizedAxiosInstance();
          if (authorizedAxios) {
            const res = await authorizedAxios.delete(
              `${API_ENDPOINT}/${currentGroup.id}`
            );
            if (res?.status === 200) {
              setGroupDeletingState({
                state: 'done',
                message: `${currentGroup.name} and its audiences have been deleted`,
              });
              reFetchGroups();
              setTimeout(() => handleSelectGroup('all'), 2000);
            } else {
              throw `Failed to delete group ${currentGroup.name}`;
            }
          }
        } catch (e) {
          setGroupDeletingState({ state: 'failed', message: e });
        }
      });
    }
  };

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <>
      <Dialog
        className={classes.root}
        fullWidth={true}
        onClose={handleClose}
        open={toggleGroupDeleteDialog.open}
        maxWidth="sm"
        headerID="deleteAudienceGroupHeader"
        header={
          <Styled.CardHeader>
            <Styled.CardTitle>
              Permanently delete audience group?
            </Styled.CardTitle>
          </Styled.CardHeader>
        }
        bodyID="deleteAudienceGroupBody"
        body={
          <Styled.StyledBodyContainer
            container
            spacing={theme.spacing.lg}
            direction="column"
          >
            <Grid item>
              <Styled.BodyHeader>Group name</Styled.BodyHeader>
              <Styled.BodyText>{currentGroup?.name}</Styled.BodyText>
            </Grid>
            <Grid item>
              <Alert severity="warning">
                You will not be able to recover any information about this
                audience group once it is deleted
              </Alert>
            </Grid>
          </Styled.StyledBodyContainer>
        }
        footer={
          <>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>

              {deleting ? (
                <Box
                  width={142}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                <Button
                  color="error"
                  disableElevation
                  onClick={handleDeleteGroup}
                  variant="contained"
                >
                  Delete Group
                </Button>
              )}
            </DialogActions>
          </>
        }
      />
      <Snackbar
        open={snackBarOptions.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity={snackBarOptions.severity}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupDeleteDialog;

import { DimensionCategory } from '../slice';

// Material UI Components
import StoreIcon from '@material-ui/icons/Store';
import { Bank, Chats, Coins, Package, User } from '@phosphor-icons/react';

import tokens from 'tokens';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    bankDataIcon: {
      color: tokens.colorIconInfo,
    },
    demographicsIcon: {
      color: tokens.colorIconExtra,
    },
    merchantIcon: {
      color: tokens.colorIconWarning,
    },
    productsIcon: {
      color: tokens.colorIconSuccess,
    },
    transactionsIcon: {
      color: tokens.colorIconDanger,
    },
    questionIcon: {
      color: tokens.colorIconInfo,
    },
  })
);

interface Props {
  category: DimensionCategory;
  size: number;
}

const DimensionIcon = ({ category, size = 22 }: Props) => {
  const classes = useStyles();
  const icon = (category: DimensionCategory) => {
    switch (category) {
      case 'bankData':
        return <Bank size={size} />;
      case 'demographics':
        return <User size={size} />;
      case 'merchant':
        return <StoreIcon className={iconStyle(category)} />;
      case 'product':
        return <Package size={size} />;
      case 'transactions':
        return <Coins size={size} />;
      case 'survey':
        return <Chats size={size} />;
    }
  };

  const iconStyle = (category: DimensionCategory) => {
    switch (category) {
      case 'bankData':
        return classes.bankDataIcon;
      case 'demographics':
        return classes.demographicsIcon;
      case 'merchant':
        return classes.merchantIcon;
      case 'product':
        return classes.productsIcon;
      case 'transactions':
        return classes.transactionsIcon;
      case 'survey':
        return classes.questionIcon;
    }
  };

  return <>{icon(category)}</>;
};

export default DimensionIcon;

import AudienceStatusHeader from 'pages/audienceManager/audienceStatus';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CopyPopover from 'components/CopyPopover';
import DataTable from 'components/DataTable/DataTable';
import ErrorIcon from '@mui/icons-material/Error';
import ExportDialog from '../exportDialog';
import ExportHistroryDialog from 'pages/audienceManager/exportHistoryDialog';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Link from 'components/Link';
import React, { useState } from 'react';
import _debounce from 'lodash/debounce';
import {
  AudienceEstSizePopup,
  AudienceExportStatus,
  getAudienceEstSizeDetails,
} from 'pages/audienceManager';
import { Box, Tooltip } from '@mui/material';
import { CircularProgress, Popover } from '@material-ui/core';
import { CustomAudience } from 'interfaces/customAudiences';
import { ERROR_PROCESSING_FILE, IN_PROGRESS } from '../../../content';
import { IconButton } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { UploadStates } from 'constants/index';
import * as Styled from './index.styles';
import { modifyProps } from 'pages/audienceManager/exportHistoryDialog/exportHistoryUtils';

interface Props {
  customAudiences: Array<CustomAudience>;
  groups: any;
  selectedGroupId: any;
  handleSelectGroup: any;
  oprations: any;
  setOprations: any;
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audienceName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 220,
      display: 'inline-block',
      cursor: 'pointer',
      fontSize: 16,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    taxonomy: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 180,
      display: 'inline-block',
      fontSize: 16,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    createdBy: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 150,
      display: 'inline-block',
      fontSize: 16,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    audienceStatus: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
    },
    fileName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 210,
      display: 'inline-block',
      fontSize: 16,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    audienceSizeStatus: {
      fontSize: 14,
      color: '#0000008c',
      lineHeight: '20px',
      fontWeight: 500,
    },
    audienceSizeIcon: {
      color: 'gray',
      padding: '2px',
      marginTop: '-1px',
    },
    totalSize: {
      fontWeight: 500,
      lineHeight: '20px',
    },
    infoIcon: {
      color: 'gray',
      padding: '2px',
      marginTop: '1px',
      cursor: 'pointer',
    },
    popover: {
      '& > div': {
        boxShadow: 'none !important',
      },
    },
  })
);

const CustomAudienceTable = (props: Props) => {
  const {
    customAudiences,
    handleSelectGroup,
    oprations,
    setOprations,
    loading,
  } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(0);
  const [name, setName] = useState('');
  const { sortBy, sortOrder } = oprations;
  const [openExportHistoryDialog, setOpenExportHistoryDialog] = useState(false);
  const [currentAudienceHistory, setCurrentAudienceHistory] = useState([]);
  const [currentAudience, setCurrentAudience] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExport = (id: number, name: string) => {
    setName(name);
    setId(id);
    setOpenModal(true);
  };

  const columns = [
    {
      title: 'Group',
      column: 'group_id',
      isSorted: sortBy === 'group_id',
      sortOrder: sortOrder,
    },
    {
      title: 'Name',
      column: 'name',
      isSorted: sortBy === 'name',
      sortOrder: sortOrder,
    },
    {
      title: 'Audience ID',
      column: 'id',
      isSorted: sortBy === 'id',
      sortOrder: sortOrder,
      isHidden: true,
    },
    {
      title: 'Taxonomy',
      column: 'segmentName',
      isSortDisabled: true,
    },
    {
      title: 'Sent File Name',
      column: 'sentFilename',
      isSortDisabled: true,
    },
    {
      title: <AudienceStatusHeader />,
      column: 'exportStatus',
      width: '10%',
      isSortDisabled: true,
    },
    {
      title: 'Created By',
      column: 'email',
      isSorted: sortBy === 'email',
      sortOrder: sortOrder,
    },
    {
      title: 'Audience Size',
      column: 'size',
      isSorted: sortBy === 'size',
      sortOrder: sortOrder,
    },
    {
      title: 'Export Audience',
      column: 'status',
      isSortDisabled: true,
    },
  ];

  const formattedRows = customAudiences?.map((x) => {
    const audience = modifyProps([x])[0];
    const exportHistory = modifyProps(audience.exportHistory);
    audience.exportHistory = exportHistory;
    const { audinceSize, audienceExportLabel } =
      getAudienceEstSizeDetails(audience);
    return (
      <>
        <td>
          <Link
            to={`/main/custom-audience-manager?groupId=${x.group_id}&email=all`}
            onClick={() => handleSelectGroup(x.group_id)}
          >
            {x.group_name}
          </Link>
        </td>
        <td>
          <Styled.AudienceName>
            <CopyPopover
              value={x?.name || ''}
              className={classes.audienceName}
              title={`(${x?.id || ''})  ${x?.name || ''}`}
            />
          </Styled.AudienceName>
        </td>
        <td style={{ display: 'none' }}>{x?.id}</td>
        <td>
          <Styled.Taxonomy>
            <CopyPopover
              value={x?.segmentName || ''}
              className={classes.taxonomy}
            />
          </Styled.Taxonomy>
        </td>
        <td>
          <Styled.FileName>
            <CopyPopover
              value={x?.sentFilename || ''}
              className={classes.fileName}
            />
          </Styled.FileName>
        </td>
        <td>
          <Styled.AudienceStatus>
            <AudienceExportStatus value={x?.exportStatus} />{' '}
            <IconButton
              onClick={() => {
                setCurrentAudienceHistory(x?.exportHistory || []);
                setCurrentAudience(x);
                setOpenExportHistoryDialog(true);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Styled.AudienceStatus>
        </td>
        <td>
          <Styled.CreatedBy>
            <CopyPopover value={x?.email || ''} className={classes.createdBy} />
          </Styled.CreatedBy>
        </td>
        <td>
          <Styled.ModeledSize>
            <div className={classes.totalSize}>{audinceSize}</div>
            <div style={{ display: 'flex' }}>
              <div className={classes.audienceSizeStatus}>
                {audienceExportLabel}
              </div>
              <div>
                <InfoIcon
                  aria-describedby="est-audience-size"
                  className={classes.infoIcon}
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                  onClick={(e) => {
                    setCurrentAudience(x);
                    setAnchorEl(e.currentTarget);
                  }}
                />
                {anchorEl && currentAudience?.id === x.id && (
                  <Popover
                    id="est-audience-size"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    className={classes.popover}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                  >
                    <AudienceEstSizePopup audience={audience} />
                  </Popover>
                )}
              </div>
            </div>
          </Styled.ModeledSize>
        </td>
        <td>
          {x?.status === UploadStates.DONE ? (
            <IconButton
              onClick={() => {
                handleExport(x.id, x.name);
              }}
            >
              <CloudUploadIcon />
            </IconButton>
          ) : x?.status === UploadStates.ERROR ? (
            <Tooltip title={ERROR_PROCESSING_FILE} placement="bottom" arrow>
              <ErrorIcon style={{ color: '#ff2c2c', marginLeft: 8 }} />
            </Tooltip>
          ) : (
            <Tooltip title={IN_PROGRESS} placement="bottom" arrow>
              <CircularProgress size={24} />
            </Tooltip>
          )}
        </td>
      </>
    );
  });

  const count =
    customAudiences && customAudiences?.length > 0
      ? typeof customAudiences[0].totalcount === 'string'
        ? Number(customAudiences[0].totalcount)
        : customAudiences[0].totalcount
      : 1;

  const handleChangePage = (newPage: number) => {
    setOprations({
      ...oprations,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (value: number) => {
    const newPageSize = parseInt(value, 10);
    setOprations({
      ...oprations,
      page: 0,
      rowsPerPage: newPageSize,
    });
  };

  const handleOnSortChange = (sortInfo: string) => {
    console.log('Sorting:', sortInfo);
    let sortOrder = 'DESC';
    if (oprations.sortBy === sortInfo.column) {
      sortOrder = oprations.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }
    setOprations({
      ...oprations,
      sortBy: sortInfo.column,
      sortOrder: sortOrder,
      page: 0,
    });
  };

  return (
    <Box>
      <DataTable
        loading={loading}
        columns={columns}
        rows={formattedRows || []}
        showRowsPerPageDropdown
        customRowsPerPageOptions={[25, 50, 100]}
        defaultRowsPerPage={25}
        onPaginationClick={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onSortChange={handleOnSortChange}
        totalRows={count}
      />
      <ExportDialog
        isOpen={openModal}
        setIsOpen={setOpenModal}
        audienceId={id}
        audienceName={name}
      />
      <ExportHistroryDialog
        isOpen={openExportHistoryDialog}
        setOpenDialog={setOpenExportHistoryDialog}
        data={currentAudienceHistory}
        customAudience={currentAudience}
      />
    </Box>
  );
};

export default CustomAudienceTable;

import _ from 'lodash';

export const getAllIds = (
  obj,
  identifier,
  previousNormalizedObject: any = false
) => {
  const allIds = obj.map((x: any) => x[identifier]).filter((x) => x);
  if (previousNormalizedObject.allIds)
    return _.union(allIds, previousNormalizedObject.allIds);
  return allIds;
};

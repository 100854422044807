import React, { useEffect, useState } from 'react';
import nanoid from 'utils/nanoid';
import {
  Audience,
  Dimension,
  addAudience,
  clearCurrentAudience,
  fetchAudience,
  loadOriginalAudience,
  setModeled,
  setUIState,
  toggleDraftStateDialog,
} from '../slice';
import { Grid, IconButton, theme } from '@klover/attain-design-system';
import { buildAudienceObject } from '../sagas/audiences';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory, useParams } from 'react-router-dom';
import * as Styled from './index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import GhostLoader from 'react-ghost-loader';
import Typography from '@material-ui/core/Typography';
import { ArrowLeft } from '@phosphor-icons/react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// Page Components
import AddDimensionCategory from '../addDimensionCategory';
import AudienceDeleteDialog from '../audienceDeleteDialog';
import AudienceName from '../audienceName';
import DimensionContainer from '../dimension';
import DimensionDialog from '../dimensionDialog';
import DraftStateDialog from '../draftStateDialog';
import DraftStateSvg from '../draftStateIndicator';
import DuplicateAudienceDialog from '../duplicateAudienceDialog';
import ExportDialog from '../export';
import TotalAudienceSize from '../totalAudienceSize';
import compareAudiences from 'utils/compareAudiences';

import tokens from 'tokens';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    draftIndicatorAndTotalAudience: {
      display: 'flex',
    },
    draftIndicator: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 10,
    },
    noAudiences: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 12,
      boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
      zIndex: 1,
    },
    dimensions: {
      margin: theme.spacing(2),
    },
    noDimensions: {
      minHeight: '70vh',
    },
    modeledButtonContainer: {
      position: 'fixed',
      right: 0,
      top: 0,
      paddingTop: '0.3em',
      paddingRight: '1.2em',
    },
    countButton: {
      cursor: 'pointer',
      height: 16,
      fontSize: 10,
      fontWeight: 'bold',
      border: '1px solid ' + tokens.colorButtonPrimary,
      borderRight: 'none',
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderWidth: 'thin',
      outline: 'none',
      backgroundColor: tokens.colorButtonDefault,
      color: tokens.colorButtonPrimary,
      opacity: 1,
      '&:hover': {
        opacity: 0.8,
      },
    },
    modeledButton: {
      cursor: 'pointer',
      height: 16,
      fontSize: 10,
      fontWeight: 'bold',
      border: '1px solid ' + tokens.colorButtonPrimary,
      borderLeft: 'none',
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderWidth: 'thin',
      outline: 'none',
      borderColor: tokens.colorButtonPrimary,
      backgroundColor: tokens.colorButtonDefault,
      color: tokens.colorButtonPrimary,
      opacity: 1,
      '&:hover': {
        opacity: 0.8,
      },
    },
    selectedButton: {
      backgroundColor: tokens.colorButtonPrimary,
      color: tokens.colorButtonDefault,
    },
  })
);

const AudienceManager = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { audienceid } = useParams();

  const [openDialog, setOpenDialog] = useState(false);

  const errorFetching = useAppSelector(
    (state) => state.audienceReducer.ui.errorFetching
  );

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const dimensions = useAppSelector((state) => {
    const audience = state.audienceReducer.currentAudience;
    if (audience) {
      return audience.dimensions.map((id) => {
        return state.audienceReducer.currentDimensions.byId[id];
      });
    }

    return [];
  });

  const openDimensionDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openDimensionDialog
  );
  const originalAudience = useAppSelector(
    (state) => state.audienceReducer.originalAudience
  );

  const openExportDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openExportDialog
  );

  const openDuplicateAudienceDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openDuplicateAudienceDialog
  );

  const openDraftStateDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openDraftStateDialog
  );

  const currentAudience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );

  const currentConditions = useAppSelector((state) => {
    return state.audienceReducer.currentConditions;
  });

  const currentDimensions = useAppSelector((state) => {
    return state.audienceReducer.currentDimensions;
  });

  const currentDimensionGroups = useAppSelector((state) => {
    return state.audienceReducer.currentDimensionGroups;
  });

  const currentGroup = useAppSelector((state) => {
    return state.audienceReducer.currentGroup;
  });

  const modeled = useAppSelector((state) => {
    return state.audienceReducer.ui.modeled;
  });

  const isAudienceLoading = audienceLoading.state === 'loading';

  useEffect(() => {
    dispatch(setModeled(false));
    dispatch(setUIState({ value: false, uiPropertyName: 'errorFetching' }));
    if (audienceid) {
      dispatch(
        fetchAudience({
          id: audienceid,
        })
      );
    } else if (!audienceid && currentGroup) {
      const newAudience: Audience = {
        id: nanoid(),
        name: '',
        totalSize: 0,
        dimensions: [],
        dimensionGroups: [],
        createdAt: 0,
        updatedAt: 0,
      };
      dispatch(loadOriginalAudience(null));
      dispatch(addAudience(newAudience));
    } else {
      history.push('/main/audience-manager');
    }
    return () => {
      dispatch(setModeled(false));
      dispatch(
        setUIState({
          value: false,
          uiPropertyName: 'errorFetching',
        })
      );
    };
  }, []);

  const updatedAudience = {
    ...currentAudience,
    updatedAt: Date.now(),
    groupId: originalAudience?.group_id,
  };

  const audienceObject = buildAudienceObject({
    updatedAudience,
    currentDimensions,
    currentDimensionGroups,
    currentConditions,
  });

  const isUnchanged = compareAudiences(originalAudience, audienceObject);

  const handleClearAudience = (e) => {
    if (originalAudience) {
      if (isUnchanged) {
        dispatch(clearCurrentAudience());
      } else {
        e.preventDefault();
        dispatch(
          toggleDraftStateDialog({ open: true, path: '/main/audience-manager' })
        );
      }
    } else {
      dispatch(clearCurrentAudience());
    }

    history.push(`/main/audience-manager?groupId=${currentGroup?.id || 'all'}`);
  };

  return (
    <>
      {!errorFetching && (
        <>
          <Grid item container className={classes.root}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                color="default"
                disabled={isAudienceLoading}
                onClick={handleClearAudience}
              >
                <ArrowLeft size={theme.icons.lg} />
              </IconButton>
              <div style={{ marginLeft: 8 }} />
              <AudienceName isAudienceLoading={isAudienceLoading} audienceid={audienceid} />
            </div>
            <Grid className={classes.draftIndicatorAndTotalAudience}>
              {!isAudienceLoading && !isUnchanged ? (
                <div className={classes.draftIndicator}>
                  <DraftStateSvg />
                </div>
              ) : null}
              <TotalAudienceSize />
            </Grid>
          </Grid>
          {/* Top Divider */}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Add Dimension Category */}
          <AddDimensionCategory isAudienceLoading={isAudienceLoading} />

          {/* Dimension Categories */}
          {isAudienceLoading ? (
            <Box className={classes.noDimensions}>
              <LoadingAudience />
            </Box>
          ) : (
            <React.Fragment>
              <Box className={classes.dimensions}>
                {dimensions.length > 0 ? (
                  <DimensionContainers dimensions={dimensions} />
                ) : (
                  <NoDimensions />
                )}
              </Box>

              <DimensionDialog open={openDimensionDialog} />
              <ExportDialog open={openExportDialog} />
              <DuplicateAudienceDialog open={openDuplicateAudienceDialog} />
            </React.Fragment>
          )}
          <DraftStateDialog openDraftStateDialog={openDraftStateDialog} />
          <AudienceDeleteDialog
            isOpen={openDialog}
            setOpenDialog={setOpenDialog}
          />
          {/* <div className={classes.modeledButtonContainer}>
            <button
              className={`${classes.countButton} ${
                !modeled ? classes.selectedButton : ''
              }`}
              onClick={() => dispatch(setModeled(false))}
            >
              Count
            </button>
            <button
              className={`${classes.modeledButton} ${
                modeled ? classes.selectedButton : ''
              } `}
              onClick={() => dispatch(setModeled(true))}
            >
              Modeled
            </button>
          </div> */}
        </>
      )}
      {errorFetching && (
        <Styled.AudienceContainer>
          <Styled.AudienceUnknown>
            <Styled.AudienceUnknownText>
              404 Error: This page does not exist.
            </Styled.AudienceUnknownText>
          </Styled.AudienceUnknown>
        </Styled.AudienceContainer>
      )}
    </>
  );
};

interface Props {
  dimensions: Dimension[];
}

const DimensionContainers = ({ dimensions }: Props) => {
  return (
    <>
      <Grid container spacing={2}>
        {dimensions.map((dimension) => (
          <DimensionContainer key={dimension.id} dimension={dimension} />
        ))}
      </Grid>
    </>
  );
};

const NoDimensions = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noDimensions}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <FilterNoneIcon />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            This audience hasn&apos;t been filtered by any conditions yet.
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            Start by adding a dimension category.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const LoadingAudience = () => {
  return (
    <Styled.Wrapper>
      <Styled.CardBody>
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="42px"
          widthRandom={0}
        />

        <GhostLoader
          height="42px"
          heightRandom={0}
          width="100%"
          widthRandom={0}
        />
      </Styled.CardBody>
      <Styled.CardBody>
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="80%"
          widthRandom={0}
        />
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
      </Styled.CardBody>
      <Styled.CardBody>
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="70%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
      </Styled.CardBody>
    </Styled.Wrapper>
  );
};

export default AudienceManager;

import _ from 'lodash';
export default function reformatAudienceObject(obj) {
  let audience = _.clone(obj);
  const dimensions = [];
  const dimension_groups = [];
  audience = _.omit(audience, ['created_at', 'updated_at']);
  for (const dimension of audience.dimensions) {
    const clonedDimension = _.clone(dimension);
    const conditions = [];
    for (const key in clonedDimension) {
      if (clonedDimension[key] === '') clonedDimension[key] = null;
    }
    for (const condition of clonedDimension.conditions) {
      const clonedCondition = _.clone(condition);
      for (const key in clonedCondition) {
        if (clonedCondition[key] === '') clonedCondition[key] = null;
      }
      conditions.push(_.omit(clonedCondition, ['created_at', 'updated_at']));
    }
    clonedDimension.conditions = conditions;
    dimensions.push(_.omit(clonedDimension, ['created_at', 'updated_at']));
  }
  for (const dimensionGroup of audience.dimension_groups) {
    for (const key in dimensionGroup) {
      if (dimensionGroup[key] === '') dimensionGroup[key] = null;
    }
    dimension_groups.push(_.omit(dimensionGroup, ['created_at', 'updated_at']));
  }
  for (const key in audience) {
    if (audience[key] === '') audience[key] = null;
  }
  audience.dimensions = dimensions.sort();
  audience.dimension_groups = dimension_groups.sort();
  return _.omit(audience, ['created_at', 'updated_at']);
}

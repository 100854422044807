import styled from 'styled-components';
import tokens from 'tokens';
import { FieldProps } from '.';
import { Field as FormikField } from 'formik';

export const Wrapper = styled.fieldset<FieldProps>`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

export const Label = styled.label`
  ${tokens.typography.label};
  display: block;
  margin-bottom: ${tokens.spacing.xs};
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
`;

export const Field = styled(FormikField)<FieldProps>`
  ${tokens.typography.body}
  background: ${tokens.colorPagebg};
  border: 1px solid ${tokens.colorGray40};
  border-radius: ${tokens.spacing.sm};
  height: 40px;
  padding: ${tokens.spacing.xs} ${tokens.spacing.sm};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  font-size: 14px;

  &:focus-visible {
    border-color: ${tokens.colorGray80};
    outline: none;
  }

  //checkboxes & radios
  &[type='checkbox'],
  &[type='radio'] {
    appearance: none;
    border: solid 2px ${tokens.colorPagebg};
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    margin: ${tokens.spacing.xs} ${tokens.spacing.sm} ${tokens.spacing.xs} 0;
    outline: 1px solid ${tokens.colorGray60};
    position: relative;
    top: -2px;
    width: 16px;
    vertical-align: middle;

    &:checked {
      background: ${tokens.colorBrandprimary};
    }

    & + * {
      ${tokens.typography.body}
    }
  }

  //selects
  select& {
    padding-left: ${tokens.spacing.xs};
  }

  //textareas
  textarea& {
    height: auto;
  }
`;

import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  totalAudienceSizeContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    // padding: '1em',
  },
  totalAudienceSizeBlock: {
    color: 'black',
    paddingRight: '1em',

    fontWeight: 500,
  },
  totalAudienceCircularProgressBlock: {
    width: 100,
    paddingRight: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgSpacing: {
    paddingTop: '0.35em',
    paddingRight: '0.4em',
  },
}));

const TotalAudienceBlock = ({
  queryLoading,
  dimension,
  fontSize,
}: {
  fontSize?: number;
  dimension: any;
  queryLoading: boolean;
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.totalAudienceSizeContainer}
      style={{ fontSize: fontSize || 18 }}
    >
      <div className={classes.svgSpacing}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_4373:204304"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="2"
            y="2"
            width="18"
            height="18"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.8663 2.56482C6.13219 2.56482 2.29004 6.40697 2.29004 11.1411C2.29004 15.8751 6.13219 19.7173 10.8663 19.7173C15.6004 19.7173 19.4425 15.8751 19.4425 11.1411C19.4425 6.40697 15.6004 2.56482 10.8663 2.56482ZM13.9624 8.00214C14.88 8.00214 15.6176 8.73969 15.6176 9.65735C15.6176 10.575 14.88 11.3126 13.9624 11.3126C13.0447 11.3126 12.3071 10.575 12.3071 9.65735C12.2986 8.73969 13.0447 8.00214 13.9624 8.00214ZM10.8406 8.67112C10.8406 7.55621 9.93151 6.64713 8.8166 6.64713C7.69311 6.64713 6.7926 7.54763 6.7926 8.67112C6.7926 9.78603 7.70168 10.6951 8.8166 10.6951C9.93151 10.6951 10.8406 9.78603 10.8406 8.67112ZM8.81657 14.4772V17.6933C6.75827 17.0501 5.12879 15.4635 4.40839 13.4395C5.30889 12.479 7.55586 11.9901 8.81657 11.9901C9.27111 11.9901 9.84572 12.0587 10.4461 12.1788C9.03955 12.9249 8.81657 13.9112 8.81657 14.4772ZM10.1888 17.9678C10.4117 17.9935 10.6347 18.0021 10.8663 18.0021C13.7736 18.0021 16.2522 16.1839 17.2556 13.6368C16.4666 12.985 14.88 12.6505 13.9623 12.6505C12.7102 12.6505 10.1888 13.2594 10.1888 14.4772V17.9678Z"
              fill="black"
            />
          </mask>
          <g mask="url(#mask0_4373:204304)">
            <rect
              x="0.574707"
              y="0.849609"
              width="20.583"
              height="20.583"
              fill="#09101D"
            />
          </g>
        </svg>
      </div>
      {!queryLoading ? (
        <div className={classes.totalAudienceSizeBlock}>
          {dimension && dimension.totalAudienceSize
            ? dimension.totalAudienceSize
            : '~'}
        </div>
      ) : (
        <div className={classes.totalAudienceCircularProgressBlock}>
          <CircularProgress size={fontSize || 18} color="inherit" />
        </div>
      )}
    </div>
  );
};

export default TotalAudienceBlock;

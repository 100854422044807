import InfoIcon from '@mui/icons-material/InfoOutlined';
import tokens from 'tokens';
import {
  AUDIENCE_STATUS_HEADER_TITLE,
  EXPORT_STATUS_HEADER_TITLE,
} from '../../../constants';
import { Tooltip, makeStyles } from '@material-ui/core';
import { getAllAudienceStatus } from 'utils/audienceExportStatus';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: tokens.colorBody,
  },
  tooltip: {
    backgroundColor: 'white',
    border: `1px solid black`,
    color: 'black',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px 0px 5px',
  },
  tooltipHeader: {
    fontWeight: 'bold',
    fontSize: '16px',
    paddingBottom: '5px',
    lineHeight: '18px',
    fontFamily: 'Calibre-Regular, sans-serif',
  },
  tooltipItemHeader: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: 'Calibre-Regular, sans-serif',
  },
  tooltipItemText: {
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: 'Calibre-Regular, sans-serif',
  },
  icon: {
    color: 'gray',
    padding: '2px',
    marginTop: '1px',
    cursor: 'pointer',
  },
}));

/**
 * Help to render UI while hovering on status info icon
 */
const TooltipDetails = () => {
  const classes = useStylesBootstrap();
  const statusDetails = getAllAudienceStatus();

  return (
    <div className={classes.tooltipContainer}>
      <div className={classes.tooltipHeader}>{EXPORT_STATUS_HEADER_TITLE}</div>
      <ul style={{ marginLeft: '-20px' }}>
        {Object.entries(statusDetails).map(([key, value]) => {
          return (
            <li key={key} style={{ margin: '5px 0px' }}>
              <span className={classes.tooltipItemHeader}>{key}</span>
              {' - '}
              <span
                className={classes.tooltipItemText}
                dangerouslySetInnerHTML={{ __html: value }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

/**
 * @returns return Status column header UI for Audience manager list view table
 */
const AudienceStatusHeader = () => {
  const classes = useStylesBootstrap();

  return (
    <div style={{ display: 'flex' }}>
      <span> {AUDIENCE_STATUS_HEADER_TITLE} </span>
      {'    '}
      <Tooltip
        arrow
        classes={classes}
        title={<TooltipDetails />}
        placement="right"
      >
        <InfoIcon
          className={classes.icon}
          style={{ fontSize: '20px', cursor: 'pointer' }}
        />
      </Tooltip>
    </div>
  );
};

export default AudienceStatusHeader;

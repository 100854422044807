import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';

export const StyledTableCell = styled(TableCell)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43rem;
  padding-top: 0.175rem;
  padding-bottom: 0.175rem;
`;

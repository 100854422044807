import Field from 'components/Field';
import { Button } from '@klover/attain-design-system';
import { Formik } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useMemo, useState } from 'react';

// import Collapsible from 'components/Collapsible';
import Option from 'components/Option';
import * as Styled from './index.styles';

import {
  SalesLiftStudy,
  fetchAllGroups,
  fetchGroup,
  fetchSalesLiftStudy,
  removeSalesLiftStudy,
  searchSalesLifts,
  setIsDisabled,
} from './slice';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import OfferIcon from '@material-ui/icons/LocalOfferRounded';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Page Components
import Header from 'components/Header';
import Table from 'components/Table';
import {
  // Offer,
  OfferGroup,
  removeSalesLiftStudyFromCurrentGroup,
} from '../offerWallManager/slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    salesLiftColumnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'top',
      width: '100%',
      padding: '2em',
      borderBottom: '1px solid rgb(232 232 232)',
    },
    root: {
      width: '100%',
    },
    salesLiftStudyListContainer: {
      flexGrow: 1,
    },
    salesLiftStudyActive: {
      backgroundColor: '#3E9D86',
      color: '#fff',
      height: 24,
      width: 24,
    },
    salesLiftStudyIcon: {
      height: 18,
      width: 18,
    },
    salesLiftStudyInactive: {
      backgroundColor: '#F9F9F9',
      height: 24,
      width: 24,
    },
    salesLiftStudyListItem: {
      borderBottom: '1px solid rgb(232 232 232)',
      height: 176,
    },
    salesLiftStudyNameButton: {
      flexWrap: 'wrap',
    },
    createGroup: {
      backgroundColor: '#F9F9F9',
      bottom: 0,
      height: 68,
      padding: 16,
      position: 'fixed',
      width: 248,
    },
    divider: {
      height: 2,
    },
    offerRowStyling: {
      borderTop: '1px solid rgb(232 232 232)',
    },
    groupList: {
      marginBottom: 'auto',
      maxHeight: '500px',
      minHeight: '400px',
      overflow: 'scroll',
    },
    header: {
      display: 'flex',
      height: '80px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '100%',
    },
    noSalesLiftStudies: {
      minHeight: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    searchSalesLiftStudy: {
      margin: 16,
    },
    searchGroup: {
      margin: 8,
      marginTop: 16,
    },
    subNav: {
      minWidth: 250,
    },
    verticalDivider: {
      borderRight: '2px solid rgb(232 232 232)',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const NoCurrentGroup = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noSalesLiftStudies}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <OfferIcon />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            Create or select a group
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            Start by clicking &quot;Create Group&quot;.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const SalesLiftStudyManager = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const [allGroups, setAllGroups] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groupId, setGroupId] = useState<string | number>(
    searchParams.get('groupId') || 'all'
  );

  const salesLiftStudiesReducer = useAppSelector((state) => {
    return state.salesLiftStudiesReducer;
  });

  useEffect(() => {
    dispatch(fetchAllGroups());
  }, []);

  useEffect(() => {
    if (groupId && groupId != 'all') {
      const curGroup = salesLiftStudiesReducer.entities.groups.byId[groupId];
      if (curGroup) {
        setCurrentGroup(curGroup);
        setGroupId(curGroup.id);
        dispatch(fetchGroup({ id: groupId }));
      }
    } else {
      dispatch(searchSalesLifts(''));
      setAllGroups(true);
    }

    updateURL(groupId);
  }, [groupId]);

  const salesLiftStudies = useAppSelector((state) => {
    const studies = state.salesLiftStudiesReducer.entities.studies.allIds.map(
      (id) => {
        return state.salesLiftStudiesReducer.entities.studies.byId[id];
      }
    );

    if (search || allGroups) {
      return studies.filter(
        ({ name }) => name && name.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (currentGroup) {
      const result = studies.filter(
        (x) => x.offergroupname === currentGroup.name
      );
      return result;
    }
    return [];
  });

  const groupsLoading = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.groupsLoading
  );

  const groupLoading = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.groupLoading
  );

  const groups = useAppSelector((state) => {
    return state.salesLiftStudiesReducer.entities.groups.allIds
      .map((id) => state.salesLiftStudiesReducer.entities.groups.byId[id])
      .filter((group) => {
        if (name !== '') {
          return group.name.toLowerCase().includes(name);
        } else {
          return true;
        }
      });
  });

  const fetchSalesLiftStudiesWithFetchGroup = (id: string | number) => {
    setGroupId(id);
    setAllGroups(id ? false : true);
  };

  const updateURL = (groupId: string | number) => {
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?groupId=${groupId}`
    );
  };

  const isGroupsLoading = groupsLoading.state === 'loading';

  const areSalesLiftStudiesLoading = groupLoading.state === 'loading';

  return (
    <Box className={classes.root}>
      <Header
        title="Sales Lift Studies"
        centerBorder={true}
        center={
          !allGroups
            ? currentGroup && (
                <Typography component="h2" variant="h6">
                  {currentGroup.name}
                </Typography>
              )
            : null
        }
        action={
          <Styled.ButtonSpacing>
            {currentGroup && !allGroups ? (
              <Button
                variant="contained"
                onClick={() => {
                  history.push(`/main/sales-lift-studies/creator`);
                }}
              >
                Create Sales Lift Study
              </Button>
            ) : null}
          </Styled.ButtonSpacing>
        }
      />

      <Box height="100%" padding={2}>
        <Styled.SearchArea>
          {isGroupsLoading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <>
              <Styled.SelectOfferGroup
                placeholder="Select Sales Lift Study Group"
                onChange={(id) => fetchSalesLiftStudiesWithFetchGroup(id)}
                value={groupId}
              >
                <Option button value="all">
                  All Sales Lifts
                </Option>
                {groups && groups.length
                  ? groups.map((group: OfferGroup) => (
                      <Option button value={group.id} key={group.id}>
                        {group.name}
                      </Option>
                    ))
                  : null}
              </Styled.SelectOfferGroup>
              <Formik>
                {() => (
                  <Styled.Form onSubmit={(e) => e.preventDefault()}>
                    <Field
                      name="searchSalesLifts"
                      placeholder="Search Sales Lifts"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </Styled.Form>
                )}
              </Formik>
            </>
          )}
        </Styled.SearchArea>

        {areSalesLiftStudiesLoading ? (
          <LoadingSalesLiftStudies />
        ) : (
          <div className={classes.salesLiftStudyListContainer}>
            {salesLiftStudies.length > 0 ? (
              <SalesLiftStudyListTable
                salesLiftStudies={salesLiftStudies}
                groupId={groupId}
              />
            ) : (
              <>
                {currentGroup ? (
                  <NoSalesLiftStudies search={search} />
                ) : (
                  <NoCurrentGroup />
                )}
              </>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
};

const SalesLiftStudyListTable = ({
  salesLiftStudies,
  groupId,
}: {
  salesLiftStudies: Array<SalesLiftStudy>;
  groupId: string | number;
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const isDisabled = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.isDisabled
  );

  const searchResults = salesLiftStudies
    .filter((salesLiftStudy: SalesLiftStudy) => {
      if (salesLiftStudy) {
        if (name !== '') {
          return salesLiftStudy.name.toLowerCase().includes(name);
        } else {
          return true;
        }
      } else {
        return false;
      }
    })
    .filter((x) => Object.keys(x).length);

  const deleteSalesLifts = () => {
    if (isDisabled) {
      const salesLiftsToDelete = Object.keys(isDisabled);
      for (const salesliftid of salesLiftsToDelete) {
        dispatch(removeSalesLiftStudyFromCurrentGroup(salesliftid));
        dispatch(removeSalesLiftStudy(salesliftid));
        dispatch(setIsDisabled(salesliftid));
      }
    }
  };

  useEffect(() => {
    deleteSalesLifts();
  }, []);

  const data = useMemo(
    () => searchResults && searchResults.filter((x) => x),
    [searchResults]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Sales Lift Study Name',
        accessor: 'name',
        Cell: (row) => (
          <Button
            color="primary"
            size="large"
            disabled={
              isDisabled && isDisabled[row.cell.row.original.salesliftstudyid]
                ? true
                : false
            }
            onClick={() => {
              dispatch(fetchSalesLiftStudy(row.cell.row.original));
              history.push(
                `/main/sales-lift-studies/editor/${row.cell.row.original.salesliftstudyid}`
              );
            }}
            className={classes.salesLiftStudyNameButton}
          >
            {row && row.cell.value ? row.cell.value : null}
          </Button>
        ),
      },
      {
        Header: 'Start Date',
        accessor: 'startdate',
        Cell: (row) => (
          <>
            {row.cell.value !== undefined && row.cell.value !== 0 && (
              <>
                {new Date(Number(row.cell.value)).toLocaleDateString('en-US')}
              </>
            )}
          </>
        ),
      },
      {
        Header: 'End Date',
        accessor: 'enddate',
        Cell: (row) => (
          <>
            {row.cell.value !== undefined && row.cell.value !== 0 && (
              <>
                {new Date(Number(row.cell.value)).toLocaleDateString('en-US')}
              </>
            )}
          </>
        ),
      },
      {
        Header: 'Lapsed Period',
        accessor: 'lapsedperiod',
        Cell: (row) => (
          <>
            {row.cell && row.cell.value
              ? row.cell.value.toLocaleString() + ' Days'
              : ''}
          </>
        ),
      },
      {
        Header: 'Attribution Window',
        accessor: 'attributionwindow',
        Cell: (row) => (
          <>
            {row.cell && row.cell.value
              ? row.cell.value.toLocaleString() + ' Days'
              : ''}
          </>
        ),
      },
      // {
      //   Header: 'Offers',
      //   accessor: 'offers',
      //   Cell: () => () => (
      //     <Styled.OffersColumn>
      //       <Collapsible
      //         header="Put first offer here"
      //         content="Put other offers here"
      //       />
      //     </Styled.OffersColumn>
      //   ),
      // },
    ],
    []
  );

  return <Table columns={columns} data={data} />;
};

const NoSalesLiftStudies = ({ search }) => {
  const classes = useStyles();

  return (
    <Box className={classes.noSalesLiftStudies}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>{!search ? <OfferIcon /> : null}</Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            {search ? (
              'No sales lift studies with this query.'
            ) : (
              <>This group doesn&apos;t have any sales lift studies yet.</>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            {!search ? (
              <>Start by clicking &quot;Create Sales Lift Study&quot;.</>
            ) : null}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const LoadingSalesLiftStudies = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noSalesLiftStudies}>
      <CircularProgress color="primary" size={40} />
    </Box>
  );
};

export default SalesLiftStudyManager;

import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import * as Styled from './index.styles';

interface Props {
  totalNotifications: number;
  filterRead: boolean;
  setFilterRead: (filterRead: boolean) => void;
  filterEmail: boolean;
  setFilterEmail: (filterEmail: boolean) => void;
}
const NotificationsOptionsBar = (props: Props) => {
  const toggleFilterRead = () => {
    props.setFilterRead(!props.filterRead);
  };
  const toggleFilterEmail = () => {
    props.setFilterEmail(!props.filterEmail);
  };
  return (
    <Styled.StyledPaper elevation={1}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Grid container direction="row" columnGap={5}>
            <Grid item>
              <FormControlLabel
                label="Your notifications only"
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={props.filterEmail}
                    onChange={toggleFilterEmail}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Show unread only"
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={props.filterRead}
                    onChange={toggleFilterRead}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          Loaded: {props.totalNotifications}
        </Grid>
      </Grid>
    </Styled.StyledPaper>
  );
};

export default NotificationsOptionsBar;

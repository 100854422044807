import _ from 'lodash';
import nanoid from 'utils/nanoid';
import { IconButton, Select } from '@klover/attain-design-system';
import { Merchants } from '../../audienceManager/currentConditionOptions/merchants';
import { setTargetTransactions } from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';

// material UI
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Autocomplete } from '@material-ui/lab';
import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

// Components
import AudienceBlock from '../audienceBlock';
import TotalAudienceBlock from '../totalAudienceBlock';
import UpDownArrow from 'components/upDownArrow';

const useStyles = makeStyles(() => ({
  dimensionContainer: {
    padding: '2em',
  },
  buttonContainer: {
    paddingTop: '2.5em',
  },
  icon: {
    height: 24,
    width: 24,
  },
  moreVertIcon: {
    display: 'flex',
    paddingLeft: '0.5em',
  },
  conditionsContainerWithTitle: {
    // paddingBottom: '2.5em',
    width: '100%',
  },
  conditionColumnTitles: {
    paddingLeft: '2.3em',
    paddingBottom: '1em',
    display: 'flex',
  },
  upDownArrow: {
    paddingTop: '2.8em',
  },
  dimensionConnector: {
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EEF2FA',
    color: '#2C6EEA',
    padding: '0.2em 0.8em',
    borderRadius: '5px',
    fontSize: 15,
  },
  totalAudienceBlockContainer: {
    width: '100%',
    backgroundColor: '#F4F6F9',
    padding: '1em',
  },
  conditionsContainer: {
    borderLeft: '4px solid #DADEE3',
    paddingLeft: '2em',
  },
  targetTransactionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  conditionRowContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1em',
    justifyContent: 'space-between',
  },
  lastConditionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  conditionRowBox1: {
    display: 'flex',
    alignItems: 'center',
  },
  rightPadding: {
    paddingRight: '1em',
  },
  conditionSelect: {
    width: 200,
  },
  operatorSelect: {
    width: 90,
  },
  qualifierSelect: {
    width: 300,
  },
  conditionSelectTitle: {
    width: 215,
  },
  operatorSelectTitle: {
    width: 105,
  },
  qualifierSelectTitle: {
    width: 300,
  },
}));

const themeOverrides = createTheme({
  overrides: {
    MuiSelect: {
      selectMenu: {
        padding: '1.2em 1em',
      },
    },
    MuiCircularProgress: {
      root: {
        maxHeight: 20,
        maxWidth: 20,
      },
      colorPrimary: {
        color: '#ffffff',
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
  },
});

const targetTransactionConditions = {
  merchantName: 'Merchant Name',
};

const targetTransactionOperators = {
  isnot: 'is not',
  is: 'is',
  // contains: 'contains',
  // doesnotcontain: 'does not contain',
};

const defaultTargetTransactionDimension = {
  category: 'targetTransactions',
  connector: 'OR',
  conditions: [],
};

const defaultTargetTransactionCondition = {
  condition: 'merchantName',
  operator: 'is',
  qualifier: '',
};

const MoreVertMenu = ({
  index,
  salesLiftDimension,
}: {
  index: number;
  salesLiftDimension: any;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (index) => {
    const cloneOfSalesLiftDimension = _.clone(salesLiftDimension);
    if (cloneOfSalesLiftDimension && cloneOfSalesLiftDimension.conditions) {
      const cloneOfConditions = _.clone(cloneOfSalesLiftDimension.conditions);
      if (index === 0 && cloneOfSalesLiftDimension.conditions.length === 1) {
        const cloneOfZeroithIndex = _.clone(cloneOfConditions[0]);
        cloneOfZeroithIndex.qualifier = '';
        cloneOfZeroithIndex.audienceSize = 0;
        cloneOfSalesLiftDimension.conditions = [cloneOfZeroithIndex];
      } else {
        cloneOfSalesLiftDimension.conditions = cloneOfConditions
          .slice(0, index)
          .concat(cloneOfConditions.slice(index + 1));
      }
      if (
        cloneOfSalesLiftDimension.conditions.length == 1 &&
        !cloneOfSalesLiftDimension.conditions[0].qualifier
      )
        cloneOfSalesLiftDimension.totalAudienceSize = null;
    }
    dispatch(setTargetTransactions(cloneOfSalesLiftDimension));
  };
  return (
    <div className={classes.moreVertIcon}>
      <div>
        <IconButton size="small" onClick={handleClick}>
          <MoreVertIcon className={classes.icon} />
        </IconButton>
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleDelete(index)}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" className={classes.icon} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

const TargetTransactions = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  const currentSalesLiftStudyDimensions =
    currentSalesLiftStudy && currentSalesLiftStudy.dimensions
      ? currentSalesLiftStudy.dimensions
      : [];
  const filteredSalesLiftStudyDimensionsForTargetTransactions =
    currentSalesLiftStudyDimensions.filter(
      (dimension) => dimension.category == 'targetTransactions'
    );
  const queryLoading = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.queryLoading
  );
  const salesLiftDimension =
    filteredSalesLiftStudyDimensionsForTargetTransactions.length
      ? filteredSalesLiftStudyDimensionsForTargetTransactions[0]
      : null;
  // const salesLiftDimensionConditions = salesLiftDimension && salesLiftDimension.conditions ? salesLiftDimension.conditions : null
  const concatTargetTransactionsIntoDimensions = () => {
    if (currentSalesLiftStudy && currentSalesLiftStudyDimensions) {
      dispatch(
        setTargetTransactions({
          ...defaultTargetTransactionDimension,
          salesliftstudyid: currentSalesLiftStudy.salesliftstudyid,
        })
      );
    }
  };
  const concatTargetTransactionConditionIntoDimensionConditions = () => {
    const cloneOfSalesLiftDimension = _.clone(salesLiftDimension);
    if (cloneOfSalesLiftDimension && cloneOfSalesLiftDimension.conditions) {
      cloneOfSalesLiftDimension.conditions =
        cloneOfSalesLiftDimension.conditions.concat([
          {
            ...defaultTargetTransactionCondition,
            salesliftconditionid: nanoid(),
          },
        ]);
    }
    dispatch(setTargetTransactions(cloneOfSalesLiftDimension));
  };

  useEffect(() => {
    if (!filteredSalesLiftStudyDimensionsForTargetTransactions.length)
      concatTargetTransactionsIntoDimensions();
    if (salesLiftDimension && salesLiftDimension.conditions) {
      if (!salesLiftDimension.conditions.length) {
        concatTargetTransactionConditionIntoDimensionConditions();
      } else {
        const emptySalesLiftConditions = salesLiftDimension.conditions.filter(
          (x) => !x.qualifier
        );
        if (!emptySalesLiftConditions.length) {
          concatTargetTransactionConditionIntoDimensionConditions();
        }
      }
    }
  }, [currentSalesLiftStudy]);

  // const changeConnector = () => {
  //   const cloneOfSalesLiftDimension = _.clone(salesLiftDimension)
  //   if (salesLiftDimension && salesLiftDimension.connector) {
  //     if (salesLiftDimension.connector === 'AND') {
  //       cloneOfSalesLiftDimension.connector = 'OR'
  //     } else {
  //       cloneOfSalesLiftDimension.connector = 'AND'
  //     }
  //   }
  //   dispatch(setTargetTransactions(cloneOfSalesLiftDimension))
  // }

  const changeOperator = (operator, salesliftconditionid) => {
    // cloning the sales lift dimension
    const cloneOfSalesLiftDimension = _.clone(salesLiftDimension);
    // checking if saleslift dimension conditions are true
    if (
      cloneOfSalesLiftDimension &&
      cloneOfSalesLiftDimension.conditions &&
      cloneOfSalesLiftDimension.conditions.length
    ) {
      // cloning the sales lift dimension conditions
      let cloneOfSalesLiftDimensionConditions = _.clone(
        cloneOfSalesLiftDimension.conditions
      );
      // setting the sales lift dimension conditions to a new array where we map over the clone of sales lift dimesnion conditions
      cloneOfSalesLiftDimensionConditions =
        cloneOfSalesLiftDimensionConditions.map((condition) => {
          // clone the condition
          const cloneOfCondition = _.clone(condition);
          // if the condition's salesliftconditionid equals to the salesliftconditionid in the parameters
          if (
            condition &&
            condition.salesliftconditionid === salesliftconditionid
          ) {
            // set the operator to the operator in the parameters
            cloneOfCondition.operator = operator;
            delete cloneOfCondition.audienceSize;
          }
          // return the updated clone of condition which will insert into the array
          return cloneOfCondition;
        });
      // set the cloneof salesliftdimensionconditions to the cloneofsalesliftdimensionconditions
      cloneOfSalesLiftDimension.conditions =
        cloneOfSalesLiftDimensionConditions;
    }
    dispatch(setTargetTransactions(cloneOfSalesLiftDimension));
  };

  const changeQualifier = (qualifier, salesliftconditionid) => {
    const cloneOfSalesLiftDimension = _.clone(salesLiftDimension);
    if (
      cloneOfSalesLiftDimension &&
      cloneOfSalesLiftDimension.conditions &&
      cloneOfSalesLiftDimension.conditions.length
    ) {
      let cloneOfSalesLiftDimensionConditions = _.clone(
        salesLiftDimension.conditions
      );
      cloneOfSalesLiftDimensionConditions =
        cloneOfSalesLiftDimensionConditions.map((condition) => {
          const cloneOfCondition = _.clone(condition);
          if (
            condition &&
            condition.salesliftconditionid === salesliftconditionid
          ) {
            cloneOfCondition.qualifier = qualifier;
            delete cloneOfCondition.audienceSize;
          }
          return cloneOfCondition;
        });
      // we have not updated the state, but we have set up the next state
      if (qualifier) {
        const mostUpToDateConditions = cloneOfSalesLiftDimension.conditions;
        const conditionsWithNoQualifiers = mostUpToDateConditions.filter(
          (condition) =>
            !condition.qualifier &&
            condition.salesliftconditionid !== salesliftconditionid
        );
        const anyEmptyQualifiers = conditionsWithNoQualifiers.length
          ? true
          : false;
        if (!anyEmptyQualifiers) {
          cloneOfSalesLiftDimensionConditions.push({
            ...defaultTargetTransactionCondition,
            salesliftconditionid: nanoid(),
          });
        }
      }
      cloneOfSalesLiftDimension.conditions =
        cloneOfSalesLiftDimensionConditions;
    }
    dispatch(setTargetTransactions(cloneOfSalesLiftDimension));
  };

  return (
    <ThemeProvider theme={themeOverrides}>
      <div>
        <div className={classes.dimensionContainer}>
          <div className={classes.targetTransactionContainer}>
            <div className={classes.buttonContainer}>
              <Button>
                {salesLiftDimension && salesLiftDimension.connector && (
                  <div className={classes.dimensionConnector}>
                    {salesLiftDimension.connector}
                  </div>
                )}
              </Button>
            </div>
            <div className={classes.upDownArrow}>
              <UpDownArrow />
            </div>
            <div className={classes.conditionsContainerWithTitle}>
              <div className={classes.conditionColumnTitles}>
                <div className={classes.conditionSelectTitle}>CONDITION</div>
                <div className={classes.operatorSelectTitle}>OPERATOR</div>
                <div className={classes.qualifierSelectTitle}>
                  QUALIFIER/VALUE
                </div>
              </div>
              <div className={classes.conditionsContainer}>
                {salesLiftDimension &&
                salesLiftDimension.conditions &&
                salesLiftDimension.conditions.length
                  ? salesLiftDimension.conditions.map((conditionRow, i) => {
                      return (
                        <div
                          key={nanoid()}
                          className={
                            classes[
                              i == salesLiftDimension.conditions.length - 1
                                ? 'lastConditionRow'
                                : 'conditionRowContainer'
                            ]
                          }
                        >
                          <div className={classes.conditionRowBox1}>
                            <div className={classes.rightPadding}>
                              <Select
                                className={classes.conditionSelect}
                                variant="outlined"
                                value={conditionRow.condition}
                              >
                                {Object.keys(targetTransactionConditions).map(
                                  (condition) => {
                                    return (
                                      <MenuItem
                                        key={nanoid()}
                                        value={condition}
                                      >
                                        {targetTransactionConditions[condition]}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </div>
                            <div className={classes.rightPadding}>
                              <Select
                                className={classes.operatorSelect}
                                variant="outlined"
                                onChange={(e) => {
                                  changeOperator(
                                    e.target.value,
                                    conditionRow.salesliftconditionid
                                  );
                                }}
                                value={conditionRow.operator}
                              >
                                {Object.keys(targetTransactionOperators).map(
                                  (operator) => {
                                    return (
                                      <MenuItem key={nanoid()} value={operator}>
                                        {targetTransactionOperators[operator]}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </div>
                            <div>
                              <Autocomplete
                                className={classes.qualifierSelect}
                                value={conditionRow.qualifier || ''}
                                options={Merchants}
                                onChange={(event, qualifier) => {
                                  changeQualifier(
                                    qualifier,
                                    conditionRow.salesliftconditionid
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    style={{ fontSize: 10 }}
                                    label={'Merchant Name'}
                                    {...params}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </div>
                            <MoreVertMenu
                              index={i}
                              salesLiftDimension={salesLiftDimension}
                            />
                          </div>
                          <AudienceBlock
                            queryLoading={queryLoading}
                            conditionRow={conditionRow}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.totalAudienceBlockContainer}>
          <TotalAudienceBlock
            dimension={salesLiftDimension}
            queryLoading={queryLoading}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default TargetTransactions;

import _ from 'lodash';
import camelizeKeys from './camelizeKeys';
import reformatAudienceObject from './reformatAudienceObject';

const compareAudiences = (originalAudience, currentAudience) => {
  try {
    if (!(originalAudience && currentAudience)) return false;
    const originalAudienceNoTime = reformatAudienceObject(originalAudience);
    const camelizedOriginalAudience = camelizeKeys(originalAudienceNoTime);
    const camelizedCurrentAudience = camelizeKeys(currentAudience);
    if (camelizedOriginalAudience.groupId !== camelizedCurrentAudience.groupId)
      return false;
    if (camelizedOriginalAudience.id !== camelizedCurrentAudience.id)
      return false;
    if (camelizedOriginalAudience.name !== camelizedCurrentAudience.name)
      return false;
    const sortedOriginalDimensionGroups =
      camelizedOriginalAudience.dimensionGroups.sort(
        (a, b) => a.dimension1Id - b.dimension1Id
      );
    const sortedCurrentDimensionGroups =
      camelizedCurrentAudience.dimensionGroups.sort(
        (a, b) => a.dimension1Id - b.dimension1Id
      );
    if (
      !(
        sortedCurrentDimensionGroups.length ==
        sortedOriginalDimensionGroups.length
      )
    )
      return false;
    for (let i = 0; i < sortedOriginalDimensionGroups.length; i++) {
      const originalDimensionGroup = sortedOriginalDimensionGroups[i];
      const currentDimensionGroup = sortedCurrentDimensionGroups[i];
      if (!_.isEqual(originalDimensionGroup, currentDimensionGroup))
        return false;
    }
    const sortedOriginalDimensions = camelizedOriginalAudience.dimensions.sort(
      (a, b) => a.id - b.id
    );
    const sortedCurrentDimensions = camelizedCurrentAudience.dimensions.sort(
      (a, b) => a.id - b.id
    );
    if (!(sortedOriginalDimensions.length == sortedCurrentDimensions.length))
      return false;
    for (let i = 0; i < sortedOriginalDimensions.length; i++) {
      const originalDimension = sortedOriginalDimensions[i];
      const currentDimension = sortedCurrentDimensions[i];
      if (
        !_.isEqual(
          _.omit(originalDimension, ['conditions', 'audienceSize']),
          _.omit(currentDimension, ['conditions', 'audienceSize'])
        )
      )
        return false;
      const originalDimensionConditions = originalDimension.conditions.sort(
        (a, b) => a.id - b.id
      );
      const currentDimensionConditions = currentDimension.conditions.sort(
        (a, b) => a.id - b.id
      );
      if (
        originalDimensionConditions.length !== currentDimensionConditions.length
      )
        return false;
      for (let j = 0; j < originalDimensionConditions.length; j++) {
        const originalCondition = originalDimensionConditions[j];
        const currentCondition = currentDimensionConditions[j];
        if (!_.isEqual(originalCondition, currentCondition)) return false;
      }
    }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export default compareAudiences;

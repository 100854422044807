import styled from 'styled-components';
import tokens from 'tokens';

// Material UI Components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

export const StyledBodyDialogContent = styled(DialogContent)`
  padding: 24px 32px 8px 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`;

export const StyledBoldSpan = styled.span`
  font-weight: bolder;
`;

export const StyledTypographyTitle = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  padding-left: 16px;
  padding-top: 16px;
`;

export const StyledDialog = styled(Dialog)`
  margin: ${tokens.spacing.xl};
`;

// import { string } from 'yup';
import React from 'react';
import * as Styled from './index.styles';

interface ToggleProps {
  label?: string;
  name?: string;
  checked: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
  onChange?: (checked: boolean) => void;
}

const ToggleSwitch = (props: ToggleProps) => {
  React.useEffect(() => {
    if (props.onChange) props.onChange(props.checked);
  }, [props.checked]);
  return (
    <Styled.Wrapper>
      <Styled.TextLabel>{props.label}</Styled.TextLabel>
      <Styled.Toggle {...props}>
        <Styled.Checkbox
          type="checkbox"
          {...props}
          checked={props.checked}
          onChange={(e) => e.preventDefault()}
        />
        <Styled.Label htmlFor={props.name}>
          <Styled.Inner />
          <Styled.Switch />
        </Styled.Label>
      </Styled.Toggle>
    </Styled.Wrapper>
  );
};

export default ToggleSwitch;

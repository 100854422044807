import styled from 'styled-components';
import tokens from 'tokens';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TextLabel = styled.div`
  ${tokens.typography.body}
  display: inline-block;
`;

export const Toggle = styled.div`
  position: relative;
  width: 40px;
  display: inline-block;
  text-align: left;
`;

export const Label = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 12px;
`;

export const Inner = styled.span`
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.1s ease-in 0s;

  &:before,
  &:after {
    background: ${tokens.colorBrandprimary};
    content: '';
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
  }

  &:after {
    background: ${tokens.colorGray40};
  }
`;

export const Switch = styled.span`
  display: block;
  width: 16px;
  margin: ${tokens.spacing.xs};
  background: ${tokens.colorBodynegative};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  border-radius: 12px;
  transition: all 0.1s ease-in 0s;
`;

export const Checkbox = styled.input`
  display: none;

  &:checked + ${Label} ${Inner} {
    margin-left: 0;
  }
  &:checked + ${Label} ${Switch} {
    right: 0;
  }
`;

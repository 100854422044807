import FilterPanel from '../filterPanel';
import GhostLoader from 'react-ghost-loader';
import Header from 'components/Header';
import React, { createContext, useEffect, useState } from 'react';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Box, CircularProgress } from '@material-ui/core';
import { DotsSixVertical } from '@phosphor-icons/react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Grid } from '@mui/material';
import { Question } from 'interfaces/quickQuestionsManager';
import { UseState } from 'interfaces';
import * as Styled from './index.styles';
import * as StyledTable from 'components/Table/index.styles';

const MovableQuestions = () => {
  const { questions, setQuestions, dragDisabled } = React.useContext(
    OrderQuestionsContext
  );
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    const sourceIndex = source.index;
    const destinationIndex = destination ? destination.index : sourceIndex;
    const newItems = [...questions];
    const [removed] = newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, removed);
    setQuestions(newItems);
    const questionids = newItems
      .map(({ questionid }) => questionid)
      .filter((x) => x);
    getAuthorizedAxiosInstance()
      .then((axiosInstance) => {
        axiosInstance
          ?.post(`${API_BASE_ADDRESS}/questions/reorder`, {
            questionids,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {questions.length ? (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="availableOffers">
              {(droppableProvided) => (
                <StyledTable.Table ref={droppableProvided.innerRef}>
                  {questions.map((question, index) => (
                    <Draggable
                      key={question.questionid}
                      draggableId={question.questionid}
                      index={index}
                      isDragDisabled={dragDisabled}
                    >
                      {(draggableProvided) => (
                        <Styled.TableBodyRow
                          key={index}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <Styled.TableCell>
                            <>
                              <DotsSixVertical
                                size={20}
                                weight="bold"
                                style={{ marginRight: 5 }}
                              />
                              {question.questiontext
                                ? question.questiontext
                                : 'N/A'}
                            </>
                          </Styled.TableCell>
                        </Styled.TableBodyRow>
                      )}
                    </Draggable>
                  ))}
                </StyledTable.Table>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <Styled.JustifyCenter>
          <div>No Questions Available</div>
        </Styled.JustifyCenter>
      )}
    </>
  );
};

export interface OrderQuestionsContextProps {
  questions: Question[];
  setQuestions: UseState;
  dragDisabled: boolean;
  setDragDisabled: UseState;
}

export const OrderQuestionsContext = createContext(
  {} as OrderQuestionsContextProps
);

const OrderQuestions = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionsLoading, setQuestionLoading] = useState(true);
  const [dragDisabled, setDragDisabled] = useState(false);
  const [allQuestions, setAllQuestions] = useState<Question[]>([]);

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          const res = await axiosInstance.get(`${API_BASE_ADDRESS}/questions`);
          const questions = res.data
            .filter(({ isactive }: Question) => isactive)
            .sort((a: Question, b: Question) => a.zindex - b.zindex);
          setQuestions(questions);
          setAllQuestions(questions);
          setQuestionLoading(false);
        } else {
          throw new Error('Failed to authorize request');
        }
      } catch (err) {
        console.log(err);
        setQuestionLoading(false);
      }
    };
    getQuestions();
  }, []);

  return (
    <OrderQuestionsContext.Provider
      value={{ setQuestions, questions, dragDisabled, setDragDisabled }}
    >
      <Header title="Order Questions" />
      <Grid container direction="column" flexWrap="nowrap" padding={1}>
        <Grid item>
          <FilterPanel
            allQuestions={allQuestions}
            questionsLoading={questionsLoading}
          />
        </Grid>
        <Grid item>
          <Styled.MainBodyWithSpacing>
            <Box height="100%" padding={2}>
              {!questionsLoading ? <MovableQuestions /> : <OrderLoading />}
            </Box>
          </Styled.MainBodyWithSpacing>
        </Grid>
      </Grid>
    </OrderQuestionsContext.Provider>
  );
};

const OrderLoading = () => {
  return (
    <Styled.LoadingWrapper>
      {[...Array(14)].map(() => (
        <>
          <Styled.LoadingBody>
            <GhostLoader
              height="40px"
              heightRandom={0}
              width="3%"
              widthRandom={0}
            />
            <GhostLoader
              height="40px"
              heightRandom={0}
              width="97%"
              widthRandom={0}
            />
          </Styled.LoadingBody>
        </>
      ))}
    </Styled.LoadingWrapper>
  );
};

export default OrderQuestions;

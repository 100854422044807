import React from 'react';
import { Dimension, DimensionCategoryCondition } from '../slice';

// Page Components
import DimensionCondition from '../dimensionCondition';

interface Props {
  dimension: Dimension;
  conditions: DimensionCategoryCondition[];
}

const DimensionConditions = ({ dimension, conditions }: Props) => {
  return (
    <>
      {dimension &&
        conditions
          .filter((x) => x)
          .map((condition) => (
            <DimensionCondition key={condition.id} condition={condition} />
          ))}
    </>
  );
};

export default DimensionConditions;

// All form fields leveraging formik. See docs here... https://formik.org/docs/overview

import { FieldHookConfig } from 'formik';
import * as Styled from './index.styles';

export interface ExtraProps {
  fullWidth?: boolean; // default false - makes the field fill its parent container's width
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

type CustomProps = ExtraProps & FieldHookConfig<string>;

const Field = ({ fullWidth, label, placeholder, ...props }: CustomProps) => {
  return (
    <>
      {label ? (
        <fieldset>
          <Styled.Label htmlFor={props.name}>{label}</Styled.Label>
          <Styled.Field
            fullWidth={fullWidth}
            placeholder={placeholder}
            {...props}
          />
        </fieldset>
      ) : (
        <Styled.Field
          fullWidth={fullWidth}
          placeholder={placeholder}
          {...props}
        />
      )}
    </>
  );
};

export default Field;

import tokens from 'tokens';
import { Tooltip, makeStyles } from '@material-ui/core';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: tokens.colorBody,
  },
  tooltip: {
    backgroundColor: tokens.colorBody,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default BootstrapTooltip;

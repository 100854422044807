import { StringMap } from 'interfaces';

export const Genders: StringMap = {
  '': '',
  Male: 'M',
  Female: 'F',
  Other: 'O',
};

export const GenderKeys = Object.keys(Genders);
export const GenderValues = Object.values(Genders);
export type Gender = typeof GenderKeys[number];
export type GenderValue = typeof GenderValues[number];

export const CSV_FILE_FORMAT_ERROR =
  'The CSV file should contain a single column with no header, and include only valid audience IDs';
export const VALID_FILE = 'Valid file';
export const ERROR_PROCESSING_FILE = 'Error occurred during file processing';
export const IN_PROGRESS = 'In Progress';
export const COPY_POPOVER_TITLE = 'Click to copy and view text';
export const IMPORTING_FILE = 'Importing file';
export const SUCCESS_FILE = 'Import Complete';
export const CHANGE_LOG = 'Change Log';
export const CHANGE_LOG_DATE = 'Date';
export const CHANGE_LOG_CHANGED_BY = 'Changed By';
export const CHANGE_LOG_CHANGED_FIELDS = 'Changed Fields';
export const NO_DATA = 'No Data';
export const JSON_VIEW = 'Json View';
export const FIELD_NAME = "Field Name";
export const OLD_VALUE = "Old Value";
export const NEW_VALUE = "New Value";
export const TARGETING_TESTING = 'Targeting Testing';
export const USER_ID_EMAIL = "User ID/Email";
export const CLEAR_FILTERS = "Clear Filters";
export const TEST_TARGETING = "Test Targeting";
export const DELETE_AB_TEST = "Delete A/B Test";
export const CREATE = 'Create';
export const EDIT = 'Edit';
export const SAVE = 'Save';
export const CANCEL = "Cancel";
export const DELETE = "Delete";
export const PLEASE_FILL_OUT_ALL_POSSIBLE_RESPONSES = 'Please fill out all possible responses.';
export const SUCCESSFULLY_CREATED_AB_TEST = 'Successfully created AB Test';
export const AB_TEST_NAME = "A/B Test Name";
export const EDIT_AB_TEST = "Edit A/B Test";
export const STEP_1 = "Step 1";
export const STEP_2 = "Step 2";
export const AB_TEST_1 = "A/B Test 1";
export const SELECT_PLACEMENT = "Select Placement";
export const TEST_TYPE = "Test Type";
export const RANDOM = "Random";
export const PERCENTAGE = "Percentage";
export const SLOTS = "Slots";
export const STATUS = "Status";
export const STEP_3 = "Step 3";
export const ALL_OFFERS = "All Offers";
export const SELECT_OFFER_GROUP_OPTIONAL = "Select Offer Group (optional)";
export const SEARCH_OFFERS = "Search Offers";
export const AVAILABLE_OFFERS = "Available Offers";
export const SELECTED_OFFERS = "Selected Offers";
export const CLOSE = "Close";
export const NO_ACTIVE_OFFERS = "There are no active offers right now.";
export const SELECT_OFFER_GROUP = "Select Offer Group";
export const OFFER_WALL_MANAGER = "Offer Wall Manager";
export const CREATE_OFFER = "Create Offer";
export const CREATE_ROTATION = "Create Rotation";
export const ROTATION_GROUP_NOTE = "Rotation group will take on all targeting/tracking of top item in the list.";
export const NO_OFFERS_AVAILABLE = "No offers available.";
export const THERE_DOESNT_SEEM_TO_BE_ENOUGH_OFFERS = "There doesn't seem to be enough offers.";
export const DRAG_ATLEAST_2_OFFERS = "Please drag at least 2 offers to add to a rotation.";
export const NO_OFFERS_SELECTED = "No offers selected.";
export const REMOVE_AB_TEST = "Remove A/B Test";
export const CREATIVE_ROTATION_NO_LONGER_EXISTS = "Creative rotation no longer exists.";
export const SUCCESSFULLY_DELETED_AB_TEST = "Successfully deleted A/B Test";
export const FAILED_TO_DELETE_AB_TEST = "Failed to delete A/B Test";
export const ROTATION_PERCENTAGES_VALIDATION_ERROR = "Rotation percentages must be integers and add up to 100%.";
export const ROTATION_PERCENTAGES_REQUIRED = "Rotation percentages must be filled out.";
export const ROTATION_MINIMUM_OFFERS = "Must add at least 1 offer(s) to the rotation.";
export const ERROR_FETCHING_CREATIVE_OFFERS = "Error fetching creative offers";
export const ERROR_FETCHING_OFFERS = "Error fetching offers";
export const ERROR_FETCHING_GROUPS = "Error fetching groups";
export const SHOW_OFFER_CHANGE_LOG = "Show Offer Change Log";
export const PLEASE_SAVE_TO_DUPLICATE = "Please save to duplicate";
export const ZIP_CODE_TARGETING_NOT_DUPLICATED = "Zip code targeting will not be duplicated.";
export const DUPLICATE = "Duplicate";
export const NO_CURRENT_GROUP = "No current group.";
export const UPLOADING = "Uploading";
export const AB_TESTING = "A/B Testing";
export const PLACEMENTS = "Placements";
export const CREATIVE_ROTATIONS = "Creative Rotations";
export const ORDERING = "Ordering";
export const QUESTIONS = "Questions";
export const AUDIENCE_MANAGER = "Audience Manager";
export const CUSTOM_AUDIENCES = "Custom Audiences";
export const QUICK_QUESTIONS = "Quick Questions";
export const CREATE_AUDIENCE = "Create Audience";
export const CREATE_GROUP = "Create Group";
export const SELECT_GROUP = "Select group";
export const ESTIMATED_AUDIENCE_SIZE = "Estimated Audience Size";
export const LAST_MODIFIED = "Last modified";
export const NEW_GROUP = "New Group";
export const HOW_TO_NAME_NEW_GROUP = "How do you want to name the new group?";

export const PLACEMENT_TYPE = {
  OFFER_WALL: "Offer Wall",
  DASHBOARD: "Dashboard",
  KLOVER_PLUS: "Klover+",
  ONBOARDING: "Onboarding"
};
export const OFFER_PLACEMENTS_TABLE_HEADERS = {
  NAME: "Name",
  ID: "ID",
  GROUP: "Group",
  START_DATE: "Start Date",
  END_DATE: "End Date"
};
export const OFFER_WALL_MANAGER_COLUMN_TITLES = {
  GROUP: "Group",
  OFFER_LABEL: "Offer Label",
  OFFER_ID: "ID",
  PLACEMENT: "Placement",
  LAST_MODIFIED: "Last Modified",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  POINTS: "Points",
  IMPRESSIONS: "Impressions",
  CLICKS: "Clicks",
  CONVERSION_CAP: "Conversion Cap"
};
export const OFFER_STATUS = {
  ACTIVE_INACTIVE: "Active/Inactive",
  ACTIVE: "Active",
  INACTIVE: "Inactive"
};
export const AUDIENCE_MANAGER_COLUMN_TITLES = {
  GROUP: "Group",
  NAME: "Name",
  AUDIENCE_STATUS: "Audience Status",
  TYPE: "Type",
  ESTIMATED_SIZE: "Est. Size",
  LAST_MODIFIED: "Last Modified"
};



import CreateQuickQuestion from 'pages/quickQuestionsManager/createQuickQuestion';
import NotFound from 'components/NotFound';
import OrderQuestions from 'pages/quickQuestionsManager/orderQuestions';
import QuickQuestionsManager from 'pages/quickQuestionsManager';
import React, { useState } from 'react';
import ViewQuickQuestion from 'pages/quickQuestionsManager/viewQuickQuestion';
import { QuickQuestionContext } from 'context';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const QuickQuestionPages: React.FC = () => {
  const { path } = useRouteMatch();
  const [questions, setQuestions] = useState([]);
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [showCreateQuickQuestion, setShowCreateQuickQuestion] = useState(false);
  const [showEditTargeting, setShowEditTargeting] = useState(false);
  const [showEditDetails, setShowEditDetails] = useState(false);
  return (
    <QuickQuestionContext.Provider
      value={{
        questions,
        setQuestions,
        questionsLoading,
        setQuestionsLoading,
        showCreateQuickQuestion,
        setShowCreateQuickQuestion,
        showEditTargeting,
        setShowEditTargeting,
        showEditDetails,
        setShowEditDetails,
      }}
    >
      <Switch>
        <Route exact path={`${path}/ordering`} component={OrderQuestions} />
        <Route
          exact
          path={`${path}/questions/:questionid?`}
          component={ViewQuickQuestion}
        />
        <Route exact path={path} component={QuickQuestionsManager} />
        <Route>
          <NotFound />
        </Route>
      </Switch>
      {showCreateQuickQuestion ? <CreateQuickQuestion /> : null}
    </QuickQuestionContext.Provider>
  );
};

export default QuickQuestionPages;

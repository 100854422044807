import { TextFieldProps, theme } from '@klover/attain-design-system';
import * as Styled from './TextFieldSearch.styles';

export const TextFieldSearch = ({ ...props }: TextFieldProps) => {
  delete props.InputProps?.startAdornment;

  let searchIcon = null;
  if (props.isSearchIcon) {
    searchIcon = <Styled.SearchIcon weight="bold" size={theme.icons.lg} />;
  }

  return (
    <Styled.Wrapper
      {...props}
      InputProps={{
        ...props.InputProps,
        startAdornment: searchIcon,
      }}
    />
  );
};

export default TextFieldSearch;

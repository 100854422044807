import styled from 'styled-components';
import tokens from 'tokens';
import { Box } from '@mui/material';

export const OuterContainer = styled(Box)`
  margin: ${tokens.spacing.sm};
`;

export const InnerContainer = styled(Box)`
  padding: 8px;
`;

import React from 'react';
import _ from 'lodash';
import colormap from 'colormap';
import { nanoid } from 'nanoid';
import {
  setCurrentSalesLiftDimension,
  setSalesLiftDimensionDialogOpen,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

//material ui
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';

// components
import QueryDisplay from '../queryDisplay';
import TotalAudienceBlock from '../totalAudienceBlock';

// configs
import salesLiftDimensionsConfig from '../configs/salesLiftDimensionsConfig';

const PAPER_WIDTH = '100%';

const useStyles = makeStyles((theme) => ({
  baseBar: {
    justifySelf: 'flex-end',
    padding: 2,
    backgroundColor: '#F4F6F9',
    display: 'flex',
    justifyContent: 'space-between',
  },
  columnName: {
    width: '30%',
  },
  columnValue: {
    width: '70%',
    color: 'black',
  },
  columnDetailsBody: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  dimensionsContainer: {
    padding: '0em 4em',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  defaultValueSettings: {
    color: 'black',
    display: 'flex',
    wordWrap: 'break-word',
  },
  dimensionPaper: {
    overflow: 'hidden',
    width: PAPER_WIDTH,
    display: 'flex',
    wordWrap: 'break-word',
    flexDirection: 'column',
    margin: '1em 0em',
    justifyContent: 'space-between',
  },
  formControl: {
    minWidth: 450,
  },
  fullHeightDimensionPaper: {
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: PAPER_WIDTH,
    height: '100%',
    display: 'flex',
    wordWrap: 'break-word',
    flexDirection: 'column',
  },
  inactive: {
    color: 'red',
  },
  inputBodies: {
    color: 'rgba(0, 0, 0, 0.54);',
    width: '100%',
    fontSize: 17,
    padding: 15,
  },
  header: {
    padding: '10px 12px 10px 24px',
  },
  mediaCardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  offerDetails: {
    width: '100%',
    paddingRight: '2em',
  },
  offerDetailsAndMediaCard: {
    display: 'flex',
    padding: '1em 0',
  },
  textInput: {
    paddingBottom: 15,
    width: 450,
  },
  timeframe: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxHeight: 48,
    minWidth: 120,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '20px',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleBar: {
    backgroundColor: '#3D399F',
    width: '100%',
  },
}));

const InputDisplay = ({ keyName }: { keyName: string }) => {
  const classes = useStyles();
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  switch (keyName) {
    case 'name':
      return (
        <Grid item xs={10}>
          {currentSalesLiftStudy && currentSalesLiftStudy.name}
        </Grid>
      );
    case 'lapsedperiod':
      return (
        <Grid item xs={10}>
          {currentSalesLiftStudy && currentSalesLiftStudy.lapsedperiod
            ? currentSalesLiftStudy.lapsedperiod
            : 'N/A'}{' '}
          Days
        </Grid>
      );
    case 'attributionwindow':
      return (
        <Grid item xs={10}>
          {currentSalesLiftStudy && currentSalesLiftStudy.attributionwindow
            ? currentSalesLiftStudy.attributionwindow
            : 'N/A'}{' '}
          Days
        </Grid>
      );
    case 'studydates':
      return (
        <Grid item xs={10}>
          {currentSalesLiftStudy && currentSalesLiftStudy.startdate
            ? new Date(
                Number(currentSalesLiftStudy.startdate)
              ).toLocaleDateString()
            : 'N/A'}{' '}
          -{' '}
          {currentSalesLiftStudy && currentSalesLiftStudy.enddate
            ? new Date(
                Number(currentSalesLiftStudy.enddate)
              ).toLocaleDateString()
            : 'N/A'}
        </Grid>
      );
    case 'offergroupname':
      return (
        <Grid item xs={10}>
          {currentSalesLiftStudy && currentSalesLiftStudy.offergroupname}
        </Grid>
      );
    case 'liftconversionevent':
      return (
        <Grid item xs={10}>
          {_.startCase(
            currentSalesLiftStudy && currentSalesLiftStudy.liftconversionevent
          )}
        </Grid>
      );
    case 'offers':
      return (
        <Grid item xs={10}>
          <div>
            {currentSalesLiftStudy &&
              currentSalesLiftStudy.offers.map((x) => (
                <div key={nanoid()}>{x.offername}</div>
              ))}
          </div>
        </Grid>
      );
    default:
      return (
        <Grid item xs={10} className={classes.defaultValueSettings}>
          N/A
        </Grid>
      );
  }
};

const CustomInputDisplay = ({
  customInputDisplayName,
}: {
  customInputDisplayName: string;
}) => {
  switch (customInputDisplayName) {
    case 'targetTransactions':
      return <QueryDisplay category={'targetTransactions'} />;
    default:
      return <div>No Component Exists Here</div>;
  }
};

const SingleSalesLiftDimension = ({
  className,
  titleColor,
  dimensionName,
  customComponentName,
  inputs,
}: {
  className: string;
  titleColor: string;
  dimensionName: string;
  inputs: Array<any>;
  customComponentName?: string | undefined;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const handleOpenDimensionDialog = (dimensionName: string) => {
    dispatch(setCurrentSalesLiftDimension(dimensionName));
    dispatch(setSalesLiftDimensionDialogOpen(true));
  };
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  const queryLoading = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.queryLoading
  );
  let dimension = null;
  if (
    customComponentName &&
    currentSalesLiftStudy &&
    currentSalesLiftStudy.dimensions &&
    currentSalesLiftStudy.dimensions.length
  ) {
    dimension = currentSalesLiftStudy.dimensions.filter(
      (x) => x.category === customComponentName
    )[0];
  }
  return (
    <>
      <Paper className={classes[className]}>
        <Grid>
          <Grid style={{ backgroundColor: titleColor, padding: 10 }}>
            <Typography className={classes.title}>{dimensionName}</Typography>
          </Grid>
          <Grid className={classes.inputBodies}>
            {inputs.length
              ? inputs.map(({ inputLabel, keyName }) => {
                  if (!inputLabel) return null;
                  return (
                    <div className={classes.columnDetailsBody} key={nanoid()}>
                      <div className={classes.columnName}>{inputLabel}</div>
                      <div className={classes.columnValue}>
                        <InputDisplay keyName={keyName} />
                      </div>
                    </div>
                  );
                })
              : null}
            {customComponentName ? (
              <CustomInputDisplay
                customInputDisplayName={customComponentName}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid>
          <Grid className={classes.baseBar}>
            <div>
              <Button
                color={'primary'}
                onClick={() => handleOpenDimensionDialog(dimensionName)}
              >
                Edit
              </Button>
            </div>
            <div>
              {dimension &&
              dimension.totalAudienceSize &&
              dimension.category == 'targetTransactions' ? (
                <TotalAudienceBlock
                  fontSize={20}
                  dimension={dimension}
                  queryLoading={queryLoading}
                />
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const colors = colormap({
  colormap: 'plasma',
  nshades: 20,
  format: 'hex',
  alpha: 1,
});

const SalesLiftDimensions = () => {
  const classes = useStyles();
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  let dimensions = null;
  if (
    currentSalesLiftStudy &&
    currentSalesLiftStudy.liftconversionevent === 'purchase'
  ) {
    dimensions = salesLiftDimensionsConfig;
  } else {
    dimensions = salesLiftDimensionsConfig.filter(
      (dimension) => dimension.dimensionName !== 'Target Transactions'
    );
  }
  return (
    <div className={classes.dimensionsContainer}>
      {dimensions.map((dimension, i) => (
        <SingleSalesLiftDimension
          key={nanoid()}
          className={'dimensionPaper'}
          titleColor={fade(colors[i], 0.8 - 0.15 * i)}
          dimensionName={dimension.dimensionName}
          inputs={dimension.inputs}
          customComponentName={dimension.customComponentName}
        />
      ))}
    </div>
  );
};

export default SalesLiftDimensions;

import styled from 'styled-components';

interface StackProps {
  spacing: string;
}

export const Stack = styled.div<StackProps>`
  & > *:not(:first-child) {
    margin-top: ${(props) => props.spacing};
  }
`;

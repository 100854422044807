import React, { useRef } from 'react';
import theme from 'theme';
import { IconButton } from '@klover/attain-design-system';
import { UploadStates } from 'constants/index';
import { useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';

// Material UI Components
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    buttonFormat: {
      margin: '0em 0.5em',
    },
    formControl: {
      minWidth: 450,
    },
  })
);

interface fileInputProps {
  isDisabled: boolean;
  setFile: (value: File | null) => void;
}
const FileInput = (props: fileInputProps) => {
  const { isDisabled, setFile } = props;
  const inputRef = useRef(null);
  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        accept="text/csv"
        ref={inputRef}
        onChange={(e) => {
          const file = e.target.files[0];
          setFile(file);
        }}
      />
      <Styled.StyledButton
        disabled={isDisabled}
        onClick={() => {
          inputRef.current.click();
        }}
      >
        Choose File
      </Styled.StyledButton>
    </>
  );
};

interface fileInputTypeProps {
  file: File | null;
  setFile: (value: File | null) => void;
}
const FileInputType = (props: fileInputTypeProps) => {
  const { file, setFile } = props;
  const zipStatus = useAppSelector(
    (state) => state.offerWallReducer.currentOffer?.zip_upload_status
  );
  const zips = useAppSelector(
    (state) => state.offerWallReducer.currentOffer?.zips
  );
  const isDisabled = !!(zipStatus !== UploadStates.DONE || zips?.length);
  const classes = useStyles(theme);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        alignContent="space-between"
        direction="row"
        wrap="nowrap"
        className={classes.formControl}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item className={classes.buttonFormat}>
            <FileInput isDisabled={isDisabled} setFile={setFile} />
          </Grid>
          <Grid item>
            <Typography>{file && file.name}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          {file && (
            <IconButton
              onClick={() => {
                setFile(null);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default FileInputType;

import isOfferDisabled from 'utils/isOfferDisabled';
import validateOfferInputs from './validateOfferInputs';
import { ValidationErrorMessages } from 'constants/index';
import { getEpochTime } from '../../../helper/helper';

/**
 * Responsible to check any invalid record in Offer Tune goals
 * @param offerTuneGoals : All offer tune goals
 * @param key : Prop name for data validation
 * @returns : true/false if any invalid record found
 */
const validTuneGoalsPointsProps = (offerTuneGoals: any, key: string) => {
  let res = true;
  if (offerTuneGoals.length > 0) {
    let inValidOfferTuneGoals;
    if (key === 'conversioncapvalue') {
      inValidOfferTuneGoals = offerTuneGoals.find(
        (goal: any) =>
          goal[key] &&
          goal.status !== 'delete' &&
          goal.isconversioncapactive &&
          (isNaN(goal[key]) || (!isNaN(goal[key]) && Number(goal[key] < 0)))
      );
    } else {
      inValidOfferTuneGoals = offerTuneGoals.find(
        (goal: any) =>
          goal[key] &&
          goal.status !== 'delete' &&
          (isNaN(goal[key]) || (!isNaN(goal[key]) && Number(goal[key] < 0)))
      );
    }
    res = inValidOfferTuneGoals ? false : true;
  }
  return res;
};

/**
 * Responsible to check any duplicate goalids are in Offer Tune goals
 * @param offerTuneGoals : All offer tune goals
 * @returns : object with flag of true/false and if true then goalid will be added
 */
const validateDuplicateGoalIDs = (offerTuneGoals: []) => {
  const goalIds = offerTuneGoals
    .filter(
      (goal) =>
        goal.goalid !== null && goal.goalid !== '' && goal.status !== 'delete'
    )
    .map((item) => String(item.goalid));
  const uniqueGoalIds = new Set(goalIds);
  const isDuplicate = goalIds.length !== uniqueGoalIds.size;
  return isDuplicate
    ? { isDuplicated: true, goalid: uniqueGoalIds.keys().next().value }
    : { isDuplicated: false };
};

/**
 * Responsible to check any blank Goal ID
 * @param offerTuneGoals : All offer tune goals
 * @returns : object with flag of true/false
 */
export const validateGoalIDs = (offerTuneGoals: []) => {
  const isBlankGoalId = offerTuneGoals?.find(
    (x) => x.status !== 'delete' && ['', undefined].includes(x.goalid)
  );
  return isBlankGoalId ? true : false;
};

const setSaveOfferButton = (currentOffer: any) => {
  let offerTuneGoals =
    currentOffer && currentOffer.offertunegoals
      ? currentOffer.offertunegoals
      : [];
  const isValidTuneGoalsPointsAwarded = validTuneGoalsPointsProps(
    offerTuneGoals,
    'pointsawarded'
  );

  const isValidTuneGoalsId = validTuneGoalsPointsProps(
    offerTuneGoals,
    'goalid'
  );

  const isValidTuneGoalsConversionValue = validTuneGoalsPointsProps(
    offerTuneGoals,
    'conversioncapvalue'
  );

  const isBlankTuneGoalID = validateGoalIDs(offerTuneGoals);
  const isDuplicateTuneGoalID = validateDuplicateGoalIDs(offerTuneGoals);

  const validZipsAndStates =
    currentOffer &&
    validateOfferInputs(currentOffer.states, 'states').isValid &&
    validateOfferInputs(currentOffer.zips, 'zips').isValid;

  const validPayout =
    currentOffer &&
    validateOfferInputs(currentOffer.mediarate, 'mediarate').isValid;

  const validConversionCap =
    currentOffer && validateOfferInputs(currentOffer.cap, 'cap').isValid;

  const validKloverPoints =
    currentOffer && validateOfferInputs(currentOffer.points, 'points').isValid;

  const isActive = currentOffer && currentOffer.isactive;
  const hasLabel = currentOffer && currentOffer.label;

  const isValidStartDate =
    !currentOffer?.startdate ||
    (!isActive &&
      getEpochTime(currentOffer?.startdate, 0, 0, 0, 0) > Date.now()) ||
    (isActive &&
      currentOffer?.startdate &&
      getEpochTime(currentOffer?.startdate, 0, 0, 0, 0) < Date.now())
      ? true
      : false;
  const isValidEndDate =
    !currentOffer?.enddate ||
    (currentOffer?.startdate &&
      getEpochTime(currentOffer?.startdate, 23, 59, 59, 999) <=
        getEpochTime(currentOffer?.enddate, 23, 59, 59, 999)) ||
    (!currentOffer?.startdate &&
      getEpochTime(currentOffer?.enddate, 23, 59, 59, 999) >= Date.now())
      ? true
      : false;

  const isDisabled =
    !hasLabel ||
    !validPayout ||
    !validConversionCap ||
    !validKloverPoints ||
    !isValidTuneGoalsPointsAwarded ||
    !isValidTuneGoalsId ||
    !isValidTuneGoalsConversionValue ||
    !isValidStartDate ||
    !isValidEndDate ||
    (isActive && isOfferDisabled(currentOffer)) ||
    !validZipsAndStates ||
    isBlankTuneGoalID ||
    isDuplicateTuneGoalID.isDuplicated;

  const SaveTitle = !hasLabel
    ? 'Needs a label'
    : !validZipsAndStates
    ? 'Invalid States or Zip Codes'
    : !validPayout
    ? ValidationErrorMessages.PAYOUT
    : !validConversionCap
    ? ValidationErrorMessages.CONVERSION_CAPPING
    : !validKloverPoints
    ? ValidationErrorMessages.POINTS
    : !isValidTuneGoalsId
    ? ValidationErrorMessages.TUNE_GOALS_ID
    : isBlankTuneGoalID
    ? ValidationErrorMessages.TUNE_GOALS_ID_REQUIRED
    : isDuplicateTuneGoalID.isDuplicated
    ? `Duplicate Goal ID : ${isDuplicateTuneGoalID.goalid} is not allowed`
    : !isValidTuneGoalsPointsAwarded
    ? ValidationErrorMessages.TUNE_GOALS_POINTS_AWARDED
    : !isValidTuneGoalsConversionValue
    ? ValidationErrorMessages.TUNE_GOALS_CONVERSION_VALUE
    : !isValidStartDate
    ? !isActive &&
      getEpochTime(currentOffer?.startdate, 0, 0, 0, 0) < Date.now()
      ? ValidationErrorMessages.START_DATE_SHOULD_BE_IN_FUTURE
      : ValidationErrorMessages.FUTURE_START_DATE_ACTIVE_OFFER
    : !isValidEndDate
    ? ValidationErrorMessages.END_DATE_EARLIER_THAN_START_DATE
    : isDisabled || isActive
    ? null
    : '';

  return { SaveTitle, isDisabled, isValidStartDate, isValidEndDate };
};

export default setSaveOfferButton;

// Leveraging the Reach Dialog component. Docs here... https://reach.tech/dialog

import React from 'react';
import VisuallyHidden from 'components/VisuallyHidden';
import { Close } from '@material-ui/icons';
import { DialogProps as ReachDialogProps } from '@reach/dialog';
import * as Styled from './index.styles';

interface DialogProps extends ReachDialogProps {
  title: string;
  children: React.ReactNode | string;
  buttons?: React.ReactNode;
  showClose?: boolean;
  onCloseClick?: () => void;
}

const Dialog = ({
  title,
  children,
  buttons,
  showClose = false,
  onCloseClick,
  ...props
}: DialogProps) => {
  return (
    <Styled.Dialog {...props}>
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        {showClose && (
          <Styled.Close onClick={onCloseClick}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>
              <Close />
            </span>
          </Styled.Close>
        )}
      </Styled.Header>

      <Styled.Content>{children}</Styled.Content>
      <Styled.Buttons>{buttons}</Styled.Buttons>
    </Styled.Dialog>
  );
};

export default Dialog;

import styled from 'styled-components';

export const AudienceName = styled.div`
  margin-left: 0px;
  font-size: 16px;
  max-width: 190px;
  white-space: normal;
  text-align: start;
`;

export const Taxonomy = styled.div`
  font-size: 16px;
  max-width: 180px;
  white-space: normal;
  text-align: start;
`;

export const FileName = styled.div`
  white-space: normal;
  text-align: start;
`;

export const CreatedBy = styled.div`
  width: 150px;
  white-space: normal;
  text-align: start;
`;

export const AudienceStatus = styled.div`
  display: flex;
  font-size: 16px;
`;

export const ModeledSize = styled.div`
  text-align: left;
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
`;

import styled from 'styled-components';
import tokens from 'tokens';
import { Select } from 'antd';
import 'antd/dist/antd.css';

export const Wrapper = styled(Select)`
  .ant-select-selector {
    border-color: ${tokens.colorGray40} !important;
    border-radius: ${tokens.spacing.sm} !important;
    font-size: 16px;
    height: 36px !important;
  }

  .ant-select-selection-placeholder {
    color: ${tokens.colorBody};
    line-height: 32px !important;
  }

  .ant-select-selection-item {
    line-height: 32px !important;
  }
`;

import AudienceIcon from 'assets/images/supervisor-account-dialog.svg';

import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  audienceIconContainer: {
    color: 'white',
    display: 'flex',
    borderRadius: 5,
    overflow: 'hidden',
    paddingRight: '0.8em',
    alignItems: 'center',

    fontSize: 15,
  },
  audienceIcon: {
    paddingLeft: '0.5em',
    paddingRight: '0.2em',
    paddingTop: '0.5em',
    paddingBottom: '0.2em',
  },
  audienceText: {
    textAlign: 'center',
    padding: '0.3em',
    paddingLeft: '0.5em',
  },
  circularProgressContainer: {
    paddingTop: '0.3em',
    paddingLeft: '0.5em',
  },
}));

const AudienceBlock = ({
  conditionRow,
  queryLoading,
}: {
  conditionRow: {
    audienceSize: string;
    qualifier: string;
  };
  queryLoading: boolean;
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.audienceIconContainer}
      style={{
        backgroundColor:
          (conditionRow.qualifier && conditionRow.audienceSize) ||
          (conditionRow.qualifier && queryLoading)
            ? 'black'
            : '#9098A1',
      }}
    >
      <div className={classes.audienceIcon}>
        <img src={AudienceIcon} alt="" />
      </div>
      {!queryLoading || !conditionRow.qualifier || conditionRow.audienceSize ? (
        <div className={classes.audienceText}>
          {conditionRow && conditionRow.audienceSize
            ? conditionRow.audienceSize
            : '~'}
        </div>
      ) : (
        <div className={classes.circularProgressContainer}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default AudienceBlock;

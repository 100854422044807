import _ from 'lodash';
import nanoid from 'utils/nanoid';
import { useAppSelector } from 'redux/hooks';

// Components
import AudienceBlock from '../audienceBlock';
import UpDownArrow from 'components/upDownArrow';

// Material UI
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
  },
  connectorStyling: {
    backgroundColor: '#EEF2FA',
    color: '#2C6EEA',
    padding: '0.1em 0.4em',
    borderRadius: '5px',
    fontSize: 15,
  },
  connectorStylingContainer: {
    paddingRight: '1em',
  },
  rowsContainer: {
    borderLeft: '4px solid #DADEE3',
    paddingLeft: '1em',
    width: '100%',
  },
  rowFlex: {
    display: 'flex',
    padding: '0.3em',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowFlexBox1: {
    display: 'flex',
    alignItems: 'center',
  },
  rowOperatorContainer: {
    padding: '0em 0.5em',
  },
  rowOperator: {
    color: '#6D7580',
    padding: '0.1em 0.3em',
    border: '1px solid #6D7580',
    borderRadius: '4px',
    fontSize: '15px',
  },
  rowQualifier: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    backgroundColor: '#3E9D86',
    color: 'white',
    padding: '0.1em 0.5em',
    borderRadius: '3px',
  },
  rowQualifierContainer: {
    paddingRight: '0.5em',
  },
}));

const themeOverrides = createTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        maxHeight: 20,
        maxWidth: 20,
      },
      colorPrimary: {
        color: '#ffffff',
      },
    },
  },
});

const QueryDisplay = ({ category }: { category: string }) => {
  const classes = useStyles();
  const dimension = useAppSelector((state) => {
    const currentSalesLiftStudy =
      state.salesLiftStudiesReducer.currentSalesLiftStudy;
    if (
      currentSalesLiftStudy &&
      currentSalesLiftStudy.dimensions &&
      currentSalesLiftStudy.dimensions.length
    ) {
      return currentSalesLiftStudy.dimensions.filter(
        (x) => x.category === category
      )[0];
    }
  });
  const queryLoading = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.queryLoading
  );
  const rows = dimension && dimension.conditions ? dimension.conditions : [];
  const someQualifiers = rows.filter((row) => row.qualifier).length;
  return (
    <ThemeProvider theme={themeOverrides}>
      <div>
        {someQualifiers ? (
          <div className={classes.root}>
            <div className={classes.connectorStylingContainer}>
              <div className={classes.connectorStyling}>
                {dimension.connector.toUpperCase()}
              </div>
            </div>
            <UpDownArrow />
            <div className={classes.rowsContainer}>
              {rows.map(({ condition, operator, qualifier, audienceSize }) => {
                if (!condition || !operator || !qualifier) return null;
                return (
                  <div className={classes.rowFlex} key={nanoid()}>
                    <div className={classes.rowFlexBox1}>
                      <div>{_.startCase(condition)}</div>
                      <div className={classes.rowOperatorContainer}>
                        <div className={classes.rowOperator}>
                          {_.startCase(operator).toLowerCase()}
                        </div>
                      </div>
                      <div className={classes.rowQualifierContainer}>
                        <div className={classes.rowQualifier}>
                          {qualifier && qualifier.toUpperCase()}
                        </div>
                      </div>
                    </div>
                    <AudienceBlock
                      queryLoading={queryLoading}
                      conditionRow={{ qualifier, audienceSize }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div>Please select some conditions</div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default QueryDisplay;

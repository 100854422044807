import React from 'react';
import _ from 'lodash';
import {
  DimensionOperator,
  DimensionOperators,
  queryTotalAudienceSize,
  updateDimensionGroup,
} from '../slice';
import { Select } from '@klover/attain-design-system';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import tokens from 'tokens';
import {
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: -13,
      marginBottom: -13,
    },
    operatorControl: {
      border: '2px',
      borderRadius: 8,
    },
  })
);

const menu = createTheme({
  overrides: {
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiSelect: {
      outlined: {
        width: 31,
        border: '2px solid #5041AB',
        borderRadius: 8,

        '&$focused': {
          border: '2px solid #5041AB',
          outline: '0px',
        },
      },
    },
  },
});

interface Props {
  id: string;
}

const DimensionGroupConnector = ({ id }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const dimensionGroup = useAppSelector((state) => {
    return state.audienceReducer.currentDimensionGroups.byId[id];
  });

  const handleOperatorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const operator = event.target.value as DimensionOperator;
    if (dimensionGroup) {
      const updatedDimensionGroup = {
        ...dimensionGroup,
        operator: operator,
      };
      dispatch(updateDimensionGroup(updatedDimensionGroup));
      dispatch(queryTotalAudienceSize());
    }
  };

  return (
    <>
      {dimensionGroup && (
        <ThemeProvider theme={menu}>
          <FormControl
            color="primary"
            variant="outlined"
            className={classes.root}
            size="small"
          >
            <div
              style={{
                height: 10,
                width: 10,
                borderRadius: 10,
                backgroundColor: tokens.colorPrimary,
              }}
            />
            <div
              style={{
                height: 14,
                width: 2,
                backgroundColor: tokens.colorPrimary,
              }}
            />
            <Select
              labelId="operator-label"
              id="operator-select"
              value={dimensionGroup.operator}
              onChange={handleOperatorChange}
              className={classes.operatorControl}
            >
              {DimensionOperators.map((operator) => {
                return (
                  <MenuItem key={operator} value={operator}>
                    {_.startCase(operator)}
                  </MenuItem>
                );
              })}
            </Select>
            <div
              style={{
                height: 14,
                width: 2,
                backgroundColor: tokens.colorPrimary,
              }}
            />
            <div
              style={{
                height: 10,
                width: 10,
                borderRadius: 10,
                backgroundColor: tokens.colorPrimary,
              }}
            />
          </FormControl>
        </ThemeProvider>
      )}
    </>
  );
};

export default DimensionGroupConnector;

import Login from 'pages/auth';
import Main from 'pages/main';
import React from 'react';
import ResetPassword from 'pages/resetPassword';
import { BrowserRouter, Route, RouteProps } from 'react-router-dom';

const Navigation: React.FC<RouteProps> = () => {
  return (
    <div>
      <BrowserRouter>
        <Route exact path="/login" component={Login} />
        <Route path="/main" component={Main} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/" component={Login} />
      </BrowserRouter>
    </div>
  );
};

export default Navigation;

import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@klover/attain-design-system';
import { IconButton, Popover, Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
/**
 * Define custom styles using makeStyles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'inline',
    },
    popoverContainer: {
      margin: '0.5rem 1rem',
      width: '550px',
    },
    label: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
  })
);

const FilterPopup = (props: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { checkedItems, setCheckedItems, fieldMapping } = props || {};
  const changedFieldList = Object.entries(fieldMapping).map(([key, value]) => ({
    key,
    value,
  }));

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event: any) => {
    const currentData = checkedItems;
    if (event.target.checked) {
      currentData.push(event.target.name);
    } else {
      const index = currentData.indexOf(event.target.name);
      currentData.splice(index, 1);
    }
    setCheckedItems([...currentData]);
  };
  const open = Boolean(anchorEl);

  return (
    <div className={classes.container}>
      <IconButton onClick={handleClick}>
        <FilterListIcon fontSize="medium" />
      </IconButton>
      <Popover
        id={'change-log-filter'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.popoverContainer}>
          <Grid container>
            {changedFieldList.map((item, index) => {
              return (
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item.key)}
                        onChange={handleCheckboxChange}
                        name={item.key}
                        size="small"
                        style={{ padding: 4 }}
                      />
                    }
                    label={<span className={classes.label}>{item.value}</span>}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

export default FilterPopup;

import debounce from 'lodash/debounce';
import { IconButton } from '@klover/attain-design-system';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useCallback, useEffect } from 'react';

// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TextField from '@material-ui/core/TextField';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

import tokens from 'tokens';
import {
  addAudience,
  fetchSomeAudiences,
  removeAudience,
  updateSearchQuery,
} from '../slice';
import { clearAudiences } from '../../audienceManager/slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audienceNameContainer: {
      fontSize: 15,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    audienceSelectorRows: {
      maxHeight: 150,
      overflowY: 'scroll',
      width: 450,
      fontSize: 15,
      // backgroundColor: 'green'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonFormat: {
      margin: '0em 0.5em',
    },
    formControl: {
      minWidth: 450,
    },
    checkIcon: {
      color: 'lightgreen',
      marginLeft: '5px',
    },
    clearIcon: {
      color: 'red',
      marginLeft: '5px',
    },
    close: {
      height: 24,
      width: 24,
    },
    columnTitles: {
      color: 'grey',
      display: 'flex',
      fontSize: 10,
      padding: 10,
      paddingTop: 30,
      paddingBottom: 0,
      marginLeft: 24,
    },
    columnNameAndValue: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      padding: 10,
      marginLeft: 24,
    },
    datePickerStyling: {
      width: 200,
    },
    divider: {
      borderLeft: '3px solid ' + tokens.colorDividerBorder,
      paddingLeft: 5,
      flexGrow: 1,
    },
    header: {
      padding: '10px 12px 10px 24px',
    },
    icon: {
      height: 24,
      width: 24,
    },
    inputColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputColumnSpacing: {
      paddingTop: 10,
    },
    lineItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'scroll',
      height: 200,
      width: 450,
    },
    textInput: {
      paddingBottom: 15,
      width: 450,
    },
    selectInput: {
      paddingBottom: 15,
      width: 450,
    },
    root: {
      margin: theme.spacing(3),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 107,
      height: 22,
      marginBottom: 5,
    },
    dealsChecklistContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 15,
    },
    noDealsText: {
      padding: '1em',
      fontSize: 15,
    },
    removeButton: {
      color: tokens.colorIconSecondary,
    },
    toggleButtonGroup: {
      marginBottom: 4,
      width: 450,
    },
    toggleButtonSelected: {
      color: tokens.colorButtonDefault,
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const AudienceSelector = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const currentOffer = useAppSelector((state) => {
    if (state.offerWallReducer && state.offerWallReducer.currentOffer) {
      return state.offerWallReducer.currentOffer;
    } else {
      return null;
    }
  });

  const selectedAudiences =
    currentOffer && currentOffer.audiences ? currentOffer.audiences : [];

  const searchQuery = useAppSelector((state) => {
    if (
      state.offerWallReducer &&
      state.offerWallReducer.ui &&
      state.offerWallReducer.ui.searchQuery
    ) {
      return state.offerWallReducer.ui.searchQuery;
    } else {
      return '';
    }
  });

  const audiences = useAppSelector((state) => {
    if (
      state.audienceReducer &&
      state.audienceReducer.entities &&
      state.audienceReducer.entities.audiences &&
      state.audienceReducer.entities.audiences.byId
    ) {
      return Object.values(state.audienceReducer.entities.audiences.byId).sort(
        (a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }
      );
    } else {
      return [];
    }
  });

  const handleSearchChange = (query) => {
    query = query.trimStart();
    if (query) {
      dispatch(fetchSomeAudiences(query));
    } else {
      dispatch(clearAudiences());
    }
  };

  const debouncedHandleSearchChangeFn = useCallback(
    debounce(handleSearchChange, 400),
    []
  );

  const filteredAudiences = audiences.filter((audience) =>
    audience.name.toUpperCase().includes(searchQuery.toUpperCase())
  );

  useEffect(() => {
    dispatch(updateSearchQuery(''));
    dispatch(clearAudiences());
  }, []);

  return (
    <ThemeProvider theme={inputs}>
      {selectedAudiences && selectedAudiences.length ? (
        <div className={classes.audienceSelectorRows}>
          {selectedAudiences.map(({ audiencename, audienceid }) => {
            return (
              <div key={nanoid()} className={classes.audienceNameContainer}>
                <div className={classes.audienceName}>{audiencename} </div>
                <div>
                  <IconButton
                    className={classes.removeButton}
                    color="primary"
                    size="small"
                    onClick={() => dispatch(removeAudience(audienceid))}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <TextField
            className={classes.textInput}
            label={'Search by audience name'}
            variant="outlined"
            value={searchQuery}
            onChange={(e) => {
              dispatch(updateSearchQuery(e.target.value));
              debouncedHandleSearchChangeFn(e.target.value);
            }}
          />
          <RadioGroup>
            {filteredAudiences.map((audience) => {
              const audiencename = audience.name;
              const audienceid = audience.id;
              return (
                <FormControlLabel
                  key={nanoid()}
                  value={audienceid}
                  control={<Radio />}
                  label={audiencename}
                  onClick={() =>
                    dispatch(addAudience({ audienceid, audiencename }))
                  }
                />
              );
            })}
          </RadioGroup>
        </div>
      )}
    </ThemeProvider>
  );
};

export default AudienceSelector;

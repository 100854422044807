import { gql } from '@apollo/client';

export const GET_CHANGE_LOGS = gql`
  query GetChangeLogs(
    $entityId: String!
    $systemName: String!
    $changedFields: [String!]
  ) {
    changeLogs(
      entityId: $entityId
      systemName: $systemName
      changedFields: $changedFields
    ) {
      changeId
      changedByEmail
      changedFields
      createdAt
      entityId
      newData
      previousData
      systemName
    }
  }
`;

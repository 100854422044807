const observationTimeInputs = [
  {
    keyName: 'studydates',
    inputLabel: 'Study Dates',
    inputType: 'dates',
    multipleKeyNames: ['enddate', 'startdate'],
    stepperKeyName: 'studydates',
    stepperInputType: 'dates',
    stepperInputLabel: 'Study Dates',
  },
  {
    inputLabel: 'Lapsed Period',
    keyName: 'lapsedperiod',
    inputType: 'daysInput',
    stepperKeyName: 'lapsedperiod',
    stepperInputType: 'daysInput',
    stepperInputLabel: 'Lapsed Period',
    min: 1,
  },
  {
    inputLabel: 'Attribution Window',
    keyName: 'attributionwindow',
    inputType: 'daysInput',
    stepperKeyName: 'attributionwindow',
    stepperInputType: 'daysInput',
    stepperInputLabel: 'Attribution Window',
    min: 1,
  },
];

export default observationTimeInputs;

// All components leveraging react-grid-system. Docs and examples here... https://sealninja.github.io/react-grid-system/

import styled from 'styled-components';
import tokens from 'tokens';
import {
  Col as ReactCol,
  Container as ReactContainer,
  Row as ReactRow,
} from 'react-grid-system';

export const Container = styled(ReactContainer)`
  padding-left: ${tokens.spacing.md} !important;
  padding-right: ${tokens.spacing.md} !important;
`;

export const Row = styled(ReactRow)`
  margin-left: -${tokens.spacing.md} !important;
  margin-right: -${tokens.spacing.md} !important;
`;

export const Col = styled(ReactCol)`
  padding-left: ${tokens.spacing.md} !important;
  padding-right: ${tokens.spacing.md} !important;
`;

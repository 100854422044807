import {
  Alert,
  Button,
  Dialog,
  Grid,
  theme,
} from '@klover/attain-design-system';
import {
  createAudience,
  duplicateAudience,
  saveAudience,
  toggleDraftStateDialog,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';

// Material UI Components
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import { CircularProgress } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import * as Styled from './index.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

interface Props {
  openDraftStateDialog: any;
}

const DraftStateDialog = ({ openDraftStateDialog }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const entitiesById = useAppSelector((state) => {
    return state.audienceReducer.entities.audiences.byId;
  });

  const currentAudience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );

  const currentConditions = useAppSelector((state) => {
    return state.audienceReducer.currentConditions;
  });

  const currentDimensions = useAppSelector((state) => {
    return state.audienceReducer.currentDimensions;
  });

  const currentDimensionGroups = useAppSelector((state) => {
    return state.audienceReducer.currentDimensionGroups;
  });

  const currentGroup = useAppSelector((state) => {
    return state.audienceReducer.currentGroup;
  });

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const handleSaveAudience = async () => {
    if (currentAudience) {
      const updatedAudience = {
        ...currentAudience,
        updatedAt: Date.now(),
        groupId: currentGroup?.id,
      };

      const normalizedObjects = {
        updatedAudience,
        currentDimensions,
        currentDimensionGroups,
        currentConditions,
      };

      const audienceExists = entitiesById[currentAudience.id];
      if (audienceExists) {
        dispatch(saveAudience(normalizedObjects));
      } else {
        dispatch(createAudience(normalizedObjects));
      }
    }
    history.push('/main/audience-manager');
  };

  const isAudienceLoading = audienceLoading.state === 'loading';

  return (
    <Dialog
      fullWidth
      className={classes.root}
      open={openDraftStateDialog.open}
      onClose={() => dispatch(toggleDraftStateDialog({ open: false }))}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      headerID="saveAudienceHeader"
      header={
        <Styled.CardHeader>
          <Styled.CardTitle>Save audience?</Styled.CardTitle>
        </Styled.CardHeader>
      }
      bodyID="saveAudienceBody"
      body={
        <Styled.StyledBodyContainer
          container
          spacing={theme.spacing.lg}
          direction="column"
        >
          <Grid item>
            <Styled.BodyHeader>Audience name</Styled.BodyHeader>
            <Styled.BodyText>{currentAudience?.name}</Styled.BodyText>
          </Grid>
          <Grid item>
            <Alert severity="info">
              The audience has been edited. Do you want to save?
            </Alert>
          </Grid>
        </Styled.StyledBodyContainer>
      }
      footer={
        <DialogActions>
          <Button
            color="primary"
            onClick={() => dispatch(toggleDraftStateDialog({ open: false }))}
            variant="outlined"
          >
            Cancel
          </Button>
          {openDraftStateDialog && openDraftStateDialog.duplicate ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                dispatch(
                  duplicateAudience({
                    audience: currentAudience,
                    name: currentAudience.name + ' (copy)',
                  })
                )
              }
            >
              Duplicate Without Saving
            </Button>
          ) : null}
          {openDraftStateDialog && openDraftStateDialog.path ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch(toggleDraftStateDialog({ open: false }));
                history.push(openDraftStateDialog.path);
              }}
            >
              {openDraftStateDialog.path === '/main/audience-manager'
                ? `Exit Editor Without Saving`
                : null}
            </Button>
          ) : null}
          {isAudienceLoading ? (
            <Box
              width={142}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress color="primary" size={20} />
            </Box>
          ) : (
            <Button
              color="primary"
              disableElevation
              onClick={handleSaveAudience}
              variant="contained"
            >
              Save Audience
            </Button>
          )}
        </DialogActions>
      }
    />
  );
};

export default DraftStateDialog;

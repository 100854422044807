import Dialog from 'components/Dialog';
import React from 'react';
import axios from 'axios';
import { OfferWallContext } from 'context';
import { Tooltip } from 'antd';
import { addNotification, removeCreativeRotations } from '../slice';
import { useAppDispatch } from 'redux/hooks';
import { Button } from '@klover/attain-design-system';

const RemoveOfferRotation = () => {
  const [creativeOffersLoading, setCreativeOffersLoading] =
    React.useState(false);
  const [creativeOffers, setCreativeOffers] = React.useState(null);
  const dispatch = useAppDispatch();
  const {
    setRemoveCreativeRotation,
    setExistingCreativeRotation,
    existingCreativeRotation,
  } = React.useContext(OfferWallContext);
  const close = () => {
    setExistingCreativeRotation(null);
    setRemoveCreativeRotation(false);
  };
  const handleSubmit = () => {
    if (existingCreativeRotation) {
      const body = {
        parent: existingCreativeRotation.offerid,
      };
      axios
        .post(
          `${process.env.API_BASE_ADDRESS}/offer-manager/offers/creative-rotation`,
          body
        )
        .then(() => {
          if (creativeOffers) {
            dispatch(
              removeCreativeRotations({
                offers: creativeOffers,
              })
            );
            dispatch(
              addNotification({
                state: 'done',
                message: `Successfully deleted rotation`,
              })
            );
          }
        })
        .catch((e) => {
          console.log('Error:', e);
          setCreativeOffers(null);
          dispatch(
            addNotification({
              state: 'error',
              message: `Error while deleting rotation`,
            })
          );
        });
    }
    close();
  };
  React.useEffect(() => {
    if (existingCreativeRotation) {
      setCreativeOffersLoading(true);
      axios
        .get(
          `${process.env.API_BASE_ADDRESS}/offer-manager/offers/creative-rotation/${existingCreativeRotation.offerid}`
        )
        .then(({ data }) => {
          setCreativeOffers(data);
          setCreativeOffersLoading(false);
        })
        .catch((e) => {
          console.log('Error:', e);
          setCreativeOffers(null);
          setCreativeOffersLoading(false);
        });
    }
  }, []);
  const disableDelete =
    creativeOffersLoading && creativeOffers && !creativeOffers.length
      ? true
      : false;
  return (
    <Dialog
      onDismiss={close}
      showClose={true}
      onCloseClick={close}
      title={'Remove Creative Rotation'}
      buttons={
        <>
          <Tooltip
            zIndex={999999999999999}
            title={disableDelete ? 'Creative rotation no longer exists' : ''}
          >
            <span>
              <Button
                variant="contained"
                color="error"
                type="submit"
                onClick={handleSubmit}
                disabled={disableDelete}
              >
                Delete
              </Button>
            </span>
          </Tooltip>
          <Button onClick={close} variant="outlined">
            Close
          </Button>
        </>
      }
    >
      <p>
        Are you sure you&apos;d like to remove creative rotations from{' '}
        <strong>{existingCreativeRotation.label}?</strong>
      </p>
    </Dialog>
  );
};
export default RemoveOfferRotation;

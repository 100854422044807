import React, { SyntheticEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { resetPasswordAction } from '../auth/actions';
import { useDispatch } from 'react-redux';
import { Button } from '@klover/attain-design-system';

// Material UI Components
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

// Components
import AppLogo from 'assets/images/app-logo.svg';
import Copyright from 'components/copyright';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  appLogo: {
    margin: theme.spacing(1),
    width: '75px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const dispatch = useDispatch();

  // Action
  const resetActions = (payload: { email: string }) =>
    dispatch(resetPasswordAction(payload));

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();

    if (email === '') {
      setIsValid(false);
      return;
    }

    resetActions({ email: email });
    history.push('/login');
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Container component="main" maxWidth="xs">
        <Box border={1} borderColor="grey.300" borderRadius={8} p={4}>
          <div className={classes.paper}>
            <img src={AppLogo} className={classes.appLogo} alt="Klover" />
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => handleSubmit(e)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                error={!isValid}
              />
              {!isValid && (
                <Alert severity="error">A valid email is required</Alert>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Reset Password
              </Button>
            </form>
            <Link to="/login">Log in</Link>
          </div>
        </Box>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </Grid>
  );
};

export default ResetPassword;

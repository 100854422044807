const offerDimensionConfig = {
  dimensionName: 'Offer',
  stepperDimensionName: 'Select group & offers',
  stepperDimensionTitle: 'Offer',
  inputs: [
    {
      inputLabel: 'Offer Group',
      keyName: 'offergroupname',
      inputType: 'offerGroupSelect',
      stepperKeyName: 'offergroupname',
      stepperInputType: 'defaultGroupSelect',
      stepperInputLabel: 'Offer Group',
    },
    {
      inputLabel: 'Offers',
      keyName: 'offers',
      inputType: 'offersSelect',
      stepperKeyName: 'offers',
      stepperInputType: 'offersSelect',
      stepperInputLabel: 'Offers',
      multipleKeyNames: ['offersSelect'],
    },
  ],
};

export default offerDimensionConfig;

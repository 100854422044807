import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';
export const OfferContainer = styled.div`
  height: 100vh;
`;

export const OfferUnknown = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferUnknownText = styled.div`
  font-size: 20px;
  text-align: center;
`;

export const Wrapper = styled.div`
  ${theme.mixins.card};
  overflow: hidden;
  margin: 1% 14% 4% 14%;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const CardBody = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
`;
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { theme } from '@klover/attain-design-system';

export const NavigationWrapper = styled.ul`
  background: ${theme.colors.backgroundBrand};
  color: ${theme.colors.textInverted};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  margin: 12px;
  padding: 0;

  /* Media query to hide NavigationWrapper on screens wider than 600px */
  @media (min-width: 600px) {
    opacity: 1;
    overflow: hidden;
    overscroll-behavior-y: none;
    transform: none;
    transition: none 0s ease 0s;
    z-index: 0;
  }
`;

export const NavigationItem = styled(NavLink)`
  ${theme.typography.bodyMD}
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.sm} ${theme.spacing.sm};
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: opacity 0.25s ease, background-color 0.25s ease;
  border-radius: 8px;
  font-family: Calibre-Regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  &:hover {
    background: ${theme.colors.buttonBackgroundHover};
    opacity: 0.9;
  }

  &.active {
    background: ${theme.colors.buttonBackgroundHover};
    border-left-color: ${theme.colors.backgroundPrimary};
    cursor: default;
  }
`;

export const SubNavItemWrapper = styled.ul`
  padding-left: calc(22px + ${theme.spacing.md});
`;

export const SubNavItem = styled(NavLink)<{ active: boolean }>`
  ${theme.typography.bodySM}
  display: flex;
  align-items: center;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  text-decoration: none;
  color: ${theme.colors.textInverted};
  border-left: solid 3px transparent;
  border-left: solid 3px
    ${(props) => (props.active ? theme.colors.backgroundPrimary : '#504479')};
  transition: opacity 0.25s ease, background-color 0.25s ease;
  font-family: Calibre-Semibold, sans-serif;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;

  &:hover {
    background: ${theme.colors.buttonBackgroundHover};
    opacity: 0.9;
  }

  ${(props) =>
    props.active &&
    `
    background: ${theme.colors.buttonBackgroundHover};
    cursor: default;
  `}
`;

// &.active {
//   background: ${theme.colors.buttonBackgroundHover};
//   border-left-color: ${theme.colors.backgroundPrimary};
//   cursor: default;
// }

export const VerticalDivider = styled(Box)`
  border-right: 1px solid rgb(232, 232, 232);
  height: 40px;
  width: 2px;
  opacity: 25%;
  margin: 0 auto;
`;

export const NavigationItemLabel = styled.div`
  font-family: Calibre-Semibold, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  -webkit-box-align: center;

  border-radius: 8px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  -webkit-box-flex: 1;
  flex-grow: 1;
`;

export const NavigationSubItemLabel = styled.div`
  font-family: Calibre-Semibold, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  -webkit-box-align: center;

  border-radius: 8px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  -webkit-box-flex: 1;
  flex-grow: 1;
`;

import React from 'react';
import { OfferWallContextProps } from 'interfaces/offerWallManager';
import { QuickQuestionContextProps } from 'interfaces/quickQuestionsManager';

export const OfferWallContext = React.createContext(
  {} as OfferWallContextProps
);

export const QuickQuestionContext = React.createContext(
  {} as QuickQuestionContextProps
);

import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useLocation } from 'react-router-dom';
import * as Styled from '../index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GroupDeleteDialog from '../groupDeleteDialog';
import GroupNameSaveIcon from './groupNameSaveIcon';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from '../variables';
import { IconButton } from '@klover/attain-design-system';
import { SnackBarOptions, defaultSnackBarOptions } from 'interfaces/ui';
import {
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { auth } from 'utils/firebase';
import {
  FloppyDiskBack,
  PencilSimple,
  TrashSimple,
  X,
} from '@phosphor-icons/react';

const API_ENDPOINT = `${API_BASE_ADDRESS}/custom-audience-manager/groups`;

export interface deleteGropProps {
  state: string;
  message: string;
}

export interface toggleGroupDeleteProps {
  open: boolean;
}

export interface groupNameProps {
  group: CustomAudienceGroup;
  handleSelectGroup: () => void;
  reFetchGroups: () => void;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() =>
  createStyles({
    editButton: {
      marginLeft: '20px !important',
      padding: 4,
    },
    deleteButton: {
      marginLeft: 4,
      padding: 4,
    },
    groupName: {
      fontSize: 20,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
        fontSize: 14,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
  },
});

const GroupName = ({
  group: currentGroup,
  handleSelectGroup,
  reFetchGroups,
}: groupNameProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const [toggleGroupDeleteDialog, settoggleGroupDeleteDialog] = useState({
    open: false,
  });
  const [groupDeleting, setGroupDeletingState] = useState({
    state: 'idle',
    message: '',
  });
  const [groupSaving, setGroupSavingState] = useState({ state: 'idle' });
  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>(
    defaultSnackBarOptions
  );

  const [name, setName] = useState('');

  useEffect(() => {
    setName(currentGroup?.name || '');
    setEditMode(false);
  }, [currentGroup]);

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleDelete = () => {
    settoggleGroupDeleteDialog({ open: true });
  };

  const handleEditName = () => {
    setEditMode(true);
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    setName(name);
  };

  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    const updatedSnackBarOptions = {
      ...snackBarOptions,
      open: false,
    };
    setGroupSavingState({ ...groupSaving, state: 'idle' });
    setSnackBarOptions(updatedSnackBarOptions);
  };

  const handleSave = async () => {
    if (currentGroup && name !== '') {
      setGroupSavingState({ ...groupSaving, state: 'loading' });
      const params: AudienceGroupParams = {
        id: currentGroup.id,
        name: name,
      };
      if (auth?.currentUser) {
        auth.currentUser.getIdTokenResult().then(async (res) => {
          const authorizedAxios = await getAuthorizedAxiosInstance();
          try {
            if (authorizedAxios) {
              const res = await authorizedAxios.put(
                `${API_ENDPOINT}/${currentGroup.id}`,
                params
              );
              if (res.status === 200) {
                reFetchGroups();
                setSnackBarOptions({
                  open: true,
                  severity: 'success',
                  message: `Group successfully updated!`,
                });
                setGroupSavingState({ state: 'done' });
                window.history.replaceState(
                  null,
                  '',
                  `${location.pathname}?groupId=${currentGroup.id}`
                );
              } else {
                setGroupSavingState({
                  state: 'failed',
                });
                setSnackBarOptions({
                  open: true,
                  severity: 'error',
                  message: `Failed to delete group ${currentGroup.name}`,
                });
              }
            }
          } catch (e) {
            setSnackBarOptions({
              open: true,
              severity: 'error',
              message: e,
            });
            setGroupDeletingState({ state: 'failed', message: e });
          }
        });
      }
    }
  };

  const isDisabled = useAppSelector(
    (state) => state.audienceReducer.ui.groupSaving.state === 'loading'
  );

  return (
    <ThemeProvider theme={inputs}>
      <Box display="flex" alignItems="center">
        {editMode ? (
          <>
            <TextField
              id="group-name"
              label="Group Name"
              onChange={handleNameChange}
              defaultValue={currentGroup?.name}
              disabled={isDisabled}
              value={name}
              variant="outlined"
              style={{ marginRight: 8 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="default"
                      disabled={isDisabled}
                      onClick={handleSave}
                      size="small"
                    >
                      <FloppyDiskBack size={24} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton disabled={isDisabled} onClick={handleCancel}>
              <X size={24} />
            </IconButton>
          </>
        ) : (
          <>
            <Typography className={classes.groupName}>
              {currentGroup?.name}
            </Typography>

            <IconButton
              className={classes.editButton}
              color="default"
              onClick={handleEditName}
              size="small"
            >
              <PencilSimple size={24} />
            </IconButton>

            <IconButton
              className={classes.deleteButton}
              color="default"
              onClick={handleDelete}
              size="small"
            >
              <TrashSimple size={24} />
            </IconButton>
          </>
        )}
      </Box>
      <Snackbar
        open={snackBarOptions.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity={snackBarOptions.severity}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
      <GroupDeleteDialog
        currentGroup={currentGroup}
        groupDeleting={groupDeleting}
        setGroupDeletingState={setGroupDeletingState}
        toggleGroupDeleteDialog={toggleGroupDeleteDialog}
        settoggleGroupDeleteDialog={settoggleGroupDeleteDialog}
        handleSelectGroup={handleSelectGroup}
        reFetchGroups={reFetchGroups}
      />
    </ThemeProvider>
  );
};

export interface CustomAudienceGroup {
  id: number;
  name: string;
}

interface GroupNameProps {
  group: CustomAudienceGroup;
}

export default GroupName;

export const MULTIPLIER = 1;

export enum AudienceTypes {
  AUDIENCE = 'AUDIENCE',
  CUSTOM = 'CUSTOM',
}

export enum SaveStatus {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

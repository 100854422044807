import {
  setHasChanged,
  setHasNotChanged,
  setIsInvalid,
  setIsValid,
  updateCurrentSalesLiftStudyProperty,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import ClearIcon from '@material-ui/icons/Clear';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton } from '@klover/attain-design-system';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    datePickerContainers: {
      display: 'flex',
      alignItems: 'center',
    },
    datePickers: {
      paddingBottom: 20,
    },
    datePickerStyling: {
      width: 200,
    },
  })
);
const StudyDates = () => {
  const minuteOffset = new Date().getTimezoneOffset();
  const millisecondOffset = minuteOffset * 60 * 1000;
  const minDate = 1622505600000 + millisecondOffset;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  const originalSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.originalSalesLiftStudy
  );
  const isInvalid = useAppSelector(
    (state) => state.salesLiftStudiesReducer.isInvalid
  );
  const handleUpdateSalesLiftStudy = (value, keyName) => {
    if (value && !value.includes('_')) {
      const updatedSalesLift = {
        ...currentSalesLiftStudy,
        [keyName]: Date.parse(value),
      };
      if (updatedSalesLift && originalSalesLiftStudy) {
        if (updatedSalesLift[keyName] && originalSalesLiftStudy[keyName]) {
          if (updatedSalesLift[keyName] == originalSalesLiftStudy[keyName]) {
            dispatch(setHasNotChanged({ keyName }));
          } else {
            dispatch(setHasChanged({ keyName }));
          }
        }
      }
      dispatch(
        updateCurrentSalesLiftStudyProperty({
          updatedSalesLift,
          propertyName: keyName,
        })
      );
    }
  };

  const clearDatesHandler = () => {
    const updatedSalesLift = {
      ...currentSalesLiftStudy,
      enddate: null,
      startdate: null,
    };
    if (originalSalesLiftStudy) {
      if (originalSalesLiftStudy.enddate)
        dispatch(setHasChanged({ keyName: 'enddate' }));
      if (originalSalesLiftStudy.startdate)
        dispatch(setHasChanged({ keyName: 'startdate' }));
    }
    dispatch(
      updateCurrentSalesLiftStudyProperty({
        updatedSalesLift,
        propertyName: 'dates',
      })
    );
  };
  if (currentSalesLiftStudy) {
    if (
      currentSalesLiftStudy.startdate &&
      currentSalesLiftStudy.enddate &&
      currentSalesLiftStudy.startdate > currentSalesLiftStudy.enddate
    ) {
      dispatch(setIsInvalid({ keyName: 'dates', helperText: 'Dates are bad' }));
    } else {
      dispatch(setIsValid({ keyName: 'dates' }));
    }
  }

  return (
    <div className={classes.datePickerContainers}>
      <div className={classes.datePickers}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id="date-picker-start"
            className={classes.datePickerStyling}
            label={'Start Date'}
            format="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            minDate={minDate}
            value={currentSalesLiftStudy?.startdate || null}
            onChange={(_, value) =>
              handleUpdateSalesLiftStudy(value, 'startdate')
            }
          />
          <KeyboardDatePicker
            id="date-picker-start"
            className={classes.datePickerStyling}
            label={'End Date'}
            format="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            minDate={currentSalesLiftStudy?.startdate || minDate}
            maxDate={Date.now()}
            helperText={
              isInvalid.dates ? 'Date should not be before start date' : ''
            }
            value={currentSalesLiftStudy?.enddate || null}
            onChange={(_, value) =>
              handleUpdateSalesLiftStudy(value, 'enddate')
            }
          />
        </MuiPickersUtilsProvider>
      </div>
      <div>
        <IconButton size="small" onClick={clearDatesHandler}>
          <ClearIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default StudyDates;

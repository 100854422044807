import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#5041AB',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#CC314D',
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;

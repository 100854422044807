import React from 'react';
import _ from 'lodash';
import { Button, IconButton } from '@klover/attain-design-system';
import { saveSalesLiftStudy, setDraftState } from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';

// Material UI Components
import BootstrapTooltip from 'components/bootstrapTooltip';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import SaveDisabledButtonMessage from '../saveDisabledButtonMessage';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
// import BootstrapTooltip from 'components/bootstrapTooltip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
  },
});

const SalesLiftDraftStateDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const salesLiftDraftState = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.salesLiftDraftState
  );
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );

  const isInvalid = useAppSelector(
    (state) => state.salesLiftStudiesReducer.isInvalid
  );

  const SalesLiftStudyLoading = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.salesLiftStudyLoading
  );

  const handleSaveSalesLiftStudy = () => {
    dispatch(setDraftState({ open: false }));
    dispatch(saveSalesLiftStudy(currentSalesLiftStudy));
  };

  const isSalesLiftStudyLoading = SalesLiftStudyLoading.state === 'loading';

  const isSaveDisabled =
    !currentSalesLiftStudy?.name || (isInvalid && !_.isEmpty(isInvalid));

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          open={
            salesLiftDraftState && salesLiftDraftState.open
              ? salesLiftDraftState.open
              : false
          }
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography className={classes.title}>
                  <span style={{ fontWeight: 'bolder' }}>
                    {currentSalesLiftStudy && currentSalesLiftStudy.name}
                  </span>{' '}
                  Draft
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={2}>
                <IconButton
                  onClick={() => dispatch(setDraftState({ open: false }))}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          {/* <Box m={3}> */}
          <DialogContent style={{ padding: '24px 32px 8px 32px' }}>
            <DialogContentText
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              The sales lift study has been edited, do you want to save{' '}
              <span style={{ fontWeight: 'bolder' }}>
                {currentSalesLiftStudy && currentSalesLiftStudy.name}
              </span>
              ?
            </DialogContentText>
          </DialogContent>
          {/* </Box> */}

          {/* Bottom Divider */}
          <Grid container spacing={0} style={{ marginTop: 150 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Actions */}
          <Box m={1}>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => dispatch(setDraftState({ open: false }))}
                variant="outlined"
              >
                Cancel
              </Button>
              {salesLiftDraftState && salesLiftDraftState.path ? (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    dispatch(setDraftState({ open: false }));
                    history.push(salesLiftDraftState.path);
                  }}
                >
                  {salesLiftDraftState.path === '/main/sales-lift-studies'
                    ? `Exit Editor Without Saving`
                    : null}
                </Button>
              ) : null}
              <BootstrapTooltip
                title={isSaveDisabled ? <SaveDisabledButtonMessage /> : ''}
              >
                <span>
                  {isSalesLiftStudyLoading ? (
                    <Box
                      width={142}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress color="primary" size={20} />
                    </Box>
                  ) : (
                    <Button
                      color="primary"
                      disableElevation
                      onClick={handleSaveSalesLiftStudy}
                      variant="contained"
                      disabled={isSaveDisabled ? true : false}
                    >
                      SAVE
                    </Button>
                  )}
                </span>
              </BootstrapTooltip>
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default SalesLiftDraftStateDialog;

import _ from 'lodash';
import axios from 'axios';
import nanoid from 'utils/nanoid';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { auth } from 'utils/firebase';
import { handleQuery } from '../../audienceManager/saga';

import {
  SalesLiftStudy,
  addNotification,
  addSalesLiftStudyToEntities,
  createSalesLiftStudy,
  deleteSalesLiftStudy,
  exportSalesLiftStudy,
  fetchSalesLiftStudy,
  saveSalesLiftStudy,
  searchSalesLifts,
  setAllValid,
  setCurrentSalesLiftStudy,
  setGroupLoadingState,
  setIsDisabled,
  setOriginalSalesLiftStudy,
  setQueryLoading,
  setSalesLiftDimensionDialogOpen,
  setSalesLiftStudiesLoadingState,
  setSalesLiftStudyExporting,
  setSalesLiftStudyLoadingState,
  setTargetTransactions,
  setUIState,
  updateTargetTransaction,
} from '../slice';

import { addSalesLiftStudyToCurrentGroup } from '../../offerWallManager/slice';

const SALES_LIFT_STUDIES_ENDPOINT = `${process.env.API_BASE_ADDRESS}/sales-lift-manager/studies`;

const minuteOffset = new Date().getTimezoneOffset();
const millisecondOffset = minuteOffset * 60 * 1000;

function reformatSalesLiftStudyForAPI(salesLiftStudy) {
  const cloneOfSalesLiftStudy = _.clone(salesLiftStudy);
  if (cloneOfSalesLiftStudy && cloneOfSalesLiftStudy.startdate) {
    cloneOfSalesLiftStudy.startdate =
      cloneOfSalesLiftStudy.startdate - millisecondOffset;
  }
  if (cloneOfSalesLiftStudy && cloneOfSalesLiftStudy.enddate) {
    cloneOfSalesLiftStudy.enddate =
      cloneOfSalesLiftStudy.enddate - millisecondOffset;
  }
  if (cloneOfSalesLiftStudy && !cloneOfSalesLiftStudy.attributionwindow) {
    cloneOfSalesLiftStudy.attributionwindow = null;
  }
  if (cloneOfSalesLiftStudy && !cloneOfSalesLiftStudy.lapsedperiod) {
    cloneOfSalesLiftStudy.lapsedperiod = null;
  }
  if (
    cloneOfSalesLiftStudy.dimensions &&
    cloneOfSalesLiftStudy.dimensions.length
  ) {
    const targetTransaction = _.clone(
      cloneOfSalesLiftStudy.dimensions.filter(
        (x) => x.category === 'targetTransactions'
      )[0]
    );
    if (
      targetTransaction &&
      targetTransaction.conditions &&
      targetTransaction.conditions.length
    ) {
      targetTransaction.conditions = targetTransaction.conditions.filter(
        (x) => x.qualifier
      );
      cloneOfSalesLiftStudy.dimensions =
        cloneOfSalesLiftStudy.dimensions.filter(
          (x) => x.category !== 'targetTransactions'
        );
      cloneOfSalesLiftStudy.dimensions =
        cloneOfSalesLiftStudy.dimensions.concat([targetTransaction]);
    }
  }
  return cloneOfSalesLiftStudy;
}

function formatSalesLiftStudyForFrontend(salesObject: any) {
  const cloneOfSalesObject = _.clone(salesObject);
  if (typeof cloneOfSalesObject.startdate !== 'number') {
    cloneOfSalesObject.startdate = parseInt(cloneOfSalesObject.startdate);
  }
  if (typeof cloneOfSalesObject.enddate !== 'number') {
    cloneOfSalesObject.enddate = parseInt(cloneOfSalesObject.enddate);
  }
  if (cloneOfSalesObject && cloneOfSalesObject.startdate) {
    cloneOfSalesObject.startdate =
      cloneOfSalesObject.startdate + millisecondOffset;
  }
  if (cloneOfSalesObject && cloneOfSalesObject.enddate) {
    cloneOfSalesObject.enddate = cloneOfSalesObject.enddate + millisecondOffset;
  }
  if (!cloneOfSalesObject.dimensions) cloneOfSalesObject.dimensions = [];
  return cloneOfSalesObject;
}

function handleCreateSalesLiftStudy(salesLiftStudy: SalesLiftStudy) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(
      SALES_LIFT_STUDIES_ENDPOINT,
      salesLiftStudy
    );
  });
}

function handleFetchSalesLiftStudy(salesLiftStudy: SalesLiftStudy) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      `${SALES_LIFT_STUDIES_ENDPOINT}/${salesLiftStudy.salesliftstudyid}`
    );
  });
}

function handleUpdateSalesLiftStudy(salesLiftStudy: SalesLiftStudy) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.put(
      `${SALES_LIFT_STUDIES_ENDPOINT}/${salesLiftStudy.salesliftstudyid}`,
      salesLiftStudy
    );
  });
}

function handleDeleteSalesLiftStudy(salesLiftStudy: SalesLiftStudy) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.delete(
      `${SALES_LIFT_STUDIES_ENDPOINT}/${salesLiftStudy.salesliftstudyid}`
    );
  });
}

function handleExportSalesLiftStudy(body) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(
      `${process.env.API_BASE_ADDRESS}/sales-lift-manager/export/`,
      body
    );
  });
}

function handleSearchSalesLifts(search) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      `${SALES_LIFT_STUDIES_ENDPOINT}?search=${encodeURIComponent(search)}`
    );
  });
}

function* watchCreateSalesLiftStudy(action: PayloadAction<any>) {
  const salesLiftStudy = action.payload;
  try {
    yield put(setSalesLiftStudyLoadingState({ state: 'loading' }));
    const salesLiftStudyForAPI = reformatSalesLiftStudyForAPI(salesLiftStudy);
    const result = yield call(handleCreateSalesLiftStudy, salesLiftStudyForAPI);
    // setting ui error fetching state to false because success.
    yield put(
      setUIState({
        value: false,
        uiPropertyName: 'errorFetching',
      })
    );
    const salesLiftStudyFromAPI = result.data;
    const salesliftstudyid = salesLiftStudyFromAPI.salesliftstudyid;
    // changing window url
    window.history.pushState(
      null,
      document.title,
      `${window.location.href}/${salesliftstudyid}`
    );
    const salesLiftStudyForFrontend = formatSalesLiftStudyForFrontend(
      salesLiftStudyFromAPI
    );
    yield put(
      addSalesLiftStudyToCurrentGroup(
        salesLiftStudyForFrontend.salesliftstudyid
      )
    );
    yield put(setCurrentSalesLiftStudy(salesLiftStudyForFrontend));
    yield put(setOriginalSalesLiftStudy(salesLiftStudyForFrontend));
    yield put(addSalesLiftStudyToEntities(salesLiftStudyForFrontend));

    if (salesLiftStudyForFrontend && salesLiftStudyForFrontend.dimensions) {
      const filteredDimensions = salesLiftStudyForFrontend.dimensions.filter(
        (x) => x.category === 'targetTransactions'
      );
      if (filteredDimensions.length) {
        yield put(setTargetTransactions(filteredDimensions[0]));
      }
    }
    yield put(setAllValid());
    yield put(setSalesLiftStudyLoadingState({ state: 'done' }));
  } catch (e) {
    console.log('error:', e);
    yield put(setSalesLiftStudyLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Create Sales Lift Study`,
      })
    );
  }
}

function* watchExportSalesLiftStudy(action: PayloadAction<any>) {
  yield put(setSalesLiftStudyExporting(true));
  try {
    const { emails, mediaspend, salesliftstudyid } = action.payload;
    const emailArray = emails.split(',');
    const emailPromises = [];
    for (const email of emailArray) {
      if (email && mediaspend && salesliftstudyid) {
        emailPromises.push(
          call(handleExportSalesLiftStudy, {
            email: email.split(' ').join(''),
            mediaspend,
            salesliftstudyid,
          })
        );
      }
    }
    const results = yield all(emailPromises);
    console.log('results ', results);
    for (const email of emailArray) {
      yield put(
        addNotification({
          state: 'done',
          message: `Exported Sales Lift Study to ${email}, a link to a csv file should show up in your email shortly`,
        })
      );
    }
  } catch (e) {
    console.log('error', e);
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Export Sales Lift Study`,
      })
    );
  }
  yield put(setSalesLiftStudyExporting(false));
}

function* watchFetchSalesLiftStudy(action) {
  try {
    const salesLiftStudyFromFrontend = action.payload;
    // turning on loading state
    yield put(setSalesLiftStudyLoadingState({ state: 'loading' }));
    // setting initial sales lift study
    yield put(setCurrentSalesLiftStudy(salesLiftStudyFromFrontend));
    const result = yield call(
      handleFetchSalesLiftStudy,
      salesLiftStudyFromFrontend
    );
    const salesLiftForFrontend = formatSalesLiftStudyForFrontend(result.data);
    yield put(setOriginalSalesLiftStudy(salesLiftForFrontend));
    yield put(setCurrentSalesLiftStudy(salesLiftForFrontend));
    yield put(addSalesLiftStudyToEntities(salesLiftForFrontend));
    yield put(setAllValid());
    yield put(setSalesLiftStudyLoadingState({ state: 'done' }));
  } catch (e) {
    yield put(
      setUIState({
        value: true,
        uiPropertyName: 'errorFetching',
      })
    );
    yield put(setSalesLiftStudyLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Retrieve Sales Lift Study ${action.payload.name}`,
      })
    );
    console.log('Error:', e);
    yield put(setSalesLiftStudyLoadingState({ state: 'done' }));
  }
}

function* watchSaveSalesLiftStudy(action: PayloadAction<SalesLiftStudy>) {
  yield put(setSalesLiftStudiesLoadingState({ state: 'loading' }));
  try {
    yield put(setSalesLiftDimensionDialogOpen(false));
    const salesLiftStudyFromFrontend = reformatSalesLiftStudyForAPI(
      action.payload
    );
    yield put(setSalesLiftStudyLoadingState({ state: 'loading' }));
    const result = yield call(
      handleUpdateSalesLiftStudy,
      salesLiftStudyFromFrontend
    );
    const salesLiftForFrontend = formatSalesLiftStudyForFrontend(result.data);
    yield put(setOriginalSalesLiftStudy(salesLiftForFrontend));
    yield put(setCurrentSalesLiftStudy(salesLiftForFrontend));
    if (
      salesLiftForFrontend &&
      salesLiftForFrontend.dimensions &&
      salesLiftForFrontend.dimensions.length
    ) {
      const targetTransactions = salesLiftForFrontend.dimensions.filter(
        (x) => x.category == 'targetTransactions'
      );
      if (targetTransactions && targetTransactions[0]) {
        yield put(setTargetTransactions(targetTransactions[0]));
      }
    }
    yield put(setAllValid());
    yield put(addSalesLiftStudyToEntities(salesLiftForFrontend));
    yield put(setSalesLiftStudyLoadingState({ state: 'done' }));
  } catch (e) {
    console.log('error', e);
    yield put(setSalesLiftStudyLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Save Sales Lift Study`,
      })
    );
  }
  yield put(setSalesLiftStudiesLoadingState({ state: 'done' }));
}
interface Condition {
  operator: string;
  qualifier: string;
  condition: string;
  salesliftconditionid: string;
  audienceSize?: string;
}

interface TargetTransaction {
  connector: string;
  conditions: Array<Condition>;
  totalAudienceSize: string;
}

function* watchSetTargetTransactions(action: PayloadAction<TargetTransaction>) {
  // setting query loading to true
  yield put(setQueryLoading(true));
  try {
    const targetTransactions = _.clone(action.payload);
    const conditionsFormattedForConditionQuery = [];
    const stack = [];
    const queryParams = [];
    // checking if target transactions true and if conditions and conditoins length
    if (
      targetTransactions &&
      targetTransactions.conditions &&
      targetTransactions.conditions.filter((x) => x.qualifier).length
    ) {
      let conditions = _.clone(targetTransactions.conditions);
      for (const condition of conditions) {
        if (condition.qualifier && condition.qualifier.length)
          conditionsFormattedForConditionQuery.push({
            condition: {
              category: 'merchant',
              name: condition.qualifier,
              type: { operator: condition.operator },
            },
            id: condition.salesliftconditionid,
          });
      }
      for (const conditionForConditionQuery of conditionsFormattedForConditionQuery) {
        stack.push(1);
        queryParams.push({
          ...conditionForConditionQuery,
          id: String(conditionForConditionQuery.id),
        });
        if (stack.length === 2) {
          stack.pop();
          queryParams.push({
            id: nanoid(),
            connector: targetTransactions.connector,
          });
        }
      }
      console.log('here are the query params:', queryParams);
      const results = yield call(handleQuery, queryParams);
      const queryResults = results.data;
      console.log('Query Results:', queryResults);
      for (const queryResult of queryResults) {
        const { row, cnt } = queryResult;
        const filteredArray = conditions.filter(
          (x) => x.salesliftconditionid == row
        );
        if (filteredArray.length) {
          const condition = filteredArray[0];
          conditions = conditions.map((x) => {
            if (x.salesliftconditionid == row)
              return { ...condition, audienceSize: cnt };
            return x;
          });
        }
      }
      const lastRowId = queryParams.pop().id;
      targetTransactions.conditions = conditions;
      if (
        queryResults &&
        queryResults.filter((x) => x.row == lastRowId) &&
        queryResults.filter((x) => x.row == lastRowId)[0] &&
        queryResults.filter((x) => x.row == lastRowId)[0].cnt
      )
        targetTransactions.totalAudienceSize = queryResults.filter(
          (x) => x.row == lastRowId
        )[0].cnt;
    }
    yield put(updateTargetTransaction(targetTransactions));
  } catch (e) {
    console.log('errors', e);
  }
  yield put(setQueryLoading(false));
}

function* watchDeleteSalesLiftStudy(action: PayloadAction<SalesLiftStudy>) {
  try {
    const salesliftstudy = action.payload;
    yield call(handleDeleteSalesLiftStudy, salesliftstudy);
    yield put(setIsDisabled(salesliftstudy.salesliftstudyid));
    yield put(setCurrentSalesLiftStudy(null));
  } catch (e) {}
}

function* watchSearchSalesLifts(action) {
  try {
    yield put(setGroupLoadingState({ state: 'loading' }));
    const search = action.payload;
    const result = yield call(handleSearchSalesLifts, search);
    const salesLifts = result.data;
    for (const salesLift of salesLifts) {
      yield put(addSalesLiftStudyToEntities(salesLift));
    }
    yield put(setGroupLoadingState({ state: 'done' }));
  } catch (e) {
    console.log('Error:', e);
  }
}

export default function* watchAll() {
  yield takeLatest<any>(createSalesLiftStudy.type, watchCreateSalesLiftStudy);
  yield takeLatest<any>(fetchSalesLiftStudy.type, watchFetchSalesLiftStudy);
  yield takeLatest<any>(saveSalesLiftStudy.type, watchSaveSalesLiftStudy);
  yield takeLatest<any>(searchSalesLifts.type, watchSearchSalesLifts);
  yield takeLatest<any>(setTargetTransactions.type, watchSetTargetTransactions);
  yield takeLatest<any>(deleteSalesLiftStudy.type, watchDeleteSalesLiftStudy);
  yield takeLatest<any>(exportSalesLiftStudy.type, watchExportSalesLiftStudy);
}

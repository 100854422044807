import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const StyledTypography = styled(Typography)`
  height: 100%;
  padding: 0;
  font-style: normal;
  font-size: 16;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledPaper = styled(Paper)`
  ${StyledTypography}
  height: 28px;
  padding: 0 8px;
  & h5 {
    color: #fff !important;
  }
  background-color: #000;
`;

import observationTimeInputs from './observationTimeInputs';

const observationTimeDimensionConfig = {
  dimensionName: 'Observation Time',
  stepperDimensionName: 'Set study dates & timeframe',
  stepperDimensionTitle: 'Observation Time',
  inputs: observationTimeInputs,
};

export default observationTimeDimensionConfig;

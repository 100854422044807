interface DataTableRowProps {
  row: any;
  details?: any;
}

export const DataTableRow = ({ row, details }: DataTableRowProps) => {
  return (
    <>
      <tr>{row}</tr>

      {details && <tr>{details}</tr>}
    </>
  );
};

export default DataTableRow;

export const currentAudience = (state) => state.audienceReducer.currentAudience;

export const currentConditions = (state) =>
  state.audienceReducer.currentConditions;

export const currentDimension = (state) =>
  state.audienceReducer.currentDimension;

export const currentDimensionGroups = (state) =>
  state.audienceReducer.currentDimensionGroups;

export const currentDimensions = (state) =>
  state.audienceReducer.currentDimensions;

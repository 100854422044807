import { StringMap } from 'interfaces';

export const MerchantCategories: StringMap = {
  'Arts and Entertainment > Movie Theatres':
    'Arts and Entertainment^Movie Theatres',
  'Arts and Entertainment > Museums': 'Arts and Entertainment^Museums',
  'Arts and Entertainment > Theatrical Productions':
    'Arts and Entertainment^Theatrical Productions',
  'Bank Fees': 'Bank Fees',
  'Bank Fees > ATM': 'Bank Fees^ATM',
  'Bank Fees > Cash Advance': 'Bank Fees^Cash Advance',
  'Bank Fees > Excess Activity': 'Bank Fees^Excess Activity',
  'Bank Fees > Foreign Transaction': 'Bank Fees^Foreign Transaction',
  'Bank Fees > Fraud Dispute': 'Bank Fees^Fraud Dispute',
  'Bank Fees > Insufficient Funds': 'Bank Fees^Insufficient Funds',
  'Bank Fees > Late Payment': 'Bank Fees^Late Payment',
  'Bank Fees > Overdraft': 'Bank Fees^Overdraft',
  'Bank Fees > Wire Transfer': 'Bank Fees^Wire Transfer',
  'Cash Advance': 'Cash Advance',
  Community: 'Community',
  'Community > Animal Shelter': 'Community^Animal Shelter',
  'Community > Assisted Living Services': 'Community^Assisted Living Services',
  'Community > Assisted Living Services > Caretakers':
    'Community^Assisted Living Services^Caretakers',
  'Community > Assisted Living Services > Facilities and Nursing Homes':
    'Community^Assisted Living Services^Facilities and Nursing Homes',
  'Community > Cemetery': 'Community^Cemetery',
  'Community > Courts': 'Community^Courts',
  'Community > Day Care and Preschools': 'Community^Day Care and Preschools',
  'Community > Disabled Persons Services':
    'Community^Disabled Persons Services',
  'Community > Drug and Alcohol Services':
    'Community^Drug and Alcohol Services',
  'Community > Education': 'Community^Education',
  'Community > Education > Art School': 'Community^Education^Art School',
  'Community > Education > Colleges and Universities':
    'Community^Education^Colleges and Universities',
  'Community > Education > Dance Schools': 'Community^Education^Dance Schools',
  'Community > Education > Fraternities and Sororities':
    'Community^Education^Fraternities and Sororities',
  'Community > Education > Primary and Secondary Schools':
    'Community^Education^Primary and Secondary Schools',
  'Community > Education > Tutoring and Educational Services':
    'Community^Education^Tutoring and Educational Services',
  'Community > Education > Vocational Schools':
    'Community^Education^Vocational Schools',
  'Community > Government Departments and Agencies':
    'Community^Government Departments and Agencies',
  'Community > Housing Assistance and Shelters':
    'Community^Housing Assistance and Shelters',
  'Community > Law Enforcement': 'Community^Law Enforcement',
  'Community > Law Enforcement > Correctional Institutions':
    'Community^Law Enforcement^Correctional Institutions',
  'Community > Law Enforcement > Fire Stations':
    'Community^Law Enforcement^Fire Stations',
  'Community > Law Enforcement > Police Stations':
    'Community^Law Enforcement^Police Stations',
  'Community > Libraries': 'Community^Libraries',
  'Community > Military': 'Community^Military',
  'Community > Nurseries and Garden Centers':
    'Community^Nurseries and Garden Centers',
  'Community > Organizations and Associations':
    'Community^Organizations and Associations',
  'Community > Organizations and Associations > Charities and Non-Profits':
    'Community^Organizations and Associations^Charities and Non-Profits',
  'Community > Post Offices': 'Community^Post Offices',
  'Community > Public and Social Services':
    'Community^Public and Social Services',
  'Community > Religious': 'Community^Religious',
  'Community > Religious > Churches': 'Community^Religious^Churches',
  'Community > Religious > Mosques': 'Community^Religious^Mosques',
  'Community > Religious > Temple': 'Community^Religious^Temple',
  'Community > Senior Citizen Services': 'Community^Senior Citizen Services',
  'Food and Drink': 'Food and Drink',
  'Food and Drink > Bar': 'Food and Drink^Bar',
  'Food and Drink > Bar > Sports Bar': 'Food and Drink^Bar^Sports Bar',
  'Food and Drink > Bar > Wine Bar': 'Food and Drink^Bar^Wine Bar',
  'Food and Drink > Breweries': 'Food and Drink^Breweries',
  'Food and Drink > Internet Cafes': 'Food and Drink^Internet Cafes',
  'Food and Drink > Nightlife': 'Food and Drink^Nightlife',
  'Food and Drink > Nightlife > Adult Entertainment':
    'Food and Drink^Nightlife^Adult Entertainment',
  'Food and Drink > Nightlife > Jazz and Blues Cafe':
    'Food and Drink^Nightlife^Jazz and Blues Cafe',
  'Food and Drink > Nightlife > Karaoke': 'Food and Drink^Nightlife^Karaoke',
  'Food and Drink > Nightlife > Night Clubs':
    'Food and Drink^Nightlife^Night Clubs',
  'Food and Drink > Restaurants': 'Food and Drink^Restaurants',
  'Food and Drink > Restaurants > Afghan': 'Food and Drink^Restaurants^Afghan',
  'Food and Drink > Restaurants > African':
    'Food and Drink^Restaurants^African',
  'Food and Drink > Restaurants > American':
    'Food and Drink^Restaurants^American',
  'Food and Drink > Restaurants > Asian': 'Food and Drink^Restaurants^Asian',
  'Food and Drink > Restaurants > Australian':
    'Food and Drink^Restaurants^Australian',
  'Food and Drink > Restaurants > Bagel Shop':
    'Food and Drink^Restaurants^Bagel Shop',
  'Food and Drink > Restaurants > Bakery': 'Food and Drink^Restaurants^Bakery',
  'Food and Drink > Restaurants > Barbecue':
    'Food and Drink^Restaurants^Barbecue',
  'Food and Drink > Restaurants > Brazilian':
    'Food and Drink^Restaurants^Brazilian',
  'Food and Drink > Restaurants > Breakfast Spot':
    'Food and Drink^Restaurants^Breakfast Spot',
  'Food and Drink > Restaurants > Burgers':
    'Food and Drink^Restaurants^Burgers',
  'Food and Drink > Restaurants > Burrito':
    'Food and Drink^Restaurants^Burrito',
  'Food and Drink > Restaurants > Cafe': 'Food and Drink^Restaurants^Cafe',
  'Food and Drink > Restaurants > Cajun': 'Food and Drink^Restaurants^Cajun',
  'Food and Drink > Restaurants > Caribbean':
    'Food and Drink^Restaurants^Caribbean',
  'Food and Drink > Restaurants > Chinese':
    'Food and Drink^Restaurants^Chinese',
  'Food and Drink > Restaurants > Coffee Shop':
    'Food and Drink^Restaurants^Coffee Shop',
  'Food and Drink > Restaurants > Cuban': 'Food and Drink^Restaurants^Cuban',
  'Food and Drink > Restaurants > Cupcake Shop':
    'Food and Drink^Restaurants^Cupcake Shop',
  'Food and Drink > Restaurants > Delis': 'Food and Drink^Restaurants^Delis',
  'Food and Drink > Restaurants > Dessert':
    'Food and Drink^Restaurants^Dessert',
  'Food and Drink > Restaurants > Diners': 'Food and Drink^Restaurants^Diners',
  'Food and Drink > Restaurants > Distillery':
    'Food and Drink^Restaurants^Distillery',
  'Food and Drink > Restaurants > Donuts': 'Food and Drink^Restaurants^Donuts',
  'Food and Drink > Restaurants > Eastern European':
    'Food and Drink^Restaurants^Eastern European',
  'Food and Drink > Restaurants > Ethiopian':
    'Food and Drink^Restaurants^Ethiopian',
  'Food and Drink > Restaurants > Falafel':
    'Food and Drink^Restaurants^Falafel',
  'Food and Drink > Restaurants > Fast Food':
    'Food and Drink^Restaurants^Fast Food',
  'Food and Drink > Restaurants > Filipino':
    'Food and Drink^Restaurants^Filipino',
  'Food and Drink > Restaurants > Fish and Chips':
    'Food and Drink^Restaurants^Fish and Chips',
  'Food and Drink > Restaurants > Food Truck':
    'Food and Drink^Restaurants^Food Truck',
  'Food and Drink > Restaurants > French': 'Food and Drink^Restaurants^French',
  'Food and Drink > Restaurants > Gastropub':
    'Food and Drink^Restaurants^Gastropub',
  'Food and Drink > Restaurants > German': 'Food and Drink^Restaurants^German',
  'Food and Drink > Restaurants > Greek': 'Food and Drink^Restaurants^Greek',
  'Food and Drink > Restaurants > Ice Cream':
    'Food and Drink^Restaurants^Ice Cream',
  'Food and Drink > Restaurants > Indian': 'Food and Drink^Restaurants^Indian',
  'Food and Drink > Restaurants > Indonesian':
    'Food and Drink^Restaurants^Indonesian',
  'Food and Drink > Restaurants > Italian':
    'Food and Drink^Restaurants^Italian',
  'Food and Drink > Restaurants > Japanese':
    'Food and Drink^Restaurants^Japanese',
  'Food and Drink > Restaurants > Juice Bar':
    'Food and Drink^Restaurants^Juice Bar',
  'Food and Drink > Restaurants > Korean': 'Food and Drink^Restaurants^Korean',
  'Food and Drink > Restaurants > Latin American':
    'Food and Drink^Restaurants^Latin American',
  'Food and Drink > Restaurants > Mediterranean':
    'Food and Drink^Restaurants^Mediterranean',
  'Food and Drink > Restaurants > Mexican':
    'Food and Drink^Restaurants^Mexican',
  'Food and Drink > Restaurants > Middle Eastern':
    'Food and Drink^Restaurants^Middle Eastern',
  'Food and Drink > Restaurants > Moroccan':
    'Food and Drink^Restaurants^Moroccan',
  'Food and Drink > Restaurants > Pizza': 'Food and Drink^Restaurants^Pizza',
  'Food and Drink > Restaurants > Portuguese':
    'Food and Drink^Restaurants^Portuguese',
  'Food and Drink > Restaurants > Scandinavian':
    'Food and Drink^Restaurants^Scandinavian',
  'Food and Drink > Restaurants > Seafood':
    'Food and Drink^Restaurants^Seafood',
  'Food and Drink > Restaurants > Spanish':
    'Food and Drink^Restaurants^Spanish',
  'Food and Drink > Restaurants > Steakhouses':
    'Food and Drink^Restaurants^Steakhouses',
  'Food and Drink > Restaurants > Sushi': 'Food and Drink^Restaurants^Sushi',
  'Food and Drink > Restaurants > Swiss': 'Food and Drink^Restaurants^Swiss',
  'Food and Drink > Restaurants > Thai': 'Food and Drink^Restaurants^Thai',
  'Food and Drink > Restaurants > Turkish':
    'Food and Drink^Restaurants^Turkish',
  'Food and Drink > Restaurants > Vegan and Vegetarian':
    'Food and Drink^Restaurants^Vegan and Vegetarian',
  'Food and Drink > Restaurants > Winery': 'Food and Drink^Restaurants^Winery',
  Healthcare: 'Healthcare',
  'Healthcare > Healthcare Services': 'Healthcare^Healthcare Services',
  'Healthcare > Healthcare Services > Alternative Medicine':
    'Healthcare^Healthcare Services^Alternative Medicine',
  'Healthcare > Healthcare Services > Chiropractors':
    'Healthcare^Healthcare Services^Chiropractors',
  'Healthcare > Healthcare Services > Dentists':
    'Healthcare^Healthcare Services^Dentists',
  'Healthcare > Healthcare Services > Emergency Services':
    'Healthcare^Healthcare Services^Emergency Services',
  'Healthcare > Healthcare Services > Hospitals, Clinics and Medical Centers':
    'Healthcare^Healthcare Services^Hospitals, Clinics and Medical Centers',
  'Healthcare > Healthcare Services > Hospitals/Clinics and Medical Centers':
    'Healthcare^Healthcare Services^Hospitals/Clinics and Medical Centers',
  'Healthcare > Healthcare Services > Medical Supplies and Labs':
    'Healthcare^Healthcare Services^Medical Supplies and Labs',
  'Healthcare > Healthcare Services > Mental Health':
    'Healthcare^Healthcare Services^Mental Health',
  'Healthcare > Healthcare Services > Nurses':
    'Healthcare^Healthcare Services^Nurses',
  'Healthcare > Healthcare Services > Nutritionists':
    'Healthcare^Healthcare Services^Nutritionists',
  'Healthcare > Healthcare Services > Optometrists':
    'Healthcare^Healthcare Services^Optometrists',
  'Healthcare > Healthcare Services > Physical Therapy':
    'Healthcare^Healthcare Services^Physical Therapy',
  'Healthcare > Healthcare Services > Podiatrists':
    'Healthcare^Healthcare Services^Podiatrists',
  'Healthcare > Healthcare Services > Pregnancy and Sexual Health':
    'Healthcare^Healthcare Services^Pregnancy and Sexual Health',
  'Healthcare > Physicians': 'Healthcare^Physicians',
  'Healthcare > Physicians > Anesthesiologists':
    'Healthcare^Physicians^Anesthesiologists',
  'Healthcare > Physicians > Cardiologists':
    'Healthcare^Physicians^Cardiologists',
  'Healthcare > Physicians > Dermatologists':
    'Healthcare^Physicians^Dermatologists',
  'Healthcare > Physicians > Ear/Nose and Throat':
    'Healthcare^Physicians^Ear/Nose and Throat',
  'Healthcare > Physicians > Gastroenterologists':
    'Healthcare^Physicians^Gastroenterologists',
  'Healthcare > Physicians > Neurologists':
    'Healthcare^Physicians^Neurologists',
  'Healthcare > Physicians > Obstetricians and Gynecologists':
    'Healthcare^Physicians^Obstetricians and Gynecologists',
  'Healthcare > Physicians > Oncologists': 'Healthcare^Physicians^Oncologists',
  'Healthcare > Physicians > Orthopedic Surgeons':
    'Healthcare^Physicians^Orthopedic Surgeons',
  'Healthcare > Physicians > Pathologists':
    'Healthcare^Physicians^Pathologists',
  'Healthcare > Physicians > Pediatricians':
    'Healthcare^Physicians^Pediatricians',
  'Healthcare > Physicians > Plastic Surgeons':
    'Healthcare^Physicians^Plastic Surgeons',
  'Healthcare > Physicians > Psychiatrists':
    'Healthcare^Physicians^Psychiatrists',
  'Healthcare > Physicians > Radiologists':
    'Healthcare^Physicians^Radiologists',
  'Healthcare > Physicians > Urologists': 'Healthcare^Physicians^Urologists',
  'Interest > Interest Charged': 'Interest^Interest Charged',
  'Interest > Interest Earned': 'Interest^Interest Earned',
  Payment: 'Payment',
  'Payment > Credit Card': 'Payment^Credit Card',
  'Payment > Loan': 'Payment^Loan',
  'Payment > Rent': 'Payment^Rent',
  'Personal Care > Laundry and Garmet Services':
    'Personal Care^Laundry and Garmet Services',
  Recreation: 'Recreation',
  'Recreation > Arts and Entertainment': 'Recreation^Arts and Entertainment',
  'Recreation > Arts and Entertainment > Aquarium':
    'Recreation^Arts and Entertainment^Aquarium',
  'Recreation > Arts and Entertainment > Arcades and Amusement Parks':
    'Recreation^Arts and Entertainment^Arcades and Amusement Parks',
  'Recreation > Arts and Entertainment > Art Dealers and Galleries':
    'Recreation^Arts and Entertainment^Art Dealers and Galleries',
  'Recreation > Arts and Entertainment > Billiards and Pool':
    'Recreation^Arts and Entertainment^Billiards and Pool',
  'Recreation > Arts and Entertainment > Bowling':
    'Recreation^Arts and Entertainment^Bowling',
  'Recreation > Arts and Entertainment > Casinos and Gaming':
    'Recreation^Arts and Entertainment^Casinos and Gaming',
  'Recreation > Arts and Entertainment > Circuses and Carnivals':
    'Recreation^Arts and Entertainment^Circuses and Carnivals',
  'Recreation > Arts and Entertainment > Fairgrounds and Rodeos':
    'Recreation^Arts and Entertainment^Fairgrounds and Rodeos',
  'Recreation > Arts and Entertainment > Movie Theatres':
    'Recreation^Arts and Entertainment^Movie Theatres',
  'Recreation > Arts and Entertainment > Museums':
    'Recreation^Arts and Entertainment^Museums',
  'Recreation > Arts and Entertainment > Music and Show Venues':
    'Recreation^Arts and Entertainment^Music and Show Venues',
  'Recreation > Arts and Entertainment > Psychics and Astrologers':
    'Recreation^Arts and Entertainment^Psychics and Astrologers',
  'Recreation > Arts and Entertainment > Sports Venues':
    'Recreation^Arts and Entertainment^Sports Venues',
  'Recreation > Arts and Entertainment > Symphony and Opera':
    'Recreation^Arts and Entertainment^Symphony and Opera',
  'Recreation > Arts and Entertainment > Theatrical Productions':
    'Recreation^Arts and Entertainment^Theatrical Productions',
  'Recreation > Baseball': 'Recreation^Baseball',
  'Recreation > Basketball': 'Recreation^Basketball',
  'Recreation > Campgrounds and RV Parks':
    'Recreation^Campgrounds and RV Parks',
  'Recreation > Cycling': 'Recreation^Cycling',
  'Recreation > Football': 'Recreation^Football',
  'Recreation > Golf': 'Recreation^Golf',
  'Recreation > Gymnastics': 'Recreation^Gymnastics',
  'Recreation > Gyms and Fitness Centers':
    'Recreation^Gyms and Fitness Centers',
  'Recreation > Hockey': 'Recreation^Hockey',
  'Recreation > Hunting and Fishing': 'Recreation^Hunting and Fishing',
  'Recreation > Landmarks': 'Recreation^Landmarks',
  'Recreation > Landmarks > Buildings and Structures':
    'Recreation^Landmarks^Buildings and Structures',
  'Recreation > Landmarks > Gardens': 'Recreation^Landmarks^Gardens',
  'Recreation > Landmarks > Historic Sites':
    'Recreation^Landmarks^Historic Sites',
  'Recreation > Landmarks > Monuments and Memorials':
    'Recreation^Landmarks^Monuments and Memorials',
  'Recreation > Outdoors': 'Recreation^Outdoors',
  'Recreation > Outdoors > Beaches': 'Recreation^Outdoors^Beaches',
  'Recreation > Outdoors > Lakes': 'Recreation^Outdoors^Lakes',
  'Recreation > Outdoors > Mountains': 'Recreation^Outdoors^Mountains',
  'Recreation > Outdoors > Rivers': 'Recreation^Outdoors^Rivers',
  'Recreation > Paintball': 'Recreation^Paintball',
  'Recreation > Parks': 'Recreation^Parks',
  'Recreation > Parks > Playgrounds': 'Recreation^Parks^Playgrounds',
  'Recreation > Race Tracks': 'Recreation^Race Tracks',
  'Recreation > Rock Climbing': 'Recreation^Rock Climbing',
  'Recreation > Running': 'Recreation^Running',
  'Recreation > Scuba Diving': 'Recreation^Scuba Diving',
  'Recreation > Skating': 'Recreation^Skating',
  'Recreation > Soccer': 'Recreation^Soccer',
  'Recreation > Sports and Recreation Camps':
    'Recreation^Sports and Recreation Camps',
  'Recreation > Sports Clubs': 'Recreation^Sports Clubs',
  'Recreation > Stadiums and Arenas': 'Recreation^Stadiums and Arenas',
  'Recreation > Swimming': 'Recreation^Swimming',
  'Recreation > Tennis': 'Recreation^Tennis',
  'Recreation > Yoga and Pilates': 'Recreation^Yoga and Pilates',
  'Recreation > Zoo': 'Recreation^Zoo',
  Service: 'Service',
  'Service > Advertising and Marketing': 'Service^Advertising and Marketing',
  'Service > Advertising and Marketing > Advertising Agencies and Media Buyers':
    'Service^Advertising and Marketing^Advertising Agencies and Media Buyers',
  'Service > Advertising and Marketing > Direct Mail and Email Marketing Services':
    'Service^Advertising and Marketing^Direct Mail and Email Marketing Services',
  'Service > Advertising and Marketing > Online Advertising':
    'Service^Advertising and Marketing^Online Advertising',
  'Service > Advertising and Marketing > Print, TV, Radio and Outdoor Advertising':
    'Service^Advertising and Marketing^Print, TV, Radio and Outdoor Advertising',
  'Service > Advertising and Marketing > Public Relations':
    'Service^Advertising and Marketing^Public Relations',
  'Service > Advertising and Marketing > Writing, Copywriting and Technical Writing':
    'Service^Advertising and Marketing^Writing, Copywriting and Technical Writing',
  'Service > Agriculture and Forestry': 'Service^Agriculture and Forestry',
  'Service > Agriculture and Forestry > Crop Production':
    'Service^Agriculture and Forestry^Crop Production',
  'Service > Agriculture and Forestry > Livestock and Animals':
    'Service^Agriculture and Forestry^Livestock and Animals',
  'Service > Agriculture and Forestry > Services':
    'Service^Agriculture and Forestry^Services',
  'Service > Art and Graphic Design': 'Service^Art and Graphic Design',
  'Service > Automation and Control Systems':
    'Service^Automation and Control Systems',
  'Service > Automotive': 'Service^Automotive',
  'Service > Automotive > Auto Oil and Lube':
    'Service^Automotive^Auto Oil and Lube',
  'Service > Automotive > Auto Tires': 'Service^Automotive^Auto Tires',
  'Service > Automotive > Car Appraisers': 'Service^Automotive^Car Appraisers',
  'Service > Automotive > Car Wash and Detail':
    'Service^Automotive^Car Wash and Detail',
  'Service > Automotive > Maintenance and Repair':
    'Service^Automotive^Maintenance and Repair',
  'Service > Automotive > Motorcycle, Moped and Scooter Repair':
    'Service^Automotive^Motorcycle, Moped and Scooter Repair',
  'Service > Automotive > Towing': 'Service^Automotive^Towing',
  'Service > Business and Strategy Consulting':
    'Service^Business and Strategy Consulting',
  'Service > Business Services': 'Service^Business Services',
  'Service > Business Services > Printing and Publishing':
    'Service^Business Services^Printing and Publishing',
  'Service > Cable': 'Service^Cable',
  'Service > Chemicals and Gasses': 'Service^Chemicals and Gasses',
  'Service > Cleaning': 'Service^Cleaning',
  'Service > Computers': 'Service^Computers',
  'Service > Computers > Software Development':
    'Service^Computers^Software Development',
  'Service > Construction': 'Service^Construction',
  'Service > Construction > Carpenters': 'Service^Construction^Carpenters',
  'Service > Construction > Contractors': 'Service^Construction^Contractors',
  'Service > Construction > Electricians': 'Service^Construction^Electricians',
  'Service > Construction > Infrastructure':
    'Service^Construction^Infrastructure',
  'Service > Construction > Masonry': 'Service^Construction^Masonry',
  'Service > Construction > Painting': 'Service^Construction^Painting',
  'Service > Construction > Roofers': 'Service^Construction^Roofers',
  'Service > Construction > Specialty': 'Service^Construction^Specialty',
  'Service > Credit Counseling and Bankruptcy Services':
    'Service^Credit Counseling and Bankruptcy Services',
  'Service > Dating and Escort': 'Service^Dating and Escort',
  'Service > Employment Agencies': 'Service^Employment Agencies',
  'Service > Engineering': 'Service^Engineering',
  'Service > Entertainment': 'Service^Entertainment',
  'Service > Events and Event Planning': 'Service^Events and Event Planning',
  'Service > Financial': 'Service^Financial',
  'Service > Financial > Accounting and Bookkeeping':
    'Service^Financial^Accounting and Bookkeeping',
  'Service > Financial > ATMs': 'Service^Financial^ATMs',
  'Service > Financial > Banking and Finance':
    'Service^Financial^Banking and Finance',
  'Service > Financial > Business Brokers and Franchises':
    'Service^Financial^Business Brokers and Franchises',
  'Service > Financial > Check Cashing': 'Service^Financial^Check Cashing',
  'Service > Financial > Collections': 'Service^Financial^Collections',
  'Service > Financial > Financial Planning and Investments':
    'Service^Financial^Financial Planning and Investments',
  'Service > Financial > Fund Raising': 'Service^Financial^Fund Raising',
  'Service > Financial > Holding and Investment Offices':
    'Service^Financial^Holding and Investment Offices',
  'Service > Financial > Loans and Mortgages':
    'Service^Financial^Loans and Mortgages',
  'Service > Financial > Stock Brokers': 'Service^Financial^Stock Brokers',
  'Service > Financial > Taxes': 'Service^Financial^Taxes',
  'Service > Food and Beverage': 'Service^Food and Beverage',
  'Service > Food and Beverage > Catering':
    'Service^Food and Beverage^Catering',
  'Service > Food and Beverage > Distribution':
    'Service^Food and Beverage^Distribution',
  'Service > Funeral Services': 'Service^Funeral Services',
  'Service > Geological': 'Service^Geological',
  'Service > Home Improvement': 'Service^Home Improvement',
  'Service > Home Improvement > Architects':
    'Service^Home Improvement^Architects',
  'Service > Home Improvement > Contractors':
    'Service^Home Improvement^Contractors',
  'Service > Home Improvement > Fences, Fireplaces and Garage Doors':
    'Service^Home Improvement^Fences, Fireplaces and Garage Doors',
  'Service > Home Improvement > Fences/Fireplaces and Garage Doors':
    'Service^Home Improvement^Fences/Fireplaces and Garage Doors',
  'Service > Home Improvement > Hardware and Services':
    'Service^Home Improvement^Hardware and Services',
  'Service > Home Improvement > Home Appliances':
    'Service^Home Improvement^Home Appliances',
  'Service > Home Improvement > Home Inspection Services':
    'Service^Home Improvement^Home Inspection Services',
  'Service > Home Improvement > Housewares':
    'Service^Home Improvement^Housewares',
  'Service > Home Improvement > Interior Design':
    'Service^Home Improvement^Interior Design',
  'Service > Home Improvement > Landscaping and Gardeners':
    'Service^Home Improvement^Landscaping and Gardeners',
  'Service > Home Improvement > Lighting Fixtures':
    'Service^Home Improvement^Lighting Fixtures',
  'Service > Home Improvement > Movers': 'Service^Home Improvement^Movers',
  'Service > Home Improvement > Pest Control':
    'Service^Home Improvement^Pest Control',
  'Service > Home Improvement > Plumbing': 'Service^Home Improvement^Plumbing',
  'Service > Home Improvement > Pools and Spas':
    'Service^Home Improvement^Pools and Spas',
  'Service > Home Improvement > Storage': 'Service^Home Improvement^Storage',
  'Service > Home Improvement > Swimming Pool Maintenance and Services':
    'Service^Home Improvement^Swimming Pool Maintenance and Services',
  'Service > Home Improvement > Tree Service':
    'Service^Home Improvement^Tree Service',
  'Service > Home Improvement > Upholstery':
    'Service^Home Improvement^Upholstery',
  'Service > Household': 'Service^Household',
  'Service > Industrial Machinery and Vehicles':
    'Service^Industrial Machinery and Vehicles',
  'Service > Insurance': 'Service^Insurance',
  'Service > Internet Services': 'Service^Internet Services',
  'Service > Legal': 'Service^Legal',
  'Service > Machine Shops': 'Service^Machine Shops',
  'Service > Management': 'Service^Management',
  'Service > Manufacturing': 'Service^Manufacturing',
  'Service > Manufacturing > Apparel and Fabric Products':
    'Service^Manufacturing^Apparel and Fabric Products',
  'Service > Manufacturing > Chemicals and Gasses':
    'Service^Manufacturing^Chemicals and Gasses',
  'Service > Manufacturing > Electrical Equipment and Components':
    'Service^Manufacturing^Electrical Equipment and Components',
  'Service > Manufacturing > Food and Beverage':
    'Service^Manufacturing^Food and Beverage',
  'Service > Manufacturing > Industrial Machinery and Equipment':
    'Service^Manufacturing^Industrial Machinery and Equipment',
  'Service > Manufacturing > Metal Products':
    'Service^Manufacturing^Metal Products',
  'Service > Manufacturing > Paper Products':
    'Service^Manufacturing^Paper Products',
  'Service > Manufacturing > Petroleum': 'Service^Manufacturing^Petroleum',
  'Service > Manufacturing > Rubber Products':
    'Service^Manufacturing^Rubber Products',
  'Service > Manufacturing > Transportation Equipment':
    'Service^Manufacturing^Transportation Equipment',
  'Service > Manufacturing > Wood Products':
    'Service^Manufacturing^Wood Products',
  'Service > Media Production': 'Service^Media Production',
  'Service > Metals': 'Service^Metals',
  'Service > Oil and Gas': 'Service^Oil and Gas',
  'Service > Personal Care': 'Service^Personal Care',
  'Service > Personal Care > Hair Removal':
    'Service^Personal Care^Hair Removal',
  'Service > Personal Care > Hair Salons and Barbers':
    'Service^Personal Care^Hair Salons and Barbers',
  'Service > Personal Care > Laundry and Garment Services':
    'Service^Personal Care^Laundry and Garment Services',
  'Service > Personal Care > Manicures and Pedicures':
    'Service^Personal Care^Manicures and Pedicures',
  'Service > Personal Care > Massage Clinics and Therapists':
    'Service^Personal Care^Massage Clinics and Therapists',
  'Service > Personal Care > Piercing': 'Service^Personal Care^Piercing',
  'Service > Personal Care > Spas': 'Service^Personal Care^Spas',
  'Service > Personal Care > Tanning Salons':
    'Service^Personal Care^Tanning Salons',
  'Service > Personal Care > Tattooing': 'Service^Personal Care^Tattooing',
  'Service > Photography': 'Service^Photography',
  'Service > Plastics': 'Service^Plastics',
  'Service > Rail': 'Service^Rail',
  'Service > Real Estate': 'Service^Real Estate',
  'Service > Real Estate > Apartments, Condos and Houses':
    'Service^Real Estate^Apartments, Condos and Houses',
  'Service > Real Estate > Apartments/Condos and Houses':
    'Service^Real Estate^Apartments/Condos and Houses',
  'Service > Real Estate > Building and Land Surveyors':
    'Service^Real Estate^Building and Land Surveyors',
  'Service > Real Estate > Commercial Real Estate':
    'Service^Real Estate^Commercial Real Estate',
  'Service > Real Estate > Property Management':
    'Service^Real Estate^Property Management',
  'Service > Real Estate > Real Estate Agents':
    'Service^Real Estate^Real Estate Agents',
  'Service > Real Estate > Real Estate Appraiser':
    'Service^Real Estate^Real Estate Appraiser',
  'Service > Real Estate > Real Estate Development and Title Companies':
    'Service^Real Estate^Real Estate Development and Title Companies',
  'Service > Repair Services': 'Service^Repair Services',
  'Service > Research': 'Service^Research',
  'Service > Security and Safety': 'Service^Security and Safety',
  'Service > Shipping and Freight': 'Service^Shipping and Freight',
  'Service > Software Development': 'Service^Software Development',
  'Service > Storage': 'Service^Storage',
  'Service > Subscription': 'Service^Subscription',
  'Service > Tailors': 'Service^Tailors',
  'Service > Telecommunication Services': 'Service^Telecommunication Services',
  'Service > Textiles': 'Service^Textiles',
  'Service > Transportation': 'Service^Transportation',
  'Service > Travel Agents and Tour Operators':
    'Service^Travel Agents and Tour Operators',
  'Service > Utilities': 'Service^Utilities',
  'Service > Utilities > Electric': 'Service^Utilities^Electric',
  'Service > Utilities > Gas': 'Service^Utilities^Gas',
  'Service > Utilities > Heating, Ventilating, and Air Conditioning':
    'Service^Utilities^Heating, Ventilating, and Air Conditioning',
  'Service > Utilities > Sanitary and Waste Management':
    'Service^Utilities^Sanitary and Waste Management',
  'Service > Utilities > Water': 'Service^Utilities^Water',
  'Service > Veterinarians': 'Service^Veterinarians',
  'Service > Water and Waste Management': 'Service^Water and Waste Management',
  'Service > Web Design and Development': 'Service^Web Design and Development',
  'Service > Welding': 'Service^Welding',
  Shops: 'Shops',
  'Shops > Adult': 'Shops^Adult',
  'Shops > Antiques': 'Shops^Antiques',
  'Shops > Arts and Crafts': 'Shops^Arts and Crafts',
  'Shops > Auctions': 'Shops^Auctions',
  'Shops > Automotive': 'Shops^Automotive',
  'Shops > Automotive > Car Dealers and Leasing':
    'Shops^Automotive^Car Dealers and Leasing',
  'Shops > Automotive > Car Parts and Accessories':
    'Shops^Automotive^Car Parts and Accessories',
  'Shops > Automotive > RVs and Motor Homes':
    'Shops^Automotive^RVs and Motor Homes',
  'Shops > Automotive > Used Car Dealers': 'Shops^Automotive^Used Car Dealers',
  'Shops > Beauty Products': 'Shops^Beauty Products',
  'Shops > Bicycles': 'Shops^Bicycles',
  'Shops > Boat Dealers': 'Shops^Boat Dealers',
  'Shops > Bookstores': 'Shops^Bookstores',
  'Shops > Cards and Stationery': 'Shops^Cards and Stationery',
  'Shops > Children': 'Shops^Children',
  'Shops > Clothing and Accessories': 'Shops^Clothing and Accessories',
  'Shops > Clothing and Accessories > Accessories Store':
    'Shops^Clothing and Accessories^Accessories Store',
  'Shops > Clothing and Accessories > Boutique':
    'Shops^Clothing and Accessories^Boutique',
  "Shops > Clothing and Accessories > Kids' Store":
    "Shops^Clothing and Accessories^Kids' Store",
  'Shops > Clothing and Accessories > Lingerie Store':
    'Shops^Clothing and Accessories^Lingerie Store',
  "Shops > Clothing and Accessories > Men's Store":
    "Shops^Clothing and Accessories^Men's Store",
  'Shops > Clothing and Accessories > Shoe Store':
    'Shops^Clothing and Accessories^Shoe Store',
  "Shops > Clothing and Accessories > Women's Store":
    "Shops^Clothing and Accessories^Women's Store",
  'Shops > Computers and Electronics': 'Shops^Computers and Electronics',
  'Shops > Computers and Electronics > Cameras':
    'Shops^Computers and Electronics^Cameras',
  'Shops > Computers and Electronics > Mobile Phones':
    'Shops^Computers and Electronics^Mobile Phones',
  'Shops > Computers and Electronics > Video Games':
    'Shops^Computers and Electronics^Video Games',
  'Shops > Construction Supplies': 'Shops^Construction Supplies',
  'Shops > Convenience Stores': 'Shops^Convenience Stores',
  'Shops > Costumes': 'Shops^Costumes',
  'Shops > Dance and Music': 'Shops^Dance and Music',
  'Shops > Department Stores': 'Shops^Department Stores',
  'Shops > Digital Purchase': 'Shops^Digital Purchase',
  'Shops > Discount Stores': 'Shops^Discount Stores',
  'Shops > Electrical Equipment': 'Shops^Electrical Equipment',
  'Shops > Equipment Rental': 'Shops^Equipment Rental',
  'Shops > Flea Markets': 'Shops^Flea Markets',
  'Shops > Florists': 'Shops^Florists',
  'Shops > Food and Beverage Store': 'Shops^Food and Beverage Store',
  'Shops > Food and Beverage Store > Beer, Wine and Spirits':
    'Shops^Food and Beverage Store^Beer, Wine and Spirits',
  'Shops > Food and Beverage Store > Beer/Wine and Spirits':
    'Shops^Food and Beverage Store^Beer/Wine and Spirits',
  'Shops > Food and Beverage Store > Farmers Markets':
    'Shops^Food and Beverage Store^Farmers Markets',
  'Shops > Food and Beverage Store > Health Food':
    'Shops^Food and Beverage Store^Health Food',
  'Shops > Food and Beverage Store > Specialty':
    'Shops^Food and Beverage Store^Specialty',
  'Shops > Fuel Dealer': 'Shops^Fuel Dealer',
  'Shops > Furniture and Home Decor': 'Shops^Furniture and Home Decor',
  'Shops > Gift and Novelty': 'Shops^Gift and Novelty',
  'Shops > Glasses and Optometrist': 'Shops^Glasses and Optometrist',
  'Shops > Hardware Store': 'Shops^Hardware Store',
  'Shops > Hobby and Collectibles': 'Shops^Hobby and Collectibles',
  'Shops > Industrial Supplies': 'Shops^Industrial Supplies',
  'Shops > Jewelry and Watches': 'Shops^Jewelry and Watches',
  'Shops > Lawn and Garden': 'Shops^Lawn and Garden',
  'Shops > Luggage': 'Shops^Luggage',
  'Shops > Marine Supplies': 'Shops^Marine Supplies',
  'Shops > Music, Video and DVD': 'Shops^Music, Video and DVD',
  'Shops > Music/Video and DVD': 'Shops^Music/Video and DVD',
  'Shops > Musical Instruments': 'Shops^Musical Instruments',
  'Shops > Newsstands': 'Shops^Newsstands',
  'Shops > Office Supplies': 'Shops^Office Supplies',
  'Shops > Outlet': 'Shops^Outlet',
  'Shops > Pawn Shops': 'Shops^Pawn Shops',
  'Shops > Pets': 'Shops^Pets',
  'Shops > Pharmacies': 'Shops^Pharmacies',
  'Shops > Photos and Frames': 'Shops^Photos and Frames',
  'Shops > Shopping Centers and Malls': 'Shops^Shopping Centers and Malls',
  'Shops > Sporting Goods': 'Shops^Sporting Goods',
  'Shops > Supermarkets and Groceries': 'Shops^Supermarkets and Groceries',
  'Shops > Tobacco': 'Shops^Tobacco',
  'Shops > Toys': 'Shops^Toys',
  'Shops > Vintage and Thrift': 'Shops^Vintage and Thrift',
  'Shops > Warehouses and Wholesale Stores':
    'Shops^Warehouses and Wholesale Stores',
  'Shops > Wedding and Bridal': 'Shops^Wedding and Bridal',
  'Shops > Wholesale': 'Shops^Wholesale',
  'Tax > Payment': 'Tax^Payment',
  'Tax > Refund': 'Tax^Refund',
  Transfer: 'Transfer',
  'Transfer > Billpay': 'Transfer^Billpay',
  'Transfer > Check': 'Transfer^Check',
  'Transfer > Credit': 'Transfer^Credit',
  'Transfer > Debit': 'Transfer^Debit',
  'Transfer > Deposit': 'Transfer^Deposit',
  'Transfer > Deposit > ATM': 'Transfer^Deposit^ATM',
  'Transfer > Deposit > Check': 'Transfer^Deposit^Check',
  'Transfer > Internal Account Transfer': 'Transfer^Internal Account Transfer',
  'Transfer > Keep the Change Savings Program':
    'Transfer^Keep the Change Savings Program',
  'Transfer > Payroll': 'Transfer^Payroll',
  'Transfer > Payroll > Benefits': 'Transfer^Payroll^Benefits',
  'Transfer > Save As You Go': 'Transfer^Save As You Go',
  'Transfer > Third Party': 'Transfer^Third Party',
  'Transfer > Third Party > Acorns': 'Transfer^Third Party^Acorns',
  'Transfer > Third Party > Betterment': 'Transfer^Third Party^Betterment',
  'Transfer > Third Party > Chase QuickPay':
    'Transfer^Third Party^Chase QuickPay',
  'Transfer > Third Party > Coinbase': 'Transfer^Third Party^Coinbase',
  'Transfer > Third Party > Digit': 'Transfer^Third Party^Digit',
  'Transfer > Third Party > PayPal': 'Transfer^Third Party^PayPal',
  'Transfer > Third Party > Square': 'Transfer^Third Party^Square',
  'Transfer > Third Party > Square Cash': 'Transfer^Third Party^Square Cash',
  'Transfer > Third Party > Venmo': 'Transfer^Third Party^Venmo',
  'Transfer > Wire': 'Transfer^Wire',
  'Transfer > Withdrawal': 'Transfer^Withdrawal',
  'Transfer > Withdrawal > ATM': 'Transfer^Withdrawal^ATM',
  'Transfer > Withdrawal > Check': 'Transfer^Withdrawal^Check',
  Travel: 'Travel',
  'Travel > Airlines and Aviation Services':
    'Travel^Airlines and Aviation Services',
  'Travel > Airports': 'Travel^Airports',
  'Travel > Boat': 'Travel^Boat',
  'Travel > Bus Stations': 'Travel^Bus Stations',
  'Travel > Car and Truck Rentals': 'Travel^Car and Truck Rentals',
  'Travel > Car Service': 'Travel^Car Service',
  'Travel > Car Service > Ride Share': 'Travel^Car Service^Ride Share',
  'Travel > Charter Buses': 'Travel^Charter Buses',
  'Travel > Cruises': 'Travel^Cruises',
  'Travel > Gas Stations': 'Travel^Gas Stations',
  'Travel > Lodging': 'Travel^Lodging',
  'Travel > Lodging > Bed and Breakfasts': 'Travel^Lodging^Bed and Breakfasts',
  'Travel > Lodging > Hostels': 'Travel^Lodging^Hostels',
  'Travel > Lodging > Hotels and Motels': 'Travel^Lodging^Hotels and Motels',
  'Travel > Lodging > Resorts': 'Travel^Lodging^Resorts',
  'Travel > Parking': 'Travel^Parking',
  'Travel > Public Transportation Services':
    'Travel^Public Transportation Services',
  'Travel > Rail': 'Travel^Rail',
  'Travel > Taxi': 'Travel^Taxi',
  'Travel > Tolls and Fees': 'Travel^Tolls and Fees',
  'Travel > Transportation Centers': 'Travel^Transportation Centers',
};

export const MerchantCategoryKeys = Object.keys(MerchantCategories);
export const MerchantCategoryValues = Object.values(MerchantCategories);
export type MerchantCategoryKey = typeof MerchantCategoryKeys[number];
export type MerchantCategoryValue = typeof MerchantCategoryValues[number];

import { COPY_POPOVER_TITLE } from '../../content';
import { Popover, Tooltip } from '@mui/material';
import { useState } from 'react';

/**
 * Component for displaying a popover with copy functionality
 * @param {String} : Cell Value
 * @param {String} : Class name
 * @returns
 */
const CopyPopover = ({ value, className, title = COPY_POPOVER_TITLE }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // Function to handle opening popover
  const handlePopoverOpen = (event) => {
    navigator.clipboard.writeText(value);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 500);

    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing popover
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Check if popover is open
  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip
        open={tooltipOpen}
        title="Copied!"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="bottom"
      >
        <span
          onClick={handlePopoverOpen}
          className={className}
          style={{ cursor: 'pointer' }}
          title={title}
        >
          {value}
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ display: 'flex', padding: '8px', alignItems: 'center' }}>
          <span
            style={{
              color: 'black',
              paddingRight: '3px',
              fontFamily: 'Calibre-Regular,sans-serif',
              fontSize: 16,
            }}
          >
            {value}
          </span>
        </div>
      </Popover>
    </>
  );
};

export default CopyPopover;

import _, { forEach } from 'lodash';
import axios from 'axios';
import { API_BASE_ADDRESS } from './variables';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { auth } from 'utils/firebase';

import { Genders } from './currentConditionOptions/genders';
import { MerchantCategories } from './currentConditionOptions/transaction_merchant_category';
import * as selectors from './selectors';

import {
  Audience,
  ConditionBankingOption,
  ConditionBankingOptions,
  ConditionComparisonOperator,
  ConditionDemographicOption,
  ConditionDemographicOptions,
  ConditionPatternOperatorKey,
  ConditionProductOptions,
  ConditionSurveyOption,
  ConditionTransactionOption,
  ConditionTransactionOptions,
  Dimension,
  DimensionCategoryCondition,
  DimensionGroup,
  addCondition,
  addNotification,
  exportAudience,
  hasAmount,
  hasCount,
  hasTimeframe,
  query,
  queryTotalAudienceSize,
  saveAudience,
  toggleAudienceExporting,
  updateCondition,
  updateConditions,
  updateCurrentAudience,
  updateDimension,
  updatingDimension,
  updatingTotalAudience,
} from './slice';

import { NormalizedObjects } from 'interfaces/entities';

import {
  AmountOptions,
  CountOptions,
  FilterOperatorValue,
  FilterOperators,
  TimeframeOperatorValue,
  TimeframeOperators,
  TimeframeOptions,
} from './interfaces';
import { StringMap } from 'interfaces';

import { AgeRanges } from './currentConditionOptions/ageRanges';
import { nanoid } from 'nanoid';
import isNumeric from 'utils/isNumeric';

export interface ExportPayload {
  columns: string[];
  emails: string;
}

interface Param {
  id: string;
  condition: ParamCondition;
  questionCondition?: ParamSurvey;
}

interface ParamConnector {
  id: string;
  connector: 'AND' | 'OR';
}

type Params = Array<Param | ParamConnector>;

interface ParamsByDimension {
  dimension: Dimension;
  params: Params;
}

type ParamCondition =
  | ParamBanking
  | ParamDemographic
  | ParamProduct
  | ParamTransaction
  | ParamSurvey;

interface ParamConditionWithOperator {
  type?: {
    operator: ConditionComparisonOperator;
  };
  name: string;
}

interface ParamBanking extends ParamConditionWithOperator {
  category: ConditionBankingOption;
}

interface ParamDemographic extends ParamConditionWithOperator {
  category: ConditionDemographicOption;
}

interface ParamProduct {
  category: ConditionTransactionOption | StringMap;
  store?: string;
  name: string;
  type?: FilterTypes;
}

interface ParamTransaction {
  category: ConditionTransactionOption | StringMap;
  name: string;
  type?: FilterTypes;
}

interface ParamSurvey {
  category: ConditionSurveyOption | StringMap;
  questionid: any;
}

interface FilterTypes {
  operator?: ConditionPatternOperatorKey;
  Recency?: FilterParamRecency;
  Monetary?: FilterParam;
  Frequency?: FilterParam;
}

interface FilterParamRecency {
  value: number | { start: number; end: number };
  operator: TimeframeOperatorValue;
}

interface FilterParam {
  value: string | number | { lower: number; upper?: number };
  operator: FilterOperatorValue;
}

interface QueryProps {
  dimension: Dimension;
  conditions: DimensionCategoryCondition[];
}

interface QueryResult {
  data: QueryData[];
}

interface QueryData {
  row: number | string;
  cnt: string;
}

interface RPNDimensionGroup {
  [id: string]: DimensionGroup;
}

function buildParams(params: Param[], dimension: Dimension) {
  const paramsWithConnectors: Params = _.flatMap(params, (value, index) => {
    if (index !== 0) {
      const connector = {
        id: `${dimension.id}${index}`,
        connector: _.toUpper(dimension.operator),
      };
      return [value, connector];
    }

    return value;
  });

  return paramsWithConnectors;
}

function handleExport(exportParams) {
  const uri = API_BASE_ADDRESS + '/audience-manager/export';

  console.log('Export params:', exportParams);
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance({
      method: 'post',
      url: uri,
      data: exportParams,
    });
  });
}

export function handleQuery(queryParams: Params) {
  const uri = API_BASE_ADDRESS + '/audience-manager/condition-query';

  console.log('Query params:', queryParams);
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance({
      method: 'post',
      url: uri,
      data: queryParams,
    });
  });
}

function buildDimensionCategoryParams(
  dimension: Dimension,
  queryProps: QueryProps
) {
  switch (dimension.category) {
    case 'bankData':
      return buildParamsBanking(queryProps);
    case 'demographics':
      return buildParamsDemographic(queryProps);
    case 'product':
      return buildParamsProduct(queryProps);
    case 'transactions':
      return buildParamsTransaction(queryProps);
    case 'survey':
      return buildParamsSurveys(queryProps);
    default:
      return [];
  }
}

function buildParamsBanking(queryProps: QueryProps) {
  const params: Param[] = queryProps.conditions.map((condition) => {
    const option = ConditionBankingOptions[condition.option];
    return buildParamCondition(condition, option);
  });

  return buildParams(params, queryProps.dimension);
}

function buildParamCondition(
  condition: DimensionCategoryCondition,
  option: string | StringMap,
  qualifier?: { operatorType: any; values: any }
) {
  let paramCondition: ParamCondition;
  if (
    typeof option === 'object' &&
    option.operatorType &&
    option.operatorType === 'comparison'
  ) {
    paramCondition = {
      category: option.query,
      name: condition.qualifiers,
      type: {
        operator: condition.operator,
      },
    };
  } else if (
    typeof option === 'object' &&
    option.operatorType &&
    option.operatorType === 'between' &&
    qualifier
  ) {
    paramCondition = {
      category: option.query,
      name: qualifier.values,
      type: {
        operator: qualifier.operatorType,
      },
    };
  } else {
    paramCondition = {
      category: option,
      name: condition.qualifiers,
      type: {
        operator: condition.operator,
      },
    };
  }
  const param: Param = {
    id: condition.id,
    condition: paramCondition,
  };

  return param;
}

function buildParamsDemographic(queryProps: QueryProps) {
  const params: Param[] = queryProps.conditions.map((condition) => {
    const option = ConditionDemographicOptions[condition.option];
    if (typeof option === 'object' && option.query === 'age') {
      const updatedCondition = {
        ...condition,
      };
      const ageRange = AgeRanges[condition.qualifiers as string];
      if (typeof ageRange === 'object' && ageRange.operator) {
        updatedCondition.operator = ageRange.operator;
      }
      if (typeof ageRange === 'object') {
        updatedCondition.qualifiers = ageRange.values;
      }
      return buildParamCondition(updatedCondition, option);
    }
    if (option === 'gender') {
      const updatedCondition = {
        ...condition,
      };
      updatedCondition.qualifiers = Genders[condition.qualifiers as string];
      return buildParamCondition(updatedCondition, option);
    }

    return buildParamCondition(condition, option);
  });
  return buildParams(params, queryProps.dimension);
}

function buildParamsProduct(queryProps: QueryProps) {
  const params: Param[] = queryProps.conditions.map((condition) => {
    const option = ConditionProductOptions[condition.option];
    const product: string = condition.qualifiers;
    const paramCondition: ParamProduct = {
      category: option,
      name: product,
    };

    const types: FilterTypes = {};
    if (
      typeof option === 'object' &&
      option.operatorType &&
      option.operatorType === 'pattern' &&
      option.query
    ) {
      paramCondition.category = option.query;
      types.operator = condition.operator;
    }

    if (
      typeof option === 'object' &&
      option.operatorType &&
      option.operatorType === 'pattern2' &&
      option.query
    ) {
      paramCondition.category = option.query;
      types.operator = condition.operator;
    }

    if (hasTimeframe(condition)) {
      const recencyParam: FilterParamRecency = {
        value: TimeframeOptions[condition.timeframe] as number,
        operator: TimeframeOperators[condition.timeframeOperator],
      };

      if (
        condition.timeframeOperator === 'custom' &&
        condition.timeframeStart &&
        condition.timeframeEnd
      ) {
        recencyParam.operator = '=';
        recencyParam.value = {
          start: condition.timeframeStart,
          end: condition.timeframeEnd,
        };
      }

      types.Recency = recencyParam;
    }
    if (hasAmount(condition)) {
      const amountParam: FilterParam = {
        value: AmountOptions[condition.amount],
        operator: FilterOperators[condition.amountOperator],
      };

      if (condition.amount === 'custom' && condition.amountCustom) {
        amountParam.value = { lower: condition.amountCustom };
      }

      if (amountParam.value && amountParam.operator) {
        types.Monetary = amountParam;
      }
    }
    if (hasCount(condition)) {
      const countParam: FilterParam = {
        value: CountOptions[condition.count],
        operator: FilterOperators[condition.countOperator],
      };

      if (condition.count === 'custom' && condition.countCustom) {
        countParam.value = { lower: condition.countCustom };
      }

      if (countParam.value && countParam.operator) {
        types.Frequency = countParam;
      }
    }
    if (Object.keys(types).length > 0) {
      paramCondition.type = types;
    }

    const param: Param = {
      id: condition.id,
      condition: paramCondition,
    };

    return param;
  });

  return buildParams(params, queryProps.dimension);
}

function buildParamsTransaction(queryProps: QueryProps) {
  const params: Param[] = queryProps.conditions.map((condition) => {
    const option = ConditionTransactionOptions[condition.option];
    const paramCondition: ParamTransaction = {
      category: option,
      name: condition.qualifiers,
    };

    const types: FilterTypes = {};
    types.operator = condition.operator;

    if (
      typeof option === 'object' &&
      option.operatorType &&
      option.operatorType === 'pattern' &&
      option.query
    ) {
      paramCondition.category = option.query;
    }

    if (hasTimeframe(condition)) {
      const recencyParam: FilterParamRecency = {
        value: TimeframeOptions[condition.timeframe] as number,
        operator: TimeframeOperators[condition.timeframeOperator],
      };

      if (
        condition.timeframeOperator === 'custom' &&
        condition.timeframeStart &&
        condition.timeframeEnd
      ) {
        recencyParam.operator = '=';
        recencyParam.value = {
          start: condition.timeframeStart,
          end: condition.timeframeEnd,
        };
      }

      types.Recency = recencyParam;
    }
    if (hasAmount(condition)) {
      const amountParam: FilterParam = {
        value: AmountOptions[condition.amount],
        operator: FilterOperators[condition.amountOperator],
      };

      if (condition.amount === 'custom' && condition.amountCustom) {
        amountParam.value = { lower: condition.amountCustom };
      }

      if (amountParam.value && amountParam.operator) {
        types.Monetary = amountParam;
      }
    }
    if (hasCount(condition)) {
      const countParam: FilterParam = {
        value: CountOptions[condition.count],
        operator: FilterOperators[condition.countOperator],
      };

      if (condition.count === 'custom' && condition.countCustom) {
        countParam.value = { lower: condition.countCustom };
      }

      if (countParam.value && countParam.operator) {
        types.Frequency = countParam;
      }
    }
    if (Object.keys(types).length > 0) {
      paramCondition.type = types;
    }

    if (paramCondition.category === 'merchantcat')
      paramCondition.name = MerchantCategories[paramCondition.name];

    const param: Param = {
      id: condition.id,
      condition: paramCondition,
    };

    return param;
  });

  return buildParams(params, queryProps.dimension);
}

function buildParamsSurveys(queryProps: QueryProps) {
  const params = queryProps.conditions.map((condition) => {
    const paramCondition: Param = buildParamCondition(
      condition,
      condition.option
    );
    if (condition.questionCondition) {
      const paramSurvey: ParamSurvey = {
        questionid: condition.questionCondition.qualifiers,
        category: queryProps.dimension.category,
      };
      paramCondition.questionCondition = paramSurvey;
    }
    return paramCondition;
  });
  return buildParams(params, queryProps.dimension);
}

function buildParamsWithConnectors(
  currentAudience: Audience,
  currentConditions: NormalizedObjects<DimensionCategoryCondition>,
  currentDimensions: NormalizedObjects<Dimension>,
  rpnDimensionGroups: RPNDimensionGroup[]
): Params {
  const params: ParamsByDimension[] = [];
  currentAudience.dimensions.forEach((dimensionId) => {
    const dimension = currentDimensions.byId[dimensionId];
    const queryProps = buildQueryProps(dimension, currentConditions);
    const catParams = buildDimensionCategoryParams(dimension, queryProps);
    const param: ParamsByDimension = {
      dimension: dimension,
      params: catParams,
    };
    params.push(param);
  });

  const paramsWithConnectors: Params = _.flatMap(params, (param, index) => {
    const dimensionGroup = rpnDimensionGroups[param.dimension.id];
    const prevParam = params[index - 1];

    if (
      dimensionGroup &&
      prevParam &&
      prevParam.dimension.id === dimensionGroup.dimension1Id
    ) {
      const connector = {
        id: `${dimensionGroup.dimension1Id}${dimensionGroup.dimension2Id}`,
        connector: _.toUpper(dimensionGroup.operator),
      };

      return [...param.params, connector];
    }

    return param.params;
  });

  return paramsWithConnectors;
}

function buildQueryProps(
  dimension: Dimension,
  conditions: NormalizedObjects<DimensionCategoryCondition>
) {
  const queryConditions: DimensionCategoryCondition[] = [];

  let questionCondition: DimensionCategoryCondition;
  for (let i = 0; i < dimension.conditions.length; i++) {
    const conditionid = dimension.conditions[i];
    if (conditions.byId[conditionid].option === 'question') {
      questionCondition = conditions.byId[conditionid];
    }
  }
  dimension.conditions.forEach((conditionid: string) => {
    if (conditions.byId[conditionid].option === 'question') {
      questionCondition = conditions.byId[conditionid];
    }
  });

  dimension.conditions.forEach((id) => {
    const condition = conditions.byId[id];
    if (isNotBlank(condition) && condition.option !== 'question') {
      if (dimension.category === 'survey') {
        const pushableCondition = _.cloneDeep(condition);
        pushableCondition.questionCondition = questionCondition;
        queryConditions.push(pushableCondition);
      } else {
        queryConditions.push(condition);
      }
    }
  });

  return {
    dimension: dimension,
    conditions: queryConditions,
  } as QueryProps;
}

function isNotBlank(condition: DimensionCategoryCondition) {
  return (
    condition.option !== '' &&
    condition.operator !== '' &&
    condition.qualifiers !== ''
  );
}

function* watchExportAudience(action: PayloadAction<ExportPayload>) {
  try {
    const currentAudience: Audience = yield select(selectors.currentAudience);
    if (currentAudience.totalSize === 0) {
      throw { message: 'The total audience size is zero.' };
    }
    const { emails, columns } = action.payload;

    const exportParams = {
      audienceid: currentAudience.id,
      email: emails,
      columns,
      submittedby: auth.currentUser?.email,
      audiencename: currentAudience.name,
    };

    const result = yield call(handleExport, exportParams);
    if (result.data) {
      yield put(
        addNotification({
          state: 'done',
          message: `Your audience is being exported and will be delivered to your email shortly.`,
        })
      );
    } else {
      yield put(
        addNotification({
          state: 'error',
          message: `There was an error exporting the audience.`,
        })
      );
    }
    console.log('Export results:', result);
    yield put(toggleAudienceExporting(false));
  } catch (e) {
    console.log(e);
    yield put({ type: 'QUERY_TOTAL_AUDIENCE_FAILED', message: e.message });
    yield put(
      addNotification({
        state: 'error',
        message: `There was an error exporting the audience.`,
      })
    );
  }
  yield put(toggleAudienceExporting(false));
}

function* watchQuery(action) {
  try {
    const { freshCondition } = action.payload || {};
    const currentConditions: NormalizedObjects<DimensionCategoryCondition> =
      yield select(selectors.currentConditions);
    let dimension: Dimension = yield select(selectors.currentDimension);
    const queryProps = buildQueryProps(dimension, currentConditions);
    if (queryProps.conditions.length === 0) {
      yield put({ type: 'QUERY_EMPTY' });
      const conditions: DimensionCategoryCondition[] = [];
      dimension.conditions.forEach((id) => {
        const condition = currentConditions.byId[id];
        const updatedCondition = {
          ...condition,
          audienceSize: 0,
        };
        conditions.push(updatedCondition);
      });
      const updatedDimension = {
        ...dimension,
        audienceSize: 0,
      };
      yield put(updateDimension(updatedDimension));
    } else {
      // Add blank condition to dimension
      yield put(addCondition());
      // setting dimension to updating
      yield put(updatingDimension(true));
      // Reload dimension from state, includes new blank condition
      dimension = yield select(selectors.currentDimension);
      const params = buildDimensionCategoryParams(dimension, queryProps);

      // ACT-2210: Skip the previously added conditions
      let updatedParams = params;
      if (freshCondition) {
        //ACT-2212 - Add skip logic incase of deleting exising condition
        if (freshCondition?.isDeleted) {
          updatedParams = params.map((item) => {
            if (item?.connector) {
              return { ...item, skip: false };
            }
            const audienceSize =
              currentConditions.byId[item.id].audienceSize || 0;
            return { ...item, skip: true, audienceSize: audienceSize };
          });
        } else {
          updatedParams = params.map((item) => {
            if (item?.condition && item?.id !== freshCondition.id) {
              const audienceSize =
                currentConditions.byId[item.id].audienceSize || 0;
              return { ...item, skip: true, audienceSize: audienceSize };
            } else {
              return { ...item, skip: false };
            }
          });
        }
      }

      const result: QueryResult = yield call(handleQuery, updatedParams);
      console.log('Query results:', result.data);
      const updatedDimension = {
        ...dimension,
        audienceSize: 0,
      };
      if (result && result.data && result.data.length === 0) {
        // No results, exit saga
        // stop updating dimension
        yield put(updateDimension(updatedDimension));
        yield put(updatingDimension(false));
        return;
      }

      const rows = result.data;
      const conditions = _.clone(queryProps.conditions).map((condition) =>
        _.omit(condition, 'audienceSize')
      );
      for (const condition of conditions) {
        const filteredRows = rows.filter((row) => row.row == condition.id);
        if (filteredRows.length) {
          const audienceSize = filteredRows[0].cnt.replace(/\,/g, '');
          if (audienceSize)
            _.assign(condition, { audienceSize: Number(audienceSize) });
        }
      }

      if (conditions.length > 0) {
        yield put(updateConditions(conditions));
      }

      let totalSize = {};

      if (result.data.length === 1) {
        totalSize = result.data[0];
      } else {
        totalSize = result.data.find((item) => {
          return (
            item['row'] === `${dimension.id}${queryProps.conditions.length - 1}`
          );
        });
      }

      if (totalSize && totalSize.cnt) {
        const audienceSize = totalSize.cnt.replace(/\,/g, '');
        const updatedDimension = {
          ...dimension,
          audienceSize: parseInt(audienceSize),
        };
        yield put(updateDimension(updatedDimension));
      }
      // stop updating dimension
      yield put(updatingDimension(false));
    }
  } catch (e) {
    yield put(updatingDimension(false));
    yield put({ type: 'QUERY_FAILED', message: e.message });
  }
}

function* watchQueryTotalAudienceSize(existingAudience) {
  try {
    // starting the updating of total audience
    yield put(updatingTotalAudience(true));
    const currentAudience: Audience = yield select(selectors.currentAudience);
    const currentConditions: NormalizedObjects<DimensionCategoryCondition> =
      yield select(selectors.currentConditions);
    const currentDimensions: NormalizedObjects<Dimension> = yield select(
      selectors.currentDimensions
    );
    const currentDimensionGroups: NormalizedObjects<DimensionGroup> =
      yield select(selectors.currentDimensionGroups);
    // Prep for Reverse Polish Notation
    const rpnDimensionGroups: RPNDimensionGroup[] = [];
    currentAudience.dimensionGroups.forEach((id) => {
      const dimensionGroup = currentDimensionGroups.byId[id];
      if (dimensionGroup) {
        rpnDimensionGroups[dimensionGroup.dimension2Id] = dimensionGroup;
      }
    });
    const paramsWithConnectors = buildParamsWithConnectors(
      currentAudience,
      currentConditions,
      currentDimensions,
      rpnDimensionGroups
    );
    if (paramsWithConnectors.length === 0) {
      yield put({ type: 'QUERY_TOTAL_AUDIENCE__EMPTY' });
      const audience: Audience = {
        ...currentAudience,
        totalSize: 0,
      };
      yield put(updateCurrentAudience(audience));
      // stopping the update
      yield put(updatingTotalAudience(false));
      // No parameters, exit saga
      return;
    }
    const result: QueryResult = yield call(handleQuery, paramsWithConnectors);
    console.log('Total audience size query results:', result.data);
    if (result && result.data && result.data.length === 0) {
      // No results, exit saga and stop updating total audience
      yield put(updatingTotalAudience(false));
      return;
    }
    let totalSize = {};
    if (Object.keys(rpnDimensionGroups).length === 0) {
      const id = paramsWithConnectors[paramsWithConnectors?.length - 1]?.id;
      totalSize = result.data?.find((a) => a.row == id);
    } else {
      const index = currentDimensionGroups.allIds.length - 1;
      const dimensionGroupId = currentDimensionGroups.allIds[index];
      const dimensionGroup = currentDimensionGroups.byId[dimensionGroupId];
      totalSize = result.data.find((item) => {
        return (
          item['row'] ===
          `${dimensionGroup.dimension1Id}${dimensionGroup.dimension2Id}`
        );
      });
    }
    if (totalSize && totalSize.cnt !== '' && totalSize.cnt !== null) {
      const audienceSize = totalSize.cnt.replace(/\,/g, '');
      const currentAudience: Audience = yield select(selectors.currentAudience);
      const audience: Audience = {
        ...currentAudience,
        totalSize: parseInt(audienceSize),
      };
      yield put(updateCurrentAudience(audience));

      try {
        // If exising audience exits, we can do updating audience.
        // Update below props
        // 1. Audience Size of audience
        // 2. Audience Size of each dimension
        // 3. Audience Size of each dimension condition
        if (existingAudience) {
          const saveableAudience = JSON.parse(
            JSON.stringify(existingAudience?.payload)
          );
          saveableAudience.updatedAudience.total_size = audienceSize;
          const dimensions = saveableAudience.currentDimensions || [];
          const conditions = saveableAudience.currentConditions || [];

          for (let i = 0; i < dimensions.allIds.length; i++) {
            const dimension = dimensions.byId[dimensions.allIds[i]];
            let dimensionResult = result.data
              .filter((a) => a.row.toString().startsWith(dimension.id))
              .sort((a, b) => {
                if (isNumeric(a.row) && isNumeric(b.row)) {
                  const rowA = parseInt(a.row.toString());
                  const rowB = parseInt(b.row.toString());
                  return rowB - rowA;
                }
              });
            if (dimension?.conditions?.length === 1) {
              const singleCondition = result.data.find(
                (x) => x.row == dimension?.conditions[0]
              );
              dimensionResult = [singleCondition];
            }
            const dimensionCount =
              dimensionResult.length === 1
                ? dimensionResult[0]?.cnt
                : dimensionResult[1]?.cnt;

            dimension.audience_size =
              parseInt(dimensionCount?.replace(/\,/g, '')) || 0;
          }

          for (let i = 0; i < conditions.allIds.length; i++) {
            const condition = conditions.byId[conditions.allIds[i]];
            const conditionResult = result.data.find(
              (x) => x.row == condition.id
            );
            if (conditionResult) {
              condition.audienceSize = parseInt(
                conditionResult?.cnt.replace(/\,/g, '')
              );
            }
          }

          yield put(saveAudience(saveableAudience));
        }
      } catch (error) {
        console.error('watchQueryTotalAudienceSize() > Error :', error);
      }
    }
    // stop updating
    yield put(updatingTotalAudience(false));
  } catch (e) {
    console.log(e);
    yield put(updatingTotalAudience(false));
    yield put({ type: 'QUERY_TOTAL_AUDIENCE_FAILED', message: e.message });
  }
}

export default function* watchAll() {
  yield takeLatest<any>(exportAudience.type, watchExportAudience);
  yield takeLatest<any>(query.type, watchQuery);
  yield takeLatest<any>(
    queryTotalAudienceSize.type,
    watchQueryTotalAudienceSize
  );
}

import styled from 'styled-components';
import tokens from 'tokens';

export const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${tokens.colorGray40};
  display: flex;
  line-height: 40px;
  padding: 24px 32px;
`;

export const Title = styled.span`
  ${tokens.typography.subheaderSm};
  line-height: 40px;
  margin: 0;
  font-family: Calibre-Semibold, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: rgb(255, 255, 255);

  @media (min-width: 600px) {
    color: rgb(26, 23, 28);
  }
`;

export const Center = styled.div`
  ${tokens.typography.subheaderSm};
  flex-grow: 1;
  line-height: 40px;
  padding-left: ${tokens.spacing.lg};
  margin-left: ${tokens.spacing.lg};
`;

export const CenterBorder = styled.div`
  ${tokens.typography.subheaderSm};
  border-left: 1px solid ${tokens.colorGray40};
  flex-grow: 1;
  line-height: 40px;
  padding-left: ${tokens.spacing.lg};
  margin-left: ${tokens.spacing.lg};
`;

export const Action = styled.div``;

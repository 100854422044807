import _ from 'lodash';
import camelizeKeys from './camelizeKeys';
import { getAllIds } from './allIds';

function handleCustomValues(obj: any) {
  const updatedObj = {
    ...obj,
  };

  if (obj.amount && obj.amount.split(':').length > 1) {
    updatedObj.amount = obj.amount.split(':')[0];
    updatedObj.amount_custom = obj.amount.split(':')[1];
  }

  if (obj.count && obj.count.split(':').length > 1) {
    updatedObj.count = obj.count.split(':')[0];
    updatedObj.count_custom = obj.count.split(':')[1];
  }

  if (obj.timeframe && obj.timeframe.split(':').length > 1) {
    const timeframe = obj.timeframe.split(':')[1];
    updatedObj.timeframe = '';
    updatedObj.timeframe_start = +timeframe.split('-')[0];
    updatedObj.timeframe_end = +timeframe.split('-')[1];
  }

  return updatedObj;
}

export function splitAudience(audience: any) {
  const audience_id = audience.id;
  const dimensions = audience.dimensions
    ? _.clone(audience.dimensions).map((dimension) => {
        const updatedDimension = handleCustomValues(dimension);
        return _.omit(
          _.assign(updatedDimension, { audience_id }),
          'conditions'
        );
      })
    : [];
  const conditions = audience.dimensions
    ? audience.dimensions.reduce((a, dimension) => {
        if (dimension.conditions) {
          const dimensionConditions = _.clone(dimension.conditions);
          return a.concat(
            dimensionConditions.map((dimensionCondition) => {
              const updatedCondition = handleCustomValues(dimensionCondition);
              return _.assign(updatedCondition, {
                dimension_id: dimension.id,
              });
            })
          );
        } else {
          return a;
        }
      }, [])
    : [];
  const dimension_groups = audience.dimension_groups
    ? _.clone(audience.dimension_groups).map((dimensionGroup) =>
        _.assign(dimensionGroup, { audience_id })
      )
    : [];
  const audienceClone = _.clone(audience);
  audienceClone.dimensions = getAllIds(dimensions, 'id');
  audienceClone.dimensionGroups = getAllIds(dimension_groups, 'dimension_1_id');
  dimensions.forEach((dimension) => {
    const selectedConditions = conditions.filter(
      (x) => x.dimension_id === dimension.id
    );
    dimension.conditions = getAllIds(selectedConditions, 'id');
  });
  return {
    audience: camelizeKeys(audienceClone),
    dimensions: camelizeKeys(dimensions),
    conditions: camelizeKeys(conditions),
    dimensionGroups: camelizeKeys(dimension_groups),
  };
}

export const Educations = [
  '',
  'High school graduate',
  'Completed some college, but no degree',
  'Associate Degree',
  'College Degree (such as B.A., B.S.)',
  'Other post high school vocational training',
  'Completed some high school',
  'Masters degree',
  'Completed some graduate, but no degree',
  'None of the Above',
  'Doctorate degree',
  'Middle School - Grades 4 - 8',
  '3rd Grade or less',
];

export type Education = typeof Educations[number];

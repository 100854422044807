export const Merchants = [
  '',
  'MCDONALDS',
  'WALMART',
  'SHELL',
  'AMAZON',
  'TACO BELL',
  'WENDYS',
  'BURGER KING',
  'WALGREENS',
  'TARGET',
  'APPLE',
  'CHICKFILA',
  'DOLLAR GENERAL',
  'DAVE INC',
  'ELEVEN',
  'DOLLAR TREE',
  'SUBWAY',
  'CVS',
  'FAMILY DOLLAR',
  'DOMINOS',
  'STARBUCKS',
  'UBER',
  'POPEYES',
  'CIRCLE K',
  'SONIC',
  'NETFLIX',
  'KFC',
  'LYFT',
  'PIZZA HUT',
  'CHEVRON',
  'AUTOZONE',
  'EXXONMOBIL',
  'LITTLE CAESARS',
  'EARNIN',
  'SUNOCO',
  'ITUNES',
  'VENMO',
  'DAIRY QUEEN',
  'CHIPOTLE MEXICAN GRILL',
  'DUNKIN DONUTS',
  'SPEEDWAY',
  'PANDA EXPRESS',
  'IHOP',
  'BRIGIT',
  'ARBYS',
  'HULU',
  'LOWES',
  'KROGER',
  'BEST BUY',
  'US POST OFFICE',
  'APPLEBEES',
  'WINGSTOP',
  'AFTERPAY',
  'UBER EATS',
  'JACK IN THE BOX',
  'PAPA JOHNS',
  'ALBERT INSTANT',
  'FIVE BELOW',
  'AMAZON PRIME VIDEO',
  'KLOVER CARE',
  'THE HOME DEPOT',
  'APPLE CASH',
  'PANERA BREAD',
  'ATM WITHDRAWAL',
  'GAMESTOP',
  'ROSS STORES',
  'SONY PLAYSTATION',
  'FLOATME',
  'OLIVE GARDEN',
  'PETSMART',
  'OREILLY AUTO PARTS',
  'BIG LOTS',
  'SPOTIFY',
  'CASH WITHDRAWAL FEE',
  'TMOBILE',
  'PUBLIX',
  'ATT',
  'OLD NAVY',
  'REDBOX',
  'BUFFALO WILD WINGS',
  'MARATHON PETROLEUM CORPORATION',
  'BATH BODY WORKS',
  'DOORDASH',
  'ZAXBYS',
  'CASH APP',
  'QT',
  'WAFFLE HOUSE',
  'RITE AID',
  'DUNKIN',
  'ALDI',
  'METROPCS',
  'TRANSFER TO CHIME SPENDING',
  'MICROSOFT',
  'KLARNA',
  'SAMS CLUB',
  'CHILIS',
  'MARSHALLS',
  'CHECK',
  'AMAZON PRIME',
  'KOHLS',
  'POSTMATES',
  'ETSY',
  'WHATABURGER',
  'TRANSFER TO CHIME SAVINGS',
  'CLEO AI',
  'DENNYS',
  'COMCAST',
  'MACYS',
  'RAISING CANES CHICKEN FINGERS',
  'RACETRAC',
  'HARDEES',
  'TJ MAXX',
  'MICROSOFT XBOX',
  'GEICO',
  'FIVE GUYS',
  'PILOT',
  'SPECTRUM',
  'CARDTRONICS',
  'KRISPY KREME',
  'WAWA',
  'TEXAS ROADHOUSE',
  'ROUND UP TRANSFER',
  'WITHDRAWAL',
  'JIMMY JOHNS',
  'PARTY CITY',
  'JCPENNEY',
  'CLEO',
  'GRUBHUB',
  'FOOT LOCKER',
  'MONTHLY SERVICE FEE',
  'FOREVER',
  'SALLY BEAUTY',
  'BP',
  'HOME DEPOT',
  'GROUPON',
  'BURLINGTON',
  'EBAY',
  'KLOVER APP BOOST',
  'LOVES TRAVEL STOP',
  'CITGO',
  'ULTA',
  'APPLECOMBILL',
  'DIRECT DEBIT DAVE INC DEBIT',
  'CHECKERS AND RALLYS',
  'SAFEWAY',
  'ONLY FANS',
  'BOOST MOBILE',
  'PETCO',
  'SPRINT',
  'COSTCO',
  'FOOD LION',
  'VICTORIAS SECRET',
  'NONCHASE ATM FEEWITH',
  'AUNTIE ANNES',
  'PEPSICO',
  'ADVANCE AUTO PARTS',
  'MURPHY USA',
  'ROBINHOOD',
  'MICHAELS',
  'TEXACO',
  'CVSPHARM',
  'NONWELLS FARGO ATM TRANSACTION FEE',
  'NINTENDO',
  'NIKE',
  'DAVECOM',
  'ENTERPRISE RENTACAR',
  'INSTACASH REPAYMENT',
  'MONTHLY MAINTENANCE FEE',
  'RED LOBSTER',
  'CARLS JR',
  'COCACOLA',
  'GOODWILL',
  'DISNEY',
  'INSTACART',
  'ARCO',
  'SHEETZ',
  'INNOUT BURGER',
  'PLANET FITNESS',
  'CRACKER BARREL',
  'UPS',
  'WISH',
  'EMPOWER',
  'SMOOTHIE KING',
  'TRANSFER TO CREDIT BUILDER',
  'WHOLE FOODS',
  'MONEYLION',
  'EMPOWER INC',
  'FIREHOUSE SUBS',
  'POSSIBLE FINANCE',
  'CVS PHARM',
  'RED ROBIN',
  'FEDEX',
  'RACEWAY',
  'STEAK N SHAKE',
  'SPOTME TIP',
  'GOOGLE',
  'QUIKTRIP',
  'MONEYLION INSTACASH',
  'OUTBACK STEAKHOUSE',
  'WHITE CASTLE',
  'HOBBY LOBBY',
  'ALBERTSONS',
  'FACEBOOK PAY',
  'RAINBOW',
  'STAPLES',
  'OVERDRAFT ITEM FEE FOR ACTIVITY OF ELECTRONIC TRANSACTION POSTING DATE POSTING SEQ',
  'MEIJER',
  'BOJANGLES FAMOUS CHICKEN N BISCUITS',
  'CHIPOTLE',
  'FANDANGO',
  'DAVE INC DEBIT WEB ID',
  'COLD STONE CREAMERY',
  'RUE',
  'SEPHORA',
  'SPENCERS',
  'RENTACENTER',
  'JERSEY MIKES SUBS',
  'OFFICE DEPOT',
  'TROPICAL SMOOTHIE CAFE',
  'UHAUL',
  'SAVEALOT',
  'ONLINE BANKING TRANSFER TO CHK CONFIRMATION',
  'SEZZLE ORDER P',
  'DIAMOND SHAMROCK',
  'COOK OUT',
  'CRICKET WIRELESS',
  'AMERICAN AIRLINES',
  'CHAMPS SPORTS',
  'PLAYSTATION',
  'GREAT CLIPS',
  'HARBOR FREIGHT TOOLS',
  'PHILLIPS',
  'DOLLAR TR',
  'TRADER JOES',
  'JOURNEYS',
  'GOOGLE PLAY',
  'ZUMIEZ',
  'CITI TRENDS',
  'CONOCO',
  'TGI FRIDAYS',
  'DEL TACO',
  'QDOBA',
  'AIRBNB',
  'NSF RETURNED ITEM FEE FOR ACTIVITY OF ELECTRONIC TRANSACTION POSTING DATE POSTING SEQ',
  'HM',
  'BRANCH MESSENGER INC',
  'JAMBA JUICE',
  'ONLINE BANKING TRANSFER TO SAV CONFIRMATION',
  'RALLYS',
  'HOT TOPIC',
  'SHEIN',
  'CINNABON',
  'ACADEMY SPORTS OUTDOORS',
  'CINEMARK THEATRES',
  'DIGIT',
  'SEZZLE ODR',
  'WITHDRAWAL MADE IN A BRANCHSTORE',
  'CULVERS',
  'PROGRESSIVE',
  'ONLINE TRANSFER TO CHK TRANSACTION',
  'STATE FARM',
  'JIFFY LUBE',
  'BOJANGLES',
  'BARNES NOBLE',
  'NORDSTROM',
  'PAI ISO',
  'HIBBETT SPORTS',
  'ACORNS',
  'PAPA JOHNS PIZZA',
  'SIRIUSXM',
  'AUDIBLE',
  'HEB',
  'VANS',
  'SHAKE SHACK',
  'CHURCHS CHICKEN',
  'GOLDEN CORRAL',
  'STRAIGHTTALK',
  'CLAIRES',
  'TRANSFER TO CHECKING',
  'HOLIDAY INN',
  'MARCOS PIZZA',
  'SHOE CARNIVAL',
  'STEAM GAMES',
  'PURCHASE AUTHORIZED ON DAVE INC LOS ANGELES CA S CARD',
  'TRACTOR SUPPLY',
  'VZWRLSS',
  'LONGHORN STEAKHOUSE',
  'EXPRESS',
  'THORNTONS',
  'WINNDIXIE',
  'COINBASE',
  'VEND AT AIR SERV',
  'FLOAT ME CORP',
  'CAPTAIN DS',
  'HOMEGOODS',
  'FLOATME FUND I DB',
  'MARATHON PETRO',
  'EXPEDIA',
  'DAVE BUSTERS',
  'ONLINE TRANSFER TO SAV TRANSACTION',
  'CORNER STORE',
  'SHOE DEPT',
  'MURPHYATWAL',
  'DICKS SPORTING GOODS',
  'ATM FEE WITHDRAWAL',
  'ARAMARK',
  'IKEA',
  'SARKU JAPAN',
  'AMAZON KINDLE',
  'CASH APP CA',
  'BED BATH BEYOND',
  'DDBR',
  'STOP SHOP',
  'GNC',
  'AFFIRM',
  'BILL PAYMENT',
  'ML PLUS',
  'INTERNATIONAL TRANSACTION FEE',
  'PLANET FIT CLUB FEES',
  'CK',
  'PILOT FLYING J',
  'TRANSFER TO SHARES',
  'FASHIONNOVA',
  'SBARRO',
  'JERSEY MIKES',
  'FINISH LINE',
  'QUADPAY ANYWH',
  'SIX FLAGS',
  'EL POLLO LOCO',
  'HOOTERS',
  'WINCO FOODS',
  'IPSY',
  'BASKINROBBINS',
  'DAVE INC DEBIT',
  'UNITED',
  'TJMAXX',
  'MONEYLION INSTACAS',
  'ALIEXPRESS',
  'OVERDRAFT FEE',
  'AMC ONLINE',
  'CHEDDARS',
  'BOSTON MARKET',
  'HOLLISTER',
  'STASH',
  'APPLECOMBILL CA',
  'CARTERS',
  'COSTCO GAS',
  'CASEYS GENERAL STORE',
  'PROGRESSIVELEASE',
  'STOP PAYMENT FEE',
  'RETURNED CHECK FEE',
  'SELF LENDER',
  'LAZ PARKING',
  'TACO CABANA',
  'AEROPOSTALE',
  'DD BR',
  'DOLLAR GE',
  'ROSS',
  'EARNINACTIVEHOURS',
  'BUCEES',
  'THE CHILDRENS PLACE',
  'WIENERSCHNITZEL',
  'PLATOS CLOSET',
  'DIRECT DEBIT ALBERT SAVINGS D EDI PYMNTS',
  'DIRECT DEBIT PAYPAL VERIFYBANK',
  'HELPHBOMAX',
  'VIOC',
  'TOTAL WINE MORE',
  'GULF OIL',
  'MOTEL',
  'PREMIER BANKCARD',
  'JOANN STORES',
  'USA VEND AT AIR SERV',
  'INSTACASH REPAYMEN',
  'DAVE INC VISA DIRECT CA',
  'PIGGLY WIGGLY',
  'OLLIES BARGAIN OUTLET',
  'ADOBE',
  'FREDDYS FROZEN CUSTARD STEAKBURGERS',
  'HARRIS TEETER SUPERMARKETS INC',
  'SNACK SODA VENDING',
  'TRUEBILL',
  'FOOTACTION',
  'CHARLOTTE RUSSE',
  'DILLARDS',
  'ALBERT SAVINGS D EDI PYMNTS WEB ID',
  'PROGRESSIVE INSURANCE',
  'CUMBERLAND FARMS',
  'ADIDAS',
  'MAPCO',
  'RALPHS',
  'SHOPRITE',
  'CHEWY',
  'HOBBYLOBB',
  'GIANT',
  'CCBILL',
  'MURPHY',
  'PURCHASE AUTHORIZED ON DAVE INC VISA DIRECT CA S CARD',
  'APPLECOMBILL PURCHASE',
  'SPEEDY CASH',
  'ML PLUS MEMBRSHP',
  'KROGER FUEL',
  'PANDORA',
  'ROSS DRESS FOR LESS',
  'MINUTEKEY',
  'AMPM',
  'ROYAL FARMS',
  'DSW',
  'FACEBK',
  'SPORT CLIPS',
  'ATM FEE INQUIRY',
  'UNITED PACIFIC',
  'TIM HORTONS',
  'DELTA AIR LINES',
  'VERIZON',
  'KUM GO',
  'CHURCH S CHICKEN',
  'VUDU',
  'SMASHBURGER',
  'FUNDS',
  'CICIS PIZZA',
  'SERVICE CHARGE',
  'DISNEY PLUS',
  'BEST WESTERN',
  'ALBERT GENIUS',
  'HUNGRY HOWIES',
  'TOM THUMB',
  'ARMY AIR FORCE EXCHANGE SERVICE',
  'GRUBHUBTACOBELL',
  'NAYAX VENDING MACHINE',
  'MARKETWORK',
  'SUPERCUTS',
  'MARKET C',
  'CHARLEYS PHILLY STEAKS',
  'SUBWAY PURCHASE',
  'CENTS ONLY STORES',
  'GOPUFF',
  'CHECKERS',
  'SPROUTS FARMERS MARKET',
  'ACE HARDWARE',
  'NONCHASE ATM FEEINQ',
  'PLAYSTATION NETWORK',
  'PAYMENT',
  'ATT BILL PAYMENT',
  'MERCARI',
  'CHUCK E CHEESE',
  'SPIRIT HALLOWEEN',
  'KLOVER APP BOOST CHICAGO IL',
  'DDS DISCOUNTS',
  'SEI',
  'ACH TRANSACTION DAVE INC DEBIT',
  'CASH APP PMNT SENT',
  'LJS',
  'MURPHY EXPRESS',
  'REGAL CINEMAS',
  'CHICKFIL',
  'NOODLES COMPANY',
  'VONS',
  'BATH AND BODY',
  'ATM FEE DENIED TRANSACTION',
  'PILOT TRAVEL CENTER',
  'MOD PIZZA',
  'BJS RESTAURANTS',
  'FOOD MART',
  'GOLDEN CHICK',
  'POS DEBIT VISA CHECK CARD DAVE INC',
  'JASONS DELI',
  'TRANSFER TO',
  'STORE',
  'RECURRING PAYMENT APPLECOMBILL',
  'DIRECT DEBIT ALBERT GENIUS EDI PYMNTS PAYMENT',
  'REPLACEMENT ATM OR DEBIT CARD FEE',
  'EXTENDED OVERDRAFT FEE',
  'SOUTHWES',
  'ROOT INSURANCE',
  'HOTELSCOM',
  'EMPOWER INC PPD ID',
  'MAVERIK CONVENIENCE STORE',
  'WEGMANS',
  'FAZOLIS',
  'ONLYFANS',
  'PURCHASE AUTHORIZED ON KLOVER APP BOOST CHICAGO IL S CARD',
  'SPIRIT AIRL',
  'FOOD LESS',
  'KMART',
  'DAVECOM PURCHASE',
  'RUBY TUESDAY',
  'POSHMARK',
  'BRAUMS',
  'PACIFIC SUNWEAR',
  'KEEP THE CHANGE TRANSFER TO ACCT FOR',
  'DOLLARTRE',
  'POS DEBIT VISA CHECK CARD CASH APP',
  'WETZELS PRETZELS',
  'EARNIN ACTIVE PAYMENTRET WEB ID',
  'TEMPORARY CREDIT REVERSAL ON CARD CLAIM',
  'ADVANCE AMERICA',
  'PROBILLER',
  'ALBERT SAVINGS D EDI PYMNTS',
  'YARD HOUSE',
  'ATM WITHDRAWAL PLUS P',
  'OVERDRAFT ITEM FEE',
  'AARONS',
  'INSUFFICIENT FUNDS',
  'FAMOUS FOOTWEAR',
  'FRED MEYER',
  'MARRIOTT',
  'RED ROOF INN',
  'USA SNACK SODA VENDING',
  'AMERICAN EAGLE',
  'CAPITAL ONE MOBILE PMT WEB ID',
  'AMERICAS BEST',
  'FIRESTONE',
  'VERIZON WIRELESS',
  'YOUTUBEPREMIUM',
  'BOXYCHARM',
  'EDIBLE ARRANGEMENTS',
  'BLAZE PIZZA',
  'OCHARLEYS',
  'SPIRIT AIRLINES',
  'ALBERT',
  'AMERICAN DELI',
  'MCALISTERS',
  'GETGO',
  'QUICK STOP',
  'TARGET T PURCHASE',
  'DISNEYPLUS',
  'SAVINGS WITHDRAWAL LIMIT FEE',
  'LOVE S COUNTRY',
  'LIMIT',
  'BESTBUYCOM',
  'TIME WARNER CABLE',
  'PUBLIC STORAGE',
  'KLOVER APP BOO',
  'CANTEEN',
  'PRIME VIDEO',
  'DIRECT DEBIT EMPOWER INC',
  'LUCKY',
  'OSCO',
  'GOOGLE MUSIC',
  'XSOLLA',
  'POLLO TROPICAL',
  'KLOVER HOLDINGS',
  'QVC',
  'PET SUPPLIES PLUS',
  'APPLE STORE',
  'HOTELS',
  'VOLA',
  'WISHCOM',
  'DOMINO S',
  'APPLEBEES GRI',
  'RETURNED ITEM FEE',
  'DAYS INN',
  'RACK ROOM SHOES',
  'SEZZLE',
  'CANTEEN VENDING',
  'EXPRESS MART',
  'HILTON',
  'OREILLY AUTO',
  'RAISING CANES',
  'ROBLOX',
  'PIERCING PAGODA',
  'SCHLOTZSKYS',
  'YOUTUBE TV',
  'ATM FEE',
  'HYVEE',
  'EARNINACTIVEHOUR PAYMENTRET WEB ID',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS APPLECOMBILL CA',
  'ALLSTATE',
  'KWIK TRIP',
  'THE ROKU CHANNEL',
  'ROBINHOOD FUNDS',
  'XFINITY WIFI',
  'TORRID',
  'FIRST WATCH',
  'WU',
  'SAMSCLUB',
  'YOURSCOREANDMORE',
  'ATM WITHDRAWAL FEE',
  'WAYFAIR',
  'PURCHASE BANK CHECK OR DRAFT',
  'TRANSFER TO CREDIT CARD',
  'SPOTLOAN',
  'CBS ALL ACCESS',
  'LIBERTY MUTUAL',
  'USPS PO',
  'CENTURYLINK',
  'SPIRIT HALLOWEEN SUPERSTORES',
  'CHICKEN EXPRESS',
  'ALLIANZ',
  'CHINA WOK',
  'PROGRESSIVE LEASING',
  'CHILIS GRILL',
  'USA CANTEEN VENDING',
  'PURCHASE',
  'QUADPAY',
  'MICROSOFTXBOX',
  'BRANCH MESSENGER I',
  'DUTCH BROS COFFEE',
  'BJS WHOLESALE CLUB',
  'INGLES MARKETS',
  'HYATT RESORTS',
  'POTBELLY SANDWICH SHOP',
  'EXPERIAN',
  'WM SUPERCENTER PURCHASE',
  'SMART AND FINAL',
  'BIRD APP',
  'GOOGLE STORAGE',
  'AMERICAN',
  'JETBLUE',
  'PENN STATION',
  'ZARA',
  'VITAMIN SHOPPE',
  'ACIMA',
  'TILLYS',
  'ML PLUS LOAN',
  'PARKMOBILE',
  'SUPER',
  'U HAUL STORE',
  'BIG SPORTING GOODS',
  'THE GENERAL',
  'QUALITY INN',
  'FREEPRINTS',
  'CMSVEND',
  'GABRIEL BROS',
  'ATM WITHDRAWAL PLUS PAI ISO',
  'SPRINT WIRELESS',
  'USPS',
  'VICTORIASSECRET',
  'DAVE INC VISA DIRECT',
  'BUFFALO WILD',
  'SQUARE INC',
  'BELK',
  'KVAT FOOD CITY',
  'GRUBHUBMCDONALDS',
  'JPAY STAMPS',
  'LOWES HOME IMPROVEMENT',
  'CEFCO',
  'SKILLZ ESPORTS',
  'MESSENGER',
  'MICHAEL KORS',
  'LITTLE CAESARS PURCHASE',
  'TIME WISE',
  'GIANT EAGLE',
  'ZEBIT INC',
  'PAPER STATEMENT FEE',
  'ATM BALANCE INQUIRY FEE',
  'AMTRAK',
  'FLASH FOODS',
  'BLIZZARD ENTERTAINMENT',
  'COLLEGE TRANSCRIPT',
  'POSTMATES MEMBERSHIP',
  'DOLLARTREE',
  'ORIGIN',
  'CAPITAL ONE MOBILE PMT',
  'INTEREST CHARGEPURCHASES',
  'ADVANCE AUTO P',
  'GREENLIGHT',
  'MOES SW GRILL',
  'DDS DISCOUNT',
  'ADJUSTMENT',
  'DRAFTKINGS',
  'ON THE BORDER',
  'ENMARKET',
  'GUITAR CENTER',
  'TIGER MART',
  'DUANE READE',
  'FAMOUSFOOTWEAR',
  'ESPN PLUS',
  'HILTON HOTELS RESORTS',
  'VBS FIRSTLOAN',
  'ROKU FOR HULU LLC',
  'NIKEPOSUS',
  'MEMPHIS',
  'ATM WITHDRAWAL W',
  'WIRELESS',
  'DOUBLETREE',
  'MICHAELS STORE',
  'FIESTA MART',
  'DEBIT',
  'SHOE SHOW',
  'HUDDLE HOUSE',
  'ISPAPIMDS WITHDRWL PAI ISO',
  'SPECS WINE SPIRITS FINER FOODS',
  'SLING',
  'EVERI',
  'EBT ACCT CHECK',
  'AFTERPAY US INC',
  'PAYMENTUS CORP',
  'JIMMY JAZZ',
  'TRUEACCORD',
  'DISNEY MOBILE',
  'ACIMA CREDIT',
  'REMOVING GOVT STIMULUS CHASE DAY OVERDRAFT ASSISTANCE FROM',
  'MELLOW MUSHROOM',
  'PAPA MURPHYS',
  'BOB EVANS REST',
  'CENTS ONLY STORE',
  'BRANCH MESSENGER',
  'ALBERT INSTANT PURCHASE',
  'BPBP',
  'ABCMOUSE',
  'FACEBOOKPAY',
  'UNITED DAIRY FARMERS',
  'SUNSHINE',
  'MICROSOFT ULTIMATE',
  'BPCIRCL',
  'LOVES',
  'DICKS SPORTING',
  'EBAY INC',
  'HAMPTON INN',
  'AMAZON MUSIC',
  'MISTER CAR WASH',
  'ADVANCE AUTO',
  'VEND AT AIR SE',
  'PF CHANGS',
  'FREDDYS',
  'LIDS',
  'KLARNA PAYMENTS',
  'TICKETMASTER',
  'USASNACK SODA VENDING',
  'WEIS MARKETS',
  'MONEYGRAM',
  'CUROLOGY',
  'STEAM PURCHASE',
  'VERIZON WRLS',
  'MOES',
  'TO VARO BANK ACCOUNT',
  'DAVE INC PURCHASE',
  'PMNT SENT CASH APP CA',
  'INSTACASH',
  'DAVE INC PMNT SENT VISA DIRECT',
  'CINEMARK',
  'CARMAX',
  'GROUPON INC',
  'EINSTEIN BROS BAGELS',
  'BOB EVANS',
  'CLARK',
  'STATER BROS MARKETS',
  'BESTBUY',
  'NST THE HOME D',
  'FINISHLINE',
  'ISPAPIMDS BAL INQ PAI ISO FEE',
  'SQUARE CASH',
  'BURLINGTON COAT FACTORY',
  'WORLD FINANCE',
  'INTUIT TURBOTAX',
  'WHICH WICH',
  'MARATHON',
  'TACO BUENO',
  'SMOKE SHOP',
  'ACADEMY SPORTS',
  'TITLEMAX',
  'PROGRESSIVE INS',
  'PHILO INC',
  'MAINTENANCE FEE',
  'CHEGG ORDER',
  'RITAS',
  'AT HOME STORE',
  'JUSTFAB',
  'STRIPES',
  'JETS PIZZA',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON APPLECOMBILL',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS EARNIN ACTIVE PAYMENTRET WEB ID',
  'LANIER PARKING',
  'ACME MARKETS',
  'IDENTITYIQ',
  'WESTERN UNION',
  'PAYPAL INST XFER',
  'USA VEND AT AIR SE',
  'CENTURY THEATRES',
  'PAYPAL VERIFYBANK',
  'BURLINGTON STO',
  'VA ABC STORE',
  'TACO JOHNS',
  'ATM WITHDRAWAL COOP ELEVENFC',
  'SHUTTERFLY',
  'JACKS',
  'FIRST LOAN DB',
  'TMOBILE POSTPAID PDA',
  'POSTMATES TIP',
  'WITHDRAWAL BY CASH',
  'GRUBHUBGHPLUS',
  'HLUHULU HULUCOMBILL CA',
  'TO VARO SAVINGS ACCOUNT',
  'BRIGITCOM MEMBERSHIP PPD ID',
  'POS DEBIT VISA CHECK CARD APPLECOM BILL C',
  'PLANET FITNES',
  'OVERDRAFT PROTECTION TRANSFER FEE',
  'STARZ ENTERTAINMENT',
  'FLOATME FUND',
  'TOMMY HILFIGER',
  'LOVES CNTRY ST',
  'CHECKCARD ALBERT INSTANT',
  'GROCERY OUTLET',
  'YMCA',
  'LIDL',
  'DAVECOM CA',
  'CROCS',
  'TWIN PEAKS',
  'GREAT AMERICAN COOKIES',
  'ROSS STORES PURCHASE',
  'XFINITY MOBILE',
  'BPCAPL',
  'NONCHASE ATM WITHDRAW W',
  'IL MAKIAGE',
  'ROSES STORE',
  'SNAP FINANCE',
  'RECURRING PAYMENT HLUHULU HULUCOMBILL',
  'WWE NETWORK',
  'CASHED CHECK',
  'FRYS FOOD AND DRUG',
  'VERIZON WRL MY ACC',
  'MCALISTERS DELI',
  'ACH TRANSACTION ALBERT SAVINGS D EDI PYMNTS',
  'OLLIES BARGAIN',
  'CRACKER B',
  'QT INSIDE',
  'LEMONADE INSURANCE',
  'EARNIN ACTIVE PAYMENTRET',
  'THECHILDRENSPLACE',
  'ADVANCE AUTO PA',
  'LITTLE CAESAR',
  'NSF RETURN ITEM FEE FOR A TRANSACTION RECEIVED ON CHECK',
  'BRIGITCOM PROTECTION PPD ID',
  'PEACOCK',
  'THE DISNEY STORE',
  'SPEEDY',
  'AMZNFREETIME',
  'ALBERT GENIUS EDI PYMNTS',
  'EZPAWN',
  'FINGERHUT PAYMENTS',
  'GOTINDER',
  'FANDUEL',
  'POS DEBIT VISA CHECK CARD DAVE INC VISA DIRECT',
  'ATM WITHDRAWAL PLUS LK',
  'USAA FUNDS TRANSFER DB',
  'HOLIDAY STATIONS STORES',
  'SPINX',
  'PROGRESSIVE INSUR',
  'NONCHASE ATM WITHDRAW WEST',
  'APPLECOMBILL MOBILE PURCHASE',
  'LATE FEE',
  'PAYPAL VERIFYBANK PPD ID PAYPALRD',
  'BOOKS A MILLION',
  'CARRABBAS',
  'TACO CASA',
  'WINE AND SPIRITS',
  'CORNER',
  'ATM WITHDRAWAL E',
  'MURPHYATWA PURCHASE',
  'INTERNATIONAL PURCHASE TRANSACTION FEE',
  'FRONTIER AI',
  'KUMGO',
  'ZIPS',
  'TIJUANA FLATS',
  'POS DEBIT VISA CHECK CARD ALBERT INSTANT',
  'CREDIT ONE BANK PAYMENT WEB ID WEB',
  'DOLLARGE',
  'PRICE CHOPPER SUPERMARKETS',
  'AUTHORIZED ON',
  'FOREIGN TRANSACTION FEE',
  'NONCHASE ATM WITHDRAW E',
  'POS DEBIT VISA CHECK CARD SUBWAY',
  'UBR POSTMATES',
  'GOFNDME',
  'OVERDRAFT PROTECTION TO',
  'EBAY US',
  'GLOBAL',
  'GODADDY',
  'OTHER BANK ATM WITHDRAWAL FEE',
  'TRANSFER TO LOAN',
  'YOUTUBE',
  'SPEEDWAY PURCHASE',
  'TOTALVISA',
  'FASHIONNOVAC',
  'KWIK STOP',
  'LA FITNESS',
  'ALBERT GENIUS EDI PYMNTS PAYMENT WEB ID',
  'HUDSON',
  'NSF RETURNED ITEM FEE FOR ACTIVITY OF CHECK POSTING DATE POSTING SEQ',
  'ROCK AUTO',
  'SAVE AS YOU GO TRANSFER DEBIT TO XXXXXXXXXXX',
  'CUSTOMER',
  'BEALLS OUT',
  'SHIPLEY DONUTS',
  'CHARTER',
  'ITS JUST WING',
  'ACE CASH EXPRESS',
  'BAHAMA BREEZE',
  'KIDS FOOT LOCKER',
  'QUADPAY QUADPAY ANY HTTPSWWWQUAD NY',
  'SELF LENDER SCC',
  'STOCKX',
  'YOUTUBE VIDEOS',
  'FARMER BOYS',
  'FIRST LOAN',
  'HLLFRSH',
  'PAYPAL',
  'SNACK SODA VEN',
  'MARKET STREET',
  'SMITHS FOOD AND DRUG',
  'KWIK SHOP',
  'DTLR',
  'QT OUTSIDE',
  'USA CANTEEN',
  'PLAYSTATIONNET',
  'INSOMNIA COOKIES',
  'JOANN FABRIC CRAFTS',
  'DIRECT DEBIT ALBERT GENIUS EDI PYMNTS P',
  'HUDSONNEWS',
  'GRUBHUBKFC',
  'LOGANS',
  'STATERBROS',
  'MANDARIN EXPRESS',
  'JC PENNY',
  'LOVE S TRAVEL',
  'ADJUSTMENT DR',
  'SHORT STOP',
  'HOBBYLOBBY',
  'MICROSOFTXBOX MSBILLINFO',
  'EXTENDEDSTAY',
  'LOVES COUNTRY',
  'SLING TV LLC',
  'TOPHATT',
  'ALBERT GENIUS EDI PYMNTS P WEB ID',
  'SOLO FUNDS INC',
  'CRICKET WRLS',
  'CASH AMERICA',
  'FLYING J',
  'EARNINACTIVEHOUR PAYMENTRET',
  'VARO ADVANCE REPAYMENT',
  'BANFIELD',
  'WHITEPAGES',
  'COSTCO WHOLESALE',
  'ORDERWISH',
  'INSTACASH REPAYME',
  'HALLMARK',
  'LUKOIL',
  'ROSES',
  'STRAIGHTTALKAIRTIME',
  'EXPERIAN CREDIT REPORT',
  'BUILDABEAR WRKSHP',
  'ALDO',
  'NIANTIC INC',
  'MARKET',
  'BATH AND BODY WORKS',
  'PYPL PAYIN',
  'FANATICS',
  'BURGER KI',
  'SPOKEO',
  'BPCIRCLE',
  'DHGATE',
  'AUTO AIR VACUUM',
  'NONCHASE ATM WITHDRAW EAST',
  'LANE BRYANT',
  'PEP BOYS',
  'KING SOOPERS',
  'NONCHASE ATM WITHDRAW S',
  'BRIGITCOM MEMBERSHIP',
  'DOORDASH DASHPASS',
  'WITHDRAWAL FEE',
  'BPGIANT',
  'QUIK STOP',
  'TUESDAY MORNING',
  'ONLINE BANKING PAYMENT TO CRD CONFIRMATION',
  'PIZZA',
  'PLANET FIT',
  'NAYAX VENDING',
  'APPLE CASH CA',
  'PEPSIVEN',
  'BUCKLE',
  'PAYMENT TO CHASE CARD ENDING IN',
  'FLOWERS',
  'QUICK MART',
  'USPS PO PURCHASE',
  'INSUFFICIENT FUNDS FEE FOR CHECK IN THE AMOUNT OF',
  'MCDONALD',
  'JACK IN THE B',
  'EMPIRE',
  'DISH NETWORK',
  'URBAN OUTFITTERS',
  'ATM WITHDRAWAL FEE OUT OF NETWORK',
  'CHARLEYS PHILLY ST',
  'JOHNNY ROCKETS',
  'BRIGIT MEMBERSHIP',
  'PANDA EXP',
  'KRYSTAL',
  'OVERDRAFT PROTECTION XFER TO CHECKING',
  'FIVERR',
  'REVERSAL',
  'ACH TRANSACTION EMPOWER INC',
  'DIRECT DEBIT ALBERT INSTANT EDI PYMNTS INSTANT',
  'DONATOS PIZZA',
  'PROGRESSIVE LEASIN',
  'QUADPAY FASHION',
  'LEVELUPZAXBYS',
  'EZ MART',
  'SKILLZ',
  'WITHDRAWAL TRANSFER TO SHARE',
  'CHEESECAKE',
  'HLUHULU PURCHASE HULUCOMBILL',
  'VZ WIRELESS',
  'SHOPIFY',
  'METRO PCS',
  'GRUBHUBWENDYS',
  'ONLYFANSCOM',
  'FIVE BELO',
  'USA SNACK SODA VEN',
  'SHOWTIME',
  'DISNEY STORE',
  'STRAIGHTTALK AIRTIME',
  'PORTILOS',
  'TOPGOLF',
  'FUDDRUCKERS',
  'CAPITAL ONE',
  'TAKE OIL CHANGE',
  'GREYHOUND LINES CNP',
  'CREDIT ONE BANK PAYMENT',
  'PROGRESSIVE INSU',
  'ONCE UPON A CHLD',
  'USPS CHANGE OF ADDRESS',
  'REVERSAL OF TEMPORARY CREDIT PROVIDED FOR NEGATIVE BALANCE PRIOR TO STIMULUS PAYMENT',
  'AMERICAN EAGLE OUTFITTERS',
  'CARD REPLACEMENT FEE',
  'RENTLY',
  'OVERDRAFT PROTECTION FEE',
  'PF CHANGS CHINA BISTRO',
  'INTEREST CHARGECASH ADVA NCES',
  'UBERATEATS',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS HLUHULU HULUCOMBILL CA',
  'XBOX LIVE',
  'DODGE STORE',
  'THE CHILDRENS',
  'SECURUS INMATE CALL VID',
  'ONEMAIN',
  'INSTACART EXPRESS MEMBERSHIP',
  'ATM WITHDRAWAL PLUS WELLS FARGO BAN',
  'ITS JUST',
  'UBR PENDINGUBER',
  'ACE PARKING',
  'MENCHIES',
  'DAVECOM VISA DIRECT CA',
  'COMFORT INN',
  'NONCHASE ATM WITHDRAW N',
  'MONTHLY FEE',
  'SPENCER GIFTS',
  'OVERDRAFT PAID FEE',
  'ONE STOP',
  'AARONS SALO C',
  'BRIGITCOM PROTECTION',
  'SHIPT',
  'PETRO',
  'ALON',
  'REGIONS',
  'FAS MART',
  'SECU FOUNDATION',
  'WIRE TRANSFER FEE',
  'MONEYLION INSTACA',
  'WITHDRAWAL LIMIT FEE',
  'INSTACASH REPAYMENT NEW YORK NY',
  'BURLINGTON STORES',
  'INMATE PAYMENT',
  'CHUCK E CHEESES',
  'CASEYS GEN STORE',
  'ITS FASHION',
  'ROKU FOR DISNEY ELECTR',
  'QUICKCHEK',
  'FOOD DEPOT',
  'QUIZNOS',
  'WEBULL FINANCIAL',
  'BRIGIT PROTECTION PPD ID',
  'ICSOLUTIONS',
  'COST PLUS',
  'HUNTINGTON ATM CASH WITHDRAWAL',
  'NAYAX AIR',
  'RETURN ITEM CHARGEBACK',
  'DICKEYS',
  'AE OUTF ONLINE',
  'VOLA NY',
  'UNITED OIL',
  'MARSHALLS PURCHASE',
  'GUESS',
  'NOODLES',
  'VICTORIAS SEC',
  'BENIHANA',
  'EARNINACTIVEHOUR VERIFYBANK',
  'SAMSUNG ELECTRONICS',
  'PLAYSTATION NETWORK CA',
  'RETURNED ITEM CHARGEBACK FEE',
  'INTEREST CHARGE ON PURCHASES',
  'PROPERTY PAYMENT RENT',
  'CARIBOU COFFEE',
  'ADAM MAIL',
  'KEY FOOD STORES COOP INC',
  'ATM WITHDRAWAL FEE DOMESTIC',
  'FRYS FOOD AND DRUG FUEL',
  'INTEREST CHARGED ON PURCHASES',
  'ATM WITHDRAWAL N',
  'USAVEND AT AIR SERV',
  'CHURCH S',
  'INSUFFICIENT FUNDS FEE',
  'LONGHORN STEAK',
  'CENTS ONLY',
  'BRIDGECREST DT',
  'WAITR INC',
  'WINN DIXI',
  'ALBERT SAVINGS',
  'AARONS EZPAY',
  'NSF OD FEE',
  'QT PURCHASE',
  'ZOES KITCHEN',
  'BILL PAY',
  'LATE PAYMENT FEE',
  'QUADPAY QUADPAY ANYWH',
  'AARONS DIRECT',
  'DEBIT CARD SETUP FEE',
  'THE CHEESECAK',
  'GAPOWER LOCAL',
  'COUNTER CHECK',
  'POS TRANSACTION',
  'DIRECT DEBIT ALBERT INSTANT EDI PYMNTS I',
  'ROUND TABLE PIZZA',
  'HOUR FITNESS',
  'NONHUNTINGTON CASH WITHDRAWAL',
  'CITY GEAR',
  'FABLETICS',
  'CASH WITHDRAWAL',
  'ONLINE TRANSFER TO XXXXX',
  'BRANCH MESSENGER INC MN',
  'VISTAPRINT',
  'PURCHASE APPLECOMBILL CA RECURRING',
  'SCENTBIRD',
  'BIG SPORTING',
  'HAAGEN DAZS',
  'NAILS',
  'BATH BODY WO',
  'INTERNET TRANSFER CREDIT TO DD',
  'MCDONA',
  'VARO ADVANCE AUTOREPAYMENT',
  'DUNHAMS',
  'HYATT',
  'AMAZON MARKETPLACE',
  'WYNDHAM HOTELS RESORTS',
  'CMSVEND CV',
  'RUBIOS',
  'TRANSFER TO EXTERNAL BANK',
  'MILLER S ALE HOUSE',
  'ATM FEE DENIED',
  'SAVERS',
  'DUTCH BROS',
  'CREDIT ACCEPTANCE CORP',
  'BRIGIT MEMBERSHIP PPD ID',
  'ALLSUPS CONVENIENCE STORES',
  'BOX LUNCH',
  'CARTERS INC',
  'SQUARE',
  'JUSTICE',
  'ROMWE',
  'TOTAL WINE AND MORE',
  'YOUTUBEPRE',
  'ORC CREDIT ONE',
  'SCOTCHMAN',
  'YOUTUBE MUSIC',
  'USACANTEEN VENDING',
  'PAID CHECK',
  'WF WAYFAIR',
  'ALBERT GENIUS WALNUT CA',
  'VOLA INC',
  'AFFIRM PAYMENT',
  'FORMAN MILLS',
  'TU TRANSUNION',
  'BRAUMS STORE',
  'LAKELAND',
  'LUSH US',
  'TRANSFER FROM VARO SAVINGS ACCOUNT TO VARO BANK ACCOUNT',
  'SUNPASS',
  'GORDMANS',
  'ESPORTS',
  'MINICLIP',
  'FABFITFUN',
  'SAVINGS OD PROTECTION TRANSFER FEE',
  'CURRENT',
  'TOYS R US',
  'ACH TRANSACTION ALBERT GENIUS EDI PYMNTS',
  'ATM WITHDRAWAL S',
  'SMARTSTYLE',
  'ZENNI OPTICAL',
  'PAPA JOHNS PI',
  'CHECKCARD DAVECOM CA',
  'BARNESNOBLE',
  'MAURICES',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS BRIGITCOM PROTECTION PPD ID',
  'CC BILLCOM ONLY FANS',
  'FACEBOOK',
  'PET SUPERMARKET',
  'VZWRLSS BILL PAY',
  'MARKET B',
  'EL SUPER',
  'CHINA KING',
  'VERIZON WRL MY ACCT VE',
  'XFINITY PREPAID',
  'MENARDS',
  'SHERATON',
  'ML PLUS MEMBRSHP MONEYLION',
  'BOWLERO',
  'CAPITAL ONE ONLINE PMT WEB ID',
  'MODELLS',
  'BASS PRO STORE',
  'MOON ACTIVE',
  'BOOHOO',
  'MARKET BASKET',
  'ADORE ME INC',
  'TMOBILE POSTPAID FDP',
  'CASEYS GEN',
  'KRYSTAL ATL',
  'NON CASH WITHDRAWAL FEE',
  'CRAIGSLISTORG',
  'NONHUNTINGTON ATM CASH WITHDRAW',
  'PURCHASE AUTHORIZED ON DAVECOM VISA DIRECT CA S CARD',
  'CASEYS',
  'NSF FEE',
  'FUZZYS TACO SHOP',
  'JEWELOSCO',
  'DAVE ADVANCE',
  'FUBOTV INC',
  'POSSIBLE FINANCE PAYMENT',
  'GRUBHUBPOPEYES',
  'PURCHASE AUTHORIZED ON ALBERT GENIUS WALNUT CA S CARD',
  'OVERDRAFT PD',
  'TURKEY HILL',
  'NATIONWIDE',
  'CRUMBL',
  'SPIN SCOOTER',
  'LOAF N JUG',
  'DAVE INC LOS ANGELES CA',
  'ANCESTRY',
  'AFTERPAY US INC CA',
  'HAMPTON INNS',
  'ELECTRONIC IMAGE',
  'ROKU FOR STARZ',
  'STUBHUB INC',
  'TRANSFER TO ACCT ON VIA WEB',
  'SKYLINE CHILI',
  'HALF PRICE BOOKS',
  'J PAY STAMPS',
  'POS DEBIT VISA CHECK CARD H M',
  'STRAIGHTTALK SERVICES',
  'SWEET FROG',
  'ALBERT INSTANT CA',
  'DOLLARTREE PURCHASE',
  'BRANCH MESSENGER BRANCH MES',
  'CHICKFILA APP',
  'AVON',
  'AUTO AIR VACUUM SERVICE',
  'CHINA MAX',
  'HELLOFRESH',
  'CARQUEST',
  'BURLNGTON STORES',
  'AMOCO',
  'MORPHE',
  'DAIRYLAND',
  'LATE FEE FOR PAYMENT DUE',
  'CREDIT REPORT',
  'ATM WITHDRAWAL PLUS BANK OF AMERICA',
  'THE OLIVE GARD',
  'DEPOSITED ITEM RETN UNPAID PAPER',
  'COX',
  'AFTERPAY DE',
  'HLUHULU',
  'PLT US',
  'HM PURCHASE',
  'FAMOUS DAVES',
  'ERENTERPLAN',
  'USAA INSURANCE',
  'MESSENGER INC',
  'HERTZ',
  'INSTACART HTTPSINSTACAR',
  'BURKES OUT',
  'BURGER',
  'BAHAMA BUCKS',
  'DEBIT CARD PURCHASE AT DAVECOM CA ON FROM CARD XXXXXXXXXXXX',
  'ACCESS SECURE DEPOS',
  'ATM WITHDRAWAL P',
  'HOPPER',
  'FIVE BELO PURCHASE',
  'AUTO AIR VACUUM SERVI',
  'DOLLARSHAVECLUBUS',
  'BANK OF AMERICA CREDIT CARD BILL PAYMENT',
  'TINDER',
  'ATM WITHDRAWAL PLUS PNC BANK',
  'EXPERIAN CREDIT REPOR',
  'CREDIT',
  'VERIZONWRLSS',
  'COACH INC',
  'FRYSFOOD',
  'KAY JEWELERS',
  'PAYRANGE MOBILE',
  'DEBIT FOR DAVE INC DEBIT CO REF',
  'GATE',
  'LENSCRAFTERS',
  'DENNYS PURCHASE',
  'INTERNET TRANSFER CREDIT TO SV',
  'E BAY O',
  'VICTORIASSECRETCO',
  'PAID OVERDRAFT ITEM FEE',
  'ICING',
  'INTERNAL REVENUE SERVICE',
  'BRIGIT PROTECTION',
  'CRICUT',
  'ACH TRANSACTION EARNIN ACTIVE PAYMENTRET',
  'DEBIT CARD PURCHASE XXXXX APPLECOMBILL XXXXX',
  'TRANSUNION',
  'YCC STORE',
  'PLAYRIX GAMES',
  'EBAY O',
  'DISCOVER EPAYMENT WEB ID',
  'STOP N SHOP',
  'DOMINION ENERGY',
  'LEXINGTON LAW',
  'SPRINT WIRELESS KS',
  'FINGERHUT FRESHSTART',
  'INTERNATIONAL PROCESSING FEE CARD',
  'SUDDENLINK',
  'BETFAIR',
  'KWIK FILL',
  'CLAIM REVERSAL',
  'OFFICIAL CHECKS CHARGE',
  'INTERNATIONAL SERVICE',
  'ALBERT INSTANT WALNUT CA',
  'FREETAXUSA',
  'TEXAS',
  'CHARLEYS GRILLED SUBS',
  'ZULILY',
  'DISCOUNTTIRECO',
  'FIVE STAR FOOD',
  'PNC BANK',
  'AFTERPAY US INC PURCHASE',
  'HAUTLK RACK',
  'DIRECT DEBIT CAPITAL ONE MOBILE PMT',
  'ALDI PURCHASE',
  'NFCU ONLINE CASH ADVANCE',
  'AVIS',
  'VUDUCOM',
  'PURCHASE AUTHORIZED ON EBT ACCT CHECK MEMPHIS TN S CARD',
  'COST PLUS WORLD MARKET',
  'SUNGLASS HUT',
  'SONIC DRIVEI',
  'MICHAELS STORES',
  'GTLINMATE PHONE SVC',
  'EBT ACCT CHECK MEMPHIS TN',
  'PRIMERICA',
  'OPENSKYCC',
  'XOOM',
  'PLAYSTATION NETWOR',
  'EFT WITHDRWL EFT',
  'RETURNED NSF ITEM FEE',
  'SAMS MART',
  'CROCS INC',
  'H E B',
  'RACETRAC PURCHASE',
  'OCULUS',
  'GET GO',
  'EMBASSY SUITES',
  'IPSY GLAM BAG',
  'WINDSOR FASHIONS',
  'CHILIS GR',
  'HANNAFORD',
  'OVERDRAFT ITEM FEE FOR ACTIVITY OF CHECK POSTING DATE POSTING SEQ',
  'QUADPAY A HTTPSWWWQUAD NY',
  'FOREVER PURCHASE',
  'CANVA',
  'FUNIMATION',
  'DASHMART',
  'CITGO FOOD MART',
  'TRANSFER FROM VARO BANK ACCOUNT TO VARO SAVINGS ACCOUNT',
  'MOES SOUTHWEST GRILL',
  'GENESISFS CARD',
  'DELTA',
  'TIDAL',
  'LA QUINTA INNS',
  'TOPS FRIENDLY MARKETS',
  'BRIGIT DEBIT WEB ID',
  'YOU TUBE PREMIUM',
  'FAST STOP',
  'NONTD ATM FEE',
  'CHINA GARDEN',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS PLANET FIT CLUB FEES PPD ID',
  'SWA INFLIGHT WIFI',
  'CHASE',
  'INMATE PHONE SVC',
  'TESORO',
  'SMOKER FRIENDLY',
  'ADD TO BAL',
  'BURLINGTON STO PURCHASE',
  'GRUBHUBBURGERKING',
  'CORRAL',
  'MICROSOFTXBOX MSBILLINFO WA',
  'ACH TRANSACTION CAPITAL ONE MOBILE PMT',
  'FIVE GUYS BURGERS AND FRIES',
  'AARONS SALO',
  'WITHDRAWAL BY CHECK',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON HLUHULU HULUCOM',
  'HOTTOPIC',
  'GOFUNDME',
  'VICTORIAS SEC PURCHASE',
  'ALBERT INSTANT EDI PYMNTS',
  'WAWA PURCHASE',
  'SCENTSY INC',
  'STAR STOP',
  'SURCHARGE FEEP',
  'DRAFTKINGS INC',
  'EXTRA SPACE',
  'WAFFLE HOUSE PURCHASE',
  'VBS BETTERDAY',
  'SPRINT WIRELESS PURCHASE',
  'TAQUERIA',
  'AFTERPAY CA',
  'ABC FINE WINE SPIRITS',
  'VERIZON AGENT FEE',
  'NONCHASE ATM WITHDRAW TH',
  'ROAD RANGER',
  'ULTIMATE MO',
  'PEI WEI ASIAN DINER',
  'FLEXSHOPPER',
  'PARKERS',
  'MINI MART',
  'JAILATM COM',
  'COMFORT SUITES',
  'TARGET MOBILE PURCHASE',
  'SIMPLEMOBILE',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON CHECK',
  'CRACKER BARRE',
  'ZALES',
  'HORIZONCARD SVCS',
  'BATH AND BODY W',
  'CONT FINANCE',
  'MBIPROBILLER',
  'PETER PIPER PIZZA',
  'BATH AND BODY PURCHASE',
  'RING MONTHLY',
  'ARCO AMPM',
  'HUDSONNEWS ST',
  'KLOVER APP',
  'APPLE CASH INFINITELOOP',
  'CUSHIONAI',
  'COCA COLA',
  'IN N OUT BURGER PURCHASE',
  'TURO INC',
  'MOTOMART',
  'PANDA EXPRE',
  'A EAGLE OUTFTR',
  'LUCKYLAND',
  'OTHER BANK ATM BALANCE',
  'BEAUTY SUPPLY',
  'HP INSTANT INK',
  'GAP OUTLET US',
  'ZYNGA INC',
  'WABA GRILL',
  'GREAT WALL',
  'DISNEYPLUS CA',
  'POS DEBIT VISA CHECK CARD MURPHYATWAL',
  'VBS MINTOMONEY',
  'MARATHON PETRO PURCHASE',
  'TMOBILE POSTPAID P',
  'LEVIS OUTLET',
  'DDBR Q',
  'NATL GEN INS',
  'BOOT BARN',
  'STORE MGRSBUX',
  'HIBACHI EXPRESS',
  'STARZ',
  'USASNACK SODA VEN',
  'MICROSOFTSTORE',
  'SMILEDIRECTCLUB',
  'SHOP N GO',
  'NOA COPPA',
  'TRACTORS',
  'STEAKNSHAKE',
  'ATM CASH WITHDRAWALS',
  'INSTACASH PAYMEN MONEYLION',
  'BUFFALO WILD WING',
  'METROCARD MACHINE',
  'MINECRAFT',
  'EB TO CHECKING',
  'GO PUFF',
  'FOOD TOWN',
  'ACCEPTANCENOW',
  'NAPA STORE',
  'BETTER DAY LOANS',
  'GIRL SCOUT COOKIES',
  'TARGET T',
  'ECOMPLISH INC',
  'YOGURTLAND',
  'STASH TRANSFER',
  'GTL INMATE PHONE SVC',
  'GRUBHUBSUBWAY',
  'NYCT PAYGO',
  'TNT FIREWORKS',
  'SHOE DEPT ENCORE',
  'WEB INITIATED PAYMENT AT DAVE INC DEBIT',
  'LOVES CNTRY',
  'GUYS',
  'CREDIT ONE',
  'SAVE YOUR CHANGE TRANSFER',
  'HUDSON NEWS',
  'BATTERIES PLUS',
  'DOORDASH STRIPECOM',
  'SKY ZONE',
  'EXCESS ACTIVITY FEE',
  'NFLSHOP',
  'FAMILY',
  'SECURUS INMATE CAL',
  'APPLE CASH INFINITELOOP CA',
  'ROKU FOR DISNEY ELECTRO',
  'LENDUP',
  'VISIONWORKS',
  'PLANET FIT CLUB FEES PPD ID',
  'RECURRING PAYMENT AFTERPAY US INC',
  'CREDIT ONE BANK PAYMENT WEB ID',
  'INTEREST CHARGECASH ADVANCES',
  'GENESISFS CARD WEB ID',
  'TMOBILE AUTO PAY',
  'RETURNED ITEM FEE FOR AN UNPAID CHECK IN THE AMOUNT OF',
  'DOLLAR TR PURCHASE',
  'BISCUITVILLE',
  'CASHEDDEPOSITED ITEM RETN UNPAID FEE',
  'SECURUS INMATE CALLVI',
  'AGODA',
  'PAYLESS SHOESOURCE',
  'SALLY BEAUTY PURCHASE',
  'DIRECT DEBIT EMPOWER TRANSFER',
  'PAYPAL INST XFER WEB ID PAYPALSI',
  'FRED MEYER FUEL CENTER',
  'BIGGBY COFFEE',
  'ULTA PURCHASE',
  'NONCHASE ATM WITHDRAW SOUT',
  'KLOVER APP BOOST CHICAGO CA',
  'STOP N GO',
  'ASIAN CHAO',
  'CAPITAL ONE ONLINE PMT',
  'OFFICE DE',
  'CHICKFI',
  'REGAL NAILS',
  'OFFICEMAX',
  'TROPICAL SMOO',
  'EB TO SAVINGS',
  'SALLYBEAUTY',
  'PYBRIDGECREST',
  'CA DMV FEE',
  'SUNPASS ACC',
  'HUDSON ST',
  'TMOBILEPOSTPAID PDA WA',
  'EVERI BAL INQ EVERI FEE',
  'SUPERCELL',
  'FEE FOR NONCITIBANK ATM USE',
  'ML PLUS MEMBRSHP MONEYLION PPD ID',
  'PUBLIC',
  'HAIR CUTTERY',
  'TURBOTAX',
  'MR HERO',
  'ECONO LODGE',
  'OPTIMUM',
  'MONEYTREE',
  'PATREON',
  'PROPERTY',
  'USA CANTEEN VENDIN',
  'MADRAG',
  'WU BRIDGECREST',
  'RECURRING PAYMENT DAVECOM',
  'CLEO PURCHASE',
  'PAYLESS SHOESO',
  'GRUBHUBDUNKIN',
  'CAFE RIO MEXICAN GRILL',
  'TACOTIME',
  'ACTIVISION',
  'AFTERPAY PURCHASE',
  'H MART',
  'PILOT PURCHASE',
  'CENTS ONLY STOR',
  'HSN',
  'RODEWAY INN',
  'MTA METROCARD MACHINE',
  'USACANTEEN',
  'SQUARE INC CASH APP T CCD ID',
  'UPSTART',
  'G GOAT',
  'ATM WITHDRAWAL PAI ISO',
  'CIRKUL INC',
  'BUBBAS',
  'COTTON ON',
  'ROSS STORE',
  'LEVELUPKFC',
  'PMNT SENT APPLE CASH INFINITELOOPCA',
  'LEMONADE I',
  'OH LOT',
  'FASHION',
  'ARCO PURCHASE',
  'GUYS QSR',
  'DELTA AIRLINES',
  'RANDALLS',
  'NAVY EXCHANGE',
  'THE JUICY CRAB',
  'POS DEBIT VISA CHECK CARD DAVECOM',
  'ROOSTERS',
  'SCOOTERS COFFEE',
  'SANTANDER CONSUMER',
  'QDOBA MEXICAN',
  'SKECHERS',
  'INTUIT',
  'HOMEWOOD SUITES',
  'IM PRO MAKEUP NY LP',
  'WILD WING CAFE',
  'CASEYS PIZZA',
  'MONEYLION INSTACASH NY',
  'SMOKEY BONES',
  'MURPHYATWALMAR',
  'PEPBOYS STORE',
  'CHEWYCOM',
  'WU CO',
  'BUFFALO W',
  'DIGITCO DIGITCO PPD ID',
  'GRAIN TECHNOLOGY',
  'HANDY MART',
  'PURCHASE AUTHORIZED ON KLOVER APP BOOST CHICAGO CA S CARD',
  'HERBALIFE',
  'CHINA EXPRESS',
  'YOUTUBE VI',
  'ONEMAIN FINANCIAL',
  'CUB FOODS',
  'EDIBLE ARRANGEMENT',
  'NST THE HOME D PURCHASE',
  'GREAT AMERICAN',
  'PANDORA ECOMMERCE',
  'SAVE YOUR CHANGE',
  'MICHAELS STORE PURCHASE',
  'AM EAGLE OTFIT',
  'POS DEBIT VISA CHECK CARD BRANCH MESSENGER I',
  'FUNDS TRANSFER LOAN PAYMENTS FUNDS TRANSFER TO',
  'APPLECOM BILL',
  'PROGRESSIVE INSU CE',
  'NORTHGATE',
  'SXMSIRIUSXMCOMACCT',
  'CHINA HOUSE',
  'FALLAS',
  'ACH TRANSACTION EARNINACTIVEHOUR PAYMENTRET',
  'GLDSCR',
  'NSF FEE WITH IMAGE ACH DEBIT',
  'TO USAA FUNDS TRANSFER DB',
  'OVERDRAFT CHARGE',
  'TWITCH',
  'STEAM',
  'GAP US',
  'WM SUPERC WAL PURCHASE',
  'CASHNET',
  'OVERDRAFT TRANSFER TO DD',
  'MICROSOFTXBOX PURCHASE MSBILLINFO',
  'CVSPHARMACY',
  'TWIN LIQUORS',
  'TACO B',
  'TARGETCORPO',
  'WESTSIDE LENDING',
  'REDBUBBLE',
  'USAA FUNDS TRANSFER DB TO',
  'DIRECT DEBIT CREDIT ONE BANK PAYMENT',
  'MINIT MART',
  'MOBILE BANKING TRANSFER WITHDRAWAL',
  'ATM WITHDRAWAL PLUS A',
  'ORDERWISHCOM',
  'NEW CHINA',
  'AT HOME',
  'WM MAX LLC',
  'KTPLTWAYFAIR',
  'TROPICAL',
  'CASH APPSTEPHANIE',
  'KFCAW',
  'TRUE VALUE HARDWARE',
  'ALBERT INSTANT EDI PYMNTS I WEB ID',
  'PIT STOP',
  'ON THE RUN',
  'MURPHYATWA',
  'SMITHS',
  'TOUCHTUNESHELPSHIFTCO',
  'ALLSTATE INS CO',
  'UBER TRIP',
  'DAVE INC DEBIT ACH WITHDRAWAL',
  'TH STREET',
  'SIE PLAYSTATIONNET',
  'HARVEYS',
  'ONEMAIN LOAN',
  'IRS USATAXPYMT',
  'CIRCLE M',
  'VOLA VOLA',
  'THE UPS STORE',
  'PURCHASE INTEREST CHARGE',
  'SPEEDY STOP',
  'UBER CA',
  'PAY',
  'BEAUTY WORLD',
  'WAWA STORE',
  'AMF',
  'TACO MAC',
  'POS DEBIT VISA CHECK CARD HLUHULU HULUCOM B',
  'IRS',
  'DEBIT CARD PURCHASE AUT VISA DDA PUR',
  'GM FINANCIAL',
  'ATM WITHDRAWAL TH',
  'SPEEDY MART',
  'USAGE FEE ATM',
  'CASH APPCHRISTOPHE',
  'ODP TRANSFER TO CHECKING',
  'GACOM',
  'PETROMART',
  'GOLDEN KRUST',
  'FUNDS TRANSFER TO ACCT XXXXX',
  'KINDLE UNLTD',
  'ABERCROMBIE',
  'SLEEP INN',
  'METROPOLITAN TRANSPORTATION AUTHORITY',
  'NATYOURSCOREANDMORE',
  'ECHST',
  'DEBIT POS AUT DDA PURCHASE',
  'J PAY MONEY TRANSFER',
  'PORTILLOS',
  'GREASE MONKEY',
  'GENIUS',
  'PARKER S',
  'PAYBYPHONE',
  'ATM FEEBAL INQ AT OTHER NETWORK',
  'SEAMLESS',
  'FIREHOUSE SUB',
  'CHARLEYS PHILLY',
  'PITA PIT',
  'THE SALVATION ARMY',
  'OUTBACK',
  'CHECK CLEARED',
  'FOODTOWN',
  'CHINA BUFFET',
  'BEVERAGES MOR',
  'ALBERT INSTANT AUTH',
  'OREILLY AUTO PURCHASE',
  'WAYBACK BURGERS',
  'CREDIT R',
  'INSTACASH REPA',
  'BILLIE INC',
  'AMC ONLINE KS',
  'CLEO AR',
  'SCORESENSE',
  'PADDLE',
  'EARNINACTIVEH',
  'INSTANT INK',
  'STYLES',
  'BASHAS',
  'LABCORP',
  'FANTASTIC SAMS',
  'FASHIONNOVACOM',
  'FIRST PROGRESS',
  'CBS ALL',
  'RECURRING PAYMENT AFTERPAY',
  'DISCOUNT',
  'SEARS',
  'SONIC DRI',
  'CANTEEN VENDIN',
  'KIRKLANDS',
  'GREYHOUND LINES',
  'DISNEYPLUS PURCHASE',
  'CORNER BAKERY',
  'BRANCH MESSENGER INC PURCHASE',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS APPLECOMBILL CA',
  'DOMINO',
  'ADVANCE AUTO P PURCHASE',
  'CASH ADVANCE FEE',
  'UBR PENDINGUBERCOM',
  'CREDIT REPOR',
  'CERTUS AIR VENDING',
  'STUDIO',
  'OVERDRAFT RETURNED FEE',
  'MONEY ORDER',
  'BANK DEBIT',
  'DISCOUNT LIQUOR',
  'SE',
  'BUYBUYBABY',
  'DUNKIN Q',
  'ACH TRANSACTION BRIGITCOM MEMBERSHIP',
  'GOODYEAR',
  'TMOBILEPOSTPAID PDA',
  'ETSYCOM NY',
  'WISHCOM CA',
  'PREMIER BANKCARD LLC',
  'FRANCESCAS',
  'AT T',
  'CKO PATREON MEMBERSHI',
  'DICKS CLOTHINGSPORTING',
  'NURXCO',
  'GRID APP',
  'FRYS',
  'PARKING',
  'CRUNCHYROLL',
  'ADOBE INC',
  'GENGHIS GRILL',
  'NONCHASE ATM WITHDRAW HWY',
  'ONCE UPON A CHILD',
  'DICKSSPORTINGGOOD',
  'KTLPTWAYFAIR',
  'EA MOBILE',
  'PAYPAL RETRY PYMT',
  'TMOBILEPOSTPAID P',
  'INTEREST CHARGE ON CASH ADVANCES',
  'CALVIN KLEIN',
  'GOMART',
  'SPORTSMANS WAREHOUSE',
  'STRAIGHTTALKSERVICES',
  'PAYPAL ECHECK',
  'JAM CITY',
  'GASBUDDY',
  'RECURRING PAYMENT DISNEYPLUS',
  'TELLER CASHED CHECK',
  'HIATUSAPP',
  'ESURANCE CAR INSURANCE',
  'TOMMYS',
  'JACK IN THE',
  'FIRSTENERGY',
  'ROKU FOR DISNEY EL',
  'EXPERIAN CREDIT R',
  'FASHION NOVA',
  'NOTHING BUNDT CAKE',
  'GFS STORE',
  'ALABAMA ABC',
  'ROBERTOS TACO SHOP',
  'COLOR STREET LLC',
  'TRANSFER TO OTHER',
  'CASHNETUSA',
  'SPECS',
  'ROSES STORES',
  'CHINA ONE',
  'MAESTRO CIRRUS SETTLEMENT',
  'ALASKA AIR',
  'WALGREENS STORE',
  'COMM ED',
  'EL RANCHO',
  'CREDIT ACCEPTANCE',
  'BILO',
  'PAPA JOHN',
  'NEW YORK STATE DMV',
  'BILL PAYMENT PURCHASE',
  'MARIONOS',
  'HOLIDAY STATIONS',
  'EUROPEAN WAX CENTER',
  'REGIONAL ATM WITHDRAWAL FEE',
  'STATE OF CALIF DMV INT CA',
  'CHINA',
  'CASHADVPAY',
  'STASH SUBSCRIPTION',
  'MCALISTERS DE',
  'SBD SCENTBIRD',
  'MCAFEE',
  'STITCH FIX',
  'BARNES AND NOBL',
  'SCHNUCKS',
  'PLANET SMOOTHIE',
  'FPB CR CARD INTERNET WEB WEB ID',
  'CHICK',
  'MASSAGE ENVY',
  'CASH SAVER',
  'AARONS ONLINE PAYMENT',
  'TACO BEL',
  'DOORDASH WWWDOORDASH',
  'RAMADA',
  'PAYRANGE MOBILE APP',
  'SMARTCREDIT',
  'BILL PAY V',
  'VBS WEST SIDE',
  'GAS EXPRESS',
  'EBAYINCSHIP',
  'PETCO PURCHASE',
  'ONSTAR',
  'ZAPPOS',
  'TRACFONE',
  'JJ FISH CHICKEN',
  'SKECHERSUSA',
  'FRONTIER',
  'GOOGLE STO',
  'MONEYLION MEMBERSHIP',
  'ACH DEBIT DAVE INC DEBIT',
  'ALIBABA',
  'ACH TRANSACTION BRIGITCOM PROTECTION',
  'RESTAURANT',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS CAPITAL ONE MOBILE PMT WEB ID',
  'ADVANCEAUTOPARTS',
  'CITY OF',
  'AFTERPAY PURCHASE DE',
  'SPOTIFYUSAI',
  'DOORDASH STRIPECOM CA',
  'VILLAGE PANTRY',
  'TORCHYS TACOS',
  'RELIANT ENERGY',
  'NONHUNTINGTON ATM BALANCE INQUI',
  'GODIVA',
  'ASURIONWIRELESS IN',
  'HOT SPOT',
  'GOODRX',
  'RECURRING PAYMENT MICROSOFTXBOX MSBILLINFO',
  'CHECKERS PURCHASE',
  'VEND AT AIR S',
  'RALEYS SUPERMARKET',
  'DC DSP TEMP CR REV',
  'DOORDASHCA',
  'REFUEL',
  'CHANGEORG',
  'PENDING',
  'SAVE YOUR',
  'TO DAVE INC DEBIT',
  'EASTBAY',
  'POINT OF SALE DEBIT L DATE CASH APP',
  'QUADPAY FASHION NOVA',
  'POS DEBIT VISA CHECK CARD PLAYSTATION NETWOR',
  'PORT OF SUBS',
  'BPCKW',
  'ML PLUS NY',
  'CORNER MART',
  'REEBOK',
  'MAVIS TIRE',
  'QUICKPAY WITH ZELLE PAYMENT TO JPM',
  'LYFT RIDE',
  'NONCHASE ATM WITHDRAW',
  'DTE ENERGY',
  'HULU LLC',
  'ATMOS ENERGY',
  'BRUEGGERS',
  'QUICKPAY WITH ZELLE PAYMENT TO',
  'DEALS',
  'ATM WITHDRAWAL ELEVENFC',
  'HUCKS FOOD F',
  'JACK IN T',
  'NONCHASE ATM WITHDRAW NORT',
  'DEBIT CARD PURCHASE A DAVECOM',
  'CITITRENDS',
  'SODA SNACK VENDING',
  'STINKER STORES',
  'GIANT EAG',
  'ZIPCAR',
  'ACH TRANSACTION PAYPAL INST XFER',
  'ASOS',
  'COLD STONE CR',
  'CONVERSE',
  'PROG SELECT',
  'SONNYS BBQ',
  'DISNEY MOB',
  'STITCH FIX INC',
  'INGLES GAS EXP',
  'BILL PAYMENT TX',
  'BRANCH MESSENGER PURCHASE',
  'BWW',
  'DILLONS',
  'DOORDASH DASHPASS WWWDOORDASH CA',
  'MAIN EVENT',
  'SAVAGEXFENTY',
  'GMFINANCIA',
  'TRAVELOCITY',
  'BRUSTERS ICE CREAM',
  'CHECKCARD MURPHYATWA',
  'FRESH THYME',
  'LITTLE CA',
  'BONEFISH',
  'ELEMENT VAPE',
  'DEBIT CARD PAYMENT AUT VISA DDA PUR',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON PLANET FIT CLUB FEES',
  'FATBURGER',
  'TIMEWISE',
  'LA MICHOACANA',
  'USPS CHANGE OF ADD',
  'SS BOOKING FEE',
  'BPCIRC',
  'THE FRESH MARKE',
  'SP FASHIONNOVACOM FNOVAMYSHOPI CA',
  'LOCATION',
  'VBS CREDITCUBE',
  'CATO',
  'HONG KONG',
  'QUADPAY FASHION NOV HTTPSWWWQUAD NY',
  'BUMBLE',
  'WENDY S',
  'INSTACART SAN',
  'ACH TRANSACTION FLOAT ME CORP PAYMENTS',
  'USACANTEEN VENDIN',
  'SAMSUNGELECTRONICS',
  'SURCHARGE FEEPAI ISO',
  'ACH TRANSACTION PAYPAL VERIFYBANK',
  'WWWKOHLSCOM',
  'ORDERWISHCOM WWWWISHCOM',
  'CUBESMART',
  'SPECTRUM PURCHASE',
  'CENTS ONLY ST',
  'STEIN MART',
  'MATTRESS FIRM',
  'FOODMAXX',
  'PERKINS',
  'AFFIRM INC',
  'CARVEL',
  'AMERICAS BEST WINGS',
  'DISCOUNT TIRE',
  'SCOPELY INC',
  'YOU TUBE VIDEOS',
  'OPPORTUNITY FINA',
  'PYPL PAYIN CA',
  'BRIGIT DEBIT',
  'MIDAS',
  'TJMAXX PURCHASE',
  'FIRSTACCESS',
  'SPOTHERO',
  'NYC DOT PARKING METERS',
  'SPEEDEE MART',
  'MARBLE SLAB CREAMERY',
  'VBS LENDUMO',
  'SPKSPOKEO SEARCH',
  'MOJANG',
  'O REILLY AUTO',
  'WM SUPERC',
  'ONLINE TRANSFER TO CK XXXXXX REF',
  'XSOLLA ROBLOX',
  'CVSPHARMACY PURCHASE',
  'FAST MART',
  'ALASKA AIRLINES',
  'YOUTUBEPREM',
  'BUFFALO WILD WI',
  'TH MIDWEST',
  'FREESHIPPING',
  'FAT TUESDAY',
  'CRSCR',
  'CAPITAL ONE CRCARDPMT WEB ID',
  'PROG ADVANCED',
  'INTERNATIONAL TRANS FEE',
  'TD ATM DEBIT AUT DDA WITHDRAW',
  'PANERA BR',
  'KEYME',
  'LIQUOR',
  'DOORDASHSUBWAY',
  'REBEL',
  'STARBUCKS STORE',
  'CHUYS',
  'SHAPERMINT',
  'SYNCHRONY BANK CC PYMT WEB ID',
  'POSSIBLE FINANCE DEBIT WEB ID',
  'ATM SERVICE FEE',
  'SURCHARGE FEELK',
  'BROOKSHIRE GROCERY COMPANY',
  'BUDGET CAR RENTAL',
  'STOP N SAVE',
  'GEORGIA POWER',
  'THE UPS STORE PURCHASE',
  'TOUCHTUNESHELPSHIFT',
  'DIRECT DEBIT CREDIT KARMA TRANSFER',
  'WITHRAWAL BY CASH',
  'FAMILY EXPRESS',
  'NONTD ATM DEBIT AUT DDA WITHDRAW',
  'DISCOVER EPAYMENT',
  'GRUBHUBFOOD',
  'OCEAN STATE',
  'STEWARTS SHOPS',
  'ORCCREDIT ONE',
  'USPS CHANGE OF ADDRES TN',
  'BARKCO',
  'RECOUP',
  'HELPHBOMAXCOM HTTPSHBOMAXC NY',
  'ISA FEE',
  'AMZN MKTP',
  'POLLO CAMPERO',
  'CHINA STAR',
  'SHOEDAZZLE',
  'MONEYLION INSTA',
  'MICROSOFTXBOX LIVE GO MSBILLINFO WA',
  'HARRYS',
  'YOURSCOREANDMOREC',
  'EINSTEIN BROS',
  'RACK ROOM RACK ROOM SH',
  'AUNTIE',
  'VEND AT AIR',
  'MICROSOFT ULTIMATE MO',
  'QUADPAY A',
  'GAS N GO',
  'EZ GO',
  'CLARKS',
  'TRANSFER TO CHASE',
  'SHELL SERVICE',
  'EMPOWER TRANSFER',
  'WALMART SUPER',
  'ROUTE',
  'CHECKCARD ALBERT GENIUS',
  'USA VEND AT AIR',
  'INTERNET TFR TO SAVINGS',
  'NOTHING BUNDT CAKES',
  'CAR WASH',
  'PLAYSTATIONNETWORK',
  'DIRECT DEBIT DIGITCO RAINY DAY',
  'OD ADVANCE TRANSFER OUT',
  'SONIC DRIVE I',
  'CENTRAL MARKET',
  'TEEPUBLIC',
  'STRAIGHTTALK AIRTI',
  'DIRECT DEBIT',
  'SENT ZELLE PMT ID FTB',
  'ARCO AM PURCHASE',
  'INTERNET TFR TO CHECKING',
  'KING',
  'PROGRESSIVE INSURANC OH',
  'TOTAL LIFE CHANGES',
  'INTEREST PAYMENT',
  'SAVE YOUR PAY TRANSFER',
  'DDBR Q PURCHASE',
  'CTS STORE',
  'DIRECT AUTO INS',
  'TOUCHTUNESHELPSHI',
  'LANEBRYANT',
  'CASH APPASHLEY',
  'ACH DAVE INC DEBIT',
  'NONCHASE ATM WITHDRAW MAIN',
  'ZIPCAR INC',
  'GREAT AMERICAN COO',
  'QUICK',
  'CHEGG',
  'PERFUMANIA',
  'CHECKCARD VOLA NY',
  'QFC',
  'GREAT AMERICAN COOKIE',
  'QUALITY INNS',
  'KATE SPADE',
  'DAIRY QUE',
  'BJS WHOLESALE CLUB FUEL',
  'BOSCOVS',
  'CELLULAR SALES',
  'DISCORD',
  'PRIORITY',
  'RENTERSCONDO INS',
  'CENTERPOINT ENERGY',
  'RECURRING PAYMENT AFTERPAY DE',
  'MELROSE',
  'ONLINE TRANSFER TO SV XXXXXX REF',
  'DOORDA',
  'SCHNUCKS MARKETS',
  'IBI SHOEDAZZLE',
  'NONCHASE ATM WITHDRAW NORTH',
  'ADM GMA OTI',
  'BAJA FRESH',
  'TARGET PURCHASE',
  'INSUFFICIENT FUNDS CHARGE',
  'VESTA ATT PREPAID',
  'SHOPPERS',
  'RAINBOW PURCHASE',
  'SHONEYS',
  'OLIVE GARDE',
  'CKO PATREON MEMBERSHIP',
  'T J MAXX',
  'MARKET A',
  'VERIZON WRL MY ACCT VN',
  'AUTO AIR VACUUM SERVI PLAINVIEW NY',
  'BEENVERIFIED',
  'GRUBHUBIHOP',
  'PAYPAL TRANSFER',
  'FANDUEL INC',
  'SHERWINWILLIAMS',
  'PLAID PANTRY',
  'BATHBODYWOR',
  'QUICKPAY WITH ZELLE PAYMENT TO MOM',
  'OVERDRAFT PROTECTION WITHDRAW',
  'BEN JERRYS',
  'BEAUTY',
  'VZWRLSS BILL PAY V',
  'COMENITY PAY VI WEB PYMT P WEB ID',
  'SAFE AUTO INSURANCE CO',
  'SPIRIT AI',
  'DOMESTIC INCOMING WIRE FEE',
  'CORNER STORE PURCHASE',
  'DICKSSPORTINGGOODSCO SSPORTINGGOODSCO',
  'BATH BODY WOR',
  'AMERICAN AIR',
  'HANDI STOP',
  'PEI WEI',
  'POS DEBIT VISA CHECK CARD WAWA',
  'LINKEDIN',
  'CRT LEXINGTON LAW',
  'GRUBHUBCHIPOTLE',
  'QUADPAY QUADPAY A HTTPSWWWQUAD NY',
  'NON SUNTRUST ATM FEE ATM CASH WITHDRAWAL FEE',
  'ACH TRANSACTION CREDIT ONE BANK PAYMENT',
  'DENNY S',
  'DOORDASH DASHPASS WWWDOORDASH',
  'RETURNED CHECKNSF FEE',
  'ORDERWISHCOM WWWWISHCOM CA',
  'HAPPYS PIZZA',
  'AMC ONLINE PURCHASE',
  'FORMSWIFT',
  'KONA GRILL',
  'ONLINE SCHEDULED TRANSFER TO SAV CONFIRMATION',
  'GRAB N GO',
  'DROPBOX',
  'EZ STOP',
  'FILIBERTOS',
  'SPRINGHILL SUITES',
  'EMPOWER TRANSFER WEB ID',
  'MICROSOFT STORE',
  'ATM WITHDRAWAL BROA',
  'IBI SAVAGEXFENTY',
  'BUDGET',
  'BURLINGTON STOR',
  'CENTRAL',
  'GOGOAIR',
  'SALVATION ARMY',
  'USA VEND AT AIR S',
  'CTOWN SUPERMARKETS',
  'CANDLEWOOD SUITES',
  'PARCHMENT',
  'ROKU FOR BET',
  'WITHDRAWAL HOME BANKING TRANSFER TO SHARE INTERNET ACCESS',
  'TRUEBILL TRUEBILL P',
  'VALLARTA',
  'POS DEBIT VISA CHECK CARD ALBERT GENIUS',
  'NSF RETURN ITEM FEE FOR A TRANSACTION RECEIVED ON PLANET FIT CLUB FEES',
  'BUC EES',
  'NON BALANCE INQUIRY FEE',
  'FIVE STAR',
  'THE MENS WEARHOUSE',
  'LEDO PIZZA',
  'SOUTHWEST AIRLINES',
  'ATM WITHDRAWAL PLUS CHASE',
  'DIGITCO SUBSCRIPTN PPD ID',
  'RAISING CANE S',
  'DIPPIN DOTS',
  'SPROUTS FARMER',
  'DIRECT DEBIT DIGITCO SUBSCRIPTN',
  'URBAN AIR',
  'EARNINACTIVEHOURS VISA DIRECT',
  'MANSCAPED',
  'TMOBILE POSTPAID F',
  'ACH TRANSACTION BRIGIT PROTECTION',
  'INTERNATIONAL TRAN FEE',
  'OVERDRAFTRETURN ITEMS FEE',
  'QUICKPAY WITH ZELLE PAYMENT TO MOM JPM',
  'NORDSTROM DIRECT',
  'PERKINS REST',
  'CREDIT KARMA TRANSFER WEB ID',
  'ANYTIME FITNESS',
  'SEVEN',
  'CENTSONLY',
  'CASH APPCASH CARD',
  'PAYLEASE',
  'ALABAMA POWER',
  'FINGERHUT',
  'RIOT',
  'CRAFTY CRAB',
  'DOORDASH PURCHASE',
  'KLARNA PAYMENTS NY',
  'SAMS CLUB PURCHASE',
  'MARTA TVM',
  'BUSHS CHICKEN',
  'JCREW',
  'TMOBILEPOSTPAID FDP WA',
  'BAL INQ',
  'AU BON PAIN',
  'TOBACCO',
  'BJS WHOLE',
  'DAISO',
  'TRANSFER TO WELLS FARGO',
  'SIEPLAYSTATIONNETWOR CA',
  'LITTLE',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS BRIGITCOM MEMBERSHIP PPD ID',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS EARNINACTIVEHOUR PAYMENTRET WEB ID',
  'POS DEBIT VISA CHECK CARD APPLE CASH',
  'QUICKQUACK',
  'CAPTAIN D S',
  'ACTBLUE',
  'TEESPRING',
  'APL ITUNESCOMBI',
  'CULVER S',
  'YOUTUBE MU',
  'HLUHULU U HULUCOMBILL CA',
  'WINNDIXI',
  'ACCEPT INSUR',
  'PETSENSE',
  'IPSY MARKETPLACE',
  'WW INTLDIGITAL',
  'ALLSUP',
  'HEB ONLINE',
  'VIABILL',
  'AUTO AIR VACUUM SE',
  'TX DPS DL OFFICE',
  'BONEFISH GRILL',
  'BARROS PIZZA',
  'DASHPA',
  'LOS ANGELES CA DAV',
  'EXPRESSVPN',
  'EAGLES',
  'PENN FOSTER INC',
  'WU PURCHASE',
  'POPEYE S',
  'ROSATIS PIZZA',
  'TCBY',
  'SAFELITE AUTOGLASS',
  'DUTCHBROSCO',
  'PLAYSTATION NETWO PURCHASE',
  'OF LONDON',
  'WING ZONE',
  'POS DEBIT VISA CHECK CARD',
  'YOUTUBEPREMIU',
  'ONLINE XFER TRANSFER TO CK X',
  'CKO PATREON MEMBE',
  'ORBITZ',
  'PIEOLOGY',
  'REMITLY',
  'ROKU FOR HBO',
  'ALLPOINT ATM',
  'SCGOV',
  'BILL PAY VE',
  'GENESISFS',
  'SAMSCLUB PURCHASE',
  'STEAK N S',
  'SHOPDISNEY',
  'FACTORY CONNECTION',
  'P',
  'AUTO AIR VACUUM SVC',
  'MARINER FINANCE',
  'LIQUOR MART',
  'RENT A CENTER ECOMMERCE',
  'NNT HM PURCHASE',
  'LOGANS ROADHOUSE',
  'NSF CHARGE',
  'CHURCHS',
  'PRETZELMAKER',
  'BPMAPCO',
  'FBFUNDRAISER',
  'COMFORT INNS',
  'MTA NYCT PAYGO',
  'PETVALU',
  'WM SUPERCENTER',
  'DONUT PALACE',
  'DEBIT DAVE INC INTERNET PAYMENT',
  'TOTAL CARD INC',
  'CDKEYS',
  'VITAMINSHOPPE',
  'OHIO TURNPIKE',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS DISNEYPLUS CA',
  'BUCKYS STORE',
  'DEBIT PURCHASE',
  'MBIPROBILLERCOM',
  'TRACTOR S',
  'FILIBERTOS MEXICAN FOOD',
  'ATM WITHDRAWAL PLUS USAACATM',
  'CHEBA HUT',
  'TIRES PLUS',
  'POS DEBIT VISA CHECK CARD AT TBILL PAYMENT',
  'IPSY GLAM BAG PLUS',
  'GREAT LAKES',
  'QUICK TRACK',
  'TGI FRIDAYS PURCHASE',
  'KWIK STAR',
  'ETSYCOM',
  'CHILIS GRIL',
  'JAMES AVERY',
  'CASH APPBRITTANY',
  'GOODWILL STORE',
  'DEBIT CARD PURCHASE P DAVECOM',
  'MICROSOFTSTORE MSBILLINFO',
  'A TOWN WINGS',
  'DAYLIGHT DONUTS',
  'DICKS CLOTHINGSPORTI',
  'PIZZA BOLIS',
  'POS DEBIT VISA CHECK CARD PILOT',
  'COST CUTTERS',
  'MILLERS ALE HOUSE',
  'PNC EXPRESS FUNDS FEE',
  'FANDANGONOW',
  'CALI PIZZA KITC INC',
  'RAINBOWSHOPS',
  'TRAVELODGE',
  'BAR LOUIE',
  'AGI RENTERS CONDO',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS EARNIN ACTIVE PAYMENTRET WEB ID',
  'IMAGE',
  'STAPLES PURCHASE',
  'BIG O TIRES',
  'SAMS GAS',
  'LIQUORS',
  'BASKIN',
  'UA INFLT',
  'SURF STYLE',
  'DISTROKID',
  'CELLAIRIS',
  'ASURIONWIRELESS INSURA',
  'FIG LOANS LLC',
  'ACH ELECTRONIC DEBIT DAVE INC DEBIT',
  'BONCHON',
  'RIDE COST',
  'BILL PAYMENT WWWATTCOM',
  'NORTON',
  'PORTILLOS HOT DOGS',
  'BJS FUEL',
  'UDEMY',
  'PLAYSTATION NETWORK PURCHASE',
  'GAME STOP',
  'THE CHEES',
  'SQUARE CASH PMNT SENT',
  'NONCHASE ATM WITHDRAW SOUTH',
  'NEKTER JUICE BAR',
  'POS DEBIT VISA CHECK CARD LITTLE CAESARS',
  'LIBERTY',
  'MAESTRO CIRRUS SETTLE',
  'AUNTIE ANNES FL',
  'CARPARTSCOM',
  'METRO DINER',
  'MOONPAY',
  'INSOMNIA',
  'BITE SQUAD',
  'ROKU FOR ESPN',
  'CHIPOT',
  'COLUMBIA',
  'OF',
  'BEAUTY EXCHANGE',
  'ACH TRANSACTION BRIGIT MEMBERSHIP',
  'VIACOMCBS STREAMING',
  'ACH TRANSACTION ALBERT INSTANT EDI PYMNTS',
  'DIRECT DEBIT FPB CR CARD INTERNET WEB',
  'YESWAY',
  'KAISER',
  'COUNTRY',
  'USAACATM',
  'DICKS',
  'DELIVERY',
  'APPLEBEES G',
  'JUICE IT UP',
  'TRANSFER TO CHECKING P ONLINE REFERENCE',
  'PROGRESSIVE INSURANC',
  'CUSTOMER WITHDRAWAL',
  'WINGS',
  'OVERDRAFT ITEM FEE FOR ACTIVITY OF ELECTRONIC TRANSA POSTING DATE POSTING SEQ',
  'CASH APPMICHAEL',
  'HABIT',
  'PROG COUNTY MUT',
  'SOUNDCLOUD INC',
  'BROOKSHIR',
  'SALATA',
  'DOLLAR GEN',
  'AVANT LLC',
  'CA DMV FEE CA',
  'SECURUS INMATE',
  'WM DIRECT LLC',
  'SUPER STOP',
  'CHILI S',
  'IDAPPLE',
  'THE FLAME BROILER',
  'STATE OF CALIF DMV',
  'WIXCOM',
  'MINUTECLINIC',
  'QUADNY',
  'ULTIMATE MSBILLINFO WA',
  'TIFFS TREATS',
  'HARDEES PURCHASE',
  'CHECK OR SUPPLY ORDER PPD ID',
  'RECURRING PAYMENT MICROSOFTXBOX LIV MSBILLINFO',
  'SALLY BEA',
  'SPEEDPAY',
  'TRANSFER TO SAVINGS',
  'PAYMENT TX',
  'COURSEHERO',
  'SP FASHIONNOVAC FNOVAMYSHOPI',
  'MARIANOS',
  'CORNER MARKET',
  'FOOD FAST',
  'PORTFOLIO RECOV',
  'BAL INQ FEE',
  'EXPEDITED MAIL FEE',
  'DISCOUNT TOBACCO',
  'VENTRA',
  'THE PAMPERED CHEF',
  'POS DEBIT VISA CHECK CARD MICROSOFTXBOX MSBILLINFO',
  'BEAUTY DEPOT',
  'PURCHASE APPLECOMBILL CA',
  'CUSTOMER WITHDRAWAL IMAGE',
  'FORTNITE',
  'MICROSOFT XBOX MSBILLINFO WA',
  'SPRINT RETAIL',
  'WINCO',
  'CROSSROADS',
  'WIX',
  'RETURNED ITEM FEE NSF',
  'AMERICANFIRSTFIN',
  'PRA GROUP INC',
  'BATH BODY WO PURCHASE',
  'SMARTPAYCHG',
  'NORDSTROM RACK',
  'FREDDYS FROZE',
  'LEGO',
  'AVENUE C SECURE PAY',
  'INSTA CASH REPAYMENT',
  'NONTD ATM FEE NONTD ATM FEE',
  'FUEL DEPOT',
  'ADJUSTMENTCORRECTION OF POSTED ITEM',
  'ONLINE BANKING TRANSFER TO ACCT',
  'SUBWAY MOBILE PURCHASE',
  'GROCERY',
  'U HAUL MOVING STORAGE',
  'APPLE PAY',
  'COACH',
  'GOOGLE STOR',
  'DEBIT POS AUT DDA PURCH WCB',
  'PLANET',
  'WITHDRAWAL XXXXX',
  'IPSY GLAM BAG HELPIPSYCOM CA',
  'POS DEBIT VISA CHECK CARD ROSS STORES',
  'COLDSTONE',
  'ONCUE',
  'JOANN FABRIC',
  'KENNEDY FRIED CHICKEN',
  'TRANSFER TO CHECKING A ONLINE REFERENCE',
  'ACE HDWE',
  'PDQ',
  'ACH TRANSACTION VOLA VOLA',
  'CAVENDERS',
  'MACYS COM',
  'EXPRESS OIL',
  'CREDIT ACCEPTANCE CORPO',
  'LANES',
  'LUBYS CAFE',
  'QUIZLET',
  'DOORDASH WWWDOORDASH CA',
  'LA PARRILLA',
  'PROG PREMIER',
  'POS DEBIT VISA CHECK CARD KLOVER APP BOOST',
  'CHUMBA GOLD',
  'INFOTRACER',
  'CHILIS G',
  'DUKEENERGY',
  'JOSTENS INC',
  'ULTACOM',
  'MICROSOFT ULTIMATE MSBILLINFO WA',
  'KFC G PURCHASE',
  'BPMACS',
  'SPIRE',
  'SALADWORKS',
  'OVERDRAFT RET',
  'SILVER',
  'MISSION BBQ',
  'SONIC DRIVE',
  'OPPFINANC PAYMENT PPD ID',
  'POINT OF SALE DEBIT L DAVE INC VISA DIRECTCA',
  'BUFFALO WIL',
  'LOAF N JU',
  'SPENCER GIFTS PURCHASE',
  'VZWRLSS BILL PAY VE',
  'MURPHY EXPRESS PURCHASE',
  'PIER IMPORTS',
  'FLASH MARKET',
  'GOODRX GOLD',
  'POPEYE',
  'TRANSFER TO BANK OF AMERICA',
  'FLOAT ME',
  'WISHCOM PURCHASE',
  'KANGAROO EXPRESS',
  'DEL TACO PURCHASE',
  'CALLTRUTH',
  'MICROSOFT XBOX MSBILLINFO',
  'TARGETCOM',
  'DICKS SPORTIN',
  'ROUSES',
  'PANDA',
  'DRIZLY INC',
  'VBSSPOTLOAN',
  'FRED M FU',
  'USA FIVE STAR FOOD',
  'AM EAGLE',
  'KING BUFFET',
  'EXPEDIA EXPEDIACOM',
  'FAIRFIELD INN SUITES',
  'URBANOUTFITTERS',
  'INTEREST CHARGED ON BANK CASH ADVANCES',
  'GOLDEN CORRAL PURCHASE',
  'FIREHOUSE',
  'QUEST DIAGNOSTICS',
  'CASH APPCHRISTINA',
  'NOTHING BUNDT',
  'LITTLE CAES',
  'FANDUELBETFAIR',
  'PMNT SENT APPLE CASH ADDE CA',
  'PAYMENT PURCHASE',
  'BLD RESUME',
  'TRANSFER TO CASH APP',
  'DOORDASHCHILIS GRILL',
  'ONLY FANS UK',
  'MICROSOFT EA PLAY',
  'PAYMENTUSSERVICE',
  'EXPRESS LANE',
  'VILLAGE INN',
  'LOVELY',
  'JPAY MONEY TRANSFE',
  'KILWINS',
  'FLWRSBAKRY',
  'FROMYOUFLOWERS',
  'ZG ZILLOWRENTALS',
  'CARLS JR PURCHASE',
  'JAMBA',
  'SLIM CHICKENS',
  'PHANTOM EFX',
  'NAPA AUTO PARTS',
  'ACME',
  'GARAGE',
  'JERSEY MI',
  'ATM WITHDRAWAL PLUS TNSSMART',
  'TOBACCO PLUS',
  'PLAYSTATIONNETWOR PURCHASE',
  'FLASH FOOD',
  'PREMIER PARK',
  'GREAT WRAPS',
  'ROEBUCK',
  'UNITED EXPRESS',
  'POS DEBIT VISA CHECK CARD DENNYS',
  'WEIGELS',
  'PAYMENTUS SERVICE FEE',
  'CARDENAS MARKET',
  'BEVERAGES MORE',
  'ZAZZLE INC',
  'TARGET CARD',
  'DIRECT DEBIT PLANET FIT CLUB FEES',
  'FPB CR CARD INTERNET',
  'HLUHULU HULUCOMBILL',
  'ARCOARCO',
  'NINTENDO AMERICAUS',
  'DEPT EDUCATION',
  'OPENSKY',
  'DEBIT FOR ALBERT SAVINGS D EDI PYMNTS CO REF',
  'HRB ONLINE TAX PRODUCT',
  'MRS FIELDS',
  'CASH APPJASMINE',
  'ATT PREPAID',
  'AAA INSURANCE',
  'CASH APPCHRISTIAN',
  'SUPER FOODS',
  'RADISSON',
  'CRUIZERS',
  'GIANT FUEL',
  'USA SODA SNACK VENDING',
  'UPPYS',
  'BEALLS',
  'CASHNET USA',
  'DEBIT FOR CHECKCARD XXXXXX SUBWAY',
  'KLARNA PAYMENTS OH',
  'AUTO AIR',
  'INTERNET WITHDRAWAL',
  'HBO NOW',
  'DOORDASHCHIPOTLE STRIPECOM',
  'SUSHI',
  'PLAYTIKA LTD',
  'POS DEBIT VISA CHECK CARD VOLA',
  'PLAYSTATIO',
  'EARNINACTIVEHOUR',
  'CONTACTS SUBSCRIPTION',
  'OVERDRAFT TRANSFER FEE',
  'ACH TRANSACTION BRANCH MESSENGER BRANCH MES',
  'RAZZOOS CAJUN CAFE',
  'COFFEE BEAN STORE',
  'BURGERIM',
  'HOWARD JOHNSON',
  'BLDRESUME',
  'GATOS',
  'MOBILE TRANSACTION FEE',
  'LA QUINTA INN',
  'ATT PAYMENT',
  'RECURRING PAYMENT KLARNA PAYMENTS',
  'BLIZZARD ENTERTAIN',
  'PETLAND',
  'FRYSMKTPLACE',
  'SMITHS FO',
  'STOCKXX',
  'RED ROBIN GOU',
  'TOBACCO OUTLET',
  'PAY GCOWALLETH',
  'CHURCHS CHICK',
  'EPOCH',
  'LA CITY PARKING METER',
  'ATM WITHDRAWAL MAIN',
  'SUBSCRIPTION',
  'HONDA',
  'HERTZ RENTACAR',
  'PROPERTY PAYMENT R',
  'STAR MART',
  'STEAMGAMESCOM',
  'POS DEBIT VISA CHECK CARD EBT ACCT CHECK',
  'THE FRESH MARKET',
  'US CELLULAR',
  'WESTIN HOTEL',
  'DICKSSPORTINGGOODSCOM SSPORTINGGOODS',
  'DUTCHBROSLL',
  'ROKU FOR PHILO',
  'SMART',
  'S COUNTRY',
  'LITTLE TOKYO',
  'STEAKNSHAKE Q',
  'OFFICEMAXDEPOT',
  'TRANSFER',
  'RETURNED DEPOSIT ITEM',
  'TMOBILEPOSTPAID F',
  'JOE VS',
  'EPIC GAMES',
  'FOODLESS',
  'FAMILY VIDEO',
  'RUSH REPLACEMENT ATM OR DEBIT CARD FEE',
  'GIFT SHOP',
  'ML PLUS LOAN MONEYLION',
  'KLOVER APP BOOS',
  'DOMAINS',
  'PIZZA PATRON',
  'BEAUTY EMPIRE',
  'SQUARE ENIX',
  'CAPITAL ONE AUTO CARPAY WEB ID',
  'PRETZEL MAKER',
  'BIG RED',
  'KWIK MART',
  'DISNEY MOBI',
  'NIKECOM OR',
  'CROWNE PLAZA',
  'ONLYF',
  'EYEBUYDIRECT',
  'SHAWS',
  'GIGANTIC',
  'ULTASALONCO',
  'LOWE S',
  'MOOYAH',
  'DEBIT CARD PURCHASE XXXXX CASH APP XXXXX',
  'BEAUTY MART',
  'POINT OF SALE DEBIT L DATE APPLE COMBILL',
  'NONUSBANK ATM DENIED FEE',
  'GAP OUTLET',
  'ADJUSTMENT TO EF ATM DEPOSIT',
  'RANCH',
  'ROKU FOR CBS INTERACTI',
  'TAKE',
  'BROADWAY',
  'PROVISIONAL CREDIT REVERSAL',
  'T BILL',
  'ACADEMY SPORTS PURCHASE',
  'SAV ADJUSTMENT DR',
  'CLKBANK',
  'ONLINE XFER TRANSFER TO SV X',
  'VERIZON ONETIMEPAY',
  'MCDONALD S',
  'KLARNA PAYMENTS PURCHASE',
  'CHECK DEPOSIT RETURN',
  'USAVEND AT AIR SE',
  'ROBINHOOD DEBITS',
  'TRANSFER TO VARO',
  'BODYCANDY',
  'TMOBILEPOSTPAID PDA PURCHASE',
  'CRAZY',
  'CAPITAL ONE CARD PYMT D',
  'CONVENIENT FOOD MART',
  'FABKIDS',
  'ROSS STORE PURCHASE',
  'NEW YORK',
  'STARZ ENTERTAINMEN',
  'CHUMBA',
  'TOTAL WIRELESS',
  'FOSSIL',
  'TRANSFER TO MONEY MARKET A ONLINE REFERENCE',
  'REGIONAL ATM INQUIRY FEE',
  'ZHANG LIANG',
  'KLOVER',
  'INTERNET TRF TO DDA',
  'DRIZLY',
  'BEENVERIFIE',
  'YOUTUBE VIDE',
  'VERIZON AGENT',
  'ESURANCE',
  'TOUCHTUNESHELPSHIFTC',
  'GIANT FOOD INC',
  'POSTMATES MEMBERSH',
  'CAVIAR',
  'BASKIN Q',
  'TRANSFER FEE',
  'KICKBACKJACKS',
  'ARBYS PURCHASE',
  'SUGAR FACTORY',
  'DIRECT AUTO',
  'HTTPSPOSTM',
  'SEDC PAYMENT',
  'AUNTIE ANNES PRETZELS',
  'TXDPS DRIVER LICENSE',
  'LOVES TRAVEL STOPS',
  'FID BKG SVC LLC',
  'SUPERIOR SUPER WHSE',
  'POLLO REGIO',
  'MOES SOUTHWES',
  'RING YEARLY PLAN',
  'IPVANISH',
  'VERIFYBANK EARNINACTIV',
  'ETRADE',
  'MICROSOFTXBOX REDMOND WA',
  'MSI RENTERS',
  'TRUTHFINDER',
  'MISCELLANEOUS DEBIT',
  'RACEWAY PURCHASE',
  'ATOM TICKETS LLC',
  'LENDING',
  'CASH APPELIZABETH',
  'ITSUGAR',
  'CHIPOTLE PURCHASE',
  'YOUTUBE MEMBER',
  'LIDS PURCHASE',
  'QT OUTSIDE PURCHASE',
  'TO SHARE',
  'BVDBEEN VERIFIED TRIAL',
  'ML PLUS LOAN MONEYLION PPD ID',
  'STARS AND STRIKES',
  'YOURSCOREANDMORECOM',
  'MENS WEARHOUSE',
  'OUT OF NETWORK',
  'ADVANCE',
  'MMBILL',
  'CASH APPDOMINIQUE',
  'CHEWY INC',
  'IL TOLLWAYWEB',
  'HOTWIRESALES FINAL',
  'ATM WITHDRAWAL LK',
  'TOTAL WINE MO',
  'YOU TUBE TV',
  'HM PURCHASE HM',
  'OHIO BILLMATRIX',
  'VA ABC',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS QUADPAY QUADPAY ANY HTTPSWWWQUAD NY',
  'DEBIT CARD PURCHASE AT CASH APP CA ON FROM CARD XXXXXXXXXXXX',
  'SNACKCRATE',
  'CASH APPJAY',
  'POS DEBIT INSTACASH REPAYMENT',
  'ROARMONEY TRANSFER',
  'GREYHOUND LINES CN',
  'URBANMOVIECHANNEL',
  'CIRCLE',
  'FAMILY FARE',
  'CHEAPOAIR',
  'DISPUTE TEMP CREDIT REV',
  'MYFICO',
  'GRAMMARLY',
  'EVERGREEN SERVIC',
  'STPSHPFUEL',
  'VICTORIAS SECR',
  'USASNACK SODA VENDING PURCHASE',
  'BIG Y',
  'SQUARE INC SDV VRFY T',
  'SQUARE CASH CA',
  'HIGHS',
  'FUNIMATION PRODUCTIONS',
  'SQUARE INC CASH APP',
  'CH ACCOUNTING DEBIT ADJUSTMENT FDES NNF',
  'FAST TRACK',
  'EXPRESS OIL CHANGE',
  'LUMINSKIN',
  'MICROSOFTXBOX PURCHASE',
  'ZAFUL',
  'QT INSIDE PURCHASE',
  'DR SQUATCH',
  'PRIME VIDEOM',
  'USAFIVE STAR FOOD',
  'TACO SHOP',
  'YOSHINOYA',
  'CHILIS ECOMM OLOCOM',
  'RUBY THAI KITCHEN',
  'DEPOSITED OR CASHED CHECK',
  'GRUBHUBDENNYS',
  'DAVECOM VISA DIRECT',
  'APARTMENTS',
  'PRO NAILS',
  'MTAMETROCARD MACHINE',
  'CREDIT KARMA TRANSFER',
  'SPRINKLES',
  'WAVE',
  'PEETS',
  'COLD STONE',
  'T J APPLEBE',
  'AIRPORT PARKING',
  'LIM RIDE',
  'SNACK SODA',
  'BETMGM',
  'SELF LENDER INC PAYMENTS PPD ID',
  'TRANSFER TO OVERDRAWN RELATED ACCOUNT XXXXXXXX FDES NMA',
  'POPEYES PURCHASE',
  'NONCHASE ATM WITHDRAW BROA',
  'COX PHOENIX COMM SERV',
  'CERTIFIED OIL',
  'SAVE MART',
  'WILLIAMS CHICKEN',
  'OVERDRAFT TRNSFR FEE WITH IMAGE',
  'NATIONAL GRID',
  'BUFFALO',
  'LIM RIDE COST',
  'MOBILE DEPOSIT ADJUSTMENT',
  'NETFLIXCO',
  'SHOE SENSATION',
  'PDFFILLER',
  'FASMART',
  'VENTRA MOBILE',
  'HOOK REEL',
  'COACHOUTLET',
  'OR LIQUOR STORE',
  'DUNKIN Q PURCHASE',
  'MACY S',
  'JUST WING',
  'CHK',
  'MEGABUS',
  'DAVECOM PMNT SENT VISA DIRECT',
  'MTAMETROCARD MACHINE NEW YORK NY',
  'DRIVETIME',
  'ZOOMUS',
  'GRUBHUBFIVEGUYS',
  'DOMINOAPOSS',
  'CK DEALER',
  'FRYS FOOD',
  'VICTORIASSECRETCOM',
  'FOODSCO',
  'ROSES EXPRESS STORE',
  'FOX FUELS',
  'COMENITY PAY OH WEB PYMT P WEB ID',
  'LEVEL',
  'DOORDASHBUFFALO WILD',
  'FOOD MAXX',
  'ZEDGE',
  'VERIZONWRLSS RTCCR',
  'ATM TRANSACTION FEE',
  'FLOATME PURCHASE',
  'CLERKIE',
  'MASTER WOK',
  'WITHDRAWAL TRANSFER TO LOAN',
  'WEB INITIATED PAYMENT AT ALBERT SAVINGS D EDI PYMNTS XXXXXXXXXXX',
  'GAP',
  'SOFI SECURITIES',
  'APPLECOMUS',
  'PINGER INC',
  'HEB PURCHASE',
  'TEXTNOW',
  'CRTLEXINGTON LAW',
  'PROTECTED GOAL ACCOUNT TRANSFER',
  'GOD IS DOPE',
  'FRYS FUEL',
  'CHINA KITCHEN',
  'SALAD AND GO',
  'SLINGCOM CO',
  'QUADPAY FASHION N HTTPSWWWQUAD',
  'AQUARIUM',
  'ND AND CHARLES',
  'KWIK CHEK',
  'ALBERT INSTANT EDI PYMNTS INSTANT WEB ID',
  'CRYPTO HUB',
  'PATREON MEMBER',
  'CHECKPEOPLE',
  'TACOS',
  'SAMSCLUBCOM',
  'IMMEDIATE FUNDS PROCESSING FEE',
  'CAPITAL ONE CRCARDPMT',
  'TMOBILE POSTPAID TEL',
  'Q',
  'FASHIONNOV',
  'EARNINACTIVEHO',
  'ATM WITHDRAWAL BROAD',
  'GA DRIVER SVCS ONLINE',
  'BPFIVE',
  'NORDSTROMRACK',
  'ORDER',
  'FFNHELP',
  'NEWEGG INC',
  'CONTACTS INC',
  'SIMPLEACIMA',
  'GOOGOO',
  'AUNTIE ANNES TX',
  'OTTERBOXLIFEPROOF',
  'ELECTRONICACH DEBIT DAVE INC DEBIT',
  'TRANSFER TO CERTIFICATE',
  'SIMPLY',
  'ANDYS FROZEN CUSTARD',
  'GUYS QSR PURCHASE',
  'UNIQLO',
  'DOORDASHCHIPOTLE STRIPECOM CA',
  'UBR',
  'TRANSFER TO MONEY MARKET P ONLINE REFERENCE',
  'BPHAND',
  'WAYFIELD FOODS',
  'QUALITY',
  'LIQUOR DEPOT',
  'CVSPHARM PURCHASE',
  'MICROSOFTXBOX LIVE GOL',
  'PANERA BREA',
  'BATH AND BODY WORK',
  'UNDERGROUND BY',
  'POS DEBIT VISA CHECK CARD QT',
  'UNITED DAIRY FAR',
  'ACH DEBIT BRIGIT COM MEMBERSHIP',
  'PARKINGMETER CHICAGO IL',
  'INTL TXN FEE',
  'BILL PAY VN',
  'FRIENDLYS',
  'DISCOVERYPLUS',
  'BJS WHOLESALE',
  'NAYAX AMUSEMENTS',
  'ATM CASH ADVANCE FEE',
  'CRICKET',
  'BUZZOID',
  'WINCO FOO',
  'RESIDENCE INN',
  'DIRECTV',
  'MINIMUM INTEREST CHARGE',
  'OFFICE MAXOFFI',
  'MEMBERSHIP',
  'POS DEBIT VISA CHECK CARD DAVECOM VISA DIRECT',
  'ATM USAGE FEE',
  'AVENUE C SECURE',
  'ROSES EXPRESS',
  'POS DEBIT VISA CHECK CARD TARGET',
  'BOSA DONUTS',
  'CHICK FIL A',
  'WORLD OIL',
  'CASH APPJENNIFER',
  'IBISHOEDAZZLE',
  'BANKCARD',
  'MECKLENBURG ABC',
  'CFKPEOPLEFINDERS',
  'TXDPS DRIVER LICENSE EGOVCOM TX',
  'WALNUT',
  'AMBIT TEXAS LLC',
  'FEE OVERDRAFT AT',
  'CHECK INTO CASH',
  'USPSCOM MOVERS GUIDE',
  'TAXI SVC',
  'LIFETOUCH NSS ONLINE',
  'DONUTS',
  'HUDSONDUNKIN',
  'INFINITY',
  'BUDDYS',
  'VETERANS CANTEEN',
  'CLAIRES PURCHASE',
  'RACK ROOM RACK',
  'OVERDRAFT ITEM CHARGE',
  'ICE CREAM',
  'TMOBILE POSTPAID',
  'STRAIGHTTALK SERVI',
  'CELLPAY',
  'POSSIBLE FINANCE DEBIT',
  'ACCOUNT TO SAVINGS',
  'MY BUSINESS',
  'ANTHROPOLOGIE',
  'SHELL OIL',
  'US HWY',
  'ACH TRANSACTION KLOVER APP BOOST',
  'MICROSOFT EA ACCESS',
  'BEAUTY MAX',
  'CHIPOTLE ONLINE CO',
  'MAKWA FINANCE',
  'DOORDASHCHIPOTLE',
  'ZAGG INC',
  'CORNER STOP',
  'SKILLZ INC',
  'MURPHYATWA PURCHASE MURPHYATWAL',
  'SMITHSFUEL',
  'HLUHULU U PURCHASE HULUCOMBILL',
  'HH',
  'BR FACTORY US',
  'MICROSOFTULTIMATE',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS EMPOWER INC PPD ID',
  'FUNDRAISER',
  'SELF LENDER INC ADMIN FEE PPD ID',
  'AKIRA',
  'DICKS CLOTHIN',
  'ASSURANCE WIRELESS',
  'BROOKSHIRE BROS',
  'MICROSOFTSTORE MSBILLINFO WA',
  'CASH APPJESSICA',
  'KNIGHTS INN',
  'BURGERVILLE',
  'CHRYSLER CAPITAL',
  'ONSTAR SERVICES',
  'ADT SECURITY',
  'COMPARE FOODS',
  'EXCESS',
  'STRAIGHTTALKAIRTI',
  'FACEBOOKPA',
  'AUTOBELL CAR WASH',
  'USPSCOM MOVERS GUID TN',
  'OF AMERICA',
  'DAVE INCDEBIT',
  'SEAWORLDMRCHDSE',
  'FREDS',
  'QUICK SHOP',
  'OUTBACK PURCHASE',
  'CARPARTS',
  'USAMINUTE KEY INC',
  'ETHIKA INC',
  'GLU MOBILE',
  'SHANES RIB SHACK',
  'ONLINE BANKING TRANSFER MOBILE APP TRANSFER TO',
  'DOORDASHCHIPOTLE WWWDOORDASH CA',
  'QUADPAY QUADPAY A HTTPSWWWQUAD',
  'MURPHYATWALMA PURCHASE',
  'SQUARESPACE INC',
  'WU SANTANDER CNSMR',
  'CASH APPANTHONY',
  'RACK ROOM SHOE',
  'BREAK TIME',
  'PARTS GEEK LLC',
  'PAVILIONS',
  'TNSSMART',
  'USA',
  'BELKCOM',
  'KUNG FU TEA',
  'CENTROHELP',
  'FRIENDLY ICE CREAM',
  'SYNCB PAYMENT',
  'COUNTRY STORE',
  'COLUMBIA GAS',
  'FEDERAL TAX',
  'HONEYBAKED HAM',
  'LENDING LLC',
  'PIZZA KING',
  'EMPOWER INC EMPSUBSCR',
  'COSMOPROF',
  'UBER PURCHASE',
  'DIRECT DEBIT DIGITCO SAVINGS',
  'ACE CASH',
  'RALEYS',
  'KRYSTAL JAX',
  'ROVER',
  'EARNINACTIVEHOUR VERIFYBANK WEB ID',
  'BIRCHBOX',
  'WAL MART WAL MART STO',
  'BURGERFI',
  'STATE',
  'POS DEBIT VISA CHECK CARD BWIBOINGO WIRELES',
  'POS DEBIT VISA CHECK CARD APPLE CASH INFINITELOOPCA',
  'NEX FUEL',
  'OFFICE MAX OFFI',
  'GOOGLE STORAG',
  'SUMMIT',
  'NONCHASE ATM WITHDRAW LAS',
  'SNACK SODA VE',
  'SPIRIT AIRLINES ONBOARD',
  'DAVIDS BRIDAL',
  'GRUBHUBPANDAEXPRESS',
  'DIGITCO INSTANTFEE PPD ID',
  'USCONNECT ACCNT VEND',
  'BURGER K',
  'CHECKCARD EBT ACCT CHEC',
  'ACH TRANSACTION BRIGIT DEBIT',
  'PLAYSTUDIOS',
  'OMAZE',
  'ADVFINANCIAL',
  'MARKET H',
  'PET SUPPLIES P',
  'POS DEBIT VISA CHECK CARD LIDS',
  'SPIRIT',
  'TRACTORSUPPLYC',
  'POTBELLY',
  'SWEET',
  'ADVANCEAUTOPAR',
  'QMART',
  'BLIMPIE',
  'COASTAL',
  'TIRE KINGDOM',
  'PAPPADEAUX SEAFOOD',
  'SIMPLE ACIMA',
  'FRYS ELECTRONICS',
  'UNITED PACIFI',
  'THE NORTH FACE',
  'POS DEBIT VISA CHECK CARD SPRINT WIRELESS',
  'MAGGIANOS',
  'SUNPASSACC',
  'TMOBILEPOSTPAID FDP',
  'LIONS DEN',
  'PLAT PARKING',
  'HARVEST PLATFORM',
  'LIFE STORAGE',
  'LONE STAR',
  'TOTAL WINE AND PURCHASE',
  'MAXLEND',
  'SPEEDPAYDUKE ENERGY',
  'ONLINE',
  'POS DEBIT VISA CHECK CARD WAFFLE HOUSE',
  'DIRECT DEBIT SELF LENDER INC PAYMENTS',
  'MANCHU WOK',
  'BANFIELDPET',
  'CALIFORNIA',
  'LENME',
  'SPOTIFYUSA',
  'USA SODA SNA',
  'HAROLDS CHICKEN',
  'TILLYS INTERNET',
  'ELEVENFCTI',
  'ATM WITHDRAWAL HIGH',
  'TRANSFER TO NAVCHEK',
  'VOLA PURCHASE',
  'ALE HOUSE',
  'BPAMOCO',
  'STAYBRIDGE SUITES',
  'GOLDS GYM',
  'MLBSHOP',
  'MARGARITAVILLE',
  'AVE C MKT',
  'ROMAN',
  'VICTORIASSE',
  'THE MELTING POT',
  'ONLYFANSCOM IL',
  'OVERTURE PROMOTIONS',
  'ZAPPOSCOM',
  'DOORDASHMCDONALD PURCHASE',
  'CHARLEYS PHILLY STEAK',
  'POS DEBIT VISA CHECK CARD RACETRAC',
  'TMOBILEPOSTPAID PURCHASE',
  'OH BUREAU MOTOR VEHICL',
  'RESUMENOW',
  'CORNER BAKERY CAFE',
  'BPWEST',
  'SCHEELS',
  'DIRT CHEAP',
  'MATCH',
  'WINGS N THINGS',
  'ALBERT GENIUS PURCHASE',
  'AVENUE C CANTEEN',
  'BILL PAYMENT WWWATTCOM TX',
  'DOORDASHCHIPOTLE WWWDOORDASH',
  'BARNESNOBLE PURCHASE',
  'SERVICES',
  'TOP NAILS',
  'DUCK DONUTS',
  'MOBILE',
  'BEDBATHB',
  'STOP AND SHOP',
  'VTSUP',
  'TELADOC',
  'JUSTICE WEB',
  'EARNIN VERIFYBANK',
  'FENTYBEAUTY',
  'UNITED TX',
  'SEAWORLDFOOD SVC',
  'TURKEY HI',
  'POS DEBIT VISA CHECK CARD SIEPLAYSTATIONNET',
  'NEW YORK STATE DMV NY',
  'LAZ PARKING PURCHASE',
  'METLIFE',
  'SPROUTS',
  'SECURUS INMATE CALLVID',
  'MUNICIPAL ONLINE',
  'ATM WITHDRAWAL PNC BANK',
  'SHUDDER',
  'EDI PYMNTS ALBERT SAVIN',
  'FLOATME TX',
  'POSTMATES MEMBERSH HTTPSPOSTMATE CA',
  'DEBIT PURCHASE VISA CARD CASH APP',
  'ETRANSFER DEBIT ONLINE XFER',
  'AMZN DIGITAL',
  'DOLLS KILL',
  'WITHRAWAL BY CHECK',
  'CHOPSTIX',
  'SUNBIT INC',
  'POS DEBIT VISA CHECK CARD ALDI',
  'NCOURT SERVICE FEE',
  'VANS PURCHASE',
  'PLANET FITNE',
  'LIL CRICKET',
  'VILLAGE MARKET',
  'FIRST',
  'CHEVRONHALLMARK PETROL',
  'CHAMPS PURCHASE',
  'US GAS',
  'HLU HULU',
  'QAPITAL',
  'WEB AUTHORIZED PMT CAPITAL ONE',
  'DEBIT ADJUSTMENT DUPLICATE ITEM REVERSING DEPOSIT MADE ON AS DUPLICATE IT EM REF R ISN FDES NTX',
  'ALBERT INSTANT AUT',
  'APPLE PAY SENT MONE CA',
  'MIDLANDCREDITMANAGEMNT',
  'HRBLOCK RETAIL',
  'OFFICE MA',
  'POS DEBIT VISA CHECK CARD HARDEES',
  'COLOURPOP',
  'SALSARITAS',
  'INTELIUS',
  'GRUBHUB HOLDINGS INC',
  'WHITE',
  'CARDENAS',
  'BILL PAY VW',
  'SPLICE',
  'BUCA DI BEPPO',
  'FASTR',
  'DOLLAR',
  'CASEYS GEN STO',
  'MICROSOFT ULTIM',
  'THRIFTBOOKS',
  'BPCK ST',
  'REPLACEMENT CARD FEE',
  'ACH TRANSACTION ML PLUS MEMBRSHP MONEYLION',
  'CITY MARKET',
  'SAM S CLUB',
  'VZWRLSS BILL PAY VN',
  'MCDONALDAPOSS',
  'ZAXBY S',
  'FYF FROMYOUFLOWERS',
  'ATLANTA AIRPORT',
  'FRANCHISE TAX BO',
  'SHOP RITE',
  'AARONS CNP',
  'TXUBILL PAYMENT',
  'METRO',
  'THE BODY SHOP',
  'ONLYFANSCOM PURCHASE',
  'CHARLEYS',
  'DOLLARGENERAL',
  'HOT HEAD BURRITOS',
  'VEND LLC',
  'ACH DEBIT BRIGIT COM PROTECTION',
  'EXPRESS CAR WASH',
  'STEVE MADDEN',
  'POS DEBIT VISA CHECK CARD FOREVER',
  'OFF BROADWAY SHOES',
  'BPRICKERS',
  'TWITCHINTER',
  'ITS JUST WI',
  'BRIGHTLENDING',
  'UBER TRIP CA',
  'PROGRESSIVELEASE PMTS PPD ID',
  'BEDBATHBEYOND',
  'GHIRARDELLI',
  'ZAXBYAPOSS',
  'RECURRING PURCHASE AT APPLECOMBILL CA ON FROM CARD XXXXXXXXXXXX',
  'LA QUINTA INN SUITES',
  'TOKYO EXPRESS',
  'TEMPOE',
  'LINE FINANCIAL',
  'ANNUAL FEE',
  'ATM WITHDRAWAL WEST',
  'ASIAN BUFFET',
  'COLLECT CALL',
  'DALLAS BBQ',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON AFTERPAY',
  'DOORDASHDENNYS',
  'TRANSF TO OD COLLECTIONS',
  'NETCREDIT',
  'ANOTHER BROKEN EGG',
  'PYMT SENT APPLE CASH',
  'MAC COSMETICS',
  'INSTACASH RE',
  'CASH APPCHRISTOPH',
  'WEIGELS Q',
  'AFTERPAY US',
  'STARBUCKS CARD',
  'INSTACART HTTPSINSTACAR CA',
  'SAMS MEMBERSHIP',
  'NOODLES CO',
  'EPC FORTNITE',
  'CA',
  'CREDITREPORT',
  'SUBSTITUTE CHECK',
  'BIGLOTS',
  'USA GASOLINE',
  'REVERSE',
  'AZ MVD WEB',
  'CASH WITHDRAWAL P TELLER',
  'SHOP N SAVE',
  'BURLNGTON STOR PURCHASE',
  'KANGAROO EXPRES',
  'FORTIVA MC',
  'LJS AW',
  'CFX VES WEBSITE',
  'BOJANGLES CHI',
  'ACCOUNT NEGATIVE BALANCE FEE',
  'CASH APPJAMES',
  'KFC AW',
  'CHECKCARD EMPOWER SAN FRANCISCOCA',
  'TA',
  'L L HAWAIIAN BBQ',
  'FUEL CITY',
  'WIRE TRANS SVC CHARGE SEQUENCE SRF TRN RFB',
  'CHUCK E CHEESE PURCHASE',
  'CAR WASH USA EXPRESS',
  'AMZN MKTP US AMZNCOMBILL WA',
  'DRAFT',
  'SLINGCOM',
  'NATURAL GROCERS',
  'MICROSOFT ULTI',
  'BRIDGECREST DT RETAIL',
  'PMNT SENT APPLE CASH CA',
  'GEORGIA AQUARIUM',
  'TXTAG',
  'ATM SURCHARGE',
  'BINNYS BEVERAGE DEPOT',
  'CREDIT ONE BAN',
  'EZPASS MA',
  'DAVI NAILS',
  'ONLINE TRANSFER DEBIT',
  'MYSCOREIQ',
  'MIAMI SUBS',
  'C STORE',
  'NIANTIC IN',
  'ATM WITHDRAWAL W T',
  'BURGER BAR',
  'FLEET FARM',
  'SOLITAIRE CASH',
  'AGENT ASSISTED TRANSFER TO SAV CONFIRMATION',
  'REDNERS MKTS',
  'CONVERSEUS',
  'CSC SERVICEWORKS KIOSK',
  'MONTHLY SERVICE CHARGE',
  'NANOBIT',
  'ACH TRANSACTION TRUEBILL TRUEBILL P',
  'WINGS ETC',
  'DAVE BUSTERS PWC',
  'THE HALAL GUYS',
  'BLUESKY',
  'CIRILLA S',
  'CASH APPCHRIS',
  'SEATGEEKTICKETS',
  'POSTMATES TEMP AUTH',
  'APPLE CASH SENT PMNT SENT',
  'WEB AUTHORIZED PMT PAYPAL',
  'WNB FACTORY',
  'GAP ONLINE',
  'BIG APPLE',
  'IT WORKS MARKETING INC',
  'WORLD',
  'MR TIRE',
  'KING TACO',
  'PPLUS',
  'MICROSOFTULTIMATE M MSBILLINFO WA',
  'EXETER AUTO REC',
  'SEASONS',
  'BURTON',
  'BJS RESTAURAN',
  'MICROSOFTSTORE PURCHASE MSBILLINFO',
  'LOAN AT LAST',
  'OFFICE',
  'LAZY DOG RESTAURANT',
  'TU SMARTMOVE',
  'MISSGUIDED',
  'MARTINS',
  'RED LOBST',
  'ESKIMO HUT',
  'ALLSUPS',
  'FOOD BAZA',
  'POS DEBIT VISA CHECK CARD TMOBILEPOSTPAID P',
  'CHEVRONCSI',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS ML PLUS LOAN MONEYLION PPD ID',
  'BPCLIPP',
  'NONCHASE ATM WITHDRAW NW',
  'MSBILLINF',
  'POP FIT',
  'DEBIT PURCHASE VISA CARD APPLECOMBILL',
  'PROPERTY PAYMENTRENT',
  'BAGEL',
  'RECURRING PAYMENT SIEPLAYSTATIONNET',
  'NORI JAPAN',
  'FACEBOOK FACEBO',
  'THE CHILDRENS PURCHASE',
  'USA SNACK SODA',
  'VF OUTLET',
  'SPEEDY CASH KS',
  'CHECKCARD',
  'MSBILLINFO',
  'FESTIVAL FOODS',
  'ESURANCE CAR INSUR',
  'HORIZON CARD SERVICES',
  'UP IN SMOKE',
  'GOOGLE YOUTUBE',
  'CRYSTAL',
  'CRAB DU JOUR',
  'DISCOUNT DRUG MART',
  'ATM WITHDRAWAL TH A',
  'ALBERT GENIUS EDI PYMNTS PAYMENT',
  'ATM MAC PAI ISO',
  'SPIRI',
  'EXCESSIVE WITHDRAWALITEM FEE',
  'WITHDRAWAL DDA DEBIT',
  'TXDPS DRIVER LICEN EGOVCOM',
  'LEVIS',
  'SURCHARGE FEEWELLS FARGO BAN',
  'PARK MOBILE',
  'PET SUPPLIES',
  'WWWKOHLSCOM OH',
  'BEAUTY OUTLET',
  'GOLDEN CHINA',
  'COLLAGECOM',
  'ASHLEY STEWART',
  'CENEX ZIP TRIP',
  'DOLLAR GE HIGHWAY',
  'SAMSUNG ELECTRONIC',
  'GOLDEN DRAGON',
  'DOLLAR RENT A CAR',
  'NSF RETURNED ITEM FEE FOR ACTIVITY OF ELECTRONIC TRANSA POSTING DATE POSTING SEQ',
  'RENTERSCONDO',
  'STOP PAY FEE',
  'WITHDRAWAL INTERNET TRANSFER TO CK',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS BRIGITCOM PROTECTION PPD ID',
  'CROWN FRIED CHICKEN',
  'ATT TV',
  'MACYS COM OH',
  'STADIA',
  'MONAT GLOBAL CORP',
  'USAA CREDIT CARD PAYMENT',
  'STOP SHOP PURCHASE',
  'VILLAGE',
  'CONVENIENCE',
  'FOREIGN ATM OR POS FEE ATM FEE',
  'PLAYSTATIONNETWORK CA',
  'EVRYPLT',
  'CINNABO',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON DISNEYPLUS',
  'SHOE CARN',
  'HOME CHEF',
  'DIRECT DEBIT SELF LENDER INC ADMIN FEE',
  'RECURRING PAYMENT MICROSOFT XBOX MSBILLINFO',
  'BAKERSBURGERS',
  'CLOUD',
  'MBIPROBILLERCOMXT',
  'JOURNEYS PURCHASE',
  'BRUSTERS',
  'DAIRY',
  'BUILDABEAR WORKSHOP',
  'NONCHASE ATM WITHDRAW US',
  'FIVEBELOW',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS ML PLUS MEMBRSHP MONEYLION PPD ID',
  'ACH TRANSACTION PAYPAL RETRY PYMT',
  'CREDITREVIEWCO',
  'CITI CARD ONLINE PAYMENT WEB ID CITICTP',
  'LEVEL BANK PLUS DUES',
  'CHARLEYS PHILLY S',
  'GULF PWI',
  'LIMRIDE COST',
  'AUTO AIR VACUUM SERVICE PLAINVIEW NY',
  'VENDING',
  'POS DEBIT VISA CHECK CARD DOORDASH STRIPECOM',
  'BUFFET',
  'TACOS EL GORDO',
  'VZWRLSSBILL PAY VE',
  'POS DEBIT VISA CHECK CARD ADVANCE AUTO PA',
  'CHEVRONSUNSHINE',
  'READYREF',
  'FREDDY S',
  'PROFLOWERS',
  'GW SERVICEFEE',
  'SYNCHRONY BANK CC PYMT',
  'TIFFANY CO',
  'MARATHON P',
  'INSTACASH REPAY',
  'PICK N SAVE',
  'JACK S',
  'HR BLOCK',
  'XFER DEBIT DAVE DAVE INC SOUTH CO',
  'GOOGLE CLOUD STORAGE',
  'SNAPTRAVEL',
  'SCRIBD INC',
  'SHIPLEY',
  'MULTIPLE SHOPS',
  'GOOGLE FI',
  'FIG LOANS',
  'DIGITCO RAINY DAY',
  'HUCKS FOOD FUEL STO',
  'FINISH LINE IN',
  'EXPEDIA EXPEDIACOM WA',
  'GENESIS FS CARD',
  'IM PRO MAKEUP NY L',
  'RAISINGCANES',
  'HY VEE',
  'SCHOLASTIC INC',
  'SPLENDIES',
  'CASH APP CA DEBIT CARD PURCHASE',
  'DIRECT DEBIT ALBERT INVESTMEN ACH',
  'PAPARAZZI LLC',
  'RENTLYCOM',
  'FBPAY',
  'ALON DK',
  'THREDUP',
  'POS DEBIT VISA CHECK CARD MONEYLION INSTACAS',
  'AVI',
  'WITHDRAWALCASH',
  'BLACK BEAR DINER',
  'COUNTRY FAIR',
  'COTTON PATCH CAFE',
  'STAR MARKETS',
  'CHARLEYS PHIL',
  'NEW YORK COMPANY',
  'DEBIT FOR EARNIN ACTIVE PAYMENTRET CO REF XXXXX',
  'SUNSATIONS',
  'COMENITY BANK',
  'OASIS',
  'TOGOS',
  'PMNT SENT APPLE CASH SENT CA',
  'PIZZA INN',
  'JJ FISH AND CHICKEN',
  'FED EXPRESS FEE',
  'PLAZA',
  'VERIZON WRL MY ACCT VW',
  'UNITED PURCHASE',
  'FIGLOANS TEXAS',
  'ATM WITHDRAWAL WELLS FARGO BAN',
  'FAT SHACK',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS MICROSOFTXBOX LIVE GO MSBILLINFO WA',
  'FRONTIER ONBOARD',
  'LACOSTE',
  'ZELLE TRANSACTION',
  'NTTA AUTOCHARGE',
  'FYFFROMYOUFLOWERS',
  'RED APPLE',
  'BRANCH MESSENGE',
  'CASH APP GOSQCOM',
  'MEINEKE CAR CARE',
  'POS DEBIT VISA CHECK CARD CHECKERS',
  'ALBERT SAVINGS DEDI PYMNTS',
  'DISCOVERY PLUS',
  'GTLCOLLECT CALL',
  'FINE FARE',
  'ULTA BEAUTY',
  'OVERTHECOUNTER',
  'USPSCOM MOVERS G',
  'UNITED PACIFIC PURCHASE',
  'PMNT SENT',
  'FLOATME DB',
  'XHALE CITY',
  'STAMPS',
  'JOES PIZZA',
  'ATM WITHDRAWAL E T',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS PROGRESSIVELEASE PMTS PPD ID',
  'TERIYAKI MADNESS',
  'ELLATION',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS PLANET FIT CLUB FEES PPD ID',
  'FINGERHUT FRESHSTA',
  'WAL MART WAL MART SUP',
  'KAISER PHARM',
  'CARTER S',
  'POINT OF SALE DEBIT L TIME PM DATE SPEEDWAY',
  'KFCTB',
  'WARNER BROS',
  'DICKS CLOTHING',
  'BPIL',
  'CRUNCH FITNESS',
  'SHOE CITY',
  'CAPITAL ONE AUTO CARPAY',
  'FASTRIP FOOD',
  'PROG LEASING LLC',
  'PARKINGMETER',
  'BESPOKE POST',
  'SHOGUN',
  'SELF LENDER INC PAYMENTS',
  'SAMSUNGELEC',
  'EOS FITNESS',
  'PAYMENTUSSERVICEFEE',
  'IMOS PIZZA',
  'ULTACOM IL',
  'GOURMET DELI',
  'THE MARKET',
  'DEBIT FOR EMPOWER INC CO REF EMPSUBSCR',
  'RECURRING DEBIT CARD XXXXX APPLECOMBILL XXXXX',
  'FLANIGANS',
  'HIGHWAY',
  'FIRST PREMIER',
  'LOVE S',
  'TOUCHTUNES',
  'WWWADVANCEAUTOPARTS',
  'WU GM FINANCIAL',
  'SURCHARGE',
  'LIQUOR BARN',
  'CAPITAL ONE CARD PYMT VA',
  'PANDA EX',
  'VISA DDA PUR CASH APP',
  'USCONNECT IMPRL VEND',
  'PAYLESS SHOESO PURCHASE',
  'NATHANS FAMOUS',
  'ROKU FOR',
  'HONG KONG EXPRESS',
  'VIDEO',
  'ROBEKS',
  'HIBACHI GRILL',
  'DMV',
  'UPTOWN BEAUTY',
  'GOHENRY',
  'VIASAT INFLIGHT WIFI',
  'NAYAX VENDING HUNT VALLEY MD',
  'BIG PICTURE LOAN',
  'FAST CARD FEE DEBIT',
  'DEBIT PURCHASE VISA CARD DAVE INC VISA DIRECT',
  'CLAIRE S',
  'TIMBERLAND',
  'DOORDASH PURCHASE WWWDOORDASH',
  'GATEWAY',
  'ACH DEBIT EARNIN ACTIVE PAYMENTRET',
  'TO EMPOWER INC',
  'ACH TRANSACTION CAPITAL ONE ONLINE PMT',
  'STEAKNSHAKE PURCHASE',
  'NONATM CASH FEE',
  'STEAK ESCAPE',
  'VALUE VILLAGE',
  'BRIGIT DEBIT CCD ID',
  'GA DRIVER SVCS CSC',
  'DECKERS UGG',
  'USPOSTALSER',
  'NEWKS EATERY',
  'NINTENDO AMERICAU WA',
  'MILLERS ENERGY',
  'NYCT PAYGO RECOVER',
  'ADVENTURE ACADEMY',
  'ERAC TOLL',
  'VOODOO',
  'PROGRESSIVE IN',
  'USCC IVR',
  'WORLD OF DISNEY',
  'ATM WDL SA',
  'DECKERS',
  'TAYLOR FOOD',
  'CASH APPJONATHAN',
  'KING GCOHELPPAY',
  'FRYSMKTP',
  'RECURRING PAYMENT BILL PAYMENT',
  'BEENVERIFIED INC',
  'POS DEBIT VISA CHECK CARD CLEO',
  'DICKEYS BARBE',
  'DOORDASHARBYS',
  'TX DPS DL OFFICE AUSTIN TX',
  'UPLIFT INC',
  'INTERNATIONAL POS FEE VIS GB',
  'A AUTO INC',
  'TICKETLEAPTICKETS',
  'SEPTA FARE MACHINE',
  'CHACHOS',
  'SHIPT BALANC',
  'ONLINE ADVANCE',
  'SUPER BEAUTY',
  'BIG DADDYS',
  'VISA DDA PUR APPLE COM BILL',
  'BUSCH GARDENS',
  'CHRONIC TACOS',
  'EXCESSIVE TRAN FEE',
  'TEX BEST',
  'E Z MART',
  'DIGITCO SUBSCRIPTN',
  'LONGHORN STEAK PURCHASE',
  'MI PUEBLO',
  'COINMACH',
  'CH HTTPSPOSTMATE',
  'ACCESS SECURE DEPO',
  'THE CHEESEC',
  'CASH APPKIMBERLY',
  'CONNS',
  'JUSTANSWER',
  'SPOKEO SEARCH',
  'MARBLE SLAB',
  'PROG UNIVERSAL',
  'GIANTEAG',
  'MARKET PLACE',
  'HAPPY NAILS',
  'A',
  'LA HACIENDA',
  'JEWEL OSCO',
  'ASPIRE MASTERCARD',
  'POS DEBIT VISA CHECK CARD MICROSOFTXBOX LIV MSBILLINF',
  'DEALER',
  'JPAY MONEY TRANSFER FL',
  'CAPTAIN DS SE',
  'GRUNT STYLE',
  'RECURRING DEBIT PURCHASE CARD APPLECOMBILL',
  'ONLINE TRANSFER TO PP XXXXXXXXXXXX REF',
  'WITHDRAWAL TRANSFER TO',
  'VZWRLSS BILL PAY VW',
  'MY VZ VE P',
  'NETFLIXCOM',
  'COMMERCIO',
  'FAIRFIELD INN',
  'PREPAID SERVE',
  'AGI RENTERS CONDO INS',
  'JEAEZPAY',
  'USACANTEEN VENDING PURCHASE',
  'MOES ONLINE',
  'CREW CARWASH',
  'NAYAX WASH',
  'DOORDASH DASHPASS PURCHASE WWWDOORDASH',
  'STASH CAPITAL S ACH WEB ID',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS DISCOVER EPAYMENT WEB ID',
  'INSTACART PURCHASE',
  'FUEL MART',
  'APLUS',
  'NAIROBI',
  'NAILS SPA',
  'MONEYLION LOAN PAY',
  'PROGRESSIVE LEA',
  'KING SOOP',
  'SOUTHWES TX',
  'LEVELUPPOTBELLY',
  'ROSAS CAFE TORTILLA F',
  'USAA INSURANCE PAYMENT',
  'CHAMPION',
  'INTERNATIONAL TRANSACTION FEE ONLY FANS UK',
  'NON SUNTRUST ATM FEE ATM BALANCE INQUIRY FEE',
  'FASHION N HTTPSWWWQUAD NY',
  'EXETE AUTOREC',
  'MCDONAL',
  'GLACIER WATER VENDING',
  'TOWNEPLACE SUITES',
  'ASOS US SALES LLC WWWASOSCOM DE',
  'SEZZLE ORDER P MN',
  'PEARSON EDUCATION',
  'ATM WITHDRAWAL PLUS EFT',
  'BOOST',
  'FOOD GIANT',
  'CSC SERVICEWORKS ULTRA',
  'GOLDEN',
  'GROVE',
  'AUNTIE ANNE',
  'INMATE',
  'TICKETSATWORK',
  'OH BUREAU MOTOR VEHICLE COLUMBUS OH',
  'CHARLOTTE RUSS',
  'MONEYGRAM US',
  'FLOAT',
  'POS DEB',
  'NORDVPN',
  'SMOKE CITY',
  'BC UBER CASH',
  'STASHSUBSCRIPTION',
  'THRIFTY',
  'STOP GO',
  'VUDU LLC',
  'CROWNE PLAZA HOTELS',
  'CHASE CREDIT CRD EPAY',
  'SPI DUKEENERGY',
  'SP FASHIONNOVACOM PURCHASE FNOVAMYSHOPI',
  'APPLE CASH PMNT SENT',
  'CARD REVERSAL OF CREDIT',
  'CAPITAL ONE CARD P',
  'ALBERT SAVINGS D',
  'AMERICAS',
  'USAA PC INT AUTOPAY',
  'SURCHARGE FEE',
  'ABM ATLANTA ARPRT',
  'CHATURBILL',
  'INTERNET TRANSFER TO DDXXXX',
  'FAMOUS FOOTWEA',
  'NC QUICK PASS',
  'GAMINGFUNDS',
  'DOLLARTRE PURCHASE',
  'WESTLAKE HARDWARE',
  'CLAIM JUMPER',
  'ACH TRANSACTION PLANET FIT CLUB FEES',
  'WSS',
  'RED CRAB',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON QUADPAY QUADPAY A HTTPSWWW',
  'ACCOUNT SETUP FEE',
  'MONEYLION MEMBERSH',
  'ACH TRANSACTION GENESISFS CARD',
  'CHEVRON HALLMARK PETROL',
  'CHASE ACCOUNT OPENING',
  'TASTE OF CHINA',
  'APPLE CASH PMNT SENT INFINITELOOP',
  'BEGGARS PIZZA',
  'INTEREST ON PURCHASES',
  'SHORE STOP',
  'SHELLSHELL',
  'POS DEBIT VISA CHECK CARD VERIZON WRL MY ACC',
  'DICKEYS BARBECUE PIT',
  'STATE OF CALIF DMV INT PURCHASE',
  'CHILI S GRILL BAR',
  'ABCMOUSECOM',
  'STATERBRO',
  'QUICK PICK',
  'VELVET TACO',
  'JELLYBTNGAMES',
  'WOODSPRING SUITES',
  'WOK N ROLL',
  'SPROUTS FARMER PURCHASE',
  'NAIL BAR',
  'VISA DIRECT CA DAV',
  'PAYMENT ID H MONEY SE',
  'GRUBHUBBUFFALOWILDWIN',
  'RECURRING PAYMENT MICROSOFTXBOX',
  'FIESTA',
  'ALTA CONVENIENCE',
  'USPS CHANGE OF ADDRESS PURCHASE',
  'SERVICE',
  'STINKER',
  'WINCHELL S',
  'CHEVRON SUNSHINE',
  'FEE',
  'ABC PLANET FITNESS',
  'BASKIN ROBBIN',
  'PLAYRIX GA',
  'PREMIUM PARKING',
  'SERVI',
  'BEL AIR',
  'BPHANDEE',
  'SUNSTOP',
  'SUPERAMERICA',
  'DESTINATION',
  'T J MAXX PURCHASE',
  'CUSTOM MEDICAL',
  'SMITHS FU',
  'VICTORIASSECRETCOM OH',
  'RUG DOCTOR',
  'FLEXSHOPPER LLC',
  'FLASH FDS',
  'TINEE GIANT',
  'ENTERPRISE TOLL',
  'TO PAYPAL VERIFYBANK',
  'RESUMEHELP',
  'INTERNET TRANSFER CREDIT TO IL',
  'GLASSES USA INC',
  'ASURION WIRELESS INSURAN',
  'PRECISION TUNE',
  'SPEEDYCASH',
  'IPSY ADD ONS',
  'FANZZ',
  'STONEBERRY',
  'AT T PAYMENT',
  'MICROSOFT CONSOLE',
  'MONEYLION LOAN REP',
  'THE BOILING CRAB',
  'LANGE HAIR INC',
  'HOT WOK',
  'FRANCESCAS B',
  'USA SNACK SODA VE',
  'WORLDWINNER',
  'DEBIT FOR CHECKCARD XXXXXX CASH APP',
  'SPORTS BAR',
  'ECONO LODGES',
  'UNIFORM ADVANTAGE',
  'HOBBYLOBBY PURCHASE',
  'LOVELY NAILS',
  'FUEL MAXX',
  'VISA DDA PUR DAVE INC VISA DIRECT',
  'CHECKERS C',
  'JACKPOCKET I',
  'PERSONALIZATION MALL',
  'BPTIMES',
  'VUDUCOM CA',
  'DICKSSPORTI',
  'DNHGODADDYCOM',
  'AMERICAN FINANCIAL',
  'HEYDOCTOR',
  'SS',
  'EXPEDIA PURCHASE EXPEDIACOM',
  'SHIEKH SHOES',
  'WET WILLIES',
  'DUTCHBROSCOFFEE',
  'HARPS',
  'AUNTIE ANNES GA',
  'REGIONAL ACCEPTANCE',
  'PURE FLIX',
  'ICHIBAN',
  'SMITHS FUEL',
  'UNIV PARKING',
  'CA DMV FEE PURCHASE',
  'TMOBILEPOSTPAID TEL WA',
  'VBS ZOCALOANS',
  'ZAXBYS PURCHASE',
  'CASH APPCHRISTOPHE CA',
  'BITESQUAD DELIVERY',
  'XCEL ENERGY',
  'ASSISTED TELEPHONE TRANSFER',
  'PROGRESSIVE INSURANCE PURCHASE',
  'SEZZLE ORDER P PURCHASE',
  'DISCOVERY CLOTHING',
  'PARKRECEIPTS',
  'BEDBATH BEYOND',
  'MCALISTER',
  'ACADEMY',
  'BKGBOOKING',
  'CARVANA',
  'PAY GCOWALLETH CA',
  'PLANNED PARENTHOOD',
  'O REILLY',
  'BET PLUS',
  'ROVIO',
  'POS DEBIT VISA CHECK CARD ULTA',
  'STEAK N',
  'SQC SQUARE CASH',
  'UNION',
  'CONSERV FUEL',
  'POS DEBIT VISA CHECK CARD WU C',
  'NAYAX VENDING PURCHASE',
  'CAJUN CAFE',
  'TOWN COUNTRY',
  'VIMEO',
  'TACO HTTPSPOSTMATE',
  'SUN TAN CITY',
  'POS DEBIT VISA CHECK CARD SQUARE CASH',
  'U HAUL',
  'CHARLOTTE RUSS PURCHASE',
  'RAVE',
  'KLOVER APP BOOST PURCHASE',
  'DBT CRD',
  'SIE PLAYSTATIONNE',
  'QUICK PANTRY',
  'CBS MOBILE APP',
  'MYEYEDR',
  'GO MART',
  'GRAND BUFFET',
  'VICTORIA S SEC',
  'CHANELLOS PIZZA',
  'DEBIT CARD PURCHASE XXXXX DAVE INC VISA DIRECT',
  'WITHDRAWAL INTERNET TRANSFER TO SAV',
  'APPLE PAY SENT MONEY',
  'ZAZZLE USD',
  'MCMIO',
  'REPUBLIC SERVICES',
  'USAA INSURANCE PYM',
  'EXTENDED OVERDRAFT SERVICE CHRG',
  'EZPASS MD MPC',
  'GRANDYS',
  'OCOOVERSTOCKCOM OCOOVERSTOCK',
  'CRO INTERNET',
  'SIZZLER',
  'SKYWAY CONCESSION',
  'CARID',
  'PAPAYA GAMING INC',
  'AMERICAS BEST WING',
  'SAVE MART SUPERMARKETS',
  'BPGIAN',
  'TONYS PIZZA',
  'EVERI WITHDRWL EVERI LAS VEGAS NV FEE',
  'ATM WITHDRAWAL PLUS SUNTRUST',
  'MICROSOFT XBOX PURCHASE MSBILLINFO',
  'TRAVEL MART',
  'TOOT N TOTUM',
  'OTT ZEUS',
  'GTL COLLECT CALL',
  'CODE',
  'OTC BRANDS INC',
  'POS DEBIT VISA CHECK CARD PROGRESSIVE INSUR',
  'MAIN STREET',
  'JPAY MONEY TRANSFER',
  'CK PURCHASE',
  'BMW',
  'DODGE',
  'AAA ACG',
  'DAVE BUSTERS PURCHASE',
  'BARTELL DRUG',
  'LEMON',
  'DIXIE QUEEN',
  'SAKURA',
  'JIFFY MART',
  'JERSEY',
  'SECURUS INMATE CALL VI',
  'PAYACTIV',
  'INSTACART CA',
  'FRAGRANCENET',
  'BPNEW',
  'JOURNEYS KIDZ',
  'SNAPPY',
  'HOLIDAY OIL',
  'DEPOSIT ITEM RETURNED FEE NSF ST OF ITEMSCK DEP AMT DEP DATECK AMT SVC FEE',
  'BIG RED LIQUORS',
  'WWWSHEINCOM',
  'WU SANTANDER',
  'CASH',
  'COLLAGE',
  'ACH PAYPAL INST XFER',
  'DOORDASH DASHPASS PURCHASE',
  'LITTLE GENERAL',
  'THE SPOT',
  'CHINA DRAGON',
  'HOBBYLOBB PURCHASE',
  'STAR MARKET',
  'TMOBILEPOSTPAID FDP PURCHASE',
  'SS BOOKING FEE STYLESEATCOM CA',
  'PINKBERRY',
  'QVC INC',
  'ATM WITHDRAWAL M',
  'GRUBHUBREDROBIN',
  'CASH APPTIFFANY',
  'WEB AUTHORIZED PMT DAVE INC',
  'LESS',
  'GRAND',
  'USA PEPSI BOTTLING',
  'HOME STORE',
  'FAST BREAK',
  'FOODLESS PURCHASE',
  'PACKAGE STORE',
  'NTTA CUST SVC ONLINE',
  'CONSUMERS ENERGY',
  'COURTESY PAY FEE',
  'AA PMT DEBIT',
  'VERIZONWRLSS RTCCR VE',
  'USPS KIOSK',
  'GULF OIL PURCHASE',
  'BUCKYS',
  'MANHATTAN',
  'TOKYO GRILL',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS ALBERT SAVINGS D EDI PYMNTS WEB ID',
  'TD BANK',
  'FUN GAMES',
  'GREYHOUND',
  'APPFOLIO',
  'LENDUMO',
  'BPGAS',
  'CBW CREDIT FRESH',
  'PETRO MART',
  'SHARKS FISH CHICKEN',
  'WDW DISNEY TICKETS',
  'DICKS SPORTING GO',
  'SEDANOS',
  'POS DEBIT VISA CHECK CARD AFTERPAY US INC',
  'FRESCO Y MAS',
  'TOPHATTER',
  'ALVINS ISLAND',
  'SELF LENDER INC ADMIN FEE',
  'AMZN',
  'FOREIGN ATM OR POS FEE EXCHANGE FEE',
  'HARLAND CLARKE',
  'PATPAT',
  'RECURRING PAYMENT DOORDASH DASHPASS WWWDOORDASH',
  'NTTA ONLINE',
  'HAMPTON',
  'PEETS COFFEE TEA',
  'BLINK',
  'COURTYARD BY MARRI',
  'BANK OF AMERICA',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS SPEEDY PAYMENT PPD ID',
  'PGEEZPAY',
  'GRAM GAMES',
  'FIRSTDIGITAL',
  'KID TO KID',
  'GRUBHUBCHICKFILA',
  'ACCOUNT NUMBER ENDING IN USAA INSURANCE PAYMENT',
  'TOBACCO VAPE',
  'DICKS CLOTHINGSPORTIN',
  'WINCO FOODS PURCHASE',
  'MAXWAY',
  'FOOD COURT',
  'BPPRIDE',
  'RETURN ITEM FEE',
  'AMIGOS',
  'POINT OF SALE DEBIT L DATE SPEEDPAYDUKEENER',
  'RECURRING PAYMENT MICROSOFTULTIMATE MSBILLINFO',
  'CHEDDARS PURCHASE',
  'NONBOFA TELLER WITHDRAWAL FEE',
  'FARM FRESH SUPERMARKETS',
  'POS DEBIT VISA CHECK CARD SPECTRUM',
  'PARCHMENT TRANSCRIPTS',
  'FEE OVERDRAFT AT EACH',
  'SOUTHERN',
  'POSSIBLE',
  'FASTLANE',
  'BOSTON',
  'ONE FINANCE INC',
  'GRID MEMBERSHIP',
  'BJS RESTAURANTS PURCHASE',
  'EVENT',
  'GOOD GO',
  'ATM WITHDRAWAL FM',
  'EZ TRIP',
  'CHASE CREDIT CRD AUTOPAY PPD ID',
  'GOLDEN WOK',
  'USCONNECT CRH CATERING',
  'PARTYCITY',
  'MICHAEL',
  'TIGER',
  'FSI GAPOWER LOCAL',
  'DISCOUNT ZONE',
  'COMENITY PAY VI WEB PYMT',
  'MIMIS CAFE',
  'POPSOCKETS',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS MICROSOFTULTIMATE M MSBILLINFO WA',
  'EXPRESS FOOD MART',
  'SURF CITY SQUEEZE',
  'ROKU FOR CBS INTERACTIV',
  'POS DEBIT DDA DEBIT',
  'FUEL EXPRESS',
  'PCHINTELIUS',
  'SUPERSTOP',
  'STANTON OPTICAL',
  'VALUEPAWNJEWELRY',
  'POS DEBIT VISA CHECK CARD DTLR',
  'VAPOR',
  'WU CHRYSLER CAPITAL',
  'DRAFT KINGS',
  'PROGRESSIVE LE',
  'CREDIT ONE BANK',
  'SAMS XPRESS',
  'VISA',
  'BOOKSY INC',
  'T MOBILE',
  'FUNDS TRANSFER',
  'EA PLAY',
  'POS DEBIT VISA CHECK CARD DISNEYPLUS',
  'FRIENDLY',
  'COLD STON',
  'YOUR PIE',
  'KWIKTRIP',
  'WING STOP',
  'UP',
  'EBLENS',
  'BESTWAY',
  'WEB INITIATED PAYMENT AT EARNIN ACTIVE PAYMENTRET XXXXX',
  'BYRNE DAIRY',
  'RUTTERS',
  'EARNIN ACTIVEHOURS VISA DIRECT',
  'VEORIDE',
  'BEAUTY PLUS',
  'GULF MART',
  'MONRO',
  'QUIP NYC INC',
  'COLONIAL PARKING',
  'RESTAURANT DEPOT',
  'CAFE ZUPAS',
  'SONICDRIVEINSTORE',
  'DRRIGHT OF SETOFF TO DEP ACCT ENDING IN',
  'OFFICEMAX DEPOT',
  'NINTENDO AMERI',
  'STARSHIP',
  'CHINA CHEF',
  'BURKES',
  'NONCHASE ATM WITHDRAW OLD',
  'TMOBILEAUTO PAY WA',
  'YARDI',
  'SPIRIT HALLOWEENC',
  'WING TEL INC',
  'SERVICE FEE',
  'WIRE FEE',
  'FREETAXUSACOM',
  'LOANPYMNT',
  'SPIDUKEENERGY',
  'HUHOT MONGOLIAN GRILL',
  'FAMOUS WOK',
  'PATREON MEMBERSHIP',
  'DEBIT DAVE INC COID',
  'DOORDASHCHECKERS',
  'CASEY',
  'OPPFINANC PAYMENT',
  'NONCHASE ATM WITHDRAW W T',
  'PICK N SA',
  'COUNTY MARKET',
  'FL DL TAG GOREN',
  'DEBIT MEMO',
  'EVERGY',
  'AUTO AIR VACUUM PURCHASE',
  'EVERI WITHDRWL EVERI',
  'MACYS COM PURCHASE',
  'DIRECT DEBIT JP MORGAN CHASE AUTH DEBIT',
  'QT MOBILE PURCHASE',
  'WEB AUTHORIZED PMT EARNIN ACTIVE',
  'TACTICAL',
  'INNERSLOTH LLC',
  'CAFE',
  'LENDING S',
  'BIG BOY',
  'SOS',
  'POS DEBIT VISA CHECK CARD RACEWAY',
  'WWWCVSCOM',
  'CRO',
  'EINSTEIN BROS BAGE',
  'CHICKENS',
  'CAPITAL ONE PHONE PYMT TEL ID',
  'SMILEYHOMEY',
  'PRIME',
  'CEREBRAL',
  'GLOSSIER',
  'ROKU FOR DISCOVERY',
  'GOOD TIMES DR THRU',
  'PDFSIMPLI',
  'EAGLE MART',
  'PROPERTY PAYMENTR',
  'EL CHEAPO',
  'EXPRESS GAS',
  'FRESHLY',
  'VERIZON WRL MY AC',
  'ACH TRANSACTION PAYPAL ECHECK',
  'SIEPLAYSTATIONNE PURCHASE',
  'POS DEBIT VISA CHECK CARD MURPHYATWALMAR',
  'IMPERFECT FOODS',
  'RUBY TUESDAY SITE',
  'FEDLOANSERVICING STDNT LOAN',
  'EXPRESS STOP',
  'SURCHARGE FEEBANK OF AMERICA',
  'BEAUTY U',
  'OF LONDON GBR',
  'SMOKEYS',
  'FARM BUREAU',
  'EARNI',
  'HOMEOWNERS INSURANCE',
  'PAYLESS POWER',
  'FFP',
  'CASH APPSAMANTHA',
  'CITY BARBEQUE',
  'INMATE PHONE S',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS DOORDASH DASHPASS WWWDOORDASH CA',
  'MYLIFE PPL BKGRD SVC',
  'ATM CASH WITHDRAWAL P',
  'ACICREDIT ONE BANK N',
  'PROGRESSIVELEASE PMTS',
  'WWWSHEINCOM CALIFORNIA',
  'GUITARCENTER',
  'TOBACCO SHOP',
  'KEEN',
  'WINDSOR FASHIO',
  'INFOPAYCOM',
  'THE ORIGINAL',
  'EXPRESS FUEL',
  'CAPITAL ONE CARD PYMT',
  'FL DL TAG GORENEW FL',
  'THE LIQUOR STORE',
  'INCLEARINGS CHECK',
  'GREAT CLIP',
  'CHECKSMART',
  'VZWRLSS MY VZ VE P',
  'GRUBHUBREDLOBSTER',
  'DIRECT DEBIT BRIGIT MEMBERSHIP',
  'JERSEY MIKE',
  'POWER MART',
  'BROOKSHIRES',
  'USPS CHANGE OF AD PURCHASE',
  'UNITED AIRLINES',
  'PIZZA RANCH',
  'DEBIT FOR PAYPAL VERIFYBANK CO REF',
  'TRANSFUND',
  'KFC J PURCHASE',
  'MERCURY',
  'ONLINE TRANSFER TO PPX TRANSACTION',
  'C BAKERY CAFE USA',
  'CONDADO TACOS',
  'PURCHASE AUTHORIZED ON EARNINACTIVEHOURS VISA DIRECT CA S CARD',
  'SMOKERS CHOICE',
  'PAPPADEAUX SEAFOOD KIT',
  'TACOBELL',
  'CAMPUS BOOKSTOR',
  'EDI PYMNTS ALBERT SAVINGS D',
  'FLOATME FUND I D DIRECT PAY',
  'PEARLE VISION',
  'ACH EMPOWER INC',
  'JOLLIBEE',
  'QUIK TRIP CORPORATION',
  'CV DALLAS',
  'GUSTO',
  'IIQIDENTITYIQCOM',
  'TWISTEE TREAT',
  'POS DEBIT VISA CHECK CARD AT TBILL PAYMENT WWWATTCOM',
  'BLINK MOTO',
  'POS DEBIT VISA CHECK CARD AUTO AIR VACUUM',
  'CBSALLACCES',
  'HUUUGE GLOBAL',
  'ENTERTAINMENT',
  'EQUIFAX',
  'EYEGLASS WORLD',
  'VERIZON VZ',
  'KLOVER APP BOOST IL',
  'BRIGHT HOUSE NETWORKS',
  'ATM WITHDRAWAL L',
  'HACIENDA',
  'ATTBILL PAYMENT',
  'ADMIRAL',
  'MARRIOTT INTERNATIONAL',
  'LOCKSMITH',
  'SEARS ROEBUCK',
  'AMERFIRSTFINPAYMEN',
  'BUFFALO EXCHANGE',
  'AUTOMATIC TRANSFER TO SAV CONFIRMATION',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS AFTERPAY CA',
  'NADINE WEST INC',
  'CORPORATE ACH DAVE INC DEBIT',
  'VS WOW',
  'COMPANYKITCHEN',
  'STARBUCKSSE',
  'BET PLAY',
  'COLUMBUS',
  'FIRST WOK',
  'RAINBOW MARKET',
  'EXCESS TX FEE',
  'AGENT ASSISTED TRANSFER TO CHK CONFIRMATION',
  'HOTWIRE SALES FINAL',
  'SMART STYLE',
  'NBASTORE',
  'CASH APPDANIELLE',
  'ONO HAWAIIAN BBQ',
  'KWIK SAK',
  'SALONCENTRIC',
  'ACH DEBIT EARNINACTIVEHOUR PAYMENTRET',
  'CHEWYCOM FL',
  'FIREWORKS',
  'ASIAN EXPRESS',
  'MICROSOFT XBOX PURCHASE',
  'WESCO INTERNATIONAL',
  'PAPPASITOS CANTINA',
  'BPSUNSH',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS CREDIT ONE BANK PAYMENT WEB ID WEB',
  'DELI GROCERY',
  'CAPITAL ONE PHONE PYMT',
  'BPCLIPPER',
  'CASH APPKAYLA',
  'AAA MEMBERSHIP',
  'GLORIAS RESTAURANT',
  'SEEKHDCO',
  'TX',
  'PAYMENTRET EARNIN ACTIVE',
  'LENNYS SUB SHOP',
  'SEC OF STATE BRANCH',
  'POS DEBIT VISA CHECK CARD MARATHON PETRO',
  'CICIS',
  'MINIT MART EG',
  'BALANC',
  'BUCEES PURCHASE',
  'AMWAY GLOBAL INDEPENDENT BUSINESS OWNER',
  'ANNUALFEE',
  'COSTA VIDA',
  'SUKI HANA',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS KLARNA PAYMENTS NY',
  'STUBHUB INC CA',
  'TACTILE',
  'DEPOSIT ITEM RETURNED FEE ALTEREDFICTITI OF ITEMSCK DEP AMT DEP DATECK AMT SVC FEE',
  'DIRECT DEBIT SQUARE INC SDV VRFY T',
  'KIDS FOOT LOCK',
  'WESTERNBIDI',
  'STEWARTS SHOP',
  'RECOVERY FOR FORCED CLOSED ACCOUNT FDES NMO',
  'STATE OF CALIF DMV INT',
  'EA INC',
  'ONLINE DOMESTIC WIRE FEE',
  'MGA GAINSCO AUTOM',
  'NATIONAL GENERAL',
  'DISCOVERY',
  'MARKET EXPRESS',
  'TOYOTA FINANCIAL',
  'WINDSOR',
  'POS DEBIT VISA CHECK CARD ML PLUS',
  'HARBOR FREIGHT PURCHASE',
  'EXPERIAN CREDIT REPOR CA',
  'CHECK CARD PURCHASE CASH APP',
  'UBER EATS CA',
  'BONFARE MARKET',
  'MICROSOFT XBOX WA',
  'POINT OF SALE DEBIT L DATE BRANCH MESSENGER I',
  'MICROSOFTXBOX LIVE GOL PURCHASE MSBILLINFO',
  'RENT A CENTER ECOMMERC',
  'FILIBERTOS MEXICAN',
  'CASH APPDAVID',
  'WINGS BEACHWEAR',
  'TRASHBILLING',
  'ROKU FOR DISNEY',
  'PROGRESSIVE INSU PURCHASE',
  'PIZZA GUYS',
  'ALBERT SAVINGS D EDI PYMNTS ACH WITHDRAWAL',
  'THE STORE',
  'YOUTUBEPR',
  'BURGER STREET',
  'JOURNEYSCOM',
  'SAVINGS',
  'SHAKE',
  'RNR TIRE EXPRESS',
  'TIER OVERDRAFT ITEM CHARGE',
  'CROWN LIQUORS',
  'POINT OF SALE DEBIT L TIME PM DATE MURPHYATWAL',
  'MPC',
  'KOLACHE FACTORY',
  'ROTTEN ROBBIE',
  'STATION',
  'NV ENERGY',
  'MICROSOFT APPS',
  'STARZ ENTER',
  'CRUNCHYROLL ANIME',
  'GREAT AMERICAN CO',
  'US DEPT OF EDU DBE GRP',
  'AFF',
  'POS DEBIT VISA CHECK CARD MICROSOFT XBOX MSBILLINFO W',
  'VZWRLSSIVR VE',
  'TOUCHTUNESHELPSHIFT TOUCHTUNESCO NY',
  'MANUAL DBBKRG',
  'QPAMAZON',
  'VISIBLE',
  'SHARETEA',
  'VZ WIRELESS VE VZW WEBPAY',
  'GETAROUND',
  'FLYING J PURCHASE',
  'POS DEBIT VISA CHECK CARD PETCO',
  'INSTACART HTTPSINSTACARCA',
  'AARONS REVRUN',
  'CASH APPCOURTNEY',
  'RUBY THAI',
  'CURRENT JUNE',
  'CANTEEN VEND',
  'PAK A SAK',
  'SOUTHWES PURCHASE',
  'POS DEBIT VISA CHECK CARD HM',
  'POS DEBIT VISA CHECK CARD SP FASHIONNOVAC FNOVAMYSH',
  'AMAZONCOM',
  'CUSTOM RX',
  'WALGREEN',
  'SEAWORLD',
  'CUMBERLAND FAR PURCHASE',
  'FITNESS',
  'AUNTIEANNES',
  'CREDITED',
  'ABC FINE WINESP',
  'XPRESS MART',
  'ATM WITHDRAWAL BANK OF AMERICA',
  'CICI S PIZZA',
  'THE HABIT BUR',
  'SPEEDY CASH PURCHASE',
  'COF MERCHNT BIL',
  'UPTOWN CHEAPSKATE',
  'LONG JOHN SIL',
  'PLAYITAGAINSP',
  'PROGRESSIVE LEASING HTTPSPROGL UT',
  'SALON',
  'DQ GRILL CHILL',
  'PAYMENT FOR AMZ STORECARD',
  'PROGRESSIVE LEASIN HTTPSPROGL',
  'YOUTUB',
  'AMZN MKTP US AMZNCOMBILL',
  'HLUHULU U HULUCOM BILLCA',
  'CDONALDS',
  'INTERNET PMT TO STANDBY',
  'CURRENT JULY',
  'NAYAX FOODVEND',
  'SEGPAY',
  'SANTANDER',
  'SHIPT ORDER',
  'LA NAILS',
  'WALGRE',
  'COUSINS SUBS',
  'LEVL PAY',
  'EMPORIUM',
  'WALLETTOP',
  'PAID TO',
  'WHOEASY',
  'TRADERS',
  'RICH OIL',
  'DOLLAR PLUS',
  'BT WAITR',
  'BILO GROCERY',
  'YOGURTLAND CA',
  'WWWKOHLSCOM PURCHASE',
  'ATM WITHDRAWAL COOP USAACATM',
  'PLAYSTATIONNE',
  'EBAY INC CA',
  'CHANGE POINT LAUNDRY PY',
  'A PURCHASE',
  'MTA METROCARD',
  'SAMSUNG',
  'METRO BY T MOBILE',
  'NEXBILLPAY LLC',
  'VUDU INC',
  'BROTHERS',
  'NOTHING BUNDT CAK',
  'CASH APPNICOLE',
  'REPAY',
  'WINDSTREAM',
  'POS DEBIT VISA CHECK CARD GUYS QSR',
  'COUNTRY CORNER',
  'LAUNDRY',
  'SMART AND FINA',
  'CASH APPROBERT',
  'INFINITY INSURANCE',
  'STIR FRY',
  'RECURRING PAYMENT VZWRLSSBILL PAY',
  'NEW CHINA BUFFET',
  'EARTHBOUND',
  'VENUS',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS TMOBILEAUTO PAY WA',
  'POS DEBIT VISA CHECK CARD OUTBACK',
  'INSTACASH PAYMEN MONEYLION PPD ID',
  'SHIPT TIP',
  'TOPS MARKETS',
  'HOMELAND',
  'TGI FRIDA',
  'PARCHMENT UNIV DOCS',
  'DIRECT DEBIT CAPITAL ONE ONLINE PMT',
  'APPLE PAY SENT M',
  'BASKIN Q PURCHASE',
  'GTL INMATE PHONE S',
  'LOLLI POPS',
  'PERMITIUM',
  'WASTE MGMT WM EZPAY',
  'CARLIE CS IGA',
  'OH BUREAU MOTOR',
  'EMPOWER INC ACH WITHDRAWAL',
  'ACCESS SECURE',
  'VA LOTTERY',
  'CREDIT ONE BANK PAYMENT TEL ID TEL',
  'PAYPAL INST XFER DOORDASHINC WEB ID PAYPALSI',
  'CHURCH S CHICKEN PURCHASE',
  'MARATHON FOOD MA',
  'GIANT PURCHASE',
  'EXPRESS INC',
  'SPENCERSONLINE',
  'CHICKEN SALAD CHICK',
  'POS DEBIT VISA CHECK CARD KLOVER APP BOOST I',
  'VBS EAGLE',
  'CHOPSTICKS',
  'DISCOUNT DRUG',
  'PANERABREAD',
  'SMITHSFU',
  'CYCLE GEAR',
  'ALBERTOS MEXICAN FOOD',
  'KFC E PURCHASE',
  'NONCHASE ATM WITHDRAW ST',
  'HOT N JUICY CRAWFISH',
  'POS DEBIT VISA CHECK CARD MICROSOFTSTORE MSBILLINFO W',
  'EVERYDAY',
  'PTC EZPASS CSC',
  'SUBSCRIPTION ACORNS',
  'DIGITCO SAVINGS',
  'SHIPLEYS DONUTS',
  'GOLDEN GATE',
  'MEDIACOM',
  'HOLLYWOOD',
  'MEMBERSH',
  'SHOPPERS FOOD PHARM',
  'PLANTED TREE',
  'FINGERHUT DEBIT CREDIT',
  'QUICK CHEK FOOD',
  'NAIL SPA',
  'CAPITAL ONE PHONE PYMT PPD ID',
  'MR FUEL',
  'ROKU FOR CBS INTER',
  'DOLLAR GE PURCHASE',
  'LAGOS',
  'OD PROTECTION TRAN FEE',
  'WWWCVSCOM IN',
  'CASH APPVICTORIA',
  'QUIK MART',
  'STEAM GAME',
  'WILD BILLS TOBACCO',
  'ACH DEBIT ALBERT SAVINGS D EDI PYMNTS',
  'VIM',
  'PALMETTO',
  'NONCHASE ATM WITHDRAW HIGH',
  'G FUEL LLC',
  'TJX',
  'FLORIDA POWER LIGHT',
  'CREDIT ONE BANK PAYMENT WEB ID MICRODR',
  'OKCUPID',
  'ALEX AND ANI',
  'TD AMERITRADE CL',
  'LIQUOR WORLD',
  'COMCASTXFINITY',
  'CASH DISBURSEMENT FEE',
  'OLLIES BAR',
  'KINGS',
  'CHEVRON CSI',
  'PANERA',
  'GOOGLE PLA',
  'BEDBATHBEY',
  'GTL INMATE',
  'LULULEMON',
  'YOUTUBE MUS',
  'ITUNESAPPST',
  'SURCHARGE FEECHASE',
  'BIMART',
  'CASEYS GE',
  'THE DEPOT',
  'AC MOORE STR',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS AFTERPAY DE',
  'HOMEWAV',
  'GAMES',
  'PANDA GARDEN',
  'FANCY NAILS',
  'STAGE',
  'GOOGLE P',
  'CINDIES',
  'HANDY',
  'BP GAS',
  'NSF FEE ITEM RETURNED ACH BRIGITCOM PROTECTIO ACH BRIGITCOM PROTECTIO',
  'TVL PROTECT',
  'LIQUOR LOCKER',
  'NJ EZPASS',
  'CLEO AI HTTPSWWWMEET DE',
  'LOS AMIGOS',
  'PEPSI BOTTLING',
  'LUXURY NAILS',
  'YANDY',
  'CHECKCARD WU CA',
  'RECURRING OVERDRAFT SERVICE CHARGE',
  'TA HTTPSPOSTMATE',
  'LOUNGE',
  'BUDDYS HOME FURNISHING',
  'PHILZ COFFEE',
  'BI LO GRO',
  'EL RODEO',
  'WINDSOR FASHION',
  'POS DEBIT VISA CHECK CARD GATE',
  'TROPICAL SMOOTH',
  'BUFFALO WILD WIN',
  'BAL INQ FEE BAL INQ FEE',
  'THE HABIT',
  'PAYWITHFOUR',
  'BP FOOD MART',
  'ARMANI EXCHANGE',
  'ROBLOX CORP',
  'CHEERS',
  'RECURRING PAYMENT SXMSIRIUSXMCOMA',
  'INTERNET BANKING TRANSFER WITHDRAWAL',
  'SIRIUSXMCO',
  'FIVE G',
  'MOO MOO CAR WASH',
  'FILIBERTOS MEXICAN FOO',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS ML PLUS LOAN MONEYLION PPD ID',
  'HM NEW YORK NY',
  'BPCIRC PURCHASE',
  'GREYHOUND FS',
  'CHILDRENSPL',
  'EL VAQUERO',
  'VILLAGEINNREST',
  'AUTOSAVE DEPOSIT BASED',
  'REGAL',
  'THE UPS',
  'KING DOLLAR',
  'CENTURY TWENTY ONE',
  'CHINESE',
  'BPNORTH',
  'FUNZPOINTS',
  'THE FRAGRANCE OUTLET',
  'NONCHASE ATM WITHDRAW E T',
  'PRET A MANGER',
  'NONCHASE ATM WITHDRAW FM',
  'AT KLOVER CARE',
  'LK',
  'OVERDRAFT CHARGE OVERDRAFT FEE REBILL FDES NMO',
  'PROG LEASING L',
  'QUICKPAY WITH ZELLE PAYMENT TO DAD',
  'JANE LLC',
  'GEN X CLOTHING',
  'CHARLEYS GRILLED S',
  'LEE NAILS',
  'GOODY GOODY LIQUOR',
  'CHECK N GO',
  'RURAL KING',
  'EARNINACTIVEHOURS VISA DIRECT CA',
  'RECURRING PAYMENT VZWRLSSBILL PAY V',
  'NONCHASE ATM WITHDRAW T',
  'PUMACOM',
  'AFFIRMCOM PAYMENTS AFFIRMCOM CA',
  'FD CA DMV',
  'HELPJAGEX',
  'RENT A TIRE',
  'APPLEB',
  'CAPITAL GRILLE',
  'TAVERN',
  'SPEEDYS',
  'RENTTRACK',
  'DEBIT PURCHASE VISA CARD DAVE INC',
  'DIAMOND PARKING',
  'SUPER MOTELS',
  'POPEYES LOUIS',
  'PARKWHIZ INC',
  'SEZZLE USD',
  'ORCCREDIT ONE NV',
  'NEIMAN MARCUS',
  'GA DRIVER SVCS ONL',
  'CAPTAIN DS PURCHASE',
  'POTBELLY PURCHASE',
  'KLOVER APP BOOST POS DEBIT',
  'RAZOR SCOOTER',
  'USAVEND AT AIR S PURCHASE',
  'CCBILLCOM ONLYFANS',
  'THE TOLL ROADS OF OC',
  'SUN LOAN COMPANY',
  'BROOKSHIRE BROT',
  'SPK SPOKEO SEARCH',
  'SUDDEN SERVICE',
  'CITI BIKE NYC',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS CAPITAL ONE CRCARDPMT WEB ID',
  'POS DEBIT VISA CHECK CARD CHAMPS',
  'TOUCHDOWN WINGS',
  'TACOS EL GAVILAN',
  'SNOOZE',
  'TOWN STAR',
  'BPCK STORE',
  'PIER',
  'SUPER SAVER',
  'LEADBANKSELFLEND PAYMENTS PPD ID',
  'FAREWAY STORES',
  'SONIC PURCHASE',
  'Z MART',
  'SPEEDPAYDUKEENERGY',
  'WEB AUTHORIZED PMT EARNINACTIVEHOUR',
  'EINSTEINMOBILEAPP',
  'EXPRESS MARKET',
  'INSTACART SUBSCRIPTION',
  'ATM WITHDRAWAL W TH',
  'ACORNS INVEST TRANSFER',
  'ROSAS CAFE TORTI',
  'INTUIT TURBOTAX CA',
  'APPLECARD GSBANK PAYMENT WEB ID',
  'SCHOLASTIC',
  'MAMA MARGIES',
  'RUDYS',
  'ABC FINE WINE SP',
  'SHARKS FISH AND CHICKEN',
  'ROKU FOR WARNERMED',
  'TMOBILE POSTPAID WEB WA',
  'MCC MEDIACOM',
  'SHOWMARS',
  'ONEMAIN FINANCIAL GRP',
  'ACH TRANSACTION EMPOWER TRANSFER',
  'RP RESIDENT DIRE',
  'GRUBHUBPANERABREAD',
  'ATM WITHDRAWAL NW',
  'MARTA',
  'NYTIMES',
  'CHECKCARD PYPL PAYIN CA RECURRING',
  'ACH ELECTRONIC DEBIT ALBERT SAVINGS D EDI PYMNTS',
  'BJS RESTAURANT',
  'GOGAS',
  'NCOURT SERVICE F',
  'NONCHASE ATM WITHDRAW BROAD',
  'INSUFFICIENT FUNDS FEE ITEM PAID',
  'THECUT INC',
  'THREADBEAST',
  'TRAINING',
  'GRIMALDIS PIZZA',
  'AUTOSAVE GOAL',
  'PCA ATL',
  'HOTWIRESALES FINA',
  'BSW BEAUTY',
  'LITTLE CAESARS MOBILE PURCHASE',
  'NONCHASE ATM WITHDRAW SW',
  'MONEYLION LOAN PAYMENT',
  'BAR GRILL',
  'ARC TEAM',
  'SIMPLEMOBILE AIRTIME',
  'ADVANCEAUTO',
  'BROTHERS PIZZA',
  'BATH BODY',
  'CASH CENTRA',
  'BUILDABEAR WRK',
  'CASETIFY',
  'ACADEMY SPORTS OUTDO',
  'WINGS OVER',
  'A W',
  'ASPIRATION PAY WHAT IS FAIR',
  'AMERICAN EAGLE PURCHASE',
  'HRB ONLINE TAX PRO',
  'VIACOMCBS STREAMIN',
  'MYLIFE',
  'SPIRIT AIRLINES ON',
  'RECURRING PAYMENT MICROSOFTSTORE MSBILLINFO',
  'INGLES GAS',
  'CHURCH',
  'LES SCHWAB TIRES',
  'OUTOFNETWORK ATM FEE',
  'YUMMY',
  'APPLEBEE',
  'DEBIT CARD PURCHASE P CASH APP',
  'HOMETOWN BUFFET',
  'ROY ROGERS',
  'ALLSTATE NBINDCO',
  'COUNTRY INN SUITES',
  'BPOLD',
  'LEVELUPTROPICALSMOOTH',
  'POS DEBIT VISA CHECK CARD AMC ONLINE',
  'SKILLSHARE',
  'CHEDDAR S',
  'TRANSFER TO SHARE',
  'US AUTO FINANCE',
  'POLORL',
  'CANDYWRITER',
  'UNLEASHED',
  'BEST STOP',
  'THE FRESH MARK',
  'MTAMETROCARD PURCHASE',
  'ROMANOS',
  'MRS FIELDS COOKIES',
  'KAYCOM',
  'HIBACHI',
  'PET SUPERMARKE',
  'HOUSE',
  'DRAGON',
  'FACEBOOK FACEBOOK AD',
  'PHO',
  'PGANDE',
  'MARCOS PIZZA PURCHASE',
  'YOUTUBE VIDE GCOHELPPAY CA',
  'SAFECO INSURANCE',
  'PAYMENTUS',
  'STREET CORNER',
  'USCONNECT ACCNT VE',
  'RECURRING PAYMENT VERIZONWRLSSRTCCR',
  'VESTA ATT PREPA',
  'USA TECHVEN',
  'CAPITAL ONE CARD ONLINE DEP',
  'FOUR POINTS',
  'PHOENIX',
  'BRAVO SUPERMARKET',
  'HOTEL INDIGO',
  'UNIQUE',
  'VBS FOXHILLS',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS CLEO AI HTTPSWWWMEET DE',
  'DOLLAR GE HWY',
  'AMERICASH LOANS',
  'FARMERS FURNITURE',
  'EYEMART EXPRESS',
  'DOORDASHKFC',
  'BETTERME',
  'TRUE RELIGION',
  'TACO VILLA',
  'TRUEBILL SAVINGS',
  'NJT BUS MYTIX',
  'TICKETS',
  'UNIVERSAL',
  'USA CANTEEN VEND',
  'CASH APPJUSTIN',
  'TH MIDWES',
  'BAYMONT INN SUITES',
  'ENTERGY BILLMATRIX',
  'DTLR PURCHASE',
  'EBAY O PURCHASE',
  'ATM WITHDRAWAL PLUS BRANCH BANKING',
  'ALAMO RENTACAR',
  'THE VITAMIN SH',
  'POSHMARK CA',
  'GREENLINE LOANS',
  'DAVE',
  'HP',
  'WINE AND SPIRI',
  'HWY',
  'WU SANTANDER CNSMRUSA',
  'USCONNECT RFSOL VEND',
  'HOLIDAY PHOTO',
  'FOOD KING',
  'EARNIN ACTIVE',
  'LA MADELEINE',
  'POS DEBIT VISA CHECK CARD AFTERPAY',
  'PAYMENTS',
  'LIDSCOM',
  'POS DEBIT VISA CHECK CARD ARCO',
  'ACH ELECTRONIC DEBIT EMPOWER INC',
  'SP FASHIONNOVA PURCHASE',
  'FARMERS BASKET',
  'AFFIRM PAYMENT CA',
  'SKYPE',
  'QUICK FOOD MART',
  'THRIVE MARKET',
  'WORLDREMIT',
  'TAHOMA EXPRESS',
  'DOLLAR SHAVE CLUB US',
  'DDS DISCOUNTS PURCHASE',
  'CAB STORE',
  'CAJUN SEAFOOD',
  'BIG BOSS',
  'EXPERIAN CREDIT',
  'TRANSFER TO CHK XXXXXX',
  'AZ MVD WEB AZ',
  'BOXYCHARM MONTHLY BOX',
  'DEBIT CARD FEE VISA INTERNATIONAL SERVICE ASSESSMENT DATE',
  'FUNDS TRANSFER TO CK XXXXXX REF',
  'SIMPLEMOBILEAIRTIME',
  'TAQUERIA MEXICO',
  'AMAZONSTORES',
  'POS DEBIT VISA CHECK CARD OF LONDON',
  'TOPHATTBATCH',
  'AMERICAS BEST VALUE IN',
  'ALBERT GENIUS EDI PYMNTS P',
  'OCHARLEYS RES',
  'SHELL SERVICE STATION',
  'PAYMENTRET',
  'CRACKER B PURCHASE',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS SIEPLAYSTATIONNETWOR CA',
  'POS DEBIT VISA CHECK CARD CASH APPCHRISTOPH',
  'DDBR PURCHASE',
  'FLOWERSCOM',
  'MOON ACTIV',
  'MINUTEMAN FOOD M',
  'TRANSFER TO OD ACCOUNT',
  'POS DEBIT VISA CHECK CARD DOORDASH WWWDOORDASHCA',
  'POINT OF SALE DEBIT L TIME AM DATE SPEEDWAY',
  'MR SUBMARINE',
  'PATREON MEMBE',
  'DOORDASHCOM',
  'ACH TRANSACTION ML PLUS LOAN MONEYLION',
  'VUDUCOM PURCHASE',
  'EXETER FUNDING II',
  'INTERNET WITHDRAWAL TO',
  'GRINDR LLC',
  'MARATHON AL',
  'LATE CHARGE',
  'DEALDASH',
  'SMOOTHIE',
  'INSURANCE',
  'TAQUERIA ARANDAS',
  'MIAMI GRILL',
  'AMZN DIGITALM PURCHASE',
  'ALOHA',
  'LIMEBIKE',
  'EPIC GAMES STORE',
  'SXMSIRIUSXMCOMACCT NY',
  'INSTANT TRANSFER BETWEEN ASPIRATION SPEND AND ASPIRATION SAVE',
  'CALI PIZZA KITC IN',
  'BEAUTY LAND',
  'ACICREDIT ONE BANK NV',
  'MICRO ELECTRONIC',
  'BILL MILLER BARBQ',
  'VISA DDA PUR KLOVER APP BOOST CHICAGO',
  'CMSVEND CV DALLAS',
  'UNIVERSITY',
  'CHICKEN SALAD CHIC',
  'YOU TUBE MUSIC',
  'FOXBET STARS',
  'INTOWN SUITES',
  'TARGETCOM MN',
  'JFF PUBLICATIONS',
  'S S',
  'PRICERITE',
  'KELLYS CAJUN GRILL',
  'SMALLCAKES',
  'GTLINMATE PHONE S',
  'ASURIONWIRELESS INSURAN',
  'FOSTERS FREEZE',
  'EPISODE APP',
  'BRANCH ACCOUNT UNLOAD TO CARD',
  'STYLESEAT BOOKING',
  'EATS HELPUBERCOMCA',
  'CHINA CAFE',
  'ATM WITHDRAWAL J',
  'CA USA',
  'TELMATE INMATE SRV',
  'ROUSE S MARKET',
  'JAILFUNDS',
  'DC PARKING METERS',
  'THE BOSS PALACE',
  'FLOWERSCOMINC',
  'PL PAYLEASE',
  'CHANGE ORG',
  'C MART',
  'BILL PAYME',
  'DIBELLAS',
  'POPCORN',
  'PAYMENT FOR AMZ STORECARD WEB ID',
  'ATM CASH WITHDRAWAL LK',
  'ALG AIR',
  'ATM WITHDRAWAL I',
  'BEST LOCKERS',
  'AMTRAK MOBIL',
  'GAS MART',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON DOORDASH DASHPASS WWWDOOR',
  'USASNACK SODA VE PURCHASE',
  'STEAMGAMESCOM WA',
  'WITHDRAWAL INTERNET TRANSFER TO XXXX',
  'CARIBBEAN',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS SPEEDY PAYMENT PPD ID',
  'EL TAPATIO',
  'RIB CRIB',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON CLEO AI HTTPSWWW',
  'COUNTRY CLUB',
  'MICROSOFT ULTIMATE M',
  'ELEGANT BEAUTY',
  'THE HAT',
  'KENJO MARKET',
  'ANTHONYS',
  'SPEEDPAYDUKEENER',
  'MONTHLY BOX',
  'SPIDUKE ENERGY',
  'MUSEUM',
  'DOORDASHCHIPOTLE PURCHASE',
  'SKRIMP SHACK',
  'MY VZ VW P',
  'CRO ST JULIANS',
  'CURRENT APRIL SUBS',
  'NETPAY ADVANCE',
  'BRANCH FEE FOR UNLOADING TO CARD',
  'J EFAX SERVICES',
  'ONSTAR DATA PLAN',
  'POS DEBIT VISA CHECK CARD VZWRLSSBILL PAY',
  'MOUNTAIN MIKES PIZZA',
  'PROPERTY PAYMENT',
  'PANCHEROS MEXICAN GRILL',
  'PREPAID PYMNT',
  'R AM',
  'ON THE GO',
  'MSI RENTERS INSURANCE',
  'GOODWILL INDUSTRIES',
  'TIPPY',
  'LEADBANKSELFLEND CC PMT PPD ID',
  'LA FIESTA',
  'VICTORIAS',
  'TTDEYE',
  'SONIC DR',
  'NFCU MEMBERSHIP FEE',
  'CONVENIENCE STORE',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS ATTBILL PAYMENT TX',
  'CASH STORE',
  'PAYPAL INST XFER APPLECOM BILL WEB ID PAYPALSI',
  'PLAZA AZTECA',
  'ROCK BOTTOM',
  'TRANSFER TO CHK XXXXX',
  'SHELTER',
  'EVERYPLATE',
  'BASKINROBBIN',
  'CAROLINA',
  'BIG D',
  'BEAUTY ISLAND',
  'MEGABUSCOM',
  'PERKINS RESTA',
  'MILOS',
  'WWWSHEINCOM CALIFORNIA CA',
  'ADORE',
  'PIC N PAC',
  'TD ZELLE SENT AUT TD ZELLE SENT',
  'DART GO PASS',
  'FIRMOO',
  'INMATESALESCOM',
  'SS BOOKING FEE STYLESEATCOM',
  'ATT PREPA',
  'MARKET WORK',
  'ACCOUNT TRANSFERRED TO',
  'DOORDASHPAPA JOHNS PI',
  'POS DEBIT VISA CHECK CARD DOORDASH DASHPASS WWWDOORDAS',
  'ONLY FANS PURCHASE UK',
  'DIRECT DEBIT BRIGIT PROTECTION',
  'WOODMANS FOOD M',
  'ORANGE LEAF',
  'ATM WITHDRAWAL PLUS SECU',
  'VZWRLSSBILL PAY VN',
  'BUMBLE HELPBUMBLE',
  'TROPICAL SMOOTHIE PURCHASE',
  'ACH WEBSINGLE XXXXX EARNIN ACTIVE PAYMENTRET',
  'BABIES R US',
  'POS DEBIT VISA CHECK CARD SPEEDWAY',
  'LULULEMONCOM',
  'PREAUTHORIZED DEBIT DAVE INC DEBIT',
  'DOORDASHSTEAK N SHAKE',
  'JINYA RAMEN BAR',
  'MAKI OF JAPAN',
  'LEES FAMOUS RECIPE',
  'INSTACASH REPAYMENT NY',
  'CASH APPJORDAN',
  'EL POLLO',
  'TRANSFER TO SAV XXXXXX',
  'ORION FUELS',
  'HM MOBILE PURCHASE',
  'FANDUEL STARDUST',
  'COWBOYS',
  'CASH APPAMBER',
  'SMOKE SHACK',
  'THE EXCHANGE',
  'STAR LIQUOR',
  'POS DEBIT VISA CHECK CARD USPS CHANGE OF ADD',
  'STICKR LLC',
  'ZOOSK',
  'NONCHASE ATM WITHDRAW STAT',
  'ASHLEY',
  'MARKETWORK PURCHASE',
  'BIGS',
  'POS DEBIT VISA CHECK CARD KFC G',
  'WESTIN',
  'SBDSCENTBIRD',
  'OH BUREAU MOTOR VEHICLE',
  'ACH ALBERT SAVINGS DEDI PYMNT',
  'SCENTBOX',
  'BADOO',
  'BARGAIN HUNT',
  'LULUS',
  'POS DEBIT VISA CHECK CARD PREMIER BANKCARD',
  'ONLY FANS UK GBR',
  'USAA INSURANCE PYMT',
  'CHECKCARD PURCHASE VISA KLOVER CARD XXXXXX POS AT KLOVER APP BOOST',
  'VOYAGER',
  'PAYMENTRET EARNIN ACTI',
  'CIRCLE B',
  'DIRECT DEBIT DISCOVER E PAYMENT',
  'HUCKS FOOD FUEL',
  'BREAKTIME',
  'MICROSOFT XBOXLIVE WA',
  'SPYFLY',
  'NONCHASE ATM WITHDRAW PARK',
  'FAMOUS',
  'THE CORNER STORE',
  'FUEGO',
  'CARLS',
  'THE ATHLETES FOOT',
  'KIEZELPAY',
  'EXPRESS PURCHASE',
  'JUSTFLY',
  'ROKU FOR DISNEY E',
  'TOTAL WINE AND',
  'CUROLOGY HTTPSCUROLOGY CA',
  'PLAYSTATION NET',
  'XFR WD TO SHARE',
  'BEVMO',
  'USA SODA SNACK VEN',
  'AMERICAS CAR MART',
  'ATT RETRY PYMT',
  'SAMS FOOD MART',
  'CINEMAS',
  'ALOFT',
  'PERFUME',
  'CARHARTT',
  'CAR SPA',
  'BATH AND BODY WO',
  'SERVICE CHARGE SUSTAINED OD',
  'NATIVE',
  'EL TORO',
  'CHECKWITHDRAWAL',
  'LIQUOR STORE',
  'UPLIFT',
  'FIVE GUY',
  'ALAMO',
  'PANTRY',
  'AUTOSAVE SAFETY NET',
  'CASH APPTAYLOR',
  'GUCCI',
  'QUADPAY FASHION N HTTPSWWWQUAD NY',
  'VERIZONWRLSSRTCCR VN',
  'BPSOUTH',
  'WILLIAMSSONOMA',
  'PAYPAL ECHECK WEB ID PAYPALEC',
  'BETRIVERS',
  'HALLOWEEN CITY',
  'AMERICAN EXPRESS ACH PMT M WEB ID',
  'MGM GRAND',
  'BEAUTY TOWN',
  'COUNTRY MARKET',
  'ALLY ALLY PAYMT',
  'JENNYS MARKET',
  'SQUARE D',
  'DEBIT FOR ALBERT GENIUS EDI PYMNTS CO REF PAYMENT',
  'BLACK',
  'KFTEAUSA',
  'EA ORIGINCOM',
  'UBER PENDING',
  'CKOPATREON MEMBERSHIP',
  'AUTO AIR VACUUM SERV',
  'GOOGLE YOUTUBE SUBSC',
  'DING TEA',
  'D SMOKE SHOP',
  'SINCLAIR',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS ML PLUS MEMBRSHP MONEYLION PPD ID',
  'DAVIDS',
  'JIFFYSHIRTS',
  'RAISING CANE',
  'RAISING C',
  'OPCNONFIN SERVICE FEE',
  'VET CANTEEN',
  'ZELLE PP PAYMENT SENT ON',
  'TEXTME',
  'CENTSONLY PURCHASE',
  'ALTARD STATE',
  'PAPA JOHNS PURCHASE',
  'POSSIBLE FINANCE AR',
  'PAID ITEM FEE',
  'WETZELS',
  'PAYMENTUSSERVICEFEE GA',
  'DEBIT FOR CHECKCARD XXXXXX QT',
  'SURPRISEKATESPADE',
  'TOPS FUEL',
  'VALVOLINE',
  'CHURCHES',
  'SAVE A LOT PURCHASE',
  'GIRL SCOUTS',
  'DOMESTIC CASH WITHDRAWAL FEE',
  'DOTTYS',
  'GNC GNC',
  'PAPPAS BARBQ',
  'KINGS BEAUTY SUPPLY',
  'CASH APPJOSHUA',
  'ATM WITHDRAWAL SW',
  'MEINEKE',
  'PRIME VIDEOMY',
  'PLANTED',
  'PLATO S CLOSET',
  'AB PETROLEUM',
  'SUNSHINE LOANS',
  'USCC CALL CENTER',
  'NETWORK',
  'ABC FINE WINE',
  'JTI',
  'LUPE TORTILLA',
  'BOARDWALK',
  'INSTANTINK',
  'BIRD APP CA',
  'CAPRIOTTIS',
  'MV PRODUCTIONS INC',
  'TRANSFERWITHDRAWAL TO SHARE',
  'NJ EZPASS VIOLATIONS',
  'USCELL',
  'CASH APPCRYSTAL',
  'SMOKE',
  'CASH APPDANIEL',
  'EPAYMYLOAN PMT',
  'CAPTAIN JAYS',
  'SHOPPERS FOOD',
  'QUICKBITEU',
  'CHICKFILA ONE',
  'GET N GO',
  'CHUMBA GOLD COINS',
  'AMERICAN PURCHASE',
  'CHINA TASTE',
  'CHESTNUT',
  'CASH APPKEVIN',
  'SOCIETY',
  'BFS FOODS',
  'ACH PAYPAL RETRY PYM',
  'ALBERT SAVINGS D EDI PYMNTS XXXX',
  'STAR NAILS',
  'EL PASO',
  'CHEVRO',
  'NON MT ATM FEE J',
  'ONETIMEPAY',
  'FRIENDLY MART',
  'LUCKY BRAND',
  'GREYHOUND LINES CNP TX',
  'HTTPS SHOPLUVME',
  'CURACAO',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS DAVE INC DEBIT WEB ID',
  'CSC SERVICEWORKS',
  'CLICKBANK',
  'MO DMV',
  'ELEPHANT INSURANCE',
  'DIRECT DEBIT LEADBANKSELFLEND PAYMENTS',
  'TRIPLE',
  'TAQUERIA JALISCO',
  'VENDING MACHINE',
  'ANCESTRYCOM',
  'THORNTONS PURCHASE',
  'PRO IMAGE SPORTS',
  'EDDIE BAUER',
  'USA FIVE',
  'ZELLE PAYMENT TO MOM JPM',
  'PACSUN PURCHASE',
  'TXU BILL PAYMENT',
  'HONGKONG',
  'BPFAST',
  'TRIP HELPUBERCOMCA',
  'BLUECHEW',
  'LAZY DOG',
  'BARNES AND NOB',
  'CPR CERTIFICATION',
  'ACH PLANET FIT CLUB FEES',
  'BRAUMS STORE PURCHASE',
  'KONA ICE',
  'ASURIONWIRELESS',
  'PYPL PAYI',
  'MAVERIK',
  'ULTACOM PURCHASE',
  'MODERN NAILS',
  'RECURRING PAYMENT SEZZLE ORDER P',
  'INTL DEBIT CARD PUR AUT INTL DDA PUR',
  'RACKROOMSHOES',
  'CHARLEYS PHILLY STEAKS PURCHASE',
  'SONIC DRIVE IN',
  'AVENUE',
  'MCCLURE STORE',
  'PERPAY',
  'SP',
  'GOFUNDME CA',
  'GYMSHARK',
  'PAYPAL VERIFYBANK XXXXXXXXX',
  'HONG KONG RESTAURANT',
  'FOOTLOCKER WI',
  'SALLIEMAEBANK SMBPAYMENT',
  'YANKEE CANDLE',
  'KEYMELOCKSMITHS',
  'HOLIDAY',
  'TOPPERS PIZZA',
  'SUNMART',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS MONEYLION INSTACASH NY',
  'SUPER EXPRESS',
  'WALMART FAMILY MOBILE',
  'O CHARLEYS',
  'ATM WITHDRAWAL PLUS US BANK',
  'ATM CASH ADVANCE',
  'CHASE CREDIT CRD EPAY ONUS WEB ID',
  'BRAVO SUP',
  'FOODA INC',
  'GRUBHUBARBYS',
  'AM EAGLE OUTFT',
  'BILL PAYMENT GA',
  'ENTERPRISE RENTA PURCHASE',
  'BEVERAGE DEPOT',
  'BUDGET INN',
  'SAKS OFF TH',
  'CARD DAVE INC',
  'ATM CASH DDA DEBIT',
  'GLACIER WATER VEND',
  'QUICKPAY WITH ZELLE PAYMENT TO DAD JPM',
  'MOES SOUT',
  'DONATOS PIZZERIA',
  'BETFAIRCASINO',
  'TRUCK STOP',
  'POFCOM',
  'ORCCREDIT ONE PURCHASE',
  'YOUTUBE VI GCOHELPPAY',
  'TRANSFER TO DAVE',
  'MIRABITO',
  'MICHAEL WILLIS',
  'AUTOSAVE PAYDAY',
  'MAINTENANCE SERVICE CHARGE',
  'CURRENT MAY',
  'PLAY',
  'SMITHS FOOD',
  'DIRECT DEPOSIT TRANSFER TO FIFTH THIRD EARLY ACCESS',
  'EXCESSIVE WITHDRAWAL FEE',
  'LEGALSHIELD',
  'WU SANTANDER CNSMR USA',
  'KLOVER HOLDINGS PURCHASE',
  'ARBY S',
  'BIG FISH GAMES',
  'CHEWYCOM PURCHASE',
  'VISION CENTER',
  'SPARKLIGHT',
  'STRIDE',
  'GRAETERS',
  'GOOD TO GO',
  'TIERRA MIA COFFEE',
  'LOTUS SEAFOOD',
  'PANDA WOK',
  'INTERNATIONAL POS FEE VIS HK',
  'TIRES',
  'WEB AUTHORIZED PMT CREDIT ONE BANK',
  'USPS CHANGE OF',
  'TRANSFER TO CK XXXXXXXX WITHDRAWAL',
  'DOORDASH CA',
  'MICROSOFTXBOX GAME PA MSBILLINFO WA',
  'RAPID CASH',
  'SHAKEYS PIZZA',
  'CUMBERLAND',
  'BROW ART',
  'GRILL',
  'RECURRING PAYMENT EXPERIAN CREDIT R',
  'BPAMPM',
  'OPCFIN SERVICE FEE',
  'SERIOUSLY',
  'ACH ML PLUS MEMBRSHPMONEYLION',
  'CODIGAMES',
  'BPSUNRI',
  'GRUBHUBFOO',
  'TOBACCO WORLD',
  'INSTACART PURCHASE HTTPSINSTACAR',
  'RMA TOLL',
  'WOODFOREST',
  'FRICKERS',
  'LEEANN CHIN',
  'PAYLESS',
  'ALIEXPRESS CA',
  'CHURCH S CHICKE',
  'SLICE',
  'WHITE CASTL',
  'NEWWAVSPRKLIGHT',
  'AZ MOTOR VEHICLE DIV AZ',
  'ARENA',
  'POINT OF SALE DEBIT DAVE INC',
  'BPQUICK',
  'SODA SNACK VEN',
  'CCB MADISON',
  'CITIBANK NA',
  'SOUPLANTATION SWEET TOMATOES',
  'GIT N GO',
  'APL ITUNESCOMB',
  'ACH BRANCH MESSENGERBRANCH ME',
  'SLINGCOM PURCHASE CO',
  'HIBACHI BUFFET',
  'CRICKET WIRELES',
  'UBISOFT',
  'OLD TIME POTTERY',
  'JACK IN',
  'POS DEBIT VISA CHECK CARD ETSYCOM',
  'ONLINE TRANSFER TO CHECKING',
  'FASTRAK CSC',
  'YOUTUBEP',
  'CSCSW',
  'MY VZ VN P',
  'POS DEBIT VISA CHECK CARD CORNER STORE',
  'CASH APPDESTINY',
  'TUTTI FRUTTI',
  'LIFETOUCH NSS ONLI',
  'ROUSES MARKET',
  'LASSUS',
  'ROCKET FIZZ',
  'KEKES BREAKFAST CAFE',
  'BANANAREPUBLIC US',
  'DONATEFB',
  'CHECKOUT',
  'DUTCHBROS',
  'YOUNIQUE LLC',
  'JUSTANSWER TRIAL',
  'POINT OF SALE DEBIT L DATE SPECTRUM',
  'SUPERMERCADO',
  'SECURUS INMATE CA',
  'CITYSD PARKING',
  'HUDSONNEWS ST PURCHASE',
  'ACH ALBERT GENIUS EDI PYMNT',
  'ATM WITHDRAWAL A',
  'TMOBILE POSTPAID T',
  'POS DEBIT VISA CHECK CARD MURPHY EXPRESS',
  'BEVERAGES MO',
  'SUPERIOR GROCERS',
  'WESTLAKE FINANCIAL',
  'DOORDASHBOB EVANS',
  'ACH WEBSINGLE DAVE INC DEBIT',
  'AMERFIRSTFINPAYMENT',
  'NONCHASE ATM WITHDRAW LAKE',
  'PETES',
  'GLU GAMES INC',
  'MIKES',
  'ACH CAPITAL ONE MOBILE PM',
  'ONLYFANS IL',
  'FRGTEAMFANS',
  'FUEL STOP',
  'BLOOMYS',
  'POS DEBIT VISA CHECK CARD ARBYS',
  'EXETER FINANCE LLC',
  'LUVME HAIR',
  'SWEETWATER SOUND',
  'FOREIGN ATM INQUIRY FEE BALANCE INQUIRY',
  'GRUBHUBTGIFRIDAYS',
  'OVERTHECOUNTER WITHDRAWAL',
  'VIASAT INC',
  'PAYIT NC DMV',
  'MTA MVM VENDING MACHIN NEW YORK NY',
  'TACO MAYO',
  'MEETME',
  'AFTERPAY US CA',
  'BOMBSHELLS',
  'FARMERS MARKET',
  'OVERSTOCK',
  'RETURN MAIL FEE',
  'HEB GAS',
  'BRIGIT PURCHASE',
  'TACO PALENQUE',
  'MICROSOFTULTIMATE MONTMSBILLINFO',
  'BEDBATHBEYOND PURCHASE',
  'MERCARI CA',
  'TEL UPGRADE',
  'ITR WESTPOINT TOLLS',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS PAYPAL INST XFER APPLECOM BILL WEB ID PAYPALSI',
  'CROWN WIRELESS',
  'CARD ENDING IN USAA CREDIT CARD PAYMENT',
  'PAYMERCHANT',
  'CENTS ONLY PURCHASE',
  'SUPERTREAT GMB',
  'POS DEBIT VISA CHECK CARD UNITED',
  'GENESISFS CARD TEL ID',
  'LISAS CHICKEN',
  'GTLTELMATE INMATE SRV',
  'MINT MOBILE',
  'FOOD STORE',
  'NONSUFFICIENT FUNDS',
  'LUSH DIGITAL USA',
  'DOORDASHPANDA EXPRE WWWDOORDASH CA',
  'CHECKCARD STATE OF CALIF DMV INT CA',
  'TOBACCO HUT',
  'SUBWAY CHICAGO IL',
  'CASH APPAMANDA',
  'NUTRITION LLC',
  'BILL PAYMENT PURCHASE WWWATTCOM',
  'ATM WITHDRAWAL US',
  'OFFERUP',
  'NY PIZZA',
  'ZZOUNDS',
  'DAYS INNSDAYSTOP',
  'TOTAL',
  'SPIRE BILL PAY',
  'WINGST',
  'VENTRA VENDING',
  'SAFE AUTO',
  'CHARMING CHARLIE',
  'POINT OF SALE DEBIT L TIME PM DATE HH',
  'E COMPLISH INC',
  'MAINTENANCESERVICE FEE',
  'CHECK N GO PPD ID',
  'MARSHALL',
  'PAYMENT CREDIT ONE BANK',
  'KITCHEN',
  'FOODARAMA',
  'ALBERT INSTANT WALNUT CA US',
  'ATM WITHDRAWAL PLUS M',
  'MI CASITA',
  'LOVISA AMERICA LLC',
  'TOYOTA',
  'WASABI',
  'WALMART GROCERY',
  'TOWN CENTER',
  'RECURRING PAYMENT CCBILLCOM ONLYF',
  'OTTERBOX LIFEPROOF',
  'VW VZW',
  'QUALITY INN SUITES',
  'PA WITHDRWL USAACATM',
  'TEECHIP',
  'CARSHIELD',
  'MARCOS PI',
  'SMOOTHIEKING',
  'BP PURCHASE',
  'SAVEMART',
  'FUNDS ROBINHOOD',
  'DICKS CLOTHINGSP',
  'FREDDYS PURCHASE',
  'SMART AND FINA PURCHASE',
  'LILITH MOBILE',
  'ELECTRONIC WITHDRAWAL BRIGITCOM',
  'METROCARD',
  'PETROL',
  'BOOMERJACKS GRILL',
  'TOTALAV',
  'RESUME',
  'THE CATCH',
  'AGIRENTERSCONDO INS',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON MICROSOFTULTIMATE MSBILLI',
  'IHEARTMEDIA',
  'BOWLMOR',
  'STAR FOOD MART',
  'AUTOSAVE PERIODIC',
  'DUTCHBRO',
  'SECURUS INMATE CALLVI TX',
  'POS PURCHASE POS TARGET T',
  'LOVES TS',
  'IMARKETSLIVE',
  'FASCINATIONS',
  'STRACK VAN TIL',
  'RETAIL',
  'LONGS',
  'REGAL CINEMAS MOBI',
  'AUTOMATIC PAYMENT',
  'WATER',
  'DEPOSIT ITEM RETURNED FEE REFER TO MAKER OF ITEMSCK DEP AMT DEP DATECK AMT SVC FEE',
  'CHECKCARD OF LONDON',
  'CLOVER',
  'QUIK TRIP CORP',
  'WAWA MOBILE PURCHASE',
  'PROGRESSIV',
  'USA GAS',
  'FRANKS',
  'DISNEY PLU',
  'DP DOUGH',
  'PREMIER BANKCARD LLC SD',
  'MASSEYS',
  'BREWINGZ',
  'ATM WITHDRAWAL T',
  'OREGANOS PIZZA',
  'STAR BEAUTY',
  'LOVES COUNTRY STORE',
  'POS DEBIT VISA CHECK CARD TMOBILEPOSTPAID F',
  'INTL TXN FEE INTL TXN FEE',
  'VERA BRADLEY',
  'SMOKE AND VAPE',
  'SHEN ZHEN',
  'DISNEY MOVIE CLUB',
  'USCONNECT ACEVN VEND N',
  'DISCOVER PHONE PAY TEL ID',
  'ARMOUR',
  'FULL MOON BBQ',
  'WORLD ACCEPTANCE WORLD',
  'CLEO AI INC CLEO AI IN',
  'POS DEBIT VISA CHECK CARD WISHCOM',
  'POS DEBIT VISA CHECK CARD SAMSCLUB',
  'BPPAR',
  'CANTEEN VENDI',
  'FLOATME FUND I D',
  'WEBMERCHANT',
  'ACH DEBIT ALBERT GENIUS EDI PYMNTS',
  'INVESTMEN',
  'DOLLARGENERAL PURCHASE',
  'KRAB KINGZ',
  'DOUBLE QUICK',
  'ACCESSMCP INMATE PACK',
  'EL PATRON',
  'MARKETWORK RENTON WA',
  'FINGERHUT PAYM',
  'DIRECT ENERGY',
  'THE PIT STOP',
  'VBS GREENARROW',
  'REPAYMENT',
  'BABYLIST',
  'APOCC VISE',
  'GRUBHUBAPPLEBEES',
  'ETSYCOM PURCHASE',
  'TOKYO',
  'AARP MEMBERSHIP',
  'OFFSET DEBIT',
  'GERBER LIFE',
  'PARKCOLUMBUS',
  'PETER PIPER PIZ',
  'AUNTIE ANNES OH',
  'FLOAT ME CORP PAYMENTS ACH WITHDRAWAL',
  'MUNICIPAL ONLINE PAYME',
  'POS DEBIT VISA CHECK CARD VZWRLSSBILL PAY V',
  'GCOWALLETHCA',
  'TOMMYS EXP CW',
  'ATM FOREIGN TRANSACTION FEE',
  'LENDNATION ONLINE',
  'TMOBILEPOSTPAID T',
  'GREENSBORO ABC',
  'SHOPPERS WORLD',
  'FITBIT INC',
  'WESTAR',
  'STATE LIQUOR STORE',
  'VZWRLSS PREPAID PYMNT',
  'THE ICE CREAM',
  'ATM WITHDRAWAL K',
  'HOLLYWOOD NAILS',
  'CHIME',
  'FRIENDLY EXPRESS',
  'VZWRLSS MY VZ VN P',
  'CRDFX',
  'LOVES STORE',
  'DIRECT DEBIT FPB CR CARD RETRY PYMT WEB',
  'TORRIDCOM',
  'NISSAN',
  'TAXSLAYER',
  'EA ORIGINCOM HELPEACOM CA',
  'COMENITY PAY VI PHONE PYMT P TEL ID',
  'MRS WINNERS',
  'REP CARD FEE',
  'ALLY ALLY PAYMT WEB ID',
  'TEXAN MART',
  'FASTOP MARKET',
  'TXGOVSERVICEFEEDIR EGOVCOM TX',
  'BWI BOINGO',
  'MARCUS',
  'HOMESITE',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON MICROSOFTXBOX LIV MSBILLI',
  'SHOES',
  'PREMIER BANKCARD PURCHASE',
  'SURCHARGE FEEA',
  'ACH TRANSACTION FPB CR CARD INTERNET',
  'OBERWEIS DAIRY',
  'GOODYS',
  'UNIMART',
  'TEXAS THRIFT STORE',
  'QUAKER STEAK LUBE',
  'AMERIC',
  'FASTRIP',
  'CAR WASH USA',
  'EATNPARK',
  'MOES SW GRILL PURCHASE',
  'PRICELINE',
  'ASHWORTH COLLEGE',
  'TBPH',
  'STARY LTD',
  'AUNTIE ANNE S',
  'YOUNGS',
  'OD PROTECTION TRANSFER TO DEPOSI',
  'PAPA JOHN S',
  'GLD LLC',
  'SAMS MARKET',
  'CREDIT ACCEPTANCE COR',
  'USASODA SNACK VENDING',
  'XFINITY WIFI PURCHASE',
  'NSF FEE ITEM RETURNED ACH BRIGITCOM MEMBERSHI ACH BRIGITCOM MEMBERSHI',
  'ISPAPIMDS WITHDRWL PAI ISO CHICAGO IL FEE',
  'BURRITO',
  'CUROLOGYINC',
  'EVERGREEN',
  'OLIVE OLE',
  'RUSHS',
  'POS DEBIT VISA CHECK CARD KLARNA PAYMENTS',
  'TMOBILE POSTPAID IVR',
  'CHICKFIL A',
  'HLUHULU U HULUCOMBILLCA',
  'CAPITAL ONE MOBILE PMT ACH WITHDRAWAL',
  'SPEEDY PAYMENT PPD ID',
  'ACH TRANSACTION DIGITCO SUBSCRIPTN',
  'PHILIPS',
  'PEPBOYS STORE PURCHASE',
  'DICKS SPORTIN PURCHASE',
  'PATREON INC ME',
  'ST FRANKLIN FINANCIAL',
  'NAT YOURSCOREANDMORE',
  'HONDA PMT',
  'FARMERS',
  'VERIZON WRL MY',
  'EXPRESS WASH',
  'AMERIGAS',
  'MYTONA',
  'HMCOM',
  'STRAIGHTTALKAIRTIME FL',
  'ECONOLODGE',
  'ADVANCE FINANCIAL',
  'ACH USAA PC INT AUTOPAY',
  'VILLA FIK',
  'OLB TRF',
  'CREW CAR WASH',
  'SEAFOOD CITY SU',
  'DOORDASH PURCHASE STRIPECOM',
  'VERIZONVICTRA',
  'TBMBM',
  'RTCCR',
  'DEBIT CARD PURCHASE P APPLECOMBILL',
  'FORD MOTOR CR FORDCREDIT',
  'BUBBLE CASH',
  'EXTENDED OVERDRAWN BALANCE CHARGE',
  'POS DEBIT VISA CHECK CARD ZAXBYS',
  'LOVE S COUNTRY PURCHASE',
  'BRIDGE IT INC PROTECTION PPD ID',
  'MASTERCUTS',
  'THRIFTY CAR RENTAL',
  'BARKBOX INC',
  'LIQUOR KING',
  'NONCHASE ATM WITHDRAW GRAN',
  'PIRATE SHIP',
  'SPRINT STORE PURCHASE',
  'KING INTERNET',
  'UNITEDHEALTHONE',
  'CHICKEN SHACK',
  'ASURIONWIRELESS INSURANCE',
  'DISNEYLAND TICKETS',
  'ACH TRANSACTION CAPITAL ONE CRCARDPMT',
  'TRANSFER TO CHIME CHECKING',
  'ADAMEVECOM',
  'WITHDRAWAL TCODE CD',
  'PAPAS PIZZA',
  'ANGEL NAILS',
  'APPLE CASH INFINITELOOPCA',
  'OUTBACK STEAK',
  'AARONS SALES AND LEASE',
  'NAMECHEAP',
  'CCICARECOM',
  'NONCHASE ATM WITHDRAW W C',
  'LEVEL BANK PLUS DUES HTTPSLEVELBAN CA',
  'ATM WITHDRAWAL LAKE',
  'ACH TEMPORARY CREDIT REVERSAL ON ACCT CLAIM',
  'SEES CAND',
  'ONLYFANS PURCHASE',
  'SHENZHENSHI',
  'MAGIC NAILS',
  'S G',
  'OFFERUP ID',
  'FRANKIES',
  'BILL MILLER BARB',
  'LEMONADE I LEMONADE I',
  'POINT OF SALE DEBIT L DATE HLUHULU HULU COMBIL',
  'BANDCAMP',
  'AMERICAN EAGLE OUTFITT',
  'DONATEFBCOMCA',
  'BJS WHOLESALE PURCHASE',
  'TENDER SHACK',
  'ELECTRONIC WITHDRAWAL BRIGIT',
  'GODFATHERS PIZZA',
  'SECURITYFINANCECO',
  'COUNTER',
  'CASH APPMICHAEL PMNT SENT',
  'BLS IMARKETSLIVE',
  'OFFICE MAX',
  'JEFFERSON',
  'ATM WITHDRAWAL PLUS ALI',
  'WU CA',
  'POS DEBIT VISA CHECK CARD VANS',
  'TMOBILE POSTPAID W',
  'POS DEBIT VISA CHECK CARD SONIC',
  'CASEYS GEN STOR',
  'DOORDASHRALLYS',
  'LENDMARK FINANCI',
  'GRUBHUBPANDAEXPRES',
  'SUSHI KING',
  'KAOIRFITNES',
  'CASH APPJOHN',
  'HUDSONST',
  'BLINK WEB',
  'DOORDASHOUTBACK STEAK',
  'OUTPUT INC',
  'LIGHTRICKS',
  'POS DEBIT VISA CHECK CARD DAVE BUSTERS',
  'TMOBILEPOSTPAID IVR WA',
  'BALANCE TRANSFER TO VARO BANK NA',
  'TOGGLE INSURANCE',
  'VZWRLSS IVR VE',
  'CASH APPSTEPHANIE CA',
  'TOYOTA PAY TFS',
  'VBS ADVANCECASH',
  'VALLEY MART',
  'ROCKETLAW',
  'TESO',
  'LOVE S TRAVEL PURCHASE',
  'ELECTRONICACH DEBIT ALBERT SAVINGS D EDI PYMNTS',
  'RHINO',
  'JADE GARDEN',
  'STREAMING',
  'WITHDRAWAL BRANCH OHIO',
  'PAPPADEAUX',
  'CIGNA',
  'STOP AND GO',
  'GAMEFLY MEMBERSHIP',
  'CHARTER COMM',
  'VICTORY',
  'OLLIES BARGAIN PURCHASE',
  'PETCO COM',
  'NSFOD CHARGE RET INSUFFICIENT FUNDS',
  'VZWRLSSMY VZ VE P',
  'HARTZ CHICKEN',
  'VENTRA ACCOUNT',
  'WPY GOFUNDME',
  'KELLYS CAJUN',
  'MI RANCHITO',
  'REGAL CINEMAS MOBILE AP',
  'NOAHS BAGELS',
  'GNC PURCHASE',
  'STOREF',
  'CHECK WITHDRAWAL',
  'ROUNDUP TRANSFER',
  'QDOBA MEX',
  'POS DEBIT VISA CHECK CARD USASNACK SODA VEN',
  'COFFEE',
  'ZOOMERZ',
  'PAYPAL INST XFER UBER WEB ID PAYPALSI',
  'CPS',
  'PLUCKERS',
  'WINDSTREAM PMTFEE',
  'GOVNC DMV',
  'HAPPY WOK',
  'GLORY DAYS GRILL',
  'MARGARITAS',
  'SUNPASSACC FL',
  'VESTA ATT PREPAID OR',
  'AW',
  'CARNIVAL CRUISE',
  'IMVU',
  'FOODLAND',
  'FRED M FUEL',
  'CARD SUBWAY',
  'VZWRLSS MY VZ VW P',
  'FLIGHTCLUB',
  'ACH DEBIT EMPOWER INC',
  'HARTFORD',
  'MUNICIPAL ONLINE P',
  'LEMONADE I LEMONADE LEMONADE',
  'AFTERPAY AFTERPAYCOM DE',
  'ALBERT SAVINGS WEB ID',
  'AMBETTER',
  'RUTHS CHRIS STEAK HOU',
  'HIMS HERS',
  'LOAN AT LAST DB',
  'SIGMA SOLUTIONS',
  'Z MARKET',
  'FREEWAY INSURANCE',
  'CHECKCARD OF LONDON INTERNATIONAL TRANSACTION FEE',
  'ASSURANT',
  'OSHKOSH',
  'EBAY O CA',
  'CENGAGE LEARNING INC',
  'TOCA BOCA AB',
  'FOX AND HOUND',
  'CHEGG ORDER CA',
  'BLAKE S LOTABURGER',
  'SALAD',
  'STOP MARKET',
  'TEAMFANSHOP',
  'GOOGLE MUS',
  'ADIDASAMERI',
  'ENERGY',
  'DECKERSUGG',
  'Q MART',
  'ZELLE PAYMENT TO MOM',
  'PDFCO',
  'PERFECT NAILS',
  'NINJA KIWI',
  'DICKSSPORTINGGOODSCO PA',
  'ACH WITHDRAWAL DAVE INC',
  'WEST CREEK FINANCIAL',
  'THE JOINT',
  'MANHATTAN BAGEL',
  'WILD BILLS',
  'SYNCB PHONE PAYMENT',
  'CHINESE GOURMET EXPRESS',
  'DOORDASHCHILIS GRIL WWWDOORDASH CA',
  'KWICK STOP',
  'HUMBLEBUNDLE',
  'CLEAN JUICE',
  'EXTENDED STAY',
  'GCOWALLETH',
  'LIDS LOCKER ROOM',
  'STICKER MULE',
  'CST',
  'LEOS CONEY ISLAND',
  'OTTERBOX',
  'SHIPTORDER',
  'JR CRICKETS',
  'HOLIDAY STATIONSTORE',
  'POS DEBIT VISA CHECK CARD ONLYFANSCOM',
  'ROYAL FARMS PURCHASE',
  'CASH WD',
  'BLUEWATER',
  'WALMART SUPER CENTER',
  'NAVI ED SERV WEB',
  'WIRE OUT',
  'MAGIC WOK',
  'MONTHLY SERVICE CHARGE VR',
  'ACCOUNT TO VAULT',
  'DEBIT CARD PURCHASE A APPLECOMBILL',
  'MAIN STORE',
  'HAPPY',
  'SKILLZ ESPORTS MA',
  'OPENSKY MOBILE PYM WEB ID',
  'DIGITAL',
  'VERGE CREDIT',
  'BPMAPC',
  'PIGGLY WI',
  'EARTHLINK LLC',
  'AD HOC LABS',
  'REGIONAL ACCEPT',
  'OPC TAXSERVICE FEE',
  'ABERCROMBIE FI',
  'RECURRING PAYMENT SLINGCOM CO',
  'CHINA TOWN',
  'WENDY',
  'GRUB MART',
  'SHEINCOM',
  'COX PHOENIX COMM S',
  'KRAUSZERS',
  'ABERCROMBIE FITCH',
  'VENDING PARLEVEL',
  'BPSUNSHINE',
  'POS DEBIT VISA CHECK CARD ONLY FANS UK',
  'MICHAELS STORES INC',
  'POS DEBIT VISA CHECK CARD ARCO AMPM',
  'LA CRAWFISH',
  'FIVE STAR PIZZA',
  'PIXELBERRYSTU',
  'KJS MARKET IGA',
  'FIVESTAR',
  'SAMS FOOD STORE',
  'POS DEBIT VISA CHECK CARD JOURNEYS',
  'LA CITY PARKING ME',
  'TRANSFER TO GREEN DOT PREPAID DEBIT CARD',
  'UPHOLD',
  'INSUFFICIENT FUNDS FEE FOR A PAYMENT SENT DETAILS APPLE CASH INFINITELOOP CA',
  'OTTERBOXLIFEPROOF CO',
  'CINEMA',
  'BLUEBIRD',
  'THE GREENE TURTLE',
  'FOOD BAZAAR',
  'HOTWIRE',
  'DEPOSITED ITEM RETURNED NSF ST OF ITEMSCK DEP AMT DEP DATECK AMT SVC FEE',
  'STASH FINANCIAL LLC',
  'PHILO INC HTTPSPHILOCO CA',
  'DEBIT CARD PURCHASE P SUBWAY',
  'ESURANCE CAR INSURANC',
  'GRUBHUBWAWA',
  'CPSAUTOLOANPAYMENT',
  'LUCKY STOP',
  'CLV PARKING METER',
  'INMATESALES',
  'AMI ENTERTAINMENT',
  'USA PETRO',
  'PANDA HOUSE',
  'JIMBOYS TACOS',
  'RECURRING PAYMENT MICROSOFTXBOX GAM MSBILLINFO',
  'EA ACCESS',
  'ATM CASH WITHDRAWAL A',
  'SQUARETRADE EBA',
  'INTERNATIONAL TRANSACTION FEE SHEINCOM LONDON',
  'NSF FEE ITEM RETURNED ACH DAVE INC DEBIT ACH DAVE INC DEBIT',
  'BEAUTY BEYOND',
  'TARTE INC',
  'CHECKING WITHDRAWAL',
  'EBT ACCT CHEC PURCHASE',
  'BPPETRO',
  'WITHDRAWAL ATM WITHDRAW FEE CARD',
  'NYC TAXI',
  'CURRENT DECEMBER',
  'ROKU FOR FANDANGONOW',
  'FOOD UNIV',
  'MCALISTER S',
  'PRIME VIDEO WA',
  'GREEN ARROW',
  'EASY PICKINS',
  'KRYSTAL MFS',
  'STAR BEAUTY SUPPLY',
  'VIASAT',
  'ECOMPLISH',
  'TXDPS DRIVER LICE PURCHASE',
  'COMENITY PAY OH WEB PYMT',
  'TO LOAN',
  'TOP GOLF',
  'VONS PURCHASE',
  'POS DEBIT VISA CHECK CARD CAPITAL ONE CARD P',
  'FIRE WINGS',
  'QUALITY MART',
  'DOORDASHBUFFALO WILD STRIPECOM CA',
  'JACKPOCKET',
  'VERIZONWRLSSRTCCR VE',
  'NOOM TRIAL',
  'DEBIT FOR CHECKCARD XXXXXX AFTERPAY DE',
  'LITTLE C',
  'LIFE TIME CAFE',
  'SNAPFISH US',
  'WEALTHFRONT INC',
  'DIRT CHEAP LLC',
  'AFTERS ICE CREAM',
  'KING WOK',
  'VERIZON WRLS IVR V',
  'NEW YORK PIZZA',
  'WM SUPERC WA',
  'YOUTUBE SUPER',
  'BESTCANVAS INC',
  'MICROSOFT PC',
  'SPANGLES',
  'CINEMARK MOVIE CLUB',
  'AMZN MKTP US',
  'HI ROAD',
  'RECURRING PAYMENT PAYMENT',
  'QUANY',
  'GREEN',
  'LITTLE CAESARS CA',
  'STARS',
  'SMOKERS OUTLET',
  'RUNZA',
  'MUNCHIES',
  'DRESSLILY',
  'FINANCIA',
  'CASH APPJOSEPH',
  'AM INCOME',
  'VZWRLSSBILL PAY PURCHASE',
  'LE NAILS',
  'LH TRADING',
  'ALLEGNT BUYONBOARD',
  'NYCDOT PARKNYC',
  'BPHASTY',
  'BRANCH ADVANCE REPAYMENT',
  'NON MT ATM FEE K',
  'TARGETCORP',
  'BPROAD',
  'KWIK SERV',
  'ANGIES BEAUTY SUPPLY',
  'CHECKPOINT',
  'POS DEBIT VISA CHECK CARD GIANT',
  'ROCKET LAWYER US',
  'COUNTRY MART',
  'NONCHASE ATM WITHDRAW NOR',
  'METRA MOBILE',
  'ALLY ALLY PAYMT I WEB ID',
  'TRANSFER TO NETSPEND PREPAID ACCOUNT',
  'STARDUST',
  'CICIS PIZZA PURCHASE',
  'VILLAGE PIZZA',
  'USCONNECT CRH CATE',
  'POINT OF SALE DEBIT MURPHYATWA',
  'RED STAR VAPOR',
  'CHEVRONHALLMA',
  'AAO',
  'NEW YORK COMP',
  'BUMBLE HELP BUMBLE',
  'MICROSOFT EA',
  'THE I MARKET',
  'SCOTT',
  'WEB INITIATED PAYMENT AT ALBERT GENIUS EDI PYMNTS P',
  'DISNEY M',
  'BODEGA',
  'RECURRING CHECK CARD PURCHASE APPLECOMBILL',
  'FANDUELCOM',
  'DISCOUNT LIQUORS',
  'HAAGENDAZS',
  'PDQ FL',
  'BPTTA',
  'BREEZE THRU',
  'COCINA',
  'SPACE AGE',
  'CASH APPWILLIAM',
  'CHEVRONGM OIL CO LL',
  'TRIPCOM',
  'J BUFFALO WINGS',
  'PLAYSTATIONNETWO',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON PURCHASE AUTHORI ZED ON APPLECOMBILL',
  'ADAM AND EVE',
  'DOORDASHJACK IN THE B',
  'YOUTUBE V',
  'BUSCH GARDENS FOOD SVC',
  'PROG LEASING L PURCHASE',
  'CHARLEYS PHILLY S PURCHASE',
  'COX LAS VEGAS COMM SV',
  'CASH APP PURCHASE',
  'SKRILL',
  'SELLERONLN',
  'HOULIHANS',
  'BLOOMINGDALES',
  'EDIBLE ARRANGEMEN',
  'USAUSASNACK SOD PURCHASE',
  'OAKLEY',
  'ACORNS INVESTING TRANSFER',
  'FASTSPRING',
  'CHEVRON PWI',
  'NONBOW ATM FEE',
  'BPFOUR',
  'STEAK',
  'K SPEED',
  'HIPHOP',
  'ATM WITHDRAWAL S CO',
  'PRIMARK',
  'E Z PASS VA WEB',
  'MERCHANT PAYMENT NNT MSBILLINFO MSBILLINFO WA ON FROM CARD XXXXXXXXXXXXX',
  'ALBERT GENIUS EDI PYMNTS ACH WITHDRAWAL',
  'HLUHULU U',
  'GREAT STOPS',
  'SHARE WITHDRAWAL',
  'DAIRY MART',
  'TRADER JOE S',
  'YOUTUBE T',
  'SPIRIT AIR',
  'TACO DEL MAR',
  'DEBIT FOR EARNINACTIVEHOUR PAYMENTRET CO REF',
  'TERIYAKI',
  'GOOGLE ST',
  'OD ACCOUNT TRANSFERRED FROM',
  'ORIG CO',
  'PLAT PARKING LOT',
  'NICKS',
  'GOOGLE PL',
  'J ALEXANDERS',
  'CASH APPNICHOLAS',
  'LIFE UNIFORM',
  'ZERO DEGREES',
  'EUROPEAN WAX CENTE',
  'CASH DEPOT VEND',
  'TEAM BEACHBODY',
  'FLEMINGS',
  'DOORDASHCHIPOTLE PURCHASE WWWDOORDASH',
  'NESPRESSO',
  'SWEETGREEN',
  'HILY CORP',
  'CASH APPDEE',
  'VAN HEUSEN',
  'REI',
  'DEBIT FOR CAPITAL ONE MOBILE PMT CO REF',
  'PLARIUM GLOBAL',
  'FRGNFLSHOPCOM',
  'USPSCOM MOVERS GUIDE PURCHASE',
  'DOORDASHPANDA EXP WWWDOORDASH',
  'LUIGIS PIZZA',
  'Q LUV',
  'POS DEBIT VISA CHECK CARD GAPOWER LOCAL',
  'AZ VEHICLE EMISSIONS',
  'GREAT',
  'ROMEOS PIZZA',
  'CONVEN',
  'CHEVRONTERRIBLE HERBST',
  'EARNINACTIVEH PMNT SENT VISA DIRECT',
  'JOANN FABRICS CRAFTS',
  'HOLLYWOOD HAIR',
  'MILLERS',
  'COBBLESTONE AUTO SPA',
  'TMOBILEAUTO PAY PURCHASE',
  'POS DEBIT VISA CHECK CARD EARNINACTIVEHOURS VISA DIREC',
  'LORD TAYLOR',
  'WWWTORRIDCOM',
  'LION STUDIOS',
  'ACAPULCO',
  'QUEEN NAILS',
  'CONCESSIONS',
  'CHICKEN KING',
  'EINSTEIN BAGELS',
  'COMM SERV',
  'SPDPYAMERICAN CREDIT A',
  'BPHWY',
  'RENTAPPLICATION',
  'YOUTUBEPREMIU CA',
  'BARCLAYCARD US CREDITCARD WEB ID',
  'TBKFC',
  'ORANGE JULIUS',
  'PRIDE',
  'CHECK ORDER CHARGE',
  'DOORDASHSUBWAY STRIPECOM',
  'QUADPA',
  'NON MT ATM FEE M',
  'LEVEL BANK PLUS DU HTTPSLEVELBAN CA',
  'ELECTRONICACH DEBIT SQUARE INC CASH APP T',
  'BAMBOO',
  'WINGS TO GO',
  'COUNTRY INN',
  'DEBIT CARD ADJUSTMENT',
  'KP RX',
  'LEADBANKSELFLEND PAYMENTS',
  'RECOVERY',
  'LONGHORN',
  'GONG CHA',
  'RAISING CAN',
  'SUPERLO FOODS',
  'ID LIQUOR STORE',
  'DIAMOND NAILS',
  'MATHWAY',
  'LL HAWAIIAN',
  'CHECKCARD PREMIER BANKCARD SD',
  'TO INTERNET WITHDRAWAL',
  'THE STATION',
  'FLORIDA',
  'BARTHER',
  'INSTACOINS',
  'VICTORIASSECRETC',
  'LAS MARGARITAS',
  'COA PARKING METERS',
  'CHUY S',
  'THE OLIVE',
  'LUCILLES',
  'BAKERS BURGERS',
  'LOVES COUNTRY PURCHASE',
  'BUSCH GARDENS FOOD',
  'HUDHOMESUSAORG',
  'RIVERLINK',
  'DASHPASS',
  'COMCAST XFINITY',
  'POGO ENERGY',
  'DDV DISCOVERYPLUS',
  'AMG RETAIL I LLC',
  'ROSAS CAFE TORTILLA',
  'ATM WITHDRAWAL WOOD',
  'FOOTLOCKER PURCHASE',
  'RAPID ROBERTS',
  'CURRENT FEBRUARY',
  'ATM WITHDRAWAL W BE',
  'THRIFT',
  'BUFFET CITY',
  'AUNTIE ANNES MD',
  'FANTASY PHOTO',
  'TELCASH',
  'SCHOOLCAFE',
  'LOS ARCOS',
  'DAVE BUSTERS INC',
  'DEBIT FOR CHECKCARD XXXXXX AFTERPAY',
  'BUY GO',
  'APPLECOMUS CA',
  'NIANTIC',
  'BPBULL',
  'BARKCO BARKBOX',
  'RESUMENERD',
  'ASTA PARKING',
  'PLAY STUDIOS',
  'BILO GRO',
  'FIDELITY',
  'MINI MARKET',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS BRIGIT PROTECTION PPD ID',
  'DEBIT FOR ALBERT GENIUS EDI PYMNTS CO REF P',
  'GAS USA',
  'IKEACOM',
  'PARCHMENTUNIV DOCS',
  'TUESDAY MORNIN',
  'INTERSTATE',
  'HIGHLIFES',
  'FLAGSHIP CREDIT',
  'KIDS FOOT LOCK PURCHASE',
  'SIMPLEMOBILE SERVICES',
  'UNDER ARMOUR',
  'FD CA DMV SVC CA',
  'TOUCHTUNESHELPSHI TOUCHTUNESCO',
  'R BO',
  'MAGICTAVERN',
  'DEBIT CARD FEE VISA INTERNATIONAL SERVICE ASSESSMENT DATE CARD',
  'NONCHASE ATM WITHDRAW US H',
  'BRIDGECREST',
  'JUSTIN',
  'AMORACOFFEE',
  'POS DEBIT VISA CHECK CARD EXPEDIA EXPEDIACO',
  'CAPITAL ONE NA CAPITALONE WEB ID',
  'STOP FOOD MART',
  'ASPEN',
  'FASTRAC',
  'SMOKE VAPE',
  'GRUBHUBDAIRYQUEEN',
  'LAS PALAPAS',
  'NISSAN AUTO',
  'CASH APPCHRISTINE',
  'TXGOVSERVICEFEE EGOVCOM',
  'POS DEBIT VISA CHECK CARD POPEYES',
  'AUTO AIR VACUUM SERVI PURCHASE',
  'MARCS',
  'GIGIS CUPCAKES',
  'POINT OF SALE DEBIT WM SUPERCENTER',
  'ACH TRANSACTION PAYPAL TRANSFER',
  'EXCESS WITHDRAWALTRANSFER CHARGE',
  'BLACK BEAR DI',
  'CHICKF',
  'BPI',
  'LITTLE WOODROWS',
  'WMD LLC',
  'UNCOLLECTED FUNDS CHARGE',
  'KFC C PURCHASE',
  'TOUCHTUNESHELPSH',
  'AMI ENTERTAINMENT NETWO',
  'CHECK CARD PURCHASE SUBWAY',
  'SEASONS OF JAPAN',
  'POS DEBIT VISA CHECK CARD AFTERPAY DE',
  'RULER FOO',
  'LA FIT ANNUALFEE',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS MICROSOFTXBOX MSBILLINFO WA',
  'CALIFORNIA BURRITO',
  'CHINATOWN',
  'EBAY INC PURCHASE',
  'WINGS N MORE',
  'CHECKCARD CA DMV FEE CA',
  'PLANET FITNE PURCHASE',
  'GOOGLE S',
  'APPLIANCE WAREHOUSE',
  'VBSMAXLEND',
  'ULTIMATE',
  'WAWA STORE PURCHASE',
  'TERRIBLES',
  'ATM WITHDRAWAL STAT',
  'WM DIRECT',
  'JUST ENERGY',
  'TRACTORSUPPLYCO',
  'SMART MART',
  'MANDEE',
  'RENT ONE',
  'GNC GNC PURCHASE',
  'EBT ACCT CHECK MEMPHIS TN US',
  'NONCHASE ATM WITHDRAW N M',
  'EGGS UP GRILL',
  'BREAK TIM',
  'DIRECT DEBIT OPENSKY MOBILE PYM',
  'LEVELUPSMOOTHIEKIN',
  'MARKETPLACE',
  'AFI ALLY PAYMT WEB ID',
  'VOODOO DOUGHNUT',
  'INTERNATIONAL TRANSACTION FEE MBIPROBILLERCOM',
  'TROPICAL SM',
  'FRYSFOODDRG PURCHASE',
  'AVAS FLOWERS',
  'CHICME',
  'SPEEDY EXPRESS',
  'EARTH FARE',
  'USA CANTEEN VENDI',
  'THE JUICY SEAFOOD',
  'LOVE BOOK',
  'ACH TRANSACTION DIGITCO RAINY DAY',
  'MCDONALD S F',
  'CASH APPANGEL',
  'PENSKE TRK LSG',
  'CFP CAP ONE AUTO LOAN',
  'BIG SMOKE',
  'CHINA INN',
  'ALBERT INSTANT EDI PYMNTS I',
  'CONSUMERS ENERGY CO',
  'NC COURT PAYMENTS',
  'MUNICIPAL ONLINE PAYMEN',
  'QUADPAY QUADPA',
  'ESSENCE BEAUTY SUPPLY',
  'DOORDASHCHILIS GR WWWDOORDASH',
  'FANTASY PHOTO BOOTH',
  'MCCMEDIACOM',
  'HAWAII',
  'SMART COMMUNICATIONS',
  'ATM WITHDRAWAL USAACATM',
  'PAPAYA CLOTHING',
  'TSS',
  'KEEPS',
  'KABUKI',
  'THINK GEEK',
  'SOCIALPOINT',
  'FORTIVA',
  'FRED LOYA INSURANCE',
  'WU SANTANDER CNSMR US',
  'VIP NAILS',
  'CASH APPBRIANNA',
  'FARMERS INS',
  'ROSS STORES MOBILE PURCHASE',
  'SHELL SERVICE S',
  'FRISCHS BIG B',
  'FAIRFIELD INN SU',
  'ELF COSMETICS',
  'ARCTIC CIRCLE',
  'ATM CASH WITHDRAWAL',
  'SHOE STATION',
  'QT PHOENIX AZ',
  'AUTO AIR VACUUM S PURCHASE',
  'HAIR PLUS',
  'PROGRESSIVE LEASING UT',
  'VALLARTA VFE',
  'PAPAYA',
  'VINTAGE STOCK',
  'FALCON FUELS',
  'GEORGIA PWR PURCHASE',
  'RECURRING PAYMENT BILL PAYMENT WWWATTCOM',
  'SEI PURCHASE',
  'BPRICKE',
  'SPECTRUM MO',
  'KLOVER APP BOOST CHICAGO IL US',
  'MOBILOANS LLC',
  'AARONS SALO C PURCHASE',
  'HYRECAR',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS CAPITAL ONE MOBILE PMT WEB ID',
  'NTTA CALL CENTER',
  'EVENT TICKET INSURANCE',
  'GW SERVICE FEE',
  'DOORDASHTHE CHEESECAK',
  'BUFFALOSTUDIOS',
  'FUNDS TRANSFER RETURN',
  'RECURRING PAYMENT TMOBILEAUTO PAY',
  'CITGO FOOD MAR',
  'POS DEBIT VISA CHECK CARD AT T PAYMENT',
  'TO THE BANCORP BANK',
  'MOES PURCHASE',
  'CCBILLCOM ONLYFANS CA',
  'SHOP MISS A',
  'UBERAT EATS',
  'BOOKING',
  'RIOT GAMES INC',
  'APPLE ONLINE STOR CA',
  'TMOBILEPOSTPAID TEL PURCHASE',
  'ACH ELECTRONIC DEBIT EARNIN ACTIVE PAYMENTRET',
  'GREAT STEAK',
  'WITHDRAWAL COURTESY PAY FEE',
  'CANARY',
  'JUST WI',
  'PA DRIVER VEHICL',
  'BPTHE',
  'LITTLE ITALY',
  'RIOTGAM',
  'CURRENT JANUARY',
  'CDONALDS F MOBILE PURCHASE',
  'NSF FEE ITEM RETURNED CHK CHK',
  'EMEALS',
  'ULTIMAT',
  'SAVE A LOT',
  'VIVINT INCUS',
  'NONCHASE ATM WITHDRAW CENT',
  'ML PLUS MEMBRSHP MONEYLION ML',
  'GOODCENTS SUBS',
  'CONTINENTAL CANTEEN',
  'TRANSACTION FEE',
  'SURCHARGE FEETNSSMART',
  'PUERTO VALLARTA',
  'FRGFANATICSCOM',
  'FREDDYS FROZEN CUSTARD',
  'NICOLE',
  'NONCHASE ATM WITHDRAW TH A',
  'RUSTY TACO',
  'BPSCOTC',
  'ACH WEBSINGLE CREDIT ONE BANK PAYMENT',
  'NOTHING BUND',
  'RALLY S',
  'UBREAKIFIX',
  'FIGS INC',
  'LEES FAMOUS RECIPE CHI',
  'WWWCVSCOM PURCHASE IN',
  'HCTRA EZ TAG ONLINE',
  'CASH APP CA US',
  'SFM',
  'ARCHIVES',
  'SUB STATION II',
  'SIXFLAGSENT',
  'MILLER BARBQ',
  'CAPITAL ONE CREDIT CARD',
  'DOUBLE GOOD POPCORN',
  'DASHPAS',
  'PHOTOBOOTH VEND',
  'CENTRE',
  'HAPPY SHOPPER',
  'THE PILL CLUB',
  'DICKS SPORTING GOO',
  'EDI PYMNTS P ALBERT GENI',
  'TERIYAKI EXPRESS',
  'CARFAX',
  'CHEAPTIX',
  'CLOTHES MENTOR',
  'POINT OF SALE DEBIT L DATE SPIDUKEENERGY',
  'ULTIMATE M',
  'EARTHBOUND TRADING CO',
  'YOGIS GRILL',
  'INTUIT PAYMEI PURCHASE',
  'SIERRA',
  'COMENITY BANK OH',
  'CINCO DE MAYO',
  'CASH APP VISA TRANSFER',
  'QUICK TRIP',
  'STEWARTS',
  'ATM WITHDRAWAL PLUS MCI',
  'NORTH',
  'CHECKERS DRIVE IN',
  'PARKINDY LLC',
  'CITI CARD ONLINE PAYMENT',
  'TIDAL WAVE AUTO',
  'FIREHOUSE S',
  'CHECKCARD MICROSOFTULTI MSBILLINFO WA RECURRING',
  'ATM WITHDRAWAL W NO',
  'CRO INTERNET MLT',
  'GOODWILL IND',
  'CENGAGE LEARNING',
  'CASH APPANGELA',
  'HERFF JONES',
  'ADJUSTMENT FOR DUPLICATE ITEM',
  'OTLSCORESENSECOM',
  'SQ PURCHASE',
  'HOP IN',
  'BARGAIN',
  'POS PURCHASE POS SPEEDWAY',
  'POS DEBIT VISA CHECK CARD CHIPOTLE',
  'SHOE DEPT PURCHASE',
  'MICROSOFT EA ACCESS M',
  'NONCHASE ATM WITHDRAW RD',
  'FRYSFUEL',
  'PHO SAIGON',
  'GIOVANNIS',
  'SHORE',
  'THE HOME D',
  'ARLINGTON',
  'PIZZA PLUS',
  'SARPINOS PIZZERIA',
  'RUTTERS FARM',
  'BROOKSHIRE BROTHERS',
  'AUTO AIR VACU',
  'CFK PEOPLEFINDERS',
  'STARZ ENTERTAINMENT CO',
  'PURCHASING POWER',
  'ASTRO',
  'AMERICAN FIRST FINANCE',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS PROGRESSIVELEASE PMTS PPD ID',
  'MICROSOFTEA ACCESS M',
  'SALLIE MAE',
  'WEST CREEK',
  'RECURRING PURCHASE AT HLUHULU XXXXX HULUCOMBILL CA ON FROM CARD XXXXXXXXXXXX',
  'JARED',
  'FAT BURGER',
  'MOES OLO',
  'CAREOF',
  'KARTASOVS',
  'CHEVRONPWI',
  'WELLS',
  'FIESTA MEXICANA',
  'ATM WITHDRAWAL PLUS REGIONS',
  'PLAYRIX GAM',
  'ACH TRANSACTION SELF LENDER INC PAYMENTS',
  'ENVATO',
  'K BEAUTY SUPPLY',
  'VILLA PIZZA',
  'HUMAN BEAN',
  'CASH APPMIKE',
  'PROACTIV',
  'EINSTEIN',
  'JIMMYJAZZ',
  'MRBEAST BURGE',
  'CENEX',
  'GRUB BURGER BAR',
  'RECURRING PAYMENT QVC',
  'PIZZA FACTORY',
  'NH LIQUOR STORE',
  'ONLINE TRANSFER',
  'PURPLE HAZE',
  'FAMILY FA',
  'NAVIENT PMT SPE',
  'POINT OF SALE DEBIT L TIME PM DATE BPCIRCL',
  'MOVINGHELP',
  'PORTILLOS HOT DOG',
  'ATM WITHDRAWAL SOUT',
  'FUBOTV',
  'ALIEXPRESS PURCHASE',
  'DOORDASHMCDONA',
  'WHYNOTLEASEIT',
  'BREWING',
  'ELECTRONIC WITHDRAWAL PAYPAL',
  'ACH WEBSINGLE XXXXX CAPITAL ONE MOBILE PMT',
  'MICROSO',
  'ENTERTAINM',
  'TAL INDIANAPOLIS',
  'BACK YARD BURGERS',
  'AAA MIDATLANTIC',
  'ROYALFARMS',
  'JACK I',
  'TOTAL WINE M',
  'YUM YUM DONUTS',
  'PORTFOLIO RECOVERY',
  'OPPFINANC',
  'ROAR MONEY TRANSFER',
  'EXPERIAN CREDI',
  'ORKIN LLC',
  'METRO MART',
  'FEDERAL TAX WITHHOLDING',
  'BULK MARATHON',
  'STREE',
  'BAR AND GRILL',
  'RECURRING PAYMENT STRAIGHTTALKSERVI',
  'CHEAPER THAN DIRT',
  'PEOPLEFINDERS',
  'BETMGM PLAY',
  'RA SUSHI',
  'FLOATME DB ONLINE PMT',
  'TFITICKETFLY EVENTS CA',
  'PICK UP STIX',
  'ACH TRANSACTION SYNCHRONY BANK CC PYMT',
  'CKOPATREON MEMBERSHI CA',
  'MICROSOFTEA ACCESS MSBILLINFO WA',
  'DDBRIGITCOMMEMBERSHIP',
  'DESTINATION XL',
  'HONEY',
  'GRILLE',
  'NOODLES C',
  'CRACKER',
  'GATE PURCHASE',
  'WHATABURG',
  'NONCHASE ATM WITHDRAW S C',
  'VEGAS',
  'POINT OF SALE DEBIT L DATE ALBERT INSTANT',
  'DEBIT CARD REPLACEMENT',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS SPRINT WIRELESS KS',
  'MBIPROBILLERCOM PURCHASE',
  'SUNRISE',
  'WIRE TRANS SVC CHARGE SEQUENCE SRF OW TRN RFB OW',
  'TAMS BURGERS',
  'RUTHS CHRIS STEAK',
  'FLORIDA POWER LI',
  'CARD CLAIM REVERSAL OF PROVISIONAL CREDIT',
  'SUNNY FOOD MART',
  'TACOJOHNS',
  'NEWBURY',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS POSSIBLE FINANCE DEBIT WEB ID',
  'ACH TRANSACTION DISCOVER EPAYMENT',
  'POS DEBIT VISA CHECK CARD MBIPROBILLERCOM',
  'ZOCALOANS DB',
  'POSTMATES MEMBERS',
  'COUNTY',
  'THE HOME DE',
  'QUICKLY',
  'JIMMYS',
  'ADMIRAL PETROLEU',
  'KRISPY KR',
  'FC INS',
  'K AFFILIATED MED GROUP',
  'ITS TAX',
  'POS DEBIT VISA CHECK CARD CASH APPBRITTANY',
  'TMOBILEPOSTPAID TEL',
  'RECURRING PAYMENT YOUTUBEPRE',
  'TX DPS DL OFFICE PURCHASE',
  'RECURRING PURCHASE AT DAVECOM CA ON FROM CARD XXXXXXXXXXXX',
  'FRYS FOOD DRG',
  'GRUBHUBHOOTERS',
  'FEE RETURNED ITEM AT',
  'TORY BURCH LLC',
  'FCE',
  'SEEDFI',
  'CLEAN FREAK',
  'FOOD PLUS',
  'GOFUNDME PURCHASE',
  'CENTS',
  'POS DEBIT VISA CHECK CARD EXPERIAN CREDIT R',
  'GAMELOFT SE',
  'BEENVERIFIEDTRIAL',
  'COSMO BEAUTY',
  'BARNESNOBLECOMBN',
  'ACE CASH EXPRESS INC',
  'CASH APPGABRIELLE',
  'TRANSFER TO ACCT',
  'FD CA DMV SV',
  'GREAT CHINA',
  'LAVEDERLING',
  'MR GYROS',
  'IT WORKS MARKETING',
  'ATM WITHDRAWAL W CE',
  'GOODWILL RETAIL',
  'PL PEOPLELOOKERTRIAL',
  'BUFFAL',
  'SUNPASSACC PURCHASE',
  'FUEL',
  'ROBERT',
  'CHESAPEAKE',
  'TIKTOK LIVE',
  'EBAYINCSHI',
  'WU GMFINAN',
  'ONCUE EXPRESS',
  'VILLA ITALIAN KIT',
  'PURE R LLC',
  'PRESSED JUICERY',
  'SPIRIT AI PURCHASE',
  'HAGGEN',
  'TELLER CASHED',
  'APPLIED BANK PMT',
  'EL MAGUEY',
  'GRUBHUBAPPLEBEESGRILL',
  'ASURIONWIRELESS INSURA TN',
  'WELLS FARGO BAN',
  'OLD COUNTRY BUFFET',
  'CIRCLE S MART',
  'FIELD',
  'VANSCOM',
  'GRABNGO',
  'MTA NYCT PAYGO RECOVER',
  'ZARAUSA',
  'BSN SPORTS LLC',
  'CREDITNINJA PMT PAYMENT PPD ID',
  'SAKS FIFTH AVE',
  'ECHECK',
  'MOE S',
  'MISC DEBIT ADJUSTMENT ON CARD CLAIM',
  'SURCHARGE FEEELEVENFCTI',
  'CHECK PROCESSING FEE REF',
  'TMOBILE TEL UPGRADE',
  'ALBERT INVESTMEN ACH WEB ID',
  'KOKEE TEA',
  'BPGRAND',
  'INSTACASH PAYMEN',
  'QUICK QUACK',
  'OLB TRF FROM SV TO CK WITHDRAWAL',
  'FBV STUDIO MOVIE GRI',
  'ACH TRANSACTION CREDIT KARMA TRANSFER',
  'HOTEL',
  'SSENSE',
  'DINER',
  'G GOAT HTTPSWWWGOAT CA',
  'SILVER DINER',
  'LARRYS GIANT SUBS',
  'TD AMERITRADE',
  'ROBINHOOD FUNDS WEB ID',
  'P TERRYS BURGER STAND',
  'PRIMANTI BROS',
  'COLLEGE TRANSCRIPT IL',
  'GRUBHUBJERSEYMIKES',
  'RM STORE',
  'WEB INITIATED PAYMENT AT ALBERT GENIUS EDI PYMNTS PAYMENT',
  'TORY BURCH',
  'MISS A STORES',
  'QUICK CHEK',
  'BATHBODYWO',
  'VERIZON WRL MY AC PURCHASE',
  'IRS USATAXPYMT PPD ID',
  'HLUHULU HULUCOM',
  'SPRING',
  'NIKECOM PURCHASE OR',
  'KINNEY DRUGS',
  'NIKEPOS US',
  'POTATO CORNER',
  'QVCOF PA',
  'HICKORY TAVERN',
  'POS EXA WM SUPERCENTER',
  'FEEL BEAUTY',
  'BAR',
  'FIVE STAR FOOD VEND',
  'PCHOPPER',
  'DICKS CLOTHIN PURCHASE',
  'CPRNATIONALCPR',
  'POS DEBIT VISA CHECK CARD STAPLES',
  'RED MANGO',
  'CHECK NUMBER',
  'CONTACTS IN',
  'AEP ONLINE',
  'WEB XFER TO CHK',
  'RAINBOWSHOPSCOM',
  'TMOBILEAUTO PAY',
  'OVERDRAFT ITEM FEE FOR ITEM APPLECOMBILL',
  'DISCOUNT TIRE CO',
  'IT S BOBA TIME',
  'CHEVRON TERRIBLE HERBST',
  'CASH APPCHRISTIAN CA',
  'DEBIT FOR EARNINACTIVEHOUR PAYMENTRET CO REF XXXXX',
  'PRETTYLITTER',
  'BRIGIT NY',
  'WINGS PLUS',
  'MILITARY',
  'POS DEBIT VISA CHECK CARD PACSUN',
  'PLAYRIX',
  'TASTE',
  'CONTACTS SUBSCRIPT',
  'AFNI INC',
  'MONEYLION LOAN PAYMEN',
  'SL PRIME',
  'ATM WITHDRAWAL WHIT',
  'PRETTY NAILS',
  'ATM WITHDRAWAL PLUS L',
  'STOP A MINIT',
  'ORDER WISH',
  'RECREATIONGOV',
  'ADTSECURITY MYADT',
  'DUNKIN BASK',
  'SAVE THE CHILDREN',
  'LEGENDS',
  'AARONS ONLINE PAY',
  'US NAILS',
  'CRATE BARREL',
  'EXTERNAL TRANSFER FEE DAY CONFIRMATION',
  'CELL TOUCH',
  'POS DEBIT VISA CHECK CARD VERIZONWRLSSRTCCR',
  'LUNA GRILL',
  'HARDEE S',
  'MAKEOVERGAME',
  'TRANSFER TO NAVY FEDERAL CREDIT UNION',
  'JUMP START',
  'KRYSTAL SAV',
  'BAD DADDYS BURGER',
  'MONEYLION NY',
  'WEB INITIATED PAYMENT AT EARNINACTIVEHOUR PAYMENTRET XXXXX',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS INSTACASH REPAYMENT NY',
  'IM PRO MAKEUP NY',
  'BUSHS',
  'EL NOPAL',
  'NON MT ATM FEE I',
  'CASH APPCHRIST',
  'ACH ELECTRONIC DEBIT SQUARE INC CASH APP',
  'WAYBACK BURGE',
  'YETI COOLERS',
  'SELECT MANAGEMENT',
  'CARRABBAS IT',
  'ANGRY CRAB SHACK',
  'FOOD UNIVERSE',
  'SMULE INC',
  'CHEEK BOSS',
  'NON MT ATM FEE L',
  'LUKE FUEL STATION',
  'FIRSTENERGY OPCO FE ECHECK',
  'WELLS FARGO',
  'Q LINK WIRELESS LLC',
  'ATM WITHDRAWAL PLUS CAPITAL ONE',
  'PERKINS RESTAU',
  'FL DL TAG GO RENEW',
  'INTERNATIONAL',
  'POS MAC DOLLAR TR',
  'POS DEBIT VISA CHECK CARD TROPICAL SMOOTHIE',
  'CHECK CARD CASH ADV PNCATA',
  'FLAMING WOK',
  'MICROTEL INN SUITES',
  'CASH APPDIAMOND',
  'GREAT CLIPS A',
  'CREAMISTRY',
  'ELEGANT NAILS',
  'EARNINACTIVEHOUR PURCHASE',
  'AMERIPARK',
  'UBR PENDINGUBERCOM CA',
  'POTBELLY SAND',
  'DBA UNITED PACIF',
  'EASYPAY FINANCE',
  'PAYUSATAX CONV FEE',
  'POS DEBIT VISA CHECK CARD HEB',
  'KONAMI',
  'BPRUSHC',
  'FINGERHUT FRES',
  'VISA DIRECT NY VEN',
  'ATOM TICKETS LLC CA',
  'BRANDON',
  'CHURCHS C',
  'LIQUOR LAND',
  'PRETTYLITTLETHING',
  'ACH USAA PC INT RETRY PYM',
  'QUEENS BEAUTY SUPPLY',
  'KLOVER APP BOO PURCHASE',
  'ONE STOP MARKET',
  'CASA OLE',
  'PGE EZPAY',
  'VERIFYBANK EARNINACTIVEHOUR',
  'GASNGO',
  'CITIZENS',
  'BOXLUNCHGIVES',
  'THE SHACK',
  'SPACE AGE NO',
  'VBS NATLSMLLOAN',
  'RISE DE II DB',
  'FAZOLI S',
  'ATM WITHDRAWAL PLUS C',
  'RAINBOWPICK',
  'VILLA FRESH ITALI',
  'PALM BEACH TAN',
  'ACH TRANSACTION USAACOM PAY EXT P C',
  'SOUNDCLOUD',
  'THECHILDRENSPLACECOM NJ',
  'CHEVRON STAR STOP',
  'VILLAGE LIQUOR',
  'BAYMONT INN AND SUITES',
  'KINGS BEAUTY',
  'CRACKER BAR',
  'USAMINUTE KEY IN BOULDER',
  'TILLYS PURCHASE',
  'LAROSAS',
  'DOORDASHCHIPOTLE PURCHASE STRIPECOM',
  'JET FOOD STORES',
  'CASH APPERICA',
  'MEUNDIES',
  'FIRESTONE PURCHASE',
  'DIRECT DEBIT BRIGITCOM MEMBERSHIP',
  'FREEDOM',
  'ROBINHOOD FUNDS PPD ID',
  'QVCOF',
  'PEOPLEFUN',
  'BPPARK',
  'PRINCESS',
  'KICKSTARTER',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS ALBERT INSTANT WALNUT CA',
  'FRESH DONUTS',
  'BPUNI',
  'EL RANCHITO',
  'APPLEBEES NEI',
  'OFFERUP INC',
  'MADEWELL',
  'HORSE',
  'SRVTSCOM',
  'BISTRO',
  'SAN ANTONIO TX FLO',
  'GINOS PIZZA',
  'PALACE',
  'SPECS PURCHASE',
  'CUSTOMINK LLC',
  'TCGPLAYER',
  'MIKES PIZZA',
  'ATM WITHDRAWAL W ST',
  'TOMMY',
  'DELTA AIR',
  'SEE TICKETS',
  'EPC EPIC GAMES STORE',
  'KWIK KAR',
  'LEADBANKSELFLEND CC PMT',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS EARNIN PAYMENTRET WEB ID',
  'POINT OF SALE DEBIT L TIME PM DATE LOWE S',
  'AMERICAN HEART',
  'DIRECTVAPOR',
  'INTEREST ON CASH ADVANCES',
  'SHIPT MONTHLY',
  'BARNESNOB',
  'SPRINT RETAIL PURCHASE',
  'PARADIES',
  'BUCEE S',
  'CASH STORE TX',
  'CURRENT MARCH',
  'LUBYS',
  'LUCKY FOOD MART',
  'AT ALBERT INSTANT',
  'LEES SANDWICHES',
  'EQT AMBETTER',
  'SNACK SODA VENDI',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS BRIGIT DEBIT WEB ID',
  'OPCNONFIN SERVICE FE NE',
  'BANFIELD WELLNESS',
  'AUDI',
  'SPIRITS',
  'FIRST STOP',
  'DAIRY QU',
  'DATAVERSE',
  'POS PURCHASE DAVE INC SOUTH COCHRAN LOS ANGELES CA USCARD NBR POS WITHDRAWAL',
  'USA VENDING',
  'JEWELRY',
  'SOCIAL',
  'EXPERIAN CREDIT REPORT PURCHASE',
  'ROYAL BUFFET',
  'MACYS ONLINE PMT WEB ID CITICTP',
  'JOHNNY CARINOS',
  'CITY NAILS',
  'POS DEBIT VISA CHECK CARD SS BOOKING FEE STYLESEATCOM',
  'DIRECT DEBIT LEADBANKSELFLEND CC PMT',
  'SURCHARGE FEEALI',
  'CASH APPTYLER',
  'AVANT',
  'CHURCHSCHICKEN',
  'CASH APPMARCUS',
  'UBER CASH',
  'BYTE SHOP',
  'DEBIT CARD PURCHASE A CASH APP',
  'BOOKOFMONTH',
  'GOOGLE GOOGLE S',
  'STOP FOOD',
  'ACH TRANSACTION CAPITAL ONE AUTO CARPAY',
  'VERIZON PAYMENTREC',
  'NONCHASE ATM WITHDRAW W S',
  'UNOCHICAGOGRILL',
  'ROKU FOR DISNEY ELECTRONI',
  'HOMEBASE',
  'RHODES',
  'RECURRING DEBIT PURCHASE CARD HLUHULU HULUCOMBILCA',
  'THECHILDRENSPLACECOM',
  'ACH BRIGITCOM PROTECTIO',
  'RETRY',
  'MICROSOFTXBOX LI PURCHASE MSBILLINFO',
  'KFC D PURCHASE',
  'FARFETCH',
  'RECURRING PAYMENT AFTERPAY US',
  'DICKSSPORTINGGOO',
  'VANSCOM PSHOPVANS CA',
  'KRYSTAL NSH',
  'HUGHESNET',
  'VOLARIS',
  'LOGANS ROADHO',
  'US DEPT OF EDU DB',
  'HAIR TOWN',
  'REXEL',
  'US TREASURY TAX',
  'PLUSHCARE',
  'DEPOSIT',
  'CURRENT MARCH SUBS',
  'THE SMOKE SHOP',
  'VICTORIA ISLA',
  'AMERICANFIRSTFIN PPD ID',
  'CHINA MOON',
  'SPLASH N DASH',
  'SPEEDPAY DUKE PURCHASE',
  'GOLDEN BEAUTY SUPPLY',
  'CHICAGO',
  'WHITECASTLE',
  'POS DEBIT VISA CHECK CARD CVS PHARMACY',
  'SCHOLASTIC BOOK FAIRS R',
  'BEACHBODY FITNESS',
  'CUMBERLAND FAR',
  'WA DOL LIC REG',
  'IGA EXPRESS',
  'OSAKA',
  'VZWRLSSMY VZ VE P FL',
  'QUICK WAY',
  'PANERA B',
  'VONS STORE PURCHASE',
  'ACH TRANSACTION COMENITY PAY VI WEB PYMT',
  'THE BURGER DE',
  'EMPOWER INC XXXXXX EMPSUBSCR',
  'FAMOUS RECIPE',
  'ONLINE TRANSFER TO SAVINGS',
  'THE GARAGE',
  'BRUNSWICK',
  'DEBIT DAVE INC WEB',
  'EARNINACTIVEHOURVERIFYBANK',
  'NYCDOT PARKING METERS LONG IS CITY NY',
  'JET PEP',
  'ENEBA',
  'NV ENERGYSPEEDPAY',
  'GAS GO',
  'TRANSFER TO CITI SAVINGS A ONLINE REFERENCE',
  'DEPOSITED ITEM RETURNED ALTEREDFICTITI OF ITEMSCK DEP AMT DEP DATECK AMT',
  'SCRHUG',
  'DENNYS ONLI',
  'CONNECTECH STOREF',
  'NORTHWEST',
  'POSSIBLE FINANCEPAYMENT',
  'RENT A CENTER ECOMMER TX',
  'WOODMANS FOOD MARKET',
  'HSNHSN',
  'APCO BILLMATRIX',
  'FINISH LINE PURCHASE',
  'PPL TEAM BEACHBODY',
  'BC TEL',
  'AZAZIE INC',
  'PEAK',
  'VICTORIASSECRETCOM PURCHASE',
  'COVID TEMPORARY CREDIT RECOVERY',
  'MOBILE BANKING PAYMENT TO CREDIT CARD',
  'NEEDMYTRANSCRIPT',
  'SMOKE ZONE',
  'UNCOLLECTED PROTECTION WD',
  'STEAK N SHAKE PURCHASE',
  'CROWN',
  'OPENSKY MOBILE PYM',
  'WONDERSHARE',
  'CHEVRON HALLMA',
  'STASH CAPITAL S ACH',
  'SWIFTY CAR WASH',
  'EL CHARRO',
  'DBT PURCHASE CASH APP CA',
  'FRIENDSHIP',
  'OLD CHICAGO',
  'VZWRLSSMY VZ VN P FL',
  'FUNCTION OF BEAUTY',
  'PARIS BAGUETTE',
  'EBAY CA',
  'DOORDASH DASHPASS WWWDOORDASHCA',
  'WWWADVANCEAUTOPAR',
  'ATM SERVICE CHARGE',
  'AVENUE C SECURE PAY TROY MI',
  'PROPERTY PAYMENT RENT CA',
  'ATM WITHDRAWAL US H',
  'EXTENDED OVERDRAFT FEE DAYS SINCE',
  'DIRECT DEBIT CAPITAL ONE AUTO CARPAY',
  'WITHDRAWAL TRANSFER FEE',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS SXMSIRIUSXMCOMACCT NY',
  'USPS KIOSK PURCHASE',
  'JACK HTTPSPOSTMATE',
  'PORTFOLIO RECOV ASSOC',
  'EDI PYMNTS PAYMENT ALBER',
  'ACH TRANSACTION ACORNS INVEST TRANSFER',
  'BASKINRO',
  'EL TACO TOTE',
  'ZIPLINE',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS INSTACASH PAYMEN MONEYLION PPD ID',
  'ATM WITHDRAWAL OLD',
  'NICOR GAS BILL',
  'PROG PALOVERDE',
  'ATM WITHDRAWAL CENT',
  'FIRST NATIONAL',
  'MIXTILES',
  'AL',
  'ROYAL LIQUOR',
  'MARATHON FOOD MART',
  'BEAUTY MASTER',
  'MIHOYO LIMITED',
  'POS DEBIT VISA CHECK CARD ROSS STORE',
  'TWITCHTV SUB SRVCS',
  'FPL DIRECT DEBIT ELEC PYMT WEBI WEB ID',
  'SOS AUTO',
  'MONEYLION LOAN REPAYMEN',
  'EL MARIACHI',
  'STORMING CRAB',
  'BARBER',
  'TMP TEMPOE',
  'FIREBIRDS',
  'FASTERPAY',
  'ROUSES MA',
  'TICKETSATWORKCOM',
  'GOLF GALAXY',
  'DEBIT PURCHASE CARD HM',
  'LE PAIN QUOTIDIEN',
  'LUKE',
  'SALS PIZZA',
  'PLAY GCOHELPPAY CA',
  'ELECTRONIC PMTWEB DAVE INC DEBIT',
  'AAL TEL',
  'FACEBO',
  'INTERNAL FUNDS TFER',
  'ATM WITHDRAWAL LAK',
  'POSTMATES MEMBERSHIP HTTPSPOSTMATECA',
  'PAYPAL INST XFER ACH WITHDRAWAL',
  'CASH APPCHARLES',
  'SAVEALOT PURCHASE',
  'TV NOW',
  'SCRIBD',
  'SEAWORLD PARKSENT SEA',
  'POS DEBIT VISA CHECK CARD TGI FRIDAYS',
  'A LIQUOR',
  'PRIORITY CHECK',
  'LA QUINTA',
  'WHEELS RIDE',
  'COACHOUTLETCO',
  'USACANTEEN VEND',
  'SO CAL EDISON CO BILL PAYMT WEB ID',
  'PARCHMENTUNIV DOC',
  'DEBIT FOR CHECKCARD XXXXXX APPLECOMBILL',
  'COUNTRY INN AND SUITES',
  'LITTLE CAESARS TX',
  'LINDT CHOCOLATE',
  'DEBIT FOR CHECKCARD XXXXXX KLARNA PAYMENTS',
  'SALOON',
  'WITHDRAWAL HOME BANKING TRANSFER TO LOAN INTERNET ACCESS',
  'CHEVRONJACKSONS FOOD S',
  'PLAY INTERNET',
  'PLAY GCOHELPPAY',
  'VICTORIA S SECRET',
  'ONLINE TRANSFER TO GMR TRANSACTION',
  'MIDWAY',
  'PELICANS',
  'SS CARD PACKAGE',
  'BACK YARD BURGER',
  'ACH TRANSACTION PROGRESSIVELEASE PMTS',
  'NSFOD CHARGE PAID PAID BY OVERDRAFT PROTECTION',
  'HUNGRY HOWIES PURCHASE',
  'XFINITY WIFI PA',
  'ATL WINGS',
  'CHANGE POINT LAUND',
  'HARD ROCK',
  'SCRIBBLES SOFTWARE',
  'COMED PAYMENT',
  'GOLDEN BEAUTY',
  'SQUAREUP',
  'DOCTOR ON DEMAND',
  'FREDDYS F',
  'OVERDRAFT PROTECTION TRANSFER',
  'FUNDS TRANSFERDEBIT',
  'SEASONS PIZZA',
  'CHILIS ECOM PURCHASE',
  'DOORDASHZAXBYS',
  'HB XFR WD TOSHARE',
  'BH PHOTO',
  'JPAY',
  'NJT MOBILE',
  'AUNTIE ANNES NY',
  'EMPIRESTORE',
  'OPC WESTLAKE FINANCIAL',
  'BPCK',
  'HARPERCOLLI',
  'POS DEBIT VISA CHECK CARD USAA INSURANCE PYM',
  'VZWRLSS PREPAID PY',
  'KANGAROO EXPRE',
  'MAIN MOON',
  'VICTORIA',
  'DEPOP',
  'BEALLS FL',
  'SMART STOP',
  'SCHOLASTIC BOOK FAIRS',
  'JUICY SEAFOOD',
  'IVS VENDING MASSAGE',
  'MART FAMILY MOBILE',
  'POS DEBIT VISA CHECK CARD DEL TACO',
  'MICHIGANLOTTERY',
  'NAVER WEBTOON',
  'MEGA SAVER',
  'AWLPEARSON EDUCAT PRSONCSCOM',
  'COSMOPRO',
  'BUSY BEE',
  'MANCHESTER',
  'S PIZZA',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS OPPFINANC PAYMENT PPD ID',
  'THE CASH STORE',
  'THE HUMAN BEAN',
  'THIGHSTOP',
  'SCHWAB BROKERAGE',
  'FAST LANE',
  'GTLINMATE PHONE SVC VA',
  'MICROSOFTXBOX MOBILE PURCHASE MSBILLINFO',
  'INSUR',
  'CASH APPJASON',
  'THE GREAT COOKIE',
  'PAR MAR',
  'DISCOVER RETRY PYMT',
  'PARCEL PENDING',
  'OSH KOSH',
  'SUPER MART',
  'SY PET SUPPLIES',
  'GENESIS',
  'PEPPERJAX GRILL',
  'MARK VEND CO',
  'MARIOS PIZZA',
  'VERIFYBANK EARNINACTIVE',
  'PELIPOST',
  'LIGHTINTHEBOX',
  'PIADA ITALIAN',
  'WESTERN DENTAL',
  'TACO STAR',
  'BACKMARKETUSMP',
  'UNITED OIL PURCHASE',
  'INTOXALOCK',
  'DIRT CHEA',
  'MART',
  'ROKU FOR WARNERMEDIA G',
  'WWWHOTTOPICCOM',
  'PAPA J',
  'PURCHASE AUTHORIZED ON ROARMONEY TRANSFER NEW YORK NY S CARD',
  'POS DEBIT VISA CHECK CARD MICROSOFTULTIMATE MSBILLINF',
  'DIRECT SC',
  'HUNAN WOK',
  'CASH APPMELISSA',
  'KINDLE SVCS',
  'AMAZON KINDLE UNLIMITED',
  'POS DEBIT VISA CHECK CARD BPCIRCL',
  'SO CAL GAS PAID SCGC WEB ID',
  'LONG JOHN SILVERS',
  'CHINA RESTAURANT',
  'AUSTIN',
  'CHINA CITY',
  'INTUIT INC',
  'TRAVELERS PERS INS',
  'NONCHASE ATM WITHDRAW WASH',
  'BERTUCCIS',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS PAYPAL INST XFER UBER WEB ID PAYPALSI',
  'LEADBANKSELFLEND',
  'POS DEBIT VISA CHECK CARD USACANTEEN VENDIN',
  'US DEPT OF EDU DBE G DC',
  'ACH DEBIT BRANCH MESSENGER BRANCH MES',
  'FAST FOOD',
  'CINNAHOLIC',
  'VIACOM',
  'CURRENT AUGUST',
  'CENEX CUBBY S',
  'AND',
  'DOVLY',
  'TEACHERSPAYTEACHERS',
  'CROSSROADS IGA',
  'BEENVERIFIEDC',
  'POS DEBIT VISA CHECK CARD CK',
  'TASKER ON TASKRABBIT',
  'DISBURSED',
  'LEES DISCOUNT LIQUOR',
  'DIRECT DEBIT DAVE INC',
  'POINT OF SALE DEBIT L TIME PM DATE MURPHY EXPRESS',
  'USA FANTASY PHOTO',
  'SHOPSMARTER',
  'PRICELN',
  'JALAPENOS',
  'LUMINESS AIR',
  'BURGER KIN',
  'CORNER STORE HOUSTON TX',
  'USA SNACK SODA VENDI',
  'CSC SERVICEWORKS U',
  'EINSTEINS',
  'WESTERNBEE',
  'ULTIM',
  'NATIONAL',
  'LIQUOR CITY',
  'QUICK N CLEAN',
  'VW CREDIT TEL',
  'HAIR BEAUTY',
  'MAZZIO S',
  'YOUTUBE M',
  'SUSTAINED OVERDRAFT FEE',
  'PAPPAS BBQ',
  'GM OIL',
  'ICE BOX',
  'AWL PPD ID',
  'CLASSMATES',
  'PULL A PART',
  'DEBIT FOR CHECKCARD XXXXXX SPECTRUM',
  'QT OUTSID PURCHASE',
  'PLAY IT AGAIN SPORTS',
  'MORPHE US',
  'UBISOFT EMEA SAS',
  'SUNTRUST',
  'STAR',
  'DCGAMEPUB',
  'ALL AMERICAN',
  'ROARMONEY TRANSFER NEW YORK NY',
  'ACH TRANSACTION DIGITCO SAVINGS',
  'KINDERCARE',
  'GRAND MART',
  'ROKU FOR SHOWTIME DIGI',
  'CAMEO',
  'ACCESSMCP INMATE',
  'DAVES SUPERMARKET',
  'POS DEBIT VISA CHECK CARD FLYING J',
  'CARSON',
  'TOUS LES JOURS',
  'CMSVENDCV DALLAS',
  'ATM WITHDRAWAL PLUS ST CITIZENS',
  'BBVA USA',
  'BEAUTY ZONE',
  'MAPCO PURCHASE',
  'CRUNCH FIT CLUB FEES',
  'DEBIT CARD PURCHASE XXXXX SUBWAY',
  'WUDRIVETIME',
  'COMMUNITY',
  'CHECK WITH IMAGE INCLEARING CHECK',
  'ACIMA CREDIT LLC',
  'DOLLAR TRE',
  'RING UNLIMITED',
  'SOUTHWESTAIR',
  'DOG HAUS',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON KLARNA PAYMENTS',
  'RESUMEGENIUS',
  'NTTA CUST SVC ONLI',
  'USAA PC EXT',
  'BPJET',
  'POS DEBIT VISA CHECK CARD PAY GCO WALLETHCA',
  'CASH DISBURSED',
  'CUMBERLAND FARM',
  'CHUCKLES STORES',
  'SHOP FAIR',
  'CONNECT',
  'BEDS N STUFF',
  'SAM ASH MUSIC',
  'MONTERREY MEXICAN',
  'MAIN ST',
  'BPKING',
  'ATM WITHDRAWAL NORT',
  'DEALS PURCHASE',
  'COSTCO WHSE',
  'FRANKS PIZZA',
  'ASPIRATION PP',
  'BURGERS',
  'PLAYSTATIONNETW',
  'VARIETY WHOLESALERS RO',
  'DICKS SPORTING PURCHASE',
  'URLTV SUBSCRIPTION',
  'WEB XFER TO SAV',
  'RECURRING PURCHASE AT BRANCH MESSENGER I MN ON FROM CARD XXXXXXXXXXXX',
  'DOORDASH WWWDOORDASHCA',
  'NICKY DS',
  'AJS',
  'WESTERN SIZZLIN',
  'ISPAPIMDS WITHDRWL PAI ISO BROOKLYN NY FEE',
  'KFC TB',
  'TAQUERIA EL',
  'JJ FISH',
  'FREEBIRDS',
  'GOOGLE CA',
  'GROCERY INC',
  'DISTROKID EXTRAS',
  'TOLLS BY MAIL',
  'ABC CRUNCH FITNESS',
  'MARKET DISTRICT',
  'MIDWEST',
  'KEFIRGAMES',
  'AMERICAS BEST PURCHASE',
  'CHIME PP',
  'OLYMPIA SPORTS',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS HLUHULU U HULUCOMBILL CA',
  'ADJUSTMENTPAYMENTS',
  'PULP JUICE AND SMOOTHI',
  'PAPA JOH',
  'REGAL CINEMAS MOBILE',
  'CASH APPJESSICA PMNT SENT',
  'STATEFOODSAFETYCOM',
  'JETTY INSURANCE',
  'BONFIRE',
  'CC BILL',
  'EVENT TICKET INSURANC VA',
  'ZILLOWRENTALS',
  'WESTBON INC',
  'CIRCLE A',
  'TO ALBERT SAVINGS D EDI PYMNTS',
  'CASTLE MEGASTORE',
  'VENDWATCH TELEMATICS',
  'MICRO ELE',
  'S MART',
  'AFLAC',
  'REVOLUT',
  'SMARTYPLUS',
  'POS DEBIT VISA CHECK CARD STRAIGHTTALKAIRTI',
  'STUBHUB INC PURCHASE',
  'DEBIT PURCHASE VISA CARD DAVECOM',
  'COSTA',
  'SUPREMENEWYORKCOM',
  'CASH APPANTONIO',
  'NOOM PLAN',
  'REGIONAL ACCEPTANC',
  'NONCHASE ATM WITHDRAW CASIN',
  'A PLUS',
  'AMZN MKTP US AMZNCOMB PURCHASE AMZNCOMBILL',
  'OLLIES BARGAIN OUT',
  'CN TRAVEL PLAZA',
  'EFOODCARD',
  'BIBIBOP ASIAN GRILL',
  'BETTERHELP',
  'BEALLS OUT STE',
  'URBANOUTFITTERSCO',
  'UNITED OF OMAHA',
  'ANDRETTI INDOOR KARTING',
  'COMENITY PAY OH PHONE PYMT P TEL ID',
  'STEAMGAMESCOM PURCHASE',
  'SPECTRUM OH',
  'ERIE INS GROUP',
  'EDGE',
  'CHINA PALACE',
  'CHEVRON HALLMARK PETRO',
  'SUPER NAILS',
  'ROMA PIZZA',
  'AUTOZONEINC',
  'GALLS',
  'SKIMS',
  'LEVELUPTROPICALSMO',
  'NEWEGG',
  'AWL DEBIT PPD ID',
  'TMOBILE BC TEL',
  'TEACHERSPAYTEACHER',
  'GOPUFF GB LICENSE',
  'DRIVE IN',
  'ADAM AND ADAM AND EVE',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON WITHDRAWAL MADE IN A BRANCHSTORE',
  'NJT NY PENN STA',
  'VAPOR WORLD',
  'MCDONALDS WWWDOORDASH',
  'BAD DADDYS BURGER BAR',
  'EL RIO GRANDE',
  'DISCOUNT SMOKES',
  'OFFICE MAXOFF',
  'NONCHASE ATM WITHDRAW E S',
  'DOORDASHBURGER KI STRIPECOM',
  'AEDIRECTCOL',
  'RED WING SHOES',
  'FRYS MARK',
  'DOORDASHJACK IN THE WWWDOORDASH CA',
  'PARX CASINO',
  'PLAYA BOWLS',
  'FOOD EXPRESS',
  'MOBILE FLASH FUNDS FEE CHARGED ON',
  'WILLIAMSONDICKIE',
  'ACH DEBIT CAPITAL ONE MOBILE PMT',
  'KFC K PURCHASE',
  'ATM CASH WITHDRAWAL E',
  'AFFIRM PAYMEN',
  'HAMBURGER STAND',
  'ACH TRANSACTION TR TO NAVYCASH DEBIT',
  'TEXAS CHICKEN BURGERS',
  'LVPKASAMBA',
  'QUICKWAY',
  'HOBBY LOBBY ECOMM',
  'AMERICAN GAS',
  'ALLPOINT',
  'ATM WITHDRAWAL NOR',
  'ABC LIQUOR',
  'HUCKS FOOD',
  'DAIRY FAR',
  'SCRSHIN',
  'TENDER GREENS',
  'FACEBOO',
  'PIERCING PAGOD',
  'SELLERS BROS',
  'INNERSLOTH',
  'PRODIGYGAME',
  'RAPID FIRED PIZZA',
  'DINNERLY',
  'SHUTTERFLY CA',
  'TO ALBERT GENIUS EDI PYMNTS',
  'DIXIE MART',
  'CHILI',
  'FEDERICOS',
  'HIBACHI SUSHI',
  'WITHDRAWAL CASH',
  'ABC FINE',
  'MARBLE SLAB CREAME',
  'PREPAID PY',
  'NORTHFACE VF OUTDOOR',
  'ATM WITHDRAWAL W BR',
  'BJS RESTA',
  'WALK ONS',
  'ROCKSBOX',
  'BIG CARTEL',
  'USPSCOM CLICKNSHIP',
  'SIEPLAYSTATIONNETWORK',
  'BAKERS',
  'HOMEOWNERS INSURAN',
  'VIA',
  'BPAM PM',
  'HABITAT FOR HUMANITY',
  'KRYSTAL CHN',
  'DEBIT FOR CREDIT ONE BANK PAYMENT CO REF',
  'THE CORNER',
  'GOURMET',
  'CHICKIES AND PETES',
  'VANSCOM PSHOPVANS',
  'SE PURCHASE',
  'LIMRIDE COST HTTPSWWWLIM CA',
  'AMERICAUS',
  'AMERICAN RED CROSS',
  'DAZN LIMITED',
  'POSHMARK PURCHASE',
  'TIME WARNER CA TWCABLE',
  'WWENETWORK',
  'GUYS ECOMM',
  'CHINA GOURMET',
  'PIRATE SHIP POSTAGE',
  'GREAT AMERICAN CO PURCHASE',
  'PRINTING',
  'FACTOR',
  'CHECKERS C PURCHASE',
  'SAKURA JAPANESE',
  'MTANYCT PAYGO NEW YORK NY',
  'WARBY PARKER',
  'INSTANT',
  'NYCDOT PARKING METERS',
  'ACH TRANSACTION EARNINACTIVEHOUR VERIFYBANK',
  'RECURRING PAYMENT WU',
  'CREDITNINJA',
  'CHEVRON GM OIL CO LL',
  'SUCCULENT STUDIOS',
  'DOUBLEBEES',
  'HULU CA',
  'DEEP ELLUM',
  'MYJEWELERSCLUB',
  'RAZZOOS CAJUN CAF',
  'ACH TRANSACTION ATT PAYMENT',
  'BPGREEN',
  'HARMONS',
  'CHICK FILA',
  'CASH APPJACQUELINE',
  'USAAANNUAL FEE',
  'RECURRING PAYMENT MICROSOFTEA ACCES MSBILLINFO',
  'RECURRING PAYMENT SPRINT WIRELESS',
  'SMARTPAY CHG',
  'CENTS ONLY STR',
  'MIDTOWN',
  'VERIZONWRLSS RTCCR VN',
  'TMOBILE POSTPAID WEB',
  'PAY PMNT SENT GCOWALLETH',
  'THE PATIO',
  'STOP',
  'ROSS STORES PURCHASE ROSS STORES',
  'APPLECOMUS PURCHASE',
  'APPLE PAY SENT MONEY MOBILE PURCHASE',
  'PABC MULTI SPACE',
  'FREDMEYE FRED',
  'SHORES LIQUOR',
  'VANILLAGIFT',
  'VOCELLI PIZZA',
  'SPORTSMANS GUIDE',
  'GO PUFF PA',
  'LYFT RIDE FRI',
  'LJSAW',
  'ONLYFA',
  'BESTBUYPHONEPL',
  'FAST PHILS',
  'INTUIT TURBOTAX PURCHASE',
  'FOOD FUEL',
  'MICROTEL INN',
  'BADCOCK HOME FURNITURE',
  'UNITED PAC',
  'SEASIDE',
  'SUPER LIQUOR',
  'NJT RAIL MYTIX',
  'NAT YOURSCOREANDMO',
  'FUZZYS TACO S',
  'AMF PURCHASE',
  'PANTAYA',
  'USA CASH DEPOT VEND',
  'JULIANS MLT',
  'SMASHBURG',
  'TIME WISE PURCHASE',
  'ROOT INSURANCE OH',
  'D MART',
  'PACKAGE',
  'T NAILS',
  'REASORS',
  'SAMSBEAUTY',
  'LINKTREE',
  'GAP OUTLETCOM',
  'PAISANOS',
  'G M OIL',
  'UNDERGROUND',
  'FAYETTEVILLE',
  'DOORDASHSUBWAY WWWDOORDASH',
  'SPDPY',
  'EPCFORTNITE',
  'ICHIBAN BUFFET',
  'CHEVRONGM OIL CO L',
  'HAIR BUZZ',
  'ACH DEBIT BRIGIT MEMBERSHIP',
  'FATZ CAFE',
  'SPREADSHIRT',
  'USAMINUTE KEY IN',
  'KING GCOHELPPAY CA',
  'NOTHING BUNDT CA',
  'LOVE CULTURE',
  'MISS A STORE',
  'WELLS FARGO CARD PHONE PYMT',
  'KOHLS PURCHASE',
  'WD',
  'TUNECORE INC',
  'MPA PARKING PAY BY PHON',
  'OFFSET OD TO ACCT',
  'PUBLIC HOUSE',
  'CONVENIENT FOOD',
  'FIESTA MART PURCHASE',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS SPRINT WIRELESS KS',
  'BBX BIRCHBOX INC',
  'GOPUFF PA',
  'THORNTON',
  'DRIVE THRU',
  'HARMONSTORE',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS EXPERIAN CREDIT REPOR CA',
  'ELEGANT BEAUTY SUPPLIES',
  'SERVICE FE',
  'HANDELS ICE CREAM',
  'IKEACOM MD',
  'CHILIAPOSS',
  'PATS PIZZERIA',
  'RECURRING PAYMENT QUADPAY QUADPAY A HTTPSWWWQUAD',
  'SHOPKO',
  'TRANSFER TO SIMPLE',
  'HOT DOG',
  'SUPER MOTEL',
  'POS DEBIT VISA CHECK CARD USPSCOM MOVERS G',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS EARNIN TO TIP JAR WEB ID',
  'JIMMY JOHNS PURCHASE',
  'CASH APPBRIAN',
  'BLICK ART',
  'CHEAP TOBACCO',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS COMENITY PAY VI WEB PYMT P WEB ID',
  'POS DEBIT VISA CHECK CARD PROGRESSIVE LEASIN HTTPS PRO',
  'BOSTON MA',
  'DISTRICT',
  'CIVITMYFLORIDACOUNTYC',
  'DOORDASHCHILIS G PURCHASE',
  'BREAD',
  'SAUCE',
  'NINTENDO AMERICAUS PURCHASE',
  'WASH N GO',
  'GOODRX GOLD HTTPSGOLDGOO CA',
  'KRYSTAL COL',
  'KALKOMEY ENTERPRISES',
  'JIMS RESTAURANT',
  'CASH APPKATHERINE',
  'WHIP IN',
  'LITTLE CAESA',
  'DEBIT PURCHASE CARD MURPHYATWAL',
  'CASH APPJASMINE PMNT SENT',
  'SOUTHERN CAL',
  'ATM CASH WITHDRAWAL TX',
  'LA MICHOACANA HOUSTON',
  'COP PARKING METER',
  'LAWDEPOT',
  'RCO MOBILE PURCHASE',
  'VZ WIRELESS VE VZW WEBPAY WEB ID',
  'BUFFALO ROCK VENDI',
  'PHONE TRFR',
  'ZIPPS SPORTS GRILL',
  'GRAINGER',
  'THE BOTTLE SHOP',
  'HAPPY GARDEN',
  'RICHS CAR WASH',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS TMOBILEPOSTPAID FDP WA',
  'KUNG FU SALOON',
  'P ATM WITHDRAWAL',
  'GOODWILL INDUSTRIE',
  'TOP CHINA',
  'CHURCHS CHICKEN PURCHASE',
  'HULU PURCHASE',
  'NATURAL NAILS',
  'LIFE TIME FITNESS',
  'CVSPHARM PURCHASE CVSPHARM',
  'PEPSI BOTTLING VENT',
  'UNITED SUPERMARKET',
  'NACHO DADDY',
  'BAGELCODE',
  'TIDAL WAVE',
  'LIQUOR WAREHOUSE',
  'NONCHASE ATM WITHDRAW WHIT',
  'AUTOSAVE SAVINGS',
  'POS DEBIT VISA CHECK CARD APPLE PAY SENT M',
  'STRAIGHTTALK AIRT',
  'AZ MOTOR VEHICLE D',
  'ATM WITHDRAWAL CHASE',
  'POS DEBIT VISA CHECK CARD ORDERWISHCOM WWWWISHCOM C',
  'KINGS LIQUOR',
  'EA ORIGINCOM HELPEACOM',
  'COOK OUT NORTHSIDE',
  'AMERICAN EXPRESS ACH PMT W WEB ID',
  'CASH APPPATRICIA',
  'MICHIGAN FUELS',
  'POS DEBIT VISA CHECK CARD DEPARTMENT MOTOR V',
  'QUICK SERVE',
  'FUEL ZONE',
  'BRIDGE IT INC MEMBERSHIP PPD ID',
  'DISNEY MO',
  'PAYRANGE MOBILE AP',
  'UNCLES',
  'BLAZE PIZZA PURCHASE',
  'WWWSHEINCOM PURCHASE CALIFORNIA',
  'OPCNONFIN SERVICE',
  'A MART',
  'HOTTOPICINC',
  'DOORDASHSUBWAY STRIPECOM CA',
  'DOORDASHBURGER KI WWWDOORDASH',
  'CHICKEN NOW',
  'FRESH',
  'EEGEES',
  'CASH APPNATHANIEL',
  'COUNTY TAX',
  'BRUEGGERS BAGELS',
  'JELLY BTN GAMES',
  'POFCOM DE',
  'PAYMENTRET EARNINACTIVE',
  'POS DEBIT VISA CHECK CARD IN N OUT BURGER',
  'NORTHSIDE',
  'CHARGEOFF REAPPLY FEE',
  'WORLD OF BEER',
  'ONLINE ACCT OPENING TRANSFER',
  'MARKET D',
  'AMERFIRSTFINPAYMENTCO',
  'NONWELLS FARGO ATM TRANSFER FEE',
  'BPCHUS',
  'STRIKE',
  'VIASAT INFLIGHT W',
  'SUPER C',
  'HOUSE OF BEAUTY',
  'POS DEBIT VISA CHECK CARD BRIGIT',
  'SWENSONS',
  'NSF FEE ITEM RETURNED ACH EARNIN ACTIVE PAYMENTRE ACH EARNIN ACTIVE PAYMENTRE',
  'FLAGSHIP',
  'CASH APPANTHONY PMNT SENT',
  'LUXOR FRONT DESK',
  'USACANTEEN VENDI PURCHASE',
  'SHOPPERS WAREHOUSE',
  'APPLE COM BILL',
  'CALIBER COLLISION',
  'JDS MARKET',
  'PRICE CUTTER',
  'WISH CA',
  'PROCTORU INC',
  'VIVINT INC US',
  'TRUEBILL NEGOTIATION',
  'NSF RETURN ITEM FEE',
  'OPC UTLSERVICE FEE',
  'ARCOARCO PURCHASE',
  'RACK ROOM SHOE PURCHASE',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS PAYPAL INST XFER WEB ID PAYPALSI',
  'AE OUTF',
  'MONTHLY CHECKING FEE',
  'AVI FOODSYSTEMS INC',
  'FEE FOR OVERDRAFT ITEM PAID',
  'EA ORIGINCOM PURCHASE HELPEACOM',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON SXMSIRIUSXMCOMA',
  'ACH TRANSACTION OPPFINANC PAYMENT',
  'BEAUTYLISH',
  'EXPRESS STORE',
  'LYFT RIDE SAT',
  'OPC CNAC',
  'EXPRESS LLC',
  'EXPEDITED CARD FEE',
  'GAS PIPE',
  'CAFE RIO',
  'BRIGHTLENDING DEBCRED PPD ID',
  'GROUPON IN',
  'ACH WITHDRAWAL EMPOWER INC',
  'MAMBO SEAFOOD',
  'WWWDOORDASH',
  'USASNACK SODA',
  'QUICK CHEK CORP',
  'ICE VENDING',
  'POS PURCHASE POS MURPHYATWA',
  'WEB ORDER',
  'TIME WISE HOUSTON TX',
  'URGENT CARE',
  'DEBIT FOR CHECKCARD XXXXXX CLEO AI HTTPSWWWMEETDE',
  'SCGOVDMV',
  'RIVERSIDE',
  'HOLLYWOOD BEAUTY',
  'HILLMAN GROUP',
  'BELLE TIRE',
  'EHARMONY',
  'IBIFABKIDSCOM',
  'FEDERICOS MEXICAN FOOD',
  'NJT PABT',
  'WHITE CAS',
  'MCW',
  'LA CARRETA',
  'HELIXFI PMT PPD ID',
  'CAJUN GRILL',
  'DEBIT CARD PURCHASE XXXXX ONLYFANSCOM XXXXX',
  'EL REY',
  'ATM WITHDRAWAL TNSSMART',
  'CRAZY CRAB',
  'DAIQUIRIS',
  'ATM WITHDRAWAL ALI',
  'VIVA BURRITO',
  'UBISOFT INC',
  'DUNKIN PURCHASE',
  'CHAO CAJUN',
  'BPCLIP',
  'BUCKYS EXPRESS',
  'DARE AMERICA',
  'HUM NUTRITION INC',
  'DICKEYS BARBECUE',
  'GUCCI ECOMMERCE',
  'NJT BUS',
  'PRIVATERECORDS',
  'SHOE SHOW MEGA',
  'WEB INITIATED PAYMENT AT EARNINACTIVEHOUR PAYMENTRET XXXXXXXXXXX',
  'NFCU MEMBERSHIP FE',
  'INSTACARTALDI',
  'GEEK ORDERWISH',
  'GUMCOCC',
  'BPUNION',
  'WAWA ORLANDO FL',
  'DELTA AIR DELTACOM',
  'UNI MART',
  'CHEVRONTERRIBLE HERBS',
  'DIONS',
  'SALLY BEA PURCHASE',
  'SMOOTHIEKING MA',
  'AIRTIME',
  'SWAROVSKI',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS CREDIT ONE BANK PAYMENT WEB ID',
  'FREDDYS FROZEN CUS',
  'SHOE DPT ENCORE',
  'CASH APPKAY',
  'APPLE CASH ADDE PMNT SENT',
  'FUZZYS TACO',
  'MONEYTREE HPPPROD',
  'FLASH MARKET CIT',
  'THE POP SHOPPE',
  'PAPER STMT FEE',
  'JASMINE',
  'MARKETWORK WA',
  'BEST DONUTS',
  'USA TECHVEN USA',
  'C BAKERY CAFE US',
  'BOOST FINANCE',
  'POS DEBIT DAVE INC LOS ANGELES CA',
  'DIRECT DEBIT POSSIBLE FINANCE DEBIT',
  'SPRINT WIRELES',
  'SUNSET',
  'THE WASH HOUSE',
  'USPS PO BOXES ONLINE',
  'EXPERIAN CREDIT PURCHASE',
  'DEBIT PURCHASE VISA CARD PLAYSTATIONNETWO',
  'WILD SIDE',
  'ALABAMA',
  'STARZ ENTERTAINME',
  'RECURRING PAYMENT AFFIRM PAYMENT',
  'ALBERT INSTA',
  'TUDORS BISCUIT WORLD',
  'CASELY',
  'NONCHASE ATM WITHDRAW NE',
  'BEAUTY MECCA',
  'GIANT LANDOVER',
  'POINT OF SALE DEBIT L DATE DAVE COM',
  'QUIBI',
  'ATM WITHDRAWAL W UN',
  'ROSE NAILS',
  'MICHAELS TX',
  'INSOMNIA COOKIE',
  'SELF LENDER INC DESADMIN FEE ID INDNADV PLUS BANKING CO IDXXXXX PPD',
  'TRUEBILL P',
  'KITSCHLLC',
  'SNAP FINANCE PAYMENT PPD ID',
  'HENDERSON CHICKEN',
  'TMOBILEPOSTPAID I',
  'ACH WITHDRAWAL',
  'JACKPOCKET INC',
  'TRIAL',
  'NUTRITION',
  'UBAMBOOKS',
  'DOORDASHSUBWAY WWWDOORDASH CA',
  'DISCOUNT CIGARETTES',
  'THE CHEE',
  'OVERTURE PROMOTION',
  'TRANSFER TO CK',
  'DEVICE PROTECTION',
  'OWNABLE',
  'POS DEBIT VISA CHECK CARD STATE OF CALIF DMV',
  'JAILATM',
  'ACH DEBIT BRIGIT PROTECTION',
  'ATM WITHDRAWAL COOP ELEVENFC VIRGINIA BEACVA',
  'TB PH',
  'HEB HOUSTON TX',
  'SECURITY FINANCECO',
  'ATM WITHDRAWAL E BR',
  'LENDING CLUB',
  'PAYNOW FIRST ENERGY',
  'SUPERIOR SUPER',
  'ME N EDS PIZZERIA',
  'JPAY STAMPS FL',
  'LOT LESS',
  'ATM WITHDRAWAL W GR',
  'VISA DIRECT CA PAY',
  'NONCHASE ATM WITHDRAW SE',
  'GOOGLE MUSI',
  'TMOBILETEL UPGRADE WA',
  'MR TACO',
  'SOUTHERN CLASSIC CHICK',
  'KING GYROS',
  'FINGERHUT DEBIT CR',
  'THRIFTY LIQUOR',
  'BARNESNOBLECOMBN NY',
  'NAPA',
  'PARK CHICAGO MOBILE',
  'SEZZLE ORDER P HTTPSSEZZLEC MN',
  'BOURBON STREET CAFE',
  'VERIZONWRLSS RTCCR VW',
  'FANDUEL DEP',
  'THE OFFICE',
  'NONHUNTINGTON ATM WITHDRAWAL FE',
  'KRYSTAL MAC',
  'DEBIT CARD PURCHASE AT PLAYSTATION NETWOR CA ON FROM CARD XXXXXXXXXXXX',
  'CASH APPERIC',
  'FIRECRAFT STU',
  'TECOPEOPLES GAS',
  'MINUTEKEY PURCHASE',
  'HARLAND',
  'RING UNLIMITED MONTHLY',
  'VZWRLSSMY VZ VW P FL',
  'DODGES STORE',
  'DRAGON CITY',
  'ATT TV NOW',
  'CAR WASH USA EXPRE',
  'FBV STUDIO MOVI',
  'POS DEBIT VISA CHECK CARD CASH APPJONATHAN',
  'OMAZE INC',
  'POS DEBIT VISA CHECK CARD FLOATME',
  'VERIZON PREMIUM',
  'FABULOUS',
  'EBT ACCT',
  'AUTO AIR VACUUM SERVIC',
  'EXPRESSIONS',
  'PAYPAL INST XFER EBAY INC WEB ID PAYPALSI',
  'TRANSCRIPT SERVICES',
  'TOTAL LIFE CHANGES INC',
  'FIRST CASH PAWN',
  'SEAWORLD PARKSENT',
  'FITNESS CONNECTION',
  'ARGOVSTPAYMENT',
  'WAREHOUSE',
  'POS DEBIT VISA CHECK CARD MICROSOFT XBOX',
  'OLD TOWN',
  'ASSURANCEAMERICA',
  'MAD GREENS',
  'UPTOWN',
  'MAPLE',
  'ECHSTNET',
  'SQUARE INC SDVVRFY T WEB ID',
  'AGIRENTERSCONDO INS FL',
  'AFI ALLY PAYMT',
  'UBEREATS',
  'PARCHMENTUNIV',
  'ZARA USA PURCHASE',
  'BARBERSHOP',
  'AMERICAN CRE',
  'DDS DISCOUNT PURCHASE',
  'DOORDASHPANDA EXP STRIPECOM',
  'CHEVRONSUNSHI',
  'TRYCAVIAR',
  'CARDENAS MARKETS',
  'MA',
  'CASH APPMATTHEW',
  'APPLE ONLINE STORE',
  'INSUFFICIENT FUNDSRETURNED ITEM FOR CHECK FOR CHECK CLEARED',
  'CFX EPASS RETAIL',
  'TONYS MARKET',
  'RUTTERS FARM S',
  'GOODWILL INDUSTRIES OF',
  'SPDPYAMERICAN CREDIT',
  'CHEVRON TERRIBLE HERBS',
  'DOORDASHCRACKER BARRE',
  'FASTRAK VIOLATION CENT',
  'FRNDLY TV',
  'QUIKTRIPTRANSFUN',
  'GAZBAH',
  'ATM WITHDRAWAL E ST',
  'BAYMONT INN',
  'POSTAL ANNEX',
  'FINGERHUT PAYMENT PPD ID',
  'TH HTTPSPOSTMATE',
  'DR DUE TO ATMDEP ERROR',
  'SNAPPY MART',
  'NICKS GYROS',
  'GENERAL',
  'FINISH LINE PURCHASE IN',
  'SPA NAILS',
  'ACH INSTACASH PAYMENMONEYLION',
  'ATM WITHDRAWAL RD',
  'BSW',
  'VIVA VEGAS',
  'PERFECT',
  'SPECS LIQUOR',
  'APPLECOMBILL WWWAPPLECOM',
  'NEW YORK CITY TAXI',
  'APPLEBEES WWWDOORDASH',
  'POINT OF SALE DEBIT L TIME AM DATE MURPHYATWAL',
  'CHEGG ORDER PURCHASE',
  'PEACH',
  'MIDLANDCREDITMANAG',
  'DICKEYS B',
  'GO CALENDARS GAMES BOO',
  'SUBWAY HOUSTON TX',
  'TROPICALSMOOTH',
  'NAILS AND SPA',
  'JEWEL OSCO PURCHASE',
  'LOWES FOODS',
  'NCIC INMATE COMMUNICATI',
  'LEMONADE I LEMONADE LEMONADECOM NY',
  'POS DEBIT VISA CHECK CARD MICROSOFTXBOX GAM MSBILLINF',
  'GLORIA JEANS COFFEE',
  'MICROSOFTXBOX GAME PAS',
  'EXPRESS LIQUOR',
  'GLOBAL LENDING SERVICE',
  'AMERICAN WINGS',
  'MARCOS',
  'KENDRA SCOTT',
  'CHECKCARD PURCHASE CORNER STORE VISA CARD XXXXXX POS AT CORNER STORE',
  'NSFOD CHARGE PAID INSUFFICIENT FUNDS',
  'NONCHASE ATM WITHDRAW S T',
  'HAMPTON INN SUITES',
  'GIVING FUND',
  'HOOK FISH AND CHICKEN',
  'KATAPULT',
  'VESTA ATT OR',
  'PIEOLOGY OLO',
  'SOMISOMI',
  'CHEVRONHALLMARK PETRO',
  'POS DEBIT VISA CHECK CARD AUTO AIR VACUUM SE PLAINVIEW',
  'RUDY S COUNTRY STO',
  'VESTA ATT PREPA OR',
  'BRAINLY',
  'LUCKYS',
  'TOUCHTUNESCONY',
  'JENNY BEAUTY SUPPLY',
  'THEA COLLECT',
  'FEE FEE',
  'DAVES SUPERMAR',
  'FSI GEORGIA POWER',
  'EMPIRE PIZZA',
  'G GOAT HTTPSWWWGOAT',
  'VANS INC',
  'GIOVANNIS PIZZA',
  'FLEXJOBS',
  'Q PURCHASE',
  'POLO RL',
  'LANEBRYANTCOM',
  'CHUMBA GOLD COINS VALETTA',
  'ELITE',
  'PAYGOV',
  'BPLEE',
  'FRESH MARKET',
  'BELLAGIO',
  'CENTS ONLY STO',
  'PARKINGMETER PURCHASE',
  'PALS',
  'ATM CASH WITHDRAWAL RT',
  'USA ICE VENDING',
  'DEPTARTMENT MOTOR',
  'BLOCKCHAIN',
  'BOX STUDIO MOVIE GRI',
  'CHICKEN',
  'WYZE LABS INC',
  'GEORGES',
  'ACH ELECTRONIC DEBIT CAPITAL ONE MOBILE PMT',
  'MERCARI PURCHASE',
  'WAHLBURGERS',
  'IRON AGE',
  'WEIGHTWATCH',
  'BTJ WINGS',
  'MICROS',
  'RACETRAC MOBILE PURCHASE',
  'STREET',
  'BPSUNRISE',
  'WESTBON INC PPD ID',
  'ATM WITHDRAWAL PLUS ARMED FORCES B',
  'PROGRESSIVE INSU CE OH',
  'MIDLAND',
  'MERRICK BANK COR MOBILE PAY WEB ID',
  'YAMATO',
  'DEPARTMENT MOTOR V',
  'TAQUERIA LA',
  'PRIME VIDEOMS',
  'WIENERSCHNITZ',
  'MOBILE PURCHASE SIGN BASED A D APPLECOMBILL CA',
  'SUPREME',
  'BPLAKE',
  'MYBSC',
  'DDBRIGITCOMPROTECTION',
  'RAMZN',
  'INTERNET TRANSFER TO ACCT SANTANDER SAVINGS',
  'MISTER CAR WASH PURCHASE',
  'BRANCH ACCT XFR WDL',
  'FASHION Q',
  'HUEY MAGOOS',
  'MOBILE PURCHASE SIGN BASED P D APPLECOMBILL CA',
  'CAPTAIN D',
  'STATE HWY',
  'PALMETTO MOON',
  'GREATER',
  'INTERNET TRANSFER TO ACCT SIMPLY RIGHT CHECKING',
  'CHECK CARD PURCHASE WENDY S',
  'FAMOUS FOOTWEARCO',
  'US BANK',
  'ATM WITHDRAWAL E S',
  'ACH TRANSACTION SUBSCRIPTION ACORNS',
  'LIQUOR STOP',
  'TONYS',
  'HUDSON ST PURCHASE',
  'QUICKTAGPET TAG VENDIN',
  'MISFITS MARKET',
  'CHECK CARD RUSH FEE',
  'ATM WITHDRAWAL BELL',
  'BPBIG',
  'CASH APPDEMETRIUS',
  'QT DALLAS TX',
  'DURHAM COUNTY ABC',
  'DAIRYLAND AUTO',
  'PROG SPECIALTY',
  'PUMP AND PANTRY',
  'ANNUAL MEMBERSHIP FEE',
  'SUBWAY NEW YORK NY',
  'CON',
  'LOUISIANA FAMOUS FRIED',
  'TIMMY CHANS',
  'PA DRIVER VEHICLE SER',
  'JD BYRIDER',
  'SLING TV',
  'ASSISTANCE',
  'TWH AUTO TRANSFER TO XXXXX',
  'SQUARETRAD',
  'LUCKY LIQUOR',
  'QUICKBIT',
  'FINISHLINE PURCHASE',
  'SO CAL GAS PAID SCGC',
  'CASH APPBRITTNEY',
  'SCOPELY IN',
  'REDI MART',
  'ROYAL BEAUTY SUPPLY',
  'MAMAS PIZZA',
  'BIG LOTS STORES',
  'ACH TRANSACTION SELF LENDER INC ADMIN FEE',
  'ONE STOP FOOD MART',
  'POS DEBIT VISA CHECK CARD DOORDASHCHIPOTLE WWWDOORDAS',
  'PAINTING WITH A TWIST',
  'AMERFIRSTFINPAYMENTCOM',
  'HONG KONG BUFFET',
  'CANTINA',
  'TRACFONE AIRTIME',
  'CASH APPSARAH',
  'ROOMSTER',
  'FASTRIP FOOD STORE',
  'SMOKERS',
  'SISTERS',
  'VBS LITTLELAKE',
  'NONCHASE ATM WITHDRAW WES',
  'GEORGAAQUARIUM',
  'PROSPECT PARK',
  'DOORDASHDUNKIN',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS QUADPAY FASHION NOV HTTPSWWWQUAD NY',
  'MICROSOFT ULTIMAT',
  'POS DEBIT VISA CHECK CARD INSTACART HTTPSINSTACARCA',
  'RODEWAY INNS',
  'LAX AIRPORT LOT P',
  'FOODMART',
  'USA PURCHASE',
  'BUY BUY BABY NASH',
  'ATM WD',
  'GOOGLE KING',
  'TGIFRIDAYS',
  'ACICREDIT ONE BANK',
  'APPLE PURCHASE',
  'NEWSSTAND',
  'POS MAC SPEEDWAY',
  'DEBIT PURCHASE VISA CARD HLUHULU HULUCOMBILCA',
  'STASH CAPITAL S',
  'CHECK PAID',
  'TRANSFER TO CITI SAVINGS P ONLINE REFERENCE',
  'ANOTHER BROKEN',
  'PARKING METERS',
  'PMNT SENT APPLE CASH',
  'BUBBLYBELLE',
  'BIG ALS',
  'COCO BEAUTY SUPPLY',
  'USAVEND AT AIR SERV PURCHASE',
  'EXETER AUTO',
  'CITIBANK NACITIBANK',
  'HAPPY FOOD MART',
  'WENDY S PURCHASE',
  'ALBERT INVESTMEN ACH',
  'INDOOR',
  'BEAUTIFULL',
  'CONNECTECH STOREFR',
  'CASH APPAARON',
  'POINT OF SALE DEBIT TARGET T',
  'IDENTITYIQCO',
  'KRYSTAL AUG',
  'DECIEM',
  'SAFE AUTO INSURANC',
  'GLAMOUR NAILS',
  'APPLE PAY CA',
  'ATWOOD',
  'DRIVEWEALTH LLC',
  'OK FOOD MART',
  'CREST FOODS',
  'JPAY MEDIA ACCOUNT',
  'ROYAL LIQUORS',
  'DONUT KING',
  'PICKNPULL',
  'WINGS SEAFOOD',
  'OFFICE MAXOFF PURCHASE',
  'MLGW',
  'TACO BE',
  'POS PURCHASE POS WM SUPERCENTER',
  'LAKEVIEW',
  'MONEYLION TRANSFER',
  'PELICANS SNOBALLS',
  'TRADING PASSION LTD',
  'JJ LIQUOR',
  'XPRESS FUEL',
  'VZWRLSS APOCC VISE',
  'CBS MOBILE',
  'WITHDRAWAL ACH DAVE INC',
  'WALMART CO',
  'HABBY PTE LTD',
  'JAPAN',
  'LUDIA INC',
  'RURAL',
  'ALLSTAR',
  'CHEVRONGM OI',
  'SPRINT ACHBILLPAY WEB ID',
  'ATM WITHDRAWAL S HA',
  'STRAIGHTTALKAIRT PURCHASE',
  'PMNT SENT CASH APPMICHAEL CA',
  'H E B GAS',
  'ITS JU',
  'FORD MOTOR CR',
  'INTERNET TRANSFER CREDIT TO CC',
  'EXTERNAL WITHDRAWAL CAPITAL ONE MOBILE PMT',
  'CHEVRONSTAR STOP',
  'DEBIT FOR CHECKCARD XXXXXX APPLE CASH INFINITELOOPCA',
  'SHOPPERS FOODPHARM',
  'LIMRIDE COST HTTPSWWWLIM',
  'BEST BEAUTY',
  'AGACI',
  'DESERT CAB',
  'TAXI SVC LV YCS',
  'NYCFINANCECONVENIENCEFE NEW YORK NY',
  'ANCANCESTRYCOM',
  'BANKCARD LLC',
  'MILLER S ALE HOUS',
  'DEBITHOLD',
  'TOOMICS',
  'BARRIO',
  'SELF LENDER INC DESPAYMENTS ID INDNADV PLUS BANKING CO IDXXXXX PPD',
  'PIC QUIK',
  'SLICELIFE',
  'ACH HOLD PAYPAL INST XFER ON',
  'LITTLE ITALY PIZZA',
  'DR SQUATCH INC',
  'LAS PALMAS',
  'VAPES',
  'FAMILY DOL',
  'BEEF O BRADYS',
  'ISPAPIMDS WITHDRWL PAI ISO NEW YORK NY FEE',
  'Q NAILS',
  'PANDORA PURCHASE',
  'DIRECT DEBIT BRIGITCOM PROTECTION',
  'LJS PURCHASE',
  'USACANTEEN PURCHASE',
  'SUNDANCE NOW',
  'ATM WITHDRAWAL S ST',
  'MICROSOFTULTIMATE MO PURCHASE MSBILLINFO',
  'GREEN ARROW LOAN',
  'PANCHOS',
  'SCHLOTZSKYS A',
  'ATM WITHDRAWAL WITHOUT CHARGE',
  'HURTS DONUT',
  'SCENTSYINC ID',
  'ROADRUNNER',
  'WENDYAPOSS',
  'EBAY PURCHASE',
  'MESSENGE',
  'ACH ELECTRONIC DEBIT EARNINACTIVEHOUR PAYMENTRET',
  'PDQ PURCHASE',
  'HYDROJUG',
  'LA CITY PARKING METER LOS ANGELES CA',
  'POS DEBIT VISA CHECK CARD DOORDASHCHIPOTLE STRIPECOM',
  'MICROSOFTXBOX LIVE GO',
  'BEST NAILS',
  'JUNGLE JIMS',
  'SCCRSH',
  'MOBILE PMT CAPIT',
  'WINE SPIRITS',
  'STOP INC',
  'HANES',
  'RACING MART',
  'GREEN CRUSH',
  'TRACTORSUPPLY',
  'HLUHULU UHHULUCOM BILLCA',
  'OF PURCHASE LONDON',
  'TMO INS DEDUCTIBLE',
  'HY VEE GAS',
  'TIDAL WAVE AUTO SPA',
  'V SHRED LLC',
  'CAPITAL ONE AUTO CARPAY PPD ID',
  'EA ELECTRONIC ARTS',
  'ATM WITHDRAWAL PLUS WOODFOREST NATI',
  'CASH APPVERONICA',
  'FIRST WAT',
  'GLACIER WATER VENDIN',
  'SPORTS',
  'BVDBEEN VERIFIEDCOM',
  'MONEYLION MEMBERS',
  'WITHDRAW',
  'CHERRY VA',
  'XPRESS CAR WASH',
  'CPS ENERGY',
  'SA COMPANY',
  'PROVISIONAL',
  'HOLIDAY CAR WASH',
  'TERRIBLE HERBST',
  'CIGARS',
  'SENOR TACO',
  'PASSNY PAYMENT',
  'BUY BUY BABY',
  'CHRISTMAS TREE',
  'POS PUR CASH APP CA',
  'UNITED APRO',
  'ATM CASH WITHDRAWAL NH',
  'SPEEDY GAS',
  'PLACEIT EMPOWERKIT',
  'NONCHASE ATM WITHDRAW SAN',
  'JEWEL OSCO CHICAGO IL',
  'JESSICA',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS TMOBILEPOSTPAID FDP WA',
  'JOANN',
  'CUROLOGY CA',
  'QUAYUSA',
  'MIYABI JR EXPRESS',
  'MICROSOFT XBOXLIVE',
  'QUADPAY FASHION NOVA PURCHASE HTTPSWWWQUAD',
  'TOBACCO MART',
  'YOUTUBE MEM',
  'AFTERPAY DEUS',
  'COUNTRY FARMS',
  'UBER HQ',
  'NOOM INC',
  'CRAVE',
  'PERRYS BBQ',
  'CENTR',
  'APPLE PAY CASH',
  'FASTRIP FOOD S',
  'CHEVRON GM OIL CO L',
  'CIVITMYFLORIDACOUN',
  'NONCHASE ATM WITHDRAW S HA',
  'ACH WEBSINGLE XXXXX EARNINACTIVEHOUR PAYMENTRET',
  'BEVERAGE WAREHOUSE',
  'USA IVS VENDING',
  'RECURRING PAYMENT CKOPATREON MEMBE',
  'PA COURTS FINES AND COS',
  'JERSEY M',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS TARGET DEBIT CRD ACH TRAN POS ID',
  'NORFOLK',
  'LUSH US PURCHASE',
  'VARO INITIATED ADJUSTMENT',
  'WITHDRAWAL DAVE INC TYPE DEBIT ID CO DAVE INC ACH ECC WEB ACH TRACE',
  'ACH BRIGITCOM MEMBERSHI',
  'ACH DEBIT PLANET FIT CLUB FEES',
  'STEAK N SHA',
  'MARIETTA DINER',
  'INSTACART SUBSCRIPTI HTTPSINSTACAR CA',
  'WE HTTPSPOSTMATE',
  'WAWA GAS STORE',
  'EIGHTVAPE',
  'PHANTOM',
  'T MOBILE PAYMENTS',
  'WETZEL PRETZEL',
  'PAPPAS BARBQ Q',
  'BUSTERS',
  'ELECTRONICACH DEBIT EARNIN ACTIVE PAYMENTRET',
  'ACH CREDIT ONE BANK PAYMENT',
  'CHECK N GO X',
  'CREDIT ACCEPTNCE LOAN SVC',
  'FLOATME FUND I',
  'MARATHON FOOD',
  'DIRECT DEBIT CAPITAL ONE NA CAPITALONE',
  'VITALITY BOWLS',
  'PEPBOYS',
  'PYPL PAYIN PURCHASE',
  'MINTO MONEY',
  'ELECTRONICACH DEBIT EMPOWER INC EMPSUBSCR',
  'GALLERY',
  'DOORDASH DASHPASS DOORDASHCOM CA',
  'CC',
  'KFC PURCHASE',
  'WORD FIND',
  'ACH DEBIT EARNINACTIVEHOUR VERIFYBANK',
  'SUPER STORE',
  'FLYERS',
  'DEPOT',
  'PRINTED CHECK IMAGE FEE',
  'DRAGON EXPRESS',
  'KNOTTS BERRY FARM',
  'WHEELS RIDE IN STYLE',
  'GIANT MARTINS',
  'SYNCHRONY BANK CC PYMT TEL ID',
  'CREDIT KARMA',
  'SUPER SAVE',
  'INTERNATIONAL TRANSACTION FEE OF LONDON',
  'CHOCOLATE',
  'HIMS HERS HEALTH',
  'ESURANCE INSURANCE',
  'GENERAL STORE',
  'CASH APPANDREA',
  'SPOKEO CA',
  'LIMTEMP HOLD',
  'TURBOTENANT',
  'CREDIT ONE BANK PAYMENT ACH WITHDRAWAL',
  'AMAZONCOM AMZNCOMBI',
  'SHARKS FISH',
  'CASH APPCASH CARD CA',
  'SPLASH CAR WASH',
  'PRICELN TVL PROTECT',
  'NONCHASE ATM WITHDRAW S CO',
  'EBAYCOMMERCE',
  'FRYS MKTP',
  'GUTHRIES',
  'DIGITCO PAYMENT PPD ID',
  'USASNACK SODA VENDING MOBILE PURCHASE',
  'SHUTTERFLY INC',
  'ONLINE BANKING TRANSFER TO',
  'LENDER INC',
  'SOCIAL IMPACT',
  'POS DEBIT VISA CHECK CARD CASH APPCHRISTIAN',
  'HMSHOST',
  'JOHNNYS PIZZA HOUSE',
  'DISCOUNT TOBACC',
  'HUTCH',
  'CREATIVE CLOUD INDIV',
  'FINGERHUT PAYMENTS MN',
  'ATM WITHDRAWAL SAN',
  'PLAYERS LOUNGE',
  'CASH EQUIVALENT TRANSACTION FEE',
  'CLEO AI WILMINGTON DE ON',
  'LOS TACOS',
  'XSOLLAHKLIM PO',
  'SAMS',
  'NONCHASE ATM WITHDRAW W M',
  'PAYMENTRET EARNINACTIV',
  'MICHAELS PURCHASE',
  'ZIBBYWAYFAIR',
  'POS DEBIT VISA CHECK CARD TMOBILEAUTO PAY',
  'POINT OF SALE DEBIT L TIME PM DATE ADVANCE AUTO PA',
  'COFFEE BEAN',
  'SHADOW',
  'CEDAR POINT FOODS',
  'DEBIT PURCHASE VISA CARD ALBERT INSTANT',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS BRIGIT MEMBERSHIP PPD ID',
  'ATM WITHDRAWAL GRAN',
  'KEWLIOO',
  'ANDPIZZA',
  'CASH APPMICHAE',
  'SPIRIT HALLOWEE',
  'ATM WITHDRAWAL PACI',
  'PLAYDEMIC LTD',
  'G ACOM',
  'GRUBHUBGHPLUS GRUBHUBCOM NY',
  'IMVU MOBILE',
  'SMOKERS PARADISE',
  'DISC REPLAY',
  'SUTHERLANDS',
  'DEBIT FOR ML PLUS MEMBRSHP MONEYLION CO REF ML',
  'CHARLOTTE',
  'GTL TELMATE INMATE',
  'PAYLESS LIQUORS',
  'BUYFBME',
  'DRAKES',
  'PATIENT FIRST TOKEN',
  'DISCOUNT DRUG M',
  'DISCOUNT FOOD MART',
  'CHINESEALLCORP',
  'DISCOVER PAYMENTS PPD ID',
  'ATM WITHDRAWAL W CH',
  'BREWING CO',
  'ACH DEBIT',
  'DISNEY ELECTRO',
  'AMZN DIGITALMY PURCHASE',
  'CREDIT ONE BANK PAYMENT TEL ID',
  'RAISE',
  'SMOKE PLUS',
  'USA BEAUTY SUPPLY',
  'FABLETICSLL',
  'TODAYS CAR WASH',
  'TMOBILE POSTPAID WEB PURCHASE',
  'TM',
  'BRITTANY',
  'SELF LENDER INC',
  'OH BUREAU MOTOR W COLUMBUS OH',
  'LUCKY MART',
  'SAKURA EXPRESS',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS BRIGITCOM MEMBERSHIP PPD ID',
  'PAYMENTRET EARNINACTIVEHOUR',
  'BURGER BOY',
  'GOOGLE GOOGLE YOUTUB',
  'AGAVE',
  'WASTE MANAGEMENT',
  'RIOTGAMESIN',
  'SHU DEAL',
  'MARDEL',
  'INTERNET TRANSFER TO INTEREST CHECKING ACCOUNT XXXXXX',
  'POS DEBIT VISA CHECK CARD APPLECOM BILL',
  'ROSEGAL',
  'ATM WITHDRAWAL N CO',
  'BRIGITCOM',
  'LAMZN',
  'PRIMO HOAGIES',
  'SLINGTVLLC',
  'ACTBLUE JOEBIDEN',
  'LIME US',
  'SOUFEEL',
  'SURCHARGE FEEEFT',
  'JJ FISH AND CHICKE',
  'BOOKSAMILLION',
  'ATM WITHDRAWAL FEE PREPAID PROCESSING',
  'ENCORE',
  'GIFTS',
  'RING MONTHLY HTTPSRINGCOM CA',
  'BILL PAY CHECK',
  'GREEN MOUNTAIN ENERGY',
  'WEST CREEK FINANCI',
  'PRETTYLITTL',
  'FIRSTENERGYEZPAY WEB',
  'METROLINK',
  'BKOFAMERICA ATM WITHDRWL TH STREET',
  'SNACK SHACK',
  'GABRIEL',
  'DOR MVD INS',
  'GRANDE',
  'JUICELAND',
  'GOO GOO',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS GENESISFS CARD WEB ID',
  'MAYFLOWER SEAFOOD',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS GOOGLE YOUTUBEPREMIU CA',
  'ROKU FOR SHOWTIME',
  'DAVE INC DEBIT WEB',
  'SAVE MORE',
  'HIBACHI SAN',
  'ALDO US PURCHASE',
  'DISCOVER BANK SECUR DPST WEB ID',
  'ESPN PLUS NY',
  'BEEN VERIFIED',
  'RCO AM MOBILE PURCHASE',
  'DEBIT CARD PURCHASE XXXXX BRANCH MESSENGER INC XXXXX',
  'VIP PETCARE RIG',
  'VERIZONWRLSSRTCC PURCHASE',
  'VALLARTA SUPERMA',
  'BLACKJACK PIZZA',
  'PAY PMNT SENT GCOHELPPAY',
  'PRIME TRUST LLC',
  'RESERVATIONS',
  'GREERS',
  'MOVIEPASS INC',
  'PRETZEL',
  'WWESHOP',
  'GATTIS PIZZA',
  'CONED BILL PAYMENT',
  'CORNER CAFE',
  'PW RESIDENT DIRE',
  'TRANSFER TO ONLINE SAVINGS',
  'INSUFFICIENT FUNDSPAID ITEM DEBIT FOR CHECKCARD XXXXXX APPLECOMBILL',
  'ASOSCOMLTD',
  'REWORKS',
  'JOHNNY',
  'WINNDIXI PURCHASE',
  'POS DEBIT VISA CHECK CARD USACANTEEN',
  'SONESTA HOTELS',
  'HOUSE OF PIZZA',
  'POS DEBIT VISA CHECK CARD SOUTHWES',
  'PARADISE',
  'BANANA REPUBLIC',
  'LOS BETOS',
  'EAGLE',
  'CREDITNINJA PAY PAYMENT PPD ID',
  'CARNIVAL CRUISE TKTRES',
  'THE BEARD CLUB INC',
  'QUIK TRIP CORP PURCHASE',
  'TOGOORDER',
  'VENDING CSC SERVICE WOR',
  'CON ED OF NY INTELL CK PPD ID',
  'ATM WITHDRAWAL TIMES S',
  'TIME SAVER',
  'HELPHBOMAXCOM',
  'RIVERS',
  'MOE S SW GRILL',
  'WITHDRAWAL ATMFEE ATM FEE DRAFT WITHDRAWAL TRACE',
  'HIDDENLISTINGS',
  'OFFICE MAX OFF',
  'FAMILY MARKET',
  'MICROSOFTXBOX LI PURCHASE',
  'CLOUD SMOKE CO',
  'SHOE MGK',
  'BOINGO INTERNET SV',
  'DAILY STOP',
  'PERSONALIZATION MA',
  'WM SUPERCENTER WALMART SUPER CEN',
  'AIR VAC CONNECTION',
  'GOOD TIMES',
  'ANDYS FROZEN CUST',
  'TMOBILE AAL TEL',
  'VZWRLSSPREPAID PYMNT',
  'QDOBA MEXIC',
  'NEOCORTEXT INC',
  'CONTINENTAL FINANCE CO',
  'MAJESTIC',
  'BARBERITOS',
  'THE KICKIN CRAB',
  'POCAHONTAS PARKWAY',
  'MODERN MARKET',
  'WALMART STORE',
  'ROKU FOR PEACOCK TV LL',
  'DOLLARSHAVE DOL',
  'DFW AIRPORT PARKING',
  'EMPOWERME',
  'POS DEBIT VISA CHECK CARD GA DRIVER SVCS ONL',
  'T MART',
  'CHILDRENS',
  'ROADSIDE ASSISTANCE',
  'BPEAST',
  'ULTASALONC',
  'ALBERT INSTANT EDI PYMNTS INSTANT',
  'DEBIT FOR CHECKCARD XXXXXX POS PURCHASE TPV',
  'CUSTOMGIFTS',
  'UNITED CREDIT EDUCATION',
  'RULER FOODS',
  'RECURRING PAYMENT TMOBILEPOSTPAID T',
  'SAMFINACO',
  'SPEC S',
  'RUTTERS FARM STORE',
  'TOBACCO TOWN',
  'BWIBOINGO WIRELESS',
  'CREDIT FRESH',
  'A FOOD MART',
  'EL TORITO',
  'LESLIES POOL SPLY',
  'POINT OF SALE DEBIT L TIME AM DATE HH',
  'AUDIOBOOKS',
  'UNITED CREDIT EDUC',
  'QUICKWAY JAPANESE',
  'PEKING RESTAURANT',
  'MUCHAS GRACIAS',
  'BRIGHTLENDING DEBIT PPD ID',
  'TERMINIX',
  'ONE STOP NUTRITION',
  'CHARLIES',
  'PRESIDENT',
  'FASTOP',
  'PROXIMA BETA',
  'DELTA AIR DELTACOM CA',
  'COST PLUS WLD',
  'LANCER',
  'WEB REINSTATEMENT',
  'ASSOCIATE',
  'PRINTERPIX',
  'LORIS GIFTS STORE',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS STASH CAPITAL S ACH WEB ID',
  'WING IT',
  'AUTO PARTS',
  'NONCHASE ATM WITHDRAW RETAILWIT',
  'INSTACASH REPAYMNEW',
  'GRUBHUBFOOD GHP',
  'ZOCA LOANS',
  'MONTHLY SERVICE CHARGE PR',
  'SUN MART',
  'L A FITNESS',
  'JPAY MONEY TRANSF PURCHASE',
  'EL SOMBRERO',
  'LIBERTY GAS',
  'TARGET DEBIT CRD ACH TRAN POS ID',
  'STARLINK',
  'KEMPER SPECIALTY',
  'SPECS LI',
  'CHECK CASHING WITHDRAWAL REF',
  'WEB AUTHORIZED PMT ALBERT SAVINGS D',
  'REDNERS QK SHP',
  'GACOMLIMIT',
  'OLD NAVY US',
  'FBV STUDIO MOVIE GR',
  'UNIBET',
  'TARGETCOM PURCHASE',
  'MWJ LLC',
  'MINITMAN',
  'DDPAYPALVERIFYBANK',
  'ELEV',
  'BC UBER',
  'FL DL TAG GORE PURCHASE',
  'TREXIS INSURANCE',
  'VZWRLSSBILL PAY VE FL',
  'MONEYLION LOAN REPAYM',
  'MIGHTY TACO',
  'BPEXPRESS',
  'DIRECT DEBIT CAPITAL ONE PHONE PYMT',
  'ZPIZZA',
  'COLLEGE TRANSCRIP',
  'RIDES',
  'PERSONIFY FINANC',
  'SPECTRUM TX',
  'CANDY',
  'CAPITAL ONE NY',
  'RECURRING PAYMENT EA ORIGINCOM HELPEACOM',
  'RALEY S',
  'GEMINI',
  'FACEBOOK PAYMEN',
  'TRUE ACCORD',
  'GRUBHUBSHAKESHACK',
  'SUNSHINE PURCHASE',
  'OPC TAX SERVICE',
  'DOF PARKINGANDCAMERA TI',
  'ACH TRANSACTION HARLAND CLARKE CHK ORDER',
  'HARDWARE',
  'POS DEBIT VISA CHECK CARD GW SERVICEFEE',
  'MARKET DISTRIC',
  'NONCHASE ATM WITHDRAW S ST',
  'FOOD GIAN',
  'NORFOLK MACARTHUR',
  'LNKDINBILL',
  'TRUTHFINDERCO',
  'DIRECT WITHDRAWAL DAVE INC DEBIT',
  'WEB AUTHORIZED PMT VOLA',
  'ENTERP',
  'EDIBLEARRAN',
  'ROAD RUNNER',
  'SBARROS',
  'SIMPLEMOBILESERVICES',
  'TRADINGVIEW',
  'CHIPOTLE ONLINE',
  'MONEYLION CO',
  'HARRAHS',
  'FUNDS TRANSFER VIA MOBILEWITHDRAWAL HOME BANKING TRANSFER TO SHARE',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS CAPITAL ONE ONLINE PMT WEB ID',
  'ARCOGREEN',
  'LANIER PARKING PURCHASE',
  'HAT CLUB',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS AFFIRM PAYMENT CA',
  'VERSONA',
  'NONCHASE ATM WITHDRAW N MAI',
  'SUSHI HOUSE',
  'HOT DOGS',
  'TROPIC',
  'DOORDASHCHIPOTLE CA',
  'KRAUSZERS FOOD STORE',
  'ALBERT INSTANT AU',
  'CHECKCARD CRO INTERNET',
  'LEMONADE',
  'XAMZN',
  'ALLHEART',
  'STRATOSPHERE HOTEL',
  'AARONS EZPAY WWWAARONSCO',
  'PEKING',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS ALBERT GENIUS EDI PYMNTS PAYMENT WEB ID',
  'AMERICAN DREAM',
  'BRIGHT',
  'LA QUINTA INN SU',
  'COPPER',
  'PUGET SOUND ENERGY',
  'SUPER DOLLAR',
  'HAIR WORLD',
  'HAIR DEPOT',
  'EXTERNAL TRANSFER FEE NEXT DAY CONFIRMATION',
  'DRUG EMPORIUM',
  'NFHSNETWORK',
  'BRAVO SUPERMARK',
  'POINT OF SALE DEBIT L DATE PLAYSTATION NETWOR',
  'FUND I DB',
  'ZEBIT INC PURCHASE',
  'ALLSTATE IND CO',
  'LEVELUPSWEETGREEN',
  'FORCED POST ACH WITHDRAWAL ADJUSTMENT',
  'SNOWFLAKE DONUTS',
  'CASH APPANDREW',
  'INSTACART MOBILE PURCHASE',
  'FUNKO SHOP',
  'SNACK BAR',
  'COUNTER CK FEE',
  'VISTAPRVISTAPRINTCOM MA',
  'PRIME VIDEOMA',
  'FAST EDDIES',
  'ACH DEBIT PAYPAL VERIFYBANK',
  'WINE LIQUOR',
  'DTV',
  'BLENDJET',
  'DDBR Q CHICAGO IL',
  'SECURITYFINANCE',
  'EL GALLO GIRO',
  'COOK OUT LITHONIA',
  'BPDLR',
  'DOORDASH MOBILE PURCHASE',
  'DOORDASHWAWA',
  'DTF PIT',
  'ACH VOLA VOLA',
  'MIGUELS JR',
  'MIKES LIQUOR',
  'THE LIQUOR',
  'ATM WITHDRAWAL ST',
  'SCHOLASTIC EDUCATION',
  'LEVELUP',
  'AUNTIE ANNES NC',
  'ROOT INSURANCE PURCHASE',
  'CUPSHE',
  'MYSTIC',
  'MURPHYATWAL HOUSTON TX',
  'GRAB GO',
  'GRUBHUBBOBEVANS',
  'LIFETOUCH',
  'TOAST',
  'DEALIN INC',
  'AMG RETAIL I',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON SIEPLAYSTATIONNET',
  'ENTERPRISES',
  'SINGAPORE',
  'DBT PURCHASE APPLECOMBILL CA',
  'MICROSOFT EA ACCES MSBILLINFO WA',
  'BIRDIES',
  'REVEL',
  'LOUIS VUITTON',
  'WHOEASYCOM',
  'FUNDS TRANSFER DEBIT FDES NNC',
  'ADORE ME INC HTTPSWWWADOR NY',
  'HAPPY DRAGON',
  'WB LIQUORS',
  'RENTERS INS',
  'FASHION NAILS',
  'RECURRING PAYMENT WWWCVSCOM IN',
  'GLAMNETIC',
  'GOPUFF PURCHASE',
  'STOP PAYMENT CHARGE',
  'LETGO',
  'DAZZLE UP STORE',
  'PROPERTY PAYMENT PURCHASE',
  'CASEY S GENERAL',
  'TOP BEAUTY SUPPLY',
  'TEMP EIP RELIEF REVERSAL FORCE P',
  'JP MORGAN CHASE FEE',
  'DISCOVER',
  'PAGEPLUS CELLULAR',
  'BUFFALO WINGS',
  'HEB GASCARWASH',
  'ANGELOS PIZZA',
  'TRANSAMERICA',
  'PINKIES',
  'MICHIGAN',
  'GULF FUEL CITY',
  'AUTO AIR VACUUM SVC PLAINVIEW NY',
  'WOKDRAGON',
  'RECURRING PAYMENT SS BOOKING FEE STYLESEATCOM',
  'PLAY SIMPLE',
  'FASTMART',
  'UPS US SHIP',
  'USA NAILS',
  'WITHDRAWAL AT G ONLINE TRANSFER STD TO SHARE',
  'OREGON LIQUOR STORE',
  'POINT OF SALE WITHDRAWAL TACO BELL',
  'POS DEBIT VISA CHECK CARD DD BR',
  'POS DEBIT VISA CHECK CARD NAYAX VENDING HUNT VALLEY',
  'DOORDASHJACK IN T WWWDOORDASH',
  'VZWRLSSBILL PAY VN FL',
  'CARD KLOVER CARE',
  'ACH TRANSACTION ROBINHOOD FUNDS',
  'CHECKCARD CRO INTERNET INTERNATIONAL TRANSACTION FEE',
  'CALVINKLEIN',
  'ADD BALANCE',
  'RECURRING PAYMENT PLAYSTATIONNETWORK',
  'COURSE',
  'DBT PURCHASE CASH APP CASH APP CA USCARD NBR POS WITHDRAWAL',
  'US MARKET',
  'EZ WAY',
  'BODYBUILDING',
  'HRB ONLINE TAX PRODUC',
  'KICKBACK JACKS',
  'ATM WITHDRAWAL E R',
  'STATE SALES TAX',
  'SHIPLEY DONUT',
  'STATEMENT',
  'DEBIT CARD PURCHASE AT OH BUREAU MOTOR VE COLUMBUS OH ON FROM CARD XXXXXXXXXXXX',
  'EXTREME PIZZA',
  'DHGATECOM',
  'STUDIOS',
  'OHIO THRIFT',
  'MCCLURE OIL',
  'NAIL STUDIO',
  'DOLLAR MART',
  'COMFORT INN SUITES',
  'TOBACCO EXPRESS',
  'GOPUF',
  'NISSAN MOTOR',
  'WEST MARINE',
  'TIER RETURNED ITEM CHARGE',
  'SUNRISE FOOD MART',
  'CASH APPJEREMY',
  'NATIVE GRILL WINGS',
  'CHECK VIEW IMAGE',
  'AUBURN',
  'POPALOCK',
  'TOUCH DOWN WINGS',
  'ATM WITHDRAWAL VAN',
  'SO CAL GAS DESPAID SCGC IDXXXXX INDN CO IDXXXXX WEB',
  'EL TIGRE',
  'CLASSY NAILS',
  'MBS DIRECT',
  'JPAY STAMPS PURCHASE',
  'NONCHASE ATM WITHDRAW RIVE',
  'MCALISTE',
  'PIZZA BAR',
  'DEBIT FOR CHECKCARD XXXXXX TARGET',
  'EMPOWER EMPOWERME CA',
  'BLAZE',
  'ABC LV',
  'TRACTOR S PURCHASE',
  'HEB GASCARWA',
  'LIM ADD BALANCE',
  'CATCH',
  'NONCHASE ATM WITHDRAW UNIV',
  'SMOKE DEPOT',
  'THE HABIT B',
  'BRIDGE IT INC PROTECTION',
  'SPORTS AND SOCIAL',
  'PRIME VIDEOMH',
  'PACSUN',
  'STATE LIQUOR',
  'CAPTAIN',
  'LOUISIANA FRIED CHICKEN',
  'HUMANA',
  'MERIDIAN',
  'SXMSIRIUSXMCOM',
  'AMEX EPAYMENT ACH PMT',
  'CHUMBA GOLD COIN',
  'MOTHERHOOD',
  'GO CARWASH',
  'JOHNS',
  'S AND S',
  'INGLES MARKETS PURCHASE',
  'MICROSOFT XBOX REDMOND WA',
  'GAS PLUS',
  'JAMES CONEY ISLAND',
  'GOLDEN BOWL',
  'WINDSTAR',
  'CBS ALL ACCESS CA',
  'WELLS FARGO AUTO',
  'RETURNED ITEM FEE FOR AN UNPAID ITEM DETAILS EMPOWER INC PPD ID',
  'M FINANCE MOFI',
  'PLAYSTATION CA',
  'DOLLAR GENER',
  'CRUTCHFIELD',
  'SUR LA TABLE',
  'POS DEBIT VISA CHECK CARD CA DMV FEE',
  'PROGRESSIVELEASEPMTS',
  'ANYTIME',
  'TURO INC TRIP',
  'SMOKE LESS',
  'BUNNY STOP',
  'THE ORIGINAL PANCA',
  'PELICANS SNOBA',
  'DOORDASHCHILIS GRILL STRIPECOM CA',
  'SUPER FOOD MART',
  'QUICK STO',
  'NOB HILL',
  'JAMES',
  'NONCHASE ATM WITHDRAW W NO',
  'GREAT WOLF',
  'CAMINO',
  'WASH LAUNDRY WAVERIDER',
  'WIRELESS ZONE',
  'PELICANS SNOB',
  'RENT A CENTER ECOM',
  'FEE FOR OVERDRAFT ITEM RETURNED',
  'VBSGREENLINE',
  'MCDONALDSWWW',
  'LIQUOR ZONE',
  'CONSOLE',
  'ATM WITHDRAWAL HILL',
  'ADIDAS PURCHASE',
  'DOMINION',
  'CORK N BOTTLE',
  'HAIR GALLERIA',
  'ISPAPIMDS WITHDRWL PAI ISO ATLANTA GA FEE',
  'EVERSOURCE',
  'SUNRISE MARKET',
  'ATM WITHDRAWAL UNIV',
  'BOURBON',
  'SWIFT',
  'CHINESE RESTAURANT',
  'APPLE CASH SENT MONE',
  'ST US',
  'LEMONADE I LEMONA LEMONADECOM NY',
  'TAXHANDLINGFEE',
  'NORWEX USA INC',
  'ANTONIOS PIZZA',
  'ATM WITHDRAWAL W MAI',
  'VERIZON WRL MY A',
  'FIRSTACCESS SD',
  'CASH APPCHRISTINA CA',
  'CHEVRONGM OIL',
  'GYMBOREE',
  'TREASURE',
  'VSP',
  'BILLMATRIX',
  'WA HTTPSPOSTMATE',
  'CHECK CARD PURCHASE ZAXBY S',
  'RUSSELL CELLULAR',
  'TOBACCO CITY',
  'T B',
  'CONSUMER CELLULAR INC',
  'TICKPICK',
  'PASSNY TOLLBYMAIL',
  'ACH TRANSACTION CLEO AI INC CLEO AI IN',
  'PROSPECT',
  'CARD ALBERT INSTANT',
  'TRANSFER TO S',
  'PAPPADEAUX SEAFOO',
  'LIME',
  'CASH APPMONIQUE',
  'DBCOFFEEFRE',
  'ATL CNBC',
  'WELLS FARGO CA',
  'GREAT STO',
  'POS DEBIT VISA CHECK CARD GOLDEN CORRAL',
  'BOINGO',
  'ELIZABETHS PIZZA',
  'ICFTECHNOL',
  'JET FOOD STORE',
  'DOORDASHDENNYS STRIPECOM',
  'MOBILE DEPOSIT CHECK POSTED TWICE REF AMN',
  'DOLLAR STORE',
  'KRISPY KRUNCH',
  'FUEL TIME',
  'ARROW',
  'SEAWORLD SAN DIEGO',
  'DEBIT CARD PURCHASE AT ALBERT INSTANT CA ON FROM CARD XXXXXXXXXXXX',
  'SCHWANS HOME SERVICE',
  'APPLE CASH ADDED TO',
  'SOUL FOOD',
  'BPBUCKYS',
  'WITHDRAWAL DAVE INC TYPE DEBIT ID CO DAVE INC ACH TRACE',
  'BODY WORKS',
  'ETSYCOM MULTIPLE SHO',
  'FAN DUEL STARDUST WP',
  'IBISAVAGEXFENTY',
  'VZWRLSSPREPAID PY',
  'HANDI MART',
  'CAFE RIO MEXI',
  'RALPH LAUREN CORPORATIO',
  'ANDRETTI INDOOR KA',
  'ISPAPIMDS WITHDRWL PAI ISO BRONX NY FEE',
  'INSUFFICIENT FUNDS FEE FOR A RECURRING CARD PURCHASE DETAILS KLARNA INC KLARNACOM OH',
  'ONO HAWAIIAN',
  'PIZZA EAST',
  'EL BRACERO',
  'SOUND CLOUD INC',
  'SWEETWATER',
  'LOVE FOOD MART',
  'AUSTIN AIRPORTFB',
  'CHEGG TXTBK',
  'AMSTAR',
  'ATM WITHDRAWAL S H',
  'JIMMY JOH',
  'ANYTIME FIT',
  'PEETS COFFEE',
  'DEPOSIT ITEM RETURNED FEE ACCOUNT CLOSED OF ITEMSCK DEP AMT DEP DATECK AMT SVC FEE',
  'CHINESE KITCHEN',
  'THE DONUT PALACE',
  'HMF HMFUSA',
  'POS DEBIT VISA CHECK CARD SPKSPOKEO SEARCH',
  'CCBILLCOM ONLYFANS AZ',
  'TURNPIKE',
  'POSSIBLE FINANC',
  'PRIME VIDEOMC',
  'PEEBLES',
  'UP N SMOKE',
  'FAST N FRIENDLY',
  'NONCHASE ATM WITHDRAW S M',
  'DOWNTOWN',
  'MENDOCINO FARMS',
  'PEKING HOUSE',
  'PROG GARDEN',
  'OREILLY AUTO P',
  'FOOD BASKET',
  'POINT OF SALE DEBIT L DATE PAYIT NC DMV',
  'FUBO TV INC',
  'BPEXPRE',
  'TROOP',
  'THECHILDRENSPLA',
  'PRUDENTIAL',
  'HP FUEL',
  'FRYS FUEL PURCHASE',
  'YOURRENTOWN',
  'PAID NSF FEE',
  'CRYPTO',
  'BUDDYS FOOD MART',
  'DRURY INNS',
  'PRIME VIDEOMN',
  'UNDERGROUND BY JYS',
  'CHINESE GOURMET',
  'NONCHASE ATM WITHDRAW E M',
  'DOLLAR FINANCIAL',
  'DDLNUGENIX',
  'POS DEBIT VISA CHECK CARD ROYAL FARMS',
  'DOORDASHBUFFALO W STRIPECOM',
  'EP MART',
  'QPONLINE',
  'PAYPAL INST XFER HULU WEB ID PAYPALSI',
  'NSF FEE WITH IMAGE',
  'POINT OF SALE DEBIT L DATE APPLE CASH',
  'TUBBYS',
  'POS DEBIT VISA CHECK CARD STEAMGAMESCOM',
  'TRANSFER TO SAV XXXXX',
  'ATLANTA',
  'OLIVE',
  'GENJIGO',
  'ACH PAYPAL ECHECK',
  'JPAY MONEY TRANSFER PURCHASE',
  'ATM WITHDRAWAL PLUS ROYAL FARMS',
  'NONCHASE ATM WITHDRAW W TH',
  'BVDBEENVERIFIEDCOM',
  'AMYS DONUTS',
  'SPROUTS FARMERS MKT',
  'NONCHASE ATM WITHDRAW ROUTE',
  'AMERICA',
  'CHEVRON SUNSHI',
  'DTV DIRECTV SERVICE',
  'SMOKERS EXPRESS',
  'CITY OF LA DWP',
  'CA DMV FEE GRASS VALLEY CA',
  'COURTYARD',
  'CRUISERS',
  'MYLIFE MY ID BKGRD TR',
  'TWISTED',
  'DOORDASH DASHPA',
  'AMSCOT',
  'RUN IN',
  'POS DEBIT VISA CHECK CARD CASH APPJENNIFER',
  'CASH APPEBONY',
  'SAMSCLUBCOM PURCHASE',
  'MELALEUCA ORDER',
  'REVERSAL OF EXCESS CREDIT DUE TO MERCHANT CREDIT CLAIM',
  'ACH TRANSACTION CAPITAL ONE PHONE PYMT',
  'TROPSUNPROD',
  'SOLAR NAILS',
  'EXETER AUTOREC',
  'VAPE CITY',
  'TXU BILL PAYME PURCHASE',
  'JOHNNYS',
  'VIASAT INFLIGHT',
  'BENNYS',
  'EAST COAST WINGS',
  'USPSCOM MOVERS PURCHASE',
  'STITCHFIXIN',
  'POSTNET',
  'SANDBOXX',
  'YOUNGJOY',
  'DAIRY QUEE',
  'INSUFFICIENT FUNDS FEE FOR A CARD PURCHASE DETAILS ALBERT INSTANT CA',
  'APPLECOMBI',
  'PATREON M',
  'PAYRANGE MOBILE APP OR',
  'CITY MART',
  'VICTORIASS',
  'CASH APP CA US CARD PURCHASE',
  'BOULEVARD',
  'POINT OF SALE DEBIT L DAVE COM VISA DIRECTCA',
  'FIREHOUS',
  'YOUTUBE TV CA',
  'AFFORDABLE',
  'COH PARKING METERS',
  'ATS CHECKING TO CHECKING',
  'DEBIT FOR EARNIN ACTIVE PAYMENTRET CO REF',
  'NNT HM',
  'AFTERPAY PURCHASE AFTERPAYCOM DE',
  'BIRMINGHAM',
  'FINEST',
  'MAURICES MAURICES',
  'DOORDASHHOOTERS',
  'DONUT STAR',
  'GTL',
  'FANDUEL BETFAIR',
  'ISPAPIMDS WITHDRWL PAI ISO HOUSTON TX FEE',
  'TOLLS',
  'FRIENDLY GUS',
  'ACCEPTANCENOWCOM',
  'CREDIT RESV PAYMENT',
  'WSDOT GOOD TO GO',
  'ANCANCESTRY DNA',
  'MUNICIPAL COURT',
  'BIG DADDY',
  'HOOK FISH CHICKEN',
  'ATM WITHDRAWAL PLUS CR',
  'BRISTOL FARMS',
  'AT HOME STORE PURCHASE',
  'ACACIA ENERGY LLC',
  'NONCHASE ATM WITHDRAW ND',
  'FASHION HOUSE',
  'SPEEDWAY MOBILE PURCHASE',
  'DISCOUNTTIRE',
  'ACH ELECTRONIC DEBIT CREDIT ONE BANK PAYMENT',
  'EXECUTIVE',
  'PLANET FITN',
  'TSI',
  'TIGER EXPRESS',
  'THE C STORE',
  'ELECTRONICACH DEBIT CAPITAL ONE MOBILE PMT',
  'ETAGS',
  'AROMA JOES',
  'LOTUS',
  'ELECTRONIC PMTWEB CAPITAL ONE MOBILE PMT',
  'CAPITAL ONE NA CAPITALONE',
  'STUDY',
  'HOUR FITNESS USA I',
  'NONCHASE ATM WITHDRAW SOU',
  'POS DEBIT VISA CHECK CARD NINTENDO AMERI',
  'STOP A MI',
  'CALIFORNI',
  'FRANKIES FUN PARK',
  'SECURITYFINANCEC',
  'STATERBRO PURCHASE',
  'HOME BANKINGTRANSFER WITHDRAWAL TOSHARE',
  'SELF LENDER PURCHASE',
  'PRIME VIDEOMO',
  'SCOTTS',
  'HELLOFRESH NY',
  'BPMAIN',
  'BUCKS',
  'CK MART',
  'GARDEN',
  'CCBILLCOM ONLYFANS PURCHASE',
  'POS MAC TARGET T',
  'QPWALMART',
  'LENDMARK FINANCIAL',
  'SEPTA WEBCARD',
  'TOTALVISA PURCHASE',
  'NONCHASE ATM WITHDRAW S H',
  'ATM WITHDRAWAL AVEN',
  'YEARBOOKS',
  'NAPA ONLINE',
  'KING CRAB',
  'SEC OF STATE ESERVICES',
  'ACH PROGRESSIVELEASEPMTS',
  'LENDUP LENDUPLOAN PPD ID',
  'MICROSOFT RTC BLDG',
  'VISA DDA PUR DOMINO S',
  'WESTLAKE FINAN',
  'POS DEBIT VISA CHECK CARD CHUCK E CHEESE',
  'NONCHASE ATM WITHDRAW E TH',
  'DISCORD NITROMONTHLY',
  'THE BUCKLE',
  'COX KANSAS COMM',
  'POS DEBIT VISA CHECK CARD MOES',
  'BPRICK',
  'DOLLAR TR HIGHWAY',
  'TAGGED',
  'CASH APPTHOMAS',
  'ENERGY MARKET',
  'GREYHOUND LINES CNP PURCHASE',
  'VBS LAKESHORELNS',
  'BPROCK',
  'VINEYARD VINES',
  'DISCOUNT LIQUO',
  'EASTLINELENDING',
  'ATM CASH WITHDRAWAL PM',
  'THE CHILDRENS PLAC',
  'PAGODA',
  'JASONS',
  'JD',
  'BRIGHT MONEY SAVING WEB ID',
  'SHAKING CRAB',
  'CREDIT ONE BA',
  'JENNYS BE',
  'KIWICO INC',
  'USACASH DEPOT VEND',
  'COMENITY CAPITAL BANK OH',
  'SAVAGE X FENTY',
  'SNACK',
  'YELLOW CAB',
  'AFFINITY APPS',
  'FAMOUSFOOTWEAR PURCHASE',
  'DIAMOND',
  'OUTFITTERS',
  'COINMACH HOUSTON',
  'ACI CREDIT ONE BAN',
  'CHEVRON JACKSONS FOOD S',
  'POS DEBIT VISA CHECK CARD VA ABC STORE',
  'CANTEEN CHARLOTTE',
  'TXDPS DRIVER LICENSE EGOVCOM',
  'IT WORKS',
  'LA MEXICANA',
  'SERVICE CHARGE NON TCF ATM',
  'SPIRIT A',
  'AT T BILL PAYMENT',
  'BESTBUY CO',
  'BEVMO ECOMMERCE',
  'TNSSMART WITHDRWL S LAS VEGAS',
  'POINT OF SALE DEBIT KLOVER APP BOOST',
  'HIBACHI GRILL SUPREME',
  'CAPITOL',
  'CLEARME',
  'AUNTIE ANNES CA',
  'WHOOP',
  'CRT CREDITCOMCRED',
  'BEAUTY AND BEYOND',
  'OH BUREAU MOTO',
  'DEBIT FOR ALBERT INSTANT EDI PYMNTS CO REF I',
  'KLARNA PAYMENT',
  'OUTLET',
  'CITY LIQUORS',
  'GAMEFLY',
  'SECURE',
  'DENNY',
  'PARAMOUNT',
  'RED ROBIN G',
  'GENGHIS GRILL TX',
  'APOCC VISW',
  'MR EXPRESS',
  'CLEO AI HTTPSWWWMEET DEUS',
  'PINK CLOVE',
  'STRAIGHTTALKAI',
  'BLIZZARD ENTERTAINM',
  'WAL MART',
  'TRIANGLE',
  'POS DEBIT VISA CHECK CARD NCOURT SERVICE F',
  'POS DAVECOM CA ON',
  'HOOKAH',
  'ANTHONYS PIZZA',
  'CASH APPELIZABETH CA',
  'QUADPAY DECKERS USA',
  'HAPPY MART',
  'VENTRA WEBSITE',
  'CASH APPRICHARD',
  'POS DEBIT VISA CHECK CARD WM SUPERCENTER',
  'CASH APPSHAY',
  'PACIFIC',
  'BLIZZARD',
  'BPBEST',
  'BYRNE DAI',
  'NEW YORK STATE',
  'OREGANOS',
  'POS DEBIT VISA CHECK CARD GTLINMATE PHONE S',
  'CONTACTS',
  'RECURRING PAYMENT RENT A CENTER ECOM',
  'CITY GAS',
  'BEEF O BRADY S',
  'AUTO INSURANCE UAIC',
  'CORELIFE EATERY',
  'SMART COMMUNICATIONS C',
  'MICROSOFTXBOX LIVE G WA',
  'MONTHLY MAINTENA',
  'CLOSED',
  'THE DOUGHNUT PALACE',
  'ZITOBOX',
  'TRANSF TO OD RECOVERY',
  'SKILLZ ESPORTS PURCHASE',
  'JR FOOD MART',
  'STREAMRAYHELP',
  'MICHAEL KORS PURCHASE',
  'ULTIMAMSBILLINFO',
  'POINT OF SALE DEBIT RACETRAC',
  'POS DEBIT VISA CHECK CARD VICTORIASSECRETCO',
  'MOBILE BANKING PAYMENT TO CREDIT CARD CARD',
  'LOW BOBS',
  'INSUFFICIENT FUNDSRETURNED ITEM DEBIT FOR EARNIN ACTIVE PAYMENTRET CO REF XXXXX',
  'O CHARLEY S',
  'DISCOVER PHONE PAY',
  'FROMYOUFLOW',
  'AM PM',
  'RED BOWL',
  'MR PICKLES',
  'FAST TRAC',
  'PREMIER BANKCARD LLC PURCHASE',
  'BRIGIT AR',
  'WINDSOR FASHIO PURCHASE',
  'INTERNAL TRANSFER',
  'VITAMIN WORLD',
  'ODP FEE FOR TRANS POSTED ON',
  'PRIME VIDEOMI',
  'MICROSOFT STOR',
  'NONCHASE ATM WITHDRAW E C',
  'ATM WITHDRAWAL NE',
  'PAYMENTUSSERVICEFEE PURCHASE',
  'ALDI MOBILE PURCHASE',
  'GOLDEN CITY',
  'T MAC ONLINE',
  'SNAPPYS',
  'DAVECOM CA US',
  'CHECK CARD PURCHASE LITTLE CAESARS',
  'FOOD CITY',
  'BROADWAY LIQUOR',
  'KONG BOX',
  'DELTA PURCHASE',
  'RACETRAC DALLAS TX',
  'LIDS PURCHASE LIDS',
  'BARSTOOL',
  'HINGE',
  'LOCAL MARKET',
  'POS DEBIT VISA CHECK CARD PAPA JOHNS',
  'EL BURRITO',
  'DISPUTE TEMP CREDIT REVERSAL',
  'DISCOUNT MART',
  'GARRETT POPCORN SHOPS',
  'LAZY DOG RESTAURAN',
  'MAGIK MARKET',
  'TIDAL WAVE AUTO SP',
  'EASYUP TRANSFER TO XXXXX',
  'MDG USA INC',
  'DUTCHBROSLL GRANTS PASS OR',
  'CTYCHGOSERVICEFEES IL',
  'CHAINSCALE INC',
  'FOOD CITY PURCHASE',
  'COMM ED RESI PMT',
  'CITY OF ATLANTA',
  'ORDERWISHCOM PURCHASE WWWWISHCOM',
  'TREX MART',
  'MY EYELAB',
  'MICROSOFTXBOX LIVE GOLDMMSBILLINFO',
  'FOXS PIZZA',
  'GEN KOREAN BBQ HOUSE',
  'FEEL BEAUTY SUPPLY',
  'LUMIN',
  'TMOBILEPOSTPAI',
  'LL HAWAIIAN BBQ',
  'WEB INITIATED PAYMENT AT CAPITAL ONE MOBILE PMT XXXXXXXXXXX',
  'JUVIAS PLACE',
  'RENTACE',
  'PATRIOT',
  'PAY TEL COMMUNICATIONS',
  'CELLAXS',
  'LILI',
  'CHILIS ECOMM PURCHASE OLOCOM',
  'BRAVO',
  'AVIVA',
  'GO PUFF PURCHASE',
  'SEES CANDY',
  'BIRD APP PURCHASE',
  'KLOVER APP BOOST CHICAGO IL POS DEBIT',
  'REGAL NAILS SALON',
  'ATI NURSING',
  'MAMAS',
  'CORNER PANTRY',
  'CANVAPTYLIM CA',
  'JOHNNYS PIZZA',
  'SUPER WASH',
  'PROG MARATHON',
  'OLB TRF FROM CK TO SV WITHDRAWAL',
  'TRANSFER TO REGIONS BANK',
  'N MAIN',
  'HALLOWEEN EXPRESS',
  'CASH APPJAY CA',
  'DESIGN',
  'LIRR ETIX TICKET',
  'LADY FOOT LOCKER',
  'PETES FR',
  'JAGEX',
  'ADORE ME',
  'ARCOAMPM',
  'FANSEDGE',
  'CHECKCARD ACICREDIT ONE BANK N NV',
  'MI TIENDA',
  'FAMOUS TOASTERY',
  'HAIR ZONE',
  'SHOE TIME',
  'ELIZABETH',
  'ATM WITHDRAWAL RIVE',
  'EATS PENDING',
  'NEXUSMODS',
  'THRIFT BOOKS GLOBA',
  'NONCHASE ATM WITHDRAW STATE',
  'INSTACART HTTPSINSTACACA',
  'DEL MAR',
  'AUNTIE ANNES PA',
  'GLOSSYBOX',
  'THE TAVERN',
  'PUNCH BOWL SOCIAL',
  'CONVERGENTTEX',
  'THE MENS WEARH',
  'DEBIT FOR CHECKCARD XXXXXX KFC G',
  'NONCHASE ATM WITHDRAW S MAI',
  'ACH PAYMENT DAVE INC DEBIT',
  'CASH APPBRITTANY PMNT SENT',
  'BPRUSHCO',
  'RECURRING PAYMENT STARZ ENTERTAINMEN CO',
  'BELLA PIZZA',
  'POS DEBIT VISA CHECK CARD CITY OF NORFOLK PA',
  'GAMESTOPCOR',
  'INCFILE',
  'AR USA',
  'SCOOTERS',
  'WOW BAO',
  'LENDINGCLUB',
  'GOOGLE PANDORA',
  'HELLO BELLO',
  'GOTINDERCOMHELP',
  'CHANEL',
  'PCA ATLANTA LOT',
  'CHEVRON GM OI',
  'ROSAUERS',
  'TD AUTO',
  'TARGET CARD SRVC BILL PAY WEB ID T',
  'WAL WALMAR',
  'STARS RESTAURANT',
  'ALLSTATE PAYMENT',
  'WINSTAR BEVERAGE',
  'OVERDRAFT CHARGE ITEMS PRESENTED',
  'DEBIT PURCHASE CARD ALDI',
  'FLIXBUSUS',
  'GENESISFS CARD RETRY PYMT',
  'VORTEX BAR GRILL',
  'DIRECT DEBIT BRIDGECREST DT RETAIL',
  'FLASH FOODS PURCHASE',
  'STOCKX PA',
  'NONCHASE ATM WITHDRAW E MAI',
  'OHIO TURNPIKE ATPM',
  'JIBJAB',
  'MICROSOFT STORE WA',
  'LUCKY SAN',
  'TRAVEL GUARD GROUP INC',
  'HOLLYWOOD FEED',
  'CHRIS',
  'FEDERAL',
  'GROOVE',
  'ROBOKILLER',
  'ATLAS',
  'ACH TRANSACTION ACORNS INVESTING TRANSFER',
  'TNSSMART WITHDRWL S LAS VEGAS LAS VEGAS NV FEE',
  'GRAND LIQUOR',
  'BPVAN',
  'K W CAFETERIAS',
  'DISCOUNT SMOKE',
  'WEST SIDE',
  'FASHION NOVA RETURNS',
  'DIVVY BIKE SHARE',
  'ACEABLE',
  'THE TWISTED CRAB',
  'SMOKE HOUSE',
  'ROSAS CAFE TORT',
  'DEPOSITED ITEM RETURNED ALTEREDFICTITI OF ITEMSCK DEP AMT DEP DATECK AMT SVC FEE',
  'TIFF S TREATS',
  'NOTARIZE INC',
  'MARTINS FUEL',
  'JUAN POLLO',
  'PIZZA EXPRESS',
  'VBS UBI CASH',
  'CAROLINA ALE',
  'USTOP',
  'ONLINE PAYMENT TO DX XXXXXX REF',
  'HSU ED',
  'CROCS INC CROCSCOM CO',
  'BRIDGE IT INC MEMBERSHIP',
  'GOLDEN NUGGET',
  'NUTRITIO',
  'HAAGEN DAZS PURCHASE',
  'CASH APP CHRIS',
  'COVINGTON CREDIT ABS',
  'GTLINMATE PHONE SVC PURCHASE',
  'VBS RAPID TITLE',
  'OPCEXETER',
  'INTERNET TRANSFER TO ONLINE SAVINGS ACCOUNT XXXXXX',
  'PYPL PA',
  'CAVENDERS BOOT',
  'SAMSCLUBCOM AR',
  'HLUHULU U HULUCOMBILL',
  'L GTN DEPT SAFETY',
  'ATM WITHDRAWAL MADIS',
  'CORAL',
  'WIKIPEDIA',
  'GCOHELPPAYCA',
  'CASH APPSHAWN',
  'U BAR',
  'DIVA NAILS',
  'TONYS FRESH MA',
  'NATHANS',
  'FACEAPP',
  'LOS ANGELES AIRPORT',
  'CASH APPKENNETH',
  'MI TIERRA',
  'AWL',
  'HONEY DEW DONUTS',
  'SHARIS CAFE A',
  'SIEPLAYSTATION',
  'USAA LOAN',
  'PENNSYLVANIA',
  'PLAYSTATIONNETWORK PURCHASE',
  'USAUSACANTEEN V PURCHASE',
  'GTL TELMATE INMATE SRV',
  'I LOVE',
  'BURLINGTON STORE',
  'GOLF CLUB',
  'FOOTLOCKER',
  'NETFLI',
  'ZAZZLECOMIN',
  'KIKOFF LENDING',
  'DEBIT PURCHASE VISA CARD VOLA',
  'TOWN AND COUNTRY',
  'RECURRING PAYMENT QUADPAY FASHION N HTTPSWWWQUAD',
  'RX MEDICAL',
  'PHANTOM FIREWORKS',
  'CREDIT ACCEPTAN',
  'CASH WITHDRAWAL A TELLER',
  'CHEKERS DRIVE IN',
  'SHOPHQ',
  'MSFT',
  'DOORDASHCHILIS GR STRIPECOM',
  'POS DEBIT VISA CHECK CARD CASH APPSTEPHANIE',
  'FGTEDCVEGAS',
  'SMART FOODSERVI',
  'ACH XFR WD TOSHARE',
  'CLICKPAY',
  'DOLLARSHAVECLUBUS CA',
  'PLAYSTUDIO',
  'BPLITTLE',
  'ORDERWISHCOM MOBILE PURCHASE WWWWISHCOM',
  'DESIGNS',
  'YOUTUBE ME',
  'WITHDRAWAL AT G ONLINE TRANSFER DTS TO SHARE',
  'FOODS',
  'DIRECT DEBIT EARNIN TO TIP JAR',
  'KICKS',
  'FILLTIREUS',
  'SNAPTRAVEL HOTEL DEALS',
  'ARIZONA PUBLIC SERVICE ELECTRIC',
  'RUBYS DINER',
  'VZWRLSSBILL PAY VW FL',
  'TRANSFER TO BALANCE ASSIST',
  'HOBBYTOWN',
  'INSTACART SUBSCRIP',
  'CHEVRONHALLMARK',
  'TIGER MART PURCHASE',
  'TOMS FAMOUS FAMILY',
  'HAVANA',
  'DISCOVER PAYMENTS WEB ID',
  'PRINTFUL INC',
  'AFRITICKETS',
  'DEPOSITED ITEM RETURNED',
  'BEEF JERKY OUTLET',
  'BRIDGE LENDING S',
  'MEXICO LINDO',
  'GAMEFLYMEMBERSHIP',
  'HOMEAGAIN',
  'BROWN DERBY',
  'SALTGRASS',
  'RAISIN',
  'SLING TV LLC CO',
  'CVS PHARMACY',
  'CENTRAL GAS',
  'SWEPCO BILLMATRIX',
  'TCC',
  'INSUFFICIENT OR UNAVAILABLE FUNDS ITEM RETURNED',
  'EBAY O SAN JOSE CA',
  'POS DEBIT VISA CHECK CARD TMOBILEPOSTPAID T',
  'DENNYS ONLINE',
  'DEPOSITED ITEM RETURNED NSF ST OF ITEMSCK DEP AMT DEP DATECK AMT',
  'JEA EZPAY',
  'BJS RESTAUR',
  'DOORDASHBUFFALO WIL WWWDOORDASH CA',
  'WOLFGANG PUCK',
  'EARNIN TO SAVINGS WEB ID',
  'CASH APPJASMIN',
  'ASPIRE MC',
  'JENI S SPLENDID IC',
  'PORTOS BAK',
  'CASH APPTAY',
  'EL CAMINO',
  'EVENT TICKET INSUR',
  'EZ SHOP',
  'BEALLS OUT PURCHASE',
  'LIQUOR CABINET',
  'POKE BAR',
  'CHIME VISA',
  'BUDDY BEAR CAR WASH',
  'AUTO AIR VACUUM SERVICE PURCHASE',
  'ACH DEBIT CREDIT ONE BANK PAYMENT',
  'COO MID',
  'USCONNECT ACEVN VE',
  'SECOND PRESENTMENT',
  'BB DINER',
  'VECTREN UTILITY PYMT',
  'BUCKY S',
  'SALT STRAW',
  'NSF CHARGE REBILL NONSUFFICIENT FUNDS FEE REBILL FDES NMO',
  'CAPITAL ONE CARD PYMT D PURCHASE',
  'EARNINACTIVEH PURCHASE',
  'AMZN DIGITAL PURCHASE',
  'POS DEBIT VISA CHECK CARD NEX FUEL',
  'CROWN FRIED C',
  'MTA LIRR ETIX TICKET',
  'QUICK QUACK CAR WASH',
  'COLD STONE CREAMER',
  'OVERDRAWN',
  'AFTERPAY US PURCHASE',
  'PRONTO PIZZA',
  'PICMONKEY LLC',
  'BPMALL',
  'YCC',
  'SPEEDWAY W',
  'LIRR STATION TIX',
  'KING CHEF',
  'NONCHASE ATM WITHDRAW WASHI',
  'YARDI SERVICE',
  'MERCHANTS TIRE',
  'FACTORY',
  'MORPHE PURCHASE',
  'CHANGE OF ADDRESS',
  'FEE REBILL ATM TRANSACTION FEE REBILL FDES NMO',
  'CASH APPASHLEY CA',
  'TAPPS',
  'DIRECT DEBIT ML PLUS MEMBRSHP MONEYLION',
  'SPEED',
  'EXTERNAL WITHDRAWAL PAYPAL INSTANT TRANSFER INST XFER',
  'CONDOM SENSE',
  'QVCOF PURCHASE',
  'CANTEEN DALLAS',
  'GRUBHUBCOLDSTONECREAM',
  'NONCHASE ATM WITHDRAW ROUT',
  'KFC G',
  'GAME CIRCUS',
  'IBISHOEDAZZLE CA',
  'PLAYRIX GAME',
  'KING GCOHELPPAYCA',
  'TIKTOK INC',
  'SOFTWARE FEE',
  'THE BAR',
  'ALTITUDE TRAMPOLINE PAR',
  'ACH TRANSACTION SNAP FINANCE PAYMENT',
  'WITHDRAWAL DAVE INC TYPE DEBIT ID CO DAVE INC ACH TRACE NUMBER',
  'ACH DEBIT ML PLUS MEMBRSHP MONEYLION',
  'CENTROHELPCOM',
  'VISTAPRVISTAPRINT',
  'AAA CA MEMBERSHIP WEB',
  'MAGNOLIA BAKERY',
  'UPTOWN BEAUTY SUPPLY',
  'WINNDIXIELIQUO',
  'SCNB',
  'CRO GIBRALTAR',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS SNAP FINANCE PAYMENT PPD ID',
  'USACANTEEN VENDING MOBILE PURCHASE',
  'CHECKE',
  'MANGO',
  'NEWKS',
  'RAFFERTYS',
  'CHECKCARD CRO ST JULIANS',
  'SERVICE CHARGEMONTHLY MAINTENANCE FEE',
  'RAYCONGLOBAL',
  'CONCORD ABC STORE',
  'VETSOURCE',
  'BLDRESUME PURCHASE',
  'ADVENTURE',
  'KLOVER HOLDINGS IL',
  'REGAL NAILS SALON SPA',
  'HARLAND CLARKE CHK ORDERS',
  'INSUFFICIENT FUNDS FEE FOR A ITEM DETAILS IRS USATAXPYMT PPD ID',
  'LIFE STORAGE ECOM',
  'WORLD ACCEPTANCE',
  'FAMOUS PIZZA',
  'ACH ELECTRONIC DEBIT BRIGITCOM MEMBERSHIP',
  'ULTIMATE REDMOND WA',
  'THE LOCAL',
  'FIRST CHOICE POWER',
  'NONCHASE ATM WITHDRAW S S',
  'STREAM ENERGY',
  'WM DIRECT L',
  'LONG JOHN',
  'DIRECT DEBIT COMENITY PAY VI WEB PYMT P',
  'CARD KLOVER APP BOOST',
  'FAMILY THRIFT CENTER',
  'STATE ROAD',
  'CASH WITHDRAWAL P NON CITI ATM PAI ISO',
  'AUNTIE ANNES VA',
  'TASTY CRAB',
  'TOOTNTOTUM',
  'ONLINE SCHEDULED TRANSFER TO CHK CONFIRMATION',
  'SOUTHERN CAL EDISON',
  'HAUTELOOKIN',
  'PLAZA LIQUORS',
  'BOMGAARS',
  'MOBILE PMT CAPITAL ONE',
  'INTERNATIONAL POS FEE VIS CD',
  'ACH TRANSACTION GRAIN TECHNOLOGY GRAIN TECH',
  'HURRICANE',
  'GMA SECURE',
  'DEBIT CARD PURCHASE XXXXX ATTBILL PAYMENT XXXXX',
  'WU SANTANDER CNSMR US TX',
  'ASURIONWIRELESS I',
  'FINE WINE',
  'USAVEND AT AIR',
  'PRIME VIDEOMZ',
  'ATM WITHDRAWAL N CL',
  'WINALL',
  'ROSS DRESS',
  'BUZZY BEE',
  'BRIDGECREST DT RETAIL PPD ID',
  'COUSIN VINNYS PIZZA',
  'LUCENTPAY',
  'VARIETY WHOLESALERS ROS',
  'KINGSGROUP',
  'MONTHLY WWWBOXYCHARM FL',
  'SOUTH',
  'IDETSY',
  'FREEPRINTS CA',
  'OVERDRAFT FEE FOR A TRANSACTION POSTED ON RECURRING PAYMENT AUTHORI ZED ON EXPERIAN CREDIT R',
  'ATM WITHDRAWAL S T',
  'DEPT EDUCATION STUDENT LN',
  'STEVEMADDEN',
  'DIGIT FUNDS XFER HELLO DIGIT INC WEB ID',
  'KIKOFF LENDING LLC',
  'MAC TOOLS',
  'POS DEBIT VISA CHECK CARD BATH BODY WORKS',
  'FUEL STATION',
  'VIA NY',
  'ROKU FOR WWE',
  'NONCHASE ATM WITHDRAW PACI',
  'FARM STORE',
  'ULTIMA',
  'FOOD CORP',
  'CASH APPMARIA',
  'BATH BODY WORK',
  'WASHINGTON',
  'SP FASHIONNOVACOM',
  'CASH APPJOHNATHAN',
  'CHOICECREDITSCORE',
  'ATM WITHDRAWAL RICH',
  'OYOBOOKING',
  'TOWN PUMP',
  'DICKS SPORTINGGOODS',
  'DEBIT FOR EMPOWER TRANSFER CO REF',
  'PRO IMAGE',
  'SUPA CENT',
  'OIL CHANGER',
  'PROPERT',
  'JOES CRAB SHACK',
  'DEBIT FOR CHECKCARD XXXXXX LITTLE CAESARS',
  'MOES ORIGINAL BBQ',
  'UNION AVE',
  'UNITED CREDIT EDUCATIO',
  'TEXAS DE BRAZIL',
  'ACH ELECTRONIC DEBIT ALBERT GENIUS EDI PYMNTS P',
  'CHECKCARD CRO ST JULIANS INTERNATIONAL TRANSACTION FEE',
  'TIFFANY',
  'GRAVITY COFFEE',
  'ACH DEBIT BRIGIT DEBIT',
  'MEIJER GROCER',
  'NORMS',
  'WM SUPERC WAL PURCHASE WM SUPERC WALMAR',
  'DOORDASHTGI FRIDAYS',
  'BILLIE INC WWWMYBILLIE NY',
  'KAISER PERMANENTE HEALTH CARE',
  'PINK STORE',
  'BALTIMORE GAS RESIDENT',
  'ORIENT',
  'CENTER MARKET',
  'AMZN MKTP USM AMZNCOMBILL',
  'EARNINACTIVEHOURPAYMENTRET',
  'ALEXANDRIA',
  'LIQUOR EXPRESS',
  'KRYSTAL MNT',
  'DELTA SONIC',
  'FAMILY MART',
  'EDDIE WORLD',
  'CASH AISLE',
  'GRUBHUBBOSTONMARKET',
  'ACH POSSIBLE FINANCEPAYMENT',
  'PUERTO',
  'RUTTERS FARM STRE',
  'EDDIE VS',
  'WELLS FARGO CARD CCPYMT WEB ID',
  'TMOBILE POSTPAID I',
  'CUMBERLAND COUNTY',
  'ONLINE TRANSFER TO SHARE',
  'ACH ONLINE BANKING DEBIT',
  'THRIVE CAUSEMETICS',
  'LA MICHOACANA DALLAS',
  'POINT OF SALE DEBIT L DATE CLEO',
  'MIDWAYUSA',
  'TOTAL WI',
  'SMOKERS WORLD',
  'CHECKCARD DOORDASH CA',
  'WEB INITIATED PAYMENT AT EARNIN ACTIVE PAYMENTRET XXXXXXXXXXX',
  'BET MGM',
  'ZIPS WASH CLUB',
  'POS DEBIT VISA CHECK CARD QT INSIDE',
  'NOOM DAY TRIAL',
  'KOBE JAPANESE STEA',
  'NSF FEE ITEM RETURNED ACH EMPOWER INC ACH EMPOWER INC',
  'SALVATORES PIZZA',
  'PREMIUM OD USAGE FEE',
  'HEMPER',
  'JOSE TEQUILAS',
  'PIZZA MY HEART',
  'USA FIVE STAR FOOD VEND',
  'PRIME VIDEOMB',
  'DAYS INNS DAYSTOP',
  'TELESALE',
  'THUMBTACK',
  'MCCS VENDING',
  'POS PURCHASE',
  'POLLO TROPICA',
  'ALBERT GENIUS WALNUT CA US',
  'ROKU PURCHASE',
  'SHANGHAI',
  'KABBAGE',
  'VENDOSTORE',
  'VZWRLSSIVR VN',
  'NO ORIG DAIQUIRI',
  'ALLBLKTV',
  'ORIG CO NAMEEMPOWER INC CO ENTRY DESCR SECPPD ORIG ID',
  'PAYPAL INST XFER PLAYSTATION WEB ID PAYPALSI',
  'MONTHLY',
  'POS DEBIT VISA CHECK CARD VESTA AT T PREPA',
  'MICROSOFTXBOX LIVE GO MSBILLINFO',
  'AARONS ONLINE PAYMEN GA',
  'GREEN TEA',
  'HARBOR',
  'CAPITAL ONE BNK DIVR VA',
  'KMF KMFUSA',
  'SMITHSFO',
  'BUILDABEAR',
];
export type Merchant = typeof Merchants[number];

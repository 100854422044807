import { TLogin } from 'types/types';
import { auth } from 'utils/firebase';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

const _authenticate = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((auth) => auth)
    .catch((err) => err);
};

export const getAuthentication = async (payload: TLogin) => {
  const user = await _authenticate(payload.email, payload.password);
  if (user) {
    return {
      status: 'success',
      code: 200,
      res: {
        uid: user.user.uid,
        email: user.user.email,
      },
      message: 'Logged in success!',
    };
  } else {
    return {
      status: 'failure',
      code: 400,
      res: {
        uid: undefined,
        email: undefined,
      },
      message: 'Invalid username or password',
    };
  }
};

export const logOutUser = async () => {
  await signOut(auth);
  return {
    status: 'success',
    code: 200,
    message: 'Logged out successfully!',
    res: {},
  };
};

export const resetPassword = async (email: string) => {
  await sendPasswordResetEmail(auth, email);
  return {
    status: 'success',
    code: 200,
    message: 'Password reset link shared to your email account.',
    res: {},
  };
};

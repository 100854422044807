import nanoid from 'utils/nanoid';
import { Select } from '@klover/attain-design-system';
import {
  fetchAllGroups,
  setCurrentSalesLiftStudyOffers,
  setStepInvalid,
  setStepValid,
} from '../slice';
import { fetchGroup } from '../../offerWallManager/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';

//Material UI
import {
  FormControl,
  InputLabel,
  MenuItem,
  ThemeProvider,
  createTheme,
} from '@material-ui/core';

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
  },
});

const OfferGroupSelect = () => {
  const dispatch = useAppDispatch();

  const currentGroup = useAppSelector(
    (state) => state.offerWallReducer.currentGroup
  );
  const offergroupid = currentGroup ? String(currentGroup.id) : '';

  if (currentGroup) {
    dispatch(setStepValid({ keyName: 'offerGroupSelect' }));
  } else {
    dispatch(setStepInvalid({ keyName: 'offerGroupSelect' }));
  }

  useEffect(() => {
    dispatch(fetchAllGroups());
  }, []);
  const groupsEntities = useAppSelector(
    (state) => state.salesLiftStudiesReducer.entities.groups
  );
  const changingOfferGroup = (groupid) => {
    dispatch(fetchGroup({ id: groupid }));
    dispatch(setCurrentSalesLiftStudyOffers());
  };

  return (
    <div>
      <ThemeProvider theme={dialog}>
        <FormControl variant="outlined">
          <Select
            style={{ maxHeight: 48, width: 300 }}
            onChange={(e) => changingOfferGroup(e.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            value={offergroupid}
          >
            {groupsEntities.allIds.map((groupid) => {
              return (
                <MenuItem
                  key={nanoid()}
                  style={{ backgroundColor: 'white' }}
                  value={groupid}
                >
                  {groupsEntities.byId[groupid].name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ThemeProvider>
    </div>
  );
};

export default OfferGroupSelect;

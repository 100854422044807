export const Relationships = [
  '',
  'Single, never married',
  'Married',
  'Separated, divorced or widowed',
  'Domestic partnership/living with someone',
  'Prefer not to answer',
];

export type Relationship = typeof Relationships[number];

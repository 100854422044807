import { Button } from '@klover/attain-design-system';
import ErrorMsg from 'components/ErrorMsg';
import Field from 'components/Field';
import React from 'react';
import Toggle from 'components/Toggle';
import _ from 'lodash';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormikProvider, useFormik } from 'formik';
import { Offer } from '../slice';
import { OfferWallContext } from 'context';
import { Tooltip } from 'antd';
import { nanoid } from 'nanoid';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';
import * as Yup from 'yup';
import { Dialog, theme, Grid as AttainGrid } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@klover/attain-design-system';
import {
  PLEASE_FILL_OUT_ALL_POSSIBLE_RESPONSES,
  SUCCESSFULLY_CREATED_AB_TEST,
  SELECT_OFFER_GROUP_OPTIONAL,
  SELECT_PLACEMENT,
  AB_TEST_NAME,
  AB_TEST_1,
  TEST_TYPE,
  PERCENTAGE,
  CREATE,
  EDIT,
  SAVE,
  STEP_1,
  STEP_2,
  RANDOM,
  SLOTS,
  STATUS,
  STEP_3,
  CLOSE,
  ALL_OFFERS,
  SEARCH_OFFERS,
  AVAILABLE_OFFERS,
  SELECTED_OFFERS,
  PLACEMENT_TYPE
} from '../../../content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    selectDropdown: {
      minWidth: 130,
      height: '50px',
      '& fieldset': {
        top: '-12px !important'
      }
    }
  })
);

const CreateAbTest = () => {
  const {
    setShowCreateAbTest,
    setAbTests,
    abTests,
    setExistingAbTest,
    existingAbTest,
    setSnackBarOptions,
  } = React.useContext(OfferWallContext);
  const classes = useStyles();
  const [groups, setGroups] = React.useState([]);
  const [groupid, setGroupid] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [availableOffers, setAvailableOffers] = React.useState([]);
  const [selectedOffers, setSelectedOffers] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const selectedMap = selectedOffers.reduce(
    (a, { offerid }: Offer) => ({ ...a, [offerid]: 1 }),
    {}
  );

  const handleSave = (payload) => {
    setSaving(true);
    if (existingAbTest) {
      axios
        .put(
          `${process.env.API_BASE_ADDRESS}/ab-tests/${existingAbTest}`,
          payload
        )
        .then(() => {
          const filteredAbTests = abTests.filter(
            ({ abtestid }) => abtestid != existingAbTest
          );
          setAbTests([...filteredAbTests, payload]);
          setSnackBarOptions({
            open: true,
            message: SUCCESSFULLY_CREATED_AB_TEST,
            error: false,
          });
          setSaving(false);
        })
        .catch((e) => {
          const errorMessages =
            e.response.data.errors &&
            e.response.data.errors.map((error) => error.message);
          setSaving(false);
          setSnackBarOptions({
            open: true,
            message: errorMessages.join('/n'),
            error: true,
          });
        });
    } else {
      axios
        .post(`${process.env.API_BASE_ADDRESS}/ab-tests/`, payload)
        .then((res) => {
          setAbTests([...abTests, res.data]);
          setSaving(false);
          setSnackBarOptions({
            open: true,
            message: SUCCESSFULLY_CREATED_AB_TEST,
            error: false,
          });
        })
        .catch((e) => {
          const errorMessages =
            e.response.data.errors &&
            e.response.data.errors.map((error) => error.message);
          setSaving(false);
          setSnackBarOptions({
            open: true,
            message: errorMessages.join('/n'),
            error: true,
          });
        });
    }
  };

  const validationSchema = Yup.object().shape({
    label: Yup.string().required('A label is required.'),
    high: Yup.number()
      .integer('Percentage must be an integer.')
      .min(1, 'There should be at least 1 percent.')
      .required('The percentage is required'),
    slots: Yup.number()
      .integer('Slots must be an integer.')
      .min(1, 'There should be at least 1 slot.')
      .required('The number of slots are required.'),
  });

  const formik = useFormik({
    initialValues: {
      abtestid: '',
      label: '',
      low: 0,
      high: 0,
      slots: 0,
      placementid: 1,
      testingtype: 'randomizer',
      isactive: false,
    },
    validationSchema,
    onSubmit: (values) => {
      const modifiedValues = _.clone(values);
      modifiedValues.placementid = parseInt(modifiedValues.placementid);
      if (slots) {
        modifiedValues.slots = parseInt(modifiedValues.slots);
      } else {
        modifiedValues.slots = 0;
      }
      if (high) {
        modifiedValues.high = parseInt(modifiedValues.high);
      } else {
        modifiedValues.high = 0;
      }
      modifiedValues.offers = selectedOffers.map(({ offerid }) => ({
        offerid,
      }));
      handleSave(modifiedValues);
    },
  });
  const { high, placementid, testingtype, slots, isactive } = formik.values;

  const handleFetchAvailableOffers = async (placementid: number) => {
    try {
      const res = await axios.get(
        `${process.env.API_BASE_ADDRESS}/offer-manager/offers?abtests=true&placementid=${placementid}`
      );
      setAvailableOffers(
        res.data.map((offer: Offer) => ({
          ...offer,
          offerid: `${offer.offerid}`,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    axios
      .get(`${process.env.API_BASE_ADDRESS}/offer-manager/offer-groups`)
      .then((res) => {
        setGroups(res.data);
      })
      .catch((e) => {
        console.log('Errors:', e);
      });
    handleFetchAvailableOffers(placementid);
    if (existingAbTest) {
      axios
        .get(`${process.env.API_BASE_ADDRESS}/ab-tests/${existingAbTest}`)
        .then((res) => {
          const {
            offers,
            placementid,
            slots,
            testingtype,
            isactive,
            high,
            low,
            label,
            abtestid,
          } = res.data;
          formik.setValues({
            abtestid,
            placementid,
            slots,
            testingtype,
            isactive,
            high,
            low,
            label,
          });
          setSelectedOffers(offers);
        })
        .catch((e) => {
          console.log('Errors:', e);
        });
    }
  }, []);
  const filteredOffers = availableOffers.filter(
    ({ offerid, offergroupid, label, abtestid }) => {
      if (abtestid) return false;
      if (groupid) {
        if (offergroupid != groupid) return false;
      }
      if (search) {
        if (label && !label.toUpperCase().includes(search.toUpperCase()))
          return false;
      }
      if (selectedMap) {
        if (selectedMap[offerid]) return false;
      }
      return true;
    }
  );
  const onDragEnd = (res) => {
    const { destination, source } = res;
    if (!destination) return;
    const sourceIndex = source.index;
    const sourceId = source.droppableId;
    const destinationId = destination.droppableId;
    const destinationIndex = destination ? destination.index : sourceIndex;
    const newSelection = _.clone(selectedOffers);
    if (destinationId == 'selectedOffers') {
      if (sourceId == 'availableOffers') {
        const [removed] = filteredOffers.splice(sourceIndex, 1);
        newSelection.splice(destinationIndex, 0, removed);
      } else {
        const [removed] = newSelection.splice(sourceIndex, 1);
        newSelection.splice(destinationIndex, 0, removed);
      }
    } else if (destinationId == 'availableOffers') {
      newSelection.splice(sourceIndex, 1);
    }
    setSelectedOffers(newSelection);
  };

  const validationErrors = Object.values(formik.errors);

  return (
    <FormikProvider value={formik}>
      <Dialog
        fullWidth
        maxWidth="md"
        className={classes.root}
        onClose={() => {
          setShowCreateAbTest(false);
          setExistingAbTest('');
        }}
        open={true}
        headerID="abTestHeader"
        title=''
        header={
          <Styled.CardHeader>
            <Styled.CardTitle>{`${existingAbTest ? EDIT : CREATE} A/B Test`}</Styled.CardTitle>
          </Styled.CardHeader>
        }
        bodyID="abTestBody"
        footer={
          <>
            <Tooltip
              zIndex={999999999999999}
              title={
                validationErrors && validationErrors.length
                  ? validationErrors.map((error) => {
                    if (typeof error == 'string') return <div>{error}</div>;
                    if (error.length && _.isArray(error))
                      return (
                        <div>{PLEASE_FILL_OUT_ALL_POSSIBLE_RESPONSES}</div>
                      );
                    return null;
                  })
                  : null
              }
            >
              <span>
                <Button
                  variant="contained"
                  onClick={() => {
                    formik.handleSubmit();
                    setShowCreateAbTest(false);
                  }}
                  disabled={saving || validationErrors.length > 0}
                >
                  {saving ? <CircularProgress size={20} /> : SAVE}
                </Button>
              </span>
            </Tooltip>
            <Button
              onClick={() => {
                setShowCreateAbTest(false);
                formik.resetForm();
              }}
              variant="outlined"
            >
              {CLOSE}
            </Button>
          </>
        }
        body={
          <Styled.StyledBodyContainer
            container
            spacing={theme.spacing.lg}
            direction="column"
          >
            <AttainGrid item>
              <DragDropContext onDragEnd={onDragEnd}>
                <Grid.Row>
                  <Grid.Col xs={6}>
                    <Styled.SubHeaderClean>{STEP_1}</Styled.SubHeaderClean>
                    <Field
                      label={AB_TEST_NAME}
                      name="label"
                      placeholder={AB_TEST_1}
                      fullWidth
                    />

                    <ErrorMsg name="label" />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={6}>
                    <Styled.SubHeader>{STEP_2}</Styled.SubHeader>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  {!existingAbTest ? (
                    <Grid.Col xs={4}>
                      <Styled.InputElementLabel>
                        {SELECT_PLACEMENT}
                      </Styled.InputElementLabel>
                      <Select
                        name="select"
                        id="placementid"
                        value={placementid}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleFetchAvailableOffers(e.target.value);
                        }}
                        style={{ width: '100%' }}
                        className={classes.selectDropdown}
                      >
                        <MenuItem value="1" key="1">{PLACEMENT_TYPE.OFFER_WALL}</MenuItem>
                        <MenuItem value="2" key="2">{PLACEMENT_TYPE.DASHBOARD}</MenuItem>
                        <MenuItem value="3" key="3">{PLACEMENT_TYPE.KLOVER_PLUS}</MenuItem>
                        <MenuItem value="4" key="4">{PLACEMENT_TYPE.ONBOARDING}</MenuItem>
                      </Select>
                    </Grid.Col>
                  ) : null}
                  <Grid.Col xs={2}>
                    <Styled.InputElementLabel>
                      {TEST_TYPE}
                    </Styled.InputElementLabel>
                    <Select
                      name="select"
                      id="testingtype"
                      value={testingtype}
                      onChange={formik.handleChange}
                      className={classes.selectDropdown}
                    >
                      <MenuItem value="randomizer" key="randomizer">{RANDOM}</MenuItem>
                    </Select>
                  </Grid.Col>
                  <Grid.Col xs={2}>
                    <Field
                      label={PERCENTAGE}
                      name="high"
                      value={high}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (!value) {
                          formik.handleChange(e);
                        } else {
                          if (value >= 0 && value <= 100) {
                            formik.handleChange(e);
                            if (value.includes('.')) {
                              formik.setFieldValue(`high`, parseInt(value));
                            }
                          }
                        }
                      }}
                      type="number"
                      placeholder="%"
                      fullWidth
                    />

                    <ErrorMsg name="high" />
                  </Grid.Col>
                  <Grid.Col xs={2}>
                    <Field
                      label={SLOTS}
                      name="slots"
                      value={slots}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (!value) {
                          formik.handleChange(e);
                        } else {
                          if (value >= 0 && value <= 100) {
                            formik.handleChange(e);
                            if (value.includes('.')) {
                              formik.setFieldValue(`slots`, parseInt(value));
                            }
                          }
                        }
                      }}
                      type="number"
                      fullWidth
                    />

                    <ErrorMsg name="slots" />
                  </Grid.Col>
                  <Grid.Col>
                    <Styled.Label>{STATUS}</Styled.Label>
                    <Toggle
                      checked={isactive}
                      onClick={() => formik.setFieldValue('isactive', !isactive)}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={6}>
                    <Styled.SubHeader>{STEP_3}</Styled.SubHeader>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={6}>
                    <Field
                      as="select"
                      label={SELECT_OFFER_GROUP_OPTIONAL}
                      name="group"
                      value={groupid}
                      onChange={(e) => setGroupid(e.target.value)}
                      fullWidth
                    >
                      <option value="">{ALL_OFFERS}</option>
                      {groups && groups.length
                        ? groups.map(({ name, id }) => (
                          <option value={id} key={nanoid()}>
                            {name}
                          </option>
                        ))
                        : null}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <Field
                      label={SEARCH_OFFERS}
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder={'e.g Calypso Beauty'}
                      fullWidth
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={6}>
                    <Styled.SubHeader>{AVAILABLE_OFFERS}</Styled.SubHeader>
                    <Droppable droppableId="availableOffers">
                      {(droppableProvided) => (
                        <Styled.OfferList ref={droppableProvided.innerRef}>
                          {filteredOffers.map((offer: Offer, index) => (
                            <Draggable
                              key={offer.offerid}
                              draggableId={offer.offerid}
                              index={index}
                            >
                              {(draggableProvided) => (
                                <Styled.OfferListItem
                                  disabled={false}
                                  selected={false}
                                  key={index}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                >
                                  {offer.offerid ? `(ID: ${offer.offerid}) ` : ''}
                                  {offer.label ? offer.label : ''}
                                </Styled.OfferListItem>
                              )}
                            </Draggable>
                          ))}
                        </Styled.OfferList>
                      )}
                    </Droppable>
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <Styled.SubHeader>{SELECTED_OFFERS}</Styled.SubHeader>
                    <Droppable droppableId="selectedOffers">
                      {(droppableProvided) => (
                        <Styled.OfferList ref={droppableProvided.innerRef}>
                          {selectedOffers.map((offer: Offer, index) => (
                            <Draggable
                              key={offer.offerid}
                              draggableId={offer.offerid}
                              index={index}
                            >
                              {(draggableProvided) => (
                                <Styled.OfferListItem
                                  disabled={false}
                                  selected={false}
                                  key={index}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                >
                                  {offer.offerid ? `(ID: ${offer.offerid}) ` : ''}
                                  {offer.label ? offer.label : ''}
                                </Styled.OfferListItem>
                              )}
                            </Draggable>
                          ))}
                        </Styled.OfferList>
                      )}
                    </Droppable>
                  </Grid.Col>
                </Grid.Row>
              </DragDropContext>
            </AttainGrid>
          </Styled.StyledBodyContainer>
        }
      />
    </FormikProvider>
  );
};

export default CreateAbTest;

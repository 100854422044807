import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import {
  Button,
  Dialog,
  Grid,
  TextField,
  theme,
} from '@klover/attain-design-system';
import {
  ToggleDuplicateAudienceDialogAction,
  duplicateAudience,
  toggleDuplicateAudienceDialog,
} from '../slice';
import { useUser } from 'reactfire';

// Material UI Components
import DialogActions from '@material-ui/core/DialogActions';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import * as Styled from './index.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    audienceName: {
      padding: '0px !important',
    },
  })
);

interface Props {
  open: boolean;
}

const DuplicateAudienceDialog = ({ open }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { data: user } = useUser();
  const currentUser = user || { email: '' };

  const currentAudience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );

  const initialAudienceName =
    currentAudience && currentAudience.name
      ? `${currentAudience.name} (copy)`
      : '';

  const [audienceName, setAudienceName] = useState(initialAudienceName);

  const dispatchClose = () => {
    setAudienceName(initialAudienceName);
    const actionProps: ToggleDuplicateAudienceDialogAction = {
      open: false,
    };
    dispatch(toggleDuplicateAudienceDialog(actionProps));
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const audienceName = event.target.value as string;
    setAudienceName(audienceName);
  };

  const handleDuplicate = () => {
    const updatedAudience = {
      ...currentAudience,
      created_by_email: currentUser.email,
      updated_by_email: currentUser.email,
    };
    dispatch(
      duplicateAudience({ audience: updatedAudience, name: audienceName })
    );
  };

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const isAudienceLoading = audienceLoading.state === 'loading';

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      headerID="duplicateAudienceGroupHeader"
      header={
        <Styled.CardHeader>
          <Styled.CardTitle>Duplicate Audience</Styled.CardTitle>
        </Styled.CardHeader>
      }
      bodyID="duplicateAudienceGroupBody"
      body={
        <Styled.StyledBodyContainer
          container
          spacing={theme.spacing.lg}
          direction="column"
        >
          <Grid item>
            <Styled.BodyHeader>
              How do you want to name the duplicate audience?
            </Styled.BodyHeader>
            <TextField
              required
              disabled={isAudienceLoading}
              className={classes.audienceName}
              onChange={handleNameChange}
              value={audienceName}
              fullWidth
              id="duplicate-audience-name"
              placeholder="Audience Name"
              type="text"
              variant="outlined"
            />
          </Grid>
        </Styled.StyledBodyContainer>
      }
      footer={
        <>
          <DialogActions>
            <Button
              color="primary"
              disabled={isAudienceLoading}
              onClick={handleClose}
              variant="outlined"
            >
              Cancel
            </Button>

            <Button
              color="primary"
              disableElevation
              disabled={!audienceName.length || isAudienceLoading}
              onClick={handleDuplicate}
              variant="contained"
            >
              Duplicate Audience
            </Button>
          </DialogActions>
        </>
      }
    />
  );
};

export default DuplicateAudienceDialog;

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from 'redux/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    requiredRow: {
      display: 'flex',
    },
    requiredRowEmoji: {
      paddingRight: '0.5em',
    },
    messageTextAlignment: {
      textAlign: 'left',
      padding: 10,
    },
  })
);

const checkMark = String.fromCodePoint(9989);
const xMark = String.fromCodePoint(10060);

const SaveDisabledButtonMessage = () => {
  const classes = useStyles();
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  return (
    <div className={classes.messageTextAlignment}>
      <div>{"Hi! Looks like you're trying to save"}</div>
      <br />
      <div>To save make sure you have</div>
      <br />
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {currentSalesLiftStudy?.name ? checkMark : xMark}
        </div>
        <div>Sales Lift Study Name</div>
      </div>
      <br />
    </div>
  );
};

export default SaveDisabledButtonMessage;

import axios from 'axios';
import { auth } from './firebase';

const getAuthorizedAxiosInstance = async () => {
  const res = await auth.currentUser?.getIdTokenResult();
  let authorizedAxiosInstance = null;
  if (res) {
    authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
  }
  return authorizedAxiosInstance;
};

export default getAuthorizedAxiosInstance;

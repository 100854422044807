import CircularProgress from '@material-ui/core/CircularProgress';
import Field from 'components/Field';
import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormikProvider, useFormik } from 'formik';
import { Offer, addNotification, updateOfferCreativeRotation } from '../slice';
import { OfferWallContext } from 'context';
import { Tooltip } from 'antd';
import { useAppDispatch } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';
import * as Yup from 'yup';
import { Button } from '@klover/attain-design-system';
import GhostLoader from 'react-ghost-loader';
import { Dialog, theme } from '@klover/attain-design-system';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { Grid as MiUiGrid } from '@material-ui/core';
import { MenuItem, Select } from '@klover/attain-design-system';
import { ALL_OFFERS, AVAILABLE_OFFERS, CANCEL, CREATE, DRAG_ATLEAST_2_OFFERS, EDIT, ERROR_FETCHING_CREATIVE_OFFERS, ERROR_FETCHING_GROUPS, ERROR_FETCHING_OFFERS, NO_OFFERS_AVAILABLE, NO_OFFERS_SELECTED, PLACEMENT_TYPE, ROTATION_GROUP_NOTE, ROTATION_MINIMUM_OFFERS, ROTATION_PERCENTAGES_REQUIRED, ROTATION_PERCENTAGES_VALIDATION_ERROR, SAVE, SEARCH_OFFERS, SELECT_OFFER_GROUP_OPTIONAL, SELECT_PLACEMENT, SELECTED_OFFERS, STEP_1, STEP_2, THERE_DOESNT_SEEM_TO_BE_ENOUGH_OFFERS } from '../../../content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    selectDropdown: {
      minWidth: 250,
      height: '50px',
      '& fieldset': {
        top: '-12px !important'
      }
    }
  })
);

const validWeights = () =>
  Yup.array().test({
    name: 'are-selected-offer-weights-valid',
    test: function (values) {
      if (values && values.length) {
        if (values.length >= 1) {
          if (_.every(values.map(({ weight }) => weight !== null))) {
            if (values.every(({ weight }) => _.isInteger(weight))) {
              const totalWeight = values.reduce(
                (a, { weight }) => (weight ? (a += weight) : a),
                0
              );
              if (totalWeight !== 100) {
                return this.createError({
                  message: ROTATION_PERCENTAGES_VALIDATION_ERROR,
                  path: 'selectedOffers',
                });
              }
            } else {
              return this.createError({
                message: ROTATION_PERCENTAGES_VALIDATION_ERROR,
                path: 'selectedOffers',
              });
            }
          } else {
            return this.createError({
              message: ROTATION_PERCENTAGES_REQUIRED,
              path: 'selectedOffers',
            });
          }
        } else {
          return this.createError({
            message: ROTATION_MINIMUM_OFFERS,
            path: 'selectedOffers',
          });
        }
        return true;
      } else {
        return this.createError({
          message: ROTATION_MINIMUM_OFFERS,
          path: 'selectedOffers',
        });
      }
    },
  });

const CreateOfferRotation = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    existingCreativeRotation,
    setExistingCreativeRotation,
    setShowCreativeRotation,
  } = React.useContext(OfferWallContext);
  const dispatch = useAppDispatch();
  const validationSchema = Yup.object().shape({
    selectedOffers: validWeights(),
  });
  const formik = useFormik({
    initialValues: {
      placement: 1,
      inputField: '',
      offers: [],
      groups: [],
      selectedOffers: [],
      search: '',
      offersLoading: false,
      groupsLoading: false,
      selectedLoading: false,
      groupid: '',
    },
    validationSchema,
    onSubmit: ({ selectedOffers }) => {
      const { offerid } = selectedOffers[0];
      const body = {
        parent: offerid,
        children: selectedOffers.map(({ offerid, weight }) => ({
          offerid,
          weight,
        })),
      };
      axios
        .post(
          `${process.env.API_BASE_ADDRESS}/offer-manager/offers/creative-rotation`,
          body
        )
        .then(() => {
          dispatch(updateOfferCreativeRotation(body));
          dispatch(
            addNotification({
              state: 'done',
              message: `Successfully ${existingCreativeRotation ? 'updated' : 'created'
                } rotation`,
            })
          );
          history.push('/main/offer-wall-manager/creative-rotations');
        });
      setExistingCreativeRotation(null);
      setShowCreativeRotation(false);
      formik.resetForm();
    },
  });

  const {
    placement,
    groups,
    offers,
    search,
    offersLoading,
    groupsLoading,
    selectedLoading,
    groupid,
    selectedOffers,
  } = formik.values;

  const offerToIdMap = offers.reduce(
    (a, offer) => ({ ...a, [offer.offerid]: offer }),
    {}
  );

  React.useEffect(() => {
    formik.setFieldValue('offersLoading', true);
    axios
      .get(`${process.env.API_BASE_ADDRESS}/offer-manager/offers?eligible=true`)
      .then((results) => {
        const currentTimestamp = Date.now();
        const filteredByDate = results.data.filter(
          ({ isactive, startdate, enddate }: Offer) => {
            if (isactive && startdate && enddate) {
              if (
                !(startdate <= currentTimestamp && enddate >= currentTimestamp)
              ) {
                return false;
              }
            }
            return true;
          }
        );
        formik.setFieldValue('offers', filteredByDate);
        formik.setFieldValue('offersLoading', false);
      })
      .catch((e) => {
        console.log('Error:', e);
        formik.setFieldValue('offers', []);
        formik.setFieldValue('offersLoading', false);
        dispatch(
          addNotification({
            state: 'error',
            message: ERROR_FETCHING_OFFERS,
          })
        );
      });
    axios
      .get(`${process.env.API_BASE_ADDRESS}/offer-manager/offer-groups`)
      .then((results) => {
        formik.setFieldValue('groups', results.data);
        formik.setFieldValue('groupsLoading', false);
      })
      .catch((e) => {
        console.log('Error:', e);
        formik.setFieldValue('groups', []);
        formik.setFieldValue('groupsLoading', false);
        dispatch(
          addNotification({
            state: 'error',
            message: ERROR_FETCHING_GROUPS,
          })
        );
      });
    if (existingCreativeRotation) {
      formik.setFieldValue('selectedLoading', true);
      axios
        .get(
          `${process.env.API_BASE_ADDRESS}/offer-manager/offers/creative-rotation/${existingCreativeRotation.offerid}`
        )
        .then(({ data }) => {
          formik.setFieldValue('selectedOffers', data);
          formik.setFieldValue('selectedLoading', false);
        })
        .catch((e) => {
          console.log('Error:', e);
          formik.setFieldValue('selectedOffers', []);
          formik.setFieldValue('selectedLoading', false);
          dispatch(
            addNotification({
              state: 'error',
              message: ERROR_FETCHING_CREATIVE_OFFERS,
            })
          );
        });
    }
  }, []);
  const selectedMap = selectedOffers.reduce(
    (a, { offerid }) => ({ ...a, [offerid]: true }),
    {}
  );
  const noCreativeRotationOffers = offers.filter(
    ({ creativerotationenabled }) => !creativerotationenabled
  );
  const filteredOffers = React.useMemo(() => {
    let targetPlacement = placement;
    if (existingCreativeRotation) {
      if (selectedOffers && selectedOffers.length) {
        const target = selectedOffers[0].offerid;
        const filteredOffers = offers.filter(
          ({ offerid }) => target == offerid
        );
        if (filteredOffers.length) {
          const { placementid } = filteredOffers[0];
          targetPlacement = placementid;
        }
      }
    }
    let searchFiltered = offers.filter(
      ({ offerid, label, creativerotationenabled, placementid }) =>
        label &&
        label.toUpperCase().includes(search.toUpperCase()) &&
        placementid == targetPlacement &&
        !creativerotationenabled &&
        offerid &&
        !selectedMap[offerid]
    );
    const updatedGroupId = groupid === 'all' ? '' : groupid;
    if (updatedGroupId) {
      searchFiltered = searchFiltered.filter(
        ({ offergroupid }) => offergroupid == updatedGroupId
      );
    }
    return searchFiltered.sort((a, b) => b.isactive - a.isactive);
  }, [search, offers, groupid, placement, selectedMap]);

  const disableSelection =
    noCreativeRotationOffers.length + selectedOffers.length < 1;

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    const sourceIndex = source.index;
    const destinationIndex = destination ? destination.index : sourceIndex;
    const newSelection = _.clone(selectedOffers);
    if (source.droppableId == destination.droppableId) {
      if (existingCreativeRotation) {
        if (
          destination.droppableId === 'selectedOffers' &&
          destinationIndex == 0
        ) {
          const modifiedDestinationIndex = destinationIndex + 1;
          const [removed] = newSelection.splice(sourceIndex, 1);
          newSelection.splice(modifiedDestinationIndex, 0, removed);
        } else {
          const [removed] = newSelection.splice(sourceIndex, 1);
          newSelection.splice(destinationIndex, 0, removed);
        }
      } else {
        if (destination.droppableId === 'selectedOffers') {
          const next = newSelection[sourceIndex + 1];
          const [removed] = newSelection.splice(sourceIndex, 1);
          const nextOffer = next ? offerToIdMap[next.offerid] : null;
          const removedOffer = offerToIdMap[removed.offerid];
          let modifiedDestinationIndex = destinationIndex;
          if (!removedOffer.isactive && destinationIndex == 0) {
            modifiedDestinationIndex += 1;
          }
          if (sourceIndex === 0 && nextOffer && !nextOffer.isactive) {
            return;
          }
          newSelection.splice(modifiedDestinationIndex, 0, removed);
        }
      }
    } else {
      if (existingCreativeRotation) {
        if (destination.droppableId === 'selectedOffers') {
          let modifiedDestinationIndex = destinationIndex;
          if (destinationIndex == 0) {
            modifiedDestinationIndex += 1;
          }
          const [removed] = filteredOffers.splice(sourceIndex, 1);
          newSelection.splice(modifiedDestinationIndex, 0, removed);
        } else if (destination.droppableId === 'availableOffers') {
          const [removed] = newSelection.splice(sourceIndex, 1);
          const { offerid }: Offer = removed;
          const modifiedOffers = offers.map((offer: Offer) =>
            offer.offerid == offerid
              ? {
                ...offer,
                creativerotationenabled: null,
                parentofferid: null,
                weight: null,
              }
              : offer
          );
          formik.setFieldValue('offers', modifiedOffers);
        }
      } else {
        if (destination.droppableId === 'selectedOffers') {
          const [removed] = filteredOffers.splice(sourceIndex, 1);
          let modifiedDestinationIndex = destinationIndex;
          if (!removed.isactive && destinationIndex == 0) {
            modifiedDestinationIndex += 1;
          }
          newSelection.splice(modifiedDestinationIndex, 0, removed);
        } else if (destination.droppableId === 'availableOffers') {
          const next = newSelection[sourceIndex + 1];
          const nextOffer = next ? offerToIdMap[next.offerid] : null;
          if (sourceIndex === 0 && nextOffer && !nextOffer.isactive) return;
          newSelection.splice(sourceIndex, 1);
        }
      }
    }
    formik.setFieldValue('selectedOffers', newSelection);
  };

  const errors = Object.values(formik.errors);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      className={classes.root}
      title=''
      onClose={() => {
        formik.resetForm();
        setExistingCreativeRotation(null);
        setShowCreativeRotation(false);
      }}
      open={true}
      headerID="createAudienceGroupHeader"
      header={
        <Styled.CardHeader>
          <Styled.CardTitle>{`${existingCreativeRotation ? EDIT : CREATE} Offer Rotation`}</Styled.CardTitle>
        </Styled.CardHeader>
      }
      bodyID="createAudienceGroupBody"
      body={
        <Styled.StyledBodyContainer
          container
          spacing={theme.spacing.lg}
          direction="column"
        >
          <MiUiGrid item>
            <DragDropContext onDragEnd={onDragEnd}>
              <FormikProvider value={formik}>
                <Styled.Wrapper>
                  {!existingCreativeRotation ? (
                    <Grid.Row>
                      <Grid.Col xs={6}>
                        <Styled.SubHeaderClean>{STEP_1}</Styled.SubHeaderClean>
                        <Styled.InputElementLabel>
                          {SELECT_PLACEMENT}
                        </Styled.InputElementLabel>

                        <Select
                          name="select"
                          id="questionsFilter"
                          disabled={selectedOffers.length ? true : false}
                          value={placement}
                          onChange={formik.handleChange}
                          style={{ width: '100%' }}
                          className={classes.selectDropdown}
                        >
                          <MenuItem value="1" key="1">{PLACEMENT_TYPE.OFFER_WALL}</MenuItem>
                          <MenuItem value="2" key="2">{PLACEMENT_TYPE.DASHBOARD}</MenuItem>
                          <MenuItem value="3" key="3">{PLACEMENT_TYPE.KLOVER_PLUS}</MenuItem>
                          <MenuItem value="4" key="4">{PLACEMENT_TYPE.ONBOARDING}</MenuItem>
                        </Select>
                        <Styled.SubHeader>{STEP_2}</Styled.SubHeader>
                      </Grid.Col>
                    </Grid.Row>
                  ) : null}
                  <Grid.Row>
                    <Grid.Col xs={6}>
                      <Styled.InputElementLabel>
                        {SELECT_OFFER_GROUP_OPTIONAL}
                      </Styled.InputElementLabel>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <Styled.InputElementLabel>
                        {SEARCH_OFFERS}
                      </Styled.InputElementLabel>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <Select
                        name="groupid"
                        id="groupid"
                        onChange={formik.handleChange}
                        value={groupid || 'all'}
                        className={classes.selectDropdown}
                        disabled={groupsLoading}
                        style={{ width: '100%' }}
                      >
                        <MenuItem key="all" value="all">
                          {ALL_OFFERS}
                        </MenuItem>
                        {groups.length
                          ? groups.map(({ name, id }, i) => (
                            <MenuItem value={id} key={i}>
                              {name}
                            </MenuItem>
                          ))
                          : null}
                      </Select>
                    </Grid.Col>
                    <Styled.SearchOffers xs={6}>
                      <Styled.SearchField onSubmit={(e) => e.preventDefault()}>
                        <Field
                          name="search"
                          placeholder="e.g: Calypso Beauty"
                          fullWidth
                        />
                      </Styled.SearchField>
                    </Styled.SearchOffers>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col xs={6}>
                      <Styled.SubHeader>{AVAILABLE_OFFERS}</Styled.SubHeader>
                      <Styled.Note>
                        {!offersLoading && !groupsLoading && disableSelection
                          ? THERE_DOESNT_SEEM_TO_BE_ENOUGH_OFFERS
                          : DRAG_ATLEAST_2_OFFERS}
                      </Styled.Note>
                      <Droppable droppableId="availableOffers">
                        {(droppableProvided) => (
                          <Styled.OfferList ref={droppableProvided.innerRef}>
                            {offersLoading ? (
                              <>
                                <GhostLoader
                                  height="30px"
                                  heightRandom={0}
                                  width="100%"
                                  widthRandom={0}
                                />
                                <GhostLoader
                                  height="30px"
                                  heightRandom={0}
                                  width="100%"
                                  widthRandom={0}
                                />
                                <GhostLoader
                                  height="30px"
                                  heightRandom={0}
                                  width="100%"
                                  widthRandom={0}
                                />
                                <GhostLoader
                                  height="30px"
                                  heightRandom={0}
                                  width="100%"
                                  widthRandom={0}
                                />
                                <GhostLoader
                                  height="30px"
                                  heightRandom={0}
                                  width="100%"
                                  widthRandom={0}
                                />
                              </>
                            ) : (
                              <>
                                {filteredOffers && filteredOffers.length > 0 ? (
                                  <>
                                    {filteredOffers.map((offer: Offer, index) => {
                                      const selectedOfferLength =
                                        selectedOffers && !selectedOffers.length;
                                      const disabledOffer =
                                        !offer.isactive && selectedOfferLength;
                                      const possibleParent =
                                        offer.isactive && selectedOfferLength;
                                      return (
                                        <Draggable
                                          key={offer.offerid}
                                          draggableId={offer.offerid}
                                          index={index}
                                          isDragDisabled={
                                            disableSelection || disabledOffer
                                          }
                                        >
                                          {(draggableProvided) => (
                                            <Styled.OfferListItem
                                              disabled={
                                                disableSelection || disabledOffer
                                              }
                                              selected={possibleParent}
                                              key={index}
                                              ref={draggableProvided.innerRef}
                                              {...draggableProvided.draggableProps}
                                              {...draggableProvided.dragHandleProps}
                                            >
                                              {offer.offerid
                                                ? `(ID: ${offer.offerid}) `
                                                : ''}
                                              {offer.label ? offer.label : ''}
                                            </Styled.OfferListItem>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>{NO_OFFERS_AVAILABLE}</>
                                )}
                              </>
                            )}
                          </Styled.OfferList>
                        )}
                      </Droppable>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <Styled.SubHeader>{SELECTED_OFFERS}</Styled.SubHeader>
                      <Styled.Note>
                        {ROTATION_GROUP_NOTE}
                      </Styled.Note>
                      <Droppable droppableId="selectedOffers">
                        {(droppableProvided) => (
                          <Styled.OfferList ref={droppableProvided.innerRef}>
                            {selectedLoading ? (
                              <CircularProgress color="primary" size={40} />
                            ) : selectedOffers && selectedOffers.length > 0 ? (
                              <>
                                {selectedOffers.map((offer, index) => (
                                  <Draggable
                                    key={offer.offerid}
                                    draggableId={offer.offerid}
                                    index={index}
                                    isDragDisabled={
                                      existingCreativeRotation && index == 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {(draggableProvided) => (
                                      <Styled.OfferListItem
                                        key={index}
                                        selected={index == 0}
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        <Styled.OfferListItemName>
                                          {offer.offerid
                                            ? `(ID: ${offer.offerid}) `
                                            : ''}
                                          {offer.label ? offer.label : ''}
                                        </Styled.OfferListItemName>

                                        <Styled.OfferListItemPercentage
                                          name={`selectedOffers.${index}.weight`}
                                          min={0}
                                          max={100}
                                          onChange={(e) => {
                                            e.preventDefault();
                                            const { value } = e.target;
                                            if (!value) {
                                              formik.handleChange(e);
                                            } else {
                                              if (value >= 0 && value <= 100) {
                                                formik.handleChange(e);
                                                if (value.includes('.')) {
                                                  formik.setFieldValue(
                                                    `selectedOffers.${index}.weight`,
                                                    parseInt(value)
                                                  );
                                                }
                                              }
                                            }
                                          }}
                                          type="number"
                                          placeholder="%"
                                          fullWidth
                                        />
                                      </Styled.OfferListItem>
                                    )}
                                  </Draggable>
                                ))}
                              </>
                            ) : (
                              <Styled.NoOfferSelected>{NO_OFFERS_SELECTED}</Styled.NoOfferSelected>
                            )}
                          </Styled.OfferList>
                        )}
                      </Droppable>
                    </Grid.Col>
                  </Grid.Row>
                </Styled.Wrapper>
              </FormikProvider>
            </DragDropContext>
          </MiUiGrid>
        </Styled.StyledBodyContainer>
      }
      footer={
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              formik.resetForm();
              setExistingCreativeRotation(null);
              setShowCreativeRotation(false);
            }}
          >
            {CANCEL}
          </Button>
          <Tooltip
            zIndex={999999999999999}
            title={
              errors.length
                ? errors.map((message, i) => {
                  return <div key={i}>{message}</div>;
                })
                : ''
            }
          >
            <span>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  setExistingCreativeRotation(null);
                  setShowCreativeRotation(false);
                }}
                disabled={
                  !formik.isValid || formik.isSubmitting || !formik.touched
                }
              >
                {SAVE}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      }
    />

  );
};
export default CreateOfferRotation;

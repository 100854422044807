import Field from 'components/Field';

type Props = {
  freeformanswertype: any;
  handleChange: (value: any) => void;
};

const SelectFreeFormAnswerResponseType = ({
  freeformanswertype,
  handleChange,
}: Props) => {
  return (
    <Field
      fullWidth
      label="Free Form Answer Type"
      as="select"
      name="freeformanswertype"
      value={freeformanswertype}
      onChange={handleChange}
    >
      <option value="" aria-hidden="true" hidden>
        Select an Option
      </option>
      <option value="NUMERIC">Number</option>
      <option value="STRING">Text</option>
    </Field>
  );
};

export default SelectFreeFormAnswerResponseType;

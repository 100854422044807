import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import styled from 'styled-components';
import tokens from 'tokens';
import * as Styled from 'components/Table/index.styles';
import { theme } from '@klover/attain-design-system';

export const TableWrapper = styled.div`
  margin: 32px;
  border: 1px solid rgb(230, 227, 232);
  border-radius: 8px;
`;

export const HideStuff = styled.div`
  height: 12px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableHeader = styled(Styled.TableHeader)`
  top: 16px;
  font-family: 'Calibre-Regular';
  font-size: 16px;
`;

export const DragIcon = styled(DragIndicatorIcon)`
  float: left;
  height: 24px;
  opacity: 0.25;
  margin-right: ${tokens.spacing.sm};
  padding: ${tokens.spacing.xs} 0;
  width: 16px;
`;

export const TableBodyRow = styled(Styled.TableBodyRow)`
  &:hover {
    color: ${(props) =>
    props.$disableHover ? '' : tokens.colorPrimary};

    ${DragIcon} {
      opacity: 1;

      path {
        fill: ${tokens.colorPrimary};
      }
    }
  }
`;

export const TabsWrapper = styled.div`
  && {
    background: ${theme.colors.backgroundPrimary};
    border-bottom: 1px solid ${theme.colors.borderDark};
    margin: 0 0 1rem 0;
    padding: 8px 32px 0px 32px;

    .MuiTab-root {
      ${theme.typography.bodyLG};
      color: ${theme.colors.textTertiary};
      padding: 0 0 ${theme.spacing.sm};
      position: relative;
      text-transform: none;

      & + .MuiTab-root {
        margin-left: ${theme.spacing.xl};
      }
    }

    .MuiTab-root.Mui-selected {
      ${theme.typography.headingXS};
      color: ${theme.colors.textBrand};
      cursor: default;

      &:after {
        content: '';
        position: absolute;
        border-radius: 2px 2px 0 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: ${theme.colors.backgroundBrand};
      }
    }
  }
`;

import { StringMap } from 'interfaces';

export const AmountOptions: {
  [key: string]: string | number | { lower: number; upper?: number };
} = {
  '': '',
  $0: 0,
  '$1-$5': { lower: 1, upper: 5 },
  '$5-$10': { lower: 5, upper: 10 },
  '$10-$20': { lower: 10, upper: 20 },
  '$20-$50': { lower: 20, upper: 50 },
  '$50-$100': { lower: 50, upper: 100 },
  '$100-$150': { lower: 100, upper: 150 },
  '$150-$200': { lower: 150, upper: 200 },
  '$200-$300': { lower: 200, upper: 300 },
  '$300-$500': { lower: 300, upper: 500 },
  '$500-$1,000': { lower: 500, upper: 1000 },
  '$1,000+': { lower: 1000 },
  custom: '',
};
export const AmountOptionKeys = Object.keys(AmountOptions);
export const AmountOptionValues = Object.values(AmountOptions);
export type AmountOptionKey = typeof AmountOptionKeys[number];
export type AmountOptionValue = typeof AmountOptionValues[number];

export const CountOptions: {
  [key: string]: string | number | { lower: number; upper?: number };
} = {
  '': '',
  '0': { lower: 0 },
  '1': { lower: 1 },
  '2-5': { lower: 2, upper: 5 },
  '6-10': { lower: 6, upper: 10 },
  '10-20': { lower: 10, upper: 20 },
  '20-50': { lower: 20, upper: 50 },
  '50-100': { lower: 50, upper: 100 },
  '100+': { lower: 100 },
  custom: '',
};
export const CountOptionKeys = Object.keys(CountOptions);
export const CountOptionValues = Object.values(CountOptions);
export type CountOptionKey = typeof CountOptionKeys[number];
export type CountOptionValue = typeof CountOptionValues[number];

export const FilterOperators: StringMap = {
  '': '',
  is: '=',
  isNot: '!=',
  isMoreThan: '>',
  isLessThan: '<',
  isAtLeast: '>=',
  isUpTo: '<=',
};
export const FilterOperatorKeys = Object.keys(FilterOperators);
export const FilterOperatorValues = Object.values(FilterOperators);
export type FilterOperatorKey = typeof FilterOperatorKeys[number];
export type FilterOperatorValue = typeof FilterOperatorValues[number];

export const TimeframeOperators: StringMap = {
  '': '',
  inTheLast: '>=',
  moreThan: '<',
  custom: 'custom',
};
export const TimeframeOperatorKeys = Object.keys(TimeframeOperators);
export const TimeframeOperatorValues = Object.values(TimeframeOperators);
export type TimeframeOperatorKey = typeof TimeframeOperatorKeys[number];
export type TimeframeOperatorValue = typeof TimeframeOperatorValues[number];

export const TimeframeOptions: { [key: string]: number | string } = {
  '': '',
  '7 days': '7',
  '14 days': '14',
  '30 days': '30',
  '60 days': '60',
  '90 days': '90',
  '6 months': '182.5',
  '12 months': '365',
} as const;
export const TimeframeOptionKeys = Object.keys(TimeframeOptions);
export const TimeframeOptionValues = Object.values(TimeframeOptions);
export type TimeframeOptionKey = typeof TimeframeOptionKeys[number];
export type TimeframeOptionValue = typeof TimeframeOptionValues[number];

import { ENUM_AUDIENCE_EXPORT_STATUS } from 'pages/audienceManager/exportHistoryDialog/exportHistoryUtils';
const { NOT_SENT, SENT, MODELING_COMPLETED, LATE, ERROR, LIVE_IN_TTD } =
  ENUM_AUDIENCE_EXPORT_STATUS;

//const SEED_COLOR = '#faa632';
const NOT_SENT_COLOR = 'gray';
const SENT_COLOR = '#428bca';
const MODELING_COMPLETED_COLOR = '#428bca';
const LIVE_IN_TTD_COLOR = '#5cb85c';
const LATE_COLOR = '#d9534f';
const ERROR_COLOR = '#d9534f';

const NOT_SENT_TEXT = 'Seed created but not sent to TransUnion for modeling';
const SENT_TEXT = 'Seed sent to TransUnion for modeling';
const MODELING_COMPLETED_TEXT = `The modeled audience has been placed into a distribution endpoint & received by Attain <b>(Trade Desk Only)</b>`;
const LATE_TEXT = `The seed was sent to TransUnion, but we haven't heard back for 5+ days`;
const LIVE_IN_TTD_TEXT = 'The audience is live in Trade Desk for activation';
const ERROR_TEXT = 'An unknown error occurred';

/**
 * Retrive all export status and its description
 * @returns Object with status as key and text as value
 */
export const getAllAudienceStatus = () => {
  return {
    [NOT_SENT]: NOT_SENT_TEXT,
    [SENT]: SENT_TEXT,
    [MODELING_COMPLETED]: MODELING_COMPLETED_TEXT,
    [LIVE_IN_TTD]: LIVE_IN_TTD_TEXT,
    [LATE]: LATE_TEXT,
    [ERROR]: ERROR_TEXT,
  };
};

/**
 * Retrive iconColor & tooltip based on status of last exported audience
 * @param value : status of exported audience
 * @param isCustomExport : boolean flag to check if audience type is custom audience
 * @returns : object with iconColor & tooltipText props
 */
export const getAudienceExportStatus = (value: any) => {
  let iconColor, tootipText;
  switch (value) {
    case SENT: {
      iconColor = SENT_COLOR;
      tootipText = SENT_TEXT;
      break;
    }
    case MODELING_COMPLETED: {
      iconColor = MODELING_COMPLETED_COLOR;
      tootipText = MODELING_COMPLETED_TEXT.replace('<b>', '').replace(
        '</b>',
        ''
      );
      break;
    }
    case LIVE_IN_TTD: {
      iconColor = LIVE_IN_TTD_COLOR;
      tootipText = LIVE_IN_TTD_TEXT;
      break;
    }
    case LATE: {
      iconColor = LATE_COLOR;
      tootipText = LATE_TEXT;
      break;
    }
    case ERROR: {
      iconColor = ERROR_COLOR;
      tootipText = ERROR_TEXT;
      break;
    }
    default: {
      iconColor = NOT_SENT_COLOR;
      tootipText = NOT_SENT_TEXT;
      break;
    }
  }

  return { iconColor, tootipText };
};

import {
  LOGIN_FAILURE,
  LOGIN_PROGRESS,
  LOGIN_SUCCESS,
  LOGOUT_PROGRESS,
  LOGOUT_SUCCESS,
  RESET,
  RESET_PASSWORD_PROGRESS,
  RESET_PASSWORD_SUCCESS,
} from './constants';

const INITIAL_STATE_LOGIN = {
  loading: false,
  isLoggedIn: false,
  data: {},
  error: undefined,
  success: false,
};

//hack logueo
// if (process.env.NODE_ENV === 'development') {
//   const data = localStorage.getItem('data')
//   if (data) {
//     INITIAL_STATE_LOGIN.data = JSON.parse(data)
//     INITIAL_STATE_LOGIN.success = true
//     INITIAL_STATE_LOGIN.isLoggedIn = true
//   }
// }

export const loginReducer = (state = INITIAL_STATE_LOGIN, action: any) => {
  switch (action.type) {
    case LOGIN_PROGRESS:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case LOGIN_SUCCESS:
      // if (process.env.NODE_ENV === 'development') {
      //   localStorage.setItem('data', JSON.stringify(action.payload))
      // }
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        isLoggedIn: true,
        error: undefined,
      };
    case LOGIN_FAILURE:
      return {
        ...INITIAL_STATE_LOGIN,
        error: action.payload,
        loading: false,
        isLoggedIn: false,
      };
    case LOGOUT_PROGRESS:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE_LOGIN,
      };
    case RESET_PASSWORD_PROGRESS:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...INITIAL_STATE_LOGIN,
      };
    case RESET:
      return {
        ...INITIAL_STATE_LOGIN,
      };
    default:
      return state;
  }
};

import Button from 'components/Button';
import Field from 'components/Field';
import styled from 'styled-components';
import tokens from 'tokens';
import * as Grid from 'components/Grid';
import { Grid as AttainGrid, theme } from '@klover/attain-design-system';
export const Wrapper = styled(Grid.Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const SubHeader = styled.h3`
  ${tokens.typography.subheaderSm};
  margin: ${tokens.spacing.lg} 0 ${tokens.spacing.sm};
  font-family: 'Calibre-Semibold';
  font-size: 18px;
`;

export const SubHeaderClean = styled(SubHeader)`
  margin-top: 0;
  font-family: 'Calibre-Semibold';
  font-size: 18px;
`;

export const SearchOffers = styled(Grid.Col)`
  display: flex;
`;

export const SearchField = styled.div`
  flex-grow: 1;
  padding-right: ${tokens.spacing.sm};
`;

export const SearchButton = styled(Button)`
  align-self: flex-end;
`;

export const Note = styled.p`
  ${tokens.typography.note};
  margin-bottom: ${tokens.spacing.xs};
  font-family: 'Calibre-Regular';
  font-size: 14px;
`;

export const OfferList = styled.ul`
  background: ${tokens.colorGray20};
  border: 1px solid ${tokens.colorGray40};
  border-radius: ${tokens.spacing.sm};
  height: 200px;
  margin: 0;
  overflow: auto;
  padding: ${tokens.spacing.xs};
`;

type OfferListItemProps = {
  selected: boolean;
  disabled: boolean;
};

export const OfferListItem = styled.li<OfferListItemProps>`
  background: ${tokens.colorPagebg};
  border: 1px solid ${tokens.colorGray40};
  border-radius: ${tokens.spacing.xs};
  box-shadow: 0 ${tokens.spacing.xs} ${tokens.spacing.xs} rgba(0, 0, 0, 0.1);
  color: ${(props) => (props.disabled ? tokens.colorGray40 : tokens.colorBody)};
  display: flex;
  justify-content: space-between;
  padding: ${tokens.spacing.sm} ${tokens.spacing.md};
  font-family: 'Calibre-Regular';
  font-size: 16px;
  & + & {
    margin-top: ${tokens.spacing.xs};
  }
`;

export const OfferListItemName = styled.div`
  flex-grow: 1;
  line-height: 40px;
`;

export const OfferListItemPercentage = styled(Field)`
  width: 64px;
`;

export const NoOfferSelected = styled.div`
  font-family: 'Calibre-Regular';
  font-size: 18px;
  padding: 8px;
`;

export const CardHeader = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
  width: 100%;
`;

export const CardTitle = styled.h2`
  ${theme.typography.headingSM};
  flex-grow: 1;
`;

export const StyledBodyContainer = styled(AttainGrid)`
  span {
    ${theme.typography.headingXS};
  }
`;

export const BodyHeader = styled.div`
  ${theme.typography.headingXS};
  margin-bottom: ${theme.spacing.xs};
`;

export const BodyText = styled.p`
  ${theme.typography.bodyLG};
  color: ${theme.colors.text};
  margin-top: 0;
`;

export const InputElementLabel = styled.div`
  font-family: 'Calibre-Semibold';
  font-size: 16px;
`;
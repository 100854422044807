import { snakeCase } from 'lodash';

const snakeifyKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeifyKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: snakeifyKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export default snakeifyKeys;

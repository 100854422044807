import styled from 'styled-components';
import tokens from 'tokens';
import { Grid, theme } from '@klover/attain-design-system';

export const CardHeader = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
  width: 100%;
`;

export const CardTitle = styled.h2`
  ${theme.typography.headingSM};
  flex-grow: 1;
`;

export const StyledBodyContainer = styled(Grid)`
  span {
    ${theme.typography.headingXS};
  }
`;

export const Toggle = styled.div`
  margin: ${tokens.spacing.lg} 0;
`;

export const RadioGroup = styled.div`
  padding: ${tokens.spacing.md} 0;
`;

import axios from 'axios';
import { OfferGroup, updateCurrentGroup } from '../../offerWallManager/slice';
import {
  SalesLiftStudy,
  addNotification,
  addSalesLiftStudyToEntities,
  clearGroups,
  fetchAllGroups,
  fetchGroup,
  setGroups,
  setGroupsLoadingState,
  setSalesLiftStudiesLoadingState,
} from '../slice';
import { call, put, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { auth } from 'utils/firebase';

const OFFER_GROUPS_ENDPOINT = `${process.env.API_BASE_ADDRESS}/offer-manager/offer-groups`;
const SALES_LIFT_GROUPS_ENDPOINT = `${process.env.API_BASE_ADDRESS}/sales-lift-manager/groups`;

function handleFetchGroups() {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(OFFER_GROUPS_ENDPOINT);
  });
}

function handleFetchStudiesByGroupId(group: OfferGroup) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      `${SALES_LIFT_GROUPS_ENDPOINT}/${group.id}`
    );
  });
}

function* watchFetchSalesLiftStudies(action: PayloadAction<OfferGroup>) {
  try {
    yield put(setSalesLiftStudiesLoadingState({ state: 'loading' }));
    const currentGroup = action.payload;
    const results = yield call(handleFetchStudiesByGroupId, currentGroup);
    const salesLiftStudies = results.data;
    for (const salesLiftStudy of salesLiftStudies) {
      yield put(addSalesLiftStudyToEntities(salesLiftStudy));
    }
    const studyIds = salesLiftStudies.map(
      (salesLiftStudy: SalesLiftStudy) => salesLiftStudy.salesliftstudyid
    );
    yield put(
      updateCurrentGroup({
        ...currentGroup,
        studies: studyIds.sort((a, b) => a - b),
      })
    );
    yield put(setSalesLiftStudiesLoadingState({ state: 'done' }));
  } catch (e) {
    yield put(setSalesLiftStudiesLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Retrieve Group ${action.payload.name} and offers`,
      })
    );
    console.log('Error:', e);
  }
}

//for main page
function* watchFetchAllGroups() {
  try {
    yield put(setGroupsLoadingState({ state: 'loading' }));
    const results = yield call(handleFetchGroups);
    yield put(clearGroups());
    for (const offerGroup of results.data) {
      yield put(setGroups(offerGroup));
    }

    yield put(setGroupsLoadingState({ state: 'done' }));
  } catch (e) {
    yield put(setGroupsLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Retrieve Groups`,
      })
    );
    console.log('Error:', e);
  }
}

export default function* watchAll() {
  yield takeLatest<any>(fetchAllGroups.type, watchFetchAllGroups);
  yield takeLatest<any>(fetchGroup.type, watchFetchSalesLiftStudies);
}

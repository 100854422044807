import _ from 'lodash';
import { useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';
const checkMark = String.fromCodePoint(9989);
const xMark = String.fromCodePoint(10060);

// Define fields for Offer Wall placement
const offerWallFields = [
  { key: 'label', label: 'Offer Label' },
  { key: 'logourl', label: 'Image Url' },
  { key: 'headline', label: 'Subline' },
  { key: 'description', label: 'Body' },
  { key: 'ctatext', label: 'Call To Action' },
];

// Define fields for Dashboard placement
const dashboardFields = [
  { key: 'label', label: 'Offer Label' },
  { key: 'offername', label: 'Offer Name' },
  { key: 'logourl', label: 'Image Url' },
  { key: 'backgroundimgurl', label: 'Background Image Url' },
  { key: 'headline', label: 'Subline' },
  { key: 'description', label: 'Body' },
  { key: 'ctatext', label: 'Call To Action' },
];

// Define fields for Klover Plus placement
const kloverPlusFields = [
  { key: 'label', label: 'Offer Label' },
  { key: 'logourl', label: 'Image Url' },
  { key: 'headline', label: 'Subline' },
  { key: 'description', label: 'Body' },
  { key: 'ctatext', label: 'Call To Action' },
];

// Define fields for Onboarding placement
const onBoardingFields = [
  { key: 'label', label: 'Offer Label' },
  { key: 'logourl', label: 'Image Url' },
  { key: 'backgroundimgurl', label: 'Background Image Url' },
  { key: 'offername', label: 'Headline' },
  { key: 'headline', label: 'Subline' },
  { key: 'description', label: 'Body' },
  { key: 'ctatext', label: 'Call To Action' },
];

/***
 * Get validation fields based on the placement type of the current offer.
 * @param currentOffer - The current offer object.
 * @returns Array of validation fields for the current offer placement.
 */
function getValidationFields(currentOffer) {
  const placementid = currentOffer?.placementid;
  switch (placementid) {
    case '1': // Offer Wall
      return offerWallFields;
    case '2': {
      // Dashboard
      if (currentOffer?.comparisonemoji) {
        // Include additional comparison text field if comparison emoji exists
        return [
          ...dashboardFields,
          { key: 'comparisontext', label: 'Comparison Text' },
        ];
      }
      return dashboardFields;
    }
    case '3': // Klover Plus
      return kloverPlusFields;
    case '4': // Onboarding
      return onBoardingFields;
    default:
      return [];
  }
}

const OfferPropertyComponent = ({ currentOffer, keyName, label }) => {
  return (
    <Styled.RequiredRow>
      <Styled.RequiredRowEmoji>
        {currentOffer && keyName && currentOffer[keyName] ? checkMark : xMark}
      </Styled.RequiredRowEmoji>
      <div>{label ? _.startCase(label) : null}</div>
    </Styled.RequiredRow>
  );
};

const SaveOfferDisabledButtonMessage = () => {
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const fieldsToValidate = getValidationFields(currentOffer);

  return (
    <Styled.MessageTextAlignment>
      <div>{"Hi! Looks like you're trying to save"}</div>
      <br />
      <div>To save make sure you have</div>
      <br />
      {currentOffer ? (
        <>
          {fieldsToValidate?.map((field) => {
            return (
              <OfferPropertyComponent
                currentOffer={currentOffer}
                keyName={field.key}
                label={field.label}
              />
            );
          })}
        </>
      ) : null}
    </Styled.MessageTextAlignment>
  );
};

/**
 * Component to display validation properties of the current offer.
 * @returns JSX element representing the offer properties for validation.
 */
export const OfferAllProperties = () => {
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );

  // Get validation fields for the current offer placement
  const fieldsToValidate = getValidationFields(currentOffer);

  return (
    <Styled.MessageTextAlignment>
      {fieldsToValidate?.map((field) => {
        return (
          <OfferPropertyComponent
            currentOffer={currentOffer}
            keyName={field.key}
            label={field.label}
          />
        );
      })}
    </Styled.MessageTextAlignment>
  );
};

export default SaveOfferDisabledButtonMessage;

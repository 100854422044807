const creativeDetailsDashboard = {
  offerName: 'offername',
  imageUrl: 'logourl',
  backgroundImageUrl: 'backgroundimgurl',
  subline: 'headline',
  body: 'description',
  comparisonEmoji: 'comparisonemoji',
  comparisonText: 'comparisontext',
  price: 'price',
  callToAction: 'ctatext',
  clickthroughUrl: 'ctaurl',
  zIndex: 'zindex',
};

export default creativeDetailsDashboard;

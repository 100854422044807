import App from './App';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import theme from './theme';
import { AuthProvider, FirebaseAppProvider } from 'reactfire';
import { ThemeProvider } from '@mui/material';
import { auth, firebaseConfig } from './utils/firebase';
import { datadogRum } from '@datadog/browser-rum';
import './index.css';
import { ApolloProvider } from '@apollo/client';
import client from './graphql/apollo-client';

if (process.env.KDS_NODE_ENV == 'dev' || process.env.KDS_NODE_ENV == 'prod') {
  datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    env: process.env.DATADOG_ENV,
    site: process.env.DATADOG_SITE,
    service: process.env.DATADOG_SERVICE,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.GIT_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
  const keyValPairs = JSON.parse(process.env.DATADOG_SRE_TAGS_JSON);
  for (const key in keyValPairs) {
    datadogRum.addRumGlobalContext(key, keyValPairs[key]);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <AuthProvider sdk={auth}>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.querySelector('#root')
);

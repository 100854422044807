import EditDetails from '../editDetails';
import EditTargeting from '../editTargeting';
import Stack from 'components/Stack';
import nanoid from 'utils/nanoid';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Divider } from '@material-ui/core';
import {
  QQ_ANSWERTYPE_FREE_FORM,
  QQ_ANSWERTYPE_MULTIPLE,
  QQ_ANSWERTYPE_SINGLE,
} from '../variables';
import {
  Question,
  QuickQuestionTargeting,
} from 'interfaces/quickQuestionsManager';
import { QuickQuestionContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Styled from './index.styles';

import BootstrapTooltip from 'components/bootstrapTooltip';
import ChangeLogDialog from '../../../components/ChangeLogDialog';
import CreatedAndUpdatedDate from '../createdAndUpdatedDate';
import GhostLoader from 'react-ghost-loader';
import Toggle from 'components/Toggle';
import Typography from '@material-ui/core/Typography';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import {
  ArrowLeft,
  DotsThreeVertical,
  ListMagnifyingGlass,
} from '@phosphor-icons/react';
import {
  Box,
  Button,
  IconButton,
  Grid as MGrid,
  Menu,
  MenuItem,
} from '@klover/attain-design-system';
import * as Grid from 'components/Grid';

import { CHANGE_LOG } from '../../../content';
import { SYSTEM_NAME } from '../../../constants';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useUser } from 'reactfire';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionName: {
      fontFamily: 'Calibre-Semibold, sans-serif',
      fontSize: 24,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 400,
      marginRight: 10,
    },
    questionId: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    editAudienceName: {
      fontFamily: 'Calibre-Regular,sans-serif',
      width: 400,
    },
    button: {
      margin: `${theme.spacing(0, 1)} !important`,
    },
    exportSpinner: {
      margin: '0.15em 1.2em',
    },
    editButtonBox: {
      marginLeft: theme.spacing(4),
    },
    form: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 5,
    },
  })
);
const ViewQuickQuestion = () => {
  const {
    setShowEditDetails,
    showEditDetails,
    showEditTargeting,
    setShowEditTargeting,
  } = useContext(QuickQuestionContext);
  const classes = useStyles();
  const { questionid }: { questionid: string } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const [question, setQuestion] = useState(null);
  const [questionLoading, setQuestionLoading] = useState(true);
  const [countsLoading, setCountsLoading] = useState(true);
  const [targetingLoading, setTargetingLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(null);
  const [answerIdToCountMap, setAnswerIdToCountMap] = useState(null);
  const [targeting, setTargeting] = useState({});
  const [openChangeLogDialog, setOpenChangeLogDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data } = useUser();
  const openAudienceAction = Boolean(anchorEl);

  useEffect(() => {
    const getQuestion = async () => {
      try {
        setQuestionLoading(true);
        if (questionid) {
          const axiosInstance = await getAuthorizedAxiosInstance();
          if (axiosInstance) {
            const res = await axiosInstance?.get(
              `${API_BASE_ADDRESS}/questions/${questionid}`
            );
            const { totalCount, responses } = res.data.responses;
            setQuestion(res.data);
            setQuestionLoading(false);
            setTotalCount(totalCount);
            setAnswerIdToCountMap(responses || {});
            setCountsLoading(false);
            setTargeting(res.data.audiences);
            setTargetingLoading(false);
          } else {
            throw new Error('Failed to authorize request');
          }
        }
      } catch (err) {
        console.log(err);
        setQuestionLoading(false);
        setCountsLoading(false);
        setTargetingLoading(false);
      }
    };
    if (showEditTargeting) {
      return;
    }
    getQuestion();
  }, [showEditTargeting]);

  const handleActivate = async () => {
    try {
      const axiosInstance = await getAuthorizedAxiosInstance();
      if (axiosInstance) {
        await axiosInstance.put(
          `${API_BASE_ADDRESS}/questions/status/${questionid}`,
          {
            isactive: !question.isactive,
            user: data?.email,
          }
        );
        setQuestion({ ...question, isactive: !question.isactive });
      } else {
        throw new Error('Failed to authorize request');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const QuestionView = ({
    question,
    targeting,
  }: {
    question: Question;
    targeting: QuickQuestionTargeting;
  }) => {
    const {
      questiontext,
      isactive,
      answers,
      answertype,
      points,
      questionemoji,
    } = question;

    const answerTypeToText = (answertype: string) => {
      switch (answertype) {
        case QQ_ANSWERTYPE_SINGLE:
          return 'Single Selection';
        case QQ_ANSWERTYPE_MULTIPLE:
          return 'Multiple Selection';
        case QQ_ANSWERTYPE_FREE_FORM:
          return 'Free form Answer';
      }
    };

    return (
      <>
        <Styled.QuestionDetailsAndPreviewContainer>
          <Styled.QuestionDetailsAndPreview>
            <Styled.CardContainer style={{ width: '53rem', margin: 0 }}>
              <Styled.CardTitle variant="h6">
                Quick Question - Details
              </Styled.CardTitle>
              <Stack spacing={tokens.spacing.lg}>
                <Grid.Row>
                  <Grid.Col>
                    <Styled.Label>Text</Styled.Label>

                    <Styled.Info>
                      {questiontext ? questiontext : null}
                    </Styled.Info>
                  </Grid.Col>

                  <Grid.Col>
                    <Styled.Label>Status</Styled.Label>
                    <Styled.Info>
                      {isactive ? (
                        <Styled.Active>Active</Styled.Active>
                      ) : (
                        <Styled.Inactive>Inactive</Styled.Inactive>
                      )}
                    </Styled.Info>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Styled.Label>Answer Type</Styled.Label>
                    <Styled.Info>{answerTypeToText(answertype)}</Styled.Info>
                  </Grid.Col>

                  <Grid.Col>
                    <Styled.Label>Emoji</Styled.Label>

                    <Styled.Info>
                      {questionemoji
                        ? questionemoji.split('-').reduce((a, s) => {
                            try {
                              return (a += String.fromCodePoint(`0x${s}`));
                            } catch (e) {
                              return '';
                            }
                          }, '')
                        : null}
                    </Styled.Info>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Styled.Label>Points</Styled.Label>
                    <Styled.Info>{points}</Styled.Info>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Button
                      variant="contained"
                      onClick={() => setShowEditDetails(true)}
                      disabled={!!isactive}
                    >
                      Edit Details
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Stack>
            </Styled.CardContainer>

            <Styled.CardContainer
              style={{
                width: '12.5rem',
                height: '12.5rem',
                margin: 0,
              }}
            >
              <Stack spacing="">
                <Styled.EmojiContainer>
                  <Styled.Emoji>
                    {questionemoji
                      ? questionemoji.split('-').reduce((a, s) => {
                          try {
                            return (a += String.fromCodePoint(`0x${s}`));
                          } catch (e) {
                            return '';
                          }
                        }, '')
                      : null}
                  </Styled.Emoji>
                </Styled.EmojiContainer>
                <Styled.Points>
                  <Styled.Trophy>🏆</Styled.Trophy> Earn{' '}
                  {question && question.points} points
                </Styled.Points>
                <Styled.Question>
                  {question && question.questiontext}
                </Styled.Question>
              </Stack>
            </Styled.CardContainer>
          </Styled.QuestionDetailsAndPreview>
        </Styled.QuestionDetailsAndPreviewContainer>

        <div style={{ paddingTop: '1.75rem' }} />

        <Styled.CardContainer>
          <Styled.CardTitle variant="h6">Respondents</Styled.CardTitle>
          <Grid.Row>
            <Grid.Col>
              <Styled.Table>
                <tr>
                  <th align="left">Answer</th>
                  <th align="right">Responses</th>
                  <th align="right">Percent</th>
                </tr>
                {answertype === QQ_ANSWERTYPE_SINGLE ||
                answertype === QQ_ANSWERTYPE_MULTIPLE ? (
                  <>
                    {answers && answers.length
                      ? answers.map(({ answerid, answertext }) => (
                          <Styled.TableRow key={nanoid()}>
                            <td align="left">{answertext}</td>
                            <td align="right">
                              {answerIdToCountMap &&
                              answerIdToCountMap[answerid] &&
                              totalCount
                                ? `${Math.round(
                                    (answerIdToCountMap[answerid] / 100) *
                                      totalCount
                                  ).toLocaleString()}`
                                : '0'}
                            </td>
                            <td align="right">
                              {answerIdToCountMap &&
                              answerIdToCountMap[answerid] &&
                              totalCount
                                ? `${answerIdToCountMap[answerid]} %`
                                : '0 %'}
                            </td>
                          </Styled.TableRow>
                        ))
                      : null}
                    <td
                      align="left"
                      style={{
                        fontSize: '18px',
                        lineHeight: '24px',
                        fontFamily: 'Calibre-Regular, sans-serif',
                      }}
                    >
                      Total Respondents
                    </td>
                    <td
                      align="right"
                      style={{ fontSize: '16px', lineHeight: '24px' }}
                    >
                      {totalCount ? Number(totalCount).toLocaleString() : 0}
                    </td>
                  </>
                ) : null}
              </Styled.Table>
            </Grid.Col>
          </Grid.Row>
        </Styled.CardContainer>

        <div style={{ paddingTop: '1.75rem' }} />

        <Styled.CardContainer>
          <Styled.CardTitle variant="h6">Audience Targeting</Styled.CardTitle>
          <Stack spacing={tokens.spacing.lg}>
            {targeting ? (
              <Grid.Row>
                <Grid.Col>
                  {targeting && targeting.length ? (
                    <>
                      <Styled.Label>Audiences</Styled.Label>
                      {targeting.map(({ id, name }) => (
                        <Styled.AudienceRow key={nanoid()}>
                          <div>
                            {name} - {`(ID: ${id})`}
                          </div>
                        </Styled.AudienceRow>
                      ))}
                    </>
                  ) : null}
                </Grid.Col>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Col>
                <Button
                  onClick={() => setShowEditTargeting(true)}
                  variant="contained"
                >
                  Edit Targeting
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Stack>
          {showEditTargeting ? (
            <EditTargeting
              type={question.type}
              questionid={question.questionid}
              setOriginalTargeting={setTargeting}
              audiencesList={question.audiences || []}
            />
          ) : null}
          {showEditDetails && (
            <EditDetails
              originalQuestion={question}
              setQuestion={setQuestion}
              hasResponses={!!totalCount}
              user={data?.email}
            />
          )}
        </Styled.CardContainer>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 12,
        }}
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="default"
              style={{ width: 45, height: 45 }}
              onClick={() => {
                const status =
                  state?.isActive ||
                  (question?.isactive === 1 ? 'active' : 'inactive');
                history.push(`/main/quick-questions-manager?status=${status}`);
              }}
            >
              <ArrowLeft />
            </IconButton>
          </div>

          <div style={{ marginRight: 8 }} />
          <Box flexDirection="column">
            <BootstrapTooltip title={question?.questiontext}>
              <Typography
                className={classes.questionName}
                component="h2"
                variant="h6"
              >
                {question?.questiontext}
              </Typography>
            </BootstrapTooltip>
            <Typography
              component="h3"
              variant="body2"
              className={classes.questionId}
            >
              ID: {question?.questionid || 'N/A'}
            </Typography>
          </Box>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <Toggle
              name="is-active-toggle"
              checked={question && question.isactive ? true : false}
              onClick={handleActivate}
            />
          </div>
          <div>
            <IconButton
              color="default"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <DotsThreeVertical size={24} weight="bold" />
            </IconButton>

            <Menu
              id="audience-action-btn"
              open={openAudienceAction}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onBlur={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={() => setOpenChangeLogDialog(true)}>
                <span
                  title="Show Audience Change Log"
                  className={classes.menuItem}
                >
                  <ListMagnifyingGlass size={22} />
                  {CHANGE_LOG}
                </span>
              </MenuItem>
            </Menu>
            {openChangeLogDialog && (
              <ChangeLogDialog
                isOpen={openChangeLogDialog}
                setOpenDialog={setOpenChangeLogDialog}
                entityId={questionid}
                systemName={SYSTEM_NAME.quick_questions_manager}
              />
            )}
          </div>
        </div>
        <div>
          <Styled.ActionContainer>
            <CreatedAndUpdatedDate />
          </Styled.ActionContainer>
        </div>
      </div>
      <Divider style={{ height: 2 }} />

      <Styled.ContentWrapper>
        {!questionLoading && !countsLoading && !targetingLoading ? (
          question ? (
            <QuestionView question={question} targeting={targeting} />
          ) : (
            <Styled.Nothing>No Question Exists</Styled.Nothing>
          )
        ) : (
          <QuestionLoading />
        )}
      </Styled.ContentWrapper>
    </>
  );
};

const QuestionLoading = () => {
  return (
    <>
      <Styled.Wrapper>
        <MGrid container spacing={2}>
          <MGrid item xs={8}>
            <Styled.CardBody style={{ marginBottom: 7 }}>
              <GhostLoader
                height="42px"
                heightRandom={0}
                width="100%"
                widthRandom={0}
              />
            </Styled.CardBody>
            {[...Array(3)].map((x, i) => (
              <Styled.CardBody
                key={`${i}_question_details`}
                style={{ marginBottom: 7 }}
              >
                <GhostLoader
                  height="42px"
                  heightRandom={0}
                  width="100%"
                  widthRandom={0}
                />
                <GhostLoader
                  height="42px"
                  heightRandom={0}
                  width="100%"
                  widthRandom={0}
                />
              </Styled.CardBody>
            ))}
            <Styled.CardBody style={{ marginBottom: 7 }}>
              <GhostLoader
                height="42px"
                heightRandom={0}
                width="20%"
                widthRandom={0}
              />
              <GhostLoader
                height="42px"
                heightRandom={0}
                width="100%"
                widthRandom={0}
              />
            </Styled.CardBody>
          </MGrid>
          <MGrid item xs={4}>
            <Styled.CardBody>
              <GhostLoader
                height="240px"
                heightRandom={0}
                width="100%"
                widthRandom={0}
              />
            </Styled.CardBody>
          </MGrid>
        </MGrid>
      </Styled.Wrapper>
      <Styled.Wrapper>
        {[...Array(4)].map((x, i) => (
          <Styled.CardBody key={`${i}_question_response`}>
            <GhostLoader
              height="42px"
              heightRandom={0}
              width="100%"
              widthRandom={0}
            />
            <GhostLoader
              height="42px"
              heightRandom={0}
              width="100%"
              widthRandom={0}
            />
            <GhostLoader
              height="42px"
              heightRandom={0}
              width="100%"
              widthRandom={0}
            />
          </Styled.CardBody>
        ))}
      </Styled.Wrapper>
      <Styled.Wrapper>
        <Styled.CardBody>
          <GhostLoader
            height="42px"
            heightRandom={0}
            width="20%"
            widthRandom={0}
          />
        </Styled.CardBody>
        <Styled.CardBody>
          <GhostLoader
            height="42px"
            heightRandom={0}
            width="100%"
            widthRandom={0}
          />
        </Styled.CardBody>
      </Styled.Wrapper>
    </>
  );
};

export default ViewQuickQuestion;

import ABTesting from 'pages/offerWallManager/abTesting';
import CreateAbTest from 'pages/offerWallManager/createAbTest';
import CreateOfferRotation from 'pages/offerWallManager/createOfferRotation';
import CreativeRotations from 'pages/offerWallManager/creativeRotations';
import NotFound from 'components/NotFound';
import OfferEditor from '../offerWallManager/editor';
import OfferWallManager from 'pages/offerWallManager';
import Preview from 'pages/offerWallManager/preview';
import React from 'react';
import RemoveAbTest from 'pages/offerWallManager/removeAbTest';
import RemoveOfferRotation from 'pages/offerWallManager/removeCreativeRotation';
import { OfferWallContext } from 'context';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { SnackbarCloseReason } from '@material-ui/core';

const OfferWallPages: React.FC = () => {
  const { path } = useRouteMatch();
  const [existingCreativeRotation, setExistingCreativeRotation] =
    React.useState(null);
  const [showCreativeRotation, setShowCreativeRotation] = React.useState(false);
  const [showRemoveCreativeRotation, setRemoveCreativeRotation] =
    React.useState(false);
  const [abTests, setAbTests] = React.useState([]);
  const [abTestsLoading, setAbTestsLoading] = React.useState(false);
  const [showCreateAbTest, setShowCreateAbTest] = React.useState(false);
  const [existingAbTest, setExistingAbTest] = React.useState('');
  const [showRemoveAbTest, setRemoveAbTest] = React.useState(false);
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    open: false,
    message: '',
    error: false,
  });
  const onCloseSnackbar = (
    _e: React.SyntheticEvent<any, Event>,
    _r: SnackbarCloseReason
  ): void | undefined => {
    setSnackBarOptions({ open: false, message: '', error: false });
  };
  return (
    <OfferWallContext.Provider
      value={{
        showCreateAbTest,
        setShowCreateAbTest,
        abTestsLoading,
        setAbTestsLoading,
        abTests,
        setAbTests,
        existingCreativeRotation,
        showRemoveCreativeRotation,
        setShowCreativeRotation,
        setRemoveCreativeRotation,
        setExistingCreativeRotation,
        existingAbTest,
        setExistingAbTest,
        showRemoveAbTest,
        setRemoveAbTest,
        snackBarOptions,
        setSnackBarOptions,
        onCloseSnackbar,
      }}
    >
      <Switch>
        <Route
          exact
          path={`${path}/creative-rotations`}
          component={CreativeRotations}
        />
        <Route exact path={`${path}/ab-tests`} component={ABTesting} />
        <Route exact path={`${path}/preview`} component={Preview} />
        <Route
          exact
          path={`${path}/editor/:offerid?`}
          component={OfferEditor}
        />
        <Route exact path={path} component={OfferWallManager} />
        <Route>
          <NotFound />
        </Route>
      </Switch>
      {showCreativeRotation ? <CreateOfferRotation /> : null}
      {showRemoveCreativeRotation ? <RemoveOfferRotation /> : null}
      {showCreateAbTest ? <CreateAbTest /> : null}
      {showRemoveAbTest ? <RemoveAbTest /> : null}
    </OfferWallContext.Provider>
  );
};

export default OfferWallPages;

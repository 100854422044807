import { LOGIN, LOGOUT, RESET_PASSWORD } from './constants';
import { TLogin } from 'types/types';

export const loginAction = (payload: TLogin) => {
  return {
    type: LOGIN,
    payload,
  };
};

export const logOutAction = () => {
  return {
    type: LOGOUT,
  };
};

export const resetPasswordAction = (payload: { email: string }) => {
  return {
    type: RESET_PASSWORD,
    payload,
  };
};

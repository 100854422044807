import React from 'react';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Insights: React.FC = () => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5">
            Insights
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Insights;

export const NumberInHouseholds = [
  '',
  '2 persons',
  '1 person',
  '3 persons',
  '4 persons',
  '5 persons',
  'More than 5 persons',
  'Prefer not to answer',
];

export type NumberInHousehold = typeof NumberInHouseholds[number];

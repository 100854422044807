import Button from 'components/Button';
import Select from 'components/Select';
import styled from 'styled-components';
import tokens from 'tokens';
import { Autocomplete } from '@klover/attain-design-system';
import { TextField } from '@mui/material';

import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  border-left: 1px solid ${tokens.colorGray40};
  display: inline-block;
  margin-left: ${tokens.spacing.md};
  padding-left: ${tokens.spacing.md};
`;

export const ButtonComponent = styled(Button)``;

export const ButtonSpacing = styled.div`
  display: flex;

  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const ModeledSize = styled.div`
  text-align: left;
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
`;

export const Or = styled.span`
  margin: 0 ${tokens.spacing.sm};
  text-transform: uppercase;
`;

export const OffersColumn = styled.div`
  width: 150px;
`;

export const RawCount = styled.div`
  text-align: right;
  max-width: 72px;
`;

export const AudienceGroup = styled.div`
  padding-left: 10px;
`;

export const AudienceName = styled.div`
  margin-left: 0px;
  font-size: 16px;
  max-width: 550px;
  white-space: normal;
  text-align: start;
`;

export const AudienceID = styled.div`
  max-width: 15px;
`;

export const Type = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const AudienceStatus = styled.div`
  display: flex;
  font-size: 16px;
`;

export const LastModified = styled.div`
  font-size: 14px;
  text-align: center;
`;

export const SearchArea = styled.div`
  margin-bottom: ${tokens.spacing.md};
`;

export const SelectOfferGroup = styled(Select)`
  width: 200px;
`;

export const SelectAutoCompleteGroup = styled(Autocomplete)`
  width: 200px;
  margin-left: 16px;
  display: inline-flex;
  & input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
`;

export const SelectAutoCompleteGroupOptions = styled.li`
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
  word-break: break-all;
`;

export const SelectAutoCompleteGroupInput = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: `14px`,
  },
});

export const SelectAutoCompleteEmail = styled(Autocomplete)`
  width: 200px;
  margin-left: 16px;
  display: inline-flex;
  & input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
`;

export const Spinner = styled.div`
  min-height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

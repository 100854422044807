import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client';
const graphqlEndpoint = new HttpLink({ uri: process.env.GRAPHQL_ENDPOINT });
import { auth } from 'utils/firebase';

// Middleware to add the Authorization header
const authMiddleware = new ApolloLink(async (operation, forward) => {
  const response = await auth.currentUser.getIdTokenResult();
  const token = response.token;
  // Add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    }
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, graphqlEndpoint),
  cache: new InMemoryCache()
});

export default client;
import { nanoid } from 'nanoid';
import { setStepInvalid, setStepValid, updateSalesLiftOffers } from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI
import {
  Checkbox,
  ThemeProvider,
  createTheme,
  makeStyles,
} from '@material-ui/core/';

const useStyles = makeStyles(() => ({
  offersChecklistContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
  },
  noOfferGroupText: {
    padding: '1em',
    fontSize: 15,
  },
}));

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const OffersSelect = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );

  if (
    currentSalesLiftStudy &&
    currentSalesLiftStudy.offers &&
    currentSalesLiftStudy.offers.length === 0
  ) {
    dispatch(setStepInvalid({ keyName: 'offersSelect' }));
  } else {
    dispatch(setStepValid({ keyName: 'offersSelect' }));
  }
  const currentGroup = useAppSelector(
    (state) => state.offerWallReducer.currentGroup
  );
  const offersEntities = useAppSelector(
    (state) => state.offerWallReducer.entities.offers
  );
  const offers =
    currentGroup && currentGroup.offers && currentGroup.offers.length
      ? currentGroup.offers.map((offerid) => offersEntities.byId[offerid])
      : [];
  const selectedOffers =
    currentSalesLiftStudy &&
    currentSalesLiftStudy.offers &&
    currentSalesLiftStudy.offers.length
      ? currentSalesLiftStudy.offers.reduce((a, offer) => {
          a[offer.offerid] = true;
          return a;
        }, {})
      : {};
  const handleUpdateSalesLift = (offer) => {
    dispatch(updateSalesLiftOffers(offer));
  };
  return (
    <div>
      <ThemeProvider theme={inputs}>
        {offers && offers.length ? (
          offers.map((offer) => (
            <div key={nanoid()} className={classes.offersChecklistContainer}>
              <div>
                <Checkbox
                  color="primary"
                  checked={selectedOffers[offer.offerid]}
                  onClick={() => handleUpdateSalesLift(offer)}
                />
              </div>
              <div>{offer.offername}</div>
            </div>
          ))
        ) : (
          <div className={classes.noOfferGroupText}>Choose an Offer Group</div>
        )}
      </ThemeProvider>
    </div>
  );
};

export default OffersSelect;

import { useQuery } from '@apollo/client';
import { GET_CHANGE_LOGS } from '../queries';

const useChangeLogs = ({ entityId, systemName, changedFields }) => {
  const { loading, error, data, refetch } = useQuery(GET_CHANGE_LOGS, {
    variables: {
      entityId,
      systemName,
      changedFields
    },
  });

  return {
    loading,
    error,
    changeLogs: data ? data.changeLogs : [],
    refetch
  };
};

export default useChangeLogs;

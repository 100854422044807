import styled from 'styled-components';
import tokens from 'tokens';
import { Dialog as ReachDialog } from '@reach/dialog';

export const Dialog = styled(ReachDialog)`
  ${tokens.typography.body};
  background: ${tokens.colorPagebg};
  border-radius: ${tokens.spacing.sm};
  margin: ${tokens.spacing.xl} auto;
  outline: none;
  padding: ${tokens.spacing.lg};
  position: relative;
  width: 900px;
`;

export const Header = styled.header``;

export const Title = styled.h2`
  ${tokens.typography.subheaderLg};
`;

export const Close = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: calc(${tokens.spacing.lg} - 1px);
  right: calc(${tokens.spacing.lg} - 6px);
`;

export const Content = styled.div`
  padding: ${tokens.spacing.lg} 0;
`;

export const Buttons = styled.footer`
  display: flex;
  flex-direction: row-reverse;

  & > *:first-child {
    margin-left: ${tokens.spacing.sm};
  }
`;

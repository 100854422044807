import Button from 'components/Button';
import Select from 'components/Select';
import styled from 'styled-components';
import tokens from 'tokens';
import { Form as FormikForm } from 'formik';

export const ButtonComponent = styled(Button)``;

export const ButtonSpacing = styled.div`
  display: flex;

  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const Form = styled(FormikForm)`
  border-left: 1px solid ${tokens.colorGray40};
  display: inline-block;
  margin-left: ${tokens.spacing.md};
  padding-left: ${tokens.spacing.md};
`;

export const OffersColumn = styled.div`
  width: 150px;
`;

export const SearchArea = styled.div`
  margin-bottom: ${tokens.spacing.md};
`;

export const SelectOfferGroup = styled(Select)`
  margin-right: ${tokens.spacing.sm};
  width: 240px;
`;

export const Spinner = styled.div`
  min-height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

import styled from 'styled-components';
import tokens from 'tokens';
import { theme } from '@klover/attain-design-system';

export const RadioSelectionSpace = styled.div`
  margin: ${tokens.spacing.md} -${tokens.spacing.xs};
  padding: 0px ${tokens.spacing.xs};
  overflow-y: scroll;
  height: 150px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${tokens.colorDefault}; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const CardHeader = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
  width: 100%;
`;

export const CardTitle = styled.h2`
  ${theme.typography.headingSM};
  flex-grow: 1;
`;

export const LoadingWrapper = styled.div`
  ${theme.mixins.card};
  overflow: hidden;
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

export const LoadingBody = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
`;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Offer, moveOffer } from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';
import * as StyledTable from 'components/Table/index.styles';
import { useHistory } from 'react-router-dom';
import Field from 'components/Field';
import { Formik, FormikValues } from 'formik';
import GhostLoader from 'react-ghost-loader';
import Tabs from 'components/Tabs/Tabs';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import OfferIcon from '@material-ui/icons/LocalOfferRounded';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';


// Page Components
import Header from 'components/Header';
import TargetingFilters from '../targetingFilters';
import tokens from 'tokens';
import { updateSearchQuery } from '../slice';
import { NO_ACTIVE_OFFERS, OFFER_PLACEMENTS_TABLE_HEADERS, PLACEMENT_TYPE, SEARCH_OFFERS } from '../../../content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offerPreviewsContainer: {
      width: '100%',
      height: '100%',
    },
    mainContainer: {
      height: '100%',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    offersContainer: {
      height: '100%',
      width: '100%',
    },
    centeredContent: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      height: '80px',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      width: '100%',
    },
    offerListItem: {
      borderBottom: '1px solid rgb(232 232 232)',
      height: '100%',
      marginLeft: theme.spacing(8),
      padding: theme.spacing(2),
    },
    offerTitleAndGroup: {
      margin: '1em',
      wordBreak: 'break-word',
    },
    title: {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
    toggleButtonSelected: {
      color: tokens.colorButtonDefault,
    },
    topDivider: {
      height: 2,
    },
    searchOffers: {
      margin: '1rem 1rem 0rem 0rem',
      width: '100%'
    },
    userForm: {
      padding: '0 1.5em 1.5em 1.5em',
      width: '100%',
      backgroundColor: tokens.colorDefault,
      borderRadius: '10px',
      border: '1px solid ' + tokens.colorSuccess,
      boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
      height: '93px',
      margin: '14px 8px 0px 16px'
    },
    searchOfferTitle: {
      marginTop: '-12px',
      background: 'white',
      width: '105px',
      paddingLeft: 8,
      paddingRight: 8,
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    filterContainer: {
      padding: '16px 16px 0px 16px'
    }
  })
);

const Preview: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [placement, setPlacement] = useState('2');
  const [currentOffers, setCurrentOffers] = useState([]);
  const currentTargeting = useAppSelector(
    (state) => state.offerWallReducer.currentTargeting
  );
  const offerEntitiesById = useAppSelector(
    (state) => state.offerWallReducer.entities.offers.byId
  );
  const currentActiveOffers = useAppSelector(
    (state) => state.offerWallReducer.currentActiveOffers
  );

  const activeOffers = currentActiveOffers.map(
    (offerid) => offerEntitiesById[offerid]
  );
  const offersLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.offersLoading
  );

  const searchQuery = useAppSelector((state) =>
    state.offerWallReducer &&
      state.offerWallReducer.ui &&
      state.offerWallReducer.ui.searchQuery
      ? state.offerWallReducer.ui.searchQuery
      : ''
  );

  const isOffersLoading = offersLoading.state === 'loading';
  const offersFilteredByPlacement =
    activeOffers && activeOffers.length
      ? activeOffers.filter(
        (offer) =>
          offer && offer.placementid && offer.placementid == placement
      )
      : [];

  useEffect(() => {
    const offers =
      offersFilteredByPlacement && offersFilteredByPlacement.length
        ? offersFilteredByPlacement
          .filter(
            (offer) =>
              offer &&
              ((offer.offername &&
                offer.offername
                  .toUpperCase()
                  .includes(searchQuery.toUpperCase())) ||
                (offer.label &&
                  offer.label
                    .toUpperCase()
                    .includes(searchQuery.toUpperCase())) ||
                (offer.offergroupname &&
                  offer.offergroupname
                    .toUpperCase()
                    .includes(searchQuery.toUpperCase())))
          )
          .sort((a, b) => a.zindex - b.zindex)
        : [];
    setCurrentOffers(offers);
  }, [placement, searchQuery, offersLoading]);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    const sourceIndex = source.index;
    const destinationIndex = destination ? destination.index : sourceIndex;
    const newItems = [...currentOffers];
    const [removed] = newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, removed);
    const offerIds = newItems.map((x) => x.offerid);
    dispatch(moveOffer(offerIds));
    setCurrentOffers(newItems);
  };

  const disableDragging =
    (searchQuery && searchQuery.length) || !_.isEmpty(currentTargeting)
      ? true
      : false;

  return (
    <>
      <div className={classes.mainContainer}>
        {/* Header */}
        <Header
          title="Placements"
        />

        <div className={classes.contentContainer}>
          <div className={classes.offerPreviewsContainer}>
            <div className={classes.offersContainer}>
              <div className={classes.filterContainer}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <div className={classes.userForm}>
                      <div className={classes.searchOfferTitle}>{SEARCH_OFFERS}</div>
                      <div className={classes.searchOffers}>
                        <Formik
                          initialValues={{}}
                          onSubmit={() => {

                          }}
                        >
                          <Field
                            name="search-offer"
                            value={searchQuery}
                            style={{ height: 44, width: '100%' }}
                            onChange={(e) => dispatch(updateSearchQuery(e.target.value))}
                          />
                        </Formik>
                      </div>
                    </div>

                  </Grid>
                  <Grid item xs={9}>
                    <TargetingFilters />
                  </Grid>
                </Grid>
              </div>
              <Styled.TabsWrapper>
                <Tabs
                  tabs={[
                    {
                      active: placement === '2',
                      onClick: () => {
                        setPlacement('2');
                      },
                      title: PLACEMENT_TYPE.DASHBOARD,
                    },
                    {
                      active: placement === '1',
                      onClick: () => {
                        setPlacement('1');
                      },
                      title: PLACEMENT_TYPE.OFFER_WALL,
                    },
                    {
                      active: placement === '4',
                      onClick: () => {
                        setPlacement('4');
                      },
                      title: PLACEMENT_TYPE.ONBOARDING,
                    },
                    {
                      active: placement === '3',
                      onClick: () => {
                        setPlacement('3');
                      },
                      title: PLACEMENT_TYPE.KLOVER_PLUS,
                    },
                  ]}
                />
              </Styled.TabsWrapper>

              {/* When offers loading show ghost loader */}
              {isOffersLoading && (
                <div style={{ margin: '32px' }}>
                  {[...Array(10)].map((_, index) => (
                    <Grid container spacing={3}>
                      <Grid item xs={5}>
                        <GhostLoader
                          key={index}
                          height="30px"
                          heightRandom={0}
                          width="100%"
                          widthRandom={0}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <GhostLoader
                          key={index}
                          height="30px"
                          heightRandom={0}
                          width="100%"
                          widthRandom={0}
                        />
                      </Grid><Grid item xs={4}>
                        <GhostLoader
                          key={index}
                          height="30px"
                          heightRandom={0}
                          width="100%"
                          widthRandom={0}
                        />
                      </Grid><Grid item xs={1}>
                        <GhostLoader
                          key={index}
                          height="30px"
                          heightRandom={0}
                          width="100%"
                          widthRandom={0}
                        />
                      </Grid><Grid item xs={1}>
                        <GhostLoader
                          key={index}
                          height="30px"
                          heightRandom={0}
                          width="100%"
                          widthRandom={0}
                        />
                      </Grid>

                    </Grid>
                  ))}
                </div>
              )}

              {/* When offers done loading check if searchResults are true */}
              {currentOffers && currentOffers.length ? (
                <Styled.TableWrapper id='tableWrapper'>
                  <Styled.HideStuff />
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(droppableProvided) => (
                        <StyledTable.Table ref={droppableProvided.innerRef}>
                          <thead>
                            <tr>
                              <Styled.TableHeader>{OFFER_PLACEMENTS_TABLE_HEADERS.NAME}</Styled.TableHeader>
                              <Styled.TableHeader style={{ textAlign: 'right', paddingRight: '2rem'}}>{OFFER_PLACEMENTS_TABLE_HEADERS.ID}</Styled.TableHeader>
                              <Styled.TableHeader>{OFFER_PLACEMENTS_TABLE_HEADERS.GROUP}</Styled.TableHeader>
                              <Styled.TableHeader>{OFFER_PLACEMENTS_TABLE_HEADERS.START_DATE}</Styled.TableHeader>
                              <Styled.TableHeader>{OFFER_PLACEMENTS_TABLE_HEADERS.END_DATE}</Styled.TableHeader>
                            </tr>
                          </thead>

                          <tbody>
                            {currentOffers.map((offer, index) => {
                              return (
                                <Draggable
                                  key={offer.offerid}
                                  draggableId={offer.offerid}
                                  index={index}
                                  isDragDisabled={disableDragging}
                                >
                                  {(draggableProvided, draggableSnapshot) => (
                                    <OfferItemDisplay
                                      offer={offer}
                                      provided={draggableProvided}
                                      snapshot={draggableSnapshot}
                                    />
                                  )}
                                </Draggable>
                              );
                            })}
                            {droppableProvided.placeholder}
                          </tbody>
                        </StyledTable.Table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Styled.TableWrapper>
              ) : null}

              {!isOffersLoading && currentOffers && !currentOffers.length ? (
                <NoOffers />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface OfferItemDisplayProps {
  offer: Offer;
  provided: any;
  snapshot: any;
}

const OfferItemDisplay = ({
  offer,
  provided,
  snapshot,
}: OfferItemDisplayProps) => {
  const history = useHistory();
  const { isDragging } = snapshot;
  const getItemStyle = (draggableStyle) => ({
    ...draggableStyle,
    userSelect: 'none',
    background: '#fff',
    borderRadius: isDragging ? 4 : 0,
    boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.25)' : '',
    height: '60px',
    borderBlock: '1px solid rgb(230, 227, 232)'
  });

  return (
    <Styled.TableBodyRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(provided.draggableProps.style)}
    >
      <StyledTable.TableCell>
        <>
          <Styled.DragIcon />
          <a onClick={() => history.push(`/main/offer-wall-manager/editor/${offer.offerid}`)}>{offer && offer.label ? offer.label : 'N/A'}</a>
        </>
      </StyledTable.TableCell>
      {!isDragging ? (
        <>
          <StyledTable.TableCell style={{ textAlign: 'right', paddingRight: '2rem'}}>
            {offer.offerid ? offer.offerid : 0}
          </StyledTable.TableCell>
          <StyledTable.TableCell>
            {offer && offer.offergroupname ? offer.offergroupname : 'N/A'}
          </StyledTable.TableCell>
          <StyledTable.TableCell>
            {offer.startdate
              ? new Date(offer.startdate).toLocaleDateString()
              : 'N/A'}
          </StyledTable.TableCell>
          <StyledTable.TableCell>
            {offer.enddate
              ? new Date(offer.enddate).toLocaleDateString()
              : 'N/A'}
          </StyledTable.TableCell>
        </>
      ) : null}
    </Styled.TableBodyRow>
  );
};

const LoadingOffers = () => {
  const classes = useStyles();

  return (
    <Box className={classes.centeredContent}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="primary" size={40} />
        </Grid>
      </Grid>
    </Box>
  );
};

const NoOffers = () => {
  const classes = useStyles();

  return (
    <Box className={classes.centeredContent}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <OfferIcon />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            {NO_ACTIVE_OFFERS}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Preview;

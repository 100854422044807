// All components leveraging react-grid-system. Docs and examples here... https://sealninja.github.io/react-grid-system/

import * as Styled from './index.styles';

const Container = ({ ...props }) => {
  return <Styled.Container {...props}>{props.children}</Styled.Container>;
};

const Row = ({ ...props }) => {
  return <Styled.Row {...props}>{props.children}</Styled.Row>;
};

const Col = ({ ...props }) => {
  return <Styled.Col {...props}>{props.children}</Styled.Col>;
};

export { Container, Row, Col };

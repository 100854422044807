import React from 'react';
import axios from 'axios';
import { OfferWallContext } from 'context';
import { Tooltip } from 'antd';
import { Button } from '@klover/attain-design-system';
import * as Styled from './index.styles';
import { Dialog, theme, Grid } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { CLOSE, CREATIVE_ROTATION_NO_LONGER_EXISTS, DELETE, FAILED_TO_DELETE_AB_TEST, REMOVE_AB_TEST, SUCCESSFULLY_DELETED_AB_TEST } from '../../../content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    selectGroup: {
      minWidth: 250,
      height: '50px',
      '& fieldset': {
        top: '-12px !important'
      }
    }
  })
);

const RemoveAbTest = () => {
  const classes = useStyles();
  const [abTestLabel, setAbTestLabel] = React.useState('');
  const {
    existingAbTest,
    setExistingAbTest,
    setRemoveAbTest,
    abTests,
    setAbTests,
    setSnackBarOptions,
  } = React.useContext(OfferWallContext);
  const close = () => {
    setExistingAbTest(null);
    setRemoveAbTest(false);
  };
  const handleSubmit = () => {
    if (existingAbTest) {
      axios
        .delete(`${process.env.API_BASE_ADDRESS}/ab-tests/${existingAbTest}`)
        .then(() => {
          setAbTests(
            abTests.filter((abTest) => abTest.abtestid !== existingAbTest)
          );
          setSnackBarOptions({
            open: true,
            message: SUCCESSFULLY_DELETED_AB_TEST,
            error: false,
          });
        })
        .catch((e) => {
          console.log('Error:', e);
          setSnackBarOptions({
            open: true,
            message: FAILED_TO_DELETE_AB_TEST,
            error: true,
          });
        });
    }
    close();
  };

  React.useEffect(() => {
    if (existingAbTest) {
      axios
        .get(`${process.env.API_BASE_ADDRESS}/ab-tests/${existingAbTest}`)
        .then(({ data }) => {
          setAbTestLabel(data.label);
        })
        .catch((e) => {
          console.log('Error:', e);
        });
    }
  }, []);

  const disableDelete = !existingAbTest;

  return (
    <Dialog
      fullWidth
      className={classes.root}
      onClose={close}
      open={true}
      headerID="removeABTest"
      title=''
      header={
        <Styled.CardHeader>
          <Styled.CardTitle>{REMOVE_AB_TEST}</Styled.CardTitle>
        </Styled.CardHeader>
      }
      bodyID="removeABTestBody"
      footer={
        <>
          <Tooltip
            zIndex={999999999999999}
            title={disableDelete ? CREATIVE_ROTATION_NO_LONGER_EXISTS : ''}
          >
            <span>
              <Button
                variant="contained"
                color="error"
                type="submit"
                onClick={handleSubmit}
              >
                {DELETE}
              </Button>
            </span>
          </Tooltip>
          <Button onClick={close} variant="outlined">
            {CLOSE}
          </Button>
        </>
      }
      body={
        <Styled.StyledBodyContainer
          container
          spacing={theme.spacing.lg}
          direction="column"
        >
          <Grid item>
            <p>
              Are you sure you&apos;d like to remove AB tests from{' '}
              <strong>{abTestLabel}?</strong>
            </p>
          </Grid>
        </Styled.StyledBodyContainer>
      }
    />

  );
};

export default RemoveAbTest;

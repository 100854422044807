import CustomStepper from 'components/customStepper';
import _ from 'lodash';
import { IconButton } from '@klover/attain-design-system';
import { Link, useHistory } from 'react-router-dom';
import {
  createSalesLiftStudy,
  setAllValid,
  setCurrentSalesLiftStudy,
  setStepInvalid,
  setStepValid,
} from '../slice';
import { nanoid } from 'nanoid';
import { updateCurrentGroup } from '../../offerWallManager/slice';
import { useEffect, useState } from 'react';

// Material UI
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// configs
import salesLiftDimensionsConfig from '../configs/salesLiftDimensionsConfig';

// Components
import TargetTransactions from '../targetTransactions';
import { InputType } from '../salesLiftDimensionDialog';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const useStyles = makeStyles(() => ({
  arrowButtonContainer: {
    paddingRight: 10,
  },
  dimensionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  dimensionPaper: {
    width: 1080,
    overflow: 'hidden',
    borderRadius: 10,
  },
  newSalesLiftStudyBar: {
    height: 60,
    fontSize: 18,
    padding: '1em',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  titleBar: {
    height: 50,
    color: 'white',
    fontSize: 17,
    backgroundColor: '#3D399F',
    display: 'flex',
    alignItems: 'center',
  },
  titleBarTextContainer: {
    paddingLeft: '0.5em',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 1000,
  },
  buttonContainerPaddingAndAlignment: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '1em',
    fontSize: 15,
  },
  inputRowContainer: {
    padding: '2em',
  },
  inputLabel: {
    width: 500,
  },
  inputType: {
    width: 500,
  },
}));

const SalesLiftStudyCreator = () => {
  const [index, setIndex] = useState(0);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const defaultCurrentSalesLift = {
    salesliftstudyid: nanoid(),
    name: null,
    startdate: null,
    enddate: null,
    liftconversionevent: 'purchase',
    offers: [],
    dimensions: [],
  };
  const cancel = () => {
    dispatch(setAllValid());
    dispatch(updateCurrentGroup(null));
    dispatch(setCurrentSalesLiftStudy(defaultCurrentSalesLift));
  };
  useEffect(() => {
    cancel();
  }, []);
  useEffect(() => {
    dispatch(setAllValid());
  }, [index]);
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );

  const isInvalid = useAppSelector(
    (state) => state.salesLiftStudiesReducer.isInvalid
  );
  const isStepInvalid = useAppSelector(
    (state) => state.salesLiftStudiesReducer.isStepInvalid
  );
  const anyInvalidInputs = isInvalid && !_.isEmpty(isInvalid) ? true : false;
  const anyEmptySteps =
    isStepInvalid && !_.isEmpty(isStepInvalid) ? true : false;
  const disabled = anyEmptySteps || anyInvalidInputs;

  let dimensions = null;
  if (
    currentSalesLiftStudy &&
    currentSalesLiftStudy.liftconversionevent &&
    currentSalesLiftStudy.liftconversionevent !== 'purchase'
  ) {
    dimensions = salesLiftDimensionsConfig.slice(0, 3);
  } else {
    dimensions = salesLiftDimensionsConfig;
  }
  const lastStep = dimensions.length - 1;
  const currentDimension = dimensions[index];
  const currentInputs = currentDimension.inputs;
  return (
    <div>
      <div className={classes.newSalesLiftStudyBar}>
        <div className={classes.arrowButtonContainer}>
          <IconButton
            onClick={() => history.push('/main/sales-lift-studies')}
            color="primary"
          >
            <ArrowBack />
          </IconButton>
        </div>
        <div>New Sales Lift Study</div>
      </div>
      <CustomStepper activeStep={index} dimensions={dimensions} />
      <div className={classes.dimensionContainer}>
        <Paper className={classes.dimensionPaper}>
          <div className={classes.titleBar}>
            <div className={classes.titleBarTextContainer}>
              {currentDimension.stepperDimensionTitle}
            </div>
          </div>
          <div className={classes.inputStyling}>
            <div className={classes.inputRowContainer}>
              {currentInputs.map(
                ({
                  options,
                  required,
                  helperText,
                  stepperKeyName,
                  stepperInputType,
                  stepperInputLabel,
                  min,
                }) => {
                  const keyName = stepperKeyName;
                  if (
                    required &&
                    currentSalesLiftStudy &&
                    !currentSalesLiftStudy[keyName]
                  ) {
                    dispatch(setStepInvalid({ keyName }));
                  } else {
                    dispatch(setStepValid({ keyName }));
                  }
                  return (
                    <div key={nanoid()} className={classes.inputRow}>
                      <div className={classes.inputLabel}>
                        {stepperInputLabel}
                      </div>
                      <div className={classes.inputType}>
                        <InputType
                          inputLabel={stepperInputLabel}
                          inputType={stepperInputType}
                          keyName={keyName}
                          options={options || {}}
                          required={required || null}
                          helperText={required && helperText ? helperText : ''}
                          min={min}
                        />
                      </div>
                    </div>
                  );
                }
              )}
              {currentDimension &&
              currentDimension.customComponentName === 'targetTransactions' ? (
                <TargetTransactions />
              ) : null}
            </div>
          </div>
        </Paper>
      </div>
      <div className={classes.buttonContainerPaddingAndAlignment}>
        <div className={classes.buttonContainer}>
          <div>
            {index === 0 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  cancel();
                  history.push('/main/sales-lift-studies');
                }}
              >
                CANCEL
              </Button>
            ) : null}
            {index !== 0 ? (
              <Button color="primary" onClick={() => setIndex(index - 1)}>
                BACK
              </Button>
            ) : null}
          </div>
          <div>
            {index !== lastStep ? (
              <Button
                color="primary"
                onClick={() => setIndex(index + 1)}
                disabled={disabled}
              >
                NEXT
              </Button>
            ) : null}
            {index === lastStep ? (
              <Button
                variant="contained"
                disabled={disabled}
                color="primary"
                onClick={() => {
                  dispatch(createSalesLiftStudy(currentSalesLiftStudy));
                  history.push('/main/sales-lift-studies/editor');
                }}
              >
                FINISH
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesLiftStudyCreator;

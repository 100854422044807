import { Offer } from 'pages/offerWallManager/slice';
function isOfferDisabled(offer: Offer) {
  const {
    offername,
    description,
    logourl,
    ctatext,
    headline,
    backgroundimgurl,
    comparisontext,
    comparisonemoji,
  } = offer;
  const errors = [];
  // if (offer.startdate && !offer.enddate)
  //   errors.push('Startdate needs a enddate');
  if (!offer.label) errors.push('Offer needs a label');
  const placementid = offer.placementid ? offer.placementid : '1';
  if (!logourl) errors.push('Offer does not have a logo');
  if (placementid == 1) {
    if (!headline) errors.push('Offer does not have a headline');
    if (!description) errors.push('Offer does not have a body');
    if (!ctatext) errors.push('Offer does not have a call to action');
  } else if (placementid == 2) {
    if (!offername) errors.push('Offer does not have an offer name');
    if (!headline) errors.push('Offer does not have a subline');
    if (!backgroundimgurl)
      errors.push('Offer does not have a background img url');
    if (comparisonemoji) {
      if (!comparisontext) errors.push('Offer does not have comparison text');
    } else {
      if (!description) errors.push('Offer does not have a description');
    }
    if (!ctatext) errors.push('Offer does not have a call to action');
  } else if (placementid == 3) {
    if (!headline) errors.push('Offer does not have a subline');
    if (!description) errors.push('Offer does not have a description');
    if (!ctatext) errors.push('Offer does not have a call to action');
  } else if (placementid == 4) {
    if (!backgroundimgurl)
      errors.push('Offer does not have a background img url');
    if (!headline) errors.push('Offer does not have a subline');
    if (!offername) errors.push('Offer does not have an headline');
    if (!description) errors.push('Offer does not have a body');
    if (!ctatext) errors.push('Offer does not have a call to action');
  } else {
    errors.push('Not a valid placement');
  }
  return errors.length > 0;
}

export default isOfferDisabled;

export const Orientations = [
  '',
  'Heterosexual',
  'Prefer not to answer',
  'Bisexual',
  'Homosexual',
  'Other',
];

export type Orientation = typeof Orientations[number];

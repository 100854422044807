const UpDownArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="3"
        width="10"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4596 8.28996L11.9996 5.82996L9.52965 8.28996C9.13965 8.67996 8.50965 8.67996 8.11965 8.28996C7.72965 7.89996 7.72965 7.26996 8.11965 6.87996L11.2896 3.69996C11.4765 3.5127 11.7301 3.40747 11.9946 3.40747C12.2592 3.40747 12.5128 3.5127 12.6996 3.69996L15.8696 6.87996C16.2596 7.26996 16.2596 7.89996 15.8696 8.28996C15.4796 8.67996 14.8496 8.67996 14.4596 8.28996ZM9.53965 15.71L11.9996 18.17L14.4696 15.72C14.6565 15.5327 14.9101 15.4275 15.1746 15.4275C15.4392 15.4275 15.6928 15.5327 15.8796 15.72C16.2696 16.11 16.2696 16.74 15.8796 17.13L12.7096 20.3C12.3196 20.69 11.6896 20.69 11.2996 20.3L8.12965 17.12C7.73965 16.73 7.73965 16.1 8.12965 15.71C8.31648 15.5227 8.57013 15.4175 8.83465 15.4175C9.09917 15.4175 9.35282 15.5227 9.53965 15.71Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="24" height="24" fill="#9098A1" />
      </g>
    </svg>
  );
};

export default UpDownArrow;

import React, { useEffect, useState } from 'react';
import {
  AudienceGroup,
  ToggleGroupDeleteDialogAction,
  saveGroup,
  setGroupSavingState,
  toggleGroupDeleteDialog,
  updateGroup,
} from '../slice';
import { IconButton } from '@klover/attain-design-system';
import { SnackBarOptions, defaultSnackBarOptions } from 'interfaces/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import Box from '@material-ui/core/Box';
import {
  FloppyDiskBack,
  PencilSimple,
  TrashSimple,
  X,
} from '@phosphor-icons/react';

import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import {
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() =>
  createStyles({
    editButton: {
      marginLeft: '20px !important',
      padding: 4,
    },
    deleteButton: {
      marginLeft: 4,
      padding: 4,
    },
    groupName: {
      fontSize: 20,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
        fontSize: 14,
        fontFamily: 'Calibre-Regular,sans-serif',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const GroupName = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>(
    defaultSnackBarOptions
  );

  const currentGroup = useAppSelector(
    (state) => state.audienceReducer.currentGroup
  );

  useEffect(() => {
    setName(currentGroup?.name || '');
    setEditMode(false);
  }, [currentGroup]);

  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    const updatedSnackBarOptions = {
      ...snackBarOptions,
      open: false,
    };
    dispatch(setGroupSavingState({ state: 'idle' }));
    setSnackBarOptions(updatedSnackBarOptions);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleDelete = () => {
    const actionProps: ToggleGroupDeleteDialogAction = {
      open: true,
    };

    dispatch(toggleGroupDeleteDialog(actionProps));
  };

  const handleEditName = () => {
    setEditMode(true);
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    setName(name);
  };

  const handleSave = () => {
    if (currentGroup && name !== '') {
      const updatedGroup: AudienceGroup = {
        ...currentGroup,
        name: name,
      };
      dispatch(saveGroup(updatedGroup));
      dispatch(updateGroup(updatedGroup));
    } else {
      dispatch(
        setGroupSavingState({
          state: 'failed',
          message: 'Group name is required',
        })
      );
      setName(currentGroup?.name || '');
    }
  };

  const isDisabled = useAppSelector(
    (state) => state.audienceReducer.ui.groupSaving.state === 'loading'
  );

  return (
    <>
      <ThemeProvider theme={inputs}>
        <Box display="flex" alignItems="center">
          {editMode ? (
            <>
              <TextField
                id="group-name"
                label="Group Name"
                onChange={handleNameChange}
                defaultValue={currentGroup?.name}
                disabled={isDisabled}
                value={name}
                variant="outlined"
                style={{ marginRight: 8 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="default"
                        disabled={isDisabled}
                        onClick={handleSave}
                        size="small"
                      >
                        <FloppyDiskBack size={24} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton disabled={isDisabled} onClick={handleCancel}>
                <X size={24} />
              </IconButton>
            </>
          ) : (
            <>
              <Typography className={classes.groupName}>
                {currentGroup?.name}
              </Typography>

              <IconButton
                className={classes.editButton}
                color="default"
                onClick={handleEditName}
                size="small"
              >
                <PencilSimple size={24} />
              </IconButton>

              <IconButton
                className={classes.deleteButton}
                color="default"
                onClick={handleDelete}
                size="small"
              >
                <TrashSimple size={24} />
              </IconButton>
            </>
          )}
        </Box>
      </ThemeProvider>

      <Snackbar
        open={snackBarOptions.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity={snackBarOptions.severity}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

interface GroupNameSaveProps {
  isDisabled: boolean;
}

const GroupNameSaveIcon = ({ isDisabled }: GroupNameSaveProps) => {
  return (
    <>
      {isDisabled ? (
        <CircularProgress color="primary" size={20} />
      ) : (
        <SaveIcon />
      )}
    </>
  );
};

export default GroupName;

import React from 'react';
import * as Styled from './index.styles';
export interface HeaderProps {
  title: React.ReactNode | string;
  center?: React.ReactNode | string;
  action?: React.ReactNode;
  centerBorder?: boolean;
}

const Header = ({
  title,
  center,
  action,
  centerBorder,
  ...props
}: HeaderProps) => {
  return (
    <Styled.Header {...props}>
      <Styled.Title>{title}</Styled.Title>
      {centerBorder ? (
        <Styled.CenterBorder>{center}</Styled.CenterBorder>
      ) : (
        <Styled.Center>{center}</Styled.Center>
      )}
      <Styled.Action>{action}</Styled.Action>
    </Styled.Header>
  );
};

export default Header;

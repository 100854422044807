import React from 'react';
import { useAppSelector } from 'redux/hooks';

// Material UI Components
import Box from '@material-ui/core/Box';
import GhostLoader from 'react-ghost-loader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import { Users } from '@phosphor-icons/react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ESTIMATED_AUDIENCE_SIZE, LAST_MODIFIED } from '../../../content';

const useStyles = makeStyles(() =>
  createStyles({
    audienceSize: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontFamily: 'Calibre-Regular,sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
    },
    title: {
      fontFamily: 'Calibre-Regular,sans-serif',
      paddingTop: 0,
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 14,
    },
    lastModified: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    spinner: {
      color: tokens.colorSpinnerDark,
    },
  })
);

const TotalAudienceSize: React.FC = () => {
  const classes = useStyles();
  const updating = useAppSelector(
    (state) => state.audienceReducer.ui.totalAudienceUpdating
  );
  const audience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );
  const modeled = useAppSelector((state) => {
    return state.audienceReducer.ui.modeled;
  });

  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingRight={2}
      >
        {audience &&
          audience.updatedAt !== undefined &&
          audience.updatedAt !== 0 && (
            <>
              <Typography className={classes.title}>{LAST_MODIFIED}</Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.lastModified}
              >
                {new Date(Number(audience.updatedAt)).toLocaleDateString(
                  'en-US'
                )}
                ・
                {new Date(Number(audience.updatedAt)).toLocaleTimeString(
                  'en-US'
                )}
              </Typography>
            </>
          )}
      </Box>

      <Box
        style={{
          paddingRight: 16,
          borderLeft: '2px solid rgb(232 232 232)',
          paddingLeft: 16,
        }}
      >
        <Grid container direction="column" justifyContent="center">
          <Grid item xs>
            <Typography className={classes.title}>
              {ESTIMATED_AUDIENCE_SIZE}
            </Typography>
          </Grid>
          <Grid item xs container direction="row" alignItems="center">
            <Grid item xs={2} alignItems="flex-end">
              <Users size={24} />
            </Grid>
            <Grid item xs={10}>
              <Typography
                className={classes.audienceSize}
                style={{ marginLeft: 8 }}
              >
                {updating ? (
                  <GhostLoader
                    height="30px"
                    heightRandom={0}
                    width="100px"
                    widthRandom={0}
                  />
                ) : audience && audience.totalSize ? (
                  audience.totalSize.toLocaleString()
                ) : (
                  '0'
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TotalAudienceSize;

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { useAppDispatch } from 'redux/hooks';
import { useState } from 'react';

interface CustomSnackBarProps {
  index: number;
  trigger: boolean;
  severity: string;
  message: string;
  removeNotificationFunction: any;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomSnackBar = ({
  index,
  trigger,
  severity,
  message,
  removeNotificationFunction,
}: CustomSnackBarProps) => {
  const dispatch = useAppDispatch();
  const [snackBarOpen, setSnackBarOpen] = useState(true);
  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackBarOpen(false);
    setTimeout(() => dispatch(removeNotificationFunction()), 100);
  };
  return (
    <Snackbar
      open={trigger && snackBarOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={1500 + index * 100}
      onClose={closeSnackBar}
    >
      <Alert onClose={closeSnackBar} severity={severity}>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;

import styled from 'styled-components';
import tokens from 'tokens';

export const OfferLabel = styled.div`
  max-width: 350px;
`;

export const ButtonSpacing = styled.div`
  display: flex;
  margin-left: -14px;

  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;

// Select Component - Uses https://ant.design/components/select (see for documentation)
import React from 'react';

import * as Styled from './index.styles';

const Select = ({ ...props }): React.ReactElement => {
  return <Styled.Wrapper {...props}>{props.children}</Styled.Wrapper>;
};

export default Select;

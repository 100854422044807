export const AgeRanges: {
  [key: string]: string | { values: string | string[]; operator?: string };
} = {
  '': '',
  '18 - 24': { values: ['18', '24'] },
  '25 - 34': { values: ['25', '34'] },
  '35 - 44': { values: ['35', '44'] },
  '45 - 54': { values: ['45', '54'] },
  '55 - 64': { values: ['55', '64'] },
  '65+': { operator: '>=', values: '65' },
};
export const AgeRangeKeys = Object.keys(AgeRanges);
export const AgeRangeValues = Object.values(AgeRanges);
export type AgeRangeKey = typeof AgeRangeKeys[number];
export type AgeRangeValue = typeof AgeRangeValues[number];

import { Button } from '@klover/attain-design-system';
import { ChangeEvent, useRef } from 'react';
interface fileInputProps {
  isDisabled: boolean;
  setFile: (value: File | null) => void;
}
const FileInput = (props: fileInputProps) => {
  const { isDisabled, setFile } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        accept="text/csv"
        ref={inputRef}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const file = e.target.files ? e.target.files[0] : null;
          setFile(file);
        }}
      />
      <Button
        disabled={isDisabled}
        onClick={() => {
          inputRef.current?.click();
        }}
        variant="contained"
      >
        Choose File
      </Button>
    </>
  );
};

export default FileInput;

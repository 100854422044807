import { Button, IconButton } from '@klover/attain-design-system';
import { Link, useHistory } from 'react-router-dom';
import {
  addNotification,
  createOffer,
  saveOffer,
  toggleDraftStateDialog,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import BootstrapTooltip from 'components/bootstrapTooltip';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import setSaveOfferButton from '../utils/setSaveOfferButton';
import { CircularProgress } from '@material-ui/core';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

import SaveOfferDisabledButtonMessage from '../saveDisabledButtonMessage';
import tokens from 'tokens';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

interface Props {
  openDraftStateDialog: any;
}

const DraftStateDialog = ({ openDraftStateDialog }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const currentGroup = useAppSelector(
    (state) => state.offerWallReducer.currentGroup
  );
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );

  const originalOffer = useAppSelector(
    (state) => state.offerWallReducer.originalOffer
  );

  const OfferLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.offerLoading
  );

  const saveOfferButtonSettings = setSaveOfferButton(currentOffer);
  const { isDisabled } = saveOfferButtonSettings;
  let { SaveTitle = <SaveOfferDisabledButtonMessage /> } =
    saveOfferButtonSettings;
  SaveTitle =
    SaveTitle === null ? <SaveOfferDisabledButtonMessage /> : SaveTitle;

  const handleSaveOffer = async () => {
    const updatedOffer = {
      ...currentOffer,
    };
    if (originalOffer) {
      dispatch(saveOffer(updatedOffer));
    } else {
      if (currentGroup) {
        updatedOffer.offergroupid = currentGroup.id;
        dispatch(createOffer({ updatedOffer, currentGroup }));
      } else {
        dispatch(
          addNotification({
            state: 'error',
            message: `No current group.`,
          })
        );
      }
    }
    dispatch(toggleDraftStateDialog({ open: false }));
    history.push('/main/offer-wall-manager');
  };

  const isOfferLoading = OfferLoading.state === 'loading';

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          open={openDraftStateDialog.open}
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography className={classes.title}>
                  <span style={{ fontWeight: 'bolder' }}>
                    {currentOffer?.offername}
                  </span>{' '}
                  Draft
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={2}>
                <IconButton
                  onClick={() =>
                    dispatch(toggleDraftStateDialog({ open: false }))
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          {/* <Box m={3}> */}
          <DialogContent style={{ padding: '24px 32px 8px 32px' }}>
            <DialogContentText
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              The offer has been edited, do you want to save{' '}
              <span style={{ fontWeight: 'bolder' }}>
                {currentOffer?.offername}
              </span>{' '}
              ?
            </DialogContentText>
          </DialogContent>
          {/* </Box> */}

          {/* Bottom Divider */}
          <Grid container spacing={0} style={{ marginTop: 150 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Actions */}
          <Box m={1}>
            <DialogActions>
              <Button
                color="primary"
                onClick={() =>
                  dispatch(toggleDraftStateDialog({ open: false }))
                }
                variant="outlined"
              >
                Cancel
              </Button>
              {openDraftStateDialog && openDraftStateDialog.path ? (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    dispatch(toggleDraftStateDialog({ open: false }));
                    history.push(openDraftStateDialog.path);
                  }}
                >
                  {openDraftStateDialog.path === '/main/offer-wall-manager'
                    ? `Exit Editor Without Saving`
                    : null}
                </Button>
              ) : null}
              <BootstrapTooltip title={SaveTitle}>
                <span>
                  {isOfferLoading ? (
                    <Box
                      width={142}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress color="primary" size={20} />
                    </Box>
                  ) : (
                    <Button
                      color="primary"
                      disableElevation
                      disabled={isDisabled}
                      onClick={handleSaveOffer}
                      variant="contained"
                    >
                      Save Offer
                    </Button>
                  )}
                </span>
              </BootstrapTooltip>
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
export default DraftStateDialog;

import moment from 'moment';

const convertTimeStampToDateTime = (
  timestamp: number,
  format = 'MM/DD/YYYY'
) => {
  return moment(timestamp).format(format);
};

export default convertTimeStampToDateTime;

import axios from 'axios';
import { auth } from 'utils/firebase';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSomeAudiences } from '../slice';
import { updateAudiencesEntities } from '../../audienceManager/slice';

const API_ENDPOINT = `${process.env.API_BASE_ADDRESS}/audience-manager/audiences`;

function handleFetchAudiences(query) {
  const ROWS_PER_PAGE = 25;
  const SORT_BY = 'updatedAt';
  const SORT_ORDER = 'DESC';
  const AUDIENCE_SEARCH_QUERY_URL = `${API_ENDPOINT}?search=${query}&rowsPerPage=${ROWS_PER_PAGE}&sortBy=${SORT_BY}&sortOrder=${SORT_ORDER}`;
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(`${AUDIENCE_SEARCH_QUERY_URL}`);
  });
}

function* watchFetchAllAudiences(action) {
  try {
    const query = action.payload;
    const result = yield call(handleFetchAudiences, query);
    const audiences = result.data;
    for (const audience of audiences) {
      yield put(updateAudiencesEntities(audience));
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* watchAll() {
  yield takeLatest<any>(fetchSomeAudiences.type, watchFetchAllAudiences);
}

import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  align-items: center;
  background: ${theme.colors.backgroundBrand};
  border-bottom: 1px solid hsla(0, 100%, 100%, 0.2);
  color: ${theme.colors.textInverted};
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: ${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.md}
    ${theme.spacing.lg};
  width: 100%;
`;

export const Logo = styled.img`
  height: 28px;
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xs};

  svg {
    color: ${theme.colors.textInverted};
  }
`;

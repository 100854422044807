import { Link as MuiLink } from '@mui/material';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

interface LinkComponentProps {
  to: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  disabled?: boolean;
}

const LinkComponent = ({
  to,
  children,
  onClick,
  disabled,
}: LinkComponentProps) => {
  const history = useHistory();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (disabled) {
        event.preventDefault();
        return;
      }

      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const isCtrlOrCmdPressed = isMac ? event.metaKey : event.ctrlKey;

      // Open in new tab if Ctrl+Click (Windows) or Cmd+Click (Mac)
      if (isCtrlOrCmdPressed) {
        return; // Do nothing, let the browser handle the new tab opening
      }

      // Otherwise, prevent default behavior and navigate within the same tab
      event.preventDefault();
      history.push(to);

      // Call the onClick handler if provided
      if (onClick) {
        onClick(event);
      }
    },
    [to]
  );

  return (
    <MuiLink
      href={disabled ? undefined : to}
      onClick={handleClick}
      style={{ fontWeight: 400 }}
      sx={{
        textDecoration: 'none',
        fontSize: 16,
        fontFamily: 'Calibre-Regular, sans-serif',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: disabled ? 'none' : 'underline',
        },
      }}
    >
      {children}
    </MuiLink>
  );
};

export default LinkComponent;

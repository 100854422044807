const conversionEventDimensionConfig = {
  dimensionName: 'Conversion Event',
  stepperDimensionName: 'Name & conversion event',
  stepperDimensionTitle: 'Name & conversion event',
  inputs: [
    {
      stepperKeyName: 'name',
      stepperInputType: 'textfield',
      stepperInputLabel: 'Sales Lift Study Name',
      helperText: 'A name is required',
      required: true,
    },
    {
      inputLabel: 'Lift Conversion Event',
      keyName: 'liftconversionevent',
      inputType: 'select',
      stepperKeyName: 'liftconversionevent',
      stepperInputType: 'select',
      stepperInputLabel: 'Lift Conversion Event',
      helperText: 'A lift conversion event is required',
      required: true,
      options: {
        purchase: 'Purchase',
        // signUp: 'Sign Up',
        // formFill: 'Form Fill',
        // click: 'Click',
        // other: 'Other',
      },
    },
  ],
};

export default conversionEventDimensionConfig;

import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import tokens from 'tokens';

export const StyledButton = styled(Button)`
  background-color: ${tokens.colorBrandprimary};
  color: ${tokens.colorBodynegative};
  &:hover {
    background-color: ${tokens.colorBrandprimaryhover};
    color: ${tokens.colorBodynegative};
  }
  &:disabled {
    background-color: ${tokens.colorGray05};
  }
`;

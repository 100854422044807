import CreateAnswerResponses from '../createAnswerResponse';
import ErrorMsg from 'components/ErrorMsg';
import Field from 'components/Field';
import Picker from 'emoji-picker-react';
import SelectFreeFormAnswerResponseType from '../SelectFreeFormAnswerResponseType';
import Stack from 'components/Stack';
import _ from 'lodash';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Button, Dialog, MenuItem, Select } from '@klover/attain-design-system';
import { CircularProgress, DialogContentText } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { LoadingStates } from 'constants/index';
import {
  QQ_ANSWERTYPE_FREE_FORM,
  QQ_ANSWERTYPE_MULTIPLE,
  QQ_ANSWERTYPE_SINGLE,
} from '../variables';
import { Question } from 'interfaces/quickQuestionsManager';
import { QuickQuestionContext } from 'context';
import { UseState } from 'interfaces';
import { addNotification } from 'pages/audienceManager/slice';
import { useAppDispatch } from 'redux/hooks';
import { useContext } from 'react';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';
import * as Yup from 'yup';

type Props = {
  originalQuestion: Question;
  hasResponses: boolean;
  setQuestion: UseState;
  user: any;
};

const EditDetails = ({
  originalQuestion,
  hasResponses,
  setQuestion,
  user,
}: Props) => {
  const dispatch = useAppDispatch();
  const { setShowEditDetails, showEditDetails } =
    useContext(QuickQuestionContext);

  const validationSchema = Yup.object().shape({
    questiontext: Yup.string().required('Question is required.'),
    answers: Yup.array().when(['answertype'], {
      is: (answertype: string) =>
        answertype === QQ_ANSWERTYPE_SINGLE ||
        answertype === QQ_ANSWERTYPE_MULTIPLE,
      then: Yup.array()
        .of(
          Yup.object().shape({
            answertext: Yup.string().required('An answer is required.'),
          })
        )
        .min(2, 'Needs at least two answers.'),
    }),
    points: Yup.number()
      .integer('Points need to be an integer.')
      .max(500, 'Points cannot be greater than 500.')
      .min(0, 'Points must be positive.')
      .required('Points are required.'),
    zindex: Yup.number()
      .integer('Z-Index needs to be an integer.')
      .min(0, 'Z-Index must be positive.')
      .required('Z-Index is required.'),
  });

  const formik = useFormik({
    initialValues: {
      ...originalQuestion,
      questionemoji: {
        unified: originalQuestion.questionemoji,
        emoji: null,
        originalUnified: originalQuestion.questionemoji,
        names: [''],
      },
      changeEmoji: false,
      questionSaving: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      formik.setFieldValue('questionSaving', true);
      try {
        const modifiedPayload: Question = _.omit(_.clone(values), [
          'changeEmoji',
          'questionSaving',
        ]);
        modifiedPayload.questionemoji =
          modifiedPayload.questionemoji.unified.toUpperCase();
        modifiedPayload.description += modifiedPayload.questiontext;
        modifiedPayload.answers = modifiedPayload.answers?.map(
          (answer, index) => ({
            ...answer,
            answerid: index + 1 < 10 ? `0${index + 1}` : `${index + 1}`,
          })
        );
        modifiedPayload.user = user;

        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          const questionRes = await axiosInstance?.put(
            `${API_BASE_ADDRESS}/questions/${originalQuestion.questionid}`,
            modifiedPayload
          );
          setQuestion(questionRes.data);
          setShowEditDetails(false);
          formik.setFieldValue('questionSaving', false);
          dispatch(
            addNotification({
              state: LoadingStates.DONE,
              message: 'Quick Question Updated',
            })
          );
        } else {
          throw new Error('Unable to authenticate request');
        }
      } catch (e) {
        console.log('the error', e);
        setShowEditDetails(false);
        formik.setFieldValue('questionSaving', false);
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: 'Error Saving Quick Question.',
          })
        );
      }
    },
  });

  const {
    answertype,
    answers,
    freeformanswertype,
    changeEmoji,
    questionemoji,
    questionSaving,
  } = formik.values;
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={() => {
        formik.resetForm();
        setShowEditDetails(false);
      }}
      title="Edit Question"
      open={showEditDetails}
      headerID="editQuestionHeader"
      header={
        <Styled.CardHeader>
          <Styled.CardTitle>Edit Question</Styled.CardTitle>
        </Styled.CardHeader>
      }
      bodyID="editQuestionBody"
      body={
        <FormikProvider value={formik}>
          <Styled.GridContainer>
            <Stack spacing={tokens.spacing.md}>
              {hasResponses && (
                <Grid.Row>
                  <Grid.Col>
                    <DialogContentText color="error">
                      Question has been answered by users. Text, Answertype and
                      Responses cannot be modified.
                    </DialogContentText>
                  </Grid.Col>
                </Grid.Row>
              )}
              {!hasResponses && (
                <Grid.Row>
                  <Grid.Col>
                    <Field
                      fullWidth
                      name="questiontext"
                      label="Text"
                      placeholder="e.g How many children in your household?"
                    />

                    <ErrorMsg name="questiontext" />
                  </Grid.Col>
                </Grid.Row>
              )}

              <Grid.Row>
                <Grid.Col>
                  <Field
                    fullWidth
                    name="points"
                    label="Points"
                    type="number"
                    placeholder="e.g 10"
                  />

                  <ErrorMsg name="points" />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Styled.Label>Emoji</Styled.Label>

                  <Styled.PickerSpace
                    changeEmoji={changeEmoji}
                    onMouseLeave={() =>
                      formik.setFieldValue('changeEmoji', false)
                    }
                    onMouseEnter={() =>
                      formik.setFieldValue('changeEmoji', true)
                    }
                  >
                    <Button type="button" variant="outlined">
                      Choose Emoji {questionemoji.emoji}
                    </Button>

                    {changeEmoji ? (
                      <>
                        <label htmlFor="questionemoji" hidden>
                          Emoji
                        </label>

                        <Picker
                          onEmojiClick={(_, emojiObject) =>
                            formik.setFieldValue('questionemoji', emojiObject)
                          }
                          disableAutoFocus={true}
                          disableSkinTonePicker
                          groupNames={{ smileys_people: 'PEOPLE' }}
                          native
                          groupVisibility={{
                            flags: false,
                            food_drink: false,
                            travel_places: false,
                            activities: false,
                            objects: false,
                            recently_used: false,
                          }}
                        />
                      </>
                    ) : null}
                  </Styled.PickerSpace>
                </Grid.Col>
                {!hasResponses && (
                  <Grid.Col>
                    <label htmlFor="answertype" hidden>
                      Answer Type
                    </label>

                    <Styled.Label>Answer Type</Styled.Label>
                    <Select
                      name="answertype"
                      value={answertype}
                      onChange={formik.handleChange}
                      disabled={hasResponses}
                    >
                      <MenuItem value={QQ_ANSWERTYPE_SINGLE}>Single</MenuItem>
                      <MenuItem value={QQ_ANSWERTYPE_MULTIPLE}>
                        Multiple
                      </MenuItem>
                      {/* <option value={QQ_ANSWERTYPE_FREE_FORM}>Free Form</option> */}
                    </Select>
                  </Grid.Col>
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  {(answertype === QQ_ANSWERTYPE_SINGLE ||
                    answertype === QQ_ANSWERTYPE_MULTIPLE) &&
                    !hasResponses && (
                      <CreateAnswerResponses answers={answers} />
                    )}
                  {answertype === QQ_ANSWERTYPE_FREE_FORM && !hasResponses && (
                    <SelectFreeFormAnswerResponseType
                      freeformanswertype={freeformanswertype}
                      handleChange={formik.handleChange}
                    />
                  )}
                </Grid.Col>
              </Grid.Row>
            </Stack>
          </Styled.GridContainer>
        </FormikProvider>
      }
      footer={
        <>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            variant="contained"
            disabled={
              !formik.isValid ||
              formik.isSubmitting ||
              !Object.keys(formik.touched).length
                ? true
                : false
            }
          >
            {questionSaving ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              'Save'
            )}
          </Button>
          <Button
            type="cancel"
            variant="outlined"
            onClick={() => {
              formik.resetForm();
              setShowEditDetails(false);
            }}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default EditDetails;

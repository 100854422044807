// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/calibre-medium.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/calibre-regular.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/calibre-semibold.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Calibre-Medium';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n}\n\n@font-face {\n  font-family: 'Calibre-Regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n}\n\n@font-face {\n  font-family: 'Calibre-Semibold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2');\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,4DAAwD;AAC1D;;AAEA;EACE,8BAA8B;EAC9B,4DAAyD;AAC3D;;AAEA;EACE,+BAA+B;EAC/B,4DAA0D;AAC5D","sourcesContent":["@font-face {\n  font-family: 'Calibre-Medium';\n  src: url('./fonts/calibre-medium.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Calibre-Regular';\n  src: url('./fonts/calibre-regular.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Calibre-Semibold';\n  src: url('./fonts/calibre-semibold.woff2') format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

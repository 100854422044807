const creativeDetailsOnBoarding = {
  imageUrl: 'logourl',
  backgroundImageUrl: 'backgroundimgurl',
  headline: 'offername',
  subline: 'headline',
  body: 'description',
  callToAction: 'ctatext',
  clickthroughUrl: 'ctaurl',
};

export default creativeDetailsOnBoarding;

import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import {
  AllowCustomAudiencePartnerExports,
  AudienceDefaultExports,
  KDSPageNames,
} from 'constants/index';
import { Method } from 'axios';
import { useEffect, useState } from 'react';

const useFetchCustomAudienceGroups = (
  method: Method,
  body: string | null,
  paramsId: string | null
) => {
  const URL = `${API_BASE_ADDRESS}/${KDSPageNames.CUSTOMAUDIENCES}/groups`;
  const [isLoading, setIsLoading] = useState(false);
  const [groupsData, setGroupsData] = useState<any>(null);
  const [rerun, setRerun] = useState(true);

  const reFetch = () => {
    setRerun((prev) => !prev);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          const resp = await axiosInstance({
            params: { id: paramsId },
            method: method,
            url: URL,
            data: body,
          });
          const data = await resp?.data;
          setGroupsData(data);
          setIsLoading(false);
        } else {
          console.log('Failed to authenticate axios instance');
          throw { message: 'Failed to authenticate axios instance' };
        }
      } catch (e: any) {
        console.error('Error from api: ', e);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [method, body, paramsId, rerun]);

  return { isLoading, groupsData, reFetch };
};

const useFetchCustomAudiences = (
  method: Method,
  body: string | null,
  paramsId: string | null,
  paramsGroupId: number | null,
  search: string,
  rowsPerPage?: number | null,
  sortOrder?: string | null,
  sortBy?: string | null,
  page?: number | null,
  groupId?: string | null,
  email?: string | null,
  isEmailLoading?: boolean | null
) => {
  const group = groupId === 'all' ? '' : groupId;
  const emailAddress = email === 'all' ? '' : email;
  const url = `${API_BASE_ADDRESS}/${
    KDSPageNames.CUSTOMAUDIENCES
  }/audiences?search=${encodeURIComponent(
    search
  )}&page=${page}&rowsPerPage=${rowsPerPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&groupId=${group}&email=${emailAddress}`;
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState<any>(null);
  const [rerun, setRerun] = useState(true);

  const reFetch = () => {
    setRerun((prev) => !prev);
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          const resp = await axiosInstance({
            params: {
              id: paramsId,
            },
            method: method,
            url: url,
            data: body,
          });
          let audiences = (await resp?.data) || [];
          audiences = audiences?.map((audience) => {
            const exportHistory =
              audience?.exportHistory?.filter((x) =>
                AudienceDefaultExports.includes(x.export_type)
              ) || [];
            const listPageExportHistory =
              audience?.exportHistory?.filter((x) =>
                AllowCustomAudiencePartnerExports.includes(x.export_type)
              ) || [];
            const latestShowcaseExport =
              exportHistory.length > 0 ? exportHistory[0] : null;
            if (latestShowcaseExport) {
              return {
                ...audience,
                segmentName: latestShowcaseExport?.segment_name || '',
                exportStatus: latestShowcaseExport?.exportStatus || '',
                exportedBy: latestShowcaseExport?.export_by || '',
                sentFilename: latestShowcaseExport?.file_name || '',
                exportHistory, //Use in Custom Audience page
                listPageExportHistory, //Display in Audience Export History List Model
              };
            }

            return {
              ...audience,
              exportHistory, //Use in Custom Audience page
              listPageExportHistory, //Display in Audience Export History List Model
            };
          });

          setResponseData(audiences);
          setIsLoading(false);
        } else {
          console.log('Failed to authenticate axios instance');
          throw { message: 'Failed to authenticate axios instance' };
        }
      } catch (e: any) {
        console.error('Error from api: ', e);
        setIsLoading(false);
      }
    };
    if (!isEmailLoading) {
      fetchData();
    }
  }, [
    method,
    body,
    paramsId,
    paramsGroupId,
    rerun,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    group,
    search,
    email,
    isEmailLoading,
  ]);

  return { isLoading, responseData, reFetch };
};

export { useFetchCustomAudienceGroups, useFetchCustomAudiences };

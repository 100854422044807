import React, { SyntheticEvent, useEffect, useState } from 'react';
import { ILoginReducer, TLogin } from 'types/types';
import { Link, useHistory } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { loginAction } from './actions';
import { Button } from '@klover/attain-design-system';

// Material UI Components
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

// Components
import AppLogo from 'assets/images/app-logo.svg';
import Copyright from 'components/copyright';
import Spinner from 'components/spinner';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  appLogo: {
    margin: theme.spacing(1),
    width: '75px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // Actions
  const authActions = (payload: TLogin) => dispatch(loginAction(payload));

  // Reducers
  const authData: ILoginReducer = useSelector(
    (state: RootStateOrAny) => state.loginReducer
  );

  // States
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (authData.data?.res?.uid) {
      history.push('/main/audience-manager');
    }
  }, [authData, history]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    authActions({
      email: email,
      password: password,
    });
  };

  return authData.loading ? (
    <Spinner />
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Container component="main" maxWidth="xs">
        <Box border={1} borderColor="grey.300" borderRadius={8} p={4}>
          <div className={classes.paper}>
            <img src={AppLogo} className={classes.appLogo} alt="Klover" />
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            {authData.error && (
              <Box mt={1}>
                <Alert severity="error">Email and password are required</Alert>
              </Box>
            )}
            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => handleSubmit(e)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </form>
            <Link to="/reset-password">Forgot password?</Link>
          </div>
        </Box>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </Grid>
  );
};

export default Login;

export const ProductMerchantNames = [
  '',
  'WALMART',
  'AMAZON',
  'DOLLAR GENERAL',
  'TARGET',
  'WALGREENS',
  'FAMILY DOLLAR',
  'DOLLAR TREE',
  'CVS',
  'KROGER',
  'HOME DEPOT',
  'LOWES HOME IMPROVEMENT',
  'PUBLIX SUPER MARKETS',
  'CIRCLE K',
  'ELEVEN',
  'FOOD LION',
  'HEB',
  'ALDI',
  'MEIJER',
  'SAMS CLUB',
  'SAFEWAY',
  'FIVE BELOW',
  'KOHLS',
  'RITE AID',
  'COSTCO WHOLESALE CORP',
  'BIG LOTS',
  'MICHAELS STORES',
  'SHELL',
  'MCDONALDS',
  'WINCO FOODS',
  'FRED MEYER',
  'MACYS',
  'BP',
  'WINN DIXIE',
  'PETSMART',
  'ULTA BEAUTY',
  'EXXON',
  'ALBERTSONS',
  'STOP SHOP',
  'SHOPRITE',
  'FRYS FOOD AND DRUG',
  'DICKS SPORTING GOODS',
  'CHEVRON',
  'SPEEDWAY',
  'SAVEALOT PYRAMID FOODS',
  'PETCO',
  'ATT',
  'ACE HARDWARE',
  'PARTY CITY',
  'KING SOOPERS',
  'ROSES',
  'HARRIS TEETER',
  'QUIKTRIP',
  'WHOLE FOODS MARKET',
  'JEWEL OSCO',
  'HYVEE',
  'HANNAFORD',
  'BJS WHOLESALE CLUB',
  'FOOD CITY',
  'MARATHON OIL',
  'RALPHS',
  'WOODMANS MARKETS',
  'SMITHS FOOD AND DRUG',
  'WAWA',
  'FOOD LESS',
  'TOTAL WINE MORE',
  'PAY LESS SUPER MARKETS',
  'JOANN FABRIC AND CRAFT STORES',
  'TRADER JOES',
  'STAPLES',
  'BARNES AND NOBLE',
  'GIANT MD',
  'GOODWILL',
  'CENTS ONLY STORES',
  'STATER BROS',
  'WEGMANS FOOD MARKETS',
  'GIANT FOOD STORE',
  'WHATABURGER',
  'PIGGLY WIGGLY',
  'SUNOCO',
  'CUB FOODS',
  'BED BATH BEYOND',
  'NIKE FACTORY STORE',
  'CASEYS GENERAL STORE',
  'GIANT EAGLE',
  'INGLES MARKETS',
  'MURPHY USA',
  'VONS',
  'SPROUTS FARMERS MARKET',
  'MILITARY COMMISSARY',
  'LIQUOR DEPOT',
  'PILOT TRAVEL CENTERS',
  'SHEETZ',
  'TJ MAXX',
  'RACETRAC',
  'BURGER KING',
  'BELK',
  'IGA',
  'LOVES TRAVEL STOP',
  'PRICE CHOPPER',
  'TACO BELL',
  'ACME MARKETS',
  'WENDYS',
  'PET SUPPLIES PLUS',
  'HARBOR FREIGHT TOOLS',
  'SCHNUCKS',
  'CITGO',
  'ARCO',
  'TOPS FRIENDLY MARKETS',
  'DEMOULAS MARKET BASKET',
  'WALMARTCOM',
  'KWIK TRIP',
  'BILO',
  'ARMY AND AIR FORCE EXCHANGE SERVICE',
  'USPS',
  'MOBIL',
  'TOM THUMB',
  'SMART FINAL',
  'HOBBY LOBBY',
  'LIDL',
  'FIESTA MART',
  'WALGREENSCOM',
  'STRIPES CONVENIENCE STORES',
  'NORDSTROM RACK',
  'MENARDS',
  'DILLONS',
  'GROCERY OUTLET BARGAIN MARKET',
  'FOOD DEPOT',
  'REDNERS MARKETS',
  'WEIS MARKETS',
  'KANGAROO EXPRESS',
  'MARCS',
  'GETGO',
  'DUANE READE',
  'PICK N SAVE',
  'FOODTOWN',
  'FOODLAND',
  'ROYAL FARMS',
  'FOODMAXX SUPERMARKETS',
  'VALERO ENERGY',
  'SUPERIOR GROCERS',
  'CASH SAVER',
  'SUPER FOODS',
  'DUNKIN DONUTS',
  'SHOPPERS FOOD PHARMACY',
  'C TOWN SUPERMARKETS',
  'BROOKSHIRE GROCERY COMPANY',
  'SHAWS SUPERMARKETS',
  'BRAUMS ICE CREAM AND DAIRY STORES',
  'KEY FOOD',
  'MARIANOS',
  'PRICERITE',
  'SAVE MART',
  'OFFICE DEPOT OFFICEMAX',
  'BRAVO SUPERMARKETS',
  'FAMILY FARE SUPERMARKETS',
  'TACO CABANA',
  'RALEYS SUPERMARKETS',
  'DISCOUNT DRUG MART',
  'SMOOTHIE KING',
  'MARSHALLS',
  'MAPCO MART',
  'INNOUT BURGER',
  'SONIC',
  'EL RANCHO SUPERMERCADO',
  'SPECS WINE SPIRITS AND FINER FOODS',
  'ARBYS',
  'FOOD BASICS',
  'EL SUPER',
  'IHOP',
  'DAIRY QUEEN',
  'HOLIDAY STATION',
  'FAS MART',
  'HARPS FOOD STORE',
  'GAMESTOP',
  'QFC QUALITY FOOD CENTERS',
  'BUCEES',
  'FOODS CO',
  'ESSO',
  'ROUSES MARKETS',
  'SPINX',
  'THE FRESH MARKET',
  'DILLARDS',
  'FAIRWAY MARKET',
  'HARVEYS SUPERMARKET',
  'MARATHON PETROLEUM',
  'CARDENAS MARKETS',
  'NORTHGATE MARKETS',
  'JACK IN THE BOX',
  'POPEYES LOUISIANA KITCHEN',
  'ACADEMY SPORTS OUTDOORS',
  'CHEESECAKE FACTORY',
  'CVSCOM',
  'OLLIES BARGAIN OUTLET',
  'NAVY EXCHANGE',
  'CITY MARKET',
  'KRYSTAL',
  'RANDALLS FOOD MARKETS',
  'CHICKFILACOM',
  'BEST BUY',
  'OREILLY AUTO PARTS',
  'STRACK VAN TIL',
  'H MART',
  'FOOD BAZAAR SUPERMARKET',
  'FOOD KING',
  'ROSS DRESS FOR LESS',
  'TROPICAL SMOOTHIE CAFE',
  'MARTINS FOOD MARKETS',
  'LUCKY SUPERMARKETS',
  'SHOP N SAVE',
  'UNITED SUPERMARKETS',
  'ADVANCE AUTO PARTS',
  'AUTOZONE',
  'CORNER STORE',
  'FRESH THYME',
  'COUNTY MARKET',
  'CUMBERLAND FARMS',
  'BIG Y',
  'CHRISTMAS TREE SHOPS',
  'VALLARTA SUPERMARKETS',
  'TARGETCOM',
  'JAY C',
  'VILLAGE PANTRY',
  'MARKET STREET',
  'SHOE SHOW',
  'THORNTONS',
  'HOT TOPIC',
  'RAISING CANES',
  'SUBWAY',
  'ASSOCIATED SUPERMARKETS',
  'HARDEES',
  'BASHAS SUPERMARKETS',
  'TONYS FINER FOODS',
  'PHILLIPS',
  'PETES FRESH MARKET',
  'KUM GO',
  'BATH BODY WORKS',
  'UNITED PACIFIC',
  'TRACTOR SUPPLY',
  'STARBUCKS',
  'FINE FARE SUPERMARKET',
  'LOWES FOODS',
  'BAKERS SUPERMARKETS',
  'FESTIVAL FOODS',
  'BURLINGTON COAT FACTORY',
  'FOOD TOWN',
  'COMPARE FOODS',
  'ACME FRESH MARKET',
  'MAVERIK',
  'HUDSON GROUP',
  'DAVES MARKETS',
  'TACO JOHNS',
  'SHELL OIL',
  'ZAXBYS',
  'NATURAL GROCERS',
  'FAZOLIS',
  'FOOD GIANT',
  'AMAZONCOM',
  'KMART',
  'MARKET DISTRICT',
  'THE UPS STORE',
  'AMERICAN DELI',
  'BROOKSHIRE BROTHERS',
  'RANCH MARKET',
  'THE VITAMIN SHOPPE',
  'LOAF N JUG',
  'TRAVELCENTERS OF AMERICA',
  'TRUE VALUE',
  'PET SUPERMARKET',
  'LITTLE CAESARS',
  'FOOD UNIVERSE MARKETPLACE',
  'SEAFOOD CITY',
  'FAMOUS FOOTWEAR',
  'LOWES MARKET',
  'GULF',
  'SUPER SAVER',
  'GOLDEN CHICK',
  'FAREWAY',
  'CASH WISE',
  'SAMSCLUBCOM',
  'APLUS',
  'MI TIENDA',
  'HIBBETT SPORTS',
  'CINNABON',
  'FOOD OUTLET',
  'CHICKFILA',
  'NO RETAILER',
  'SARKU JAPAN',
  'HOMEGOODS',
  'OLD NAVY',
  'KFC',
  'WIENERSCHNITZEL',
  'CRACKER BARREL',
  'TURKEY HILL MINIT MARKET',
  'SALLY BEAUTY SUPPLY',
  'HEINENS GROCERY STORES',
  'CARTERS',
  'GABES',
  'BOJANGLES',
  'HOMELAND',
  'WHITE CASTLE',
  'LA MICHOACANA MEAT MARKET',
  'GENERAL',
  'MILLS FLEET FARM',
  'FRESCO Y MAS',
  'GOMART',
  'AT HOME',
  'BARGAIN HUNT',
  'STEWARTS SHOPS',
  'VALERO',
  'PRICE LESS FOODS',
  'PRICE CUTTER',
  'SUPERLO FOODS',
  'SCHLOTZSKYS',
  'JOE VS',
  'DENNYS',
  'CHURCHS CHICKEN',
  'SPARKLE',
  'DSW DESIGNER SHOE WAREHOUSE',
  'JUNGLE JIMS EASTGATE',
  'JOURNEYS',
  'RULER FOODS',
  'QUICKCHEK',
  'REAL CANADIAN SUPERSTORE',
  'CARLS JR',
  'UNITED DAIRY FARMERS',
  'DOLLAR DOLLAR',
  'BUEHLERS FRESH FOODS',
  'COUNTRY MART',
  'PRESIDENTE SUPERMARKETS',
  'BOXLUNCH',
  'DAVES MARKETPLACE',
  'CHARLEYS',
  'CHICKEN EXPRESS',
  'NORDSTROM',
  'CORNER MARKET',
  'CENTRAL MARKET',
  'POLLO TROPICAL',
  'DIRT CHEAP',
  'METRO MARKET',
  'CREST FOODS',
  'TWIN LIQUORS',
  'DANS SUPERMARKET',
  'THE FRESH GROCER',
  'MARKETPLACE FOOD DRUG',
  'CARRS',
  'BARTELL DRUGS',
  'CLAIRES WORLDWIDE',
  'MCALISTERS DELI',
  'CERMAK FRESH MARKET',
  'TIM HORTONS',
  'SUPER ONE FOODS',
  'FYE FOR YOUR ENTERTAINMENT',
  'WESTERN BEEF',
  'LUNDS BYERLYS',
  'LONGS DRUGS',
  'LOWES',
  'WSS',
  'SAVERS',
  'KING KULLEN',
  'CONOCOPHILLIPS',
  'BEVMO',
  'UBER',
  'GODFATHERS PIZZA',
  'CHEDDARS SCRATCH KITCHEN',
  'STAR MARKET',
  'HARMONS',
  'MARKET',
  'REGAL CINEMAS',
  'YOKES FRESH MARKET',
  'WINGSTOP',
  'PANDA EXPRESS',
  'CHIPOTLE',
  'NORTHERN TOOL EQUIPMENT',
  'PUBLIX',
  'RACK ROOM SHOES',
  'HAGGEN NORTHWEST FRESH',
  'LEES FAMOUS RECIPES',
  'APPLEBEES',
  'GAS N GO',
  'ONCUE',
  'FREDDYS FROZEN CUSTARD STEAKBURGERS',
  'DIERBERGS MARKETS',
  'RALPH LAUREN',
  'SINCLAIR OIL',
  'FARMER BOYS',
  'HALLMARK CARDS',
  'PIZZA HUT',
  'FRESHCO',
  'TOWN COUNTRY SUPERMARKET',
  'COMMUNITY MARKET',
  'HARMON FACE VALUES',
  'SHOPPERS VALUE',
  'FOREVER',
  'BUY BUY BABY',
  'JCPENNEY',
  'JONS INTERNATIONAL MARKETPLACE',
  'SAFEWAY PHARMACY',
  'EZ MART',
  'LENNYS SUB SHOP',
  'BEL AIR',
  'DOMINOS PIZZA',
  'BROOKSHIRES PHARMACY',
  'OUR LOVE IS',
  'TURKEY HILL',
  'CINEMARK THEATRES',
  'QUIK STOP MARKETS',
  'AARONS',
  'COBORNS',
  'IRVING OIL',
  'WESCO',
  'FOODARAMA',
  'ROAD RANGER',
  'CIRCLE K CONVENIENCE STORE',
  'SCOTCHMAN',
  'DW FRESH MARKET',
  'TORRID',
  'DOOR DASH',
  'JB HAWKS DISCOUNT TOBACCO AND VAPE',
  'NOAHS NEW YORK BAGELS',
  'VGS GROCERY',
  'OUR STORE',
  'REMKE MARKETS',
  'TACO CASA',
  'HARVEYS',
  'SHOPPERS DRUG MART',
  'BOYERS FOOD MARKET',
  'MACS FRESH MARKET',
  'MISSION BBQ',
  'CHECKERS DRIVEIN RESTAURANTS',
  'MAURICES',
  'SBARRO',
  'APPLECOM',
  'BILL MILLER BARBQ',
  'OWENS MARKET',
  'SUPERAMERICA',
  'PETLAND',
  'SKYLINE CHILI',
  'PDQ',
  'AMERICAN EAGLE OUTFITTERS',
  'RURAL KING',
  'MILLERS ALE HOUSE',
  'NAM DAE MUN FARMERS MKT',
  'AW RESTAURANTS',
  'KWIK SHOP',
  'BUTERA MARKET',
  'GERRITYS SUPERMARKET',
  'CHEWYCOM',
  'FRISCHS BIG BOY',
  'HEBCOM',
  'NOB HILL FOODS',
  'REASORS',
  'SPENCERS',
  'THINKGEEK',
  'SHERMS THUNDERBIRD MARKET',
  'LITTLE GIANT FARMERS MARKET',
  'TACO BUENO',
  'LUCKYS MARKET',
  'SEDANOS',
  'FEDEX',
  'JOE VS SMARTSHOP',
  'KINNEY DRUGS',
  'SMASHBURGER',
  'SUNGLASS HUT',
  'KUHNS MARKET',
  'DODGES',
  'LUSH',
  'RIDLEYS FAMILY MARKETS',
  'JOLLIBEE',
  'MET FOODMARKETS',
  'NAM DAE MUN FARMERS MARKET',
  'GERBES',
  'RAYS FOOD PLACE',
  'PATRIOTSTORE',
  'OUTBACK STEAKHOUSE',
  'MACYSCOM',
  'SELLERS BROS',
  'FRESHFIELDS FARM',
  'MAXWAY',
  'VALLI PRODUCE',
  'BEAUTY ISLAND',
  'AMERICAS FOOD BASKET',
  'JACKSONS FOOD STORES',
  'SEARS',
  'MOD PIZZA',
  'BEST MARKET',
  'SCHEELS',
  'COUNTY FAIR FOOD STORE',
  'FLASH FOODS',
  'KWIK FILL',
  'BEAUTY U',
  'FAMILY',
  'MECKLENBURG COUNTY ABC STORE',
  'ISLAND PACIFIC SUPERMARKET',
  'BAHAMA BREEZE',
  'HUDDLE HOUSE',
  'BATHANDBODYWORKSCOM',
  'ROCHE BROS',
  'STEAK N SHAKE',
  'GLORY SUPERMARKET',
  'FOSSIL',
  'EZPAWN',
  'APPLE MARKET',
  'HARDINGS FRIENDLY MARKET',
  'RUE',
  'HUGOS FAMILY MARKETPLACE',
  'COUSINS SUBS',
  'JIMMY JAZZ',
  'INSTACARTPUBLIX',
  'WB LIQUORS WINE',
  'FAIRPLAY FOODS',
  'LOTTE PLAZA',
  'COPPS',
  'EL RIO GRANDE LATIN MARKET',
  'GIANT FOOD',
  'COASTAL FARM AND RANCH',
  'CARIBOU COFFEE',
  'PANERA BREAD',
  'INDIA BAZAAR',
  'BUSHS CHICKEN',
  'GREAT CLIPS',
  'GIANT FOOD PHARMACY',
  'SUN FRESH',
  'MR HERO',
  'DISNEY PARKS',
  'KROGER FUEL',
  'CHERRY VALLEY MARKETPLACE',
  'CASHCARRY SMART FOODSERVICE',
  'BLAINS FARM FLEET',
  'CHUYS',
  'BYRNE DAIRY',
  'ALLSUPS',
  'IDEAL FOOD BASKET',
  'CITY FRESH MARKET',
  'RACEWAY',
  'CEFCO CONVENIENCE STORES',
  'PETPEOPLE',
  'HUCKS',
  'BACKYARD BURGERS',
  'PARKERS',
  'SEABRA FOODS',
  'FARM FRESH',
  'LONG JOHN SILVERS',
  'HOMEDEPOTCOM',
  'BI MART',
  'RALLYS DRIVEIN RESTAURANTS',
  'GATE',
  'AMC THEATRES',
  'SUPER SUPERMARKET',
  'RUBY RED BEAUTY SUPPLIES',
  'T T SUPERMARKET',
  'LEE LEE INTERNATIONAL SUPERMARKET',
  'RED WHITE FOODS',
  'GOOD TIMES BURGERS AND FROZEN CUSTARD',
  'MAIN EVENT ENTERTAINMENT',
  'ROOSTERS WINGS',
  'GREAT AMERICAN COOKIES',
  'ROWES SUPERMARKET IGA',
  'CALGARY COOP',
  'ULTACOM',
  'HAIR TOWN',
  'CVS PHARMACY',
  'JUL',
  'HINABOR SUPERMARKET',
  'BRISTOL FARMS',
  'GOODWILL INDUSTRIES',
  'SUPER KING MARKETS',
  'RIESBECKS',
  'GRANTS SUPERMARKET',
  'FEEL BEAUTY SUPPLY',
  'WOODS SUPERMARKET',
  'REBEL OIL',
  'JENNY BEAUTY SUPPLY',
  'SHIPT',
  'VA ABC',
  'SHORE STOP',
  'HARVEST FOODS',
  'BEAUTY DEPOT',
  'TEXACO',
  'WAREHOUSE MARKET',
  'VILLAGE INN',
  'HONEYBAKED HAM',
  'SMOKER FRIENDLY',
  'MIAMI SUBS',
  'MARTINS SUPER MARKETS',
  'SUPER DOLLAR',
  'OCEAN STATE JOB LOT',
  'JEWELOSCO PHARMACY',
  'TEXAS ROADHOUSE',
  'SALSARITAS FRESH CANTINA',
  'FOOD COUNTRY USA',
  'BEAUTY EMPIRE',
  'RANCHO SAN MIGUEL MARKETS',
  'SHOE CARNIVAL',
  'SHERWINWILLIAMS',
  'UNCLE GIUSEPPES MARKETPLACE',
  'HUNGRY HOWIES PIZZA SUBS',
  'GOLD STAR CHILI',
  'EARTHBOUND TRADING COMPANY',
  'GELSONS',
  'SPROUTS',
  'CHIEF SUPERMARKET',
  'INSTACARTFOOD LION',
  'BURKES OUTLET',
  'EL POLLO LOCO',
  'FLOWERS BAKING CO',
  'INSTACARTALDI',
  'WALMART GROCERY',
  'PAVILIONS',
  'SCOTT M A CORPORATION MCDONALDS',
  'SURF STYLE INC',
  'NAVARRO DISCOUNT PHARMACIES',
  'PET VALU',
  'JERSEY MIKES',
  'SHUN FAT SUPERMARKET',
  'CHICKEN SALAD CHICK',
  'BIG SPORTING GOODS',
  'MARCOS PIZZA',
  'ROWES SUPERMARKET',
  'HOLLISTER',
  'TACO TIME',
  'CAPTAIN DS SEAFOOD RESTAURANT',
  'JACKS FAMILY RESTAURANTS',
  'SAVON PHARMACY',
  'QUALITY FOODS',
  'ROSAUERS',
  'DISNEY STORE',
  'MITFINE CAR WASH',
  'ALBERTOS MEXICAN FOOD',
  'NEEDLERS FRESH MARKET',
  'KG FASHION SUPERSTORE',
  'SHOE PALACE',
  'GLOBAL FOOD',
  'GOOD NEIGHBOR PHARMACY',
  'SHOP SAVE MARKET',
  'BEAUTY SUPPLY WAREHOUSE',
  'PETSENSE',
  'FRYS FOOD AND DRUG FUEL',
  'RA SUSHI',
  'PARTYTIME PACKAGE STORE',
  'LA BONITA SUPERMARKETS',
  'BIG R',
  'BOX LUNCH',
  'CLAIRES',
  'ECONO FOODS',
  'BUFFALO WILD WINGS',
  'PILOT CONVENIENCE STORE',
  'MYXED UP CREATIONS',
  'JIM N NICKS BARBQ',
  'AKIRA',
  'WILD BILLS TOBACCO',
  'FOOD DYNASTY',
  'HOME WINE LIQUORS',
  'SHORT STOP',
  'CHAPEL BEAUTY SUPPLY',
  'SPRING MARKET',
  'MITSUWA MARKETPLACE',
  'DEL TACO',
  'BOSCOVS',
  'MARTINS FOOD',
  'EL TORITO',
  'EAST RIDGE EYE CENTER DOZIER CAREY MD JAMES RICHMOND MD',
  'WINCHELLS DONUT HOUSE',
  'FAMILY FARE SUPERMARKET',
  'GAP',
  'SONNYS BBQ',
  'ROSAS CAFE',
  'NINO SALVAGGIO',
  'CAPRIOTTIS SANDWICH SHOP',
  'MESON SANDWICHES',
  'GATE FOOD POST',
  'CAFE RIO',
  'SEPHORA',
  'BERKOTS SUPER FOODS',
  'PNCBANK',
  'FRESH COUNTY MARKET',
  'STEVE MADDEN',
  'EARTH FARE',
  'TIMES SUPERMARKET',
  'ATLANTIC SUPERSTORE',
  'SALEMS GYROS MORE',
  'AUBUCHON HARDWARE',
  'CHUCK E CHEESE',
  'HOLIDAY MARKET',
  'CERMAK PRODUCE',
  'STEW LEONARDS',
  'EL AHORRO SUPERMARKET',
  'CHARLEYS PHILLY STEAKS',
  'DEL SOL MARKET',
  'MARKET BASKET',
  'BREAK TIME',
  'ENMARKET',
  'LOLLI AND POPS',
  'WAYFIELD FOODS INC',
  'KOWALSKIS MARKETS',
  'ZAGARAS MARKETPLACE',
  'UNIVERSAL',
  'MODELLS SPORTING GOODS',
  'FILIBERTOS MEXICAN FOOD',
  'ELEGANT BEAUTY SUPPLIES',
  'UNDER ARMOUR',
  'OASIS LIQUOR',
  'PAPPAS RESTAURANTS',
  'AHARTS MARKET',
  'IKEA',
  'BA SWEETIE CANDY COMPANY',
  'BUCKLE',
  'BRUSTERS ICE CREAM',
  'EBAYCOM',
  'RN MARKET',
  'AMERICAS BEST WINGS',
  'KICKSUSA',
  'RED WHITE BLUE THRIFT STORE',
  'MCX CORE BRANDS CORPS VALUE',
  'COST PLUS WORLD MARKET',
  'HARVEST MARKET',
  'THE BUFFALO SPOT',
  'SHAKE SHACK',
  'PDQ FOOD STORES',
  'BINNYS BEVERAGE DEPOT',
  'BIJOU BEAUTY HAIR SUPPLY',
  'BIG FOOD STORES',
  'JIMMY JOHNS',
  'NOTHING BUNDT CAKES',
  'PIADA ITALIAN STREET FOOD',
  'SPECS WINES SPIRITS FINER FOODS',
  'RUTHS CHRIS STEAK HOUSE',
  'FRESH FOODS IGA',
  'LIQUOR BARN',
  'AMIGOS',
  'NEW GRAND MART',
  'RUSSS MARKET',
  'MCX MARINE MART',
  'HM',
  'LOCAL MARKET FOODS',
  'VANS',
  'TOBACCO SUPERSTORE',
  'CARTERS SUPERMARKET',
  'MORTON WILLIAMS SUPERMARKETS',
  'ALASKA COMMERCIAL',
  'NAPA AUTO PARTS',
  'BASKIN ROBBINS',
  'HORNBACHERS',
  'THE FLAME BROILER',
  'LAR GENERAL',
  'COACH',
  'BEAUTY WORLD',
  'GOOGLECOM',
  'BOMGAARS',
  'RAINBOW SHOPS',
  'GROCERY ADVANTAGE',
  'POLLO CAMPERO',
  'JUNIORS SUPERMARKET',
  'EL GUERO',
  'GOPUFFCOM',
  'LONGHORN STEAKHOUSE',
  'LYNNS DAKOTAMART',
  'THREE BEARS ALASKA',
  'FRUITFUL YIELD',
  'JOONG BOO MARKET',
  'TWIN PEAKS RESTAURANT',
  'BURLINGTON',
  'MOMS ORGANIC MARKET',
  'MCKAYS MARKET',
  'MILLER SONS SUPERMARKET',
  'LA AZTECA MEAT MARKET',
  'PLANET SMOOTHIE',
  'ANGELO CAPUTOS FRESH MARKETS',
  'ZION MARKET',
  'BMV',
  'JUBILEE FOODS',
  'SUPREME HAIR BEAUTY',
  'LAX WINE SPIRITS',
  'JOANNCOM',
  'NAM DAE MUN',
  'ZAXBYS CHICKEN FINGERS BUFFALO WINGS',
  'NORTH POINT LIQUOR BAR',
  'BUFORD HIGHWAY FARMERS MARKET',
  'SENTRY FOODS',
  'BURTONS FOOD STORE',
  'PRIMARK',
  'PROVIGO',
  'CITI TRENDS',
  'MELLOW MUSHROOM',
  'AMBEST',
  'CROWN LIQUORS',
  'GENGHIS GRILL',
  'SUPER FRESH',
  'PALS SUDDEN SERVICE',
  'SOLID GOLD BEAUTY SUPPLY',
  'ND AVE THRIFT SUPERSTORE',
  'BIG RED',
  'LA CASA DEL PUEBLO INC',
  'AQU MARKET',
  'GOOD FORTUNE SUPERMARKET',
  'BREAUX MART',
  'EL HERRADERO SUPERMARKET',
  'WALT CHURCHILLS MARKET',
  'RIVERSIDE MARKET',
  'TOTAL WINE SPIRITS MORE',
  'SHOE SENSATION',
  'ANNS BEAUTY',
  'RENTACENTER',
  'HUCKS FOOD STORE',
  'PRICE LESS IGA',
  'NEW WING YUAN MARKET',
  'COUNTRY FRESH FARM MARKET',
  'NEIMAN MARCUS',
  'MY BEAUTY SUPPLY',
  'CHINA WOK',
  'SUPERFRESH',
  'CITY MILL',
  'VENUS BEAUTY PLAZA',
  'CH MARTIN',
  'LOWES FIESTA FOODS',
  'SUPERMERCADOS EL BODEGON',
  'NE NE KIDS',
  'BUDWEYS MARKET',
  'BBS TEXORLEANS',
  'EXPRESS',
  'TOPPERS PIZZA',
  'FRANCESCAS',
  'FOOD OULET',
  'SAARS',
  'FASTRAC',
  'DAVE BUSTERS',
  'BSW BEAUTY',
  'COX CASH SAVER TULSA',
  'CRAIGS GENERA',
  'CONOCO',
  'ONCE UPON A CHILD',
  'THOMAS DO IT CENTER',
  'RUTTERS',
  'FIELD STREAM',
  'CARR HARDWARE',
  'QUIZNOS',
  'RUTTERS FARM STORES',
  'GRANDYS',
  'BIG B GROCERY',
  'FOUR STARS DRIVE IN',
  'BIBIBOP ASIAN GRILL',
  'A SUPERMARKET',
  'HOOTERS',
  'CHAVEZ SUPERMARKET',
  'MORSE FRESH MARKET',
  'MOTO MART',
  'PENN STATION',
  'KINGS FOOD MARKETS',
  'ZARA',
  'LENWICH',
  'LUCKYS SUPERMARKET',
  'BELLSTORES',
  'LESLIE J OLLAR DO PC',
  'SPIRITS DEPOT',
  'MORRISON HEALTHCARE',
  'BROWARD MEAT FISH',
  'SWEETBERRY FRESH MARKET',
  'DAVIDS BRIDAL',
  'SHAKERS IGA MARKETPLACE',
  'PANTRY FOOD MART',
  'LA NUEVA ISLA SUPERMARKET',
  'PALMETTO MOON',
  'SULLIVANS FOODS',
  'BEAUTY FULL BEAUTY SUPPLY STORE',
  'UNITED SUPERMARKET OF OKLAHOMA',
  'PAY LESS SUPER MARKETS FUEL',
  'VALUE VILLAGE',
  'BEALLS FLORIDA',
  'SPARKLE MARKET',
  'GROCERY DEPOT',
  'BIG BEAR SUPERMARKET',
  'EXPRESS MART',
  'MACEYS TOOELE',
  'NIJIYA MARKET',
  'ARMANDOS MEAT MARKET',
  'NICKS GYROS SEAFOOD',
  'RUDYS TEXAS BARBQ',
  'BUFFALO WINGS RINGS',
  'APPLES MARKET',
  'BLACK DIAMOND',
  'ATWOODS RANCH HOME',
  'STRACK AND VAN TIL',
  'ROBERTOS TACO SHOP',
  'GOODY GOODY LIQUOR',
  'MGM LIQUOR WAREHOUSE',
  'COUSINS FRESH MARKET',
  'DOROTHY LANE MARKET',
  'ALDO',
  'OSCO DRUG',
  'LOS ALTOS RANCH MARKET',
  'FANCY FRUIT AND PRODUCE',
  'PLANET FITNESS',
  'SUPERIOR DISCOUNT LIQUOR',
  'KENTUCKY KINGDOM AND HURRICANE BAY',
  'VINTAGE STOCK',
  'CALLIOPES SEAFOOD POBOYS',
  'HERITAGES DAIRY STORES',
  'RUNNINGS',
  'BURGER BOY',
  'HOMEBUYS FLORENCE',
  'CVS FAMILY FOODS',
  'STEINS MARKET',
  'FOOSACKLYS',
  'LEE LEE INTERNATIONAL SUPERMARKETS',
  'PETSMARTCOM',
  'THE GREEN SOLUTION RECREATIONAL MARIJUANA DISPENSARY',
  'ERS',
  'SKECHERS',
  'TACO MAC',
  'OCEAN PRIME',
  'FRIENDLY EXPRESS',
  'MILLERS DUTCH HAUS FURNITURE',
  'SPORTSMANS WAREHOUSE',
  'STADIUM LIQUOR STORE',
  'BIG JOY WIGS BEAUTY SUPPLIES JEWELRY',
  'SMARTSTYLE FAMILY HAIR SALONS',
  'HAAGEN DAZS',
  'COUNTRY CUPBOARD',
  'MIGHTY TACO',
  'DOWNSTAIRS',
  'CITY SUPERMARKET',
  'ROSES EXPRESS',
  'YOUNGS FAMILY MARKET',
  'UWAJIMAYA',
  'SHOPPERS',
  'METRO',
  'BEAUTY EXCHANGE BEAUTY SUPPLY',
  'THE LIVING ROOM WINE CAF LOUNGE',
  'CYPRESS BEAUTY SUPPLY',
  'FOOD COURT',
  'ANIMAL CITY',
  'MOVIE TRADING COMPANY',
  'CARLIE CS IGA',
  'DEL POINT FOOD CENTER',
  'INSTACARTSPROUTS FARMERS MARKET',
  'GARDEN FARMS MARKET CHULA VISTA',
  'GRAND MART',
  'YESWAY',
  'FEDEX OFFICE PRINT SHIP CENTER',
  'GORDON FOOD SERVICE',
  'SPAINS SUPERMARKET',
  'ISLAND PACIFIC SEAFOOD MARKET',
  'PIGGLY WIGGLY MIDWEST',
  'REI',
  'TUESDAY MORNING',
  'LAS PALAPAS',
  'KETTERING LIQUORS CHEESE SHOPPE',
  'RIVERSIDE MARKETPLACE',
  'INSTACARTWINNDIXIE',
  'HARVEYS SUPERMARKET PHARMACY',
  'LA MONTANA',
  'SMOKIN JOES TOBACCO SHOP INC',
  'LA SUPERIOR MERCADO CARNICERIA',
  'UNITED GROCERY OUTLET',
  'FAMILY FARE FUEL',
  'PAYLESS SHOESOURCE',
  'ROSAUERS SUPERMARKETS',
  'ANNAPOLIS WINE SPIRITS',
  'COACH OUTLET STORE',
  'CHEBA HUT TOASTED SUBS',
  'PJP MARKETPLACE',
  'LINCOLN MILITARY HOUSING QUANTICO',
  'HAIR QUEEN BEAUTY',
  'PIC N SAV',
  'VICTORIAS SECRET',
  'SAVEALOT',
  'ELRODS COST PLUS SUPERMARKET',
  'HOMETOWN MARKET OF MORGAN',
  'TIGER MARKET IGA',
  'DAT MOI MARKET',
  'SUPER DOLLAR DISCOUNT FOODS',
  'TOM THUMB PHARMACY',
  'FRESH VALUE',
  'POPS PRIDE OF PHILLY STEAKS',
  'MOTHERS NUTRITIONAL CENTERS',
  'PASTA VITA',
  'FAMILY EXPRESS',
  'NANDOS PERIPERI',
  'WINE LIQUOR DEPOT',
  'MISTER CAR WASH',
  'HUCKS CONVENIENT FOOD STORE',
  'FOOD TOWN VETERANS MEMORIAL',
  'CC BEAUTY SUPPLY',
  'BOYERS FOOD MARKET LANSFORD',
  'FEEL BEAUTY',
  'SMITHS',
  'GOODWIL',
  'WOODWARD CORNER MARKET',
  'KJS MARKET IGA',
  'DOL',
  'QUICKTAG',
  'LUBYS',
  'KIRKLANDS',
  'CAP MARKETS',
  'JOHNSONS GIANT FOOD',
  'CHINA EXPRESS',
  'PAPPADEAUX SEAFOOD KITCHEN',
  'K BEAUTY SUPPLY',
  'INSTACARTKROGER',
  'PARIS BAGUETTE',
  'SUPER VALU',
  'DELTA SONIC',
  'PIONEER SUPERMARKET',
  'DISCOUNT FASHION WAREHOUSE',
  'KRISPY KREME DOUGHNUTS',
  'VERA BRADLEY',
  'MISAKO',
  'SMITH S B SON RED WHITE',
  'VICENTES TROPICAL GROCERY',
  'THREE BEARS GENERAL STORE',
  'EL POLLO REGIO',
  'TUBBYS GRILLED SUBMARINES',
  'MAIN EVENT TEMPE',
  'BUY LOW SUPER MARKET',
  'QUICKWAY JAPANESE HIBACHI',
  'PEP BOYS',
  'ROGER WILCO',
  'ANNAPOLIS SEAFOOD MARKET',
  'ROADYS',
  'NEW BRIDGE FARMERS MARKET',
  'BUDDYS IGA',
  'PACIFIC OCEAN MARKETPLACE',
  'KRESTON WINE SPIRITS',
  'HEN HOUSE',
  'DALLAS BBQ',
  'MOSER DISCOUNT FOODS',
  'LEES DISCOUNT LIQUOR',
  'FASCINATIONS',
  'COMPARE FOOD',
  'PLATOS CLOSET',
  'AEROPOSTALE',
  'UPTOWN BEAUTY SUPPLY',
  'ELEGANT',
  'EXTRA SUPERMARKET',
  'FOX HOUND',
  'BOISE GEAR COLLECTIVE',
  'FASCAR DRIVE THRU LLC',
  'COTTON PATCH CAFE',
  'MACAYOS MEXICAN TABLE',
  'AUNTIE ANNES',
  'ABC',
  'P TERRYS BURGER STAND',
  'HAYS GROCERY',
  'BRAVO SUPERMARKET',
  'ALAMO LIQUOR',
  'FIVE GUYS',
  'SUPERFRESH FOOD WORLD',
  'TWIN CITY SUPERMARKET',
  'WILLIAMS CHICKEN',
  'COSMO PROF',
  'BRAHMAN LOUNGE LIQUORS',
  'VISIONWORKS',
  'CASH SAVER FOOD OUTLET',
  'BELLS FOOD STORES',
  'CLAIBORNE HILL',
  'COUNTRY MARKET',
  'WINE BARREL',
  'BRANDED BARREL',
  'TRUCCHIS SUPERMARKETS',
  'INFINITYS END',
  'FINE FARE',
  'DELAWARE NORTH',
  'HEALTH FOODS UNLIMITED',
  'DDS DISCOUNTS',
  'YANKEE CANDLE',
  'MARINE CORPS EXCHANGE',
  'HYVEE FUEL',
  'FOOD RITE',
  'GIANTEAGLE',
  'AMELIAS GROCERY OUTLET',
  'WAIANAE STORE',
  'OLDNAVYCOM',
  'ELEGANT BEAUTY SUPPLY',
  'PRIMO HOAGIES',
  'FINE WINE GOOD SPIRITS',
  'THE MARKET IN THE SQUARE',
  'THE DUTCH POT PEM PINES',
  'EDEN FRESH MARKET',
  'FLIPPERS PIZZERIA',
  'ABC FINE WINE SPIRITS',
  'REGULAR CHECK',
  'PRUETT S FOOD',
  'ORANGE BEAUTY',
  'MERCER MARKET',
  'WINNERS',
  'CD LIQUORS',
  'NAVARRO DISCOUNT PHARMACY',
  'AMYS DRIVE THRU',
  'VERIZON',
  'JIMBOYS TACOS',
  'UNCLE BILLS PET CENTERS',
  'TOPGOLF',
  'VENUS BEAUTY SUPPLY',
  'INTERNATIONAL',
  'THINK GEEK',
  'TOTAL BEAUTY SUPPLY',
  'BIG CITY SUPERMARKET',
  'GROCERY STORE',
  'HILLTOP RED APPLE MARKET',
  'TOWER BEER WINE SPIRITS',
  'RHODES STOP',
  'NORTH SHORE FARMS',
  'FIESTA MARKET',
  'HAIR DEPOT PLUS',
  'REDDING',
  'FRENCH CREEK MCX MINI MART',
  'VF OUTLET',
  'BAY CITIES ITALIAN DELI',
  'TACO VILLA',
  'HARTER HOUSE',
  'MARTINDALES NATURAL MARKET',
  'BARROW STREET LIQUOR STORE',
  'CITY GEAR',
  'STIMSONS BIG STAR',
  'KARNS QUALITY FOODS',
  'KWIK STAR',
  'MANGO CANNABIS',
  'MURPHY EXPRESS',
  'HOMEBUYS',
  'MELBAS',
  'BOWLERO',
  'PEPES',
  'COACH FACTORY STORE',
  'INSTACARTHEB',
  'LINS CEDAR CITY',
  'BEAUTY MASTER',
  'FIRST NATIONAL BANK',
  'FINISH LINE',
  'TOBACCO CONNECTION',
  'CHALET PARTY SHOPPE',
  'WAREMART BY WINCO',
  'WORLD THRIFT',
  'BEDBATHANDBEYONDCOM',
  'ZERO DEGREES',
  'NICE N EASY GROCERY SHOPPES',
  'WSS NOW OPEN',
  'FUZZYS TACO',
  'ARLANS',
  'VALLEY FARM MARKET',
  'AL',
  'WALMART NEIGHBORHOOD MARKET',
  'MCDADES MARKET',
  'SHIPLEY DONUTS',
  'MCKEEVERS MARKET',
  'MERCADO',
  'TABACCO SUPERSTORE',
  'CUEVAS MEAT MARKET',
  'REDI MART',
  'EUROFRESH MARKET',
  'SHORE STOP INC',
  'RIO RANCH MARKET',
  'LADYS NAIL SALON',
  'LEROY PHARMACY',
  'MONEYTREE',
  'SUPERFOOD MARKETPLACE ELIZABETH NJ',
  'FRESCO SUPERMARKET',
  'KLEINS SUPERMARKET',
  'PET GOODS',
  'SHOP FAIR SUPERMARKET',
  'NUGGET MARKET',
  'PENN LIQUORS',
  'LA MART',
  'P C FRESH',
  'GIUNTAS MEAT FARMS',
  'JASONS DELI',
  'ANOTHER BROKEN EGG CAFE',
  'CONCORD PET FOODS AND SUPPLIES',
  'SUNSHINE FOODS',
  'J T SUPER MARKET',
  'URBAN BEAUTIQUE',
  'HEB PHARMACY',
  'SAPP BROS',
  'DTLR',
  'VILLAGE MARKET',
  'MILLERS ALE HOUSE WINTER GARDEN',
  'BUSCHS FRESH FOOD MARKET',
  'ANF GYROS GRILL',
  'MARKETPLACE OF KINGSLAND',
  'JOHNNY ROCKETS',
  'BOX COST PLUS',
  'ISLAND WING COMPANY',
  'ADMIRAL',
  'BROTHERS SUPERMARKET',
  'BALL FOODS',
  'BENIHANA',
  'ROZIERS FOOD CENTER',
  'BIG SAVER FOODS',
  'BUONA RESTAURANT CATERING',
  'PRICE CHOICE FOODMARKET',
  'METRO DINER',
  'WOHLNERS NEIGHBORHOOD GROCERY DELI',
  'WAYFIELD FOODS',
  'HUMBLE BEAUTY SUPPLY',
  'RESTAURANT DESIGN CONCEPTS',
  'DOGS R US GRILL',
  'PETCOCOM',
  'LESLIES POOL SUPPLIES SERVICE REPAIR',
  'MR ALANS',
  'SUPERMERCADO NUESTRA FAMILIA',
  'TERRIBLE HERBST',
  'MOTEL',
  'COUNTRY BUTCHER',
  'BANANA REPUBLIC',
  'LOVERS',
  'GREERS CASHSAVER',
  'PAK N SAVE',
  'THE LOVE STORE',
  'YUM YUM DONUTS',
  'FIREHOUSE SUBS',
  'CAPITOL PETRO MART',
  'TOBACCO OUTLET',
  'FROOGELS',
  'CHINA HOUSE',
  'TAQUERIA JALISCO',
  'MINI MART SPECIALTY MEATS',
  'TAX FREE LIQUORS',
  'COUNTY MARKET EXPRESS',
  'DA CATCH FISH CHICKEN',
  'FRESH MART',
  'DICKEY BUB TRUE VALUE',
  'BACHMANS FRIDLEY',
  'DICKS WINGS AND GRILL',
  'STOP BEVERAGE',
  'DOTS BELLBROOK MARKET',
  'HORNINGS MARKET OF MYERSTOWN',
  'GOEBBERTS FARM GARDEN CENTER',
  'QUIX FOOD STORE',
  'ADRIENS DISCOUNT SUPERMARKET',
  'DEATONS BUILDING HOME CENTER',
  'PRIMARK CHICAGO',
  'PLAZA SUPER JET',
  'BENSONS PET CENTER JOHNSTOWN',
  'ANT LOGISTICS MAILBOX CENTER',
  'ALBANESE CONFECTIONERY GROUP',
  'WILL COUNTY BREWING COMPANY',
  'RIB MOUNTAIN TOBACCO LIQUOR',
  'VILLA FRESH ITALIAN KITCHEN',
  'TULLYS GOOD TIMES ROCHESTER',
  'SUNBEAM BREADSCHOTTS BAKERY',
  'ONE STOP LIQUOR',
  'ONE POTATO TIME',
  'LITTLE FIVE POINTS PACKAGE STORE',
  'ALBASHA',
  'OMALLEYS MARKET',
  'QUICK STOP FOOD AND LIQUORS',
  'NUMERO UNO MRKT',
  'NEW STAR BEAUTY',
  'NATURES HARVEST',
  'MOTOMART CONVENIENCE STORES',
  'MILLERS NEIGHBORHOOD MARKET',
  'MCH AUXILIARY TAPESTRY GIFT',
  'ELEMENTS MASSAGE CENTENNIAL',
  'ISLANDS FINE BURGERS DRINKS',
  'INSTACARTPETES FRESH MARKET',
  'MISHA FOOD MART',
  'MILLERS HOAGIES',
  'DUNKIN DONUTS BASKIN ROBBIN',
  'DEPARTMENT OF PUBLIC SAFETY',
  'ALADDINS EATERY NORTH HILLS',
  'CUMBERLAND COUNTY ABC STORE',
  'CROUCH HARDWARE FARM SUPPLY',
  'BRYANT TRUE VALUE OF CORBIN',
  'SUPER A FOODS',
  'EL AGUILA SUPERMARKET',
  'BARCENAS MEXICAN RESTARAUNT',
  'ADIDAS FACTORY OUTLET STORE',
  'SMOKE UNLIMITED SMOKE SHOP',
  'ZIPPYS CAR WASHNACOGDOCHES',
  'MARANDINO FOODS',
  'LUEKENS LIQUORS',
  'THE HONEYBAKED HAM COMPANY',
  'TAQUERIA EL MEXICANO GRILL',
  'K J MELROSE PARK CONVENIENCE STORE',
  'OLIVERS MARKET STONY POINT',
  'OK MINI MARKET CORPORATION',
  'SURF THRU EXPRESS CAR WASH',
  'SPORTS FERVER VALLEY PLAZA',
  'SHOOT POINT BLANK FLORENCE',
  'LEES SANDWICHES',
  'LA NORIA MARKET',
  'PET WORLD WAREHOUSE OUTLET',
  'KING RIBS BARBQ',
  'NEW HANOVER CNTY ABC STORE',
  'KRAUSZERS FOOD AND LIQUORS',
  'NEW GENERATION FOOD MARKET',
  'JOES CRAB SHACK',
  'MD ORIENTAL MARKET BRANDON',
  'MARYS CUBAN KITCHEN BAKERY',
  'MADISON BEVERAGE WAREHOUSE',
  'J BUFFALO WINGS',
  'FRESH THYME FARMERS MARKET',
  'IVORYS TAKE OUT',
  'ITALYS BEST III',
  'HOOSIER PARK RACING CASINO',
  'GOODWILL INDUSTRIES CORBIN',
  'GATORS DOCKSIDE WINDERMERE',
  'INSANE SAUSAGES',
  'HONEY LEAF BOBA',
  'EL METATE MARKET',
  'FOOD FAIR LA GRAN MARQUETA',
  'FIREHOUSE DISCOUNT LIQUORS',
  'EL PASO MEXICAN RESTAURANT',
  'DOLLARPLUS DISCOUNT CENTER',
  'HALE AND HEARTY',
  'VINCE JOES GOURMET MARKET',
  'TRIAD MUNICIPAL ABC STORE',
  'COMMUNITY SUPER MARKET INC',
  'H BEAUTY SUPPLY',
  'BUYRITE OF NORTH BRUNSWICK',
  'GRISTEDES FOODS',
  'ALL POSTSEASON SALES FINAL',
  'ACTON MARKET COUNTRY STORE',
  'GILBERTS LIQUOR',
  'TOTAL IMAGE BEAUTY SUPPLY',
  'RONSONS GOLD STAR LIQUORS',
  'THE OLD SPAGHETTI FACTORY',
  'FRIENDLY CORNER',
  'SMITHS FOOD AND DRUG FUEL',
  'SHOPPERS DISCOUNT LIQUORS',
  'FREDS FOOD CLUB',
  'FORTHS FOODFAIR',
  'FREDDIES LIQUORS',
  'FLIGNERS MARKET',
  'REAL DEAL DOLLAR DISCOUNT',
  'FARMACIAS PLAZA',
  'PLEASANT VIEW LIQUOR WINE',
  'PARADISE LIQUOR MINI MART',
  'FAIR CITY FOODS',
  'SUPER MORELOS',
  'INTERNATIONAL FOOD MARKET',
  'MO BETTAHS HAWAILAN STYLE',
  'MAS POR MENOS SUPERMARKET',
  'LA PARRILLA MEXICAN GRILL',
  'NINO SALVAGGIO INTERNATIONAL MARKETPLACE',
  'LA CIENEGA SUPER FOOD INC',
  'GREAT AMERICAN FOOD STORE',
  'FRESH MARKET TAYLORSVILLE',
  'FRESH MARKET JEREMY RANCH',
  'DIY HOME CENTER',
  'DISTRICT TAVERN',
  'DINO FRANCESCOS',
  'IRVING SPECIALTY PHARMACY',
  'CRAVE GOLF CLUB',
  'GREENS PRODUCE AND PLANTS',
  'GREEN SPRINGS MEDICAL LLC',
  'COME AND GET IT',
  'CLOSET CLASSICS',
  'PUBLIX SUPER MARKET AT BERRY FARMS TOWN CENTER',
  'EASTERN PET SUPPLY OUTLET',
  'DESERET INDUSTRIES STORES',
  'CONSOLIDATED ACE HARDWARE',
  'CLARK TRUE VALUE HARDWARE',
  'CEREDO FOODFAIR',
  'CASH WISE FOODS',
  'WITTYS FINE WINE LIQUORS',
  'CARDENAS MARKET',
  'BROOKLYN HEIGHTS PIZZERIA',
  'CAF TU TU TANGO',
  'BSTREET EXPRESS',
  'AMERICAN SHOOTING CENTERS',
  'BROOKS BROTHERS',
  'BONOS PIT BARBQ',
  'THE MONEY PIT DRIVE THRU',
  'WESTS PLAZA COUNTRY MART',
  'BODACIOUS BARBQ',
  'UNIVELSAL ORLANDO RESORT',
  'UNICA LATINO SUPERMARKET',
  'BEST TRUCK STOP',
  'HALF PRICE BOOKS',
  'SAKE JAPANESE RESTAURANT',
  'BEST BUY MARKET',
  'BEEPS FAST FOOD',
  'SUSHI MARTINI',
  'ELLIOTTS PET EMPORIUM',
  'BEAUTY DISCOUNT',
  'SKY ZONE TRAMPOLINE PARK',
  'SEAFOOD CITY SUPERMARKET',
  'BASE RESTAURANT',
  'NORTH COURT BEVERAGE INC',
  'ROYS CARDINAL FOOD STORE',
  'AURORA PHARMACY',
  'RANCHO GRANDE PARKV ILLE',
  'PUBLIX PINES CITY CENTER',
  'AMERICAN SOCIAL',
  'AL PRIME ENERGY',
  'JUNIOR CONVENIENCE STORE',
  'AIRPORT PANINIS',
  'NATIONAL SUPERMARKET IGA',
  'ABQ BIOPARK ZOO',
  'MAJESTIC CLUBHOUSE STORE',
  'MAIN EVENT GRAND PRAIRIE',
  'WINE MERCHANTS',
  'KENNIES MARKET TANEYTOWN',
  'INSTACARTWALMART',
  'WASMO RUN CORP',
  'JERRYS ARTARAMA DELAWARE',
  'USC BOOKSTORES',
  'HAPPY MEXICAN HACK CROSS',
  'GREAT LAKES ACE HARDWARE',
  'EXTREME EXPRESS CAR WASH',
  'RAD SPIRITS',
  'RANCHERITOS',
  'TROPICAL FRESH',
  'GLENWOOD HARDWARE RENTAL',
  'TOUS LES JOURS',
  'GABRIELS SUPERMARKET IGA',
  'TIMMONS MARKET',
  'FOOD KING COST PLUS FOOD',
  'THE ROYAL MENU',
  'TEXAS BEVERAGE',
  'LA BODEGA MARKET',
  'DVDXPRESS SUPER FOODTOWN',
  'COSMO NAIL BEAUTY SUPPLY',
  'CHATEAU WINE AND SPIRITS',
  'EMS ORIGINAL SUB SHOP',
  'SUN MART FOODS',
  'BRANFORD WINE AND LIQUOR',
  'LANES CONOCO LLC',
  'BOSS CHICKEN AND CUSTARD',
  'BARTRAM FINE WINE LIQUOR',
  'STINKER STORES',
  'ALBERTS RED APPLE MARKET',
  'AAFES III CORP SHOPPETTE',
  'SOUTH CAROLINA',
  'SMOKERS CHOICE',
  'STEVES TH STREET MARKET',
  'THIEN THANH SUPERMARKET',
  'THE MARKET AT PARK CITY',
  'SUPERMERCADO EL BODEGON',
  'SUGARLOAF CAR WASH LUBE',
  'SEAMAN ST STOP',
  'PANS',
  'MINGS RESTAURANT',
  'SPEAKEASY LIQUOR MARKET',
  'SANTA FE FOODS',
  'SHIMMERING SEAS JEWELRY',
  'SHARONVILLE LIQUOR WINE',
  'SALISBURY BEAUTY SUPPLY',
  'RED BERRY FARM',
  'RECORD ARCHIVE',
  'QUARTER TO TEN',
  'CHRISTALS',
  'PROJECT RELEAF',
  'P C PLUMBING ELECTRICAL',
  'NYFRIED CHICKEN SEAFOOD',
  'NICKS GYROS AND SEAFOOD',
  'MIDWEST SHOOTING CENTER',
  'MARKETPLACE OF BUSHWICK',
  'BRANDON',
  'PDQ RESTAURANT',
  'LA MEXICANA MEAT MARKET',
  'KIMBARK BEVERAGE SHOPPE',
  'PANCAKE PANTRY',
  'INSTACARTCENTRAL MARKET',
  'HAMPDEN CROSSING LIQUOR',
  'GOODWILL MCKELLIPS MESA',
  'EXTRA JUMBO MARKETPLACE',
  'GET SASSY BEAUTY SUPPLY',
  'FRESH VALUE MARKETPLACE',
  'FOOD MARKET LA CHIQUITA',
  'DON TONOS MEXICAN GRILL',
  'NICKS GYROS II',
  'COSENTINOS APPLE MARKET',
  'PRISCILLA MCCALL',
  'CEDAR SPRINGS TAP HOUSE',
  'NAVY FOOD MART',
  'NATURES PANTRY',
  'NAPS THRIFTWAY',
  'NANAKULI SUPER',
  'DICKSON BROS TRUE VALUE',
  'COTTON PATCH QUICK STOP',
  'CHANDLER VILLAGE CENTER',
  'CASSELBERRY MEAT MARKET',
  'CARNICERIA LOS PASTORES',
  'BROOKLYN HARVEST MARKEY',
  'COSTCOCOM',
  'MACON GA ETCRL',
  'BOONVILLE SAV U MOR IGA',
  'BIG AIR TRAMPOLINE PARK',
  'BELMONT BEVERAGE STORES',
  'BEAUTY MASTER RIVERDALE',
  'LEY COMMISSARY',
  'WINGS OF WINTER GARDEN',
  'ASSOCIATED FRESH MARKET',
  'LEROUX KITCHEN',
  'ACAPULCO TROPICAL Y MAS',
  'WOODYS ROADSIDE BILOXI',
  'KENWOOD MARKET',
  'WINDY CITY PRODUCE INC',
  'JOEYS RED HOTS',
  'SHANES RIB SHACK',
  'JEWEL PHARMACY',
  'VALUE SAVE FOOD CENTER',
  'TOP TOMATO SUPER STORE',
  'HWY OF WALLACE',
  'THRIFTWAY SUPERMARKETS',
  'CENTRAL',
  'SMOKERS EMPORIUM',
  'HOT DOG SHOPPE',
  'THE HIDEAWAY BAR GRILL',
  'SUPERSAVER SUPERMARKET',
  'SUPER QUIK FOOD STORES',
  'SPUNKY MONKEY DAQUIRIS',
  'HAWAIIAN GRILL',
  'SIMONSON STATION STORE',
  'HARMONY GALAXY',
  'RALPHS MARKET GONZALES',
  'SANTA CRUZ BAY COMPANY',
  'RSVP FOOD PARTY OUTLET',
  'GROCERY OUTLET',
  'GOURMET GARAGE',
  'PICCADILLY RESTAURANTS',
  'GIANT PHARMACY',
  'TAQUERIA LORENAS',
  'PARADIES AIRPORT SHOPS',
  'OISHI ASIAN RESTAURANT',
  'G M RESTAURANT',
  'FOXS PIZZA DEN',
  'MONAGHANS LIQUOR STORE',
  'FOOT SOLUTIONS',
  'MACKENTHUNS FINE FOODS',
  'LANGLEY BEAUTY CENTRAL',
  'FIFER ORCHARDS',
  'LAST STOP LIQUOR STORE',
  'LA QUINTA DE LOS REYES',
  'LA MONTANA MEAT MARKET',
  'FRANKS',
  'THE COUNTRY MILE',
  'INTERSTATE COMVENIENCE',
  'I LOVE SUGAR LAS VEGAS',
  'G MART',
  'CAL TACO',
  'GARIBALDIS',
  'TACOS GAVILAN',
  'KRISPY KRUNCHY CHICKEN',
  'KISSIMMEE MEAT PRODUCE',
  'EREWHON MARKET',
  'HOMERS WEST RESTAURANT',
  'DTLRVILLA',
  'HOME HOME CONSIGNMENTS',
  'DULUTH TRADING',
  'GUANAJUATO MEAT MARKET',
  'GREAT WALL SUPERMARKET',
  'DELL RANGE LIQ',
  'DELEVAN CROSBY',
  'TODAY FOOD STORE',
  'FOURAKER FOOD MART INC',
  'FLOWERS BAKING COMPANY',
  'FEDERICOS MEXICAN FOOD',
  'EASY SHOP SUPER MARKET',
  'DULUTH TRADING COMPANY',
  'DISCOUNT BEAUTY OUTLET',
  'CORNER LIQUORS',
  'CALIFORNIA MARKETPLACE',
  'COMMUNITY FOODS MARKET',
  'COOKS PHARMACY',
  'CHILITOS MEXICAN GRILL',
  'CHAMPAGNES SMKT EUNICE',
  'COFFEE BEANERY',
  'BIG VALUE WINE SPIRITS',
  'BALTIMORE WASHINGTON E',
  'CLIFTON MARKET',
  'CAFE RIO MEXICAN GRILL',
  'BUY WISE BEAUTY SUPPLY',
  'CHOP CHOP RICE',
  'BRICK HOUSE TAVERN TAP',
  'BIGELOWS BAR AND GRILL',
  'CENTURY LIQUOR',
  'CENEX ZIP TRIP',
  'VALUE DRUG TRUE VALUE',
  'ATM TRANSACTION RECORD',
  'AMERICAS THRIFT STORES',
  'Z AND Z PACKAGE STORE',
  'WHEATON BEAUTY SUPPLY',
  'BURRIS LIQUORS',
  'BUFF CITY SOAP',
  'ASIAN FOOD MARKET',
  'VALLEY NEWS AND BOOZE',
  'BROWNS CHICKEN',
  'BROULIMS RIGBY',
  'TOWN COUNTRY HARDWARE',
  'THE MILL ON THE RIVER',
  'BLESSED BEAUTY',
  'BITE THE FRUIT',
  'SALTGRASS STEAK HOUSE',
  'BIONIC WAIPAHU',
  'BILLS PET SHOP',
  'BGM AUTOMOTIVE',
  'AMANA LIQUOR',
  'BEVERAGE DEPOT',
  'RAINBOW BEAUTY SUPPLY',
  'CULVERS',
  'FOOD FAIR',
  'BETHEL GROCERY',
  'BELMONT MARKET',
  'SAFEWAY GROCERY STORE',
  'S S FAMILY RESTAURANT',
  'ROBERTS PACKAGE STORE',
  'REBECCAS NATURAL FOOD',
  'RANDALLS WINE SPIRITS',
  'QUALITY BEAUTY SUPPLY',
  'PLAZA AZTECA PLYMOUTH',
  'NEWBERRY FARMS MARKET',
  'PINECREST CONVENIENCE',
  'ACRES CANNABIS',
  'ZIPPYS KAILUA',
  'OREGANOS PIZZA BISTRO',
  'ZELMOS ZIP IN',
  'ZEHRS MARKETS',
  'BAKERS DRIVE',
  'NEW PHNOM PENH MARKET',
  'NEIMANS FAMILY MARKET',
  'WOODS GROCERY',
  'MONTANA VISTA GROCERY',
  'METRO MEDS DISPENSARY',
  'MCL RESTAURANT BAKERY',
  'MARKET SQUARE LIQUORS',
  'JAMES AVERY CRAFTSMAN',
  'LOS TOROS MEAT MARKET',
  'LAS TROJAS HUNTSVILLE',
  'KRIEGERS HEALTH FOODS',
  'TOTAL FASHION',
  'TORCHYS TACOS',
  'ROOST',
  'GOLDEN TOUCH CAR WASH',
  'GRESHAM PACKAGE STORE',
  'HIGHWAY PACKAGE STORE',
  'HONG PHAT FOOD CENTER',
  'INSTACARTCVS PHARMACY',
  'TRES HERMANOS',
  'JCIJAMES CONEY ISLAND',
  'JIMS ORIGINAL HOT DOG',
  'BARROS PIZZA',
  'TATES GROCERY',
  'LAFAYETTES MUSIC ROOM',
  'CHOO CHOO EXPRESS',
  'BARGAIN BARN',
  'MR PETMAN SUPERCENTER',
  'CHAPPELLS CEE BEE',
  'BAHAMA BUCKS',
  'CASH SAVER PANTRY',
  'MM BEAUTY SUPPLY WIGS',
  'C T PACKAGE STORE',
  'DAG NYC',
  'RONA',
  'PENNINGTONS FOOD FAIR',
  'NOVROZSKYS HAMBURGERS',
  'BUY N SAVE MARKET',
  'ONE STOP SMOKERS SHOP',
  'PORTILLOS RESTAURANTS',
  'PAPERIFIC SUPERMARKET',
  'PETTISVILLE MEATS INC',
  'BROOKE APARTMENTS',
  'BB CONVENIENCE',
  'POLLYS COUNTRY MARKET',
  'BOWLING GREEN IGA',
  'BLAKES LOTABURGER',
  'RICHWAY BEAUTY CENTER',
  'ROBERT WAYNE FOOTWEAR',
  'BEST DOLLAR STORE',
  'SIMPSONS SUPER MARKET',
  'SAN PABLO SUPERMARKET',
  'SANDY FORKS MINI MART',
  'SCHIELS FAMILY MARKET',
  'TEJUN THE TEXAS CAJUN',
  'BEAUTY EXPRESSION',
  'STAKS PANCAKE KITCHEN',
  'SUGAR PINE SMOKEHOUSE',
  'BROAD BEVERAGE',
  'A TOWN WINGS',
  'TEXAS CHICKEN BURGERS',
  'THE HYDRATION STATION',
  'ASIAN FOOD CENTER',
  'ASHLAND FOOD COOP',
  'YODER MEATS',
  'TRULIEVE JACKSONVILLE',
  'ALPINE MARKET BROULIMS',
  'VINH HUNG SUPERMARKET',
  'WALLACES TRADING POST',
  'WARSAW COFFEE COMPANY',
  'BEVERAGE DEPOT LIQUORS',
  'FEDERALES',
  'ACEES MARKET DELI',
  'FATBURGER',
  'BROMAS DELI BROOKHAVEN',
  'BERNARDSVILLE HARDWARE',
  'BEVERAGE DEPOT SPIRITS',
  'BLACKJACK PIZZA SALADS',
  'A GAMERS PARADISE',
  'WHOLE FOODS COOP',
  'BROOKHAVEN MARKETPLACE',
  'BUSCH GARDEN TAMPA BAY',
  'CHIPOTLE MEXICAN GRILL',
  'CANNATAS FAMILY MARKET',
  'CARNICERIA LA HACIENDA',
  'WEST MAIN OUTLET',
  'DECICCO FAMILY MARKETS',
  'VIRGINIA PROPANE',
  'CIRCLE HARDWARE SUPPLY',
  'UNION ROAD PLAZA',
  'DAIQUIRI DADDI',
  'TIME SAVER GAUSE',
  'TIGUA SMOKE SHOP',
  'CHICKNU',
  'DUBSS DISCOUNT LIQUORS',
  'EXPRESS FACTORY OUTLET',
  'TIA DORAS BAKERY',
  'FELDMANS MARKET CENTER',
  'HAIR ART BEAUTY SUPPLY',
  'FOURTH AV SUPER MARKET',
  'FREELAND SHUR SAVE INC',
  'THE THRIFT STORE',
  'INSTACARTFAMILY DOLLAR',
  'GURLEY LEEP MOTORWERKS',
  'THE PERFECT POUR',
  'DRY FALLS',
  'FATTALS SYRIAN BAKERY',
  'COOKES FOOD STORE',
  'ISLAND GOURMET MARKETS',
  'THE BURGER JOINT',
  'THAI KITCHEN BAR',
  'DRIZLYCOM',
  'PETRO',
  'VCS',
  'LA UNICA INTERNACIONAL',
  'LAVERNIA COUNTRY STORE',
  'LISAS FILIPINO CUISINE',
  'TH STREET LIQUOR',
  'MC AULIFFES PROCESSING',
  'TERIYAKI MADNESS',
  'DONS BENS',
  'MCDANIELS DO IT CENTER',
  'MOULTRIE PACKAGE STORE',
  'TORME FOODS',
  'NEDDERMANS STEAK PLACE',
  'NEW YORKERS FOODMARKET',
  'DEER PARK',
  'PAISANOS PIZZA N PASTA',
  'SUPER PENNY MART',
  'PETERS CUT RATE LIQUOR',
  'GUNTHER TOODYS',
  'SUNSHINE GROCERY',
  'RIVERVIEW FRESH MARKET',
  'SUMMIT STOP UGAS',
  'SHAMROCK BEVERAGE MART',
  'SANTIKOS ENTERTAINMENT',
  'SPIRITS OF ATOKA',
  'SOUTHERN BOILERS',
  'ST AUGUSTINE FISH CAMP',
  'SMITHS FOOD DRUG',
  'THE KITCHEN',
  'PETES',
  'ST FRANCISVILLE MARKET',
  'SILVER MINE SUBS',
  'THE SIRE BAR AND GRILL',
  'THRIFTWAY SUPER MARKET',
  'U HOUSE OF FITTED CAPS',
  'SEAMUS MCDANIELS',
  'TEACHER BOX',
  'SCARY CANARY PUB',
  'WILLIES GRILL ICEHOUSE',
  'WASHINGTON WINE LIQUOR',
  'WILLIAMS DISCOUNT FOOD',
  'SANDWICH COMPANY',
  'KREMMLING MERC',
  'WIRELESS EXPRESSION II',
  'YELLOW JACKET HOT DOGS',
  'SACK N SAVE FOOD',
  'AMERICAN DISCOUNT FOODS',
  'ROOSTERS PACKAGE',
  'COX STORE',
  'ATLANTIC FARMERS MARKET',
  'BIG R FARM RANCH SUPPLY',
  'CANTEEN',
  'RAYS',
  'BIG R RURAL KING SUPPLY',
  'CAJUN STEAMER BAR GRILL',
  'RA SHOP METAIRIE',
  'R B MOTOR SPORTS',
  'Q Q HAWAIIAN BBQ',
  'MOSERS GROCERY',
  'COSENTINOS MARKET',
  'GIANT HEIRLOOM MARKET',
  'JAHANT FOOD FUEL STOP',
  'INSTACARTDECICCO SONS',
  'TOKYO CENTRAL',
  'HONG KONG SUPERMARKET',
  'CHECKER FLAG DRIVE THRU',
  'COXS SMOKERS OUTLET PRP',
  'PRICE CHOICE IGA',
  'DUDLEYS DISCOUNT LIQUOR',
  'EATN EAST ASIAN CUISINE',
  'STRIKE REEL',
  'BROWARD',
  'FAMILY FAIR MARKETPLACE',
  'FIRELAKE DISCOUNT FOODS',
  'PLANET K CENTRAL',
  'GRINGOS MEXICAN KITCHEN',
  'STOP N SHOP',
  'PETE AND RE PETE',
  'GRAYSON BEVERAGE CENTER',
  'JJ INTERNATIONAL MARKET',
  'HAIR CITY BEAUTY SUPPLY',
  'HOLLYWOOD BEAUTY SUPPLY',
  'JENNYS LIQUOR AND WINES',
  'KEN CHRIS PACKAGE STORE',
  'OUTLAWS BARBEQUE',
  'LAKEWOOD LICENSE BUREAU',
  'MANHATTAN BEAUTY SUPPLY',
  'OFFUTT CAPEHART EXPRESS',
  'ONCE UPON A CAFE',
  'OLD FASHION DELI',
  'NYC FRESH MARKET',
  'RAMADA VALLEJO',
  'ON THE GRILL RESTAURANT',
  'PAPA JIMS TROPICAL FISH',
  'NEKTER JUICE BAR',
  'SECOND AVE THRIFT STORE',
  'MILTON PIC N SAV',
  'BOTTLES',
  'THE',
  'RICKS BARBECUE FLORENCE',
  'RIVERDALE PACKAGE STORE',
  'SHADY MAPLE FARM MARKET',
  'MILLSTONE MARKET',
  'TASTEBUDS NATURAL FOODS',
  'STOKES BURLEY POMERELLE',
  'MILES OF EXOTICS',
  'SAVMOR DRUG',
  'SONIC DRIVE IN',
  'CASHSAVER',
  'SAKMAN DELI',
  'SAINT MARYS',
  'ARIANNA MEDICAL PHARMACY',
  'CARPENTER',
  'BMC PHO',
  'WASHINGTON HILL LIQUORS',
  'WILTON DISCOUNT LIQUORS',
  'AMERICAN SHOOTING CENTER',
  'LAKELAND LIQUORS',
  'LA CONCHA BAKERY',
  'SUNSHINE MEATS',
  'KIDS FOOT LOCKER',
  'BUREAU OF MOTOR VEHICLES',
  'KHATKAR CORP INC',
  'FAMILY SQUARE RESTAURANT',
  'JOY TO THE WIELD',
  'CLOVERLEAF PACKAGE STORE',
  'JIMN NICKS BARBQ',
  'FRESH MARKET SOUTH OGDEN',
  'OLNEY',
  'FIRST CHANGE LAST CHANGE',
  'FLETCHERS RESTAURANT BAR',
  'FOURTH QUARTER BAR GRILL',
  'FRIENDLY FRANKIES LIQUOR',
  'GERLACHS FLORAL AND GIFT',
  'INSTACARTTOTAL WINE MORE',
  'R S LIQUORS',
  'GOODWILL DONATION CENTER',
  'I BEVERAGE DEPOT',
  'KJS MARKET IGA WINNSBORO',
  'HONG PHAT MARKET',
  'HONG KONG MARKET',
  'PUMP N SAVE',
  'MALAMA MARKET OCEAN VIEW',
  'KINGS WIGS BEAUTY SUPPLY',
  'LOTTE KOREAN SUPERMARKET',
  'HONEY DEW DONUTS',
  'HOMETOWN COUNTRY',
  'MAKI YAKI JAPANESE GRILL',
  'HOLLYWOOD BEAUTY',
  'HEARTLAND XPRESS',
  'AQUATIC CRITTER',
  'NY PRESBYTERIAN PHARMACY',
  'PALMETTO HEALTH PHARMACY',
  'PLATOS CLOSET MANCHESTER',
  'BASSETTS MARKET',
  'PINK BEAUTY',
  'HAMBURGER HEAVEN',
  'BERRYHILL',
  'BEAUTY EXCHANGE',
  'SCHOOL TOOLS OFFICE PROS',
  'SCRATCH SANDWICH COMPANY',
  'GUILTY PLEASURES',
  'STAUFFERS OF KISSEL HILL',
  'SUGARLANDS DISTILLING CO',
  'STATE OF OHIO DEPARTMENT OF PUBLIC SAFETY BUREAU OF MOTOR VEHICLES',
  'GREENWISE MARKET',
  'BLUE MARLIN IGA',
  'THE WISHBONE PET COMPANY',
  'THOMASON WINE AND LIQUOR',
  'GREEK GUYS GRILL',
  'BOOTLEG LIQUORS',
  'VALUE CENTER MARKETPLACE',
  'WEAVERS DISCOUNT LIQUORS',
  'RAINBOW BLOSSOM NATURAL FOOD MARKETS MIDDLETOWN',
  'BUCKEYE RACEWAY',
  'WORLD WIDE AQUARIUM PETS',
  'YOSHINOYA CRENSHAW TH ST',
  'ACTIVATION STATUS RECEIPT',
  'ALLSUPS CONVENIENCE STORE',
  'ASIAN AND AMERICAN MARKET',
  'BAUMHOWERS VICTORY GRILLE',
  'BRONCO MEXICAN RESTAURANT',
  'COOPPORTUNITY MARKET DELI',
  'CAPTAIN JACKS LIQUOR LAND',
  'RIVER STREET SWEETS SAVANNAHS CANDY KITCHEN',
  'CHERRY VALLEY SUPERMARKET',
  'COLLEGE STATION',
  'GATE GAS STATION',
  'BALESTRAS',
  'GAS GO FOOD MART',
  'INSTACARTMY COUNTY MARKET',
  'GABRIELS SCOTIA FOODS IGA',
  'GREENS DISCOUNT BEVERAGES',
  'FRUGAL MACDOOGAL',
  'FRITZS ADVENTURE',
  'PARK N SHOP',
  'BENEFIT',
  'LIQUOR WORLD OF NASHVILLE',
  'INTERNATIONAL SUPERMARKET',
  'JAYS KRISPY FRIED CHICKEN',
  'JIMMY JAZZ FASHION SQUARE',
  'NORTHGATE GONZALEZ MARKET',
  'KOTO JAPANESE STEAK HOUSE',
  'BUREAU OF MOTOR VEHICLESDEPUTY REGISTRAR',
  'FRIJOLES FRESCAS',
  'FAMOUS TOASTERY',
  'PANDA HOUSE',
  'OAK TREE WINE AND SPIRITS',
  'FORT DRUM MARKET',
  'FOODLAND MERCADO',
  'PRICE LESS FOODS HILLVIEW',
  'REDDING GROCERY OUTLET BARGAIN MARKET',
  'BARRETT',
  'SUPER SAVE DISCOUNT FOODS',
  'SALKS ACE HARDWARE MARINE',
  'SAVWAY FINE WINES SPIRITS',
  'FIVE STAR LIQUOR',
  'TRICITY WHOLESALE TOBACCO',
  'SOMETHING DIFFERENT GRILL',
  'TARWATER FARM HOME SUPPLY',
  'FANTASTIC BEAUTY',
  'BOTTLE SHOP OF SPRING LAKE',
  'SPARKLE BRITE CAR WASH DETAIL CENTER',
  'OUTDOOR SUPPLY HARDWARE SILVER CREEK',
  'ALEXANDERS HIGHLAND MARKET',
  'EL RANCHO MARKET',
  'EL POLLO SABROSO',
  'AL CARBON',
  'BRAGG SMOKEBOMB MM EXPRESS',
  'FASTACO MEXICAN RESTAURANT',
  'CROWN DISCOUNT WINE LIQUOR',
  'DINO FRANCESCOS PIZZAPASTA',
  'MR MRS CRAB',
  'INCREDIBLE PETS',
  'AKRON ZOO',
  'DOTS SUPERMARKET',
  'FEEL BEAUTY SUPPLY STORE COLOR WIGS',
  'HOLIDAY HOUSE LIQUOR STORE',
  'FRIDAY HARBOR MARKET PLACE',
  'EDITIONS COFFEE SHOP AND BOOK STORE',
  'BREW AND BREWS DRIVE THRU AND PIZZA',
  'MINFORD IGA',
  'GEORGETTIS PASTA AND SAUCE',
  'HOOSICK STREET WINE CELLAR',
  'BOUDREAUXS LOUISIANA SEAFOOD STEAKS',
  'MIDNIGHT SUN JEWELRY GIFTS',
  'LINDYSPRING DRINKING WATER',
  'TROPICAL SMOOTHIE CAFE NEW BEDFORD',
  'MAIN STREET GROCERY MARKET',
  'KENWOOD LIQUORS',
  'METRO WINGS',
  'MOMENCE GLADIOLUS FESTIVAL',
  'MOUNTAIN FRESH SUPERMARKET',
  'LA PLAZA MARKET',
  'PAPITOS MEXICAN RESTAURANT',
  'PETES BURGERS WINGS DRINKS',
  'PRICE CHOPPER SUPERMARKETS',
  'COST LESS MARKET',
  'ABC STORE',
  'MELS',
  'VALUE VILLAGE THRIFT STORE',
  'STATS SPORTS BAR AND GRILL',
  'CLOUD HOUSE VAPE',
  'WAHKEE CHINESE SEAFOOD RESTAURANT',
  'ARNES WAREHOUSE PARTY STORE',
  'THUNDERBOLT INDOOR KARTING',
  'WHITES FOOD LINERSKIINGMAN',
  'CHURCH S CHICKEN',
  'FUJI SUSHI',
  'BALLARD TOWN COUNTRY MARKET',
  'CHIMA FOOD STORE',
  'CHESTER BEVERAGE',
  'CENTRAL COMPUTER',
  'WITS INN',
  'CARTERS PET MART',
  'CARSON CLASS SIX',
  'GOODFELLAS WINE AND SPIRITS',
  'CADDEN BROTHERS BEER DISTRIBUTORS',
  'CARMACKS GROCERY',
  'GARBERS DO IT BEST HARDWARE',
  'MIKE CS FAMILY SPORTS GRILL',
  'THE STUFFED BAKED POTATO FACTORY',
  'KENNIES MARKET SPRING GROVE',
  'PHILLIES ORIGINAL STEAK LEMONADE',
  'OFF BROADWAY SHOE WAREHOUSE',
  'MOOYAH BURGERS FRIES SHAKES',
  'BUD HALS LIQUORS',
  'BRUEGGERS BAGELS',
  'OH MARKETINTERNATIONAL FOOD',
  'BRIANS SUPERETTE',
  'LORIS GIFTS',
  'POPULUS FINANCIAL GROUP INC',
  'THE WINE CELLAR OF WATCHUNG',
  'SCOTTS BEAUTY BARBER SUPPLY',
  'SENOR PANCHOS FRESH MEXICAN',
  'BOBS FAMOUS DELI',
  'ZANOTTOS WILLOW GLEN MARKET',
  'BINGHAMTON AGWAY',
  'BILLS CASH SAVER',
  'TOP STOP',
  'BOYERS FOOD MARKET FLEETWOOD',
  'ALLSUPS CONVENIENCE STORE NO',
  'BENS SUPERCENTER',
  'BELLS FOOD STORE',
  'BOYERS FOOD MARKET BIRDSBORO',
  'CHARLEYS PHILLY STEAKS WINGS',
  'CHICAGO HEIGHTS FRESH MARKET',
  'NAPA AUTO PARTS JOHNSON MACHINE',
  'DONELSON PIKE LIQUORS',
  'GORDINS FOODS BUTCHER SHOPPE',
  'KITTY HAWK KITES VIRGINIA BEACH',
  'LIL CRICKET',
  'STIR FRY CAFE',
  'MAXI',
  'ANTHEM ALE HOUSE',
  'ANGIES BEAUTYCOM',
  'COLOSSO TACO AND SUBS',
  'TIJUANA FLATS',
  'CRUMPYS HOT WINGS',
  'GUSS COMMUNITY MARKET',
  'FOOD KING SUPERMARKET',
  'FLOWERS BAKERY OUTLET',
  'GRABBAGREEN EPICENTRE',
  'GOLDEN GATE PETROLEUM',
  'GARDNERS SUPER MARKET',
  'ELYRIA LICENSE BUREAU',
  'FRESH MARKET ST SOUTH',
  'FOODSMART',
  'BEER COMPANY',
  'DEBARGES CRAWFISH',
  'THE BODY SHOP',
  'TC FRESH MEAT',
  'GERESBECKS',
  'DICKS LAST RESORT',
  'THE GRILL BAR',
  'DONS BENS LIQUOR BARN',
  'BELLS MARKET',
  'THE LUCKY DOG',
  'THE TILE SHOP',
  'DOCS COUNTRY MART',
  'DOMINION OUTDOORS',
  'CARHARTT',
  'DOUGS SUPERMARKET',
  'CLINTON HILL HARDWARE',
  'CHENS CHINESE CUISINE',
  'H K MARKET',
  'THIRSTY GOATS',
  'TOGOS GILBERT',
  'HAIR DEPOT',
  'EVERETT',
  'TOM THUMB GAS',
  'BOUDOIR NOIR',
  'DUFFYS CAPE CORAL',
  'HANA SUSHI',
  'GACMSC',
  'CARMINES',
  'BOYOS MARKET',
  'HAPPY TIME',
  'GATORLAND',
  'BUFFALO JOES',
  'EINSTEIN BROTHERS',
  'TRATTORIA MIA',
  'TROUT LIQUORS',
  'IMOS PIZZA',
  'ANGUS WHOLESALE MEATS',
  'MACEYS PLEASANT GROVE',
  'ADAM EVE FAYETTEVILLE',
  'BUY FOR LESS',
  'VIP NAILS SPA',
  'CHECKERS',
  'FANTASY CLACKAMAS',
  'ACE HILLSIDE HARDWARE',
  'BYLERS STORE',
  'FIVE STAR OF KNOX',
  'YEARS TO YOUR HEALTH',
  'WILLES CHICKEN SHACK',
  'JAY BEAUTY',
  'WINGS FACTORY',
  'WOODMANS FUEL',
  'JAY C FUEL',
  'WYNWOOD WALLS',
  'VITELIOS MARKETPLACE',
  'JEFFERSONS',
  'VIP WIG BEAUTY DEPOT',
  'VIET HOA SUPERMARKET',
  'LTD',
  'FRYS',
  'DINER',
  'CAPITOL MART',
  'GET GO',
  'FOX BEAUTY SUPPLY',
  'FRESH FARMS NILES',
  'G L IMPORT EXPORT',
  'JERRY LEES',
  'JERRYS IGA',
  'ZESTO ATLANTA',
  'GREEN HILLS FARMS',
  'AB FOOD LIQUOR',
  'GREENFIELD MARKET',
  'THE LOW PRICE LEADER',
  'AM TO PM STORE',
  'ARCHER LIQUORS',
  'CHICKEN RICO',
  'TAILWIND CONCESSIONS',
  'AVIANO RDM LLC',
  'H Y BEAUTY SUPPLY',
  'SUPERMERCADO MURPHYS',
  'SUPER MERCADO MEXICO',
  'CIRILLAS',
  'JRS CORNER',
  'BANDANAS BARBQ',
  'KATE SPADE',
  'HABITS SMOKE VAPE',
  'KHOOL MART',
  'HAMILTONS GROCERY',
  'HAMPTON BEVERAGES',
  'BEAUTY LAND II',
  'KING CRUST',
  'EARPS',
  'SCHULTES FRESH FOODS',
  'GOODYS',
  'RUSHS KITCHEN SUPPLY',
  'RIGOBERTOS TACO SHOP',
  'RICHTERS MARKETPLACE',
  'CITISTOP',
  'KJS MARKET',
  'BIG DADDYS BBQ',
  'ACME',
  'COLUMBIA',
  'SARAH',
  'CONDOM SENSE',
  'LANEBRYANT',
  'BLAIRS WORLAND',
  'HARTER HOUSE NIXA',
  'HARVEST OF TUCSON',
  'HENRYS SMOKEHOUSE',
  'OVER EASY SCOTTSDALE',
  'NU WAVE FISH CHICKEN',
  'NICKS GYROS AND SUBS',
  'COTTONON',
  'LOS GALLOS',
  'BOTTLES LIQUOR',
  'LOS PRIMOS',
  'CPMH RAL',
  'HITCHCOCKS MARKET',
  'LUCKY DELI',
  'MILES FARMERS MARKET',
  'MED NORTH OUTFITTERS',
  'LUX BEAUTY',
  'HEMRAJ',
  'CRUIZERS',
  'MARIANAS SUPERMARKET',
  'MAGNET MAX',
  'HWY DISCOUNT ZONE',
  'IDAHO YOUTH RANCH',
  'MAIN STREET JUNCTION',
  'C FRESH MARKET',
  'C K FOOD STORE',
  'CANTINA LAREDO',
  'LITTLE CAESARS PIZZA',
  'MATRYOSHKA',
  'FARMAIR',
  'DESERT RIDGE',
  'CASSANOS PIZZA',
  'MEAT DEPOT',
  'JASONS SUPERFOODS',
  'LA SUPER MEAT MARKET',
  'DON JUAN',
  'JAX SPORTS GRILLE',
  'KNOWLANS FRESH FOODS',
  'KNEADERS BAKERY CAFE',
  'MIMIS CAFE',
  'CHAPS PIT BEEF',
  'CHARLIES PLACE',
  'MIND GAMES',
  'JESSE TAYLOR SEAFOOD',
  'BUTLER FARM MARKET INC',
  'JIM THORPE MARKET',
  'JOY BEAUTY SUPPLY',
  'MR HIBACHI',
  'HUDSON',
  'DON TACO',
  'HOME BUYS',
  'MR MIGUELS',
  'KATS WINE SPIRITS',
  'KOBE TEPPAN SUSHI',
  'HOT N JUICY CRAWFISH',
  'COMMUNITY COOP',
  'CONSUMER MEATS',
  'KOKAI THAI BISTRO',
  'HENNINGS SUPERMARKET',
  'DOLLAR STORE',
  'LAS PALAPAS CARES',
  'EASY PICKENS',
  'LAZY ACRES MARKET',
  'NEW GLARUS',
  'GOURMET GLATT MARKET',
  'GOODSONS SUPERMARKET',
  'GOCHAS BREAKFAST BAR',
  'GENERAL JACKSONS INC',
  'FISHERS',
  'HOMESENSE',
  'CORNELLS FOODS',
  'COUNTRY CORNER',
  'INATUB',
  'NORI JAPAN',
  'CRUMPS LANDING',
  'NOW HIRING',
  'EMPORIUM',
  'NUMBER WOK',
  'LOONEYS PUB NORTH',
  'OHELO ROAD',
  'FOOD CONSPIRACY COOP',
  'FELDMANS VALLEY WIDE',
  'NCR',
  'FUEGO',
  'JOCKEY',
  'LUX BEAUTY SUPPLY',
  'EVERYONE',
  'EAGLE COUNTRY MARKET',
  'MALONES COST PLUS',
  'DRAFT RESTAURANT BAR',
  'ON THE GOL',
  'DENNIS COMPANY',
  'DRUNKEN OYSTER',
  'FAMILY FRUIT',
  'ON THE RUN',
  'MARIA DS SUB SHOP',
  'DAVES VIDEO EXCHANGE',
  'EASTOWN LIQUOR',
  'END ZONE PLANO',
  'FAMILY OWNED',
  'MCX PACKAGE STORE',
  'FLYING J',
  'COUNTRY FRESH MARKET',
  'COLUMBUS FOOD MARKET',
  'JALAPENOS',
  'PAPA GINOS',
  'KARSCHS VILLAGE MARKET',
  'FARMERS BASKET',
  'PASTA PANE',
  'MILAMS MARKET IGA',
  'FARMERS MARKET',
  'CHIQUITA FOOD MARKET',
  'CHERRY LANE QUICK ST',
  'FIESTA LIQUORS',
  'FOODFAIR',
  'PAT CATANS',
  'FIESTA FOODS',
  'PIZZA LUNA',
  'MILITARY CLOTHING',
  'BUSTERS WINE SPIRITS',
  'FOOD COUNTRY',
  'FLAT TOP GRILL',
  'FLOWERS BAKING',
  'PL EXPRESS',
  'MIZERS COST FOODS',
  'FOSTERS FREEZE',
  'BOHNINGS SUPERMARKET',
  'NEW CHINA CUISINE',
  'POLLO LOCO',
  'POWER TRAC',
  'BEAUTY QUEEN C STORE',
  'JCI GRILL',
  'GAME XCHANGE',
  'OASIS FRUIT CONES',
  'ATL ZOO NOURISH CAFE',
  'ASIA MARKET AND THAI',
  'PURPLE COW',
  'GARCIAS MARKET',
  'GATE PETROLEUM',
  'QUICK MART',
  'GOLDEN SKILLET',
  'ARCHIBALDS DRIVETHRU',
  'QUICK STOP',
  'GO CALENDARS',
  'PAPPAS RESTAURANT',
  'HUNT',
  'LLBEAN',
  'FROGGIES',
  'ZEKOS EXPRESS GRILL',
  'RADIOSHACK',
  'YOLK ONE ARTS PLAZA',
  'WORLD WINES LIQUORS',
  'WAYNE LEES GROC MKT',
  'HAIR TO BEAUTY',
  'REVOLUTION',
  'RIOS PIZZA',
  'GARCIAS',
  'HAVE YOU HEARD',
  'GREGS MARKET',
  'PEANUTS FUEL MART',
  'HERITAGE DAIRY',
  'GAS MART',
  'JS LIQUOR',
  'PETS PLUS NATURAL',
  'ROESCH ORD',
  'PETSENSE DANVILLE',
  'TIMEWISE FOOD STORE',
  'THE REAL DEAL PLACE',
  'SUN ASIAN SUPER MARKET',
  'HOT DOG HEAVEN',
  'RULLI BROS',
  'HP OIL COMPANY',
  'PLS CHECK CASHERS',
  'IBEAUTY SUPPLY',
  'SUPREMO FOOD MARKET',
  'SUPERMERCADO EL REY',
  'HAWKS',
  'LUKOIL',
  'S R DIMSUM',
  'ST GEORGE PETRO LLC',
  'INSTACARTBEVMO',
  'SMITHS SUPER MARKET',
  'SLIDER INN DOWNTOWN',
  'SHARKS FISH CHICKEN',
  'SAFEWAYCOM',
  'SEOR FROGS',
  'KC HAIR OUTLET',
  'PORTER DO IT BEST',
  'SHOP NSAVE',
  'HANDEE HUGOS',
  'SALISBURY SUPER PET',
  'GLENCORE',
  'SHOP SMART',
  'REBEL OIL COMPANY',
  'RED TOMATO MARKET',
  'ROSATIS MARKETPLACE',
  'RICHMOND FOOD PRIDE',
  'REHKOPF ENTERPRISES',
  'RED APPLE KWIK FILL',
  'KUI KOREAN BBQ',
  'SHOPES IGA',
  'GONZALES',
  'LINCOLN POSTAL',
  'LOS RESTAURANT',
  'GILROYS',
  'MP',
  'HEALTHY SPOT',
  'PILOT TRAVEL CENTER',
  'OCO',
  'HMART',
  'MCCOYS',
  'SAUNDERS HARDWARE',
  'GRAETERS',
  'SCHEMAN AND GRANT',
  'SHOGUN RESTAURANT',
  'HOME LIQUORS',
  'SNACK PACK',
  'SOL Y LUNA',
  'LUCKY WISHBONE',
  'SNOISLE FOOD COOP',
  'MARUKAI LIVING',
  'MURPHYS MARKETS INC',
  'SNYDER DRUG STORE',
  'SPEED FUEL',
  'MELROSE LUMBER',
  'MIKES COUNTRY STORE',
  'MEDINAS SUPERMARKET',
  'MONTANA MARKET',
  'LUMPYS SPORTS GRILL',
  'LISAS FRIED CHICKEN',
  'STINE',
  'HASKELLS',
  'SPRINTMART',
  'MR FURLEYS BAR',
  'STAKES IGA',
  'SUN BEAUTY SUPPLY',
  'HG HILLS',
  'SUPER BEAUTY MART',
  'KINGS COUNTY MARKET',
  'STOPAMINIT',
  'SUN PALACE',
  'HOMEBASE',
  'SWAN SONG SPIRITS',
  'SUPER BEST',
  'JOY LUCK RESTAURANT',
  'LA CASITA',
  'TAQUERIA EL SABOR',
  'SUPER KING',
  'NILSSENS FOODS',
  'NOCKOS III INC',
  'JJ GAS GRILL',
  'SUPER STOP',
  'THE ORIGINAL TOPS',
  'IGA PLUS GEORGETOWN',
  'OBRIENS MARKET',
  'OCTOPUS GARDEN',
  'HUNGRY HOWIES PIZZA',
  'HOWELLS SUPERMARKET',
  'KAY JEWELERS',
  'HILLSBOROUGH COUNTY',
  'THE RANCHITO WACO',
  'HARRYS ARMY SURPLUS',
  'HOMETOWN',
  'SUPERSAVER',
  'PASEO CATRACHO',
  'PATEL BROTHERS',
  'PENNMAR LIQUOR',
  'KAZAMA SUSHI',
  'PICCADILLY MON',
  'THE SOUTHERN CHEF',
  'FUEL ON TRUCK PLAZA',
  'NAPLES PIZZA RESTAURANT',
  'ICING',
  'MINITS',
  'FOREST PARK WFO IGA',
  'HOT SPOT',
  'KILLIAN WINE',
  'TODDS IGA EXPRESS',
  'FASTRIP FOOD STORES',
  'TASTY CRAB',
  'POSTAL SERVICE',
  'PRODUCE MARKET',
  'TOP SHELF LIQUORS',
  'HWY STOP',
  'TOUCANS ALE HOUSE',
  'ECONOMY SUPERMARKET',
  'TEADM MINI',
  'TH ST CAFE',
  'DOG SUPPLIES OUTLET',
  'LIONS DEN',
  'J J FISH',
  'TRI STATE LIQUORS',
  'TRIS MARKET PLACE',
  'CUZINOS PIZZA PASTA',
  'TIGER MART',
  'ROLL N ROASTER',
  'RRANCH MARKETS',
  'JB HAWKS',
  'CRAMPS LIQUOR STORE',
  'TORE LUKES',
  'COUNTRY BOY MARKETS',
  'LIQUORAMA',
  'TOWER CITY',
  'NATURE',
  'TRADE FAIR',
  'VDC EL SOL MARKET',
  'SHOPPERS WORLD',
  'CHEESEBURGER BOBBYS',
  'VIGILS BEEF JERKY',
  'VILLAGE GIFT BARN',
  'SUPERIOR REALTY COMPANY',
  'THE DUTCH POT LAUD LAKE',
  'US GROCERY',
  'CALIS BEAUTY SUPPLY',
  'SHORES SISTERS',
  'BOTTLE STOP LIQUORS',
  'APT',
  'IAN',
  'USA LIQUOR',
  'STORE',
  'TOTAL',
  'LOS ARCOS',
  'KY SHOPPETTE',
  'WAKEFIELD LIQUORS',
  'BLYTHE RANCH MARKET',
  'PAPAYA',
  'VAN HEUSEN',
  'SPRINT FOOD ST',
  'VANCANTEEN',
  'WABA GRILL',
  'STRIPS CHICKEN',
  'LA MADELEINE',
  'SUDDEN SERVICE',
  'LANE PACKING',
  'BB LIQUOR WAREHOUSE',
  'KIDZ',
  'JACKS',
  'PATINA',
  'ABC SELECT SPIRITS',
  'KESSLERS',
  'AMERICAS BEST WING',
  'AUTO STOP FOOD MART',
  'ATWOOD WINE SPIRITS',
  'WASENA MAR',
  'SUNDO BOARDMAN',
  'WB LIQUORS',
  'AMERICAN DELIATHENS',
  'WHICH WICH',
  'ALAMO THRIFT STORES',
  'BANNER SUPERMARKET',
  'SURPLUS OUTLET',
  'WINCHESTER',
  'YUTZYS FARM MARKET',
  'WILLIES SUPER VALU',
  'PLANET',
  'HIGHWAY',
  'MARABELLA',
  'LONDON DRUGS',
  'WINDERMERE',
  'TIENDA MORALES',
  'TONYS PIZZERIA',
  'KIM LONG',
  'LTK CRAWFISH',
  'Y NOT STOP',
  'LUIGIS PIZZA',
  'BOBS PRODUCE RANCH',
  'YOGEN FRUZ',
  'QIKNEZ',
  'KP PRINT',
  'TRIPP ACE HARDWARE',
  'YOGURTLAND',
  'MAGIK MARKET',
  'BOYDS WINE SPIRITS',
  'BROTHERS FOOD MART',
  'US BEAUTY MART',
  'ZIP N MART',
  'LA LUCHA',
  'BUCYRUS TRUE VALUE',
  'A A BARBQUE',
  'THE TREASURE CHEST',
  'HUNGRYS',
  'WEAVER MARKETS',
  'LAKELAND',
  'MASSAGE ENVY',
  'BYRNE DAIRY STORES',
  'TEDS MONTANA GRILL',
  'CHANDLERS DRIVE IN',
  'TAQUERIA EL ZARAPE',
  'TAJIMA RAMEN HOUSE',
  'AGRI SUPPLY',
  'WISH WAREHOUSE',
  'ABC SUPERMARKET',
  'LAKEVIEW',
  'ACCESSORY WORLD',
  'MELAO BAKERY',
  'ASIA MARKET',
  'SULLIGENT DISCOUNT',
  'CHITOWN FOODLIQUOR',
  'KMTP',
  'JMART',
  'RALLYS',
  'SPIRITUALLY ROOTED',
  'LE JOUET',
  'SIMONS SUPERMARKET',
  'J WINGS',
  'MET FRESH',
  'AVAIL VAPOR',
  'AVALON MART',
  'LEAD LAP',
  'CROCKER EAGLE STOP',
  'BAILEYS IGA',
  'SATURN FOOD CENTER',
  'RUPENAS FINE FOODS',
  'BANANA KING',
  'LEE DADS',
  'MICHAEL KORS',
  'ROYALTY PINE HILLS',
  'BARTRAM SON',
  'MIDWEST CASH',
  'DAY FARMERS MARKET',
  'DEKALB SUPERMARKET',
  'BEAUTY AND MORE',
  'BATTERY JOE',
  'LOLLICUP',
  'BEAUTY CLUB',
  'PUEBLO MEAT MARKET',
  'BEAUTY ICON',
  'VISIT',
  'MIRA MESA',
  'KATZS',
  'REFUEL',
  'BEAUTY LAND',
  'MR SUBMARINE',
  'BLOOM NATURALLY',
  'PEOPLE SUPERMARKET',
  'DONS COUNTRY STORE',
  'PATTIS CAR COUNTRY',
  'BEAUTY LOVE',
  'BEAUTY STOP',
  'BONHAM HARDWARE',
  'DRUG CITY PHARMACY',
  'BEAUTY TOWN',
  'PALAMA SUPERMARKET',
  'EHWA BEAUTY SUPPLY',
  'RESALE',
  'MARKET C',
  'OHIO THRIFT STORES',
  'NUNUS FRESH MARKET',
  'NORTHWEST HARDWARE',
  'NEW YORK NAILS SPA',
  'JUST IN',
  'OASIS MARKET',
  'BRUCES FOODLAND',
  'BLAZE PIZZA',
  'BLISS DAIRY',
  'CAFE INTERMEZZO',
  'BOBS STORES',
  'NATIONAL WAREHOUSE',
  'DPS',
  'MOUNT IDA COLLEGES',
  'EL JIMADOR SUPREMO',
  'PNF',
  'KMOMO',
  'REXALL',
  'ELDENS FRESH FOODS',
  'MESHIKOU',
  'ERNIES FOOD MARKET',
  'ETIWANDA ROADHOUSE',
  'LUKES OF MUNDELEIN',
  'BOTTLE KING',
  'CHAMPION REALTY',
  'CHRISTMAS MOUSE',
  'LITTLE STAR CSTORE',
  'MON CAFE',
  'LASSUS HANDY DANDY',
  'WARDS',
  'CITI TRENDS INC',
  'BURGER ZONE',
  'KESLERS',
  'PACEMAKER JR',
  'RHODES',
  'FIESTA SUPERMARKET',
  'FIRST STOP TOBACCO',
  'KINOKUNIYA CHICAGO',
  'BUYBUY BABY',
  'CORELIFE EATERY',
  'COUNTRY INN B G',
  'MY HOUSE',
  'NESPRESSO',
  'KID KREOLE KOOKING',
  'PAPA MURPHYS',
  'FISHERMANS KITCHEN',
  'CVS SAVERS CLUB',
  'FETCH PET BOUTIQUE',
  'JUDYS ISLAND GRILL',
  'JUDYS BEAUTY STORE',
  'MR HENRYS',
  'K KARNE',
  'PATRIOT CAFE',
  'FRESH FOOD COMPANY',
  'CASA GRANDE',
  'SAVE A',
  'LA HACIENDA MARKET',
  'LITTLE FIVE POINTS',
  'LOT LESS CLOSEOUTS',
  'LOWES HOME CENTERS',
  'LUELLA S BAR B QUE',
  'JENSEN SUPERMARKET',
  'FRESH MARKET PLACE',
  'NEWSLINK',
  'ENTIAT FOOD CENTER',
  'OREGON DINER',
  'EL SOL MEAT MARKET',
  'MORRISON ROADHOUSE',
  'INSTACARTSAMS CLUB',
  'IDEALS SUPERMARKET',
  'CHINA TASTE',
  'OLIVE GARDEN',
  'N A FARM SUPPLYM V',
  'NATURES WAY MARKET',
  'EL GATO TUERTO III',
  'EL FARO RESTAURANT',
  'PERRY LIQUOR',
  'FRESH TACO FACTORY',
  'FROOGELS COSTFOODS',
  'HOPEWELL SUB PIZZA',
  'DRAGON DRIVE IN',
  'ECONOMY PACKAGE',
  'NICOS MEXICAN FOOD',
  'ONE STOP FOOD MART',
  'FRYEBURG THRIFTWAY',
  'EDWARDS FOOD GIANT',
  'FUBONN SUPERMARKET',
  'EL BURRITO LOCO',
  'EDWARDS CASH SAVER',
  'NAM BAC HANG',
  'PARADIES LAGARDERE',
  'PATONS SUPER DUPER',
  'G M PACKAGE LIQUOR',
  'GREEN HEALTHY CAFE',
  'EL FRESCO FARMS',
  'DISCOUNT MINI MART',
  'GIGI BEAUTY SUPPLY',
  'GRANNYS RESTAURANT',
  'PETTYS MEAT MARKET',
  'DILLONS FOOD STORE',
  'MO MOS PASTA',
  'JC CRAB',
  'GRAND PALMS BUFFET',
  'PAPA SALS',
  'EUREKA HARDWARE',
  'ONE STOP',
  'POST OAK FAST STOP',
  'RAGS WINE BEVERAGE',
  'RAMEYS MARKETPLACE',
  'ROBERT FOOD MARKET',
  'PETER RABBIT',
  'PETS N STUFF',
  'ROGERS GREEN HILLS',
  'GOLDEN SADDLE CAFE',
  'GRAND CENTRALFOODS',
  'DAVES RUNNING SHOP',
  'RUBICON RESTAURANT',
  'SAN ANTONIO WINERY',
  'PHOENIX MART',
  'GRAND TRAVEL PLAZA',
  'SCATURRO FOOD MKTS',
  'PIZZA PATRON',
  'PIZZA STREET',
  'COBBLESTONE',
  'FAMILY PIZZERIA',
  'CONNECTIONS',
  'COOP SUPPLY',
  'COCO BEAUTY SUPPLY',
  'CLOVERDALE LIQUORS',
  'MESA LAGREES',
  'SMITH EXPRESS MART',
  'STEINS GARDEN HOME',
  'STEWARTS ROOSEVELT',
  'STOP SHOP PHARMACY',
  'STUDIO MOVIE GRILL',
  'CHIME THAI CUISINE',
  'PIZZA STUDIO',
  'MAXIM MARKET',
  'SWEETWATER CHEVRON',
  'TAINOS BAKERY DELI',
  'HAWAIIAN DRIVE INN',
  'FLATONIA VALERO',
  'HOPE BEAUTY SUPPLY',
  'PLAID PANTRY',
  'CORNER STOP',
  'CARNICERIA ABASTOS',
  'IDEAL SUPERMARKETS',
  'POLLY MARKET',
  'MCCORMICK',
  'VILLA',
  'WINCO',
  'LINGLES',
  'LIDS',
  'PETGOODS',
  'MARKET PLACE',
  'MAX ERMAS',
  'TODOS SUPER MARKET',
  'TOLEDO FOOD CENTER',
  'PET DEPOT',
  'MAUYA SKY',
  'POMPANO JOES',
  'COUNTRYMARK',
  'UNIDOS SUPERMARKET',
  'UPTOWN BEAUTY MART',
  'V F FACTORY OUTLET',
  'BLUESTONE VINEYARD',
  'INSTACARTSAVE MART',
  'JDS TOBACCO OUTLET',
  'COWBOY STOP',
  'BIGGER BETTER DEAL',
  'BERLIN MARKETPLACE',
  'BARNES FOOD CENTER',
  'BARGAIN FOOD STORE',
  'PRUETTS FOOD',
  'JOHN JOHNS SEAFOOD',
  'QUEENS BULLY',
  'PETS PLUS',
  'FREE BASIC WASH',
  'FRESHWAY MARKET',
  'WHITES BAKERY CAFE',
  'XTRA BEAUTY SUPPLY',
  'ADAM EVE GREENVILLE',
  'LITTLE SHACK',
  'ALBERTSONS PHARMACY',
  'RABBIT TRAXX',
  'ARLANS MARKETS INC',
  'APPLE RETAIL STORE',
  'MANHATTAN',
  'KHOURYS WINNEMUCCA',
  'D S GROCERY',
  'RABONS SAVON',
  'KIWI BEAUTY SUPPLY',
  'FRUIT FREE FARM',
  'LEESBURG IGA',
  'ZINGO SUPERMARKET',
  'LEES KITCHEN',
  'M W PARMA',
  'WITHERSPOON GRILL',
  'KROGER MARKETPLACE',
  'WINKS',
  'WISE GUYS CHICAGO',
  'WESTSIDE BEVERAGE',
  'WESTERN BEVERAGES',
  'BLISS BEAUTY SUPPLY',
  'LOBLAWS',
  'REBEL GAS NO',
  'GATORS DOCKSIDE',
  'PETSAKES',
  'LOS MAYAS',
  'LANDIS SUPERMARKET',
  'RHODES STOPS',
  'VISA',
  'PICK SAVE',
  'RIVER SMITHS',
  'DAISO JAPAN',
  'CAESARS WINE LIQUOR',
  'RUBY TUESDAY',
  'LOTLESS',
  'CAREALOT PET SUPPLY',
  'CENTRAL IOWA VAPORS',
  'CENTRAL PARK LIQUOR',
  'PINKBERRY',
  'SALONCENTRIC',
  'GOODWILL AURORA',
  'GOODWILL RENTON',
  'DAVES I G A',
  'CHOOSING BREAK TIME',
  'CITY FARMERS MARKET',
  'V V TACK AND FEED',
  'KINGS BEAUTY',
  'CONNERS SUPERMARKET',
  'COUSINS SUPERMARKET',
  'LUPITA SUPERMARKET',
  'CREDIT CARD SERVICE',
  'UNITED PROVISIONS',
  'TRULIEVE SARASOTA',
  'MACEYS WEST JORDAN',
  'GYRO KING WINGS',
  'MAMMA MIA PIZZERIA',
  'ALON',
  'DEL SOL ACE',
  'KING TOBACCO',
  'DENNYS BEAUTY WORLD',
  'DUFFYS SPORTS GRILL',
  'ZOUP',
  'ZOGOS',
  'EDIBLE ARRANGEMENTS',
  'ELLIOT PARK GROCERY',
  'LUTHERS',
  'PIZZA PRO',
  'LEWIS',
  'DEL SOL IGA',
  'KING LIQUORS',
  'FAMILY FRESH MARKET',
  'FARAH KHOURI MARKET',
  'LATITUDES',
  'MARSH SUPERMARKETS',
  'THE WINE MERCHANT',
  'KENDRA SCOTT',
  'FRESHCO FOOD CENTER',
  'MCDONALDS US HWY N',
  'HAPPY NAILS SPA',
  'FULLERS SUPERMARKET',
  'THE SNEAKY BEAGLE',
  'MD ORIENTAL MARKET',
  'POLAR ICE',
  'HARRISON MARKET',
  'LANDSTOWN',
  'HAMPTON',
  'GLOBAL WINE SPIRITS',
  'SHAKING CRAB',
  'SHIRT SHANTY',
  'THE PAVILION PARK',
  'MADISON',
  'POUND IGA',
  'HILLS FOOD LAND',
  'DISC REPLAY',
  'HURACAN SUPERMARKET',
  'IMAGINATION STATION',
  'THE FOOD EMPORIUM',
  'SILVER STOPE',
  'NEW SEASONS MARKET',
  'INSTACARTGIANT FOOD',
  'TH ST BEAUTY TOWN',
  'TAQUERIAS ARANDAS',
  'SLS BRICKELL',
  'NEWWAY SUPERMARKET',
  'HOMETOWN MARKET',
  'JUBILEE MARKETPLACE',
  'SUTHERLAND LUMBER',
  'SUPER SHOE STORES',
  'IGA HOUCHENS',
  'SMOKE SHOP V',
  'MALONES',
  'LA TIENDA THRIFTWAY',
  'PUB PIZZA',
  'SMOKEY BONES',
  'DOC POPCORN',
  'SUNNYBROOK TAVERN',
  'STONEWALL KITCHEN',
  'HUSH PUPPIES',
  'LA BODEGA',
  'NUMERO UNO MARKETS',
  'SONOMA GRILL',
  'MAMAS COCINA LATINA',
  'Q O NAILS',
  'SPEEDEE MART',
  'STATELINE TOBACCO',
  'SOUTH SHORE PLAZA',
  'HOMETOWN IGA',
  'SPRINT FOODS',
  'MARTINS',
  'STAR TOBACCO',
  'PAPPASITOS CANTINA',
  'SHEILA',
  'DON MILLIES',
  'INSTACARTELEVEN',
  'INSTACARTRALPHS',
  'PIZZERIA',
  'DORSET HALL',
  'NADIA BEAUTY SUPPLY',
  'SHRIVERS PHARMACY',
  'SHORT STOP MARKET',
  'SHOOT POINT BLANK',
  'HOLIDAY HAIR',
  'HOKA ONE ONE',
  'SAKS FIFTH AVENUE',
  'PAYLESS SUPERMARKET',
  'RIVER PENNY SAVER',
  'KRIS MART',
  'QDOBA MEXICAN GRILL',
  'RENFROES FOODLAND',
  'REILLY AUTO PARTS',
  'PARK PLACE PORSCHE',
  'QUICKSTOP',
  'DOTS MARKET',
  'ROBERT FRESH MARKET',
  'SUMMIT LANES',
  'SUN TAN CITY',
  'PUA',
  'KING TACO',
  'PRIDE HOME CENTER',
  'MAUERIK',
  'QUIK STOP',
  'SENDIKS FOOD MARKET',
  'POLO RALPH LAUREN',
  'HAIR GALLERY',
  'KING CRAB',
  'RAFFERTYS',
  'SUPER C MART',
  'SOBEYS',
  'PURE GAS',
  'SNOWS FAMILY MARKET',
  'ST MARYS APOTHECARY',
  'STEHLY FARMS MARKET',
  'SUPER DOLLAR CENTER',
  'PETSENSE GROVETOWN',
  'PLUCKERS WING BAR',
  'PIN BOWLING LOUNGE',
  'QFC FUEL',
  'TALPA SUPERMERCADOS',
  'HAIR CUTTERY',
  'THE MERRY GOLDFINCH',
  'THE PALM RESTAURANT',
  'SUPER G MART',
  'POTETZ HOME CENTER',
  'JANS SMOKE SHOP',
  'TOBACCO VAPE CENTER',
  'QUALITY QUICK STOP',
  'RED RACKS',
  'HABIGER WEST',
  'TEHARU SUSHI',
  'RAINDEW TRUE VALUE',
  'JANSSENS MARKET',
  'JAYS ELBOW ROOM',
  'PEARLS KOREAN BBQ',
  'PARKROSE HARDWARE',
  'JJS GRILL',
  'SPECS',
  'ULTA',
  'WALMART SUPERCENTER',
  'RAMONA VILLAGE MKT',
  'TELCO STORES',
  'EL RANCHERO',
  'YOUNG BEAUTY SUPPLY',
  'ZIP THRU DRIVE THRU',
  'ACAPULCO RESTAURANTS',
  'AMERICAN RANCH HOUSE',
  'ROBIES FOOD CENTER',
  'TEXAS BURGER',
  'KELLERS DRIVEIN',
  'PAPA BEARS MARKET',
  'APPLES GROCERY STORE',
  'ODYSSEY FUN WORLD',
  'GATTIS PIZZA',
  'JEWELOSCO',
  'MELROSE',
  'TEXAS THRIFT',
  'THE I MARKET',
  'ROYS GENERAL STORE',
  'ENERGY PLUS',
  'QUICKWAY',
  'NORTHBOUND LIQUOR',
  'BESTWAY SUPERMERCADO',
  'RUBIOS RESTAURANTS',
  'THE MAD CRAB',
  'NECTAR BURLINGAME',
  'BRIDGE STREET MARKET',
  'SAM MOON ARLINGTON',
  'THE PET ZONE',
  'EUROGROCERY',
  'MIYABI JR EXPRESS',
  'FOOD PYRAMID',
  'MIT FINE CAR WASH',
  'RIO MAMBO',
  'THE SKI BARN',
  'MILES SUPERMARKET',
  'SHOPPER VALUE FOOD',
  'TISUN BEAUTY',
  'KVAT FOOD STORE',
  'FACE VALUES',
  'FAMILY FARE',
  'JC MARKET',
  'JAVA LOCO',
  'CENEX HARVEST STATES',
  'CHERRY STREET MARKET',
  'CHURUBUSCO SAV U MOR',
  'MIKYS BEAUTY WIGS',
  'MIDWEST PETROLEUM',
  'METROPLEX LIQUORS',
  'TOBACCO KING',
  'COUNTY SQUARE MARKET',
  'SL SAUSAGE COMPANY',
  'LEWIS FOOD TOWN',
  'MARKETVIEW LIQUOR',
  'CROSSOVER TRUE VALUE',
  'TOBACCO LAND',
  'AMPM',
  'LIQUOR AND WINE',
  'DAVIDS CATFISH HOUSE',
  'MANGO SUPERMARKET',
  'HYVEE GAS',
  'DICKEYS BARBECUE PIT',
  'DOWNTOWN CONVENIENCE',
  'AGWAY',
  'MIDTOWN',
  'ROSE STOP',
  'TOBACCO MART',
  'STREET',
  'FARMER JOHN',
  'EASTSIDE MARKETPLACE',
  'LOWES SUPERMARKET',
  'EXPRESS LANE',
  'LOTLESS CLOSEOUTS',
  'FLEX MARKET AND DELI',
  'FOODS OF ALL NATIONS',
  'SPRINT FOOD STORES',
  'LONGHORN ICEHOUSE',
  'EXOTIC VIDEO',
  'EL FORASTERO',
  'TOBACCO PLUS',
  'FASHION TEN',
  'ROW HOUSE',
  'TOWN COUNTRY',
  'LUCYS LOVE SHOP',
  'GLORIA JEANS COFFEES',
  'TRADING POST',
  'ROWES IGA',
  'GYUKAKU JAPANESE BBQ',
  'TRAVEL PLAZA',
  'SUPERIOR MEAT FISH',
  'MALLARD MARKETS',
  'MAXS OYSTER BAR',
  'LA TAPATIA MARKET',
  'TRIPP SUPPLY',
  'DOLLAR SMART',
  'S S FOODS',
  'YOUNG HAIR BEAUTY SUPPLIES',
  'TROY BURGERS',
  'TAQUERIA RICO TACO',
  'MCX CORE BRANDS',
  'DOLLAR MANIA',
  'I C DELI MINI MARKET',
  'IMPERIAL SUPERMARKET',
  'JPS SPIRITS WINES',
  'JOE CANALS LIQUOR',
  'AMSCOT',
  'MILLERS',
  'JAVIERS CENTURY CITY',
  'KAHOOTS PET PRODUCTS',
  'KENNEDY SPACE CENTER',
  'KEY FOOD MARKETPLACE',
  'UNCLE JULIOS',
  'THE CURIOUS PALATE',
  'STUDIO',
  'METRO CAF DINER',
  'DGX COLUMBUS',
  'LAKEHILLS TRUE VALUE',
  'THISTLEDOWN RACINO',
  'UNIFORM CITY',
  'J J PACKAGE STORE',
  'TIDE FORT MYERS FL',
  'URBAN BEAUTY',
  'HOGNBONES',
  'LIBERATO FOOD MARKET',
  'LITTLE GENERAL STORE',
  'LUMMI SEAFOOD MARKET',
  'INSTACARTSHOPRITE',
  'SAVE ALOT',
  'URBAN MARKET',
  'TOBACO SUPER STORE',
  'VALUE BEAUTY',
  'BISCUITVILLE FRESH SOUTHERN',
  'COUNTRY DO IT BEST HARDWARE',
  'RECHARGE',
  'MI PUEBLO FOODS',
  'MARBLE SLAB CREAMERY',
  'HUCKS GAS STATION',
  'HOLLYWOOD LIQUORS',
  'CULVER DRIVE',
  'MERRICK FARM COUNTRY',
  'MOORES VENTURE FOODS',
  'HINTON TRUE VALUE',
  'HILLS FOOD STORES',
  'HICKORY ROOTS BBQ',
  'VIRGINIA ABC',
  'TONYS LIQUOR STORE',
  'FOOD CUTLET',
  'PEPPERS FRESH MARKET',
  'TU CASA RESTAURANT',
  'APPLES',
  'MACS',
  'HANA WORLD MARKET',
  'HAIR ZONE',
  'MR WASH',
  'SAVESMART',
  'TRIO',
  'RAVENSWOOD FOOD FAIR',
  'REDROCK CANYON GRILL',
  'REHAB BURGER THERAPY',
  'VIVA BURRITO',
  'UNITED SUPERMARKET',
  'LUCKY',
  'RICHMOND',
  'SAKN SAVE FOOD STORE',
  'SEREEN BEAUTY SUPPLY',
  'SHOOTERS OF COLUMBUS',
  'SHOPFAIR SUPERMARKET',
  'VONS SPIRITS',
  'ST JOHN PHARMACYNOVI',
  'VALLEY SUPERMARKET',
  'FOOD SHOPPE',
  'CITYLUXE PTE',
  'CHUBBYS CAFE',
  'HAIR BUZZ',
  'SUPER CARNICERIA LOA',
  'VALUE FRESH MARKET',
  'VERONS SUPERMARKET',
  'MR AND MRS CRAB',
  'H AND J WAREHOUSE',
  'NAUGHTY',
  'VISTA URBAN MARKET',
  'GREENUP FOOD FAIR',
  'WESTERN MEAT BLOCK',
  'SHOE DEPT',
  'MYSTIC AQUARIUM',
  'FOOD SOURCE',
  'GRISTEDES',
  'THOROUGHBRED SPIRITS',
  'TORSO LEATHER FETISH',
  'CHICKEN KING',
  'GEORGETOWN MARKET',
  'CAPTAIN JAYS',
  'WHITIES PETS',
  'WILLEY FARMS',
  'NATHANIEL PLACE',
  'FORTUNE COOKIE II',
  'CANAAN FUELS',
  'EXPRESO',
  'VALLEY MARKET THAYNE',
  'NAVARRO',
  'SHOP FAIR',
  'FOOR CONWAY',
  'FOOD TOWN ANTOINE',
  'VISTA QUALITY MARKET',
  'FLASH FOODS STORE',
  'CADES MARKET',
  'GIOVANNIS',
  'WINE CHATEAU',
  'YE OLDE DURTY BIRD',
  'WINE COUNTRY',
  'WINEGARS ROY',
  'RONS IGA',
  'FRESH FARMS',
  'FAST TRAC CARWASH',
  'FANTASTIC BURGERS',
  'ADAS NATURAL MARKET',
  'WINGS N MORE',
  'GIORDANOS',
  'EXPRESS FOOD MART',
  'PAKASAK',
  'SHOPNSAVE',
  'NEVADA PICAPART',
  'BELLAS MARKET WIGGINS',
  'EL MERCADO FRESCO',
  'BEVERAGE DISTRIBUTORS',
  'ARONS KISSENA FARMS',
  'ASTOR WINES SPIRITS',
  'DUVALL TRUE VALUE',
  'SMILE GAS',
  'ZANZIS PIZZA',
  'NIELSENS MARKET',
  'ROO MUGS',
  'NUTRITION SMART',
  'CANES CHICKEN FINGERS',
  'CAPITOL CENTRE MARKET',
  'CARIBBEAN SUPERMARKET',
  'DOWNSTREAM QSTORE',
  'FUNNYS II',
  'SPOT',
  'CASTO TRADING COMPANY',
  'CHAGRIN WINE BEVERAGE',
  'BAKERY THRIFT STORE',
  'DAVE WONGS RESTAURANT',
  'J I',
  'FUDDRUCKERS',
  'BISCUITVILLE',
  'DISCOUNT BEVERAGE',
  'APEX',
  'AM PM',
  'LUKES',
  'DONELANS SUPERMARKETS',
  'EDDIES OF ROLAND PARK',
  'BEIJING MART',
  'EL PASO MEXICAN GRILL',
  'DESERT PET CENTER',
  'AVENUE',
  'PANDORA',
  'STATE RDA',
  'ZIPS DRIVEIN',
  'GLASGOW IGA',
  'ORIGINAL TOMMYS',
  'EL PATO',
  'FAIR SHARE FINE FOODS',
  'D J BEAUTY SUPPLY',
  'FISH BONES RESTAURANT',
  'FOLLOW US ON FACEBOOK',
  'CRUZIN DRIVE THRU',
  'ZOES KITCHEN',
  'BEAUTY QUEEN',
  'GABRIELS WINE SPIRITS',
  'CREATURE COMFORTS',
  'BEFRESH SUPERMARKET',
  'PALMS PIZZERRIA',
  'BELLSTORES MARATHON',
  'BENS BARGAIN CENTER',
  'PEPPERIDGE FARM',
  'BEAUTY MECCA',
  'STUFF ETC',
  'ALL PETS CLUB',
  'HERITAGE DAIRY STORES',
  'BILO FOODS PHARMACY',
  'ALWAYS MARKET',
  'PHILLIPS YESWAY',
  'WICHITA FALLS ENTERTAINMART',
  'HOME HARDWARE VARIETY',
  'HOULIHANS RESTAURANTS',
  'INSTACARTTOPS MARKETS',
  'COBCO ENTERPRISES',
  'BAUMANNS IGA',
  'AMERICAN GIRL',
  'BOONES BUTCHER SHOP',
  'CITY ACRES MARKET',
  'BREAUXS MINUTE MART',
  'BASICS',
  'KJS BEAUTY SUPPLY LLC',
  'L T FAMILY RESTAURANT',
  'CIRCLE M CRAWFISH',
  'LAWRENCES FISH SHRIMP',
  'CHOO CHOO BARBQUE',
  'SULLIVANS',
  'APOLLO MARKET',
  'BUDWEYS SUPERMARKET',
  'BULLDOG LIQUOR WINE',
  'FOOD MART',
  'LUCKYS BEVERAGE WORLD',
  'CHICOS SMOKE SHOP',
  'MEAT HOUSE OF ST PETE',
  'SUN FOODS',
  'ARDENS GARDEN',
  'CALIBOMBS BURGERS',
  'ARK ENCOUNTER',
  'PICNPAC',
  'CAIRO WINE LIQUOR',
  'AUBURNS MAIN',
  'RED X',
  'ARLANS MARKET',
  'CATTREZ ENTERPRISES',
  'ATLANTA BREAD',
  'SUNNILAND',
  'PITT COUNTY ABC',
  'SALON SL',
  'GOCHICKENGO',
  'PLAINVIEW DINER',
  'MONTERREY SUPERMARKET',
  'MYSTIC ISLANDS CASINO',
  'NATIONAL CONEY ISLAND',
  'BULLDOG ALE HOUSE',
  'BUFFALO WING STAR',
  'BUECHE FOOD WORLD',
  'ASHVILLE IGA',
  'PATS LIQUOR LEAF WINE',
  'PHIL GASBARRO LIQUORS',
  'ANGIES PLACE',
  'PINKIES LIQUOR STORES',
  'BRAYMILLER MARKET',
  'AUSTINS SHOES',
  'AW RESTAURANT',
  'AMIGO',
  'BEAUTY',
  'PUBLIX COSNERS CORNER',
  'SUPER SAV',
  'BARGAIN WORLD',
  'CISMONT MARKET DELI',
  'CLARKDEVON HARDWARE',
  'BLACK EYED SALLYS',
  'SUPERVALU',
  'COMPARE SUPERMARKET',
  'BEST WINE SPIRITS',
  'BEALLS OUTLET',
  'POPEYES',
  'ADAM J BELLO',
  'CRICKET',
  'REBEL',
  'TA CANIJO',
  'BEAUTY CENTER',
  'CULEBRA MEAT MARKET',
  'DARIMART STORES INC',
  'SBARRO ITALIAN EATERY',
  'SCHILDS IGA FOODLINER',
  'SHERIDANS SUPER FOODS',
  'SHOP RITE SUPERMARKET',
  'BAYVIEW CARRY OUT',
  'STEVENS FINE FOOD III',
  'DASH IN FOOD STORES',
  'DAVES COUNTY MARKET',
  'SUNSHINE SUPER MARKET',
  'SUPER SAVE SUPERMARKT',
  'SUPER SAVER COST PLUS',
  'BELAIR',
  'PORTERS',
  'TH CHESTER GREAT VALU',
  'BEAUTY OUTLET',
  'DETROIT COCO BEAUTY',
  'BELLONI FOODS',
  'TACO TICO',
  'TIMMY CHAN RESTAURANT',
  'ASHLAND FOOD FAIR',
  'ARIZONA LAST STOP',
  'FERN PARK',
  'BETTY BURGERS',
  'EL COMAL RESTAURANT',
  'AMISH MARKET WEST',
  'ALOHA KITCHEN BAR',
  'Y V JEWELRY',
  'POSADOS',
  'BIG J BURGERS',
  'ENTERPRISE RENTACAR',
  'BIKE AND HIKE',
  'BLAIRS POWELL',
  'FERGUS LIQUOR STORE',
  'WALLYS BURGER EXPRESS',
  'WHEATSVILLE FOOD COOP',
  'ALKO DISTRIBUTORS',
  'ALIN PARTY SUPPLY',
  'AJ FISH AND WINGS',
  'WILD GINGER',
  'QMART',
  'ROBS',
  'ANGKOR WINE AND LIQUOR',
  'FLUSHING MARKET INC',
  'AMURA',
  'BEVMAX',
  'POSTNET',
  'BIG ROCK CANDY KITCHEN',
  'TEN BELOW',
  'BLOOMINGDALES',
  'FRANZ FAMILY BAKERY',
  'BOSKIS MARKET',
  'WIGS BEAUTY',
  'ELEVENCOM',
  'BROOKSHIRES PHARMACIES',
  'TERSTEEGS',
  'BOSTON MARKET',
  'WHO DAT NAIL SPA',
  'WHITES FOODLINER',
  'WALTS HOBBY',
  'BOUDIN BAKERY',
  'TESORO GO',
  'BRAVO BURGERS',
  'HALAL INTERNATIONAL',
  'THAI DELI',
  'HILL H G FOOD STORE',
  'CARSON IVY TROOP STORE',
  'W M GROCERY',
  'CHURCHS',
  'WABAH FOOD COURT',
  'VISTA FOODS',
  'CLEAN GETAWAY CAR WASH',
  'EDO JAPAN',
  'COMMUNITY SUPER MARKET',
  'TRACY FOOD PRIDE',
  'BROOKLYN PARK',
  'BEYOND',
  'THE CATCH',
  'BROOKS LIQUOR',
  'DUTCH WAY',
  'PILOT',
  'HOOTERS FIESTA MALL',
  'BUCA DI BEPPO',
  'THE STORE',
  'BURGER STREET',
  'EAST PLATTE AV LIQUORS',
  'EXPRESS TOBACCO OUTLET',
  'USA TOBACCO',
  'THORNTON PACKAGE',
  'THE TURNIP TRUCK',
  'USA FUELING',
  'INSTACARTFRED MEYER',
  'PYBURNS',
  'THE WHARF',
  'JESSICAS RESTAURANT',
  'BUYBUYBABYCOM',
  'JOLLIBEE RESTAURANT',
  'C BAKERY CAFE',
  'BM GAS',
  'GOLDEN STAR RESTAURANT',
  'GREEN PARROT GIFT SHOP',
  'THE SMOOTHIE BAR',
  'URBANE CAFE',
  'THE SERPENTARIUM',
  'UNIQUE MART',
  'THE PARTY SOURCE',
  'THE MARKET PLACE',
  'UNION MILLS',
  'THRIFTWAY',
  'CENTRAL MEATS',
  'KATE SPADE NEW YORK',
  'KING WOK RESTAURANT',
  'JUNGLE FALLS GIFT SHOP',
  'CHAPEL BEAUTY',
  'LA SUPERIOR MERCADO',
  'LAVALETTE FOOD FAIR',
  'TOP VALUE',
  'TWICE DAILY',
  'CHARHUT',
  'RITZ',
  'TIA',
  'LA PALOMA DE MICHOACAN',
  'LA TAPATIA MEAT MARKET',
  'CHELSEA VIDEO',
  'LIQUOR DEPOT HEBRON',
  'CHERRY VALLEY',
  'CHINA WAN WAN',
  'LO SANO RESTAURANT BAR',
  'MACEYS SPANISH FORK',
  'MEADOWBROOK SPIRITS',
  'BORICS',
  'TRADEHOME',
  'TERRYS FOOD MART',
  'TRAVEL MART',
  'MAGGIANOS LITTLE ITALY',
  'TEDDYS LUNCH BOX',
  'MCALESTER TRAVEL PLAZA',
  'MEATS SUPERMARKET MORE',
  'MORTONS GOURMET MARKET',
  'MORTONS THE STEAKHOUSE',
  'CITY BARBEQUE',
  'MIGUELS RESTAURANTE',
  'U PULL IT',
  'COUNTRY STORE',
  'NEIGHBORHOOD MINI MART',
  'TOBACCO HUT',
  'CENTURY',
  'CROWN BURGERS',
  'NAIL SUPPLY GLAMOUR',
  'NEIGHBORHOOD MARKET',
  'NICKS PIZZA SEAFOOD',
  'SUPER SAVE FOODS',
  'PEONY KITCHEN BELLEVUE',
  'PIGGLY WIGGLY SARALAND',
  'POTBELLY SANDWICH SHOP',
  'QUEEN KAAHUMANU CENTER',
  'OHKAY TRAVEL CENTER',
  'PACIFIC SUPERMARKET',
  'BECKS',
  'BUBBAS',
  'RONS CARIBBEAN CUISINE',
  'REGINES',
  'RICKERS',
  'VACANTEEN',
  'DANICE STORES',
  'THRIFTSMART',
  'DAGOSTINO',
  'S P HOT POT RESTAURANT',
  'SARPINOS CORAL SPRINGS',
  'SHIVERS FROZEN CUSTARD',
  'THOMSON IGA',
  'SJ FOOD AND LIQUOR INC',
  'SPEEDWAY SUPER AMERICA',
  'PARKERS GAS STATION',
  'DANS FOOTHILL',
  'D GROCERY',
  'DANS TH SOUTH',
  'POPULAR SUPERMARKET',
  'THE GATEWAY AT DETROIT',
  'PREAUTH TRANSACTION',
  'VONS FUEL',
  'QUINNS SUPER MARKET',
  'REASORS FOOD STORES',
  'THE DRIVEIN',
  'THIS IS IT BBQ SEAFOOD',
  'TIFFANYS BEAUTY SUPPLY',
  'TJ CAJUN SEAFOOD WINGS',
  'TOBACCO KING VAPE KING',
  'VP RACING',
  'DELTA EXPRESS',
  'DIEGOS MARKET',
  'ROLLNUP',
  'TRADE FAIR SUPERMARKET',
  'VISALIA RESCUE MISSION',
  'SAVERS COST PLUS',
  'TACO PRONTO',
  'WAVE WASH',
  'DIRECTOR PARK',
  'DISCOUNT DRUG',
  'SAV MOR FOOD OUTLET',
  'WEBER GRILL RESTAURANT',
  'WILTON DISCOUNT LIQUOR',
  'SALVO AUTO PARTS',
  'TACO FRESCO',
  'CR MARKET',
  'SBARROS PIZZA OCALA',
  'WHITE HUT',
  'YOUR BUSINESS RESOURCE',
  'EDDIES LIQUOR',
  'SHANGHAI TOKYO CAFE',
  'ALASKAN PROUD KETCHIKAN',
  'ANIMAL HOUSE PET CENTER',
  'T T GROCERY',
  'SKY ZONE CHARLESTON',
  'WINNDIXIE',
  'EDDIES MARKET',
  'EDGEWATER REC',
  'RESTAURANT DEPOT',
  'RED APPLE MARKET',
  'RALPHS FOOD FAIR',
  'SUSHI SEVEN',
  'CARVEL',
  'SAVINGS',
  'WOODCRAFT',
  'EL GALLO GIRO',
  'STANS QUALITY MEATS',
  'STEVES APPLE MARKET',
  'WUS HOUSE',
  'EL RINCONSITO',
  'GOLDFINGERS',
  'BIG APPLE PANCAKE HOUSE',
  'BOROUGH BROOKLYN MARKET',
  'BOYERS FOOD MARKETS INC',
  'BROOKLYN HARVEST MARKET',
  'BROOKSHIRES FOOD STORES',
  'SUPER GIANT',
  'PRO IMAGE SPORTS',
  'COSMOPROF',
  'CARTERS BABIES AND KIDS',
  'COOK BROTHERS WAREHOUSE',
  'EL RIO GRANDE',
  'XO LIQUOR',
  'EL TORO BRAVO',
  'TARLTON IGA EXPRESS',
  'DOLLAR VILLAGE AND MORE',
  'ELYRIA APPLES',
  'ZOOM THRU',
  'FALLOUT GAMES',
  'TO MARKET TO MARKET',
  'POLLARDS CHICKEN',
  'COPY COPY',
  'ELMSFORD WINE WAREHOUSE',
  'A G LIQUOR',
  'ACE TICKET',
  'FDA WHITE OAK',
  'TOWN COUNTRY MARKET',
  'PLANET NUTRITION',
  'FRESH MARKET EAST OGDEN',
  'PIGGLY WIGGLY SC',
  'PETS ON BROADWAY',
  'VALUE CENTER MARKET',
  'FLIPPIN PIZZA',
  'VILLAGE MARKET EAST',
  'FOODLAND FUEL',
  'GO CALENDARS GAMES TOYS',
  'PE ELL MINI MART',
  'PATUXENT NURSERY',
  'PARKERS BARBECUE',
  'STAR BEAUTY',
  'ACES PLACE',
  'FRISKY WHISKY',
  'INSTACARTSTRACK VAN TIL',
  'WHITE BUFFALO FOODS',
  'FULL MOON BBQ',
  'JIMMY JAZZ FAIRLANE LLC',
  'LA CHIQUITA SUPERMARKET',
  'SPEEDY CAFE',
  'AGGIE STOP',
  'GALLERIA MART',
  'GAME X CHANGE',
  'COSTCO',
  'MAIN STREET WINE SPRITS',
  'MCCLURE OIL CORPORATION',
  'ONSITE RETAILERS',
  'ONO HAWAIIAN BBQ',
  'SLATERS',
  'AUTO PARTS',
  'NO CAHS REFUND EXCHANGE',
  'GENX CLOTHING',
  'ACME FRESH MARKET NO',
  'NEN TACO EXPRESS',
  'SINGLE STOP',
  'CHOW MEIN',
  'ADMIRAL PETROLEUM CO',
  'AMERICAN LEGION POST',
  'GIVORNS FOODS',
  'ANGIES BEAUTY SUPPLY',
  'PALLOTTAS ITALIAN GRILL',
  'PARTY WORLD CORPORATION',
  'NC BEAUTY OUTLET',
  'MRS FIELDS GIFTS',
  'MOOKIES BEVERAGE',
  'P F I',
  'GOLDEN MARKET',
  'BADGER GAS',
  'GREEK EXPRESS',
  'ARTEAGAS FOOD CENTER',
  'RICKYS DISCOUNT LIQUORS',
  'SEA ISLAND SHRIMP HOUSE',
  'GREERS MARKET',
  'AV LOPEZ SUPERMARKET',
  'BAG OF BONES BARKERY',
  'BANTAM CHEF OF UNION',
  'SHOWMARS NORTHLAKE MALL',
  'SAVON DRUGS',
  'SPARTA DISCOUNT TOBACCO',
  'SPOILED ROTTEN BOUTIQUE',
  'BAJA FRESH',
  'GUITAR CENTER',
  'METRO BY TMOBILE',
  'HANYS LIQUORS',
  'BELL STORES MARATHON',
  'SUNFLOWER FOOD STORE NO',
  'MCKINNONS MARKET',
  'MARKET OF CHOICE',
  'MAGIC VAPOR SHOP',
  'HAR MART CORP',
  'BLAND MANAGEMENT INC',
  'BREAUX MART MAGAZINE',
  'BEAUTY MAX',
  'LUXOR FOOD COURT',
  'THE SLICED BREAD MARKET',
  'LUCKY CALIFORNIA',
  'LINDLEYS GROCERY',
  'TOWN COUNTRY LIVINGSTON',
  'HARRY BUFFALO',
  'BUDS CHICKEN SEAFOOD',
  'WILLIAM JEFFREYS TAVERN',
  'BELLACINOS',
  'HENHOUSE CAFE',
  'ABERDEEN BARN STEAKHOUSE',
  'LAKEVIEW GROCERY',
  'BAR LOUIE LEGACY VILLAGE',
  'HOBBYTOWN USA',
  'CROSBY',
  'TADYCHS',
  'BENTO CAFE',
  'ROCKET FIZZ',
  'CARD DE A',
  'BOZEMAN ASSOCIATED FOODS',
  'BUYRITE LIQUORS OF UNION',
  'HOLIDAY FOODS',
  'CHATEAU STE MICHELLE',
  'KENTS CLEARFIELD',
  'CALANDROS',
  'BLIMPIE',
  'HUNGER BURGER',
  'IGA PRINCETON',
  'CHILDRENS OF ALABAMA',
  'IN OUT MARKET',
  'BESTBUYCOM',
  'CLASSY BEAUTY SUPPLY',
  'REVD UP FUN',
  'DEALZ DISCOUNT WAREHOUSE',
  'JDS SUPER MARKET',
  'EAST JAPANESE RESTAURANT',
  'JACKS ONE DOLLAR',
  'NAPA',
  'CLUBHOUSE FUN CENTER',
  'COLLEGE GREEN LIQUOR',
  'BGS MARKET',
  'COME BACK AND SEE US',
  'FORKED VINE WINE SPIRITS',
  'FRESH MARKET EAST LAYTON',
  'GARRETTS IGA SUPERMARKET',
  'INSTACARTFRYS',
  'CRUNCH',
  'BLACKHEART',
  'J R FOOD MART',
  'GERRITYS SUPERMARKET INC',
  'INSTACARTWEGMANS',
  'GOLDEN FARMS SUPERMARKET',
  'GREENACRES PACKAGE STORE',
  'CRAFT TAPHOUSE GRILL',
  'CRESCENT CITY COMICS',
  'QUICK STORE',
  'BIG SMOKE',
  'HOT N JUICY CRAB',
  'BRANCH OUT',
  'JENSENS FOODS',
  'PUMP N SHOP',
  'HONG KONG ISLAND',
  'DELAWARE NEWS CENTER',
  'BRIDGEPORT',
  'JOHNS BURGERS',
  'DONG MAI SUPERMARKET',
  'BIG JOHNS',
  'BIG BOY',
  'JJS GRUBB SHACK N LIQUOR',
  'LOUIS PAPPAS FRESH GREEK',
  'MAIN STREET DELI GROCERY',
  'JOY YEE SOUTH',
  'JR FOOD STORE',
  'POLLO REGIO',
  'DUNKIN BASKINROBBINS',
  'EARLYBIRD LAUNDROMAT',
  'MARKET FRESH SUPERMARKET',
  'MONARCH II BEAUTY SUPPLY',
  'PIZZA BOLIS',
  'BERTUCCIS',
  'NEW YORK FRENCH CLEANERS',
  'BD',
  'KAY',
  'CHEC',
  'CITI',
  'PHILLY STYLE STEAKS SUBS',
  'PYBURNS FARM FRESH FOODS',
  'HDOS SANTA ANITA',
  'HARRISONS MARKET',
  'DAIRIO',
  'WATSEKA',
  'RAWSONS DEPARTMENT STORE',
  'JUSTAVE FOODS',
  'KELLY LIQUORS',
  'PILOT STORE',
  'EL RANCHO MARKET IGA',
  'FAV TRIPINDEPENDENCE',
  'FEI LONG SUPERMARKET',
  'KELLYS MARKET',
  'NORTH',
  'RIDGWAY ELK COUNTY FOODS',
  'ROUND ROCK BEAUTY SUPPLY',
  'SIMPLY COMFORT BY DESIGN',
  'FESTIVAL SUPERMARKET',
  'KILLER BURGER',
  'STINE HOME YARD JENNINGS',
  'KIND MEDS INC',
  'KINGSTREE IGA',
  'SUMTER IGA PLUS WESTMARK',
  'GOODWILL OF NORTH GEORGIA DOUGLASVILLE STORE DONATION CENTER',
  'BELLS IGA',
  'SWEET BERRY FRESH MARKET',
  'SWEETWATER PACKAGE STORE',
  'GEN KOREAN BBQ HOUSE',
  'GK CAFE AND CATERING',
  'KNOTTY BARREL',
  'GLORIA BEAUTY SUPPLY',
  'GREEN ACRES FARM',
  'PET SUPREME',
  'GREAT WOLF LODGE',
  'UNIVERSAL ORLANDO RESORT',
  'LIQUOR LOCKER',
  'CAP N CORK',
  'VALUE FRESH MARKET PLACE',
  'CAPTAIN DS',
  'LONG TIME AGO',
  'WEBERS IGA FOOD AMP DRUG',
  'GOODWILL EVERETT',
  'PERRY ELLIS',
  'BALTIMORE',
  'GOODWILL SUPER STORE',
  'GUERRERO MEAT MARKET',
  'LUPE TORTILLA',
  'H G HILL FOOD STORES',
  'ACE HARDWARE ENKA CANDLER',
  'ALL FIRED UP SANDY PLAINS',
  'ARCHER BEAUTY SUPPLY WEST',
  'HEIGHTS FRESH MARKET',
  'CASH CARRY',
  'BAKER CREEK HEILOOM SEEDS',
  'BOMBSHELLS RESTAURANT BAR',
  'AR',
  'BROWARD MEAT FISH COMPANY',
  'BROWARD MEAT FISH GROCERY',
  'BUCKYS CONVENIENCE STORES',
  'US',
  'HOOTERS OF CORAL WAY',
  'HORROCKS FARM MARKET',
  'PAYLESS IGA',
  'HOT CHICKEN TAKEOVER',
  'CLOUD SMOKE VAPE MARIETTA',
  'GATEWAY NEWSTAND',
  'COOKIES DEPARTMENT STORES',
  'LYNNE',
  'HUDSON COUNTY MARKET',
  'PAULS PLACE',
  'DINOS CHICKEN AND BURGERS',
  'SAVEMART',
  'GALA FRESH FARMS',
  'PATRIOTBREW',
  'FRESH FOODS IGA CHADBOURN',
  'GREAT STOPS',
  'RALPHS PHARMACY',
  'HUNGRY LIKE THE WOLF',
  'DAVANS',
  'GIBBS TRUE VALUE HARDWARE',
  'WEGMANS',
  'RANDOLPH MARKET',
  'CHOCOLATEA',
  'GUMPS SHOP N SAVE EXPRESS',
  'GOODWILL OF NORTH GEORGIA ROSWELL STORE AND DONATION CENTER',
  'JOHN HERR VILLAGE MARKET INC',
  'PUBLIX SUPER MARKET AT THE SHOPPES AT REDSTONE SQUARE',
  'MICKEYS GYROS',
  'APPLETREE',
  'MR DS',
  'MRCH',
  'STK',
  'PUBLIX PHARMACY AT COBBLESTONE VILLAGE ST AUGUSTINE',
  'RAINBOW BLOSSOM NATURAL FOODS MARKET MIDDLETOWN',
  'HUEY MAGOOS CHICKEN TENDERS GARDENS ON MILLENIA',
  'JIM N NICKS COMMUNITY BBQ',
  'JOJACKS ESPRESSO BAR CAFE',
  'KEGLERS SPORTS BAR LOUNGE',
  'KHIMS MILLENIUM MARKET VI',
  'KRIEGERS HEALTH FOODS MARKET',
  'LA RAMADA MEXICAN RESTAURANT',
  'FUN SLIDES CARPET SKATEPARK AND PARTY CENTER',
  'IMAGES BEAUTY SUPPLY',
  'INSTACARTSMART FINAL',
  'MISSION FOODS',
  'JAMESTOWN TRUE VALUE',
  'MODERN MARKET',
  'LUCKY SEVEN GENERAL STORE',
  'GOODWILL INDUSTRIES OF SOUTHERN ARIZONA',
  'MOJOMAN SWIMWEAR CLOTHING',
  'BRIDGEWATERS CAR WASH AND DETAIL CENTER',
  'SUNNYSIDE CANNABIS DISPENSARY DANVILLE',
  'PAK N SAVE FOODS PHARMACY',
  'BSW ROSLINDALE BEAUTY SUPPLY WAREHOUSE',
  'UNDER ARMOUR FACTORY HOUSE ROUND ROCK',
  'WING ON',
  'SOUTHERN OREGON GOODWILL RETAIL STORE',
  'PUBLIX SUPER MARKET AT BLUE MOON CROSSING',
  'CMOG SHOPS',
  'FOOD FARM MARKET',
  'RAFFIS MARKET AND GROCERY',
  'RED ROBIN GOURMET BURGERS',
  'ROLLNUP SMOKE SHOP LIQUOR',
  'COURTYARD BY MARRIOTT SACRAMENTO CAL EXPO',
  'COAST TO COAST FURNITURE AND SLEEP CENTER',
  'MOVIE TRADING',
  'MY NAME MAKER',
  'SHOOT POINT BLANK MEMPHIS',
  'SIMONEAUDS GROCERY MARKET',
  'FIRST COMES LOVE',
  'NEDLEWERKES',
  'COLLINSVIL',
  'SUPREME LIQUOR DORCHESTER',
  'RED DEVIL PIZZA',
  'NAMASTE PLAZA',
  'THE FLAME BROILER ELMONTE',
  'SUPER FOODTOWN OF BEDFORD STUYVESANT',
  'FAMILY FARM HOME',
  'ALLSAINTS',
  'JOE RANDAZZOS FRUIT AND VEGETABLE MARKET',
  'LIBERTY TAP ROOM ON THE LAKE',
  'PANJWANI NETWORK RESTAURANTS PNR OFFICE',
  'KROGER GROCERY STORE',
  'WINGERS',
  'NACHO DADDY',
  'COMIC CITY',
  'CHICKFILA PRESCOTT VALLEY CROSSROADS',
  'LOUISIANA FAMOUS FRIED CHICKEN SEAFOOD',
  'NEIGHBORS IGA',
  'MR NICE GUY',
  'AVANTIS',
  'GRUMPY MONK',
  'BROWNS NEIGHBORHOOD MARKET',
  'CHACHOS MEXICAN RESTAURANT',
  'CHERRY VALLEY MARKET PLACE',
  'MAINE FISH MARKET RESTAURANT',
  'LA SUPERIOR MERCADOS',
  'LEES NORTH SALT LAKE',
  'EDNA S GRAB N GO',
  'ANDRETTI INDOOR KARTING GAMES MARIETTA',
  'EDISTO SURF SHOP',
  'KINGS DINING ENTERTAINMENT ROSEMONT',
  'EASTGATE LIQUORS',
  'PUBLIX SUPER MARKET AT MIDTOWN CENTER',
  'COSMIC CAF',
  'FIREBIRDS WOOD FIRED GRILL',
  'FISCHERS HARDWARE PASADENA',
  'MCDONALDS RIVERDALE MD RIVERDALE ROAD',
  'DISCOUNT TOBACCO',
  'FORT CARSON GATE SHOPPETTE',
  'FORT LEWIS NORTH SHOPPETTE',
  'GUADALAJARA',
  'MOISHAS DISCOUNT SUPERMARKET',
  'NEW FINE ARTS',
  'NEW YORK MART',
  'CREAMISTRY',
  'GALLERIA MARKET NORTHRIDGE',
  'DILLS GREENHOUSE',
  'AGAVE RYE',
  'NICKS LIQUORS',
  'HABANERO FRESH MEX KITCHEN',
  'SURTIMERCADO LA MEXICANA MEAT MARKET',
  'LUCID HEARING CENTER',
  'INSTACARTGIANT FOOD STORES',
  'ITALIANOS PIZZA PASTA SUBS',
  'KATE SPADE NEW YORK OUTLET',
  'KILLEEN NUTRITIONAL CENTER',
  'PUBLIX PHARMACY AT BELLEVIEW COMMONS',
  'CHRISTMAS TREE LOT AT THE HOME DEPOT',
  'MAGIC TOUCH CAR WASH',
  'NITE OWL NEWS',
  'MICHAELSCOM',
  'CT BEVERAGE MART',
  'MILANO MENSWEAR WOLFCHASE GALLERIA',
  'CRAVE BEER SMOKE',
  'MAIN STREET PIT STOP',
  'MI CASA MEXICAN RESTAURANT',
  'NEW ORLEANS FOOD AND SPIRITS',
  'NORTHERN TOOL',
  'MOTOMART CONVENIENCE STORE',
  'MAGNOLIA BAKERY ROCKEFELLER CENTER',
  'KOBE JAPANESE STEAKHOUSE SUSHI BAR',
  'COSTCO WHOLESALE',
  'CHARBROIL GRILL BRAZILIAN STEAKHOUSE',
  'CAPTAIN BENNYS HALF SHELL OYSTER BAR',
  'AUSTELL INTERNATIONAL FARMERS MARKET',
  'CENEX',
  'EATALY',
  'PAULA DEENS FAMILY KITCHEN',
  'WISHCOM',
  'FOLLOW US SLUSHYWORLD ON INSTAGRAM',
  'RONNIES BURGERS',
  'DAILY STOP',
  'MEMPHIS ZOO',
  'ZIP TRIP',
  'GOODWILL INDUSTRIES TH STREET STORE',
  'RIVER OAKS SHOPPING CENTER',
  'SCOLARIS FOOD DRUG COMPANY',
  'SHANNON BEVERAGE WAREHOUSE',
  'FOOD ZONE INTERNATIONAL SUPERMARKET',
  'NUCO DISCOUNT',
  'MATHERNES RIVERLANDS',
  'TAZIKIS MEDITERRANEAN CAFE',
  'BROWARD MEAT FISH OF PEMBROKE PINES',
  'MEGA REPLAY',
  'THE ROUXPOUR MEMORIAL CITY',
  'THE SPICE AND TEA EXCHANGE',
  'ROUND THE CLOCK',
  'OCEANIC ORIENTAL SUPERMARKET',
  'WEST HAVEN DISCOUNT LIQUOR',
  'YOSHINOYA PICO SAN VICENTE',
  'SAWAYA BROTHERS',
  'CHRISFOOD CENTER',
  'MEAT MARKET',
  'YOUR PIE',
  'PORTAGE FAMIILY CHIROPRACTIC',
  'RICHIES FAST FOOD RESTAURANT',
  'MEININGER ART SUPPLY',
  'OCEANA MARKET',
  'MGM WINE AND SPIRITS',
  'OLSKOOL FLAVA',
  'BARRYS U PULL IT AUTO PARTS',
  'MAZEN FOODS',
  'MACS MARKET',
  'ALGARVE',
  'WOLFIES',
  'DENNY DENNIS SPORTING GOODS',
  'SCARLETT OHARAS',
  'OMELET SHOPPE',
  'LUCKY STORE',
  'CAROLINA KITCHEN',
  'ECONOMY TRUE VALUE HARDWARE',
  'SAINT VINCENT DEPAUL SOCIETY',
  'SCRAPPY QUILTER',
  'MR KIMCHI KOREAN BBQ',
  'NICHOLS DOLLAR SAVER',
  'LUCKY STEER',
  'GARFIELD HTS LICENSE BUREAU',
  'GRUBS BAR GRILL LITTLE ROCK',
  'WEST NORRITON FARMERS MARKET IGA',
  'CARHARTT MEDFORD',
  'DISTILLING',
  'BUMPER TO BUMPER',
  'SCRATCH KITCHEN',
  'ORME HARDWARE',
  'BUEHLERS BUY LOW',
  'LOVERS LANE',
  'WAYFIELD',
  'CALLAHANS TRUE VALUE GENERAL STORE',
  'BURTONS GRILL BAR OF SOUTH WINDSOR',
  'ATLANTA AIRPORT PARADIES LAGARDERE',
  'PALACE TACOMA',
  'ZOOMERZ',
  'NEIGHBORHOOD GRILL CATERING',
  'PANDA EXPRESS S E TH STREET',
  'DONS SUPER',
  'BRATCHERS MARKET',
  'SMOKIN JOES TOBACCO LIQUOR OUTLET',
  'PIONEER SUPERMARKETS',
  'TWO GUYS',
  'PRISCILLAS ULTIMATE EXPRESS',
  'RALPHS MARKET PELICAN POINT',
  'RED TOMATOES FARMERS MARKET',
  'PORT OCALL RESTAURANT ART GALLERY',
  'PEPES MEXICAN RESTAURANT GRIFFITH',
  'PTERRYS BURGER STAND',
  'DONUT BANK',
  'SILVER STATE RELIEF FERNLEY',
  'BAYLOR UNIVERSITY MEDICAL CENTER',
  'BLUEWAVE EXPRESS',
  'THREE BEARS ALASKA BIG LAKE',
  'MAMA THERESAS PIZZERIA RESTAURANT',
  'UNCLE BILLS PET CENTERS FISHERS',
  'JOHNNY DS RESTAURANT PIZZA PARLOR',
  'PASTA E BASTA',
  'LORA J RUIZ',
  'BGOOD NORTHGLENN',
  'BEV MAX LIQUORSS',
  'LOFT LIQUOR',
  'J STERLINGS WELLNESS SPA CLERMONT',
  'GOODWILL INDUSTRIES RETAIL STORES',
  'FRANKS FOURTH AVENUE SUPER MARKET',
  'PCS UNLIMITED',
  'AC MOORE',
  'DOWN HOUSE',
  'PARTHENON FOODS EUROPEAN MARKET',
  'LIQUOR KING',
  'CAPITAL CITY WINE AND LIQUOR',
  'NORTH CHARLES FINE WINE SPIRITS',
  'PENGUIN FUELS',
  'CHUCKS PRODUCE STREET MARKET',
  'UNCLES',
  'PETES PRODUCE',
  'MIONES PIZZA ITALIAN RESTAURANT',
  'EZ CLEAN CAR WASH OIL CHANGE',
  'FANCY FRUIT PRODUCE AND MEAT',
  'SHOPPERS MARKET',
  'VBWINGS TO GO FAIRLESS HILLS',
  'PHELPS MARKET',
  'SENDIKS FOOD MARKETS',
  'AVENUE FOOD MART',
  'TACOTOTE',
  'ACHIOTE',
  'MEMES',
  'ARC THRIFT STORE',
  'PIECE OF CAKE',
  'LESTERS LIG',
  'RIVERSIDE WINE SPIRITS WAREHOUSE',
  'CHIVE',
  'FAMILY DISCOUNT FREIGHT GROCERY',
  'AMERICAN BURGERS',
  'SUNSHINE',
  'MET FRESH SUPERMARKET WHITESTONE',
  'LOWES SUPERMARKETS LIQUOR STORES',
  'WAREHOUSE DISCOUNT GROCERIES',
  'EEGEES',
  'ADAM EVE',
  'DR MARTENS',
  'CHOMP ROCKIN SUSHI TEPPAN GRILLS',
  'SHURFINE FOOD CENTER',
  'CAREALOT PET SUPPLY INDIAN RIVER',
  'ACE CORNER HARDWARE PAINT CENTER',
  'PISTACHIOLAND',
  'POLK PIT STOP',
  'WINE SPIRITS DISCOUNT WAREHOUSE',
  'SMOKE TIME SAMS',
  'BENJAMIN MOORE CREATIVE PAINT',
  'THRIFTY SPECIALTY PRODUCE MEATS',
  'THE GREEN PLANET VAPE AND GLASS',
  'BOYERS FOOD MARKET WOMELSDORF',
  'SPRING GARDEN MARKET',
  'STROKOS GOURMET DELI',
  'ECONOFOODS',
  'PREMIER FOODS',
  'SUNFLOWER FOOD STORE',
  'OUT OF THE CLOSET WILTON MANORS',
  'SUPER FOODS PHARMACY',
  'SPARKLE MARKETS',
  'BUEHLERS CAFE AT TOWNE MARKET',
  'EL JALISCO',
  'SUPERMEAT EL TORO LL',
  'SWAINS GENERAL STORE',
  'JULIOS MEXICAN RESTAURANT HUTTO',
  'ATLANTIS',
  'STOPNSAVE FOODS',
  'CAPTAIN BRADS COASTAL KITCHEN',
  'IMPERIAL CATHAY CHINESE CUISINE',
  'EL JARIPEO',
  'TAYLORS DO IT CENTER',
  'TERRYS COUNTRY STORE',
  'CHRISTIES TOY BOX SUPERSTORES',
  'THE LITTLE STORE LLC',
  'CASA JALISCO MEXICAN RESTAURANT',
  'BLOOMINGTON TRUE VALUE HARDWARE',
  'RAINBOW FOODS',
  'THE PLAZA RESTAURANT',
  'JALON NORTH',
  'CITYVIEW CAR WASH LUBE CENTER',
  'THRIFTY LIQUOR WINES',
  'TITUSVILLE TRUE VALUE HARDWARE',
  'SPECS WINE SPIRITS FINER FOODS',
  'SONORAS MEAT MARKET RESTAURANT',
  'FARMINGTON',
  'TOWN COUNTRY BOZEMAN',
  'TULALIP LIQUOR STORE',
  'ULTIMATE PARTY STORE',
  'NATIONAL WHOLESALE LIQUIDATORS',
  'FAST TRACK',
  'LUCERNE VALLEY MARKET HARDWARE',
  'JAMBA JUICE',
  'UNITED ORIENTAL FOOD',
  'VALLEY BEAUTY SUPPLY',
  'FASTOP',
  'JONES RED AND WHITE FOOD STORE',
  'BULLSEYE',
  'HERMANS FARM MARKET CIDER MILL',
  'HEMINGWAYS CUBA RESTAURANT BAR',
  'HARRYS SEAFOOD BAR AND GRILLE',
  'FIRE WINGS',
  'FISH N PIG',
  'REYNAS MARKET',
  'JAMES AVERY',
  'VAS FOREMOST LIQUORS',
  'LA PRADERA MEXICAN RESTAURANT',
  'LOS AMIGOS MEXICAN RESTAURANT',
  'CITY FARMERS MARKET SNELLVILLE',
  'VENDOME WINE SPIRITS',
  'VICENTES SUPERMARKET',
  'TACOS EL GOLOSO',
  'SIMERLYS',
  'ESPETUS CHURRASCARIA SAN MATEO',
  'EXTREME TERIYAKI GRILL EXPRESS',
  'BGS VALUE MARKET OF PINE GROVE',
  'VIETTIEN INTERNATIONAL MARKET',
  'INSTACARTBINNYS BEVERAGE DEPOT',
  'TERRY NATURALLY',
  'MR MRS CRAB OCOEE COMING SOON',
  'GLENWOOD FOODS AT GREENCASTLE',
  'FRESH START CONVENIENCE STORE',
  'SUSHI KUCHI III',
  'LOUISIANA FAMOUS FRIED CHICKEN',
  'MEMORIAL ASSISTANCE MINISTRIES',
  'MY HOUSE TO YOURS CONSIGNMENT',
  'TRINITY ROCK ISLAND GIFT SHOP',
  'DRIKES TRUE VALUE HOME CENTER',
  'COPPER BLUES ROCK PUB KITCHEN',
  'SEVANANDA NATURAL FOODS MARKET',
  'THE BATTLEFIELD COUNTRY STORE',
  'SUTHERLANDS BUILDING MATERIAL',
  'ROUSES MARKET',
  'VILLAGE MEATS MARKET',
  'ROYAL PACKAGE',
  'ROYS FOODLAND',
  'COLMAR TRUE VALUE HOME CENTER',
  'SUPER C KILGORE',
  'SIDS IGA',
  'BROADHEAD BUILDING SUPPLIES INC',
  'DOC GREENS GOURMET SALADS GRILL',
  'SOUTHERN REFRESHMENT SERVICES',
  'CAPTAIN RAYS BUFFET AND SUSHI',
  'GOODWILL INDUSTRIES OF ARKANSAS',
  'GUSS WORLD FAMOUS FRIED CHICKEN',
  'SMOKINS JOES TOBACCO SHOP INC',
  'RUSTY PELICAN',
  'TEXAN FOOD MART',
  'OLD CHICAGO PIZZA AND TAPROOM',
  'VIVI BUBBLE TEA EDEN',
  'JANOVIC PAINT DECORATING CENTER',
  'LA SUPER CARNICERIA MEAT MARKET',
  'CAJUN BOILING SEAFOOD AND BAR',
  'STANLEYS MARKET',
  'BUBBALOUS BODACIOUS BAR B QUE',
  'ORLANDO INTERNATIONAL AIRPORT',
  'RAYS GREEN HILLS SUPER MARKET',
  'J BS GALAXY',
  'SHIPMATES RESTAURANT SPORTS BAR',
  'SHOGUN JAPANESE GRILL SUSHI BAR',
  'BRONNERS CHRISTMAS WONDERLAND',
  'RAISING CANES CHICKEN FINGERS',
  'RAGTIME TAVERN SEAFOOD GRILLE',
  'WELCOME TO LAS VEGAS',
  'SALEM SPIRITS',
  'BLAIRS HARDWARE WATERTOWN ACE',
  'QUALITY CAR WASH INCORPORATED',
  'MAMAS',
  'WINGS OVER FAIRFIELD',
  'WOODLAND TRUE VALUE HOME CENTER',
  'BRIDGES PETS GIFTS WATER GARDENS',
  'SPANGLES',
  'JJ BBAGRILL',
  'SALES RECEIPT',
  'CHAMA GACHA BRAZILIAN STEAKHOUSE',
  'FERDIES DISCOUNT WINE AND LUQUOR',
  'FOGO DE CHO BRAZILIAN STEAKHOUSE',
  'HAROLDS CHICKEN OF SOUTH HOLLAND',
  'THE HIDDEN REEF',
  'PAPA JOES GOURMET MARKETPLACE',
  'PLAYA AZUL SEAFOOD OYSTER BAR',
  'POPLAR GROVE SNYDERS PHARMACY',
  'FOOD FRESH',
  'SAM SAM MEATS',
  'OSAKA JAPENESE STEAK AND SEAFOOD',
  'ROCKY MOUNTAIN CHOCOLATE FACTORY',
  'SPAGHETTI EDDIES CUCINA ITALIANA',
  'VIET HOA INTERNATIONAL FOODS',
  'SHOPWISE MARKET',
  'HOOKS CHOPS',
  'TWIN CITY SUPERMARKET PLAINFIELD',
  'AMERICAS BEST CONTACTS EYEGLASSES',
  'DUNKIN DONUTS DRIVE THRU LOCATION',
  'THE MELTING POT',
  'QUICK KING TOBACCO AND LIQUOR',
  'SHARP SHOPPER',
  'JOE CANALS DISCOUNT LIQUOR OUTLET',
  'TORRANCE BAKERY',
  'JOES MARKET',
  'ABC LOW COST PET CARE',
  'TSUNAMI RESTAURANT SUSHI BAR',
  'NEW GRAND MART INTERNATIONAL FOOD',
  'PETES FRESH MARKET EVERGREEN PARK',
  'TOUCHDOWN WINGS',
  'ROLANDS OF CHESAPEAKE STATION',
  'SHOP FAIR SUPERMARKET OF TROY AVE',
  'SUPERSTITION RANCH FARMERS MARKET',
  'THE FRESH GROCER OF MONUMENT ROAD',
  'UNDER ARMOR FACTORY HOUSE ORLANDO',
  'SMOKER FRIENDLY CIGAR EXPRESS',
  'JUICE IT UP',
  'ADALBERTOS MEXICAN FO',
  'SHORTYS BARBQ',
  'SMOKER FRIENDLYS LIQUOR PLUS',
  'HANSENS IGA',
  'SHOOT POINT BLANK ROUND ROCK',
  'SHILLA BAKERY CAFE ANNANDALE',
  'TOWN COUNTRY NO',
  'TRADEHOME SHOES',
  'LA PLACITA DE BROOKLYN SUPERMARKET',
  'RIVER VALLEY FEED PET SUPPLY',
  'JUMBO FOODS',
  'JUST SPORTS',
  'ALLIED COMMUNICATIONS',
  'HAIR BEAUTY',
  'SHOPLESS',
  'PETES FRESH MARKET CERMAK ROCKWELL',
  'TRENTWOOD FARMS',
  'TWISTERS BURGERS AND BURRITOS',
  'PHILADELPHIA STEAK AND FRIES',
  'BEAUTIFULL BEAUTY SUPPLY STORE',
  'U GAS MACKENZIE',
  'AMERICAS THRIFT STORE',
  'SILVER SPRING',
  'SKY PLAZA IGA',
  'ELEGANT BEAUTY SUPPLIES SUPERSTORES',
  'NORTHWOODS POWER EQUIPMENT L',
  'NORFOLK HARDWARE HOME CENTER',
  'LIBERTY HEALTH SCIENCES PALM HARBOR',
  'PUBLIX PHARMACY AT CALERA CROSSINGS',
  'ANITAS NEW MEXICO STYLE MEXICAN FOOD',
  'CHECKERS DISCOUNT LIQUORS WINE',
  'DELMONICOS ITALIAN STEAK HOUSE',
  'AV LOPEZ SUPERMARKETS',
  'NEWGATE PRETZEL FRESHENS YOG',
  'KARNS FOODS',
  'USED AUTO PARTS',
  'BERKLEY MANOR C STORE',
  'ROADRUNNER CAFE',
  'H W PRODUCE',
  'SEAWORLD',
  'MAPLE STREET BISCUIT COMPANY CONCORD',
  'ST LOUIS UNION STATION SODA FOUNTAIN',
  'DOLLAR GE HWY HAMPTONVILLENCUS',
  'UTICA FOOD MART',
  'VICTORY PRODUCE INTERNATIONAL MARKET',
  'MRS WINNERS CHICKEN BISCUITS',
  'RIVERSIDE RED X',
  'V NAILS AND SPA',
  'FLOWERS BAKING CO OF TYLER LLC',
  'BODEGA R RANCH MARKET',
  'BROOKLYN WATER BAGELS',
  'NAPA AUTO PARTS GENUINE PARTS COMPANY',
  'MECKLENBURG ABC LIQUOR STORE',
  'MASON BROTHERS RED OWL STORE',
  'V T WINE LIQUOR',
  'REDENTAS GARDEN',
  'STARBUDS',
  'BUY LESS',
  'LIBERTY HEALTH SCIENCES MERRITT ISLAND',
  'MAMA MARGIES MEXICAN RESTAURANTCULEBRA',
  'THB BAGELS AND DELI OF CHARLES VILLAGE',
  'COOPERS HAWK WINERY RESTAURANT COLUMBUS',
  'STOPSHOP',
  'KRIS KRINGL',
  'FOOD PLAZA',
  'LAS GOLONDRINAS MEXICAN FOOD',
  'VEGINOUT MARKET',
  'JB HAWKS DISCOUNT TOBACCO VAPE',
  'PTP PROFESSIONAL TRANSPORTATION PARTNERS',
  'ACE HARDWARE AND SPORTING GOODS KENNEWICK',
  'BUREAU OF MOTOR VEHICLES DEPUTY REGISTRAR',
  'WALTERS EXPRESS',
  'LAWA OFFICE PUB AND MUSIC HALL',
  'FOOD WORLD',
  'SONIC DRIVEIN',
  'COLUMBIA SPORTSWEAR AT PALM BEACH OUTLETS',
  'GOODWILL THRIFT STORE AND DONATION CENTER',
  'CHUCK HAFNERS FARMERS MARKET GARDEN CENTER',
  'MARTINOS BILO FOODS DISCOUNT FUEL BROCKWAY',
  'MARINE CORPS EXCHANGE NEW RIVER AIR STATION',
  'WAYBACK BURGERS',
  'KUNG FU TEA',
  'GREEN HILLS',
  'WESTBORN MARKET',
  'MOSCOW PULLMAN BUILDING SUPPLY',
  'BUSY BEAVER ASHTABULA',
  'PUBLIX SUPER MARKET AT MERGANSER COMMONS AT BONAIRE',
  'RENEGADE CLASSICS OUTLET STORE',
  'LA MADELINE',
  'WESTSIDE LIQUOR',
  'THE SHOPPES AT AMERICAN CANDLE',
  'SONRISA GRILL',
  'IKHLAS SEAFOODS AND PASTRIES',
  'GOODWILL OF NORTH GEORGIA NORTHLAKE STORE AND DONATION CENTER',
  'HWY BURGERS SHAKES AND FRIES',
  'HOUSE OF MUSIC ENTERTAINMENT',
  'SUNCOAST',
  'LAMS MARKET',
  'TH AVE',
  'WINEING BUTCHER',
  'TOWNSHIP PROFESSIONAL PHARMACY',
  'MASA',
  'MAKS',
  'FAB',
  'CC',
  'AJI SUSHI TEPPAN',
  'ALL PAWN SURPLUS',
  'BLUE WATER SEAFOOD MARKET GRILL',
  'ALL UNIFORM WEAR',
  'SONTERRA PARK',
  'CARNICERIA GUANAJUATO',
  'SPARTAN FOODS',
  'FOODS MART',
  'SPORTS NATION',
  'CHINATOWN SUPERMARKET',
  'GREAT STEAK',
  'Q BEAUTY SUPPLY',
  'R TRAVEL CENTER',
];

import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Button, MenuItem } from '@klover/attain-design-system';
import {
  ToggleDuplicateOfferDialogAction,
  duplicateOffer,
  fetchGroups,
  toggleDuplicateOfferDialog,
} from '../slice';

import { Formik, useFormik } from 'formik';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, theme, Grid, Select } from '@klover/attain-design-system';
import Field from 'components/Field';
import * as Styled from './index.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    selectGroup: {
      minWidth: 250,
      height: '50px',
      '& fieldset': {
        top: '-12px !important'
      }
    }
  })
);

interface Props {
  open: boolean;
}

const DuplicateOfferDialog = ({ open }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const offerToDuplicate = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const formik = useFormik({
    initialValues: {
      offerGroupId: '',
      groupName: '',
    },
    onSubmit: (values) => {
      dispatch(
        duplicateOffer({
          ...values,
          offerToDuplicate,
        })
      );
    },
  });

  const groupsEntities = useAppSelector((state) => {
    if (
      state.offerWallReducer &&
      state.offerWallReducer.entities &&
      state.offerWallReducer.entities.groups
    ) {
      return state.offerWallReducer.entities.groups;
    } else {
      return null;
    }
  });

  const groupIds = groupsEntities ? groupsEntities.allIds : null;

  const dispatchClose = () => {
    const actionProps: ToggleDuplicateOfferDialogAction = {
      open: false,
    };
    dispatch(toggleDuplicateOfferDialog(actionProps));
  };

  const offerLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.offerLoading
  );

  const isOfferLoading = offerLoading.state === 'loading';

  const handleClose = () => {
    dispatchClose();
  };

  React.useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={{ alignment: 'oldGroups' }}
        onSubmit={formik.handleSubmit}
      >
        {({ values }) => (
          <Dialog

            fullWidth
            className={classes.root}
            onClose={handleClose}
            open={open}
            headerID="createDuplicateOffer"
            title=''
            header={
              <Styled.CardHeader>
                <Styled.CardTitle>Duplicate Offer</Styled.CardTitle>
              </Styled.CardHeader>
            }
            bodyID="duplicateOffer"
            footer={
              <>
                <Button
                  variant="contained"
                  disabled={
                    values.alignment == 'newGroup' && !formik.values.groupName
                  }
                  onClick={formik.handleSubmit}
                >
                  Duplicate Offer
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  disabled={isOfferLoading}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </>
            }
            body={
              <Styled.StyledBodyContainer
                container
                spacing={theme.spacing.lg}
                direction="column"
              >
                <Grid item>
                  <p>
                    Are you sure you want to duplicate the offer? Choose an offer
                    group or select a new one.
                  </p>
                  <Styled.RadioGroup role="group" aria-labelledby="my-radio-group">
                    <div>
                      <label htmlFor="oldGroups">
                        <Field
                          id="oldGroups"
                          type="radio"
                          name="alignment"
                          value="oldGroups"
                        />
                        <span>Select Group</span>
                      </label>
                    </div>
                    <div>
                      <label htmlFor="newGroup">
                        <Field
                          id="newGroup"
                          type="radio"
                          name="alignment"
                          value="newGroup"
                        />
                        <span>Create New Group</span>
                      </label>
                    </div>
                  </Styled.RadioGroup>
                  {values.alignment == 'oldGroups' ? (
                    <Select
                      name="offerGroupId"
                      id="offerGroupId"
                      onChange={formik.handleChange}
                      value={formik.values.offerGroupId || 'default'}
                      className={classes.selectGroup}
                    >
                      <MenuItem key="default" value="default">
                        Default
                      </MenuItem>
                      {groupIds
                        ? groupIds.map((groupId) => {
                          return (
                            <MenuItem key={groupId} value={groupId}>
                              {groupsEntities &&
                                groupsEntities.byId &&
                                groupsEntities.byId[groupId] &&
                                groupsEntities.byId[groupId].name
                                ? groupsEntities.byId[groupId].name
                                : null}
                            </MenuItem>
                          );
                        })
                        : null}
                    </Select>
                  ) : (
                    <Field
                      type="text"
                      value={formik.values.groupName}
                      id="groupName"
                      name="groupName"
                      placeholder="e.g. Walmart Offers..."
                      onChange={formik.handleChange}
                      style={{ width: 250 }}
                    />
                  )}
                </Grid>
              </Styled.StyledBodyContainer>
            }></Dialog>
        )}
      </Formik>
    </>
  );
};

export default DuplicateOfferDialog;

import _ from 'lodash';

export const getById = (
  array: any,
  identifier: string,
  previousNormalizedObject: any = false
) => {
  const newEntities = array.reduce((obj, element) => {
    obj[element[identifier]] = element;
    return obj;
  }, {});
  if (previousNormalizedObject.byId)
    return _.assign(previousNormalizedObject.byId, newEntities);
  return newEntities;
};

import Button from 'components/Button';
import Select from 'components/Select';
import styled from 'styled-components';
import tokens from 'tokens';
import { Form as FormikForm } from 'formik';

export const InactiveAbTestIcon = styled.img`
  height: 25px;
  filter: invert(85%) sepia(1%) saturate(0%) hue-rotate(15deg) brightness(92%)
    contrast(86%);
`;

export const ActiveAbTestIcon = styled.img`
  height: 25px;
  filter: invert(54%) sepia(31%) saturate(665%) hue-rotate(115deg)
    brightness(93%) contrast(90%);
`;

export const Form = styled(FormikForm)`
  border-left: 1px solid ${tokens.colorGray40};
  display: inline-block;
  margin-left: ${tokens.spacing.md};
  padding-left: ${tokens.spacing.md};
`;

export const Pipe = styled('span')`
  border-left: 1px solid ${tokens.colorGray40};
  display: inline-block;
  margin-left: ${tokens.spacing.md};
  height: 40px;
`;

export const Clicks = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: end;
  padding-right: 12px;
`;

export const ConversionCap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const Impressions = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: end;
`;

export const OfferId = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: end;
  padding-right: 24px;
`;

export const Placement = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const DateFormatter = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const OfferLabel = styled.div`
  max-width: 350px;
  & > * {
    margin-right: ${tokens.spacing.sm};
  }
  display: flex;
`;

export const OfferLabelButton = styled(Button)`
  margin-left: ${tokens.spacing.sm};
`;

export const Or = styled.span`
  margin: 0 ${tokens.spacing.sm};
  text-transform: uppercase;
`;

export const Points = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: end;
  padding-right: 24px;
`;

export const SearchArea = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${tokens.spacing.md};
`;

export const SelectOfferGroup = styled(Select)`
  width: 170px;
`;

export const Spinner = styled.div`
  min-height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

export const ButtonSpacing = styled.div`
  display: flex;

  & > :first-child {
    margin-right: ${tokens.spacing.sm};
  }
`;

import _ from 'lodash';
import { LoadingProps } from 'interfaces/ui';
import {
  NormalizedObjects,
  initialNormalizedObject,
} from 'interfaces/entities';
import { Offer, OfferGroup } from '../offerWallManager/slice';
import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { StringMap, StringToBoolMap } from 'interfaces';

export interface SalesLiftStudy {
  salesliftstudyid: string;
  name: string;
  startdate: number;
  enddate: number;
  liftconversionevent: string;
  createdat: number;
  updatedat: number;
  offers: Offer[];
  offergroupname?: string;
  dimensions: Array<any>;
  lapsedperiod: number;
  attributionwindow: number;
}

interface SalesLiftStudiesState {
  currentSalesLiftStudy?: SalesLiftStudy;
  originalSalesLiftStudy?: SalesLiftStudy;
  currentSalesLiftStudyDimension?: any;
  currentSalesLiftDimension?: string;
  isInvalid: StringMap;
  isStepInvalid: StringToBoolMap;
  hasChanged: StringToBoolMap;
  entities: {
    groups: NormalizedObjects<OfferGroup>;
    offers: NormalizedObjects<Offer>;
    studies: NormalizedObjects<SalesLiftStudy>;
  };
  ui: {
    salesLiftDraftState: StringMap;
    isDisabled: StringToBoolMap;
    groupsLoading: LoadingProps;
    groupLoading: LoadingProps;
    notifications: Array<LoadingProps>;
    studyListOptions: any;
    salesLiftDimensionDialogOpen: boolean;
    salesLiftDeleteDialogOpen: boolean;
    errorFetching: boolean;
    salesLiftExportDialogOpen: boolean;
    salesLiftStudiesLoading: LoadingProps;
    salesLiftStudyExporting: boolean;
    salesLiftStudyLoading: LoadingProps;
    queryLoading: boolean;
  };
}

const initialState: SalesLiftStudiesState = {
  entities: {
    groups: initialNormalizedObject,
    offers: initialNormalizedObject,
    studies: initialNormalizedObject,
  },
  isInvalid: {},
  isStepInvalid: {},
  hasChanged: {},
  ui: {
    isDisabled: {},
    groupsLoading: { state: 'idle' },
    groupLoading: { state: 'idle' },
    notifications: [],
    studyListOptions: [],
    salesLiftDraftState: {},
    salesLiftDimensionDialogOpen: false,
    salesLiftDeleteDialogOpen: false,
    errorFetching: false,
    salesLiftExportDialogOpen: false,
    salesLiftStudiesLoading: { state: 'idle' },
    salesLiftStudyExporting: false,
    salesLiftStudyLoading: { state: 'idle' },
    queryLoading: false,
  },
};

export const salesLiftStudiesSlice = createSlice({
  name: 'salesLiftStudies',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.ui.notifications.push(action.payload);
    },
    clearGroups: (state) => {
      state.entities.groups = initialNormalizedObject;
    },
    createSalesLiftStudy: () => {
      //
    },
    createGroup: () => {
      //
    },
    deleteSalesLiftStudy: () => {
      //
    },
    deleteGroup: () => {
      //
    },
    exportSalesLiftStudy: () => {
      //
    },
    fetchSalesLiftStudy: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchAllGroups: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchGroups: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    setDraftState: (state, action) => {
      state.ui.salesLiftDraftState = action.payload;
    },
    setCurrentSalesLiftStudy: (state, action) => {
      state.currentSalesLiftStudy = action.payload;
    },
    setCurrentSalesLiftStudyOffers: (state) => {
      if (state.currentSalesLiftStudy) state.currentSalesLiftStudy.offers = [];
    },
    setCurrentSalesLiftDimension: (state, action) => {
      state.currentSalesLiftDimension = action.payload;
    },
    setHasChanged: (state, action) => {
      const { keyName } = action.payload;
      if (state.hasChanged) {
        state.hasChanged[keyName] = true;
      } else {
        state.hasChanged = {};
        state.hasChanged[keyName] = true;
      }
    },
    setHasNotChanged: (state, action) => {
      const { keyName } = action.payload;
      delete state.hasChanged[keyName];
    },
    setOriginalSalesLiftStudy: (state, action) => {
      state.originalSalesLiftStudy = action.payload;
    },
    setGroups: (state, action: PayloadAction<OfferGroup>) => {
      const groupsEntity = _.clone(current(state.entities.groups));
      const newAllIds = _.union(groupsEntity.allIds, [action.payload.id]);
      const newById = _.clone(groupsEntity.byId);
      newById[action.payload.id] = action.payload;
      state.entities.groups.byId = newById;
      state.entities.groups.allIds = newAllIds;
    },
    setOffers: (state, action) => {
      state.entities.offers.byId[action.payload.offerid] = action.payload;
      state.entities.offers.allIds = _.union(state.entities.offers.allIds, [
        action.payload.offerid,
      ]);
    },
    setTargetTransactions: (state, action) => {
      const updatedTargetTransactions = action.payload;
      const originalDimensions =
        state.originalSalesLiftStudy && state.originalSalesLiftStudy.dimensions
          ? current(state.originalSalesLiftStudy.dimensions)
          : [];
      const filteredDimensions = originalDimensions
        ? originalDimensions.filter((x) => x.category === 'targetTransactions')
        : [];
      const originalTargetTransactions = filteredDimensions.length
        ? filteredDimensions[0]
        : null;
      if (originalTargetTransactions && updatedTargetTransactions) {
        const originalDimension = _.omit(originalTargetTransactions, [
          'conditions',
          'totalAudienceSize',
        ]);
        const updatedDimension = _.omit(updatedTargetTransactions, [
          'conditions',
          'totalAudienceSize',
        ]);
        const originalConditions =
          originalTargetTransactions && originalTargetTransactions.conditions
            ? originalTargetTransactions.conditions.map((condition) =>
                _.omit(condition, ['audienceSize'])
              )
            : [];
        const updatedConditions =
          updatedTargetTransactions && updatedTargetTransactions.conditions
            ? updatedTargetTransactions.conditions
                .map((condition) => _.omit(condition, ['audienceSize']))
                .filter((x) => x.qualifier)
            : [];
        if (
          _.isEqual(originalDimension, updatedDimension) &&
          _.isEqual(originalConditions, updatedConditions)
        ) {
          delete state.hasChanged.targetTransactions;
        } else {
          state.hasChanged.targetTransactions = true;
        }
      }
      const cloneOfSalesLiftStudy = _.clone(
        current(state.currentSalesLiftStudy)
      );
      let cloneOfSalesLiftStudyDimensions =
        cloneOfSalesLiftStudy && cloneOfSalesLiftStudy.dimensions
          ? _.clone(cloneOfSalesLiftStudy.dimensions)
          : null;
      if (cloneOfSalesLiftStudyDimensions) {
        if (
          cloneOfSalesLiftStudy &&
          updatedTargetTransactions &&
          cloneOfSalesLiftStudy.salesliftstudyid ==
            updatedTargetTransactions.salesliftstudyid
        ) {
          cloneOfSalesLiftStudyDimensions =
            cloneOfSalesLiftStudyDimensions.filter(
              (dimension) => dimension.category !== 'targetTransactions'
            );

          cloneOfSalesLiftStudyDimensions =
            cloneOfSalesLiftStudyDimensions.concat([updatedTargetTransactions]);
        }
      }
      if (
        cloneOfSalesLiftStudy &&
        cloneOfSalesLiftStudy.dimensions &&
        cloneOfSalesLiftStudyDimensions
      ) {
        cloneOfSalesLiftStudy.dimensions = cloneOfSalesLiftStudyDimensions;
      }
      state.currentSalesLiftStudy = cloneOfSalesLiftStudy;
    },
    updateTargetTransaction: (state, action) => {
      const updatedTargetTransactions = action.payload;
      const cloneOfSalesLiftStudy = _.clone(
        current(state.currentSalesLiftStudy)
      );
      let cloneOfSalesLiftStudyDimensions =
        cloneOfSalesLiftStudy && cloneOfSalesLiftStudy.dimensions
          ? _.clone(cloneOfSalesLiftStudy.dimensions)
          : null;
      if (cloneOfSalesLiftStudyDimensions) {
        if (
          cloneOfSalesLiftStudy &&
          updatedTargetTransactions &&
          cloneOfSalesLiftStudy.salesliftstudyid ==
            updatedTargetTransactions.salesliftstudyid
        ) {
          cloneOfSalesLiftStudyDimensions =
            cloneOfSalesLiftStudyDimensions.filter(
              (dimension) => dimension.category !== 'targetTransactions'
            );
          cloneOfSalesLiftStudyDimensions =
            cloneOfSalesLiftStudyDimensions.concat([updatedTargetTransactions]);
        }
      }
      if (
        cloneOfSalesLiftStudy &&
        cloneOfSalesLiftStudy.dimensions &&
        cloneOfSalesLiftStudyDimensions
      ) {
        cloneOfSalesLiftStudy.dimensions = cloneOfSalesLiftStudyDimensions;
      }
      state.currentSalesLiftStudy = cloneOfSalesLiftStudy;
    },
    searchSalesLifts: () => {
      // Skeleton
    },
    setQueryLoading: (state, action) => {
      state.ui.queryLoading = action.payload;
    },
    addSalesLiftStudyToEntities: (
      state,
      action: PayloadAction<SalesLiftStudy>
    ) => {
      const studiesEntity = _.clone(current(state.entities.studies));
      const newAllIds = _.union(studiesEntity.allIds, [
        action.payload.salesliftstudyid,
      ]);
      const newById = _.clone(studiesEntity.byId);
      newById[action.payload.salesliftstudyid] = action.payload;
      state.entities.studies.byId = newById;
      state.entities.studies.allIds = newAllIds.filter((x) => x);
    },
    saveSalesLiftStudy: () => {
      // wrapper
    },
    setAllValid: (state) => {
      state.isInvalid = {};
      state.isStepInvalid = {};
      state.hasChanged = {};
    },
    setGroupLoadingState: (state, action) => {
      state.ui.groupLoading = action.payload;
    },
    setGroupsLoadingState: (state, action) => {
      state.ui.groupsLoading = action.payload;
    },
    setGroupDeletingState: (state, action) => {
      state.ui.groupsLoading = action.payload;
    },
    setGroupSavingState: (state, action) => {
      state.ui.groupsLoading = action.payload;
    },
    setSalesLiftDeleteDialogOpen: (state, action) => {
      state.ui.salesLiftDeleteDialogOpen = action.payload;
    },
    setSalesLiftDimensionDialogOpen: (state, action) => {
      state.ui.salesLiftDimensionDialogOpen = action.payload;
    },
    setSalesLiftExportDialogOpen: (state, action) => {
      state.ui.salesLiftExportDialogOpen = action.payload;
    },
    setSalesLiftStudyExporting: (state, action) => {
      state.ui.salesLiftStudyExporting = action.payload;
    },
    setSalesLiftStudyLoadingState: (state, action) => {
      state.ui.salesLiftStudyLoading = action.payload;
    },
    setSalesLiftStudiesLoadingState: (state, action) => {
      state.ui.salesLiftStudiesLoading = action.payload;
    },
    setStudyListOptions: (state, action) => {
      //this will trigger on fetching offers
      state.ui.studyListOptions = action.payload;
    },
    setIsDisabled: (state, action) => {
      const salesliftstudyid = action.payload;
      if (state.ui.isDisabled) {
        if (state.ui.isDisabled[salesliftstudyid]) {
          delete state.ui.isDisabled[salesliftstudyid];
        } else {
          state.ui.isDisabled[salesliftstudyid] = true;
        }
      } else {
        state.ui.isDisabled = {};
        state.ui.isDisabled[salesliftstudyid] = true;
      }
    },
    setIsValid: (state, action) => {
      const { keyName } = action.payload;
      delete state.isInvalid[keyName];
    },
    setIsInvalid: (state, action) => {
      const { keyName, helperText } = action.payload;
      if (state.isInvalid) {
        state.isInvalid[keyName] = helperText;
      } else {
        state.isInvalid = {};
        state.isInvalid[keyName] = helperText;
      }
    },
    setStepValid: (state, action) => {
      const { keyName } = action.payload;
      delete state.isStepInvalid[keyName];
    },
    setStepInvalid: (state, action) => {
      const { keyName } = action.payload;
      if (state.isStepInvalid) {
        state.isStepInvalid[keyName] = true;
      } else {
        state.isStepInvalid = {};
        state.isStepInvalid[keyName] = true;
      }
    },
    setUIState: (state, action) => {
      const { value, uiPropertyName } = action.payload;
      state.ui[uiPropertyName] = value;
    },
    updateCurrentStudyProperty: (state, action) => {
      const { updatedStudy, propertyName } = action.payload;
      if (updatedStudy) {
        if (propertyName === 'offergroup') {
          updatedStudy.offers = [];
        }
        state.currentSalesLiftStudy = updatedStudy;
      }
    },
    updateCurrentSalesLiftStudyProperty: (state, action) => {
      const { updatedSalesLift } = action.payload;
      state.currentSalesLiftStudy = updatedSalesLift;
    },
    updateSalesLiftOffers: (state, action) => {
      const offer = action.payload;
      const updatedSalesLiftStudy = _.clone(
        current(state.currentSalesLiftStudy)
      );
      const originalSalesLiftStudy = current(state.originalSalesLiftStudy);
      const selectedOffers = updatedSalesLiftStudy?.offers.reduce(
        (a, offer) => {
          a[offer.offerid] = true;
          return a;
        },
        {}
      );
      if (selectedOffers[offer.offerid]) {
        if (updatedSalesLiftStudy && updatedSalesLiftStudy.offers) {
          const updatedOfferList = updatedSalesLiftStudy.offers.filter(
            (existingOffer) => existingOffer.offerid != offer.offerid
          );
          if (updatedOfferList.length)
            updatedSalesLiftStudy.offers = updatedOfferList;
        }
      } else {
        if (updatedSalesLiftStudy && updatedSalesLiftStudy.offers) {
          updatedSalesLiftStudy.offers = updatedSalesLiftStudy.offers.concat([
            offer,
          ]);
        }
      }
      if (
        updatedSalesLiftStudy &&
        updatedSalesLiftStudy.offers &&
        originalSalesLiftStudy &&
        originalSalesLiftStudy.offers
      ) {
        const updatedOfferIds = updatedSalesLiftStudy.offers
          .map((x) => x.offerid)
          .sort((a, b) => a - b);
        const originalOfferIds = originalSalesLiftStudy.offers
          .map((x) => x.offerid)
          .sort((a, b) => a - b);
        if (_.isEqual(updatedOfferIds, originalOfferIds)) {
          delete state.hasChanged.offersSelect;
        } else {
          state.hasChanged.offersSelect = true;
        }
      }
      state.currentSalesLiftStudy = updatedSalesLiftStudy;
    },
    removeSalesLiftStudy: (state, action) => {
      const salesliftstudyid = action.payload;
      if (state.entities.studies) {
        delete state.entities.studies.byId[salesliftstudyid];
        state.entities.studies.allIds = state.entities.studies.allIds.filter(
          (x) => x != salesliftstudyid
        );
      }
    },
    removeNotification: (state) => {
      state.ui.notifications = state.ui.notifications.slice(1);
    },
  },
});

export const {
  addNotification,
  clearGroups,
  createSalesLiftStudy,
  createGroup,
  deleteSalesLiftStudy,
  deleteGroup,
  exportSalesLiftStudy,
  fetchSalesLiftStudy,
  fetchAllGroups,
  fetchGroup,
  saveSalesLiftStudy,
  searchSalesLifts,
  setCurrentSalesLiftStudy,
  setCurrentSalesLiftStudyOffers,
  setCurrentSalesLiftDimension,
  setOriginalSalesLiftStudy,
  setGroups,
  setOffers,
  setAllValid,
  setDraftState,
  setHasChanged,
  setHasNotChanged,
  setIsDisabled,
  setIsValid,
  setIsInvalid,
  setStepValid,
  setStepInvalid,
  setGroupLoadingState,
  setGroupsLoadingState,
  setGroupDeletingState,
  setGroupSavingState,
  setSalesLiftDeleteDialogOpen,
  setSalesLiftDimensionDialogOpen,
  setSalesLiftExportDialogOpen,
  setSalesLiftStudyExporting,
  setSalesLiftStudyLoadingState,
  setSalesLiftStudiesLoadingState,
  setQueryLoading,
  setTargetTransactions,
  addSalesLiftStudyToEntities,
  setStudyListOptions,
  setUIState,
  updateCurrentSalesLiftStudyProperty,
  updateCurrentStudyProperty,
  updateSalesLiftOffers,
  updateTargetTransaction,
  removeNotification,
  removeSalesLiftStudy,
} = salesLiftStudiesSlice.actions;

export default salesLiftStudiesSlice.reducer;

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from 'redux/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    requiredRow: {
      display: 'flex',
    },
    requiredRowEmoji: {
      paddingRight: '0.5em',
    },
    messageTextAlignment: {
      textAlign: 'left',
      padding: 10,
    },
  })
);

const checkMark = String.fromCodePoint(9989);
const xMark = String.fromCodePoint(10060);

const ExportDisabledButtonMessage = ({
  targetTransactionConditions,
  hasSaved,
}) => {
  const classes = useStyles();
  const originalSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.originalSalesLiftStudy
  );
  const enoughMerchants =
    targetTransactionConditions &&
    targetTransactionConditions.length &&
    targetTransactionConditions.length < 4;
  return (
    <div className={classes.messageTextAlignment}>
      <div>{"Hi! Looks like you're trying to export"}</div>
      <br />
      <div>To export make sure you have</div>
      <br />
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {!hasSaved ? checkMark : xMark}
        </div>
        <div>Saved your work</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {originalSalesLiftStudy?.name ? checkMark : xMark}
        </div>
        <div>Sales Lift Study Name</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {originalSalesLiftStudy?.enddate && originalSalesLiftStudy?.startdate
            ? checkMark
            : xMark}
        </div>
        <div>Study Dates</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {originalSalesLiftStudy?.lapsedperiod ? checkMark : xMark}
        </div>
        <div>Lapsed Period</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {originalSalesLiftStudy?.attributionwindow ? checkMark : xMark}
        </div>
        <div>Attribution Window</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {targetTransactionConditions.length ? checkMark : xMark}
        </div>
        <div>Merchant names selected in Target Transactions</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {enoughMerchants ? checkMark : xMark}
        </div>
        <div>Less than 3 Merchants</div>
      </div>
      <br />
      <div>
        {'Make sure to '}
        <strong>SAVE</strong>
        {' your work to ensure that all checkboxes can be properly checked'}
      </div>
      <br />
    </div>
  );
};

export default ExportDisabledButtonMessage;

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootSaga from './rootSaga';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'; // defaults to localStorage for web

import audienceReducer from 'pages/audienceManager/slice';
import notificationsReducer from 'pages/notifications/slice';
import offerWallReducer from 'pages/offerWallManager/slice';
import salesLiftStudiesReducer from 'pages/salesLiftStudyManager/slice';
import { loginReducer } from 'pages/auth/store';

const rootReducer = combineReducers({
  audienceReducer: audienceReducer,
  loginReducer: loginReducer,
  offerWallReducer: offerWallReducer,
  salesLiftStudiesReducer: salesLiftStudiesReducer,
  notificationsReducer: notificationsReducer,
});

const devMode = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    thunk: false,
  }),
  sagaMiddleware,
];

if (devMode) {
  middleware.push(logger);
}

const persistConfig = {
  key: 'root',
  stateReconciler: autoMergeLevel2,
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: devMode,
  middleware,
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

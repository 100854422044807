import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addNotification,
  clearGroups,
  createGroup,
  deleteGroup,
  fetchGroup,
  fetchGroups,
  removeOfferGroup,
  saveGroup,
  setGroupLoadingState,
  setGroupsLoadingState,
  toggleGroupDeleteDialog,
  toggleGroupNameDialog,
  updateCurrentGroup,
  updateCurrentGroupProperty,
  updateGroups,
  updateOffers,
  updateSearchGroups,
} from '../slice';
import { auth } from 'utils/firebase';
import { call, put, takeLatest } from 'redux-saga/effects';

const API_ENDPOINT = `${process.env.API_BASE_ADDRESS}/offer-manager/offer-groups`;

interface OfferGroup {
  id: string;
  name: string;
}

function handleCreateGroup(group: OfferGroup) {
  const params: OfferGroup = {
    id: group.id,
    name: group.name,
  };
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(`${API_ENDPOINT}`, params);
  });
}

function handleDeleteGroup(group: OfferGroup) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.delete(`${API_ENDPOINT}/${group.id}`);
  });
}

function handleFetchGroup(group: OfferGroup) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(`${API_ENDPOINT}/${group.id}`);
  });
}

function handleFetchGroups(query) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    let url = API_ENDPOINT;
    if (query) url += `?query=${query}`;
    return authorizedAxiosInstance.get(url);
  });
}

function handleUpdateGroup(group: OfferGroup) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.put(`${API_ENDPOINT}/${group.id}`, {
      name: group.name,
    });
  });
}

function* watchCreateGroup(action: PayloadAction<OfferGroup>) {
  try {
    const offerGroup = action.payload;
    const result = yield call(handleCreateGroup, offerGroup);
    yield put(updateGroups(result.data));
    yield put(updateCurrentGroup(result.data));
    yield put(toggleGroupNameDialog({ open: false }));
    yield put(
      addNotification({
        state: 'done',
        message: `Successfully Created Group ${action.payload.name}`,
      })
    );
  } catch (e) {
    yield put(toggleGroupNameDialog({ open: false }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Create Group ${action.payload.name}`,
      })
    );
  }
}

function* watchDeleteGroup(action: PayloadAction<OfferGroup>) {
  try {
    yield call(handleDeleteGroup, action.payload);
    yield put(removeOfferGroup(action.payload));
    yield put(updateCurrentGroup(undefined));
    yield put(toggleGroupDeleteDialog({ open: false }));
    yield put(
      addNotification({
        state: 'done',
        message: `Successfully Deleted Group ${action.payload.name}`,
      })
    );
  } catch (e) {
    yield put(toggleGroupDeleteDialog({ open: false }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Delete Group ${action.payload.name}`,
      })
    );
  }
}

function* watchFetchGroup(action: PayloadAction<OfferGroup>) {
  try {
    yield put(setGroupLoadingState({ state: 'loading' }));
    const result = yield call(handleFetchGroup, action.payload);
    const offerGroup = result.data;
    // const offers = offerGroup.offers;
    // const offerIds = offers.map((offer) => offer.offerid);
    // offerGroup.offers = offerIds;
    // yield put(updateOffers(offers))
    // for (const offer of offers) {
    //   yield put(updateOffers(offer));
    // }
    yield put(updateGroups(offerGroup));
    yield put(updateCurrentGroup(offerGroup));
    yield put(setGroupLoadingState({ state: 'done' }));
  } catch (e) {
    yield put(setGroupLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Retrieve Group ${action.payload.name} and offers`,
      })
    );
  }
}

function* watchFetchGroups(action: PayloadAction<string>) {
  try {
    yield put(setGroupsLoadingState({ state: 'loading' }));
    if (action.payload) {
      const searchResults = yield call(
        handleFetchGroups,
        encodeURIComponent(action.payload)
      );
      for (const offerGroup of searchResults.data) {
        yield put(updateSearchGroups(offerGroup));
      }
    } else {
      yield put(clearGroups());
      const results = yield call(handleFetchGroups);
      for (const offerGroup of results.data) {
        yield put(updateGroups(offerGroup));
      }
    }
    yield put(setGroupsLoadingState({ state: 'done' }));
  } catch (e) {
    yield put(setGroupsLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Retrieve Groups`,
      })
    );
  }
}

function* watchUpdateGroup(action: PayloadAction<OfferGroup>) {
  try {
    const { data } = yield call(handleUpdateGroup, action.payload);
    yield put(updateGroups(data));
    yield put(updateCurrentGroupProperty({ key: 'name', value: data.name }));
    yield put(
      addNotification({
        state: 'done',
        message: `Successfully Updated Group ${action.payload.name}`,
      })
    );
  } catch (e) {
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Update Group ${action.payload.name}`,
      })
    );
  }
}

export default function* watchAll() {
  yield takeLatest<any>(createGroup.type, watchCreateGroup);
  yield takeLatest<any>(deleteGroup.type, watchDeleteGroup);
  yield takeLatest<any>(fetchGroup.type, watchFetchGroup);
  yield takeLatest<any>(fetchGroups.type, watchFetchGroups);
  yield takeLatest<any>(saveGroup.type, watchUpdateGroup);
}

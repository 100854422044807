import styled from 'styled-components';

export const MessageTextAlignment = styled.div`
  text-align: left;
  padding: 10px;
`;

export const RequiredRow = styled.div`
  display: flex;
`;

export const RequiredRowEmoji = styled.div`
  padding-right: 10px;
`;

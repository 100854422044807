import styled from 'styled-components';
import tokens from 'tokens';
import { ButtonProps, ButtonVariants } from '.';

export const Button = styled.button<ButtonProps>`
  ${tokens.typography.body};
  align-items: center;
  background: ${tokens.colorBrandprimary};
  border: 0;
  border-radius: ${tokens.spacing.sm};
  color: ${tokens.colorBodynegative};
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.05em;
  padding: ${tokens.spacing.sm} ${tokens.spacing.md};
  text-transform: uppercase;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  &:hover {
    background: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? 'transparent'
        : tokens.colorBrandprimaryhover};
    box-shadow: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? `inset 0 0 0 2px ${tokens.colorBrandprimaryhover}`
        : 'none'};
    color: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? tokens.colorBrandprimaryhover
        : tokens.colorBodynegative};
  }

  &:active {
    background: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? 'transparent'
        : tokens.colorBrandprimarypressed};
    box-shadow: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? `inset 0 0 0 2px ${tokens.colorBrandprimarypressed}`
        : 'none'};
    color: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? tokens.colorBrandprimarypressed
        : tokens.colorBodynegative};
  }

  &:disabled {
    background: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? 'transparent'
        : `${tokens.colorBrandprimarydisabled} !important`};
    box-shadow: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? `inset 0 0 0 2px ${tokens.colorBrandprimarydisabled}`
        : 'none'};
    color: ${(props) =>
      props.variant === ButtonVariants.Outlined
        ? tokens.colorBrandprimarydisabled
        : tokens.colorBodynegative};
    cursor: default;
  }

  &.variant-outlined {
    background: transparent !important;
    box-shadow: inset 0 0 0 2px ${tokens.colorBrandprimary};
    color: ${tokens.colorBrandprimary};

    &:hover {
      box-shadow: inset 0 0 0 2px ${tokens.colorBrandprimaryhover};
      color: ${tokens.colorBrandprimaryhover};
    }

    &:active {
      box-shadow: inset 0 0 0 2px ${tokens.colorBrandprimarypressed};
      color: ${tokens.colorBrandprimarypressed};
    }

    &:disabled {
      box-shadow: inset 0 0 0 2px ${tokens.colorBrandprimarydisabled} !important;
      color: ${tokens.colorBrandprimarydisabled} !important;
      cursor: default;
    }
  }

  &.color-negative {
    background: red;

    &.variant-outlined {
      box-shadow: inset 0 0 0 2px red;
      color: red;
    }
  }

  &.color-positive {
    background: green;

    &.variant-outlined {
      box-shadow: inset 0 0 0 2px green;
      color: green;
    }
  }

  &.size-small {
    border-radius: ${tokens.spacing.xs};
    font-size: 14px;
    height: 24px;
    line-height: 16px;
    padding: ${tokens.spacing.xs};

    svg {
      height: 16px;
      height: 16px;
    }
  }
`;

export const Icon = styled.span`
  height: 24px;
  margin-right: ${tokens.spacing.sm};
`;

import ABTest from '../../../assets/images/ab-test.svg';
import Header from 'components/Header';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Box, Snackbar } from '@material-ui/core';
import { Button, IconButton } from '@klover/attain-design-system';
import { OfferWallContext } from 'context';
import * as Styled from './index.styles';
import DataTable from 'components/DataTable/DataTable';
import { PencilSimple, TrashSimple } from '@phosphor-icons/react';
import { sortTableData } from '../../../helper/helper';
import { DELETE_AB_TEST, PLACEMENT_TYPE, EDIT_AB_TEST } from '../../../content';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ABTesting = () => {
  const {
    abTests,
    setAbTests,
    abTestsLoading,
    setAbTestsLoading,
    setShowCreateAbTest,
    setExistingAbTest,
    setRemoveAbTest,
    snackBarOptions,
    onCloseSnackbar,
  } = React.useContext(OfferWallContext);
  const [sortBy, setSortBy] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState('DESC');
  React.useEffect(() => {
    setAbTestsLoading(true);
    axios
      .get(`${API_BASE_ADDRESS}/ab-tests`)
      .then((res) => {
        setAbTests(res.data);
        setAbTestsLoading(false);
      })
      .catch((e) => {
        setAbTestsLoading(false);
        console.log(e);
      });
  }, []);

  const columns = [
    {
      title: 'A/B Test Name',
      column: 'label',
      isSorted: sortBy === 'label',
      sortOrder: sortOrder
    },
    {
      title: 'Placement',
      column: 'placementid',
      isSorted: sortBy === 'placementid',
      sortOrder: sortOrder,
    },
    {
      title: 'Test Type',
      column: 'testingtype',
      isSorted: sortBy === 'testingtype',
      sortOrder: sortOrder,
    }, {
      title: 'Percentage',
      column: 'high',
      isSorted: sortBy === 'high',
      sortOrder: sortOrder,
      rightAlign: true,
      width: '150px',
    }, {
      title: 'Slots',
      column: 'slots',
      isSorted: sortBy === 'slots',
      sortOrder: sortOrder,
      rightAlign: true
    }, {
      title: 'Offers',
      column: 'count',
      isSorted: sortBy === 'count',
      sortOrder: sortOrder,
      rightAlign: true,
      width: '150px',
    }, {
      title: 'Actions',
      column: 'placementid',
      isSortDisabled: true,
      width: '200px',
      rightAlign: true,
    }
  ];

  const formattedRows = abTests?.map((x) => {
    let count = 0;
    if (x?.count) {
      count = x.count;
    }
    return (
      <>
        <td>
          <Styled.AbTestName>
            {x.isactive ? (
              <Styled.ActiveAbTestIcon
                src={ABTest}
                alt="Active A/B Test icon"
                title="Status: ON"
              />
            ) : (
              <Styled.InactiveAbTestIcon
                src={ABTest}
                alt="Inactive A/B Test icon"
                title="Status: OFF"
              />
            )}
            {x.label}
          </Styled.AbTestName>
        </td>
        <td>
          <Styled.TableDataItem>
            {x.placementid == 1
              ? PLACEMENT_TYPE.OFFER_WALL
              : x.placementid === 2
                ? PLACEMENT_TYPE.DASHBOARD
                : PLACEMENT_TYPE.KLOVER_PLUS}
          </Styled.TableDataItem>
        </td>
        <td>
          <Styled.TableDataItem>{x.testingtype}</Styled.TableDataItem>
        </td>
        <td>
          <Styled.RightAlignedTableDataItem>{x.high}</Styled.RightAlignedTableDataItem>
        </td>
        <td>
          <Styled.RightAlignedTableDataItem>{x.slots}</Styled.RightAlignedTableDataItem>
        </td>
        <td>
          <Styled.RightAlignedTableDataItem>{x.count}</Styled.RightAlignedTableDataItem>
        </td>
        <td>
          <>
            <Styled.ButtonSpacing>
              <IconButton
                onClick={() => {
                  setShowCreateAbTest(true);
                  setExistingAbTest(x.abtestid);
                }}
                size="small"
                title={EDIT_AB_TEST}
              >
                <PencilSimple />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => {
                  setExistingAbTest(x.abtestid);
                  setRemoveAbTest(true);
                }}
                size="small"
                title={DELETE_AB_TEST}
              >
                <TrashSimple />
              </IconButton>
            </Styled.ButtonSpacing>
          </>
        </td>

      </>
    );
  });

  const handleOnSortChange = (sortInfo: any) => {
    let newSortOrder = 'DESC';
    if (sortBy === sortInfo.column) {
      newSortOrder = sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }
    setSortBy(sortInfo.column);
    setSortOrder(newSortOrder);
    setAbTests(sortTableData(abTests, sortInfo.column, newSortOrder));
  };

  return (
    <>
      <Header
        title="A/B Testing"
        action={
          <Styled.ButtonSpacing>
            <Button
              variant="contained"
              onClick={() => setShowCreateAbTest(true)}
            >
              Create A/B Test
            </Button>
          </Styled.ButtonSpacing>
        }
      />
      <Box height="100%" padding={2}>
        <DataTable
          loading={abTestsLoading}
          columns={columns}
          rows={formattedRows || []}
          onSortChange={handleOnSortChange}
          noPagination
        />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBarOptions.open}
        autoHideDuration={6000}
        onClose={onCloseSnackbar}
        style={{ zIndex: 9999 }}
      >
        <Alert severity={snackBarOptions.error ? 'error' : 'success'}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ABTesting;

export const Households = [
  '',
  'Other',
  'Owned house',
  'Rented house',
  'Farm',
  'Owned apartment',
  'Rented apartment',
  'Living with parents',
  'University Residence',
];

export type Household = typeof Households[number];

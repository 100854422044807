import { FsCollections, LoadingStates } from 'constants/index';
import { Notification, NotificationOptions } from 'interfaces/notifications';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  addNotification,
  deleteNotification,
  fetchNotifications,
  setNotificationsLoadingState,
  updateCurrentNotifications,
  updateNotifications,
} from '../slice';

import {
  DocumentData,
  DocumentSnapshot,
  QuerySnapshot,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  writeBatch,
} from 'firebase/firestore';
import { fs } from 'utils/firebase';

function handleFetchNotifications(
  queryLimit: number,
  filterRead: boolean,
  userEmail: string,
  filterEmail: boolean,
  loadNext: boolean,
  cursorDoc: DocumentSnapshot<DocumentData> | null
) {
  const queryConstraints = [orderBy('date', 'desc')];
  const notifications = collection(fs, FsCollections.NOTIFICATIONS);
  if (loadNext && cursorDoc) {
    queryConstraints.push(startAfter(cursorDoc));
  }
  filterRead && queryConstraints.push(where('read', '==', false));
  filterEmail && queryConstraints.push(where('email', '==', userEmail));
  queryConstraints.push(limit(queryLimit));
  const q = query(notifications, ...queryConstraints);
  return getDocs(q);
}

const getCursorDoc = (currentNotifications: Array<Notification> | []) => {
  let docRef;
  if (currentNotifications.length) {
    docRef = doc(
      fs,
      FsCollections.NOTIFICATIONS,
      currentNotifications[currentNotifications.length - 1].id
    );
  }
  return docRef ? getDoc(docRef) : null;
};

function handleUpdateNotifications(
  notifications: Array<Notification>,
  userEmail: string
) {
  const batch = writeBatch(fs);
  notifications.forEach((notification) => {
    if (notification?.email === userEmail) {
      const docRef = doc(fs, FsCollections.NOTIFICATIONS, notification.id);
      batch.update(docRef, { read: true });
    }
  });
  return batch.commit();
}

function* watchFetchNotifications(action: PayloadAction<NotificationOptions>) {
  try {
    const {
      limit,
      filterRead,
      userEmail,
      filterEmail,
      currentNotifications,
      loadMore,
    } = action.payload;

    yield put(setNotificationsLoadingState({ state: LoadingStates.LOADING }));
    const cursorDoc: DocumentSnapshot<DocumentData> = yield call(
      getCursorDoc,
      currentNotifications
    );
    const result: QuerySnapshot<DocumentData> = yield call(
      handleFetchNotifications,
      limit,
      filterRead,
      userEmail,
      filterEmail,
      loadMore,
      cursorDoc
    );
    const fetchedNotifications = result.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    const updatedNotifications = loadMore
      ? [...currentNotifications, ...fetchedNotifications]
      : fetchedNotifications;
    yield put(updateCurrentNotifications(updatedNotifications));
    yield put(setNotificationsLoadingState({ state: LoadingStates.DONE }));
  } catch (e) {
    console.log('Error:', e);
    yield put(setNotificationsLoadingState({ state: LoadingStates.DONE }));
    yield put(
      addNotification({
        state: LoadingStates.ERROR,
        message: `Failed to Retrieve Notification`,
      })
    );
  }
}

function* watchUpdateNotifications(action: PayloadAction<string>) {
  try {
    const userEmail = action.payload;
    const notifications: Array<Notification> = yield select((state) => {
      if (
        state.notificationsReducer &&
        state.notificationsReducer.currentNotifications
      ) {
        return state.notificationsReducer.currentNotifications;
      }
      return [];
    });
    if (notifications.length) {
      yield call(handleUpdateNotifications, notifications, userEmail);
    }
  } catch (e) {
    console.log('Error:', e);
  }
}

function* watchDeleteNotification(action: PayloadAction<string>) {
  try {
    const id = action.payload;
    deleteDoc(doc(fs, FsCollections.TOASTERS, id));
  } catch (e) {
    console.log('Error:', e);
  }
}

export default function* watchAll() {
  yield takeLatest<any>(fetchNotifications.type, watchFetchNotifications);
  yield takeLatest<any>(updateNotifications.type, watchUpdateNotifications);
  yield takeLatest<any>(deleteNotification.type, watchDeleteNotification);
}

import { InputType } from '../salesLiftDimensionDialog';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  daysInputContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
  },
  textInput: {
    width: 210,
    paddingRight: '1em',
  },
  formControl: {
    minWidth: 200,
  },
}));

const DaysInput = ({
  inputLabel,
  keyName,
  min,
}: {
  inputLabel: string;
  keyName: string;
  min: number;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.daysInputContainer}>
      <div>
        <InputType
          className={classes.textInput}
          inputLabel={inputLabel}
          inputType="textfield"
          keyName={keyName}
          type={'Number'}
          min={min}
        />
      </div>
      <div>Days</div>
    </div>
  );
};

export default DaysInput;

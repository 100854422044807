import conversionEventDimensionConfig from './conversionEventDimension';
import observationTimeDimensionConfig from './observationTimeDimensionConfig';
import offerDimensionConfig from './offerDimensionConfig';
import targetTransactionsDimensionConfig from './targetTransactionsDimensionConfig';

const salesLiftDimensionsConfig = [
  conversionEventDimensionConfig,
  offerDimensionConfig,
  observationTimeDimensionConfig,
  targetTransactionsDimensionConfig,
];

export default salesLiftDimensionsConfig;

import { StringMap } from 'interfaces';

export const ProductCategories: StringMap = {
  '': '',
  Grocery: '|GROCERY--|',
  'Grocery > Beverages > Soda': '|GROCERY-BEVERAGES-SODA|',
  'Grocery > Snacks > Chips & Pretzels': '|GROCERY-SNACKS-CHIPS & PRETZELS|',
  'Grocery > Produce > Fresh Vegetables': '|GROCERY-PRODUCE-FRESH VEGETABLES|',
  'Grocery > Produce > Fresh Fruit': '|GROCERY-PRODUCE-FRESH FRUIT|',
  'Grocery > Beverages': '|GROCERY-BEVERAGES-|',
  'Grocery > Candy > Chocolate': '|GROCERY-CANDY-CHOCOLATE|',
  'Grocery > Dairy > Cheese': '|GROCERY-DAIRY-CHEESE|',
  'Grocery > Beverages > Juices': '|GROCERY-BEVERAGES-JUICES|',
  'Grocery > Meat & Seafood': '|GROCERY-MEAT & SEAFOOD-|',
  'Food > Burgers > Beef Burger': '|FOOD-BURGERS-BEEF BURGER|',
  'Food > Appetizers & Sides > French Fries':
    '|FOOD-APPETIZERS & SIDES-FRENCH FRIES|',
  'Grocery > Beverages > Water': '|GROCERY-BEVERAGES-WATER|',
  'Grocery > Meat & Seafood > Brand Packaged':
    '|GROCERY-MEAT & SEAFOOD-BRAND PACKAGED|',
  'Grocery > Frozen': '|GROCERY-FROZEN-|',
  'Grocery > Candy > Non-Chocolate': '|GROCERY-CANDY-NON-CHOCOLATE|',
  'Grocery > Beverages > Coffee': '|GROCERY-BEVERAGES-COFFEE|',
  'Grocery > Tobacco > Cigarettes': '|GROCERY-TOBACCO-CIGARETTES|',
  'Grocery > Dairy': '|GROCERY-DAIRY-|',
  'Grocery > Canned & Packaged > Soups': '|GROCERY-CANNED & PACKAGED-SOUPS|',
  'Grocery > Frozen > Frozen Dessert': '|GROCERY-FROZEN-FROZEN DESSERT|',
  'Food > Appetizers & Sides > Chicken Fingers & Nuggets':
    '|FOOD-APPETIZERS & SIDES-CHICKEN FINGERS & NUGGETS|',
  'Grocery > Dairy > Milk': '|GROCERY-DAIRY-MILK|',
  'Grocery > Snacks': '|GROCERY-SNACKS-|',
  'Grocery > Frozen > Frozen Entrees': '|GROCERY-FROZEN-FROZEN ENTREES|',
  'Grocery > Frozen > Frozen Vegetables': '|GROCERY-FROZEN-FROZEN VEGETABLES|',
  'Grocery > Beverages > Energy Drinks': '|GROCERY-BEVERAGES-ENERGY DRINKS|',
  'Grocery > Snacks > Cookies': '|GROCERY-SNACKS-COOKIES|',
  'Grocery > Condiment & Sauces > Sauces':
    '|GROCERY-CONDIMENT & SAUCES-SAUCES|',
  'Grocery > Bakery & Bread > Bread': '|GROCERY-BAKERY & BREAD-BREAD|',
  'Grocery > Cleaning Products > Laundry':
    '|GROCERY-CLEANING PRODUCTS-LAUNDRY|',
  'Beverages > Soft Drinks > Soda': '|BEVERAGES-SOFT DRINKS-SODA|',
  'Grocery > Snacks > Crackers': '|GROCERY-SNACKS-CRACKERS|',
  'Health & Beauty': '|HEALTH & BEAUTY--|',
  'Grocery > Meat & Seafood > Butcher Dept':
    '|GROCERY-MEAT & SEAFOOD-BUTCHER DEPT|',
  'Grocery > Canned & Packaged > Vegetables':
    '|GROCERY-CANNED & PACKAGED-VEGETABLES|',
  'Food > Sandwiches & Wraps > Meat Sandwich':
    '|FOOD-SANDWICHES & WRAPS-MEAT SANDWICH|',
  'Grocery > Breakfast > Cereal': '|GROCERY-BREAKFAST-CEREAL|',
  'Grocery > Meat & Seafood > Lunch Meats':
    '|GROCERY-MEAT & SEAFOOD-LUNCH MEATS|',
  'Grocery > Cleaning Products': '|GROCERY-CLEANING PRODUCTS-|',
  'Grocery > Bakery & Bread > Snack Cakes & Pies':
    '|GROCERY-BAKERY & BREAD-SNACK CAKES & PIES|',
  'Grocery > Frozen > Frozen Pizza': '|GROCERY-FROZEN-FROZEN PIZZA|',
  'Food > Breakfast > Breakfast Sandwich':
    '|FOOD-BREAKFAST-BREAKFAST SANDWICH|',
  'Grocery > Beverages > Iced Tea': '|GROCERY-BEVERAGES-ICED TEA|',
  'Grocery > Breakfast > Frozen Breakfast':
    '|GROCERY-BREAKFAST-FROZEN BREAKFAST|',
  'Grocery > Pasta & Grains > Pasta Noodles':
    '|GROCERY-PASTA & GRAINS-PASTA NOODLES|',
  'Grocery > Beverages > Sports Drinks': '|GROCERY-BEVERAGES-SPORTS DRINKS|',
  'Grocery > Dairy > Eggs': '|GROCERY-DAIRY-EGGS|',
  'Grocery > Breakfast > Bacon & Sausage':
    '|GROCERY-BREAKFAST-BACON & SAUSAGE|',
  'Food > Appetizers & Sides > Dips & Sauces':
    '|FOOD-APPETIZERS & SIDES-DIPS & SAUCES|',
  'Grocery > Beer, Wine & Spirits > Wine':
    '|GROCERY-BEER, WINE & SPIRITS-WINE|',
  'Grocery > Beer, Wine & Spirits > Beer':
    '|GROCERY-BEER, WINE & SPIRITS-BEER|',
  'Grocery > Dairy > Yogurt': '|GROCERY-DAIRY-YOGURT|',
  'Grocery > Herbs. Spices & Seasonings > Seasonings':
    '|GROCERY-HERBS. SPICES & SEASONINGS-SEASONINGS|',
  'Grocery > Bakery & Bread > Dessert': '|GROCERY-BAKERY & BREAD-DESSERT|',
  'Grocery > Bakery & Bread > Buns & Rolls':
    '|GROCERY-BAKERY & BREAD-BUNS & ROLLS|',
  'Grocery > Paper & Plastics > Toilet': '|GROCERY-PAPER & PLASTICS-TOILET|',
  'Grocery > Dairy > Butter & Margarine': '|GROCERY-DAIRY-BUTTER & MARGARINE|',
  'Grocery > Cleaning Products > Air & Carpet Fresheners':
    '|GROCERY-CLEANING PRODUCTS-AIR & CARPET FRESHENERS|',
  'Grocery > Condiment & Sauces > Pasta Sauces':
    '|GROCERY-CONDIMENT & SAUCES-PASTA SAUCES|',
  'Grocery > Snacks > Nuts, Seeds & Mixes':
    '|GROCERY-SNACKS-NUTS, SEEDS & MIXES|',
  'Grocery > Canned & Packaged > Fruits': '|GROCERY-CANNED & PACKAGED-FRUITS|',
  'Grocery > Breakfast': '|GROCERY-BREAKFAST-|',
  Beverages: '|BEVERAGES--|',
  'Grocery > Candy > Gum & Breath Mints': '|GROCERY-CANDY-GUM & BREATH MINTS|',
  'Grocery > Bakery & Bread': '|GROCERY-BAKERY & BREAD-|',
  'Grocery > Bakery & Bread > Tortillas & Tacos':
    '|GROCERY-BAKERY & BREAD-TORTILLAS & TACOS|',
  'Grocery > Condiment & Sauces > Salad Dressings':
    '|GROCERY-CONDIMENT & SAUCES-SALAD DRESSINGS|',
  'Grocery > Produce': '|GROCERY-PRODUCE-|',
  'Grocery > Condiment & Sauces': '|GROCERY-CONDIMENT & SAUCES-|',
  'Health & Beauty > Skin Care > Facial': '|HEALTH & BEAUTY-SKIN CARE-FACIAL|',
  'Grocery > Pasta & Grains > Rice': '|GROCERY-PASTA & GRAINS-RICE|',
  'Grocery > Candy': '|GROCERY-CANDY-|',
  'Grocery > Paper & Plastics': '|GROCERY-PAPER & PLASTICS-|',
  'Grocery > Dairy > Dough Products': '|GROCERY-DAIRY-DOUGH PRODUCTS|',
  'Grocery > Frozen > Frozen Pieces': '|GROCERY-FROZEN-FROZEN PIECES|',
  'Grocery > Frozen > Frozen Dinners': '|GROCERY-FROZEN-FROZEN DINNERS|',
  'Grocery > Dairy > Creams': '|GROCERY-DAIRY-CREAMS|',
  'Grocery > Breakfast > Bars': '|GROCERY-BREAKFAST-BARS|',
  'Home & Garden > Kitchen > Cookware & Utensils':
    '|HOME & GARDEN-KITCHEN-COOKWARE & UTENSILS|',
  'Grocery > Herbs. Spices & Seasonings > Spices':
    '|GROCERY-HERBS. SPICES & SEASONINGS-SPICES|',
  'Grocery > Pasta & Grains > Pasta Mixes':
    '|GROCERY-PASTA & GRAINS-PASTA MIXES|',
  'Grocery > Paper & Plastics > Cups,Plates,Wares':
    '|GROCERY-PAPER & PLASTICS-CUPS,PLATES,WARES|',
  'Grocery > Baking > Oils': '|GROCERY-BAKING-OILS|',
  'Health & Beauty > Bath & Shower > Body Washes & Gels':
    '|HEALTH & BEAUTY-BATH & SHOWER-BODY WASHES & GELS|',
  'Grocery > Condiment & Sauces > Salsa': '|GROCERY-CONDIMENT & SAUCES-SALSA|',
  'Grocery > Frozen > Frozen Snacks': '|GROCERY-FROZEN-FROZEN SNACKS|',
  'Grocery > Deli > Prepared Foods': '|GROCERY-DELI-PREPARED FOODS|',
  'Grocery > Paper & Plastics > Towels': '|GROCERY-PAPER & PLASTICS-TOWELS|',
  'Grocery > Snacks > Popcorn': '|GROCERY-SNACKS-POPCORN|',
  'Health & Beauty > Hair Care': '|HEALTH & BEAUTY-HAIR CARE-|',
  Food: '|FOOD--|',
  'Grocery > Cleaning Products > Dish Washing':
    '|GROCERY-CLEANING PRODUCTS-DISH WASHING|',
  'Grocery > Cleaning Products > Kitchen & Bath':
    '|GROCERY-CLEANING PRODUCTS-KITCHEN & BATH|',
  'Grocery > Beverages > Tea': '|GROCERY-BEVERAGES-TEA|',
  'Extra Value Meals': '|EXTRA VALUE MEALS--|',
  'Grocery > Canned & Packaged > Tomatoes':
    '|GROCERY-CANNED & PACKAGED-TOMATOES|',
  'Grocery > Bakery & Bread > Bread Crumbs':
    '|GROCERY-BAKERY & BREAD-BREAD CRUMBS|',
  'Grocery > Beverages > Drink Mixes': '|GROCERY-BEVERAGES-DRINK MIXES|',
  'Health & Beauty > Oral Care > Toothpaste':
    '|HEALTH & BEAUTY-ORAL CARE-TOOTHPASTE|',
  'Health & Beauty > Cosmetics > Eye Makeup':
    '|HEALTH & BEAUTY-COSMETICS-EYE MAKEUP|',
  'Health & Beauty > Deodorants > Women': '|HEALTH & BEAUTY-DEODORANTS-WOMEN|',
  'Health & Beauty > Hair Care > Shampoo':
    '|HEALTH & BEAUTY-HAIR CARE-SHAMPOO|',
  'Food > Tacos & Burritos > Meat Tacos': '|FOOD-TACOS & BURRITOS-MEAT TACOS|',
  Toys: '|TOYS--|',
  'Health & Beauty > Skin Care > Body': '|HEALTH & BEAUTY-SKIN CARE-BODY|',
  'Grocery > Produce > Salads-Prepackaged':
    '|GROCERY-PRODUCE-SALADS-PREPACKAGED|',
  'Food > Breakfast > Breakfast Sides': '|FOOD-BREAKFAST-BREAKFAST SIDES|',
  'Grocery > Frozen > Frozen Bakery': '|GROCERY-FROZEN-FROZEN BAKERY|',
  'Grocery > Frozen > Frozen Quick Meals':
    '|GROCERY-FROZEN-FROZEN QUICK MEALS|',
  'Grocery > Beer, Wine & Spirits > Liquor':
    '|GROCERY-BEER, WINE & SPIRITS-LIQUOR|',
  'Health & Beauty > Deodorants > Men': '|HEALTH & BEAUTY-DEODORANTS-MEN|',
  'Health & Beauty > Cosmetics > Foundation':
    '|HEALTH & BEAUTY-COSMETICS-FOUNDATION|',
  'Pet > Dogs > Treats': '|PET-DOGS-TREATS|',
  'Office > Office Basics': '|OFFICE-OFFICE BASICS-|',
  'Grocery > Cleaning Products > Cleaning Chemicals':
    '|GROCERY-CLEANING PRODUCTS-CLEANING CHEMICALS|',
  'Grocery > Beverages > Flavored Drinks':
    '|GROCERY-BEVERAGES-FLAVORED DRINKS|',
  'Grocery > Canned & Packaged > Seafood':
    '|GROCERY-CANNED & PACKAGED-SEAFOOD|',
  'Grocery > Tobacco > Other Tobacco': '|GROCERY-TOBACCO-OTHER TOBACCO|',
  'Health & Beauty > Hair Care > Conditioner':
    '|HEALTH & BEAUTY-HAIR CARE-CONDITIONER|',
  'Grocery > Snacks > Jerky': '|GROCERY-SNACKS-JERKY|',
  'Individual Items': '|INDIVIDUAL ITEMS--|',
  'Grocery > Snacks > Other Snacks': '|GROCERY-SNACKS-OTHER SNACKS|',
  'Grocery > Baking > Sugar & Substitutes':
    '|GROCERY-BAKING-SUGAR & SUBSTITUTES|',
  'Pet > Dogs > Dry Food': '|PET-DOGS-DRY FOOD|',
  'Body Wash & Shower Gel': '|BODY WASH & SHOWER GEL--|',
  'Health & Beauty > Oral Care > Brushes & Floss':
    '|HEALTH & BEAUTY-ORAL CARE-BRUSHES & FLOSS|',
  'Grocery > Snacks > Fruit Snacks': '|GROCERY-SNACKS-FRUIT SNACKS|',
  'Grocery > Dairy > Cream Cheese & Spreads':
    '|GROCERY-DAIRY-CREAM CHEESE & SPREADS|',
  'Grocery > Condiment & Sauces > Mayonnaise':
    '|GROCERY-CONDIMENT & SAUCES-MAYONNAISE|',
  'Grocery > Beer, Wine & Spirits': '|GROCERY-BEER, WINE & SPIRITS-|',
  'Grocery > Condiment & Sauces > Pickles':
    '|GROCERY-CONDIMENT & SAUCES-PICKLES|',
  'Grocery > Canned & Packaged': '|GROCERY-CANNED & PACKAGED-|',
  'Health & Beauty > Feminine Products > Pads/Panty Liners':
    '|HEALTH & BEAUTY-FEMININE PRODUCTS-PADS/PANTY LINERS|',
  'Health & Beauty > Cosmetics > Nails': '|HEALTH & BEAUTY-COSMETICS-NAILS|',
  McCafe: '|MCCAFE--|',
  'Health & Beauty > Cosmetics': '|HEALTH & BEAUTY-COSMETICS-|',
  'Pet > Cats > Wet Food': '|PET-CATS-WET FOOD|',
  'Pet > Cats > Dry Food': '|PET-CATS-DRY FOOD|',
  'Beverages > Soft Drinks > Iced Tea': '|BEVERAGES-SOFT DRINKS-ICED TEA|',
  'Health & Beauty > Bath & Shower': '|HEALTH & BEAUTY-BATH & SHOWER-|',
  'Grocery > Beverages > Nutritional': '|GROCERY-BEVERAGES-NUTRITIONAL|',
  'Grocery > Beverages > Punch': '|GROCERY-BEVERAGES-PUNCH|',
  'Home & Garden > Home Essentials > Batteries':
    '|HOME & GARDEN-HOME ESSENTIALS-BATTERIES|',
  'Grocery > Paper & Plastics > Tissue & Wipes':
    '|GROCERY-PAPER & PLASTICS-TISSUE & WIPES|',
  'Grocery > Bakery & Bread > Bagels': '|GROCERY-BAKERY & BREAD-BAGELS|',
  'Grocery > Meat & Seafood > Meat-Deli Sliced':
    '|GROCERY-MEAT & SEAFOOD-MEAT-DELI SLICED|',
  'Food > Dessert > Ice Cream & Gelato': '|FOOD-DESSERT-ICE CREAM & GELATO|',
  'Pet > Dogs > Wet Food': '|PET-DOGS-WET FOOD|',
  'Health & Beauty > Pain Relief > Adult Pain-Fever':
    '|HEALTH & BEAUTY-PAIN RELIEF-ADULT PAIN-FEVER|',
  'Grocery > Pasta & Grains > Instant Potatoes':
    '|GROCERY-PASTA & GRAINS-INSTANT POTATOES|',
  'Health & Beauty > Hair Care > Accessories':
    '|HEALTH & BEAUTY-HAIR CARE-ACCESSORIES|',
  'Health & Beauty > Oral Care': '|HEALTH & BEAUTY-ORAL CARE-|',
  'Grocery > Cleaning Products > Cleaning Pads':
    '|GROCERY-CLEANING PRODUCTS-CLEANING PADS|',
  'Grocery > Paper & Plastics > Trash Bags':
    '|GROCERY-PAPER & PLASTICS-TRASH BAGS|',
  'Health & Beauty > Cold & Allergy > Adult':
    '|HEALTH & BEAUTY-COLD & ALLERGY-ADULT|',
  'Health & Beauty > Hair Care > Hair Coloring':
    '|HEALTH & BEAUTY-HAIR CARE-HAIR COLORING|',
  'Grocery > Baking > Frosting & Decorating':
    '|GROCERY-BAKING-FROSTING & DECORATING|',
  'Grocery > Paper & Plastics > Food Containers':
    '|GROCERY-PAPER & PLASTICS-FOOD CONTAINERS|',
  'Grocery > Condiment & Sauces > Dessert Toppings':
    '|GROCERY-CONDIMENT & SAUCES-DESSERT TOPPINGS|',
  'Health & Beauty > Oral Care > Mouthwash & Gum':
    '|HEALTH & BEAUTY-ORAL CARE-MOUTHWASH & GUM|',
  'Grocery > Meat & Seafood > Fish Market':
    '|GROCERY-MEAT & SEAFOOD-FISH MARKET|',
  'Grocery > Paper & Plastics > Food Bags':
    '|GROCERY-PAPER & PLASTICS-FOOD BAGS|',
  'Health & Beauty > Cosmetics > Lipstick':
    '|HEALTH & BEAUTY-COSMETICS-LIPSTICK|',
  'Beverages > Other Cold': '|BEVERAGES-OTHER COLD-|',
  'Grocery > International > Asian': '|GROCERY-INTERNATIONAL-ASIAN|',
  'Grocery > Snacks > Dips': '|GROCERY-SNACKS-DIPS|',
  'Beverages > Coffee > Iced Coffee': '|BEVERAGES-COFFEE-ICED COFFEE|',
  'Grocery > Condiment & Sauces > Ketchup':
    '|GROCERY-CONDIMENT & SAUCES-KETCHUP|',
  'Pet > Dogs': '|PET-DOGS-|',
  'Grocery > Baking > Flour & Meal': '|GROCERY-BAKING-FLOUR & MEAL|',
  'Grocery > Breakfast > Toaster Pastries':
    '|GROCERY-BREAKFAST-TOASTER PASTRIES|',
  Bakery: '|BAKERY--|',
  'Home & Garden > BBQ & Garden > Matches & Logs':
    '|HOME & GARDEN-BBQ & GARDEN-MATCHES & LOGS|',
  'Grocery > Bakery & Bread > Doughnuts': '|GROCERY-BAKERY & BREAD-DOUGHNUTS|',
  'Grocery > Canned & Packaged > Latin Canned & Dried':
    '|GROCERY-CANNED & PACKAGED-LATIN CANNED & DRIED|',
  "Health & Beauty > Shaving Care > Women's Shaving":
    "|HEALTH & BEAUTY-SHAVING CARE-WOMEN'S SHAVING|",
  'Grocery > Snacks > Doughnuts': '|GROCERY-SNACKS-DOUGHNUTS|',
  'Health & Beauty > Bath & Shower > Soap - Bar':
    '|HEALTH & BEAUTY-BATH & SHOWER-SOAP - BAR|',
  'Grocery > Condiment & Sauces > Jellies & Jam':
    '|GROCERY-CONDIMENT & SAUCES-JELLIES & JAM|',
  'Health & Beauty > Hair Care > Gels/Mousse':
    '|HEALTH & BEAUTY-HAIR CARE-GELS/MOUSSE|',
  'Grocery > Canned & Packaged > Skillet Dinners':
    '|GROCERY-CANNED & PACKAGED-SKILLET DINNERS|',
  'Health & Beauty > Feminine Products > Feminine Hygiene':
    '|HEALTH & BEAUTY-FEMININE PRODUCTS-FEMININE HYGIENE|',
  Combos: '|COMBOS--|',
  'Health & Beauty > Feminine Products > Tampons':
    '|HEALTH & BEAUTY-FEMININE PRODUCTS-TAMPONS|',
  'Food > Meat Entrees > Chicken Entrees':
    '|FOOD-MEAT ENTREES-CHICKEN ENTREES|',
  'Health & Beauty > Bath & Shower > Soap - Liquid':
    '|HEALTH & BEAUTY-BATH & SHOWER-SOAP - LIQUID|',
  'Food > Tacos & Burritos > Meat Burritos':
    '|FOOD-TACOS & BURRITOS-MEAT BURRITOS|',
  'Grocery > Condiment & Sauces > Peanut Butter':
    '|GROCERY-CONDIMENT & SAUCES-PEANUT BUTTER|',
  'All Day Breakfast': '|ALL DAY BREAKFAST--|',
  'Grocery > Canned & Packaged > Baked Beans':
    '|GROCERY-CANNED & PACKAGED-BAKED BEANS|',
  'Grocery > Canned & Packaged > Chili & Stews':
    '|GROCERY-CANNED & PACKAGED-CHILI & STEWS|',
  'Grocery > Baking > Puddings & Gelatins':
    '|GROCERY-BAKING-PUDDINGS & GELATINS|',
  'Shop All Cookies': '|SHOP ALL COOKIES--|',
  'Grocery > Canned & Packaged > Meats': '|GROCERY-CANNED & PACKAGED-MEATS|',
  'Baby > Diapering Needs > Wipes & Creams':
    '|BABY-DIAPERING NEEDS-WIPES & CREAMS|',
  'Home & Garden': '|HOME & GARDEN--|',
  'Grocery > Paper & Plastics > Wraps & Foils':
    '|GROCERY-PAPER & PLASTICS-WRAPS & FOILS|',
  'Pet > Cats > Cat Litter': '|PET-CATS-CAT LITTER|',
  Burritos: '|BURRITOS--|',
  'Grocery > Cleaning Products > Utility Closet':
    '|GROCERY-CLEANING PRODUCTS-UTILITY CLOSET|',
  Breakfast: '|BREAKFAST--|',
  'Beverages > Soft Drinks > Lemonade': '|BEVERAGES-SOFT DRINKS-LEMONADE|',
  "Health & Beauty > Shaving Care > Men's Shaving":
    "|HEALTH & BEAUTY-SHAVING CARE-MEN'S SHAVING|",
  'Grocery > Canned & Packaged > Pasta': '|GROCERY-CANNED & PACKAGED-PASTA|',
  'Grocery > Herbs. Spices & Seasonings':
    '|GROCERY-HERBS. SPICES & SEASONINGS-|',
  Drinks: '|DRINKS--|',
  'Grocery > Produce > Organics': '|GROCERY-PRODUCE-ORGANICS|',
  Baby: '|BABY--|',
  'Grocery > Baking > Cake Mixes': '|GROCERY-BAKING-CAKE MIXES|',
  'Office > Pens & Pencils': '|OFFICE-PENS & PENCILS-|',
  'Food > Appetizers & Sides > Chips & Nachos':
    '|FOOD-APPETIZERS & SIDES-CHIPS & NACHOS|',
  'EntrÃ©es': '|ENTRÃ©ES--|',
  'Health & Beauty > Diet & Vitamins > Diet Supplements':
    '|HEALTH & BEAUTY-DIET & VITAMINS-DIET SUPPLEMENTS|',
  'Grocery > Bakery & Bread > Muffins & Scones':
    '|GROCERY-BAKERY & BREAD-MUFFINS & SCONES|',
  'Office > Markers & Crayons': '|OFFICE-MARKERS & CRAYONS-|',
  'Misc. > Store Services > Gift Cards': '|MISC.-STORE SERVICES-GIFT CARDS|',
  'Grocery > Condiment & Sauces > Olives & Capers':
    '|GROCERY-CONDIMENT & SAUCES-OLIVES & CAPERS|',
  'Health & Beauty > Hair Care > Hair Spray':
    '|HEALTH & BEAUTY-HAIR CARE-HAIR SPRAY|',
  'Health & Beauty > Hair Care > Hair Preparations':
    '|HEALTH & BEAUTY-HAIR CARE-HAIR PREPARATIONS|',
  'Grocery > Condiment & Sauces > Mustard & Horseradish':
    '|GROCERY-CONDIMENT & SAUCES-MUSTARD & HORSERADISH|',
  'Grocery > Condiment & Sauces > Spreads':
    '|GROCERY-CONDIMENT & SAUCES-SPREADS|',
  Candles: '|CANDLES--|',
  'Grocery > Baking': '|GROCERY-BAKING-|',
  Office: '|OFFICE--|',
  'Food > Dessert > Cookies': '|FOOD-DESSERT-COOKIES|',
  'Home & Garden > Home Essentials > Accessories':
    '|HOME & GARDEN-HOME ESSENTIALS-ACCESSORIES|',
  'Food AM': '|FOOD AM--|',
  'Beverages > Coffee': '|BEVERAGES-COFFEE-|',
  'Grocery > Beer, Wine & Spirits > Malt Beverages':
    '|GROCERY-BEER, WINE & SPIRITS-MALT BEVERAGES|',
  'Grocery > Pasta & Grains': '|GROCERY-PASTA & GRAINS-|',
  'Grocery > Frozen > Frozen Fruit': '|GROCERY-FROZEN-FROZEN FRUIT|',
  'Home & Garden > Home Essentials > Lighting':
    '|HOME & GARDEN-HOME ESSENTIALS-LIGHTING|',
  'Grocery > Dairy > Cottage & Ricotta': '|GROCERY-DAIRY-COTTAGE & RICOTTA|',
  'Grocery > Dairy > Dips': '|GROCERY-DAIRY-DIPS|',
  'Health & Beauty > Deodorants': '|HEALTH & BEAUTY-DEODORANTS-|',
  'Grocery > Tobacco': '|GROCERY-TOBACCO-|',
  'Home & Garden > Home Essentials > Scented Candles':
    '|HOME & GARDEN-HOME ESSENTIALS-SCENTED CANDLES|',
  'Grocery > Beverages > Lemonade': '|GROCERY-BEVERAGES-LEMONADE|',
  'Home & Garden > Kitchen > General Housewares':
    '|HOME & GARDEN-KITCHEN-GENERAL HOUSEWARES|',
  UNNAV: '|UNNAV--|',
  'Food > Appetizers & Sides > Other': '|FOOD-APPETIZERS & SIDES-OTHER|',
  'Beverages > Juices': '|BEVERAGES-JUICES-|',
  'Grocery > Condiment & Sauces > Vinegar':
    '|GROCERY-CONDIMENT & SAUCES-VINEGAR|',
  'Grocery > Cleaning Products > Floors & Carpet':
    '|GROCERY-CLEANING PRODUCTS-FLOORS & CARPET|',
  'Grocery > Dairy > Pudding & Gelatin': '|GROCERY-DAIRY-PUDDING & GELATIN|',
  'Food > Dessert > Cakes & Pies': '|FOOD-DESSERT-CAKES & PIES|',
  'Health & Beauty > First Aid > Creams & Sprays':
    '|HEALTH & BEAUTY-FIRST AID-CREAMS & SPRAYS|',
  'Office > Stationery': '|OFFICE-STATIONERY-|',
  'Wax Warmers': '|WAX WARMERS--|',
  'Baby Wipes': '|BABY WIPES--|',
  'Food > Rice & Noodle Dishes': '|FOOD-RICE & NOODLE DISHES-|',
  'Health & Beauty > Cosmetics > Miscellaneous':
    '|HEALTH & BEAUTY-COSMETICS-MISCELLANEOUS|',
  Desserts: '|DESSERTS--|',
  'Food > Sandwiches & Wraps > Fish Sandwich':
    '|FOOD-SANDWICHES & WRAPS-FISH SANDWICH|',
  'Food > Appetizers & Sides > Quesadillas':
    '|FOOD-APPETIZERS & SIDES-QUESADILLAS|',
  'Beverages > Smoothies': '|BEVERAGES-SMOOTHIES-|',
  'Grocery > Baking > Muffin Mixes': '|GROCERY-BAKING-MUFFIN MIXES|',
  'Health & Beauty > Skin Care': '|HEALTH & BEAUTY-SKIN CARE-|',
  'Health & Beauty > Digestive Aids > Antacids':
    '|HEALTH & BEAUTY-DIGESTIVE AIDS-ANTACIDS|',
  'Health & Beauty > Skin Care > Foot': '|HEALTH & BEAUTY-SKIN CARE-FOOT|',
  Toothpaste: '|TOOTHPASTE--|',
  'Grocery > Beer, Wine & Spirits > Mixers':
    '|GROCERY-BEER, WINE & SPIRITS-MIXERS|',
  'Home & Garden > BBQ & Garden > Grill': '|HOME & GARDEN-BBQ & GARDEN-GRILL|',
  Mainstays: '|MAINSTAYS--|',
  'Deals and Combos': '|DEALS AND COMBOS--|',
  'Health & Beauty > Diet & Vitamins': '|HEALTH & BEAUTY-DIET & VITAMINS-|',
  'Snacks, Sides & More': '|SNACKS, SIDES & MORE--|',
  'Bath Towels': '|BATH TOWELS--|',
  'Candy & Chocolate': '|CANDY & CHOCOLATE--|',
  'Beverages > Soft Drinks': '|BEVERAGES-SOFT DRINKS-|',
  'Entertainment > Books & Magazines': '|ENTERTAINMENT-BOOKS & MAGAZINES-|',
  'Grocery > Breakfast > Syrup': '|GROCERY-BREAKFAST-SYRUP|',
  'Pet > Cats': '|PET-CATS-|',
  'Beverages > Tea': '|BEVERAGES-TEA-|',
  'Baby > Diapering Needs > Diapers': '|BABY-DIAPERING NEEDS-DIAPERS|',
  'Food > Appetizers & Sides > Wings': '|FOOD-APPETIZERS & SIDES-WINGS|',
  Nails: '|NAILS--|',
  'Pet > Cats > Treats': '|PET-CATS-TREATS|',
  'Great Value Trash Bags': '|GREAT VALUE TRASH BAGS--|',
  'Beauty Next Day': '|BEAUTY NEXT DAY--|',
  Sides: '|SIDES--|',
  'Baby > Baby Bath & Skin Care': '|BABY-BABY BATH & SKIN CARE-|',
  'Health & Beauty > Cold & Allergy > Sore Throats':
    '|HEALTH & BEAUTY-COLD & ALLERGY-SORE THROATS|',
  'Grocery > Paper & Plastics > Napkins': '|GROCERY-PAPER & PLASTICS-NAPKINS|',
  'Pet > Dogs > Toys & Accessories': '|PET-DOGS-TOYS & ACCESSORIES|',
  'Automotive > Maintenance': '|AUTOMOTIVE-MAINTENANCE-|',
  'Fries, Sides & More': '|FRIES, SIDES & MORE--|',
  'Coffee and Beverages': '|COFFEE AND BEVERAGES--|',
  'Breakfast Entrees': '|BREAKFAST ENTREES--|',
  'Grocery > Deli > Cheese-Deli Sliced': '|GROCERY-DELI-CHEESE-DELI SLICED|',
  'Air Fresheners': '|AIR FRESHENERS--|',
  'Grocery > Baking > Brownie Mixes': '|GROCERY-BAKING-BROWNIE MIXES|',
  'Apparel > Boutique > Women': '|APPAREL-BOUTIQUE-WOMEN|',
  'Health & Beauty > Cosmetics > Accessories':
    '|HEALTH & BEAUTY-COSMETICS-ACCESSORIES|',
  'Grocery > Baking > Chocolate & Chips': '|GROCERY-BAKING-CHOCOLATE & CHIPS|',
  Salads: '|SALADS--|',
  'Health & Beauty > Feminine Products > Tests':
    '|HEALTH & BEAUTY-FEMININE PRODUCTS-TESTS|',
  'Beverages > Water > Still Water': '|BEVERAGES-WATER-STILL WATER|',
  'Home & Garden > BBQ & Garden > Insect Repellant':
    '|HOME & GARDEN-BBQ & GARDEN-INSECT REPELLANT|',
  'Bathroom Accessories': '|BATHROOM ACCESSORIES--|',
  'Body Lotions & Creams': '|BODY LOTIONS & CREAMS--|',
  'Health & Beauty > Eye & Ear Care > Ear':
    '|HEALTH & BEAUTY-EYE & EAR CARE-EAR|',
  'Baby > Baby Foods': '|BABY-BABY FOODS-|',
  'Sauce Packets': '|SAUCE PACKETS--|',
  'Frito-Lay': '|FRITO-LAY--|',
  'Party > Cards & Decoration': '|PARTY-CARDS & DECORATION-|',
  Tacos: '|TACOS--|',
  Software: '|SOFTWARE--|',
  'Food > Bowls > Meat Bowl': '|FOOD-BOWLS-MEAT BOWL|',
  'Beverages > Soft Drinks > Diet Soda': '|BEVERAGES-SOFT DRINKS-DIET SODA|',
  'Health & Beauty > Diet & Vitamins > Multi-Vitamins':
    '|HEALTH & BEAUTY-DIET & VITAMINS-MULTI-VITAMINS|',
  'Health & Beauty > Diet & Vitamins > Vitamins - Adult':
    '|HEALTH & BEAUTY-DIET & VITAMINS-VITAMINS - ADULT|',
  Blizzards: '|BLIZZARDS--|',
  'Health & Beauty > Shaving Care': '|HEALTH & BEAUTY-SHAVING CARE-|',
  'Energy & Sports Drinks': '|ENERGY & SPORTS DRINKS--|',
  'Sweets & Treats': '|SWEETS & TREATS--|',
  'Baby > Baby Foods > Organic': '|BABY-BABY FOODS-ORGANIC|',
  'All Paper Towels': '|ALL PAPER TOWELS--|',
  'Grocery > Breakfast > Pancakes': '|GROCERY-BREAKFAST-PANCAKES|',
  'Home & Garden > Home Essentials > Pest Control':
    '|HOME & GARDEN-HOME ESSENTIALS-PEST CONTROL|',
  'Ultimate Drink StopÂ®': '|ULTIMATE DRINK STOPÂ®--|',
  'Baby > Diapering Needs': '|BABY-DIAPERING NEEDS-|',
  Burgers: '|BURGERS--|',
  'Baby > Baby Foods > Cereals & Snacks': '|BABY-BABY FOODS-CEREALS & SNACKS|',
  'Party > Candles': '|PARTY-CANDLES-|',
  'Grocery > Frozen > Ice': '|GROCERY-FROZEN-ICE|',
  'Baby > Baby Foods > 2nd Foods': '|BABY-BABY FOODS-2ND FOODS|',
  'Gift Wrap & Supplies': '|GIFT WRAP & SUPPLIES--|',
  "Apparel > Boutique > Men's": "|APPAREL-BOUTIQUE-MEN'S|",
  'Automotive > Accessories': '|AUTOMOTIVE-ACCESSORIES-|',
  'Bath and Body Skincare': '|BATH AND BODY SKINCARE--|',
  'Grocery > Bakery & Bread > Croutons': '|GROCERY-BAKERY & BREAD-CROUTONS|',
  Crest: '|CREST--|',
  Socks: '|SOCKS--|',
  'Beverages > Coffee > Drip Coffee': '|BEVERAGES-COFFEE-DRIP COFFEE|',
  'Food > Soups & Salads > Meat/Fish Salad':
    '|FOOD-SOUPS & SALADS-MEAT/FISH SALAD|',
  Chicken: '|CHICKEN--|',
  'Everyday Deals': '|EVERYDAY DEALS--|',
  'Grocery > Produce > Fresh Herbs': '|GROCERY-PRODUCE-FRESH HERBS|',
  'Air Fresheners & Candles': '|AIR FRESHENERS & CANDLES--|',
  'Food > Tacos & Burritos > Veggie Burritos':
    '|FOOD-TACOS & BURRITOS-VEGGIE BURRITOS|',
  'Grocery > Bakery & Bread > English Muffins':
    '|GROCERY-BAKERY & BREAD-ENGLISH MUFFINS|',
  'Grocery > Frozen > Frozen Juices': '|GROCERY-FROZEN-FROZEN JUICES|',
  'Health & Beauty > First Aid > Bandages':
    '|HEALTH & BEAUTY-FIRST AID-BANDAGES|',
  'Soft Drinks': '|SOFT DRINKS--|',
  'Baby > Toys & Accessories': '|BABY-TOYS & ACCESSORIES-|',
  'Food > Dessert > Other Desserts': '|FOOD-DESSERT-OTHER DESSERTS|',
  'Health & Beauty > Diet & Vitamins > Herbal & Specialty':
    '|HEALTH & BEAUTY-DIET & VITAMINS-HERBAL & SPECIALTY|',
  'Surface Care & Protection': '|SURFACE CARE & PROTECTION--|',
  Tampons: '|TAMPONS--|',
  'Chicken & Poultry': '|CHICKEN & POULTRY--|',
  'Bathroom Cleaners': '|BATHROOM CLEANERS--|',
  'Shampoo & Conditioner': '|SHAMPOO & CONDITIONER--|',
  'Wonder Nation': '|WONDER NATION--|',
  'All Feminine Care': '|ALL FEMININE CARE--|',
  'Grocery > Baking > Miscellaneous': '|GROCERY-BAKING-MISCELLANEOUS|',
  Promotions: '|PROMOTIONS--|',
  'Grocery > Baking > Baking Products': '|GROCERY-BAKING-BAKING PRODUCTS|',
  'Grocery > Baking > Honey': '|GROCERY-BAKING-HONEY|',
  'Disposable Diapers': '|DISPOSABLE DIAPERS--|',
  'Health & Beauty > Skin Care > Sun Lotions':
    '|HEALTH & BEAUTY-SKIN CARE-SUN LOTIONS|',
  Tops: '|TOPS--|',
  Liquids: '|LIQUIDS--|',
  'Fries and Sides': '|FRIES AND SIDES--|',
  'Home & Garden > Workbench > Nails & Hooks':
    '|HOME & GARDEN-WORKBENCH-NAILS & HOOKS|',
  'Salad Dressings & Toppings': '|SALAD DRESSINGS & TOPPINGS--|',
  'Food > Pizza & Calzones > Veggie Pizza':
    '|FOOD-PIZZA & CALZONES-VEGGIE PIZZA|',
  'Baby > Diapering Needs > Training Pants':
    '|BABY-DIAPERING NEEDS-TRAINING PANTS|',
  'Health & Beauty > Cold & Allergy': '|HEALTH & BEAUTY-COLD & ALLERGY-|',
  'Automotive > Washes & Wax': '|AUTOMOTIVE-WASHES & WAX-|',
  'Training Pants': '|TRAINING PANTS--|',
  'Dog Treats': '|DOG TREATS--|',
  'Grocery > Baking > Baking Mixes': '|GROCERY-BAKING-BAKING MIXES|',
  'Grocery > Condiment & Sauces > Relishes':
    '|GROCERY-CONDIMENT & SAUCES-RELISHES|',
  'Shop All Equate Items': '|SHOP ALL EQUATE ITEMS--|',
  'Disposable Utensils': '|DISPOSABLE UTENSILS--|',
  'Food > Dessert': '|FOOD-DESSERT-|',
  'Great Value Bags': '|GREAT VALUE BAGS--|',
  '6" Cold Subs': '|6" COLD SUBS--|',
  'All Light Bulbs': '|ALL LIGHT BULBS--|',
  'All Mixed Spices & Seasonings': '|ALL MIXED SPICES & SEASONINGS--|',
  'Grocery > Frozen > Frozen Sides': '|GROCERY-FROZEN-FROZEN SIDES|',
  'Grocery > Frozen > Miscellaneous': '|GROCERY-FROZEN-MISCELLANEOUS|',
  'Tools & Accessories': '|TOOLS & ACCESSORIES--|',
  'Air Freshener & Deodorizer Refills':
    '|AIR FRESHENER & DEODORIZER REFILLS--|',
  'Gummy & Chewy': '|GUMMY & CHEWY--|',
  'Home & Garden > Workbench > Tools': '|HOME & GARDEN-WORKBENCH-TOOLS|',
  'Motor Oil': '|MOTOR OIL--|',
  'Food > Bowls': '|FOOD-BOWLS-|',
  Underwear: '|UNDERWEAR--|',
  'Grocery > Bakery & Bread > Stuffing': '|GROCERY-BAKERY & BREAD-STUFFING|',
  'Home & Garden > BBQ & Garden > Plants & Flowers':
    '|HOME & GARDEN-BBQ & GARDEN-PLANTS & FLOWERS|',
  'Grocery > Snacks > Bars': '|GROCERY-SNACKS-BARS|',
  'Health & Beauty > Oral Care > Denture Care':
    '|HEALTH & BEAUTY-ORAL CARE-DENTURE CARE|',
  'Office > Envelopes': '|OFFICE-ENVELOPES-|',
  'Shop Toys by Price': '|SHOP TOYS BY PRICE--|',
  'All Great Value': '|ALL GREAT VALUE--|',
  'All Paper & Printable Media': '|ALL PAPER & PRINTABLE MEDIA--|',
  'Baby > Baby Foods > Toddlers': '|BABY-BABY FOODS-TODDLERS|',
  'Disposable Tableware': '|DISPOSABLE TABLEWARE--|',
  'Health & Beauty > Digestive Aids > Laxatives':
    '|HEALTH & BEAUTY-DIGESTIVE AIDS-LAXATIVES|',
  'Grocery > Baking > Extracts': '|GROCERY-BAKING-EXTRACTS|',
  'Grocery > International > Latin/Mexican':
    '|GROCERY-INTERNATIONAL-LATIN/MEXICAN|',
  Pet: '|PET--|',
  'Baby > Bottles & Nursing': '|BABY-BOTTLES & NURSING-|',
  'Pet > Cats > Toys & Accessories': '|PET-CATS-TOYS & ACCESSORIES|',
  'Gift Wrap, Bags & Accessories': '|GIFT WRAP, BAGS & ACCESSORIES--|',
  'Home & Garden > Home Essentials': '|HOME & GARDEN-HOME ESSENTIALS-|',
  'Personal Care': '|PERSONAL CARE--|',
  'Breakfast Beverages': '|BREAKFAST BEVERAGES--|',
  'First Aid': '|FIRST AID--|',
  'Laundry Detergent': '|LAUNDRY DETERGENT--|',
  'Pain Relievers': '|PAIN RELIEVERS--|',
  'Sponges and Scrubbers': '|SPONGES AND SCRUBBERS--|',
  'Health & Beauty > Cold & Allergy > Children':
    '|HEALTH & BEAUTY-COLD & ALLERGY-CHILDREN|',
  'Health & Beauty > Hair Care > Combs & Brushes':
    '|HEALTH & BEAUTY-HAIR CARE-COMBS & BRUSHES|',
  Powerade: '|POWERADE--|',
  'Food > Appetizers & Sides > Mozzarella Sticks':
    '|FOOD-APPETIZERS & SIDES-MOZZARELLA STICKS|',
  'A La Carte EntrÃ©es': '|A LA CARTE ENTRÃ©ES--|',
  Hangers: '|HANGERS--|',
  'Non-Item': '|NON-ITEM--|',
  'Signature Crafted': '|SIGNATURE CRAFTED--|',
  'Dog Food': '|DOG FOOD--|',
  'No Boundaries': '|NO BOUNDARIES--|',
  'Deodorants & Antiperspirants': '|DEODORANTS & ANTIPERSPIRANTS--|',
  'Loofahs, Bath Sponges & Accessories':
    '|LOOFAHS, BATH SPONGES & ACCESSORIES--|',
  'Hair Care': '|HAIR CARE--|',
  'Baby > Formula': '|BABY-FORMULA-|',
  'Water Bottles & Hydration': '|WATER BOTTLES & HYDRATION--|',
  Meals: '|MEALS--|',
  Shampoo: '|SHAMPOO--|',
  'Clover Valley': '|CLOVER VALLEY--|',
  'DQ Combos': '|DQ COMBOS--|',
  "Arby's Roast Beef Sandwiches": "|ARBY'S ROAST BEEF SANDWICHES--|",
  'Baby > Baby Foods > Juices & Water': '|BABY-BABY FOODS-JUICES & WATER|',
  'Bath Bombs, Bubble Baths & Soaks': '|BATH BOMBS, BUBBLE BATHS & SOAKS--|',
  Conditioner: '|CONDITIONER--|',
  'Cookies & Snack Cakes': '|COOKIES & SNACK CAKES--|',
  'Fashion Dolls': '|FASHION DOLLS--|',
  'Health & Beauty > Cold & Allergy > Nasal Products':
    '|HEALTH & BEAUTY-COLD & ALLERGY-NASAL PRODUCTS|',
  'Craft Kits': '|CRAFT KITS--|',
  'Coffee Mugs': '|COFFEE MUGS--|',
  'Friends of Meat': '|FRIENDS OF MEAT--|',
  'Grocery > Beverages > Cocoa': '|GROCERY-BEVERAGES-COCOA|',
  'Oral Care': '|ORAL CARE--|',
  Electronics: '|ELECTRONICS--|',
  'Grocery > Baking > Rising Agents': '|GROCERY-BAKING-RISING AGENTS|',
  Jeans: '|JEANS--|',
  'Styling Accessories': '|STYLING ACCESSORIES--|',
  'Hand Soaps': '|HAND SOAPS--|',
  'Happy Meal': '|HAPPY MEAL--|',
  'Health & Beauty > Intimate Care > Contraceptives':
    '|HEALTH & BEAUTY-INTIMATE CARE-CONTRACEPTIVES|',
  'Manual Toothbrushes': '|MANUAL TOOTHBRUSHES--|',
  'All Purpose Cleaners': '|ALL PURPOSE CLEANERS--|',
  'facial skincare': '|FACIAL SKINCARE--|',
  'Hand Sanitizer': '|HAND SANITIZER--|',
  'Baby > Baby Foods > 3rd Foods': '|BABY-BABY FOODS-3RD FOODS|',
  Breads: '|BREADS--|',
  Eyes: '|EYES--|',
  'Food > Appetizers & Sides > Bread': '|FOOD-APPETIZERS & SIDES-BREAD|',
  'Plastic Wrap': '|PLASTIC WRAP--|',
  'Candy & Gum': '|CANDY & GUM--|',
  'Fabric Softeners': '|FABRIC SOFTENERS--|',
  'Shop Pens by Pen Point Style': '|SHOP PENS BY PEN POINT STYLE--|',
  'Shower Curtains': '|SHOWER CURTAINS--|',
  'Snacks & Sides': '|SNACKS & SIDES--|',
  'Food > Soups & Salads > Soups': '|FOOD-SOUPS & SALADS-SOUPS|',
  'Home & Garden > BBQ & Garden': '|HOME & GARDEN-BBQ & GARDEN-|',
  'Laundry Care': '|LAUNDRY CARE--|',
  Sandwiches: '|SANDWICHES--|',
  'Frozen Zone': '|FROZEN ZONE--|',
  'Health & Beauty > Eye & Ear Care > Eye - Solutions':
    '|HEALTH & BEAUTY-EYE & EAR CARE-EYE - SOLUTIONS|',
  'Cheese Curls & Puffs': '|CHEESE CURLS & PUFFS--|',
  'Dry Dog Food': '|DRY DOG FOOD--|',
  'Grocery > Deli': '|GROCERY-DELI-|',
  'More Treats': '|MORE TREATS--|',
  'All Sealants, Fillers & Adhesives': '|ALL SEALANTS, FILLERS & ADHESIVES--|',
  Appetizers: '|APPETIZERS--|',
  'Dish Soap': '|DISH SOAP--|',
  'Equate Skincare': '|EQUATE SKINCARE--|',
  "Macy's": "|MACY'S--|",
  Spirits: '|SPIRITS--|',
  'Car Air Fresheners': '|CAR AIR FRESHENERS--|',
  'Spray Paint': '|SPRAY PAINT--|',
  Supplements: '|SUPPLEMENTS--|',
  'All Chips': '|ALL CHIPS--|',
  Automotive: '|AUTOMOTIVE--|',
  'Food > Appetizers & Sides > Cheeses': '|FOOD-APPETIZERS & SIDES-CHEESES|',
  'Health & Beauty > Personal Care > Miscellaneous':
    '|HEALTH & BEAUTY-PERSONAL CARE-MISCELLANEOUS|',
  'Oral Rinse & Mouthwash': '|ORAL RINSE & MOUTHWASH--|',
  'Tees & Tanks': '|TEES & TANKS--|',
  'All TVs': '|ALL TVS--|',
  Headphones: '|HEADPHONES--|',
  'Health & Beauty > Intimate Care > Lubricants':
    '|HEALTH & BEAUTY-INTIMATE CARE-LUBRICANTS|',
  'All Dog Toys': '|ALL DOG TOYS--|',
  Allergy: '|ALLERGY--|',
  Bottoms: '|BOTTOMS--|',
  'Craft Paint': '|CRAFT PAINT--|',
  'Grocery > Pasta & Grains > Other Grains':
    '|GROCERY-PASTA & GRAINS-OTHER GRAINS|',
  'Health & Beauty > Digestive Aids > Stomach Relief':
    '|HEALTH & BEAUTY-DIGESTIVE AIDS-STOMACH RELIEF|',
  'Health & Beauty > Pain Relief > Children Pain-Fever':
    '|HEALTH & BEAUTY-PAIN RELIEF-CHILDREN PAIN-FEVER|',
  Marshmallows: '|MARSHMALLOWS--|',
  'Organic Foods': '|ORGANIC FOODS--|',
  'Dinnerware Sets': '|DINNERWARE SETS--|',
  Party: '|PARTY--|',
  Snacks: '|SNACKS--|',
  'Garlic Powder': '|GARLIC POWDER--|',
  'Grocery > Produce > Salad Bar': '|GROCERY-PRODUCE-SALAD BAR|',
  'Styling Products': '|STYLING PRODUCTS--|',
  'Cranberry Juice': '|CRANBERRY JUICE--|',
  'Disposable Cups': '|DISPOSABLE CUPS--|',
  'Health & Beauty > Eye & Ear Care > Contact Products':
    '|HEALTH & BEAUTY-EYE & EAR CARE-CONTACT PRODUCTS|',
  'All Baking Mixes': '|ALL BAKING MIXES--|',
  Bras: '|BRAS--|',
  'Curtain Rods & Hardware': '|CURTAIN RODS & HARDWARE--|',
  'Bar Soap': '|BAR SOAP--|',
  Batteries: '|BATTERIES--|',
  'Food > Appetizers & Sides > Potatoes': '|FOOD-APPETIZERS & SIDES-POTATOES|',
  'Food > Tacos & Burritos': '|FOOD-TACOS & BURRITOS-|',
  'Hair Color': '|HAIR COLOR--|',
  'Health & Beauty > First Aid > Gauze & Tapes':
    '|HEALTH & BEAUTY-FIRST AID-GAUZE & TAPES|',
  Utensils: '|UTENSILS--|',
  'Great Value Toilet Paper': '|GREAT VALUE TOILET PAPER--|',
  'Grocery > Baking > Cookie & Bar Mixes':
    '|GROCERY-BAKING-COOKIE & BAR MIXES|',
  'Oil Diffusers': '|OIL DIFFUSERS--|',
  'Grocery > Baking > Rolls & Crust': '|GROCERY-BAKING-ROLLS & CRUST|',
  'Grocery > Candy > Sugar Free & Dietetic':
    '|GROCERY-CANDY-SUGAR FREE & DIETETIC|',
  'Juice & Cider': '|JUICE & CIDER--|',
  'Best Laundry Detergents': '|BEST LAUNDRY DETERGENTS--|',
  'Cat Food': '|CAT FOOD--|',
  'Grocery > Cleaning Products > Furniture':
    '|GROCERY-CLEANING PRODUCTS-FURNITURE|',
  'Hot and Juicy Cheeseburger': '|HOT AND JUICY CHEESEBURGER--|',
  Quesadillas: '|QUESADILLAS--|',
  Shirts: '|SHIRTS--|',
  'Already Popped': '|ALREADY POPPED--|',
  'Antibiotics & Antiseptics': '|ANTIBIOTICS & ANTISEPTICS--|',
  'Dawn Dish Soap': '|DAWN DISH SOAP--|',
  'Health & Beauty > First Aid > Kits & Tools':
    '|HEALTH & BEAUTY-FIRST AID-KITS & TOOLS|',
  'Home & Garden > Bed & Bath > Bathing Aids':
    '|HOME & GARDEN-BED & BATH-BATHING AIDS|',
  "Kid's Meals": "|KID'S MEALS--|",
  'New Hair Care': '|NEW HAIR CARE--|',
  Baskets: '|BASKETS--|',
  'Equate Headaches & Fever Relief': '|EQUATE HEADACHES & FEVER RELIEF--|',
  'Equate Heartburn Relief & Antacids':
    '|EQUATE HEARTBURN RELIEF & ANTACIDS--|',
  'Food > Appetizers & Sides': '|FOOD-APPETIZERS & SIDES-|',
  "Girls' Clothing": "|GIRLS' CLOTHING--|",
  'Grocery > Baking > Bread Mixes': '|GROCERY-BAKING-BREAD MIXES|',
  'Scented Candles': '|SCENTED CANDLES--|',
  'Syrups & Sweeteners': '|SYRUPS & SWEETENERS--|',
  'Composition Books': '|COMPOSITION BOOKS--|',
  Foundation: '|FOUNDATION--|',
  'Grocery > Baby Bath & Skin Care > Beer':
    '|GROCERY-BABY BATH & SKIN CARE-BEER|',
  'Health & Beauty > Cosmetics > Cotton': '|HEALTH & BEAUTY-COSMETICS-COTTON|',
  'pet food and snacks': '|PET FOOD AND SNACKS--|',
  Treats: '|TREATS--|',
  "Women's Razor Blades": "|WOMEN'S RAZOR BLADES--|",
  'All Prep and Application': '|ALL PREP AND APPLICATION--|',
  'Cleaning Supplies': '|CLEANING SUPPLIES--|',
  'Cough, Cold & Flu': '|COUGH, COLD & FLU--|',
  'Health & Beauty > Bath & Shower > Beads/Foams/Salts':
    '|HEALTH & BEAUTY-BATH & SHOWER-BEADS/FOAMS/SALTS|',
  'Kitchen Tools & Gadgets': '|KITCHEN TOOLS & GADGETS--|',
  'Party Packs': '|PARTY PACKS--|',
  'Pen+Gear': '|PEN+GEAR--|',
  'Basic Bed Pillows': '|BASIC BED PILLOWS--|',
  'Drawing & Coloring': '|DRAWING & COLORING--|',
  'Food > Breakfast > Meat': '|FOOD-BREAKFAST-MEAT|',
  'Garanimals All': '|GARANIMALS ALL--|',
  'Grocery > Baking > Canning Supplies': '|GROCERY-BAKING-CANNING SUPPLIES|',
  'Health & Beauty > Eye & Ear Care > Eye Care':
    '|HEALTH & BEAUTY-EYE & EAR CARE-EYE CARE|',
  'Hyper Tough': '|HYPER TOUGH--|',
  "Men's Grooming": "|MEN'S GROOMING--|",
  'Taco Seasoning': '|TACO SEASONING--|',
  'Windshield Washer Fluid': '|WINDSHIELD WASHER FLUID--|',
  Toothbrushes: '|TOOTHBRUSHES--|',
  'Trash and refuse bags': '|TRASH AND REFUSE BAGS--|',
  'Automotive Electrical': '|AUTOMOTIVE ELECTRICAL--|',
  'Health & Beauty > First Aid': '|HEALTH & BEAUTY-FIRST AID-|',
  'Air Filters': '|AIR FILTERS--|',
  'All Coca-Cola': '|ALL COCA-COLA--|',
  Biscuits: '|BISCUITS--|',
  'Cereal Bars & Breakfast Bars': '|CEREAL BARS & BREAKFAST BARS--|',
  'Facial Cleansers': '|FACIAL CLEANSERS--|',
  'Food > Pizza & Calzones > Meat Pizza': '|FOOD-PIZZA & CALZONES-MEAT PIZZA|',
  'Charmin Toilet Paper': '|CHARMIN TOILET PAPER--|',
  'Grocery > International > Thai/Indian':
    '|GROCERY-INTERNATIONAL-THAI/INDIAN|',
  'Health & Beauty > Sleeping': '|HEALTH & BEAUTY-SLEEPING-|',
  'Hot Desserts a la Mode': '|HOT DESSERTS A LA MODE--|',
  'Paper Plates': '|PAPER PLATES--|',
  'Right Price Right Size Menu': '|RIGHT PRICE RIGHT SIZE MENU--|',
  'Scent Boosters': '|SCENT BOOSTERS--|',
  'Classic Cakes': '|CLASSIC CAKES--|',
  'Health & Beauty > Pain Relief > Muscle & Back':
    '|HEALTH & BEAUTY-PAIN RELIEF-MUSCLE & BACK|',
  'Home & Garden > Kitchen': '|HOME & GARDEN-KITCHEN-|',
  New: '|NEW--|',
  'Equate Facial Care': '|EQUATE FACIAL CARE--|',
  'Grocery > Frozen > Frozen Kids Meals': '|GROCERY-FROZEN-FROZEN KIDS MEALS|',
  'Hair Accessories': '|HAIR ACCESSORIES--|',
  'Health & Beauty > Intimate Care > Incontinence':
    '|HEALTH & BEAUTY-INTIMATE CARE-INCONTINENCE|',
  'Meal Deals': '|MEAL DEALS--|',
  'Sides & Desserts': '|SIDES & DESSERTS--|',
  Sweaters: '|SWEATERS--|',
  'writing instruments and accessories':
    '|WRITING INSTRUMENTS AND ACCESSORIES--|',
  'Womens Sweaters': '|WOMENS SWEATERS--|',
  'Action Figures': '|ACTION FIGURES--|',
  'Baby Beverages': '|BABY BEVERAGES--|',
  'Breakfast Sides': '|BREAKFAST SIDES--|',
  'Food > Appetizers & Sides > Onion Rings':
    '|FOOD-APPETIZERS & SIDES-ONION RINGS|',
  'Health & Beauty > Feminine Products': '|HEALTH & BEAUTY-FEMININE PRODUCTS-|',
  Makeup: '|MAKEUP--|',
  'All Dog Treats': '|ALL DOG TREATS--|',
  'Bed Pillows': '|BED PILLOWS--|',
  'Beverages > Sports Drinks': '|BEVERAGES-SPORTS DRINKS-|',
  Scarves: '|SCARVES--|',
  'gift bags and accessories': '|GIFT BAGS AND ACCESSORIES--|',
  'Hand Soap & Sanitizers': '|HAND SOAP & SANITIZERS--|',
  'Pet > Other Pet Food & Treats': '|PET-OTHER PET FOOD & TREATS-|',
  'Snacks & Cookies': '|SNACKS & COOKIES--|',
  'Solid Color Cups': '|SOLID COLOR CUPS--|',
  'Special Items And Events': '|SPECIAL ITEMS AND EVENTS--|',
  'adhesives, fasteners, and magnets': '|ADHESIVES, FASTENERS, AND MAGNETS--|',
  Apparel: '|APPAREL--|',
  'Feminine Hygiene': '|FEMININE HYGIENE--|',
  'Food > Breakfast > Pancakes': '|FOOD-BREAKFAST-PANCAKES|',
  'Grocery > Dairy > Spreads': '|GROCERY-DAIRY-SPREADS|',
  'Health & Beauty > Eye & Ear Care': '|HEALTH & BEAUTY-EYE & EAR CARE-|',
  Shorts: '|SHORTS--|',
  Sprays: '|SPRAYS--|',
  'Doll Playsets': '|DOLL PLAYSETS--|',
  'Fashion Earrings': '|FASHION EARRINGS--|',
  'Flushable Wipes': '|FLUSHABLE WIPES--|',
  'Grocery > Canned & Packaged > Asian Canned & Dried':
    '|GROCERY-CANNED & PACKAGED-ASIAN CANNED & DRIED|',
  "Kellogg's Cereals": "|KELLOGG'S CEREALS--|",
  'Laundry Bleach': '|LAUNDRY BLEACH--|',
  'Bath & Facial Tissue': '|BATH & FACIAL TISSUE--|',
  'Board Games': '|BOARD GAMES--|',
  'Chicken Combos': '|CHICKEN COMBOS--|',
  'Clothes Hangers': '|CLOTHES HANGERS--|',
  'Feminine Care': '|FEMININE CARE--|',
  'Kids Menu': '|KIDS MENU--|',
  'Laundry Cleaning': '|LAUNDRY CLEANING--|',
  'Liquid Detergents': '|LIQUID DETERGENTS--|',
  'Pet > Other Pet Accessories': '|PET-OTHER PET ACCESSORIES-|',
  'Acrylic Paints': '|ACRYLIC PAINTS--|',
  'Fresh Made Salad': '|FRESH MADE SALAD--|',
  'Insect & Pest Control': '|INSECT & PEST CONTROL--|',
  'Loofahs & Accessories': '|LOOFAHS & ACCESSORIES--|',
  'Made from Scratch Biscuits': '|MADE FROM SCRATCH BISCUITS--|',
  'Meat Sticks': '|MEAT STICKS--|',
  'Single Serve Meals': '|SINGLE SERVE MEALS--|',
  '2 to 4 Years': '|2 TO 4 YEARS--|',
  Dishwashing: '|DISHWASHING--|',
  'Eye Care': '|EYE CARE--|',
  'Glade Air Fresheners': '|GLADE AIR FRESHENERS--|',
  'Health & Beauty > First Aid > Braces & Supports':
    '|HEALTH & BEAUTY-FIRST AID-BRACES & SUPPORTS|',
  'Oils, Plug Ins, & Refills': '|OILS, PLUG INS, & REFILLS--|',
  'Paint Brushes': '|PAINT BRUSHES--|',
  'Teavana Tea': '|TEAVANA TEA--|',
  'Dental Floss': '|DENTAL FLOSS--|',
  'Health & Beauty > Oral Care > Oral Analgesic':
    '|HEALTH & BEAUTY-ORAL CARE-ORAL ANALGESIC|',
  'Hoodies & Sweatshirts': '|HOODIES & SWEATSHIRTS--|',
  Nachos: '|NACHOS--|',
  "Ol' Roy Dog Treats": "|OL' ROY DOG TREATS--|",
  'shampoos, conditioners, and hair treatments':
    '|SHAMPOOS, CONDITIONERS, AND HAIR TREATMENTS--|',
  'shaving and hair removal': '|SHAVING AND HAIR REMOVAL--|',
  Sliders: '|SLIDERS--|',
  'Sticky Notes': '|STICKY NOTES--|',
  'All Juice': '|ALL JUICE--|',
  'Apparel > Boutique > Kids': '|APPAREL-BOUTIQUE-KIDS|',
  'Food > Hot Dogs & Sausages > Hot Dogs':
    '|FOOD-HOT DOGS & SAUSAGES-HOT DOGS|',
  'Great Value Facial Tissues': '|GREAT VALUE FACIAL TISSUES--|',
  'Home & Garden > Home Essentials > Sewing Needs':
    '|HOME & GARDEN-HOME ESSENTIALS-SEWING NEEDS|',
  'Interior Car Accessories': '|INTERIOR CAR ACCESSORIES--|',
  'Personal Wipes': '|PERSONAL WIPES--|',
  'Snacks & Treats': '|SNACKS & TREATS--|',
  'All Womens Shoes': '|ALL WOMENS SHOES--|',
  'Dry Food': '|DRY FOOD--|',
  'Facial Treatments': '|FACIAL TREATMENTS--|',
  'First Aid Tools & Supplies': '|FIRST AID TOOLS & SUPPLIES--|',
  'hair styling accessories': '|HAIR STYLING ACCESSORIES--|',
  'Holiday Drinks': '|HOLIDAY DRINKS--|',
  Pants: '|PANTS--|',
  Platterz: '|PLATTERZ--|',
  'All Live Plants': '|ALL LIVE PLANTS--|',
  'All Single Herbs & Spices': '|ALL SINGLE HERBS & SPICES--|',
  'Apps & Sides': '|APPS & SIDES--|',
  Bundle: '|BUNDLE--|',
  'Candles & Waxes': '|CANDLES & WAXES--|',
  'Coffee Creamers': '|COFFEE CREAMERS--|',
  Earrings: '|EARRINGS--|',
  'Grocery > International': '|GROCERY-INTERNATIONAL-|',
  'Lemon Juice': '|LEMON JUICE--|',
  'Mens Jeans': '|MENS JEANS--|',
  'Mountain Dew': '|MOUNTAIN DEW--|',
  'Pork Skins': '|PORK SKINS--|',
  'Shop All onn.': '|SHOP ALL ONN.--|',
  Slams: '|SLAMS--|',
  'Soda & Pop': '|SODA & POP--|',
  'Body Wash': '|BODY WASH--|',
  'Equate Personal Health and Hygiene':
    '|EQUATE PERSONAL HEALTH AND HYGIENE--|',
  'Feminine and Nursing Hygiene': '|FEMININE AND NURSING HYGIENE--|',
  Frames: '|FRAMES--|',
  'Lip Balm': '|LIP BALM--|',
  "Mother's Day Decorations": "|MOTHER'S DAY DECORATIONS--|",
  'Office > Computer Supplies': '|OFFICE-COMPUTER SUPPLIES-|',
  Pizza: '|PIZZA--|',
  'Popcorn Bags': '|POPCORN BAGS--|',
  Pratt: '|PRATT--|',
  'Ready To Drink Coffee': '|READY TO DRINK COFFEE--|',
  Signature: '|SIGNATURE--|',
  'T-Shirts': '|T-SHIRTS--|',
  "Uncle Ben's": "|UNCLE BEN'S--|",
  'Better Homes & Gardens': '|BETTER HOMES & GARDENS--|',
  'Bottle Feeding': '|BOTTLE FEEDING--|',
  'Castle Combos': '|CASTLE COMBOS--|',
  'Diecast and Mini Vehicles': '|DIECAST AND MINI VEHICLES--|',
  'Drinking Glasses': '|DRINKING GLASSES--|',
  Duck: '|DUCK--|',
  'Health & Beauty > First Aid > Ice & Heat Packs':
    '|HEALTH & BEAUTY-FIRST AID-ICE & HEAT PACKS|',
  'Homestyle Breakfasts': '|HOMESTYLE BREAKFASTS--|',
  'Household Food or Beverage Storage Containers':
    '|HOUSEHOLD FOOD OR BEVERAGE STORAGE CONTAINERS--|',
  'Ice Cream': '|ICE CREAM--|',
  'Only at Walmart Toys': '|ONLY AT WALMART TOYS--|',
  'All Spiral Notebooks': '|ALL SPIRAL NOTEBOOKS--|',
  'Cell Phone Cases': '|CELL PHONE CASES--|',
  "Men's Bath & Body": "|MEN'S BATH & BODY--|",
  'Play Vehicles': '|PLAY VEHICLES--|',
  'Regular Menu': '|REGULAR MENU--|',
  'Baking & Pastry Tools': '|BAKING & PASTRY TOOLS--|',
  Books: '|BOOKS--|',
  'Dr. Pepper': '|DR. PEPPER--|',
  'Shop All Tumblers': '|SHOP ALL TUMBLERS--|',
  'Toddler Feeding': '|TODDLER FEEDING--|',
  'Womens Leggings': '|WOMENS LEGGINGS--|',
  'All Action Figures': '|ALL ACTION FIGURES--|',
  'Baskets & Bins': '|BASKETS & BINS--|',
  'Beverages > Coffee > Other Coffee': '|BEVERAGES-COFFEE-OTHER COFFEE|',
  'Big Girls Clothing Shop All': '|BIG GIRLS CLOTHING SHOP ALL--|',
  'Cereal & Granola': '|CEREAL & GRANOLA--|',
  'Desk & Adhesive Tape': '|DESK & ADHESIVE TAPE--|',
  'Grocery > Bakery & Bread > Bread Sticks':
    '|GROCERY-BAKERY & BREAD-BREAD STICKS|',
  'Grocery > Pasta & Grains > Dried Beans':
    '|GROCERY-PASTA & GRAINS-DRIED BEANS|',
  'Hot and Spicy': '|HOT AND SPICY--|',
  'onn. Phone & Cellphone Accessories':
    '|ONN. PHONE & CELLPHONE ACCESSORIES--|',
  Pillows: '|PILLOWS--|',
  'All Dry Dog Food': '|ALL DRY DOG FOOD--|',
  'All Halloween Costumes': '|ALL HALLOWEEN COSTUMES--|',
  'Chocolate Chip Cookies': '|CHOCOLATE CHIP COOKIES--|',
  'Copy & Multipurpose Paper': '|COPY & MULTIPURPOSE PAPER--|',
  'Dry Cat Food': '|DRY CAT FOOD--|',
  'Food > Sandwiches & Wraps > Meat Wrap':
    '|FOOD-SANDWICHES & WRAPS-MEAT WRAP|',
  'Lip Care': '|LIP CARE--|',
  'Mens Everyday Socks': '|MENS EVERYDAY SOCKS--|',
  "Ol' Roy Dog Supplies": "|OL' ROY DOG SUPPLIES--|",
  Pens: '|PENS--|',
  'prepared appetizers, meals and sides':
    '|PREPARED APPETIZERS, MEALS AND SIDES--|',
  Syrups: '|SYRUPS--|',
  'Toys for Kids 2 to 4 Years': '|TOYS FOR KIDS 2 TO 4 YEARS--|',
  'Vitamins & Supplements': '|VITAMINS & SUPPLEMENTS--|',
  Wings: '|WINGS--|',
  'All Disney Toys': '|ALL DISNEY TOYS--|',
  'All Stuffed Animals & Plush': '|ALL STUFFED ANIMALS & PLUSH--|',
  'Art Brushes': '|ART BRUSHES--|',
  Bowls: '|BOWLS--|',
  'Castle Packs': '|CASTLE PACKS--|',
  'Charbroiled Burgers': '|CHARBROILED BURGERS--|',
  'Entertainment > Video': '|ENTERTAINMENT-VIDEO-|',
  'Food > Breakfast > Egg Dishes': '|FOOD-BREAKFAST-EGG DISHES|',
  'Food > Dessert > Pastries': '|FOOD-DESSERT-PASTRIES|',
  'Food Prep': '|FOOD PREP--|',
  'Food Storage Bags & Containers': '|FOOD STORAGE BAGS & CONTAINERS--|',
  'Foot Care': '|FOOT CARE--|',
  Leggings: '|LEGGINGS--|',
  'New Body Care': '|NEW BODY CARE--|',
  'Plastic Storage Boxes': '|PLASTIC STORAGE BOXES--|',
  'Play Dough': '|PLAY DOUGH--|',
  'Signature Sides': '|SIGNATURE SIDES--|',
  'All Pens': '|ALL PENS--|',
  'All Wiper Blades': '|ALL WIPER BLADES--|',
  'Baby Learning Toys': '|BABY LEARNING TOYS--|',
  'Dog Toys': '|DOG TOYS--|',
  'Floor Care': '|FLOOR CARE--|',
  'Food > Dessert > Brownies': '|FOOD-DESSERT-BROWNIES|',
  Glitter: '|GLITTER--|',
  'Gummy Bears': '|GUMMY BEARS--|',
  'Hair Treatments': '|HAIR TREATMENTS--|',
  'Pet Supplies': '|PET SUPPLIES--|',
  'Seasonal > Holiday & Seasonal Goods': '|SEASONAL-HOLIDAY & SEASONAL GOODS-|',
  Sterilite: '|STERILITE--|',
  'Straight Talk Airtime & Plastic Gift Cards':
    '|STRAIGHT TALK AIRTIME & PLASTIC GIFT CARDS--|',
  'All Art Paint': '|ALL ART PAINT--|',
  'Baking Chips & Bars': '|BAKING CHIPS & BARS--|',
  'Building Sets & Kits': '|BUILDING SETS & KITS--|',
  'Cooking Oil & Vinegar': '|COOKING OIL & VINEGAR--|',
  'Dollhouses & Play Sets': '|DOLLHOUSES & PLAY SETS--|',
  'Drain & Septic Care': '|DRAIN & SEPTIC CARE--|',
  'Food > Burgers > Chicken Burger': '|FOOD-BURGERS-CHICKEN BURGER|',
  Frappuccino: '|FRAPPUCCINO--|',
  'Health & Beauty > Digestive Aids > Diarrhea':
    '|HEALTH & BEAUTY-DIGESTIVE AIDS-DIARRHEA|',
  'Home Meal Solutions': '|HOME MEAL SOLUTIONS--|',
  'Mens Big & Tall T-Shirts & Tank Tops':
    '|MENS BIG & TALL T-SHIRTS & TANK TOPS--|',
  'Nail Care': '|NAIL CARE--|',
  'Office, School, & Crafts': '|OFFICE, SCHOOL, & CRAFTS--|',
  'Pain & Fever': '|PAIN & FEVER--|',
  Powders: '|POWDERS--|',
  Sheets: '|SHEETS--|',
  'Upright Vacuums': '|UPRIGHT VACUUMS--|',
  'Vibrant Life Collars, Harnesses & Leashes':
    '|VIBRANT LIFE COLLARS, HARNESSES & LEASHES--|',
  'Womens Pajamas': '|WOMENS PAJAMAS--|',
  'Wet Dog Food': '|WET DOG FOOD--|',
  'Womens Hair Accessories': '|WOMENS HAIR ACCESSORIES--|',
  'Beverages > Water': '|BEVERAGES-WATER-|',
  'Chewy & Hard Candy': '|CHEWY & HARD CANDY--|',
  'Collectible Dolls': '|COLLECTIBLE DOLLS--|',
  'Facial Wipes': '|FACIAL WIPES--|',
  'Febreze Air Fresheners': '|FEBREZE AIR FRESHENERS--|',
  'Glass Cleaners': '|GLASS CLEANERS--|',
  'Health & Beauty > Eye & Ear Care > Eye - Accessories':
    '|HEALTH & BEAUTY-EYE & EAR CARE-EYE - ACCESSORIES|',
  'Health & Beauty > Pain Relief > Hemorrhoids':
    '|HEALTH & BEAUTY-PAIN RELIEF-HEMORRHOIDS|',
  'Organic Vegetables': '|ORGANIC VEGETABLES--|',
  'Pads & Panty Liners': '|PADS & PANTY LINERS--|',
  'Pinatas & Balloons': '|PINATAS & BALLOONS--|',
  'Sandwich Meals': '|SANDWICH MEALS--|',
  'Shop All Wall Decor': '|SHOP ALL WALL DECOR--|',
  Specialties: '|SPECIALTIES--|',
  'T-shirts & Tanks': '|T-SHIRTS & TANKS--|',
  'All Cat Food': '|ALL CAT FOOD--|',
  'Baby > Baby Medications': '|BABY-BABY MEDICATIONS-|',
  'Baby Dolls': '|BABY DOLLS--|',
  'Beverages > Milk': '|BEVERAGES-MILK-|',
  'Car Batteries': '|CAR BATTERIES--|',
  'Car Washes & Cleaners': '|CAR WASHES & CLEANERS--|',
  'Dishwasher Detergent': '|DISHWASHER DETERGENT--|',
  'Fabric Softener': '|FABRIC SOFTENER--|',
  'Fruit Juice': '|FRUIT JUICE--|',
  Hats: '|HATS--|',
  'In Wash Scent Booster': '|IN WASH SCENT BOOSTER--|',
  'Office Supplies Clean Living Shop': '|OFFICE SUPPLIES CLEAN LIVING SHOP--|',
  'Purified & Spring Water': '|PURIFIED & SPRING WATER--|',
  'Ramen & Instant Noodles': '|RAMEN & INSTANT NOODLES--|',
  'Spray Bottles': '|SPRAY BOTTLES--|',
  'Tender Juicy Chicken': '|TENDER JUICY CHICKEN--|',
  'A La Carte': '|A LA CARTE--|',
  'All Screws': '|ALL SCREWS--|',
  'All-purpose Cleaners': '|ALL-PURPOSE CLEANERS--|',
  'cold, allergy and digestive health treatments':
    '|COLD, ALLERGY AND DIGESTIVE HEALTH TREATMENTS--|',
  Drinkware: '|DRINKWARE--|',
  'Food > Breakfast > Oatmeal': '|FOOD-BREAKFAST-OATMEAL|',
  Fruits: '|FRUITS--|',
  'Great Value Creamers': '|GREAT VALUE CREAMERS--|',
  'Hand Soaps & Sanitizers': '|HAND SOAPS & SANITIZERS--|',
  'Health & Beauty > Diet & Vitamins > Minerals':
    '|HEALTH & BEAUTY-DIET & VITAMINS-MINERALS|',
  'Household Essentials': '|HOUSEHOLD ESSENTIALS--|',
  'Kosher Foods': '|KOSHER FOODS--|',
  'Lemonade Drinks': '|LEMONADE DRINKS--|',
  'Manicure and Pedicure': '|MANICURE AND PEDICURE--|',
  Mascara: '|MASCARA--|',
  Multivitamins: '|MULTIVITAMINS--|',
  'Power Menu': '|POWER MENU--|',
  'Queso & Cheese Dips': '|QUESO & CHEESE DIPS--|',
  'Salad Kits and Bowls': '|SALAD KITS AND BOWLS--|',
  Stickers: '|STICKERS--|',
  accessories: '|ACCESSORIES--|',
  'All Dog Food': '|ALL DOG FOOD--|',
  'Baking Powder & Soda': '|BAKING POWDER & SODA--|',
  'Bath Accessories': '|BATH ACCESSORIES--|',
  'Cheetos Puffs': '|CHEETOS PUFFS--|',
  'Coats & Jackets': '|COATS & JACKETS--|',
  'Coffee & Tea': '|COFFEE & TEA--|',
  'DQ Cakes': '|DQ CAKES--|',
  'Equate Bandages & Gauze Pads': '|EQUATE BANDAGES & GAUZE PADS--|',
  'Extracts & Colorings': '|EXTRACTS & COLORINGS--|',
  Flavored: '|FLAVORED--|',
  'General Mills Cereal': '|GENERAL MILLS CEREAL--|',
  'Greeting Cards': '|GREETING CARDS--|',
  'Most Popular': '|MOST POPULAR--|',
  'Most Popular Items': '|MOST POPULAR ITEMS--|',
  'Paper Coffee Filters': '|PAPER COFFEE FILTERS--|',
  'Picture Hangers': '|PICTURE HANGERS--|',
  'Sauces, Salsa & Marinades': '|SAUCES, SALSA & MARINADES--|',
  'Stain Removers & Bleach': '|STAIN REMOVERS & BLEACH--|',
  'Tide Liquid Laundry Detergent': '|TIDE LIQUID LAUNDRY DETERGENT--|',
  Turkey: '|TURKEY--|',
  'Angel Soft Toilet Paper': '|ANGEL SOFT TOILET PAPER--|',
  'Apparel > Boutique': '|APPAREL-BOUTIQUE-|',
  'Cat Treats': '|CAT TREATS--|',
  'Christmas Lights': '|CHRISTMAS LIGHTS--|',
  'Christmas Stockings': '|CHRISTMAS STOCKINGS--|',
  'Clothing > Mens > Tops': '|CLOTHING-MENS-TOPS|',
  'Equate Oral Care': '|EQUATE ORAL CARE--|',
  'Grocery & Gourmet Food': '|GROCERY & GOURMET FOOD--|',
  Keys: '|KEYS--|',
  'Kiss Nail Care': '|KISS NAIL CARE--|',
  'Lemonade & Limeade': '|LEMONADE & LIMEADE--|',
  'Mens Boxer Briefs': '|MENS BOXER BRIEFS--|',
  Music: '|MUSIC--|',
  'Other Favorites': '|OTHER FAVORITES--|',
  Rugs: '|RUGS--|',
  'Sink Accessories': '|SINK ACCESSORIES--|',
  Spoons: '|SPOONS--|',
  'All Lures, Baits and Attractants': '|ALL LURES, BAITS AND ATTRACTANTS--|',
  'Crayola Kids': '|CRAYOLA KIDS--|',
  'Drawers & Cabinet Organizers': '|DRAWERS & CABINET ORGANIZERS--|',
  Eyeliner: '|EYELINER--|',
  "Fixin's": "|FIXIN'S--|",
  'Grocery > Produce > Trays/Baskets/Platters':
    '|GROCERY-PRODUCE-TRAYS/BASKETS/PLATTERS|',
  'Grocery > Snacks > Bulk - Fruit/Nuts/Seeds':
    '|GROCERY-SNACKS-BULK - FRUIT/NUTS/SEEDS|',
  'Health & Beauty > Diet & Vitamins > Vitamins - Children':
    '|HEALTH & BEAUTY-DIET & VITAMINS-VITAMINS - CHILDREN|',
  'Incense, Potpourri, and Sprays': '|INCENSE, POTPOURRI, AND SPRAYS--|',
  'New Makeup': '|NEW MAKEUP--|',
  'paper products': '|PAPER PRODUCTS--|',
  'Pet Care': '|PET CARE--|',
  'Serving Platters & Trays': '|SERVING PLATTERS & TRAYS--|',
  Syringes: '|SYRINGES--|',
  'Womens Jeans': '|WOMENS JEANS--|',
  Yarn: '|YARN--|',
  Undershirts: '|UNDERSHIRTS--|',
  'Water Bottles': '|WATER BOTTLES--|',
  'All Ice Cream': '|ALL ICE CREAM--|',
  'Birthday Treat Decorating': '|BIRTHDAY TREAT DECORATING--|',
  'Blackout Curtains': '|BLACKOUT CURTAINS--|',
  'Core Building Supplies Clearance Shelf':
    '|CORE BUILDING SUPPLIES CLEARANCE SHELF--|',
  Dresses: '|DRESSES--|',
  'Earbuds and In-ear Headphones': '|EARBUDS AND IN-EAR HEADPHONES--|',
  'Face Masks': '|FACE MASKS--|',
  'Health & Beauty > Pain Relief': '|HEALTH & BEAUTY-PAIN RELIEF-|',
  'Home & Garden > Home Essentials > Water Softeners':
    '|HOME & GARDEN-HOME ESSENTIALS-WATER SOFTENERS|',
  'Juniors Sweatshirts & Hoodies': '|JUNIORS SWEATSHIRTS & HOODIES--|',
  'Litter Center': '|LITTER CENTER--|',
  'Makeup Removers': '|MAKEUP REMOVERS--|',
  'Oil Filters': '|OIL FILTERS--|',
  'Paint Colors': '|PAINT COLORS--|',
  'Pasta, Rice & Grains': '|PASTA, RICE & GRAINS--|',
  'Sheets & Pillowcases': '|SHEETS & PILLOWCASES--|',
  'Shop All Bath Towels': '|SHOP ALL BATH TOWELS--|',
  'Shower Curtain Liners': '|SHOWER CURTAIN LINERS--|',
  'Spray Air Fresheners': '|SPRAY AIR FRESHENERS--|',
  Teas: '|TEAS--|',
  'All Cat Treats': '|ALL CAT TREATS--|',
  'All Energy Drinks': '|ALL ENERGY DRINKS--|',
  'Basic Tees': '|BASIC TEES--|',
  'Bathroom Rugs & Mats': '|BATHROOM RUGS & MATS--|',
  'Bed-in-a-Bag Sets': '|BED-IN-A-BAG SETS--|',
  'Canned Ready to Eat Meals': '|CANNED READY TO EAT MEALS--|',
  'Cat Toys': '|CAT TOYS--|',
  'Charbroiled 100% BLACK ANGUS BEEF THICKBURGERSÂ®':
    '|CHARBROILED 100% BLACK ANGUS BEEF THICKBURGERSÂ®--|',
  'Chicken Choices': '|CHICKEN CHOICES--|',
  Decorations: '|DECORATIONS--|',
  'Fruit Punch': '|FRUIT PUNCH--|',
  'Graphic Tees': '|GRAPHIC TEES--|',
  'gum and mints': '|GUM AND MINTS--|',
  Home: '|HOME--|',
  'Interior Wall and Trim Paint': '|INTERIOR WALL AND TRIM PAINT--|',
  'Lunch & Dinner Combos': '|LUNCH & DINNER COMBOS--|',
  'Maxi Pads': '|MAXI PADS--|',
  'Pest Control': '|PEST CONTROL--|',
  'Pizza & Pizza Snacks': '|PIZZA & PIZZA SNACKS--|',
  'Sandpaper and Abrasives': '|SANDPAPER AND ABRASIVES--|',
  'Scarves & Wraps': '|SCARVES & WRAPS--|',
  'Seafood  Combos': '|SEAFOOD  COMBOS--|',
  Shakes: '|SHAKES--|',
  'Special Kitty Cat Litter': '|SPECIAL KITTY CAT LITTER--|',
  'Wet Cat Food': '|WET CAT FOOD--|',
  'Air Wick Air Fresheners': '|AIR WICK AIR FRESHENERS--|',
  'All Puffed Snacks': '|ALL PUFFED SNACKS--|',
  'All Spring Valley': '|ALL SPRING VALLEY--|',
  'Baby Skin Care & Grooming': '|BABY SKIN CARE & GROOMING--|',
  'Beverages > Other Hot': '|BEVERAGES-OTHER HOT-|',
  Bikinis: '|BIKINIS--|',
  'Birthday Party Decorations': '|BIRTHDAY PARTY DECORATIONS--|',
  'Body Scrubs & Exfoliators': '|BODY SCRUBS & EXFOLIATORS--|',
  'Boxed Lunches': '|BOXED LUNCHES--|',
  'Cat Litter': '|CAT LITTER--|',
  'Cereal Bars & Breakfast Biscuits': '|CEREAL BARS & BREAKFAST BISCUITS--|',
  Chocolates: '|CHOCOLATES--|',
  'Classic Pop-Tarts Toaster Pastries':
    '|CLASSIC POP-TARTS TOASTER PASTRIES--|',
  'Cosmetic Accessories and Tools': '|COSMETIC ACCESSORIES AND TOOLS--|',
  'Decorative Hardware': '|DECORATIVE HARDWARE--|',
  'DQ Solos': '|DQ SOLOS--|',
  'Equate Allergy Tablets': '|EQUATE ALLERGY TABLETS--|',
  'Equate Cough Cold Flu': '|EQUATE COUGH COLD FLU--|',
  'Food > Pasta > Veggie Pasta': '|FOOD-PASTA-VEGGIE PASTA|',
  Fragrances: '|FRAGRANCES--|',
  'Health & Beauty > Pain Relief > Feminine':
    '|HEALTH & BEAUTY-PAIN RELIEF-FEMININE|',
  'Herbs, Rubs & Spices': '|HERBS, RUBS & SPICES--|',
  'Ice Cream Tubs & Pints': '|ICE CREAM TUBS & PINTS--|',
  'Ink & Toner': '|INK & TONER--|',
  'Laundry Hampers': '|LAUNDRY HAMPERS--|',
  'Milk Substitutes': '|MILK SUBSTITUTES--|',
  Nail: '|NAIL--|',
  'Nail Polish': '|NAIL POLISH--|',
  'Olive Oils': '|OLIVE OILS--|',
  'Party Lights & String Lights': '|PARTY LIGHTS & STRING LIGHTS--|',
  'PJ Masks and Paw Patrol': '|PJ MASKS AND PAW PATROL--|',
  Razors: '|RAZORS--|',
  'Skincare Restock': '|SKINCARE RESTOCK--|',
  Soil: '|SOIL--|',
  'All Beading and Jewelry Making': '|ALL BEADING AND JEWELRY MAKING--|',
  'All Conditioner': '|ALL CONDITIONER--|',
  'All Incontinence': '|ALL INCONTINENCE--|',
  'All Labels & Stickers': '|ALL LABELS & STICKERS--|',
  'Bath Tissues': '|BATH TISSUES--|',
  'Berries & Cherries': '|BERRIES & CHERRIES--|',
  'Cold Cereal': '|COLD CEREAL--|',
  'Digestion & Nausea': '|DIGESTION & NAUSEA--|',
  'Disposable Bowls': '|DISPOSABLE BOWLS--|',
  'e.l.f. Eye Makeup': '|E.L.F. EYE MAKEUP--|',
  'Food > Appetizers & Sides > Meat': '|FOOD-APPETIZERS & SIDES-MEAT|',
  'Food > Appetizers & Sides > Vegetables':
    '|FOOD-APPETIZERS & SIDES-VEGETABLES|',
  'Food & Treats': '|FOOD & TREATS--|',
  'Health & Beauty > Digestive Aids': '|HEALTH & BEAUTY-DIGESTIVE AIDS-|',
  'House Hooks': '|HOUSE HOOKS--|',
  'Housecleaning Accessories': '|HOUSECLEANING ACCESSORIES--|',
  'Mens Athletic Socks': '|MENS ATHLETIC SOCKS--|',
  Mouthwash: '|MOUTHWASH--|',
  'Packaged Lunch Meat': '|PACKAGED LUNCH MEAT--|',
  'Pinata Fillers': '|PINATA FILLERS--|',
  'Tea Bags': '|TEA BAGS--|',
  'Vibrant Life Dog Toys': '|VIBRANT LIFE DOG TOYS--|',
  "Toddler Girls' Clothing": "|TODDLER GIRLS' CLOTHING--|",
  'Womens Hosiery & Tights': '|WOMENS HOSIERY & TIGHTS--|',
  'All Salts & Salt Substitutes': '|ALL SALTS & SALT SUBSTITUTES--|',
  'Body Hair Removal': '|BODY HAIR REMOVAL--|',
  'Caramels & Taffys': '|CARAMELS & TAFFYS--|',
  'Cleaning Refills': '|CLEANING REFILLS--|',
  Coffee: '|COFFEE--|',
  'Combo Meals': '|COMBO MEALS--|',
  'Costumes & Dress Up': '|COSTUMES & DRESS UP--|',
  'False Eyelashes': '|FALSE EYELASHES--|',
  'Food/Snacks, Cookies & Chips/Cookies':
    '|FOOD/SNACKS, COOKIES & CHIPS/COOKIES--|',
  Forks: '|FORKS--|',
  'Gain Dryer Sheets': '|GAIN DRYER SHEETS--|',
  'Gum & Mints': '|GUM & MINTS--|',
  Hipsters: '|HIPSTERS--|',
  jewelry: '|JEWELRY--|',
  'Lighting and Lamps': '|LIGHTING AND LAMPS--|',
  'Lip Gloss': '|LIP GLOSS--|',
  'Onions & Garlic': '|ONIONS & GARLIC--|',
  'Painting Supplies': '|PAINTING SUPPLIES--|',
  'Pen+Gear Office Products': '|PEN+GEAR OFFICE PRODUCTS--|',
  Peppers: '|PEPPERS--|',
  'Rice & Beans': '|RICE & BEANS--|',
  'Shower Curtain Hooks & Rods': '|SHOWER CURTAIN HOOKS & RODS--|',
  'Sparkling Water': '|SPARKLING WATER--|',
  'All Laundry Detergent': '|ALL LAUNDRY DETERGENT--|',
  'All Meals and Sides': '|ALL MEALS AND SIDES--|',
  'All Mens Shoes': '|ALL MENS SHOES--|',
  'American Jobs 2020': '|AMERICAN JOBS 2020--|',
  'Auto Accessories': '|AUTO ACCESSORIES--|',
  'Bottled Coffee Drinks': '|BOTTLED COFFEE DRINKS--|',
  'Bottled Tea': '|BOTTLED TEA--|',
  'Breakfast Meals': '|BREAKFAST MEALS--|',
  Briefs: '|BRIEFS--|',
  Celery: '|CELERY--|',
  'Classic Sides': '|CLASSIC SIDES--|',
  'Coily Hair Products': '|COILY HAIR PRODUCTS--|',
  'cookies, brownies and bars': '|COOKIES, BROWNIES AND BARS--|',
  'Dish Detergents': '|DISH DETERGENTS--|',
  'Dog Food & Treats': '|DOG FOOD & TREATS--|',
  Eyebrows: '|EYEBROWS--|',
  'Grocery > Dairy > Non-Dairy Beverage': '|GROCERY-DAIRY-NON-DAIRY BEVERAGE|',
  'Hefty Bags': '|HEFTY BAGS--|',
  Lips: '|LIPS--|',
  'Makeup Tools & Brushes': '|MAKEUP TOOLS & BRUSHES--|',
  'Mens Underwear & Undershirts': '|MENS UNDERWEAR & UNDERSHIRTS--|',
  'Ozark Trail Camping Furniture': '|OZARK TRAIL CAMPING FURNITURE--|',
  'Pancake & Waffle Mix': '|PANCAKE & WAFFLE MIX--|',
  "Parent's Choice Diapers": "|PARENT'S CHOICE DIAPERS--|",
  'Puffed Snacks & Popcorn': '|PUFFED SNACKS & POPCORN--|',
  'Razors & Razor Heads': '|RAZORS & RAZOR HEADS--|',
  'Sparkle Paper Towels': '|SPARKLE PAPER TOWELS--|',
  Starbucks: '|STARBUCKS--|',
  'TV Cables & Connectors': '|TV CABLES & CONNECTORS--|',
  'Vibrant Life Cat Litter Accessories':
    '|VIBRANT LIFE CAT LITTER ACCESSORIES--|',
  Washcloths: '|WASHCLOTHS--|',
  'Wood Chips and Charcoal': '|WOOD CHIPS AND CHARCOAL--|',
  "Toddler Boys' Clothing": "|TODDLER BOYS' CLOTHING--|",
  'Vegetarian Foods': '|VEGETARIAN FOODS--|',
  Wallets: '|WALLETS--|',
  'Wood Screws': '|WOOD SCREWS--|',
  'All Water Flavoring': '|ALL WATER FLAVORING--|',
  'Allergy Medicine': '|ALLERGY MEDICINE--|',
  Always: '|ALWAYS--|',
  'Art Supplies': '|ART SUPPLIES--|',
  'Baby > Baby Foods > 1st Foods': '|BABY-BABY FOODS-1ST FOODS|',
  'Baby Girl Clothing': '|BABY GIRL CLOTHING--|',
  'Bar & Wine Accessories': '|BAR & WINE ACCESSORIES--|',
  'Bath Toys': '|BATH TOYS--|',
  'Bathroom Rugs': '|BATHROOM RUGS--|',
  'Big Boys Underwear': '|BIG BOYS UNDERWEAR--|',
  "Boys' Clothing": "|BOYS' CLOTHING--|",
  'Candles & Home Fragrances': '|CANDLES & HOME FRAGRANCES--|',
  'Christmas Ornaments & Tree Decorations':
    '|CHRISTMAS ORNAMENTS & TREE DECORATIONS--|',
  Clothing: '|CLOTHING--|',
  Dogs: '|DOGS--|',
  Dozens: '|DOZENS--|',
  Face: '|FACE--|',
  'Finish Line Athletic Shoes': '|FINISH LINE ATHLETIC SHOES--|',
  'Functional Beverages': '|FUNCTIONAL BEVERAGES--|',
  'Gift Wrap & Accessories': '|GIFT WRAP & ACCESSORIES--|',
  'Hand Soap': '|HAND SOAP--|',
  'Mens Active Fleece': '|MENS ACTIVE FLEECE--|',
  Nerds: '|NERDS--|',
  'Pacifiers & Teethers': '|PACIFIERS & TEETHERS--|',
  'Pillow Cases': '|PILLOW CASES--|',
  'Replacement Auto Parts': '|REPLACEMENT AUTO PARTS--|',
  'Shareable Sides': '|SHAREABLE SIDES--|',
  'Sippy Cups': '|SIPPY CUPS--|',
  'Sleep Aids': '|SLEEP AIDS--|',
  'Soft & Plush Toys': '|SOFT & PLUSH TOYS--|',
  'Sports Drinks & Enhanced Waters': '|SPORTS DRINKS & ENHANCED WATERS--|',
  'T-Shirt Bra': '|T-SHIRT BRA--|',
  'All Cat Toys': '|ALL CAT TOYS--|',
  'All Dog Grooming': '|ALL DOG GROOMING--|',
  'All Gift Cards': '|ALL GIFT CARDS--|',
  'All Hefty': '|ALL HEFTY--|',
  'Baking Goods': '|BAKING GOODS--|',
  Binders: '|BINDERS--|',
  Boots: '|BOOTS--|',
  Brooms: '|BROOMS--|',
  'Cafe Soups': '|CAFE SOUPS--|',
  'Cakes, Cookies & Pies': '|CAKES, COOKIES & PIES--|',
  'Can Openers': '|CAN OPENERS--|',
  'Cards, Stationery & Invitations': '|CARDS, STATIONERY & INVITATIONS--|',
  Caulk: '|CAULK--|',
  Cleaners: '|CLEANERS--|',
  Diapering: '|DIAPERING--|',
  'Dollar Cravings': '|DOLLAR CRAVINGS--|',
  Donuts: '|DONUTS--|',
  'File Folders': '|FILE FOLDERS--|',
  'Food > Soups & Salads > Veggie Salad': '|FOOD-SOUPS & SALADS-VEGGIE SALAD|',
  'Grocery > Baking > No-Bake Mixes': '|GROCERY-BAKING-NO-BAKE MIXES|',
  Gum: '|GUM--|',
  'Insect Sprays & Foggers': '|INSECT SPRAYS & FOGGERS--|',
  Ketchup: '|KETCHUP--|',
  Markers: '|MARKERS--|',
  Notebooks: '|NOTEBOOKS--|',
  'On-the-Go Nutrition': '|ON-THE-GO NUTRITION--|',
  'Other Choices': '|OTHER CHOICES--|',
  Padlocks: '|PADLOCKS--|',
  'Pain Relief': '|PAIN RELIEF--|',
  'Party Orders (Archived)': '|PARTY ORDERS (ARCHIVED)--|',
  'Queen Air Mattresses': '|QUEEN AIR MATTRESSES--|',
  'Specialty Cheeses': '|SPECIALTY CHEESES--|',
  'Specialty Juices': '|SPECIALTY JUICES--|',
  'Toy Blasters': '|TOY BLASTERS--|',
  'Add-On Sides': '|ADD-ON SIDES--|',
  'All LEGO Sets': '|ALL LEGO SETS--|',
  'Area Rugs': '|AREA RUGS--|',
  'Baby Formula': '|BABY FORMULA--|',
  Berries: '|BERRIES--|',
  'Beverages > Coffee > Latte': '|BEVERAGES-COFFEE-LATTE|',
  'Canned & Jarred Vegetables': '|CANNED & JARRED VEGETABLES--|',
  'Chicken (Archived)': '|CHICKEN (ARCHIVED)--|',
  'Clothing Crafting': '|CLOTHING CRAFTING--|',
  Concealer: '|CONCEALER--|',
  'Dog Bowls & Feeding Supplies': '|DOG BOWLS & FEEDING SUPPLIES--|',
  'Dog Collars & Leashes': '|DOG COLLARS & LEASHES--|',
  'Equate Beauty': '|EQUATE BEAUTY--|',
  'Equate Shave': '|EQUATE SHAVE--|',
  'Food > Appetizers & Sides > Fruit': '|FOOD-APPETIZERS & SIDES-FRUIT|',
  'Food > Appetizers & Sides > Rice': '|FOOD-APPETIZERS & SIDES-RICE|',
  'Hooks & Picture Hangers': '|HOOKS & PICTURE HANGERS--|',
  'Indoor Halloween Decor': '|INDOOR HALLOWEEN DECOR--|',
  'Instawatch Movies': '|INSTAWATCH MOVIES--|',
  "Kids' Books": "|KIDS' BOOKS--|",
  'Last Minute Gifts': '|LAST MINUTE GIFTS--|',
  'Laundry Baskets': '|LAUNDRY BASKETS--|',
  'Liquid Fabric Softeners': '|LIQUID FABRIC SOFTENERS--|',
  'Lunch Boxes & Bags': '|LUNCH BOXES & BAGS--|',
  Mains: '|MAINS--|',
  'Movies & TV Shows': '|MOVIES & TV SHOWS--|',
  'Paint Roller Covers': '|PAINT ROLLER COVERS--|',
  'Paint Trays': '|PAINT TRAYS--|',
  Pancakes: '|PANCAKES--|',
  'Picture Frames': '|PICTURE FRAMES--|',
  'Pork Rinds': '|PORK RINDS--|',
  'Protein Bars': '|PROTEIN BARS--|',
  'Pure Balance': '|PURE BALANCE--|',
  'Room Darkening Curtains': '|ROOM DARKENING CURTAINS--|',
  'Serving Bowls': '|SERVING BOWLS--|',
  'Shirts & Tops': '|SHIRTS & TOPS--|',
  'Shop All': '|SHOP ALL--|',
  'Shop Markers by Brand': '|SHOP MARKERS BY BRAND--|',
  'Suave Shampoo': '|SUAVE SHAMPOO--|',
  'Adult Games': '|ADULT GAMES--|',
  Air: '|AIR--|',
  'All Fragrances': '|ALL FRAGRANCES--|',
  'All Vitamins & Supplements': '|ALL VITAMINS & SUPPLEMENTS--|',
  'Allergy & Sinus': '|ALLERGY & SINUS--|',
  Apple: '|APPLE--|',
  'Arts & Crafts': '|ARTS & CRAFTS--|',
  'Baby Care': '|BABY CARE--|',
  'Baby Food': '|BABY FOOD--|',
  Balaclavas: '|BALACLAVAS--|',
  'basic pillows': '|BASIC PILLOWS--|',
  'Breakfast Combos': '|BREAKFAST COMBOS--|',
  'Brunchfast Entrees': '|BRUNCHFAST ENTREES--|',
  'Cereal, Granola, & Toaster Pastries':
    '|CEREAL, GRANOLA, & TOASTER PASTRIES--|',
  'Dog Training Pads': '|DOG TRAINING PADS--|',
  'Dove Deodorant & Antiperspirant': '|DOVE DEODORANT & ANTIPERSPIRANT--|',
  'False Lashes': '|FALSE LASHES--|',
  'Floor Lamps': '|FLOOR LAMPS--|',
  'Fruit Juices': '|FRUIT JUICES--|',
  'Grocery > Frozen > Fresh Fruit': '|GROCERY-FROZEN-FRESH FRUIT|',
  'Grocery > International > Middle Eastern':
    '|GROCERY-INTERNATIONAL-MIDDLE EASTERN|',
  'Hand Creams & Lotions': '|HAND CREAMS & LOTIONS--|',
  'Household Essentials/Laundry/Laundry Detergents':
    '|HOUSEHOLD ESSENTIALS/LAUNDRY/LAUNDRY DETERGENTS--|',
  'Kitchen Cleaning': '|KITCHEN CLEANING--|',
  Maintenance: '|MAINTENANCE--|',
  'No Boundaries Bras & Panties': '|NO BOUNDARIES BRAS & PANTIES--|',
  'Racetracks & Playsets': '|RACETRACKS & PLAYSETS--|',
  'Refrigerated Doughs': '|REFRIGERATED DOUGHS--|',
  'Scrapbooking & Paper Crafts': '|SCRAPBOOKING & PAPER CRAFTS--|',
  'Skin Care': '|SKIN CARE--|',
  Sneakers: '|SNEAKERS--|',
  'Stock up on Paper & Plastic': '|STOCK UP ON PAPER & PLASTIC--|',
  'Straight Talk Prepaid Phones': '|STRAIGHT TALK PREPAID PHONES--|',
  Thongs: '|THONGS--|',
  'Trash Cans & Recycle Bins': '|TRASH CANS & RECYCLE BINS--|',
  'Wall Art': '|WALL ART--|',
  'Wet Food': '|WET FOOD--|',
  "Women's Activewear Sports Bras": "|WOMEN'S ACTIVEWEAR SPORTS BRAS--|",
  'Ziploc Bags': '|ZIPLOC BAGS--|',
  'Treat Yourself Pop-Tarts Toaster Pastries':
    '|TREAT YOURSELF POP-TARTS TOASTER PASTRIES--|',
  'TV Mounts': '|TV MOUNTS--|',
  'Vacuum Bags & Accessories': '|VACUUM BAGS & ACCESSORIES--|',
  "Valentine's Candy": "|VALENTINE'S CANDY--|",
  'Wilton Icing and Decorations': '|WILTON ICING AND DECORATIONS--|',
  'Womens Athletic Socks': '|WOMENS ATHLETIC SOCKS--|',
  'All Adventure Force Vehicles': '|ALL ADVENTURE FORCE VEHICLES--|',
  'All Sports Drinks': '|ALL SPORTS DRINKS--|',
  'All Wet Dog Food': '|ALL WET DOG FOOD--|',
  'Birthday Party Wear & Accessories': '|BIRTHDAY PARTY WEAR & ACCESSORIES--|',
  'Buns & Rolls': '|BUNS & ROLLS--|',
  'Cascade Dishwasher Detergents': '|CASCADE DISHWASHER DETERGENTS--|',
  'Catering Dessert Platter': '|CATERING DESSERT PLATTER--|',
  'Chicken Nuggets and Strips': '|CHICKEN NUGGETS AND STRIPS--|',
  'Clay & Dough': '|CLAY & DOUGH--|',
  'Clothing > Mens > Bottoms': '|CLOTHING-MENS-BOTTOMS|',
  'Cream & Whipped Toppings': '|CREAM & WHIPPED TOPPINGS--|',
  Doritos: '|DORITOS--|',
  'Febreze Car Air Fresheners': '|FEBREZE CAR AIR FRESHENERS--|',
  'Febreze Small Spaces Air Fresheners':
    '|FEBREZE SMALL SPACES AIR FRESHENERS--|',
  'Food > Sandwiches & Wraps': '|FOOD-SANDWICHES & WRAPS-|',
  'Food/Fresh Food/Produce/Fresh Vegetables':
    '|FOOD/FRESH FOOD/PRODUCE/FRESH VEGETABLES--|',
  'Garbage Bags': '|GARBAGE BAGS--|',
  'General Purpose Spray Paint': '|GENERAL PURPOSE SPRAY PAINT--|',
  'Halloween Makeup': '|HALLOWEEN MAKEUP--|',
  Hardware: '|HARDWARE--|',
  'Home & Garden > Workbench > Electric': '|HOME & GARDEN-WORKBENCH-ELECTRIC|',
  'Hyper Tough Automotive Parts': '|HYPER TOUGH AUTOMOTIVE PARTS--|',
  'Individual Meals': '|INDIVIDUAL MEALS--|',
  'Italian Seasoning': '|ITALIAN SEASONING--|',
  'K-cups & Coffee Pods': '|K-CUPS & COFFEE PODS--|',
  'Maybelline Eye Makeup': '|MAYBELLINE EYE MAKEUP--|',
  'Mens Belts': '|MENS BELTS--|',
  'Monster Energy': '|MONSTER ENERGY--|',
  Movies: '|MOVIES--|',
  'Nail Polish & Treatments': '|NAIL POLISH & TREATMENTS--|',
  'No Boundaries Juniors': '|NO BOUNDARIES JUNIORS--|',
  'nuts, dried fruit and vegetables': '|NUTS, DRIED FRUIT AND VEGETABLES--|',
  Ornaments: '|ORNAMENTS--|',
  'paper sheets and packs': '|PAPER SHEETS AND PACKS--|',
  'Pedestal Fans': '|PEDESTAL FANS--|',
  'Pizza Bagels & Bites': '|PIZZA BAGELS & BITES--|',
  'Plants, Flowers, Trees': '|PLANTS, FLOWERS, TREES--|',
  'Play Animals': '|PLAY ANIMALS--|',
  Rulers: '|RULERS--|',
  'Shaving & Hair Removal': '|SHAVING & HAIR REMOVAL--|',
  'Special Kitty Dry Cat Food': '|SPECIAL KITTY DRY CAT FOOD--|',
  'Specialty Pizza': '|SPECIALTY PIZZA--|',
  '+55 Menu': '|+55 MENU--|',
  'Accessories for Nintendo, PC, Xbox and PlayStation Systems':
    '|ACCESSORIES FOR NINTENDO, PC, XBOX AND PLAYSTATION SYSTEMS--|',
  'All Dimmers, Switches & Wall Plates':
    '|ALL DIMMERS, SWITCHES & WALL PLATES--|',
  'All Extension Cords': '|ALL EXTENSION CORDS--|',
  'All Flashlights': '|ALL FLASHLIGHTS--|',
  'All Tablets': '|ALL TABLETS--|',
  Annuals: '|ANNUALS--|',
  'Baby Boy Clothing': '|BABY BOY CLOTHING--|',
  'Baked Goods': '|BAKED GOODS--|',
  'Bath & Body': '|BATH & BODY--|',
  'Beverages > Coffee > Machiato': '|BEVERAGES-COFFEE-MACHIATO|',
  'Body Powder': '|BODY POWDER--|',
  'Cereal & Breakfast': '|CEREAL & BREAKFAST--|',
  'Charcoal Teeth Whitening': '|CHARCOAL TEETH WHITENING--|',
  'Citrus Fruits': '|CITRUS FRUITS--|',
  'Condiments & Dressings': '|CONDIMENTS & DRESSINGS--|',
  Deodorant: '|DEODORANT--|',
  'Detergent Pods': '|DETERGENT PODS--|',
  Dividers: '|DIVIDERS--|',
  'Electric Heaters': '|ELECTRIC HEATERS--|',
  'Equate Pads': '|EQUATE PADS--|',
  'Equate Sleep and Snoring': '|EQUATE SLEEP AND SNORING--|',
  'Favorite Giant Sandwich': '|FAVORITE GIANT SANDWICH--|',
  Foil: '|FOIL--|',
  'Food > Meat Entrees > Steak Entrees': '|FOOD-MEAT ENTREES-STEAK ENTREES|',
  'Garden Planters': '|GARDEN PLANTERS--|',
  'Gift Bows': '|GIFT BOWS--|',
  'Great Value Bleach': '|GREAT VALUE BLEACH--|',
  'Health & Beauty > Bath & Shower > Bubble Bath':
    '|HEALTH & BEAUTY-BATH & SHOWER-BUBBLE BATH|',
  'Home & Garden > BBQ & Garden > Tools': '|HOME & GARDEN-BBQ & GARDEN-TOOLS|',
  'Ice Cream Wafers & Cones': '|ICE CREAM WAFERS & CONES--|',
  'Juice and Milk': '|JUICE AND MILK--|',
  'Lemon-Lime & Citrus': '|LEMON-LIME & CITRUS--|',
  'Misc. > Store Services > Postage Stamps':
    '|MISC.-STORE SERVICES-POSTAGE STAMPS|',
  'Other Combos and Value': '|OTHER COMBOS AND VALUE--|',
  'Pajama Sets': '|PAJAMA SETS--|',
  'Paper & Plastic Tableware': '|PAPER & PLASTIC TABLEWARE--|',
  'Parchment Paper': '|PARCHMENT PAPER--|',
  'Party Trays': '|PARTY TRAYS--|',
  Pasta: '|PASTA--|',
  Plates: '|PLATES--|',
  'Pregnancy Tests': '|PREGNANCY TESTS--|',
  'Scott Paper Towels': '|SCOTT PAPER TOWELS--|',
  'Secret Treasures Sleepwear': '|SECRET TREASURES SLEEPWEAR--|',
  'Slider Variety': '|SLIDER VARIETY--|',
  'Special Kitty Wet Cat Food': '|SPECIAL KITTY WET CAT FOOD--|',
  'Tablecloths & Runners': '|TABLECLOTHS & RUNNERS--|',
  'Target > Beauty > Hair Care > Shampoo & Conditioner':
    '|TARGET > BEAUTY > HAIR CARE > SHAMPOO & CONDITIONER--|',
  'Toy Vehicles and Vehicle Playsets': '|TOY VEHICLES AND VEHICLE PLAYSETS--|',
  Umbrellas: '|UMBRELLAS--|',
  'Vacuum Parts': '|VACUUM PARTS--|',
  'Vibrant Life Pet Feeding & Watering':
    '|VIBRANT LIFE PET FEEDING & WATERING--|',
  'Wingstreet Wings': '|WINGSTREET WINGS--|',
  'Time and Tru Shoes': '|TIME AND TRU SHOES--|',
  'Traditional Yogurt': '|TRADITIONAL YOGURT--|',
  'Trash Bags & Containers': '|TRASH BAGS & CONTAINERS--|',
  'Viva Paper Towels': '|VIVA PAPER TOWELS--|',
  "Wendy's Kids Meal": "|WENDY'S KIDS MEAL--|",
  'Womens Jeggings': '|WOMENS JEGGINGS--|',
  'AA Batteries': '|AA BATTERIES--|',
  'All Camping Accessories': '|ALL CAMPING ACCESSORIES--|',
  'All Fabric': '|ALL FABRIC--|',
  'All Play Vehicles': '|ALL PLAY VEHICLES--|',
  'Antifreeze & Car Coolants': '|ANTIFREEZE & CAR COOLANTS--|',
  Avia: '|AVIA--|',
  'Baby Grooming & Healthcare Essentials':
    '|BABY GROOMING & HEALTHCARE ESSENTIALS--|',
  'Birthday Party Supplies': '|BIRTHDAY PARTY SUPPLIES--|',
  'Body Care Sets & Kits': '|BODY CARE SETS & KITS--|',
  'Breakfast & Snack Bars': '|BREAKFAST & SNACK BARS--|',
  Carmex: '|CARMEX--|',
  'Cat Litter Boxes': '|CAT LITTER BOXES--|',
  Classics: '|CLASSICS--|',
  Cleansers: '|CLEANSERS--|',
  'coffee and tea': '|COFFEE AND TEA--|',
  'Collectible Novelty': '|COLLECTIBLE NOVELTY--|',
  'Cotton Fabric': '|COTTON FABRIC--|',
  'Cupcake Liners': '|CUPCAKE LINERS--|',
  'Decorative Throw Pillows': '|DECORATIVE THROW PILLOWS--|',
  'desk organization and tools': '|DESK ORGANIZATION AND TOOLS--|',
  'Desserts (Archived)': '|DESSERTS (ARCHIVED)--|',
  Dinners: '|DINNERS--|',
  'Dog Grooming': '|DOG GROOMING--|',
  'Drawers & Carts': '|DRAWERS & CARTS--|',
  'Dried Fruits': '|DRIED FRUITS--|',
  'Equate Beauty Face Wipes': '|EQUATE BEAUTY FACE WIPES--|',
  Eyelashes: '|EYELASHES--|',
  'Family Meals': '|FAMILY MEALS--|',
  'Folding Tables & Chairs': '|FOLDING TABLES & CHAIRS--|',
  'Footlong Hot Subs': '|FOOTLONG HOT SUBS--|',
  'Frozen Food': '|FROZEN FOOD--|',
  'Frozen Meals & Entrees': '|FROZEN MEALS & ENTREES--|',
  'Hand Tool Sets': '|HAND TOOL SETS--|',
  'Hard Seltzer': '|HARD SELTZER--|',
  "Harry's": "|HARRY'S--|",
  'Home & Garden > Bed & Bath > Bath Hardware':
    '|HOME & GARDEN-BED & BATH-BATH HARDWARE|',
  'Hot Glue Guns': '|HOT GLUE GUNS--|',
  'House Plants': '|HOUSE PLANTS--|',
  'Ice Cream & Frozen Dairy Desserts': '|ICE CREAM & FROZEN DAIRY DESSERTS--|',
  'Iced Drinks': '|ICED DRINKS--|',
  'Indoor Christmas Decorations': '|INDOOR CHRISTMAS DECORATIONS--|',
  'Indoor Extension Cords': '|INDOOR EXTENSION CORDS--|',
  Kitchen: '|KITCHEN--|',
  'Kitchen Towels': '|KITCHEN TOWELS--|',
  'Learning & Development Toys': '|LEARNING & DEVELOPMENT TOYS--|',
  'Meals & Sides': '|MEALS & SIDES--|',
  'Natural & Organic': '|NATURAL & ORGANIC--|',
  'New Books': '|NEW BOOKS--|',
  'Nintendo Switch Games': '|NINTENDO SWITCH GAMES--|',
  Painting: '|PAINTING--|',
  'Pajama Bottoms': '|PAJAMA BOTTOMS--|',
  'Party Favors & Goody Bags': '|PARTY FAVORS & GOODY BAGS--|',
  'Party Supplies': '|PARTY SUPPLIES--|',
  'Pet Waste Cleaning and Deodorizing':
    '|PET WASTE CLEANING AND DEODORIZING--|',
  'Plastic Wrap & Foil': '|PLASTIC WRAP & FOIL--|',
  'Pretend Play Toys for Boys': '|PRETEND PLAY TOYS FOR BOYS--|',
  Primers: '|PRIMERS--|',
  'Pure Balance Dry Dog Food': '|PURE BALANCE DRY DOG FOOD--|',
  'sausages, bacon and pate': '|SAUSAGES, BACON AND PATE--|',
  Scissors: '|SCISSORS--|',
  'Sculptures & Figurines': '|SCULPTURES & FIGURINES--|',
  'Sewing Thread': '|SEWING THREAD--|',
  'Shop All Christmas Tree Decorations':
    '|SHOP ALL CHRISTMAS TREE DECORATIONS--|',
  'Skin Care Tools': '|SKIN CARE TOOLS--|',
  'Small Animal Bedding & Litter': '|SMALL ANIMAL BEDDING & LITTER--|',
  'Sporting Goods': '|SPORTING GOODS--|',
  'Stuffed Animals': '|STUFFED ANIMALS--|',
  'Ads Free Page #21': '|ADS FREE PAGE #21--|',
  'All Air Freshener Refills': '|ALL AIR FRESHENER REFILLS--|',
  'Aquarium Filtration, Heaters & Supplies':
    '|AQUARIUM FILTRATION, HEATERS & SUPPLIES--|',
  'Bath Bombs': '|BATH BOMBS--|',
  'board games and card games': '|BOARD GAMES AND CARD GAMES--|',
  Bodysuits: '|BODYSUITS--|',
  'Car Mounts': '|CAR MOUNTS--|',
  "Children's Party": "|CHILDREN'S PARTY--|",
  'Cookware Sets': '|COOKWARE SETS--|',
  'Counter Organizers': '|COUNTER ORGANIZERS--|',
  'dolls, puppets, and figures': '|DOLLS, PUPPETS, AND FIGURES--|',
  'Dry Erase Markers': '|DRY ERASE MARKERS--|',
  'Easter Eggs': '|EASTER EGGS--|',
  Entertainment: '|ENTERTAINMENT--|',
  'Equate Back & Body Aches Relief': '|EQUATE BACK & BODY ACHES RELIEF--|',
  'Equate Body Care': '|EQUATE BODY CARE--|',
  'Eye Masks': '|EYE MASKS--|',
  'Fake Nails': '|FAKE NAILS--|',
  'Featured Brands in Pet': '|FEATURED BRANDS IN PET--|',
  'First Aid Kits': '|FIRST AID KITS--|',
  'Fishing Line': '|FISHING LINE--|',
  'Food/Meal Solutions, Grains & Pasta/Canned Goods/Beans':
    '|FOOD/MEAL SOLUTIONS, GRAINS & PASTA/CANNED GOODS/BEANS--|',
  'Frozen Cappuccino': '|FROZEN CAPPUCCINO--|',
  'Grapes & Cherries': '|GRAPES & CHERRIES--|',
  'Hair Removal & Shave': '|HAIR REMOVAL & SHAVE--|',
  Halloween: '|HALLOWEEN--|',
  'Hard Soda & Seltzer': '|HARD SODA & SELTZER--|',
  'Health & Beauty > Bath & Shower > Adult Powders':
    '|HEALTH & BEAUTY-BATH & SHOWER-ADULT POWDERS|',
  'Huggies Pull-Ups': '|HUGGIES PULL-UPS--|',
  'Jerky & Dried Meat': '|JERKY & DRIED MEAT--|',
  "Kids' Dining": "|KIDS' DINING--|",
  Knives: '|KNIVES--|',
  'LED Light Bulbs': '|LED LIGHT BULBS--|',
  'Lollipops & Suckers': '|LOLLIPOPS & SUCKERS--|',
  'Mens Active Tops & T-Shirts': '|MENS ACTIVE TOPS & T-SHIRTS--|',
  'Mens Socks': '|MENS SOCKS--|',
  'Metallic Party Decorations': '|METALLIC PARTY DECORATIONS--|',
  Microwaves: '|MICROWAVES--|',
  Pads: '|PADS--|',
  Pepsi: '|PEPSI--|',
  Pizzas: '|PIZZAS--|',
  'Rawhide Chews': '|RAWHIDE CHEWS--|',
  'Remote Controls': '|REMOTE CONTROLS--|',
  'Room Air Freshener': '|ROOM AIR FRESHENER--|',
  Screwdrivers: '|SCREWDRIVERS--|',
  Seafood: '|SEAFOOD--|',
  'Soap & Body Wash': '|SOAP & BODY WASH--|',
  'Sprays & Repellents': '|SPRAYS & REPELLENTS--|',
  Stick: '|STICK--|',
  Suave: '|SUAVE--|',
  'Suavitel Fabric Softener': '|SUAVITEL FABRIC SOFTENER--|',
  'Tape & Tape Dispensers': '|TAPE & TAPE DISPENSERS--|',
  'Transmission Fluids': '|TRANSMISSION FLUIDS--|',
  'Travel Mugs': '|TRAVEL MUGS--|',
  'Travel Size Toiletries': '|TRAVEL SIZE TOILETRIES--|',
  'Vitamins and Supplements': '|VITAMINS AND SUPPLEMENTS--|',
  'Work Gloves': '|WORK GLOVES--|',
  Zappetizers: '|ZAPPETIZERS--|',
  'Vacuum Accessories': '|VACUUM ACCESSORIES--|',
  "Wing Combo's": "|WING COMBO'S--|",
  'All Cereal': '|ALL CEREAL--|',
  'All Febreze Air Fresheners': '|ALL FEBREZE AIR FRESHENERS--|',
  aromatherapy: '|AROMATHERAPY--|',
  'Bang Energy Drinks': '|BANG ENERGY DRINKS--|',
  Beauty: '|BEAUTY--|',
  'Body Scrubs & Treatments': '|BODY SCRUBS & TREATMENTS--|',
  'Card Games': '|CARD GAMES--|',
  'Charcoal and Smoker Accessories': '|CHARCOAL AND SMOKER ACCESSORIES--|',
  'Chips & Pretzels': '|CHIPS & PRETZELS--|',
  'Chopped Salads': '|CHOPPED SALADS--|',
  'Christmas Cookie & Cupcake Decorating':
    '|CHRISTMAS COOKIE & CUPCAKE DECORATING--|',
  'Coffee Mugs & Tea Cups': '|COFFEE MUGS & TEA CUPS--|',
  Colas: '|COLAS--|',
  'Corner Braces': '|CORNER BRACES--|',
  'Delivery Service Drinks': '|DELIVERY SERVICE DRINKS--|',
  Dessert: '|DESSERT--|',
  'Downy Scent Boosters': '|DOWNY SCENT BOOSTERS--|',
  'Dresses & Rompers': '|DRESSES & ROMPERS--|',
  'Dryer Sheets & Balls': '|DRYER SHEETS & BALLS--|',
  'Dryer Sheets & Fabric Enhancers': '|DRYER SHEETS & FABRIC ENHANCERS--|',
  'Dum Dums': '|DUM DUMS--|',
  'e.l.f. Face Makeup': '|E.L.F. FACE MAKEUP--|',
  'Emergency Preparedness': '|EMERGENCY PREPAREDNESS--|',
  'Facial Care': '|FACIAL CARE--|',
  'Food > Sandwiches & Wraps > Veggie Sandwich':
    '|FOOD-SANDWICHES & WRAPS-VEGGIE SANDWICH|',
  'Food > Seafood Entrees > Shrimp Entrees':
    '|FOOD-SEAFOOD ENTREES-SHRIMP ENTREES|',
  'Fresh Juices': '|FRESH JUICES--|',
  'Frosting & Icing': '|FROSTING & ICING--|',
  'Gel Pens': '|GEL PENS--|',
  'Home & Garden > Appliances': '|HOME & GARDEN-APPLIANCES-|',
  'Hunting Accessories': '|HUNTING ACCESSORIES--|',
  'Indoor Holiday Decor': '|INDOOR HOLIDAY DECOR--|',
  Irons: '|IRONS--|',
  "Kids' Blankets & Throws": "|KIDS' BLANKETS & THROWS--|",
  'Knives & Sharpeners': '|KNIVES & SHARPENERS--|',
  'Lettuce & Leafy Vegetables': '|LETTUCE & LEAFY VEGETABLES--|',
  'Liquid Fabric Softener': '|LIQUID FABRIC SOFTENER--|',
  'Makeup Restock': '|MAKEUP RESTOCK--|',
  'Mens T-Shirts': '|MENS T-SHIRTS--|',
  'Nail Polish & Removers': '|NAIL POLISH & REMOVERS--|',
  'Necklaces & Pendants': '|NECKLACES & PENDANTS--|',
  'Omelets and Skillets': '|OMELETS AND SKILLETS--|',
  'Pancakes and Such': '|PANCAKES AND SUCH--|',
  'Pedigree Dry Dog Food': '|PEDIGREE DRY DOG FOOD--|',
  'Pop-Tarts & Toaster Pastries': '|POP-TARTS & TOASTER PASTRIES--|',
  'Popcorn & Pretzels': '|POPCORN & PRETZELS--|',
  'Salad Kits': '|SALAD KITS--|',
  'Sewing Fasteners': '|SEWING FASTENERS--|',
  Shelving: '|SHELVING--|',
  'Shoe Care & Accessories': '|SHOE CARE & ACCESSORIES--|',
  'Signature Hot Panini': '|SIGNATURE HOT PANINI--|',
  'Sports & Duffel Bags': '|SPORTS & DUFFEL BAGS--|',
  'Stick Vacuums': '|STICK VACUUMS--|',
  Sunscreen: '|SUNSCREEN--|',
  'All Plumbing Parts and Repair': '|ALL PLUMBING PARTS AND REPAIR--|',
  'All Trash Bags': '|ALL TRASH BAGS--|',
  'Baby Toys': '|BABY TOYS--|',
  'Baby Wipes & Warmers': '|BABY WIPES & WARMERS--|',
  Bakes: '|BAKES--|',
  Belts: '|BELTS--|',
  'Bird Food': '|BIRD FOOD--|',
  'Black & White Gift Wrap Supplies': '|BLACK & WHITE GIFT WRAP SUPPLIES--|',
  'Cake & Bundt Pans': '|CAKE & BUNDT PANS--|',
  'Cake and Soft Serve Pies': '|CAKE AND SOFT SERVE PIES--|',
  'Canned & Packaged Foods': '|CANNED & PACKAGED FOODS--|',
  'Carts & Drawer Units': '|CARTS & DRAWER UNITS--|',
  'Cheese Crackers': '|CHEESE CRACKERS--|',
  "Children's Oral Care": "|CHILDREN'S ORAL CARE--|",
  'Coffee Creamer': '|COFFEE CREAMER--|',
  'Daily Living Aids': '|DAILY LIVING AIDS--|',
  'Denture Care': '|DENTURE CARE--|',
  Diapers: '|DIAPERS--|',
  'Dimensional Lumber': '|DIMENSIONAL LUMBER--|',
  'Dinner Plates': '|DINNER PLATES--|',
  'Dog Flea & Tick': '|DOG FLEA & TICK--|',
  Dolls: '|DOLLS--|',
  'Door Locks and Deadbolts': '|DOOR LOCKS AND DEADBOLTS--|',
  'Downy Liquid Fabric Softener': '|DOWNY LIQUID FABRIC SOFTENER--|',
  'Electric Toothbrushes': '|ELECTRIC TOOTHBRUSHES--|',
  'Eyebrow Enhancers': '|EYEBROW ENHANCERS--|',
  'Food > Appetizers & Sides > Pasta': '|FOOD-APPETIZERS & SIDES-PASTA|',
  'Food > Breakfast > Bagels': '|FOOD-BREAKFAST-BAGELS|',
  'Food/Baking/Sugar & Sweeteners': '|FOOD/BAKING/SUGAR & SWEETENERS--|',
  'Food/Condiments, Sauces & Spices/Salad Dressings':
    '|FOOD/CONDIMENTS, SAUCES & SPICES/SALAD DRESSINGS--|',
  Foodservice: '|FOODSERVICE--|',
  'gift wrap and paper supplies': '|GIFT WRAP AND PAPER SUPPLIES--|',
  'Ground Beef': '|GROUND BEEF--|',
  'Hair Care Restock': '|HAIR CARE RESTOCK--|',
  'Halloween Lighting': '|HALLOWEEN LIGHTING--|',
  'Halloween Masks': '|HALLOWEEN MASKS--|',
  'Headphones and Headsets': '|HEADPHONES AND HEADSETS--|',
  'Health & Beauty > Pain Relief > Arthritis':
    '|HEALTH & BEAUTY-PAIN RELIEF-ARTHRITIS|',
  'Home & Garden > Home Essentials > Shoe Care':
    '|HOME & GARDEN-HOME ESSENTIALS-SHOE CARE|',
  'Home & Garden > Kitchen > Sink': '|HOME & GARDEN-KITCHEN-SINK|',
  'Ice Cream & Dessert': '|ICE CREAM & DESSERT--|',
  Lipstick: '|LIPSTICK--|',
  Loungewear: '|LOUNGEWEAR--|',
  Mailers: '|MAILERS--|',
  "Men's": "|MEN'S--|",
  'Most Popular Meal': '|MOST POPULAR MEAL--|',
  'Muffins & Snack Cakes': '|MUFFINS & SNACK CAKES--|',
  'Novelty Lighting': '|NOVELTY LIGHTING--|',
  'Other Entrees': '|OTHER ENTREES--|',
  'Other Halloween Accessories': '|OTHER HALLOWEEN ACCESSORIES--|',
  'Ozark Trail Air Mattresses': '|OZARK TRAIL AIR MATTRESSES--|',
  'Paint & Painting Supplies': '|PAINT & PAINTING SUPPLIES--|',
  'Personal Care/Hair Care/Styling Accessories':
    '|PERSONAL CARE/HAIR CARE/STYLING ACCESSORIES--|',
  'Pillsbury Frosting': '|PILLSBURY FROSTING--|',
  'Pre-Hung Doors': '|PRE-HUNG DOORS--|',
  'Salsa & Queso': '|SALSA & QUESO--|',
  'Salty Snacks': '|SALTY SNACKS--|',
  'Shatterproof Christmas Ornaments': '|SHATTERPROOF CHRISTMAS ORNAMENTS--|',
  'Shirts & Blouses': '|SHIRTS & BLOUSES--|',
  'Shop All Floor Lamps': '|SHOP ALL FLOOR LAMPS--|',
  'Side and Extras': '|SIDE AND EXTRAS--|',
  'SIM Cards': '|SIM CARDS--|',
  Singer: '|SINGER--|',
  'Sleep & Snoring Aids': '|SLEEP & SNORING AIDS--|',
  'Snack, Nuts & Trail Mix': '|SNACK, NUTS & TRAIL MIX--|',
  'Steering Wheel Covers': '|STEERING WHEEL COVERS--|',
  'Unisex Body Spray': '|UNISEX BODY SPRAY--|',
  "Valentine's Day Stuffed Animals": "|VALENTINE'S DAY STUFFED ANIMALS--|",
  'Wireless Bra': '|WIRELESS BRA--|',
  'Xbox One Controllers': '|XBOX ONE CONTROLLERS--|',
  'Teriyaki Bowls': '|TERIYAKI BOWLS--|',
  'Toddler Boys Pajama Sets': '|TODDLER BOYS PAJAMA SETS--|',
  'Tumble Dryer Sheets': '|TUMBLE DRYER SHEETS--|',
  'Value Menu': '|VALUE MENU--|',
  "Wacky PackÂ® Kids' Meals": "|WACKY PACKÂ® KIDS' MEALS--|",
  'Wall Decor': '|WALL DECOR--|',
  'Waterproofers and Sealers': '|WATERPROOFERS AND SEALERS--|',
  'Alkaline Batteries': '|ALKALINE BATTERIES--|',
  'All Departments > Grocery > Fresh Food':
    '|ALL DEPARTMENTS-GROCERY-FRESH FOOD|',
  'All Shampoo': '|ALL SHAMPOO--|',
  Antiseptics: '|ANTISEPTICS--|',
  'Appearance Boards': '|APPEARANCE BOARDS--|',
  'Arts & Crafts Supplies': '|ARTS & CRAFTS SUPPLIES--|',
  'Baby Gates': '|BABY GATES--|',
  'Barbie Sisters, Friends, & Pets': '|BARBIE SISTERS, FRIENDS, & PETS--|',
  'Bath Towel Sets': '|BATH TOWEL SETS--|',
  'Between Washes': '|BETWEEN WASHES--|',
  'Bibs and Burp Cloths': '|BIBS AND BURP CLOTHS--|',
  'Body Spray for Women': '|BODY SPRAY FOR WOMEN--|',
  'Bowl Brushes & Plungers': '|BOWL BRUSHES & PLUNGERS--|',
  'Braces & Supports': '|BRACES & SUPPORTS--|',
  'Breads & Bakery': '|BREADS & BAKERY--|',
  'Brownies & Dessert Bars': '|BROWNIES & DESSERT BARS--|',
  'Bucket Meals': '|BUCKET MEALS--|',
  'Burrito Bowls': '|BURRITO BOWLS--|',
  'Canned Tuna': '|CANNED TUNA--|',
  'Canning & Preserving': '|CANNING & PRESERVING--|',
  'Car Detailing Kits': '|CAR DETAILING KITS--|',
  ChapStick: '|CHAPSTICK--|',
  'Christmas Wrapping Paper': '|CHRISTMAS WRAPPING PAPER--|',
  'Cleaning Chemicals': '|CLEANING CHEMICALS--|',
  'Cooking & Baking': '|COOKING & BAKING--|',
  'Deodorants & Antiperspirant': '|DEODORANTS & ANTIPERSPIRANT--|',
  'Dog Feeding Water and Bowls': '|DOG FEEDING WATER AND BOWLS--|',
  'Drop Cloths': '|DROP CLOTHS--|',
  'Dryer Sheets': '|DRYER SHEETS--|',
  'Energy & Endurance': '|ENERGY & ENDURANCE--|',
  'Extended Service Plans': '|EXTENDED SERVICE PLANS--|',
  'Fashion Necklaces & Pendants': '|FASHION NECKLACES & PENDANTS--|',
  'Festival Hair Color': '|FESTIVAL HAIR COLOR--|',
  'Five Star': '|FIVE STAR--|',
  'Food > Pizza & Calzones': '|FOOD-PIZZA & CALZONES-|',
  'Food Storage Containers': '|FOOD STORAGE CONTAINERS--|',
  'Fragrance Restock': '|FRAGRANCE RESTOCK--|',
  'Fresh Eggs': '|FRESH EGGS--|',
  Fruit: '|FRUIT--|',
  'George Shoes': '|GEORGE SHOES--|',
  'Granulated Sugar': '|GRANULATED SUGAR--|',
  'Halloween Snacks': '|HALLOWEEN SNACKS--|',
  'Hand & Body Lotion': '|HAND & BODY LOTION--|',
  'Hand-Sewing Needles & Thimbles': '|HAND-SEWING NEEDLES & THIMBLES--|',
  'Health & Beauty > Digestive Aids > Motion Sickness':
    '|HEALTH & BEAUTY-DIGESTIVE AIDS-MOTION SICKNESS|',
  'Health & Beauty > Fragrance > Women': '|HEALTH & BEAUTY-FRAGRANCE-WOMEN|',
  'Indoor Halloween Decorations': '|INDOOR HALLOWEEN DECORATIONS--|',
  'Insect & Pest Repellents': '|INSECT & PEST REPELLENTS--|',
  'Instant Oatmeal': '|INSTANT OATMEAL--|',
  'Iodized Salt': '|IODIZED SALT--|',
  'Key Accessories': '|KEY ACCESSORIES--|',
  Kids: '|KIDS--|',
  'L.O.L. Surprise! Dolls': '|L.O.L. SURPRISE! DOLLS--|',
  Lip: '|LIP--|',
  'Lipstick & Lipstains': '|LIPSTICK & LIPSTAINS--|',
  'Mechanical Pencils': '|MECHANICAL PENCILS--|',
  'Medium Hot Specialty Subs': '|MEDIUM HOT SPECIALTY SUBS--|',
  'Mens Pants': '|MENS PANTS--|',
  Minerals: '|MINERALS--|',
  Mints: '|MINTS--|',
  'Non-Aspirin': '|NON-ASPIRIN--|',
  'Packaging & Shipping Tape': '|PACKAGING & SHIPPING TAPE--|',
  'Pantene Conditioners': '|PANTENE CONDITIONERS--|',
  Panties: '|PANTIES--|',
  "Parent's Choice Baby Food": "|PARENT'S CHOICE BABY FOOD--|",
  'Passenger Car Tires': '|PASSENGER CAR TIRES--|',
  'Pedigree Wet Dog Food': '|PEDIGREE WET DOG FOOD--|',
  'Permanent Hair Color': '|PERMANENT HAIR COLOR--|',
  'Place Settings And Utensils': '|PLACE SETTINGS AND UTENSILS--|',
  'Plant Pots': '|PLANT POTS--|',
  Pods: '|PODS--|',
  'Potatoes, Fries, & Onion Rings': '|POTATOES, FRIES, & ONION RINGS--|',
  'Pretend Play & Dress Up': '|PRETEND PLAY & DRESS UP--|',
  'Saltine Crackers': '|SALTINE CRACKERS--|',
  Sandpaper: '|SANDPAPER--|',
  'School Supplies': '|SCHOOL SUPPLIES--|',
  'Shop All Storage Baskets & Bins': '|SHOP ALL STORAGE BASKETS & BINS--|',
  'Shower Curtains & Accessories': '|SHOWER CURTAINS & ACCESSORIES--|',
  'Small Animal Food 2': '|SMALL ANIMAL FOOD 2--|',
  'Snuggle Scent Boosters': '|SNUGGLE SCENT BOOSTERS--|',
  'Storage Boxes & Bins': '|STORAGE BOXES & BINS--|',
  'Sweatshirts & Sweatpants': '|SWEATSHIRTS & SWEATPANTS--|',
  'Sweet Side': '|SWEET SIDE--|',
  Tablecloths: '|TABLECLOTHS--|',
  '4 Pack Trend Ornaments': '|4 PACK TREND ORNAMENTS--|',
  'Action & Adventure Games': '|ACTION & ADVENTURE GAMES--|',
  'All Automotive Diagnostic Equipment':
    '|ALL AUTOMOTIVE DIAGNOSTIC EQUIPMENT--|',
  'All Collectible Figures': '|ALL COLLECTIBLE FIGURES--|',
  'All Kids & Baby Shoes': '|ALL KIDS & BABY SHOES--|',
  'All Pencils': '|ALL PENCILS--|',
  'All Phones': '|ALL PHONES--|',
  'Bandages & Bandaging Supplies': '|BANDAGES & BANDAGING SUPPLIES--|',
  Beads: '|BEADS--|',
  'Beauty/Makeup/Nails': '|BEAUTY/MAKEUP/NAILS--|',
  'Bed Blankets': '|BED BLANKETS--|',
  'Birthday Cake Mix': '|BIRTHDAY CAKE MIX--|',
  'Booster Car Seats': '|BOOSTER CAR SEATS--|',
  'Building Sets': '|BUILDING SETS--|',
  'Candle Air Fresheners': '|CANDLE AIR FRESHENERS--|',
  'Chips & Puffs': '|CHIPS & PUFFS--|',
  'Chips on Sale!': '|CHIPS ON SALE!--|',
  Cinnamon: '|CINNAMON--|',
  'Cocoa Powder': '|COCOA POWDER--|',
  'Condiments & Seasonings': '|CONDIMENTS & SEASONINGS--|',
  'Cubbies & Storage Cubes': '|CUBBIES & STORAGE CUBES--|',
  'Cups & Cones': '|CUPS & CONES--|',
  'Dips, Spreads & Salsa': '|DIPS, SPREADS & SALSA--|',
  'Doll Clothes & Accessories': '|DOLL CLOTHES & ACCESSORIES--|',
  'Drinkware Accessories': '|DRINKWARE ACCESSORIES--|',
  'Drywall Screws': '|DRYWALL SCREWS--|',
  Entrees: '|ENTREES--|',
  Extras: '|EXTRAS--|',
  'Floor Cleaners': '|FLOOR CLEANERS--|',
  'Food > Bowls > Veggie Bowl': '|FOOD-BOWLS-VEGGIE BOWL|',
  'Freeman Face Masks': '|FREEMAN FACE MASKS--|',
  'Friskies Wet Cat Food': '|FRISKIES WET CAT FOOD--|',
  "Girls' Character Shop": "|GIRLS' CHARACTER SHOP--|",
  'Glue & Glue Sticks': '|GLUE & GLUE STICKS--|',
  'Hair Brushes & Combs': '|HAIR BRUSHES & COMBS--|',
  'Hair Gel': '|HAIR GEL--|',
  'Health & Beauty > Intimate Care': '|HEALTH & BEAUTY-INTIMATE CARE-|',
  'Interior Paint': '|INTERIOR PAINT--|',
  Intex: '|INTEX--|',
  'Jigsaw Puzzles': '|JIGSAW PUZZLES--|',
  "Kids' Accessories": "|KIDS' ACCESSORIES--|",
  'Kitchen Cleaners': '|KITCHEN CLEANERS--|',
  "L'Oreal Makeup": "|L'OREAL MAKEUP--|",
  'Macaroni & Cheese': '|MACARONI & CHEESE--|',
  'Machine Screws': '|MACHINE SCREWS--|',
  'Measuring & Layout Tools': '|MEASURING & LAYOUT TOOLS--|',
  'Minced Garlic': '|MINCED GARLIC--|',
  'Oatmeal Cookies': '|OATMEAL COOKIES--|',
  'onn. Headphones': '|ONN. HEADPHONES--|',
  'onn. TV & Video Accessories': '|ONN. TV & VIDEO ACCESSORIES--|',
  'onn. TV Cables': '|ONN. TV CABLES--|',
  'Other Adhesive Tape': '|OTHER ADHESIVE TAPE--|',
  'Painkillers and Muscle Treatments': '|PAINKILLERS AND MUSCLE TREATMENTS--|',
  'Pantene Shampoos': '|PANTENE SHAMPOOS--|',
  'Perfume for Women': '|PERFUME FOR WOMEN--|',
  'Pet All Star Waste Management': '|PET ALL STAR WASTE MANAGEMENT--|',
  'Prepared Fruits': '|PREPARED FRUITS--|',
  'Protein Shakes & Drinks': '|PROTEIN SHAKES & DRINKS--|',
  Refreshers: '|REFRESHERS--|',
  'Screen Protectors': '|SCREEN PROTECTORS--|',
  'Shower Curtains & Liners': '|SHOWER CURTAINS & LINERS--|',
  Slippers: '|SLIPPERS--|',
  'Small Animal Food': '|SMALL ANIMAL FOOD--|',
  'Small Animal Treats': '|SMALL ANIMAL TREATS--|',
  'Spring Valley Sleep & Melatonin': '|SPRING VALLEY SLEEP & MELATONIN--|',
  'Target > Beauty > Bath & Body > Body Wash & Shower Gel':
    '|TARGET > BEAUTY > BATH & BODY > BODY WASH & SHOWER GEL--|',
  'Time and Tru Tops & Tees': '|TIME AND TRU TOPS & TEES--|',
  'Toddler Boys T-Shirts & Tank Tops': '|TODDLER BOYS T-SHIRTS & TANK TOPS--|',
  'Toys for Kids 5 to 7 Years': '|TOYS FOR KIDS 5 TO 7 YEARS--|',
  'Trail Mixes and Healthy Snacks': '|TRAIL MIXES AND HEALTHY SNACKS--|',
  'Travel Size Conditioner': '|TRAVEL SIZE CONDITIONER--|',
  'Variety & Multi Packs': '|VARIETY & MULTI PACKS--|',
  'Vegetables & Fruit': '|VEGETABLES & FRUIT--|',
  'Vibrant Life Dog Supplies': '|VIBRANT LIFE DOG SUPPLIES--|',
  'Video Game Titles': '|VIDEO GAME TITLES--|',
  "Women's": "|WOMEN'S--|",
  "Women's Sunglasses": "|WOMEN'S SUNGLASSES--|",
  'Womens Activewear Sweatpants': '|WOMENS ACTIVEWEAR SWEATPANTS--|',
  'Xbox One Accessories': '|XBOX ONE ACCESSORIES--|',
  'Toddler Boys Pants': '|TODDLER BOYS PANTS--|',
  'Toilet Seats': '|TOILET SEATS--|',
  'Toy Finder': '|TOY FINDER--|',
  'Transparent Tape with Dispensers': '|TRANSPARENT TAPE WITH DISPENSERS--|',
  'Tub Mats': '|TUB MATS--|',
  'utility bins, bags and totes': '|UTILITY BINS, BAGS AND TOTES--|',
  'Variety Pack Chips': '|VARIETY PACK CHIPS--|',
  Vases: '|VASES--|',
  White: '|WHITE--|',
  'Womens Wide Shoes': '|WOMENS WIDE SHOES--|',
  'Xtra Laundry Detergent': '|XTRA LAUNDRY DETERGENT--|',
  'Air Care': '|AIR CARE--|',
  'All Cutting Boards': '|ALL CUTTING BOARDS--|',
  'All Horror Movies': '|ALL HORROR MOVIES--|',
  'All Soup': '|ALL SOUP--|',
  'All Stains': '|ALL STAINS--|',
  'Automotive Fuel Additives': '|AUTOMOTIVE FUEL ADDITIVES--|',
  'Baby & Toddler': '|BABY & TODDLER--|',
  'Baking & Decorating Tools': '|BAKING & DECORATING TOOLS--|',
  'Baking Dishes': '|BAKING DISHES--|',
  'Baking Essentials': '|BAKING ESSENTIALS--|',
  'Baking Nuts, Coconut & Fruit': '|BAKING NUTS, COCONUT & FRUIT--|',
  'Bathroom Hardware': '|BATHROOM HARDWARE--|',
  'Big Boys Socks': '|BIG BOYS SOCKS--|',
  'Big Girls Socks': '|BIG GIRLS SOCKS--|',
  'Boy Shorts': '|BOY SHORTS--|',
  'Broth, Bouillon, & Stock': '|BROTH, BOUILLON, & STOCK--|',
  'Bubble Bath & Soaks': '|BUBBLE BATH & SOAKS--|',
  'Candy Canes': '|CANDY CANES--|',
  'Cat Treats & Catnip': '|CAT TREATS & CATNIP--|',
  'Coffee Makers': '|COFFEE MAKERS--|',
  'Cold & Flu': '|COLD & FLU--|',
  'Computer Cables & Connectors': '|COMPUTER CABLES & CONNECTORS--|',
  'Conair Hair Styling Tools': '|CONAIR HAIR STYLING TOOLS--|',
  Cosmetics: '|COSMETICS--|',
  'Craft Wood': '|CRAFT WOOD--|',
  'Decorative Throws': '|DECORATIVE THROWS--|',
  'Dove Personal Care': '|DOVE PERSONAL CARE--|',
  'Electronic Pets': '|ELECTRONIC PETS--|',
  'Equate Children': '|EQUATE CHILDREN--|',
  'Exceptional Value Tech': '|EXCEPTIONAL VALUE TECH--|',
  'file organization and holders': '|FILE ORGANIZATION AND HOLDERS--|',
  'Filtration, Heaters & Supplies': '|FILTRATION, HEATERS & SUPPLIES--|',
  'Fisher-Price Toys': '|FISHER-PRICE TOYS--|',
  'Foils & Wraps': '|FOILS & WRAPS--|',
  'Fresh Choices': '|FRESH CHOICES--|',
  'Frozen Meat, Poultry & Seafood': '|FROZEN MEAT, POULTRY & SEAFOOD--|',
  'Glue Sticks': '|GLUE STICKS--|',
  'Grill Accessories & BBQ Tools': '|GRILL ACCESSORIES & BBQ TOOLS--|',
  'Grocery > Snacks > Miscellaneous': '|GROCERY-SNACKS-MISCELLANEOUS|',
  'Guinea Pig Food': '|GUINEA PIG FOOD--|',
  'Hair Clips': '|HAIR CLIPS--|',
  'hair styling products': '|HAIR STYLING PRODUCTS--|',
  'Halloween Costume Accessories': '|HALLOWEEN COSTUME ACCESSORIES--|',
  'Happier Hour': '|HAPPIER HOUR--|',
  'Hard Seltzers & Malt Drinks': '|HARD SELTZERS & MALT DRINKS--|',
  Hosiery: '|HOSIERY--|',
  'Household Essentials/Batteries': '|HOUSEHOLD ESSENTIALS/BATTERIES--|',
  'kids games & toys': '|KIDS GAMES & TOYS--|',
  'Kiss Lashes': '|KISS LASHES--|',
  'Leave In Conditioner': '|LEAVE IN CONDITIONER--|',
  'LG Stylo 4 Cases': '|LG STYLO 4 CASES--|',
  'Liquid Glues': '|LIQUID GLUES--|',
  'Moving Boxes': '|MOVING BOXES--|',
  Naturals: '|NATURALS--|',
  'Other Arts & Crafts': '|OTHER ARTS & CRAFTS--|',
  'Over The Door Mirrors': '|OVER THE DOOR MIRRORS--|',
  'Packing and Shipping Boxes': '|PACKING AND SHIPPING BOXES--|',
  'Pizza Stones & Pans': '|PIZZA STONES & PANS--|',
  'Plates, Bowls, Cups & Flatware': '|PLATES, BOWLS, CUPS & FLATWARE--|',
  'Pockets & Sandwiches': '|POCKETS & SANDWICHES--|',
  'Popsicle Sticks & Dowels': '|POPSICLE STICKS & DOWELS--|',
  'Precut Fabric': '|PRECUT FABRIC--|',
  'Press On Nails': '|PRESS ON NAILS--|',
  'Pretend Play': '|PRETEND PLAY--|',
  'Printer Accessories': '|PRINTER ACCESSORIES--|',
  'Protein Shakes': '|PROTEIN SHAKES--|',
  'Purina Beneful Dry Dog Food': '|PURINA BENEFUL DRY DOG FOOD--|',
  'Reading Glasses (View All)': '|READING GLASSES (VIEW ALL)--|',
  'Room Air Fresheners': '|ROOM AIR FRESHENERS--|',
  'Sheet Metal Screws': '|SHEET METAL SCREWS--|',
  'Shop All Trash Cans': '|SHOP ALL TRASH CANS--|',
  Skittles: '|SKITTLES--|',
  'Specialty Gear': '|SPECIALTY GEAR--|',
  'Surface Mount Tiles': '|SURFACE MOUNT TILES--|',
  'Surge Protectors & UPS': '|SURGE PROTECTORS & UPS--|',
  'Sweet Rolls': '|SWEET ROLLS--|',
  'Action Figure Playsets': '|ACTION FIGURE PLAYSETS--|',
  'Adult Cold Remedies': '|ADULT COLD REMEDIES--|',
  'All Barbie Dolls': '|ALL BARBIE DOLLS--|',
  'All Frozen Pizza': '|ALL FROZEN PIZZA--|',
  'Antiperspirant and Deodorant': '|ANTIPERSPIRANT AND DEODORANT--|',
  'Appliance Parts and Accessories': '|APPLIANCE PARTS AND ACCESSORIES--|',
  'Apt. 9': '|APT. 9--|',
  'Baby Toiletries': '|BABY TOILETRIES--|',
  Balloons: '|BALLOONS--|',
  'Bath Bombs & Soaks': '|BATH BOMBS & SOAKS--|',
  'Beef Dog Food': '|BEEF DOG FOOD--|',
  'Big Boys T-Shirts & Tank Tops': '|BIG BOYS T-SHIRTS & TANK TOPS--|',
  'Body Weight Scales': '|BODY WEIGHT SCALES--|',
  'Box Fans': '|BOX FANS--|',
  'Cables & Chargers': '|CABLES & CHARGERS--|',
  'Cell Phone Chargers': '|CELL PHONE CHARGERS--|',
  'Christmas Garlands & Pom Poms': '|CHRISTMAS GARLANDS & POM POMS--|',
  'Clay, Putty & Compounds': '|CLAY, PUTTY & COMPOUNDS--|',
  'Coatings & Batters': '|COATINGS & BATTERS--|',
  Comforters: '|COMFORTERS--|',
  'Compression Sleeves': '|COMPRESSION SLEEVES--|',
  'Corn Chips': '|CORN CHIPS--|',
  'Cotton Balls & Swabs': '|COTTON BALLS & SWABS--|',
  'Couplings & Adaptors': '|COUPLINGS & ADAPTORS--|',
  'Craft Dyes': '|CRAFT DYES--|',
  'Desserts & Pastries': '|DESSERTS & PASTRIES--|',
  'Dish Washing': '|DISH WASHING--|',
  'Downy Fabric Softener': '|DOWNY FABRIC SOFTENER--|',
  'Drop Ceiling Tiles': '|DROP CEILING TILES--|',
  'Duct Tape': '|DUCT TAPE--|',
  'Equate Minerals & Supplements': '|EQUATE MINERALS & SUPPLEMENTS--|',
  'Face Moisturizer': '|FACE MOISTURIZER--|',
  'Facial Moisturizers': '|FACIAL MOISTURIZERS--|',
  'Fashion Pendants & Necklaces': '|FASHION PENDANTS & NECKLACES--|',
  'Food/Meal Solutions, Grains & Pasta/Boxed Meals':
    '|FOOD/MEAL SOLUTIONS, GRAINS & PASTA/BOXED MEALS--|',
  'Fresh Food': '|FRESH FOOD--|',
  'Granola & Cereal Bars': '|GRANOLA & CEREAL BARS--|',
  'Great Value Fabric Softener': '|GREAT VALUE FABRIC SOFTENER--|',
  'Ground & Instant Coffee': '|GROUND & INSTANT COFFEE--|',
  Haircare: '|HAIRCARE--|',
  'Household Essentials/Paper & Plastic/Disposable Tabletop':
    '|HOUSEHOLD ESSENTIALS/PAPER & PLASTIC/DISPOSABLE TABLETOP--|',
  'HP Printer Ink and Toner': '|HP PRINTER INK AND TONER--|',
  Incense: '|INCENSE--|',
  'Infant and Toddler Developmental Toys':
    '|INFANT AND TODDLER DEVELOPMENTAL TOYS--|',
  'Insect & Pest Traps': '|INSECT & PEST TRAPS--|',
  "Kids' Art & Activities": "|KIDS' ART & ACTIVITIES--|",
  'Kill Visible Bugs': '|KILL VISIBLE BUGS--|',
  'Large Hot Specialty Subs': '|LARGE HOT SPECIALTY SUBS--|',
  'Large Trash Bags': '|LARGE TRASH BAGS--|',
  'Latex Balloons': '|LATEX BALLOONS--|',
  Lunch: '|LUNCH--|',
  'Makeup Primers & Setters': '|MAKEUP PRIMERS & SETTERS--|',
  'Medicines & Treatments': '|MEDICINES & TREATMENTS--|',
  Moisturizers: '|MOISTURIZERS--|',
  'New Arrivals': '|NEW ARRIVALS--|',
  'Notebooks & Journals': '|NOTEBOOKS & JOURNALS--|',
  'Novelty Toys': '|NOVELTY TOYS--|',
  'Oil & Blemish Control': '|OIL & BLEMISH CONTROL--|',
  'Other Plastic Gaming Gift Cards': '|OTHER PLASTIC GAMING GIFT CARDS--|',
  Outfits: '|OUTFITS--|',
  'Packing Tape': '|PACKING TAPE--|',
  'Peanut Butter & Jelly': '|PEANUT BUTTER & JELLY--|',
  'Peanut Butter Cookies': '|PEANUT BUTTER COOKIES--|',
  'Personal Pan Pizza': '|PERSONAL PAN PIZZA--|',
  Pies: '|PIES--|',
  'Pineapple Juice': '|PINEAPPLE JUICE--|',
  'Plates & Cutlery': '|PLATES & CUTLERY--|',
  'PlayStation 4 Games': '|PLAYSTATION 4 GAMES--|',
  'Pots & Planters': '|POTS & PLANTERS--|',
  'Prepared Meals': '|PREPARED MEALS--|',
  'Prepared Sides': '|PREPARED SIDES--|',
  'Pureed Baby Food': '|PUREED BABY FOOD--|',
  'Quesadillas & Nachos': '|QUESADILLAS & NACHOS--|',
  'Salad Dressing Toppings': '|SALAD DRESSING TOPPINGS--|',
  'Sally Hansen Nail Polish': '|SALLY HANSEN NAIL POLISH--|',
  'sandwiches, salads and wraps': '|SANDWICHES, SALADS AND WRAPS--|',
  'Sauces & Gravy': '|SAUCES & GRAVY--|',
  'Scrub Bottoms': '|SCRUB BOTTOMS--|',
  'Seafood Dinners': '|SEAFOOD DINNERS--|',
  'Shirts & Tees': '|SHIRTS & TEES--|',
  'Shower Curtain Sets': '|SHOWER CURTAIN SETS--|',
  'snack and nutrition bars': '|SNACK AND NUTRITION BARS--|',
  'SONOMA Goods for Life': '|SONOMA GOODS FOR LIFE--|',
  'Soups, Broth & Chili': '|SOUPS, BROTH & CHILI--|',
  Spices: '|SPICES--|',
  Spray: '|SPRAY--|',
  Sprayers: '|SPRAYERS--|',
  Strawberries: '|STRAWBERRIES--|',
  'Surface Protectors': '|SURFACE PROTECTORS--|',
  'Target > Beauty > Bath & Body > Bubble Bath & Soaks':
    '|TARGET > BEAUTY > BATH & BODY > BUBBLE BATH & SOAKS--|',
  'Target > Personal Care': '|TARGET > PERSONAL CARE--|',
  "The Collector's Spot": "|THE COLLECTOR'S SPOT--|",
  'Toddler Boys Summer Shop T-Shirts & Tank Tops':
    '|TODDLER BOYS SUMMER SHOP T-SHIRTS & TANK TOPS--|',
  'Toddler Girls Leggings': '|TODDLER GIRLS LEGGINGS--|',
  Tostadas: '|TOSTADAS--|',
  'Trash Cans & Recycling Bins': '|TRASH CANS & RECYCLING BINS--|',
  Treatments: '|TREATMENTS--|',
  "Valentine's Day Gifts": "|VALENTINE'S DAY GIFTS--|",
  Wafers: '|WAFERS--|',
  'Water, Seltzer & Sparkling Water': '|WATER, SELTZER & SPARKLING WATER--|',
  'wet n wild Eye Makeup': '|WET N WILD EYE MAKEUP--|',
  'wet n wild Lip Makeup': '|WET N WILD LIP MAKEUP--|',
  'Wild Harvest Pet Supplies': '|WILD HARVEST PET SUPPLIES--|',
  'Womens Activewear Sweatshirts & Hoodies':
    '|WOMENS ACTIVEWEAR SWEATSHIRTS & HOODIES--|',
  'Womens Casual Shoes': '|WOMENS CASUAL SHOES--|',
  'Womens Casual Socks': '|WOMENS CASUAL SOCKS--|',
  'Workout Supplements & Protein Powders':
    '|WORKOUT SUPPLEMENTS & PROTEIN POWDERS--|',
  'Throw Pillows': '|THROW PILLOWS--|',
  'Time and Tru Handbags & Accessories':
    '|TIME AND TRU HANDBAGS & ACCESSORIES--|',
  'Tootsie Roll': '|TOOTSIE ROLL--|',
  'Top Rated Toys by Kids': '|TOP RATED TOYS BY KIDS--|',
  'Twin Air Mattresses': '|TWIN AIR MATTRESSES--|',
  'Underwire Bra': '|UNDERWIRE BRA--|',
  'video game accessories': '|VIDEO GAME ACCESSORIES--|',
  'Vinyl Mini Blinds': '|VINYL MINI BLINDS--|',
  Wipes: '|WIPES--|',
  "Women's Perfume": "|WOMEN'S PERFUME--|",
  "Women's Shaving Cream": "|WOMEN'S SHAVING CREAM--|",
  "Women's Underwear": "|WOMEN'S UNDERWEAR--|",
  'Womens Casual Pants': '|WOMENS CASUAL PANTS--|',
  'Wooden and #2 Pencils': '|WOODEN AND #2 PENCILS--|',
  '9Lives Dry Cat Food': '|9LIVES DRY CAT FOOD--|',
  'Adult Scissors': '|ADULT SCISSORS--|',
  'All Dog Collars & Leashes': '|ALL DOG COLLARS & LEASHES--|',
  'All Knives and Tools': '|ALL KNIVES AND TOOLS--|',
  'All Outlets': '|ALL OUTLETS--|',
  'All Pest Control': '|ALL PEST CONTROL--|',
  'Allergy & Sinus Medication': '|ALLERGY & SINUS MEDICATION--|',
  'Ankle Boots': '|ANKLE BOOTS--|',
  Antacids: '|ANTACIDS--|',
  'Baby Oils & Lotions': '|BABY OILS & LOTIONS--|',
  'Baby Proofing': '|BABY PROOFING--|',
  'bakeware and cookware': '|BAKEWARE AND COOKWARE--|',
  'Baking & Cookie Sheets': '|BAKING & COOKIE SHEETS--|',
  Basketballs: '|BASKETBALLS--|',
  'Bath Rugs & Mats': '|BATH RUGS & MATS--|',
  'Bath Tools & Accessories': '|BATH TOOLS & ACCESSORIES--|',
  Benadryl: '|BENADRYL--|',
  'Boneless Chicken': '|BONELESS CHICKEN--|',
  'Bubble Wrap': '|BUBBLE WRAP--|',
  'Cake Mix': '|CAKE MIX--|',
  'Cat Litterbox': '|CAT LITTERBOX--|',
  Chargers: '|CHARGERS--|',
  "Children's Allergy": "|CHILDREN'S ALLERGY--|",
  'Chino Pants': '|CHINO PANTS--|',
  'Christmas Ornaments': '|CHRISTMAS ORNAMENTS--|',
  'Cleaning Wipes': '|CLEANING WIPES--|',
  'Clothing > Womens > Underwear': '|CLOTHING-WOMENS-UNDERWEAR|',
  'Control Hidden Bugs': '|CONTROL HIDDEN BUGS--|',
  Crayons: '|CRAYONS--|',
  Curtains: '|CURTAINS--|',
  'Decorative Storage': '|DECORATIVE STORAGE--|',
  'Desserts & Sides': '|DESSERTS & SIDES--|',
  'Desserts Platter': '|DESSERTS PLATTER--|',
  Detergents: '|DETERGENTS--|',
  'Diapers & Wipes': '|DIAPERS & WIPES--|',
  'Dinner Legendary Steaks': '|DINNER LEGENDARY STEAKS--|',
  'Drawing and Coloring Tools': '|DRAWING AND COLORING TOOLS--|',
  'Equate Allergy Medicine': '|EQUATE ALLERGY MEDICINE--|',
  'Equate Hair Care': '|EQUATE HAIR CARE--|',
  'Essentials for Tax Professionals': '|ESSENTIALS FOR TAX PROFESSIONALS--|',
  'Expert Grill Grills': '|EXPERT GRILL GRILLS--|',
  'Extension Cords': '|EXTENSION CORDS--|',
  'Fajita Seasoning': '|FAJITA SEASONING--|',
  'False hairpieces': '|FALSE HAIRPIECES--|',
  'Fill Ups': '|FILL UPS--|',
  'Fishing Hooks': '|FISHING HOOKS--|',
  'Flavored & Enhanced Water': '|FLAVORED & ENHANCED WATER--|',
  'Flavored Coffee Creamer': '|FLAVORED COFFEE CREAMER--|',
  'Food > Breakfast': '|FOOD-BREAKFAST-|',
  'Frozen Cheese Pizza': '|FROZEN CHEESE PIZZA--|',
  Furniture: '|FURNITURE--|',
  'Games & Puzzles': '|GAMES & PUZZLES--|',
  'Girls Hair Accessories': '|GIRLS HAIR ACCESSORIES--|',
  Gloves: '|GLOVES--|',
  'Grocery > Bakery & Bread > Pizza & Focaccia':
    '|GROCERY-BAKERY & BREAD-PIZZA & FOCACCIA|',
  'Hair Products': '|HAIR PRODUCTS--|',
  'hair styling tools': '|HAIR STYLING TOOLS--|',
  'Hand Tools': '|HAND TOOLS--|',
  'Hard Candy Face Makeup': '|HARD CANDY FACE MAKEUP--|',
  'Health & Beauty > Feminine Products > Miscellaneous':
    '|HEALTH & BEAUTY-FEMININE PRODUCTS-MISCELLANEOUS|',
  'Health & Beauty > Hair Care > Permanent Products':
    '|HEALTH & BEAUTY-HAIR CARE-PERMANENT PRODUCTS|',
  'Home / Disposables / Tissues & Dispensers / Bath Tissue':
    '|HOME / DISPOSABLES / TISSUES & DISPENSERS / BATH TISSUE--|',
  'Household Essentials/Cleaning Supplies/Cleaning Tools':
    '|HOUSEHOLD ESSENTIALS/CLEANING SUPPLIES/CLEANING TOOLS--|',
  'Household Essentials/Paper & Plastic/Toilet Paper':
    '|HOUSEHOLD ESSENTIALS/PAPER & PLASTIC/TOILET PAPER--|',
  'Index Cards': '|INDEX CARDS--|',
  'iPhone Accessories': '|IPHONE ACCESSORIES--|',
  'Jerky & Bites': '|JERKY & BITES--|',
  'Kit Kat Duo': '|KIT KAT DUO--|',
  'Kitchen Rugs': '|KITCHEN RUGS--|',
  'Kitchen Towels & Dish Towels': '|KITCHEN TOWELS & DISH TOWELS--|',
  'Kool-Aid': '|KOOL-AID--|',
  Listerine: '|LISTERINE--|',
  'Lotions, Oils, & Powders': '|LOTIONS, OILS, & POWDERS--|',
  'Made By Design': '|MADE BY DESIGN--|',
  'Measuring Tools': '|MEASURING TOOLS--|',
  'Mens Activewear': '|MENS ACTIVEWEAR--|',
  'Mens Hats & Caps': '|MENS HATS & CAPS--|',
  'Mens Pajama Bottoms': '|MENS PAJAMA BOTTOMS--|',
  'Mens Workwear': '|MENS WORKWEAR--|',
  Mills: '|MILLS--|',
  'Natural Shampoo': '|NATURAL SHAMPOO--|',
  'Nintendo Switch': '|NINTENDO SWITCH--|',
  'Nintendo Switch Consoles': '|NINTENDO SWITCH CONSOLES--|',
  'Non Dairy Creamers': '|NON DAIRY CREAMERS--|',
  'Non-Chocolate': '|NON-CHOCOLATE--|',
  "Painter's Tape": "|PAINTER'S TAPE--|",
  'Panera Kids': '|PANERA KIDS--|',
  Pantry: '|PANTRY--|',
  "Parent's Choice Baby Wipes": "|PARENT'S CHOICE BABY WIPES--|",
  'Patching & Repair': '|PATCHING & REPAIR--|',
  'Patient Care': '|PATIENT CARE--|',
  'Peppers & Tomatoes': '|PEPPERS & TOMATOES--|',
  'Personal Fans': '|PERSONAL FANS--|',
  'Pipe Putty & Sealants': '|PIPE PUTTY & SEALANTS--|',
  'Play Vehicles by Brand': '|PLAY VEHICLES BY BRAND--|',
  'Pre Workout': '|PRE WORKOUT--|',
  'presentation boards and accessories':
    '|PRESENTATION BOARDS AND ACCESSORIES--|',
  'Printer Ink': '|PRINTER INK--|',
  Rings: '|RINGS--|',
  'Rubber Bands': '|RUBBER BANDS--|',
  'Salad Dressing & Croutons': '|SALAD DRESSING & CROUTONS--|',
  'Shaving Cream': '|SHAVING CREAM--|',
  'Sheet Sets': '|SHEET SETS--|',
  'Shop All Posters': '|SHOP ALL POSTERS--|',
  'Signature Wraps': '|SIGNATURE WRAPS--|',
  'Skillets & Frying Pans': '|SKILLETS & FRYING PANS--|',
  'Socks & Tights': '|SOCKS & TIGHTS--|',
  'Sour Cream & Dips': '|SOUR CREAM & DIPS--|',
  Starburst: '|STARBURST--|',
  'Super Glue': '|SUPER GLUE--|',
  'Target > Personal Care > Oral Care > Toothpaste':
    '|TARGET > PERSONAL CARE > ORAL CARE > TOOTHPASTE--|',
  'Adhesive Bandages': '|ADHESIVE BANDAGES--|',
  'Adventure Force Blasters': '|ADVENTURE FORCE BLASTERS--|',
  'Air Fryers': '|AIR FRYERS--|',
  'All Bathroom Fixtures': '|ALL BATHROOM FIXTURES--|',
  'All Children & Family Movies': '|ALL CHILDREN & FAMILY MOVIES--|',
  'All Cleaning Tools and Chemicals': '|ALL CLEANING TOOLS AND CHEMICALS--|',
  'All Coolers': '|ALL COOLERS--|',
  'All Exercise and Fitness Accessories':
    '|ALL EXERCISE AND FITNESS ACCESSORIES--|',
  'All Minnie Mouse': '|ALL MINNIE MOUSE--|',
  'All Planners': '|ALL PLANNERS--|',
  'All Seat Covers': '|ALL SEAT COVERS--|',
  'All Unicorn': '|ALL UNICORN--|',
  AND1: '|AND1--|',
  'Aqua Culture Aquarium Equipment': '|AQUA CULTURE AQUARIUM EQUIPMENT--|',
  'Arm & Hammer Pet Supplies': '|ARM & HAMMER PET SUPPLIES--|',
  'Baby Food Stage 2': '|BABY FOOD STAGE 2--|',
  'Baking Staples': '|BAKING STAPLES--|',
  'Barbie Careers': '|BARBIE CAREERS--|',
  Bedding: '|BEDDING--|',
  'Best Stain Remover': '|BEST STAIN REMOVER--|',
  Biscotti: '|BISCOTTI--|',
  'Black & White Party Decorations': '|BLACK & WHITE PARTY DECORATIONS--|',
  'Blush Stick': '|BLUSH STICK--|',
  'Buy It Again - Same Day Delivery': '|BUY IT AGAIN - SAME DAY DELIVERY--|',
  'Cabinet Knobs': '|CABINET KNOBS--|',
  'Canned Food': '|CANNED FOOD--|',
  cereals: '|CEREALS--|',
  'Christmas Gift Bags': '|CHRISTMAS GIFT BAGS--|',
  'Christmas Tree Skirts': '|CHRISTMAS TREE SKIRTS--|',
  'Clothing > Mens > Activewear': '|CLOTHING-MENS-ACTIVEWEAR|',
  'Clothing > Womens > Bottoms': '|CLOTHING-WOMENS-BOTTOMS|',
  'Colored Pencils': '|COLORED PENCILS--|',
  'Conair Hair Brushes': '|CONAIR HAIR BRUSHES--|',
  'Condoms & Contraceptives': '|CONDOMS & CONTRACEPTIVES--|',
  'Cookware, Bakeware & Tools': '|COOKWARE, BAKEWARE & TOOLS--|',
  'Corned Beef': '|CORNED BEEF--|',
  'Craft & Hobby Storage Bags & Cases':
    '|CRAFT & HOBBY STORAGE BAGS & CASES--|',
  'Cricut Cutting Tools': '|CRICUT CUTTING TOOLS--|',
  'Croft & Barrow': '|CROFT & BARROW--|',
  'Crunchy Cat Treats': '|CRUNCHY CAT TREATS--|',
  'Dairy Creamers': '|DAIRY CREAMERS--|',
  'Decorative Boxes and Baskets': '|DECORATIVE BOXES AND BASKETS--|',
  'Deep Conditioner': '|DEEP CONDITIONER--|',
  'Desktop Organizers': '|DESKTOP ORGANIZERS--|',
  'Dish Additives': '|DISH ADDITIVES--|',
  'Dog Crates, Carriers & Kennels': '|DOG CRATES, CARRIERS & KENNELS--|',
  'Door Hinges': '|DOOR HINGES--|',
  'Driving Bits': '|DRIVING BITS--|',
  'Electronics > Video Games & Consoles > Video Game Accessories':
    '|ELECTRONICS-VIDEO GAMES & CONSOLES-VIDEO GAME ACCESSORIES|',
  'Electronics for Kids': '|ELECTRONICS FOR KIDS--|',
  'Equate Laxatives': '|EQUATE LAXATIVES--|',
  'Equate Tampons': '|EQUATE TAMPONS--|',
  'Eye Cosmetics': '|EYE COSMETICS--|',
  'Family & Party Size Entrees': '|FAMILY & PARTY SIZE ENTREES--|',
  'Feature Items': '|FEATURE ITEMS--|',
  Folders: '|FOLDERS--|',
  'Foot Files': '|FOOT FILES--|',
  'Fragrance Oils for Oil Diffusers': '|FRAGRANCE OILS FOR OIL DIFFUSERS--|',
  'Fruit of the Loom': '|FRUIT OF THE LOOM--|',
  'Gain Liquid Fabric Softener': '|GAIN LIQUID FABRIC SOFTENER--|',
  'Ginger ale': '|GINGER ALE--|',
  'Goldfish Crackers': '|GOLDFISH CRACKERS--|',
  'Greases and Gear Oil': '|GREASES AND GEAR OIL--|',
  'Ground Beef Patties': '|GROUND BEEF PATTIES--|',
  'Hair, Skin, & Nails': '|HAIR, SKIN, & NAILS--|',
  Hanes: '|HANES--|',
  'Headsets & Accessories': '|HEADSETS & ACCESSORIES--|',
  'Health & Beauty > Bath & Shower > Soap - Specialty':
    '|HEALTH & BEAUTY-BATH & SHOWER-SOAP - SPECIALTY|',
  'Health/Medicine Cabinet/Pain Relievers':
    '|HEALTH/MEDICINE CABINET/PAIN RELIEVERS--|',
  'Hefty Trash Bags': '|HEFTY TRASH BAGS--|',
  'Hex Keys': '|HEX KEYS--|',
  'Holiday Gifts': '|HOLIDAY GIFTS--|',
  'Hot Cereal': '|HOT CEREAL--|',
  'Household Essentials/Cleaning Supplies/Surface Care & Protection':
    '|HOUSEHOLD ESSENTIALS/CLEANING SUPPLIES/SURFACE CARE & PROTECTION--|',
  'Igloo Coolers': '|IGLOO COOLERS--|',
  'Ink, Toner & Ribbons': '|INK, TONER & RIBBONS--|',
  'Kids Meal': '|KIDS MEAL--|',
  'Kids Scissors': '|KIDS SCISSORS--|',
  "Kids' Halloween Costumes": "|KIDS' HALLOWEEN COSTUMES--|",
  'KING JRâ„¢ Meals': '|KING JRÂ„¢ MEALS--|',
  'Kleenex Facial Tissues': '|KLEENEX FACIAL TISSUES--|',
  'Lunch Combos': '|LUNCH COMBOS--|',
  'Made in USA': '|MADE IN USA--|',
  "Men's Razor Blades": "|MEN'S RAZOR BLADES--|",
  "Men's Sunglasses": "|MEN'S SUNGLASSES--|",
  'Mens Cargo Pants': '|MENS CARGO PANTS--|',
  'Mens Pullover Sweatshirts & Hoodies':
    '|MENS PULLOVER SWEATSHIRTS & HOODIES--|',
  Needlecrafts: '|NEEDLECRAFTS--|',
  Nike: '|NIKE--|',
  'Non Alcoholic Beer': '|NON ALCOHOLIC BEER--|',
  'Nuby Sippy Cups': '|NUBY SIPPY CUPS--|',
  'Nuts & Seeds': '|NUTS & SEEDS--|',
  'Office Supplies': '|OFFICE SUPPLIES--|',
  'Oils, Masks, & Treatments': '|OILS, MASKS, & TREATMENTS--|',
  "Ol' Roy Wet Dog Food": "|OL' ROY WET DOG FOOD--|",
  'Oral Pain Relief': '|ORAL PAIN RELIEF--|',
  Other: '|OTHER--|',
  'Pancakes, Waffles, & French Toast': '|PANCAKES, WAFFLES, & FRENCH TOAST--|',
  Pencils: '|PENCILS--|',
  'Personal Care/Hair Care/Styling Products':
    '|PERSONAL CARE/HAIR CARE/STYLING PRODUCTS--|',
  'Personal Care/Oral Care/Manual Toothbrushes':
    '|PERSONAL CARE/ORAL CARE/MANUAL TOOTHBRUSHES--|',
  'Picture & Mirror Hanging': '|PICTURE & MIRROR HANGING--|',
  'Pitchers & Carafes': '|PITCHERS & CARAFES--|',
  'Poster Boards': '|POSTER BOARDS--|',
  'Pots & Pans': '|POTS & PANS--|',
  'Premium Lemonades': '|PREMIUM LEMONADES--|',
  'Prepared Vegetables': '|PREPARED VEGETABLES--|',
  'Pringles & Cheez-It': '|PRINGLES & CHEEZ-IT--|',
  'Protein & Electrolytes': '|PROTEIN & ELECTROLYTES--|',
  'Protein & Fitness': '|PROTEIN & FITNESS--|',
  'Quaker Chewy Granola Bar': '|QUAKER CHEWY GRANOLA BAR--|',
  'Ready to Eat Soup': '|READY TO EAT SOUP--|',
  'Safety Equipment and Gear': '|SAFETY EQUIPMENT AND GEAR--|',
  Sandals: '|SANDALS--|',
  Scrubs: '|SCRUBS--|',
  'Seasoning Blends': '|SEASONING BLENDS--|',
  'Sexual Health': '|SEXUAL HEALTH--|',
  'Shop All Candy': '|SHOP ALL CANDY--|',
  'Shop All Chewing Gum': '|SHOP ALL CHEWING GUM--|',
  'Shop All Christmas Trees by Color': '|SHOP ALL CHRISTMAS TREES BY COLOR--|',
  'Shop All Desks': '|SHOP ALL DESKS--|',
  'Shop All Mints': '|SHOP ALL MINTS--|',
  'Shower Heads': '|SHOWER HEADS--|',
  'Signature Sandwiches': '|SIGNATURE SANDWICHES--|',
  'Slow Cookers': '|SLOW COOKERS--|',
  'Sour Cream': '|SOUR CREAM--|',
  'Spa & Relaxation': '|SPA & RELAXATION--|',
  'Specialty Cookware': '|SPECIALTY COOKWARE--|',
  'Sponges & Scouring Pads': '|SPONGES & SCOURING PADS--|',
  Sticks: '|STICKS--|',
  'Storage Bins': '|STORAGE BINS--|',
  'Stuffed Crust Deep Deep Dish Combos':
    '|STUFFED CRUST DEEP DEEP DISH COMBOS--|',
  'Table Lamps': '|TABLE LAMPS--|',
  'Tape Measures': '|TAPE MEASURES--|',
  'Textured Hair': '|TEXTURED HAIR--|',
  'Throat Remedies': '|THROAT REMEDIES--|',
  'Toilet Paper & Wipes': '|TOILET PAPER & WIPES--|',
  'Toiletry Bags': '|TOILETRY BAGS--|',
  'Toiletry Organization': '|TOILETRY ORGANIZATION--|',
  'Tortilla and Corn Chips': '|TORTILLA AND CORN CHIPS--|',
  'Traditional Breakfast Favorites': '|TRADITIONAL BREAKFAST FAVORITES--|',
  'Twist Drill Bits': '|TWIST DRILL BITS--|',
  'Vibrant Life Dog Apparel': '|VIBRANT LIFE DOG APPAREL--|',
  'Vitamin B12 Supplements': '|VITAMIN B12 SUPPLEMENTS--|',
  'Walmart Exclusive Dog Treats': '|WALMART EXCLUSIVE DOG TREATS--|',
  "Werther's Originals": "|WERTHER'S ORIGINALS--|",
  'Wilton Treat Containers & Displays':
    '|WILTON TREAT CONTAINERS & DISPLAYS--|',
  'Wireless Mouse': '|WIRELESS MOUSE--|',
  "Women's Fragrance": "|WOMEN'S FRAGRANCE--|",
  'Womens Novelty Socks': '|WOMENS NOVELTY SOCKS--|',
  'Womens Plus Jeggings': '|WOMENS PLUS JEGGINGS--|',
  'Xbox One Games': '|XBOX ONE GAMES--|',
  'Tide Laundry Detergent': '|TIDE LAUNDRY DETERGENT--|',
  'Time and Tru': '|TIME AND TRU--|',
  Tissues: '|TISSUES--|',
  'Toys & Games': '|TOYS & GAMES--|',
  'Toys for Kids 8 to 11 Years': '|TOYS FOR KIDS 8 TO 11 YEARS--|',
  'Travel Accessories': '|TRAVEL ACCESSORIES--|',
  'TV Tray Tables': '|TV TRAY TABLES--|',
  'Vibrant Life Crates & Pet Beds': '|VIBRANT LIFE CRATES & PET BEDS--|',
  'Video Games': '|VIDEO GAMES--|',
  'Vinyl Records': '|VINYL RECORDS--|',
  'Walking Canes': '|WALKING CANES--|',
  'Wellness Deals': '|WELLNESS DEALS--|',
  Wilton: '|WILTON--|',
  'Winter Hair Hydration': '|WINTER HAIR HYDRATION--|',
  "Women's Socks": "|WOMEN'S SOCKS--|",
  'Womens Activewear Leggings, Pants & Capris':
    '|WOMENS ACTIVEWEAR LEGGINGS, PANTS & CAPRIS--|',
  'Womens Sweatshirts & Hoodies': '|WOMENS SWEATSHIRTS & HOODIES--|',
  'Workout Bottoms': '|WORKOUT BOTTOMS--|',
  ' Cereal - Cold': '| CEREAL - COLD--|',
  'Adhesives & Tape': '|ADHESIVES & TAPE--|',
  'air conditioners and fans': '|AIR CONDITIONERS AND FANS--|',
  'All Action & Adventure': '|ALL ACTION & ADVENTURE--|',
  'All Area Rugs': '|ALL AREA RUGS--|',
  'All Batteries': '|ALL BATTERIES--|',
  'All Car Decoration': '|ALL CAR DECORATION--|',
  'All Frozen': '|ALL FROZEN--|',
  'Aqua Culture Aquarium Gravel': '|AQUA CULTURE AQUARIUM GRAVEL--|',
  'Aquarium Decorations': '|AQUARIUM DECORATIONS--|',
  'Baby Shark Toys': '|BABY SHARK TOYS--|',
  Bagels: '|BAGELS--|',
  'Bakery Breakfast': '|BAKERY BREAKFAST--|',
  'Bathroom Storage': '|BATHROOM STORAGE--|',
  'Body Care': '|BODY CARE--|',
  Bookcases: '|BOOKCASES--|',
  'Bottled Water': '|BOTTLED WATER--|',
  'Brake & Engine Cleaners': '|BRAKE & ENGINE CLEANERS--|',
  'Burger & Fries': '|BURGER & FRIES--|',
  'Camping Knives & Tools': '|CAMPING KNIVES & TOOLS--|',
  'Canned & Packaged Goods': '|CANNED & PACKAGED GOODS--|',
  'Canned Goods': '|CANNED GOODS--|',
  'Car Bulbs': '|CAR BULBS--|',
  'Car Lifting Tools': '|CAR LIFTING TOOLS--|',
  'Carpet Cleaners': '|CARPET CLEANERS--|',
  'Case Sales Meat': '|CASE SALES MEAT--|',
  'Cell Phone Accessories': '|CELL PHONE ACCESSORIES--|',
  'Chicken Breasts': '|CHICKEN BREASTS--|',
  'Chocolate Boxes': '|CHOCOLATE BOXES--|',
  'Christmas Tissue Paper': '|CHRISTMAS TISSUE PAPER--|',
  'Classic Games': '|CLASSIC GAMES--|',
  'Clear Phone Cases': '|CLEAR PHONE CASES--|',
  'Clothing > Mens > Socks & Hosiery': '|CLOTHING-MENS-SOCKS & HOSIERY|',
  'Coffee Creamers & Flavorings': '|COFFEE CREAMERS & FLAVORINGS--|',
  'Comforter Sets': '|COMFORTER SETS--|',
  'Cookware & Bakeware': '|COOKWARE & BAKEWARE--|',
  'Craft Fabric': '|CRAFT FABRIC--|',
  'Craft Tape': '|CRAFT TAPE--|',
  'Cream & Creamers': '|CREAM & CREAMERS--|',
  'Cricut Vinyl': '|CRICUT VINYL--|',
  'Dandruff Conditioner': '|DANDRUFF CONDITIONER--|',
  'Discover Delicious': '|DISCOVER DELICIOUS--|',
  'Dish Detergent': '|DISH DETERGENT--|',
  'Dish Washing Liquid': '|DISH WASHING LIQUID--|',
  'Disinfecting Wipes': '|DISINFECTING WIPES--|',
  'Drinks Arctic Rush': '|DRINKS ARCTIC RUSH--|',
  'Dry Shampoo': '|DRY SHAMPOO--|',
  Dumbbells: '|DUMBBELLS--|',
  'Ear Care': '|EAR CARE--|',
  'Easter Baskets': '|EASTER BASKETS--|',
  'Easter Hanging Decorations': '|EASTER HANGING DECORATIONS--|',
  "Elmer's Art Supplies": "|ELMER'S ART SUPPLIES--|",
  Energy: '|ENERGY--|',
  Envelopes: '|ENVELOPES--|',
  'Equate Contact Lens Solution': '|EQUATE CONTACT LENS SOLUTION--|',
  'Equate Foot Care': '|EQUATE FOOT CARE--|',
  'Espresso Drinks': '|ESPRESSO DRINKS--|',
  Exclusives: '|EXCLUSIVES--|',
  'Eyes & Brows': '|EYES & BROWS--|',
  'Fashion Bath Rugs': '|FASHION BATH RUGS--|',
  'Felt & Applique': '|FELT & APPLIQUE--|',
  'Flat Washers': '|FLAT WASHERS--|',
  'Flavored Milks': '|FLAVORED MILKS--|',
  Floral: '|FLORAL--|',
  'Food/Baking/Flours & Meals': '|FOOD/BAKING/FLOURS & MEALS--|',
  'Food/Baking/Frosting, Toppings & Decorations':
    '|FOOD/BAKING/FROSTING, TOPPINGS & DECORATIONS--|',
  'Food/Beverages/Water/Sparkling & Seltzer Water':
    '|FOOD/BEVERAGES/WATER/SPARKLING & SELTZER WATER--|',
  'Food/Coffee/Coffee Creamers/All Creamers':
    '|FOOD/COFFEE/COFFEE CREAMERS/ALL CREAMERS--|',
  Footballs: '|FOOTBALLS--|',
  Formula: '|FORMULA--|',
  'Fresh Fish': '|FRESH FISH--|',
  'Fruit of the Loom Mens Sweatshirts':
    '|FRUIT OF THE LOOM MENS SWEATSHIRTS--|',
  'Fruity Breakfast': '|FRUITY BREAKFAST--|',
  'Funnels & Funnel Sets': '|FUNNELS & FUNNEL SETS--|',
  'Garden Chemicals & Treatments': '|GARDEN CHEMICALS & TREATMENTS--|',
  'Gloves & Mittens': '|GLOVES & MITTENS--|',
  'Golden Rewards Dog Treats': '|GOLDEN REWARDS DOG TREATS--|',
  'Grocery > Breakfast > Drinks & Instant':
    '|GROCERY-BREAKFAST-DRINKS & INSTANT|',
  Hairitage: '|HAIRITAGE--|',
  'Hallmark Wedding Gift Wrap': '|HALLMARK WEDDING GIFT WRAP--|',
  'Hand Dish Soap': '|HAND DISH SOAP--|',
  'Hand Lotions & Creams': '|HAND LOTIONS & CREAMS--|',
  'Hand Saws': '|HAND SAWS--|',
  'Hard Candy Eye Makeup': '|HARD CANDY EYE MAKEUP--|',
  Headlamps: '|HEADLAMPS--|',
  'Horizontal Blinds': '|HORIZONTAL BLINDS--|',
  'House Letters & Numbers': '|HOUSE LETTERS & NUMBERS--|',
  'Household Essentials/Drain & Septic Care':
    '|HOUSEHOLD ESSENTIALS/DRAIN & SEPTIC CARE--|',
  'Huggies Baby Wipes': '|HUGGIES BABY WIPES--|',
  'Insect and Animal Control': '|INSECT AND ANIMAL CONTROL--|',
  Iron: '|IRON--|',
  'Itching & Rash Treatments': '|ITCHING & RASH TREATMENTS--|',
  'Jogger & Lounge Pants': '|JOGGER & LOUNGE PANTS--|',
  'Juniors Casual Pants': '|JUNIORS CASUAL PANTS--|',
  'Juniors Skinny Jeans': '|JUNIORS SKINNY JEANS--|',
  'Kid Art Easels & Stations': '|KID ART EASELS & STATIONS--|',
  'Kids Tableware': '|KIDS TABLEWARE--|',
  "Kids' Pillows": "|KIDS' PILLOWS--|",
  'LED Strip Lights': '|LED STRIP LIGHTS--|',
  'light bulbs, switches and light accessories':
    '|LIGHT BULBS, SWITCHES AND LIGHT ACCESSORIES--|',
  'Limited Time Offers': '|LIMITED TIME OFFERS--|',
  "Men's Tops": "|MEN'S TOPS--|",
  'Mens Coats & Jackets': '|MENS COATS & JACKETS--|',
  'Mens Graphic Tees': '|MENS GRAPHIC TEES--|',
  'Mens Loungewear': '|MENS LOUNGEWEAR--|',
  'Meow Mix Dry Cat Food': '|MEOW MIX DRY CAT FOOD--|',
  'Metallic Gift Wrap Supplies': '|METALLIC GIFT WRAP SUPPLIES--|',
  'Micro SD Cards': '|MICRO SD CARDS--|',
  'Mixing Palettes & Trays': '|MIXING PALETTES & TRAYS--|',
  'Monster Trucks': '|MONSTER TRUCKS--|',
  'More Berries': '|MORE BERRIES--|',
  'Multicultural Hair Care': '|MULTICULTURAL HAIR CARE--|',
  'New Years Balloons': '|NEW YEARS BALLOONS--|',
  'Numbers and Letters': '|NUMBERS AND LETTERS--|',
  "Ol' Roy Dog Food": "|OL' ROY DOG FOOD--|",
  'Oral Accessories': '|ORAL ACCESSORIES--|',
  'Other Laundry Care': '|OTHER LAUNDRY CARE--|',
  'Over-the-Counter Medicines': '|OVER-THE-COUNTER MEDICINES--|',
  'OxiClean Stain Remover': '|OXICLEAN STAIN REMOVER--|',
  'Paint Buckets & Lids': '|PAINT BUCKETS & LIDS--|',
  'Paint Scrapers & Blades': '|PAINT SCRAPERS & BLADES--|',
  Peanuts: '|PEANUTS--|',
  'Pencil Boxes': '|PENCIL BOXES--|',
  'Pencil Sharpeners': '|PENCIL SHARPENERS--|',
  'Performance & Diet Bars': '|PERFORMANCE & DIET BARS--|',
  Pesticides: '|PESTICIDES--|',
  'Pet Toys and Exercise': '|PET TOYS AND EXERCISE--|',
  Pets: '|PETS--|',
  'Pets/Dogs/Dog Treats': '|PETS/DOGS/DOG TREATS--|',
  Placemats: '|PLACEMATS--|',
  Platters: '|PLATTERS--|',
  'Plumbing Tools': '|PLUMBING TOOLS--|',
  'Potting Soil': '|POTTING SOIL--|',
  'Premium Signature Hot Panini': '|PREMIUM SIGNATURE HOT PANINI--|',
  'Quaker Oats': '|QUAKER OATS--|',
  'Ready to Drink & Eat': '|READY TO DRINK & EAT--|',
  'Roasting Pans': '|ROASTING PANS--|',
  Roku: '|ROKU--|',
  'Salsa and Dips': '|SALSA AND DIPS--|',
  'Same Day Delivery Items': '|SAME DAY DELIVERY ITEMS--|',
  'Sauces and Dressings': '|SAUCES AND DRESSINGS--|',
  Sausages: '|SAUSAGES--|',
  'Scrub Brushes': '|SCRUB BRUSHES--|',
  'Seasonal Candles & Accessories': '|SEASONAL CANDLES & ACCESSORIES--|',
  serveware: '|SERVEWARE--|',
  'Setting Spray': '|SETTING SPRAY--|',
  'Sewing Scissors & Shears': '|SEWING SCISSORS & SHEARS--|',
  Sharpie: '|SHARPIE--|',
  'Shaving Creams & Gels': '|SHAVING CREAMS & GELS--|',
  "Shop the Johnson's Collection": "|SHOP THE JOHNSON'S COLLECTION--|",
  'Single Subject Notebooks': '|SINGLE SUBJECT NOTEBOOKS--|',
  'Sleeping Aids and Travel Sickness': '|SLEEPING AIDS AND TRAVEL SICKNESS--|',
  'Snack Boxes & Gifts': '|SNACK BOXES & GIFTS--|',
  'Special Kitty Cat Supplies': '|SPECIAL KITTY CAT SUPPLIES--|',
  'Spray Foam Insulation': '|SPRAY FOAM INSULATION--|',
  'Spring Valley Vitamin D': '|SPRING VALLEY VITAMIN D--|',
  'Starbucks Coffee Pods': '|STARBUCKS COFFEE PODS--|',
  'Stencils & Templates': '|STENCILS & TEMPLATES--|',
  Studs: '|STUDS--|',
  Swimsuits: '|SWIMSUITS--|',
  'Target > Beauty > Makeup > Face > Concealer':
    '|TARGET > BEAUTY > MAKEUP > FACE > CONCEALER--|',
  "Target > Shoes > Women's Shoes > Sandals":
    "|TARGET > SHOES > WOMEN'S SHOES > SANDALS--|",
  'AAA Batteries': '|AAA BATTERIES--|',
  'Action & Adventure': '|ACTION & ADVENTURE--|',
  activewear: '|ACTIVEWEAR--|',
  adidas: '|ADIDAS--|',
  'Air Beds': '|AIR BEDS--|',
  'Air Purifiers': '|AIR PURIFIERS--|',
  'All Comedy': '|ALL COMEDY--|',
  'All Disney Princess Toys': '|ALL DISNEY PRINCESS TOYS--|',
  'All Folders and Filing': '|ALL FOLDERS AND FILING--|',
  'All Markers': '|ALL MARKERS--|',
  'All Paint': '|ALL PAINT--|',
  'All Pancake & Waffle Mixes': '|ALL PANCAKE & WAFFLE MIXES--|',
  'All Prepaid Phones': '|ALL PREPAID PHONES--|',
  'All Textured Hair': '|ALL TEXTURED HAIR--|',
  'American Cheese': '|AMERICAN CHEESE--|',
  Antibiotics: '|ANTIBIOTICS--|',
  'Apple iPhone on Straight Talk': '|APPLE IPHONE ON STRAIGHT TALK--|',
  'Ardell False Eyelashes': '|ARDELL FALSE EYELASHES--|',
  'Art Pencils': '|ART PENCILS--|',
  'Artichokes & Asparagus': '|ARTICHOKES & ASPARAGUS--|',
  'Artificial Flowers': '|ARTIFICIAL FLOWERS--|',
  'Baby Health': '|BABY HEALTH--|',
  Backpacks: '|BACKPACKS--|',
  'Bacon & Turkey Bacon': '|BACON & TURKEY BACON--|',
  'Bakery Fresh': '|BAKERY FRESH--|',
  'Bandages & Gauze': '|BANDAGES & GAUZE--|',
  'Basic Phone Cases': '|BASIC PHONE CASES--|',
  'Beauty/Makeup/Eyes': '|BEAUTY/MAKEUP/EYES--|',
  'Better Homes & Gardens Decor': '|BETTER HOMES & GARDENS DECOR--|',
  'Big Boys Graphic Tees And T-Shirts':
    '|BIG BOYS GRAPHIC TEES AND T-SHIRTS--|',
  'Big Girls Underwear': '|BIG GIRLS UNDERWEAR--|',
  'Birthday cards for anyone': '|BIRTHDAY CARDS FOR ANYONE--|',
  'Bleach & Stain Removers': '|BLEACH & STAIN REMOVERS--|',
  'Blue Sky': '|BLUE SKY--|',
  'Body Cleansing': '|BODY CLEANSING--|',
  Bodycology: '|BODYCOLOGY--|',
  'Bone & Joint Supplements': '|BONE & JOINT SUPPLEMENTS--|',
  'Bottled Tea & Coffee': '|BOTTLED TEA & COFFEE--|',
  'Boxed Meals & Side Dishes': '|BOXED MEALS & SIDE DISHES--|',
  'Boxer Briefs & Shorts': '|BOXER BRIEFS & SHORTS--|',
  'Brake Fluids': '|BRAKE FLUIDS--|',
  'Buck & Change': '|BUCK & CHANGE--|',
  'Burgers & Patties': '|BURGERS & PATTIES--|',
  'Butter Sticks': '|BUTTER STICKS--|',
  'Cakes and Cupcakes': '|CAKES AND CUPCAKES--|',
  'California Compliant Bulbs': '|CALIFORNIA COMPLIANT BULBS--|',
  'Candles & Candleholders': '|CANDLES & CANDLEHOLDERS--|',
  'Canned Cat Food': '|CANNED CAT FOOD--|',
  Canvas: '|CANVAS--|',
  'Canvas and Art Boards': '|CANVAS AND ART BOARDS--|',
  'Car Cleaning': '|CAR CLEANING--|',
  'Car Fluids & Chemicals': '|CAR FLUIDS & CHEMICALS--|',
  'Car Sun Shades': '|CAR SUN SHADES--|',
  'Carrots & Root Vegetables': '|CARROTS & ROOT VEGETABLES--|',
  'Casual Button-Down Shirts': '|CASUAL BUTTON-DOWN SHIRTS--|',
  'Cat Feeding Supplies': '|CAT FEEDING SUPPLIES--|',
  'Character Party Kits': '|CHARACTER PARTY KITS--|',
  'Charbroiled Sliders': '|CHARBROILED SLIDERS--|',
  'Chicken Seasoning': '|CHICKEN SEASONING--|',
  "Children's Hair Care": "|CHILDREN'S HAIR CARE--|",
  'Chips & Dip': '|CHIPS & DIP--|',
  'Cleaning & Organization': '|CLEANING & ORGANIZATION--|',
  'Clearance on Health Beauty Wellness & Personal Care':
    '|CLEARANCE ON HEALTH BEAUTY WELLNESS & PERSONAL CARE--|',
  'Climbing & Mountaineering Equipment':
    '|CLIMBING & MOUNTAINEERING EQUIPMENT--|',
  Clocks: '|CLOCKS--|',
  'Clothing Care': '|CLOTHING CARE--|',
  'Clumping Cat Litter': '|CLUMPING CAT LITTER--|',
  'Clumping Litter': '|CLUMPING LITTER--|',
  'Coat Hooks': '|COAT HOOKS--|',
  'Cocktail Prep': '|COCKTAIL PREP--|',
  'Commercial Toilet Paper': '|COMMERCIAL TOILET PAPER--|',
  'Computer Cleaners': '|COMPUTER CLEANERS--|',
  'Condiments & Salad Dressings': '|CONDIMENTS & SALAD DRESSINGS--|',
  'Cooking Utensils': '|COOKING UTENSILS--|',
  Corn: '|CORN--|',
  'Cough Cold & Flu': '|COUGH COLD & FLU--|',
  'Cough, Cold, & Flu': '|COUGH, COLD, & FLU--|',
  'Craft Foam': '|CRAFT FOAM--|',
  Cups: '|CUPS--|',
  'Cups,Plates,Wares': '|CUPS,PLATES,WARES--|',
  'Decorating Tools': '|DECORATING TOOLS--|',
  'Desk Organization': '|DESK ORGANIZATION--|',
  'DG Home': '|DG HOME--|',
  'Disinfectant Wipes & Sprays': '|DISINFECTANT WIPES & SPRAYS--|',
  Disney: '|DISNEY--|',
  'Dorm Decor': '|DORM DECOR--|',
  'Downy Dryer Sheets': '|DOWNY DRYER SHEETS--|',
  Drains: '|DRAINS--|',
  'Dried Fruit & Raisins': '|DRIED FRUIT & RAISINS--|',
  'DVD Movies': '|DVD MOVIES--|',
  'Earth Therapeutics': '|EARTH THERAPEUTICS--|',
  Elastic: '|ELASTIC--|',
  'Electric Griddles': '|ELECTRIC GRIDDLES--|',
  'Equate Eye Care Products': '|EQUATE EYE CARE PRODUCTS--|',
  'Equate First Aid Kits': '|EQUATE FIRST AID KITS--|',
  'Equate Nausea & Motion Sickness Relief':
    '|EQUATE NAUSEA & MOTION SICKNESS RELIEF--|',
  'Essential Oils': '|ESSENTIAL OILS--|',
  'Exterior Shutters': '|EXTERIOR SHUTTERS--|',
  'Fabric by the Bolt': '|FABRIC BY THE BOLT--|',
  'Face Masks and Bandanas': '|FACE MASKS AND BANDANAS--|',
  'Family Boxes': '|FAMILY BOXES--|',
  'Febreze Plug In Refills': '|FEBREZE PLUG IN REFILLS--|',
  Files: '|FILES--|',
  'Fishing Soft Baits': '|FISHING SOFT BAITS--|',
  'Fishing Tackle Boxes': '|FISHING TACKLE BOXES--|',
  'Flatware Sets': '|FLATWARE SETS--|',
  'Flowers & Plants': '|FLOWERS & PLANTS--|',
  'Food > Breakfast > French Toast': '|FOOD-BREAKFAST-FRENCH TOAST|',
  'Food > Seafood Entrees > Other Seafood Entrees':
    '|FOOD-SEAFOOD ENTREES-OTHER SEAFOOD ENTREES|',
  'Food Storage Bags': '|FOOD STORAGE BAGS--|',
  'Food/Beverages/Beer, Wine & Spirits/Spirits':
    '|FOOD/BEVERAGES/BEER, WINE & SPIRITS/SPIRITS--|',
  'Food/Condiments, Sauces & Spices/Condiments':
    '|FOOD/CONDIMENTS, SAUCES & SPICES/CONDIMENTS--|',
  'Food/Fresh Food/Bakery & Bread/Breakfast Bakery & Pastries':
    '|FOOD/FRESH FOOD/BAKERY & BREAD/BREAKFAST BAKERY & PASTRIES--|',
  'Food/Fresh Food/Produce/Fresh Fruit':
    '|FOOD/FRESH FOOD/PRODUCE/FRESH FRUIT--|',
  'Food/Gluten-Free Foods/Gluten-Free Foods':
    '|FOOD/GLUTEN-FREE FOODS/GLUTEN-FREE FOODS--|',
  'Food/Meal Solutions, Grains & Pasta/Canned Goods/Canned Fruit':
    '|FOOD/MEAL SOLUTIONS, GRAINS & PASTA/CANNED GOODS/CANNED FRUIT--|',
  'Fragrance Gift Sets': '|FRAGRANCE GIFT SETS--|',
  'Friskies Cat Food': '|FRISKIES CAT FOOD--|',
  'frosting, icing and decorations': '|FROSTING, ICING AND DECORATIONS--|',
  'FT LONG Breakfast Sandwiches ': '|FT LONG BREAKFAST SANDWICHES --|',
  'Gain Scent Boosters': '|GAIN SCENT BOOSTERS--|',
  'Garden Hoses': '|GARDEN HOSES--|',
  Gatorade: '|GATORADE--|',
  'General Purpose Cords': '|GENERAL PURPOSE CORDS--|',
  Gerber: '|GERBER--|',
  'Glade Sprays': '|GLADE SPRAYS--|',
  'Greenies Dental Cat Treats': '|GREENIES DENTAL CAT TREATS--|',
  'Gyms & Playmats': '|GYMS & PLAYMATS--|',
  'Hand Towels': '|HAND TOWELS--|',
  'Hard Seltzers': '|HARD SELTZERS--|',
  Hay: '|HAY--|',
  'Headphones & Earbuds': '|HEADPHONES & EARBUDS--|',
  'Health/Medicine Cabinet/Allergy': '|HEALTH/MEDICINE CABINET/ALLERGY--|',
  'Home Improvement': '|HOME IMPROVEMENT--|',
  'Incandescent Light Bulbs': '|INCANDESCENT LIGHT BULBS--|',
  'Indoor Security Cameras': '|INDOOR SECURITY CAMERAS--|',
  'Instant Coffee': '|INSTANT COFFEE--|',
  'Juniors Graphic Tees': '|JUNIORS GRAPHIC TEES--|',
  "Kid's Equate Vitamins": "|KID'S EQUATE VITAMINS--|",
  "Kid's Menu": "|KID'S MENU--|",
  'Kids Clothing Character Shop All': '|KIDS CLOTHING CHARACTER SHOP ALL--|',
  'Kids Oral Care': '|KIDS ORAL CARE--|',
  "Kids' Books New Releases": "|KIDS' BOOKS NEW RELEASES--|",
  'Kitchen & Dining': '|KITCHEN & DINING--|',
  'Kitchen & Table Linens': '|KITCHEN & TABLE LINENS--|',
  'Kitchen Curtains': '|KITCHEN CURTAINS--|',
  'Kitchen Spatulas': '|KITCHEN SPATULAS--|',
  'Lamp Shades': '|LAMP SHADES--|',
  'Leave-in Conditioner': '|LEAVE-IN CONDITIONER--|',
  'Lens Cleaners': '|LENS CLEANERS--|',
  'Liquid Detergent': '|LIQUID DETERGENT--|',
  Litter: '|LITTER--|',
  Loaves: '|LOAVES--|',
  'Long Sleeve Button-Down Shirts': '|LONG SLEEVE BUTTON-DOWN SHIRTS--|',
  'Lunch Bags': '|LUNCH BAGS--|',
  'Luvs Diapers': '|LUVS DIAPERS--|',
  'Makeup Sets': '|MAKEUP SETS--|',
  'Market Fresh Salads': '|MARKET FRESH SALADS--|',
  'Matches & Lighters': '|MATCHES & LIGHTERS--|',
  'Matches and fire lighting tools': '|MATCHES AND FIRE LIGHTING TOOLS--|',
  'Maternity Tops': '|MATERNITY TOPS--|',
  'Maybelline Face Makeup': '|MAYBELLINE FACE MAKEUP--|',
  'Memory Cards and Thumb Drives': '|MEMORY CARDS AND THUMB DRIVES--|',
  'Mens Polos': '|MENS POLOS--|',
  'Menstrual Pain': '|MENSTRUAL PAIN--|',
  'Michelin Wiper Blades': '|MICHELIN WIPER BLADES--|',
  'Muffin Mixes': '|MUFFIN MIXES--|',
  Muffins: '|MUFFINS--|',
  'Nasal Sprays': '|NASAL SPRAYS--|',
  'Natural Cleaning': '|NATURAL CLEANING--|',
  'New Baby Products': '|NEW BABY PRODUCTS--|',
  'New Cereal': '|NEW CEREAL--|',
  'New Movie Releases': '|NEW MOVIE RELEASES--|',
  'Other Stomach Remedies': '|OTHER STOMACH REMEDIES--|',
  'Ozark Trail Canopies': '|OZARK TRAIL CANOPIES--|',
  'Ozark Trail Coolers': '|OZARK TRAIL COOLERS--|',
  'Packaging and Shipping Tape': '|PACKAGING AND SHIPPING TAPE--|',
  Paint: '|PAINT--|',
  'Paint Roller Frames': '|PAINT ROLLER FRAMES--|',
  'Paint Thinner, Solvents & Cleaners':
    '|PAINT THINNER, SOLVENTS & CLEANERS--|',
  'Paper & Plastics': '|PAPER & PLASTICS--|',
  'Pens & Pencils': '|PENS & PENCILS--|',
  'Permanent Markers': '|PERMANENT MARKERS--|',
  'Personal Care/Bath & Body/Loofahs & Accessories':
    '|PERSONAL CARE/BATH & BODY/LOOFAHS & ACCESSORIES--|',
  'Pet All Star Training Pads': '|PET ALL STAR TRAINING PADS--|',
  Pliers: '|PLIERS--|',
  'Plug In Air Fresheners Scented Oils':
    '|PLUG IN AIR FRESHENERS SCENTED OILS--|',
  Popsicles: '|POPSICLES--|',
  'Power Steering Fluid': '|POWER STEERING FLUID--|',
  'Prenatal Supplements': '|PRENATAL SUPPLEMENTS--|',
  Primer: '|PRIMER--|',
  'Pudding & Gelatin Mixes': '|PUDDING & GELATIN MIXES--|',
  Puzzles: '|PUZZLES--|',
  'Return to School - Snacks': '|RETURN TO SCHOOL - SNACKS--|',
  'Ribbons & Bows': '|RIBBONS & BOWS--|',
  'Rotary Tools': '|ROTARY TOOLS--|',
  Rubbermaid: '|RUBBERMAID--|',
  'Sandwich Cookies': '|SANDWICH COOKIES--|',
  Sangria: '|SANGRIA--|',
  Sausage: '|SAUSAGE--|',
  'Scrapbooking Embellishments': '|SCRAPBOOKING EMBELLISHMENTS--|',
  'Serums & Treatments': '|SERUMS & TREATMENTS--|',
  'Sewing Kits': '|SEWING KITS--|',
  'Shakes and Malts': '|SHAKES AND MALTS--|',
  Shaving: '|SHAVING--|',
  'Shop All Garden Hoses': '|SHOP ALL GARDEN HOSES--|',
  'Shop All Grill Fuels by Type': '|SHOP ALL GRILL FUELS BY TYPE--|',
  'Shop All Grill Tools & Accessories':
    '|SHOP ALL GRILL TOOLS & ACCESSORIES--|',
  'Shop All Novelty Lights': '|SHOP ALL NOVELTY LIGHTS--|',
  'Shop all Super Mario': '|SHOP ALL SUPER MARIO--|',
  'Shop All The Pioneer Woman': '|SHOP ALL THE PIONEER WOMAN--|',
  'Shop All Water Bottles': '|SHOP ALL WATER BOTTLES--|',
  'Signature Salads': '|SIGNATURE SALADS--|',
  'Sink Strainers': '|SINK STRAINERS--|',
  'Skin Concern': '|SKIN CONCERN--|',
  'Sleep Health': '|SLEEP HEALTH--|',
  'Slip-Resistant Work Shoes': '|SLIP-RESISTANT WORK SHOES--|',
  'Slips & Shapewear': '|SLIPS & SHAPEWEAR--|',
  'Soft Blankets': '|SOFT BLANKETS--|',
  'Solid & Liquid': '|SOLID & LIQUID--|',
  'Soup & Broth': '|SOUP & BROTH--|',
  'Sour Patch Kids': '|SOUR PATCH KIDS--|',
  'Space Heaters': '|SPACE HEATERS--|',
  'Sponges & Applicators': '|SPONGES & APPLICATORS--|',
  'Spring & Purified': '|SPRING & PURIFIED--|',
  'Spring Water': '|SPRING WATER--|',
  'Storage Tubs & Totes': '|STORAGE TUBS & TOTES--|',
  'Streaming Devices': '|STREAMING DEVICES--|',
  'String and Decorative Lights': '|STRING AND DECORATIVE LIGHTS--|',
  'Studio Lighting': '|STUDIO LIGHTING--|',
  Sunbeam: '|SUNBEAM--|',
  'Sunflower & Pumpkin Seeds': '|SUNFLOWER & PUMPKIN SEEDS--|',
  'Table Top & Multi-Game Tables': '|TABLE TOP & MULTI-GAME TABLES--|',
  'Target > Beauty > Hair Care > Hair Products':
    '|TARGET > BEAUTY > HAIR CARE > HAIR PRODUCTS--|',
  'Target > Beauty > Makeup > Lips > Lipstick & Lipstains':
    '|TARGET > BEAUTY > MAKEUP > LIPS > LIPSTICK & LIPSTAINS--|',
  'Target > Electronics > Headphones': '|TARGET > ELECTRONICS > HEADPHONES--|',
  'Tasty Tools & Gadgets': '|TASTY TOOLS & GADGETS--|',
  "Teens' Laundry Organization": "|TEENS' LAUNDRY ORGANIZATION--|",
  'Teeth Whitening': '|TEETH WHITENING--|',
  'Tide Pods Laundry Detergent': '|TIDE PODS LAUNDRY DETERGENT--|',
  'Time and Tru Leggings': '|TIME AND TRU LEGGINGS--|',
  'Toddler Foods': '|TODDLER FOODS--|',
  'Toddler Girls New Clothing Arrivals':
    '|TODDLER GIRLS NEW CLOTHING ARRIVALS--|',
  'Toddler Girls T-Shirts & Tank Tops':
    '|TODDLER GIRLS T-SHIRTS & TANK TOPS--|',
  'Tops & Tees': '|TOPS & TEES--|',
  Toy: '|TOY--|',
  Trees: '|TREES--|',
  'Tropical & Berry': '|TROPICAL & BERRY--|',
  Tweezers: '|TWEEZERS--|',
  'Underwear & Socks': '|UNDERWEAR & SOCKS--|',
  Unflavored: '|UNFLAVORED--|',
  'USB Flash Drives': '|USB FLASH DRIVES--|',
  'Utility Knives': '|UTILITY KNIVES--|',
  'Value Combos': '|VALUE COMBOS--|',
  Vitamins: '|VITAMINS--|',
  'Wall Plates': '|WALL PLATES--|',
  'Wallets & Keychains': '|WALLETS & KEYCHAINS--|',
  Wallpaper: '|WALLPAPER--|',
  'Weight Training Accessories': '|WEIGHT TRAINING ACCESSORIES--|',
  "Welch's Fruit Snacks": "|WELCH'S FRUIT SNACKS--|",
  'Wireless Keyboards': '|WIRELESS KEYBOARDS--|',
  "Women's Fragrances": "|WOMEN'S FRAGRANCES--|",
  'Womens Backpacks': '|WOMENS BACKPACKS--|',
  'Womens Crossbody Bags': '|WOMENS CROSSBODY BAGS--|',
  'Womens Shapewear': '|WOMENS SHAPEWEAR--|',
  'Womens Skinny Jeans': '|WOMENS SKINNY JEANS--|',
  'Wood Burning & Engraving Supplies': '|WOOD BURNING & ENGRAVING SUPPLIES--|',
  'Wreaths & Garland': '|WREATHS & GARLAND--|',
  'Yogurt Drinks & Kefir': '|YOGURT DRINKS & KEFIR--|',
  'You Pick Two': '|YOU PICK TWO--|',
  'Temporary Hair Color': '|TEMPORARY HAIR COLOR--|',
  Tile: '|TILE--|',
  Toasters: '|TOASTERS--|',
  'Toddler Girls Jeans': '|TODDLER GIRLS JEANS--|',
  "Toddlers' Pillows": "|TODDLERS' PILLOWS--|",
  'Toilet Paper Holders': '|TOILET PAPER HOLDERS--|',
  'Toilet Training': '|TOILET TRAINING--|',
  'Tool Boxes': '|TOOL BOXES--|',
  'Towel Bars': '|TOWEL BARS--|',
  'Tree Hut Body Wash & Body Scrub': '|TREE HUT BODY WASH & BODY SCRUB--|',
  'TVs and home theater systems': '|TVS AND HOME THEATER SYSTEMS--|',
  'U-Brands': '|U-BRANDS--|',
  'Unscented Candles': '|UNSCENTED CANDLES--|',
  'Value Starting at $1': '|VALUE STARTING AT $1--|',
  'Vibrant Life Pet Beds': '|VIBRANT LIFE PET BEDS--|',
  'Vinyl Flooring': '|VINYL FLOORING--|',
  'Wall Mirrors': '|WALL MIRRORS--|',
  'Wash-off Face Masks': '|WASH-OFF FACE MASKS--|',
  'Weed & Fungus Control': '|WEED & FUNGUS CONTROL--|',
  Wet: '|WET--|',
  'Whole Milk': '|WHOLE MILK--|',
  'Wilton Bakeware': '|WILTON BAKEWARE--|',
  'Wilton Cake Decorating': '|WILTON CAKE DECORATING--|',
  'Womens Bootcut Jeans': '|WOMENS BOOTCUT JEANS--|',
  'Womens New Lingerie & Shapewear': '|WOMENS NEW LINGERIE & SHAPEWEAR--|',
  'Womens Swimsuit Bottoms': '|WOMENS SWIMSUIT BOTTOMS--|',
  " Sam'AUs Club": "| SAM'AUS CLUB--|",
  '2% Milk': '|2% MILK--|',
  '3M': '|3M--|',
  'Acrylic Paint': '|ACRYLIC PAINT--|',
  'All Artificial Plants and Flowers': '|ALL ARTIFICIAL PLANTS AND FLOWERS--|',
  'All Binders': '|ALL BINDERS--|',
  'All Facial Tissues': '|ALL FACIAL TISSUES--|',
  'All Fresh Packaged Salads, Dressings and Dips':
    '|ALL FRESH PACKAGED SALADS, DRESSINGS AND DIPS--|',
  'All Hair Styling Products': '|ALL HAIR STYLING PRODUCTS--|',
  'All Home Security': '|ALL HOME SECURITY--|',
  'All Hooks': '|ALL HOOKS--|',
  'All onn. TV & Video Accessories': '|ALL ONN. TV & VIDEO ACCESSORIES--|',
  'All Shower Heads': '|ALL SHOWER HEADS--|',
  'All Snow Removal': '|ALL SNOW REMOVAL--|',
  'All Tea': '|ALL TEA--|',
  'All Toys by Price': '|ALL TOYS BY PRICE--|',
  'All Trolls': '|ALL TROLLS--|',
  'All Wall Decals': '|ALL WALL DECALS--|',
  'All Water Filtration': '|ALL WATER FILTRATION--|',
  'Animal & Rodent Control': '|ANIMAL & RODENT CONTROL--|',
  'Applesauce pouches': '|APPLESAUCE POUCHES--|',
  'Aqua Culture Aquarium Decorations': '|AQUA CULTURE AQUARIUM DECORATIONS--|',
  'Aquarium Cleaning': '|AQUARIUM CLEANING--|',
  'Arabica Coffee': '|ARABICA COFFEE--|',
  'Artificial Nails & Nail Art': '|ARTIFICIAL NAILS & NAIL ART--|',
  'Asian Foods': '|ASIAN FOODS--|',
  'Athletic Performance Shapewear': '|ATHLETIC PERFORMANCE SHAPEWEAR--|',
  Auden: '|AUDEN--|',
  'Audio Visual Accessories': '|AUDIO VISUAL ACCESSORIES--|',
  'Automatic Spray Air Fresheners': '|AUTOMATIC SPRAY AIR FRESHENERS--|',
  'Bagels & English Muffins': '|BAGELS & ENGLISH MUFFINS--|',
  'bags, luggage and accessories': '|BAGS, LUGGAGE AND ACCESSORIES--|',
  Bakeware: '|BAKEWARE--|',
  'Ballpoint Pens': '|BALLPOINT PENS--|',
  'Baseball Bats': '|BASEBALL BATS--|',
  'Bathroom Shelves': '|BATHROOM SHELVES--|',
  'Beard Trimming & Care': '|BEARD TRIMMING & CARE--|',
  'Beef & Veal': '|BEEF & VEAL--|',
  'Big Boys (8-20) Summer Shop T-Shirts & Tank Tops':
    '|BIG BOYS (8-20) SUMMER SHOP T-SHIRTS & TANK TOPS--|',
  'Bike Lights': '|BIKE LIGHTS--|',
  'Birthday Candles': '|BIRTHDAY CANDLES--|',
  'Birthday Party Tableware': '|BIRTHDAY PARTY TABLEWARE--|',
  'Blue Scrubs': '|BLUE SCRUBS--|',
  "Blue's Clues Toys": "|BLUE'S CLUES TOYS--|",
  'Body Oils': '|BODY OILS--|',
  'Body Scrubs & Exfoliants': '|BODY SCRUBS & EXFOLIANTS--|',
  'Body Washes': '|BODY WASHES--|',
  'Bottled Tea & Kombucha': '|BOTTLED TEA & KOMBUCHA--|',
  'Brewed Coffee and More': '|BREWED COFFEE AND MORE--|',
  'Building Kits and Sets': '|BUILDING KITS AND SETS--|',
  'Camping Fire Starters': '|CAMPING FIRE STARTERS--|',
  'Candle Holders & Accessories': '|CANDLE HOLDERS & ACCESSORIES--|',
  'Cat Flea & Tick': '|CAT FLEA & TICK--|',
  'Cat Litter Waste Disposal': '|CAT LITTER WASTE DISPOSAL--|',
  'Cat Scratching Pads': '|CAT SCRATCHING PADS--|',
  'Cell Phone Grips and Stands': '|CELL PHONE GRIPS AND STANDS--|',
  'Character Party Favors': '|CHARACTER PARTY FAVORS--|',
  'Character Party Tableware': '|CHARACTER PARTY TABLEWARE--|',
  'Chicken Meals': '|CHICKEN MEALS--|',
  'Chocolate Powders & Syrups': '|CHOCOLATE POWDERS & SYRUPS--|',
  'Christmas String Lights': '|CHRISTMAS STRING LIGHTS--|',
  'Christmas Trees': '|CHRISTMAS TREES--|',
  Cigars: '|CIGARS--|',
  'Classic Smoothies': '|CLASSIC SMOOTHIES--|',
  'Cleaning Gloves': '|CLEANING GLOVES--|',
  'Clone of Cake, Baking & Pastry Supplies':
    '|CLONE OF CAKE, BAKING & PASTRY SUPPLIES--|',
  'Clothing > Womens > Tops': '|CLOTHING-WOMENS-TOPS|',
  Clutches: '|CLUTCHES--|',
  'Coffee Supplies': '|COFFEE SUPPLIES--|',
  'Colgate Toothbrushes': '|COLGATE TOOTHBRUSHES--|',
  'Collectible Figurines & Buildings': '|COLLECTIBLE FIGURINES & BUILDINGS--|',
  Condoms: '|CONDOMS--|',
  Confetti: '|CONFETTI--|',
  Contouring: '|CONTOURING--|',
  'Cookie Dough': '|COOKIE DOUGH--|',
  'Corn & Callus Removers': '|CORN & CALLUS REMOVERS--|',
  'Cough & Sore Throat Relief': '|COUGH & SORE THROAT RELIEF--|',
  'Craft Tools': '|CRAFT TOOLS--|',
  Crayola: '|CRAYOLA--|',
  Crocs: '|CROCS--|',
  'Cupcake & Muffin Pans': '|CUPCAKE & MUFFIN PANS--|',
  'Curtain Sets': '|CURTAIN SETS--|',
  'Dinner Low Carb Offerings': '|DINNER LOW CARB OFFERINGS--|',
  'Dipping Sauces': '|DIPPING SAUCES--|',
  'DIY Bath & Spa Kits': '|DIY BATH & SPA KITS--|',
  'Dog Treats & Chews': '|DOG TREATS & CHEWS--|',
  "Domino's American Legends": "|DOMINO'S AMERICAN LEGENDS--|",
  'Donuts, Muffins, & Pastries': '|DONUTS, MUFFINS, & PASTRIES--|',
  Doormats: '|DOORMATS--|',
  'Drinkware Sets': '|DRINKWARE SETS--|',
  'Easter Indoor Decor': '|EASTER INDOOR DECOR--|',
  Electric: '|ELECTRIC--|',
  'Eligible Specialty Gift Cards': '|ELIGIBLE SPECIALTY GIFT CARDS--|',
  Embellishments: '|EMBELLISHMENTS--|',
  'Energy Drinks & Shots': '|ENERGY DRINKS & SHOTS--|',
  'Energy Shots': '|ENERGY SHOTS--|',
  'Enhanced Water': '|ENHANCED WATER--|',
  Equate: '|EQUATE--|',
  'Equate Arthritis Pain Relief': '|EQUATE ARTHRITIS PAIN RELIEF--|',
  'Equate Everyday Essentials': '|EQUATE EVERYDAY ESSENTIALS--|',
  'Equate Gauze Pads': '|EQUATE GAUZE PADS--|',
  'Equate Infants & Children Pain Relief':
    '|EQUATE INFANTS & CHILDREN PAIN RELIEF--|',
  'Erasers & Correction Tape': '|ERASERS & CORRECTION TAPE--|',
  'Eye Shadow': '|EYE SHADOW--|',
  Eyeshadow: '|EYESHADOW--|',
  'Fabric Dyes': '|FABRIC DYES--|',
  'Face Wash': '|FACE WASH--|',
  'Family Games': '|FAMILY GAMES--|',
  'Fashion Bracelets & Charms': '|FASHION BRACELETS & CHARMS--|',
  'Fashion Notebooks': '|FASHION NOTEBOOKS--|',
  'Finish Line Athletic Sneakers': '|FINISH LINE ATHLETIC SNEAKERS--|',
  'Fish Attractants': '|FISH ATTRACTANTS--|',
  Flashlights: '|FLASHLIGHTS--|',
  'Flavored Sparkling Water': '|FLAVORED SPARKLING WATER--|',
  'Food Coloring': '|FOOD COLORING--|',
  'Food/Beverages/Juice/Apple Juice': '|FOOD/BEVERAGES/JUICE/APPLE JUICE--|',
  'Food/Breakfast & Cereal/Cereal & Breakfast Bars':
    '|FOOD/BREAKFAST & CEREAL/CEREAL & BREAKFAST BARS--|',
  'Fram Oil Filters': '|FRAM OIL FILTERS--|',
  'Frosting & Decoration': '|FROSTING & DECORATION--|',
  'Frozen Drinks': '|FROZEN DRINKS--|',
  'Frozen Meat Pizza': '|FROZEN MEAT PIZZA--|',
  'Garnier Hair Care': '|GARNIER HAIR CARE--|',
  'General Mills Box Tops Cereal': '|GENERAL MILLS BOX TOPS CEREAL--|',
  'General Purpose LED Light Bulbs': '|GENERAL PURPOSE LED LIGHT BULBS--|',
  'Gerber Baby Girls Clothing': '|GERBER BABY GIRLS CLOTHING--|',
  "Girls' Accessories": "|GIRLS' ACCESSORIES--|",
  'Great Value Dryer Sheets': '|GREAT VALUE DRYER SHEETS--|',
  'Griddles & Waffle Makers': '|GRIDDLES & WAFFLE MAKERS--|',
  'Grocery > Herbs. Spices & Seasonings > Bulk - Spices':
    '|GROCERY-HERBS. SPICES & SEASONINGS-BULK - SPICES|',
  'Hallmark Christmas Gift Bags': '|HALLMARK CHRISTMAS GIFT BAGS--|',
  'Handheld Vacuums': '|HANDHELD VACUUMS--|',
  'Health & Household': '|HEALTH & HOUSEHOLD--|',
  'Home Goods': '|HOME GOODS--|',
  'Home Improvement Supplies': '|HOME IMPROVEMENT SUPPLIES--|',
  'Hot N Ready': '|HOT N READY--|',
  'Household Essentials/Cleaning Supplies/All-Purpose Cleaners':
    '|HOUSEHOLD ESSENTIALS/CLEANING SUPPLIES/ALL-PURPOSE CLEANERS--|',
  'Household Essentials/Cleaning Supplies/Kitchen Cleaners':
    '|HOUSEHOLD ESSENTIALS/CLEANING SUPPLIES/KITCHEN CLEANERS--|',
  Humidifiers: '|HUMIDIFIERS--|',
  'Individual Chicken Combos': '|INDIVIDUAL CHICKEN COMBOS--|',
  'Instant Action': '|INSTANT ACTION--|',
  'Invitations & Stationery': '|INVITATIONS & STATIONERY--|',
  'iPhone 11 Screen Protectors': '|IPHONE 11 SCREEN PROTECTORS--|',
  'iPhone XR Cases': '|IPHONE XR CASES--|',
  'Irons, Steamers & Accessories': '|IRONS, STEAMERS & ACCESSORIES--|',
  'Jackets & Coats': '|JACKETS & COATS--|',
  'Jackets & Vests': '|JACKETS & VESTS--|',
  'Jewelry Boxes & Organizers': '|JEWELRY BOXES & ORGANIZERS--|',
  'Joint Compound': '|JOINT COMPOUND--|',
  "Juniors' Bikinis": "|JUNIORS' BIKINIS--|",
  'Just for Starters': '|JUST FOR STARTERS--|',
  'Kid Connection': '|KID CONNECTION--|',
  'Kids Meals': '|KIDS MEALS--|',
  'Kids Scooter': '|KIDS SCOOTER--|',
  'Kitchen and Indoor Trash Cans': '|KITCHEN AND INDOOR TRASH CANS--|',
  'Kitchen Essentials & Tools': '|KITCHEN ESSENTIALS & TOOLS--|',
  'Kitchen Sinks': '|KITCHEN SINKS--|',
  'Kitchen Storage Racks, Holders and Dispensers':
    '|KITCHEN STORAGE RACKS, HOLDERS AND DISPENSERS--|',
  Laxatives: '|LAXATIVES--|',
  'Learning Toys': '|LEARNING TOYS--|',
  'Lemons & Limes': '|LEMONS & LIMES--|',
  'Lip Liner': '|LIP LINER--|',
  'Liquid Glue & School Glue': '|LIQUID GLUE & SCHOOL GLUE--|',
  'Litter Box & Cleanup': '|LITTER BOX & CLEANUP--|',
  'LOL Surprise Dolls': '|LOL SURPRISE DOLLS--|',
  'Makeup Gifts': '|MAKEUP GIFTS--|',
  'Makeup Removers & Wipes': '|MAKEUP REMOVERS & WIPES--|',
  'Manual Can Openers': '|MANUAL CAN OPENERS--|',
  'Masonry & Concrete Anchors': '|MASONRY & CONCRETE ANCHORS--|',
  'Meat Pizza': '|MEAT PIZZA--|',
  "Men's Shaving": "|MEN'S SHAVING--|",
  "Men's Shaving Needs": "|MEN'S SHAVING NEEDS--|",
  "Men's Socks": "|MEN'S SOCKS--|",
  'Mens Regular Jeans': '|MENS REGULAR JEANS--|',
  'Mens Relaxed Jeans': '|MENS RELAXED JEANS--|',
  'Mens Safety Toe': '|MENS SAFETY TOE--|',
  'Mens Undershirts': '|MENS UNDERSHIRTS--|',
  'Mindful Beauty Skincare': '|MINDFUL BEAUTY SKINCARE--|',
  'Moldables and Clay': '|MOLDABLES AND CLAY--|',
  'Monsterella Stix': '|MONSTERELLA STIX--|',
  'More Cheeses': '|MORE CHEESES--|',
  'More Chewy Candy': '|MORE CHEWY CANDY--|',
  'Nail Tools': '|NAIL TOOLS--|',
  'Naproxen Sodium': '|NAPROXEN SODIUM--|',
  'Nintendo Switch Accessories': '|NINTENDO SWITCH ACCESSORIES--|',
  'NYX Professional Foundation': '|NYX PROFESSIONAL FOUNDATION--|',
  'onn. Phone Screen Protectors': '|ONN. PHONE SCREEN PROTECTORS--|',
  'Other Beverages': '|OTHER BEVERAGES--|',
  'Other DG Brands': '|OTHER DG BRANDS--|',
  'Ozark Trail': '|OZARK TRAIL--|',
  Packaged: '|PACKAGED--|',
  'Paint Edgers': '|PAINT EDGERS--|',
  'Painting Accessories': '|PAINTING ACCESSORIES--|',
  'Party Accessories': '|PARTY ACCESSORIES--|',
  'Party Favors': '|PARTY FAVORS--|',
  'Pasta Meals': '|PASTA MEALS--|',
  'PC Gaming Mouse & Mouse Pads': '|PC GAMING MOUSE & MOUSE PADS--|',
  'Pendant Lighting': '|PENDANT LIGHTING--|',
  Pepper: '|PEPPER--|',
  'Percale Sheets': '|PERCALE SHEETS--|',
  Perfume: '|PERFUME--|',
  'Pest control products': '|PEST CONTROL PRODUCTS--|',
  'Phones & Accessories': '|PHONES & ACCESSORIES--|',
  'Pink Gift Wrap Supplies': '|PINK GIFT WRAP SUPPLIES--|',
  'Pistachio Nuts': '|PISTACHIO NUTS--|',
  'Pita & Lavash Bread': '|PITA & LAVASH BREAD--|',
  'Poly Bags': '|POLY BAGS--|',
  'Portable Fans': '|PORTABLE FANS--|',
  Portfolios: '|PORTFOLIOS--|',
  Pots: '|POTS--|',
  'Poufs & Floor Pillows': '|POUFS & FLOOR PILLOWS--|',
  Powder: '|POWDER--|',
  'Powder Formula': '|POWDER FORMULA--|',
  'Powders & Mixers': '|POWDERS & MIXERS--|',
  'Puffs Facial Tissues': '|PUFFS FACIAL TISSUES--|',
  'Ready To Cook Meat & Seafood': '|READY TO COOK MEAT & SEAFOOD--|',
  'Ready To Drink Cocktails': '|READY TO DRINK COCKTAILS--|',
  Red: '|RED--|',
  Refills: '|REFILLS--|',
  'Royal Blizzards': '|ROYAL BLIZZARDS--|',
  'Rubber Gloves': '|RUBBER GLOVES--|',
  "Sam'AUs Club": "|SAM'AUS CLUB--|",
  'Sandwiches and More': '|SANDWICHES AND MORE--|',
  'Sandwiches and Salads': '|SANDWICHES AND SALADS--|',
  Scrunchies: '|SCRUNCHIES--|',
  Seasonal: '|SEASONAL--|',
  'Seasonal Decorations': '|SEASONAL DECORATIONS--|',
  'Sets & Outfits': '|SETS & OUTFITS--|',
  'Shelving Boards & Brackets': '|SHELVING BOARDS & BRACKETS--|',
  'Shoe Racks': '|SHOE RACKS--|',
  'Shop All Blenders': '|SHOP ALL BLENDERS--|',
  'Shop All Star Wars': '|SHOP ALL STAR WARS--|',
  'Sides (Platter)': '|SIDES (PLATTER)--|',
  'Skin and Scalp Treatments': '|SKIN AND SCALP TREATMENTS--|',
  'Slab Doors': '|SLAB DOORS--|',
  'Sliced Meat': '|SLICED MEAT--|',
  'Small Animal Bedding': '|SMALL ANIMAL BEDDING--|',
  'Snowflake Christmas Ornaments': '|SNOWFLAKE CHRISTMAS ORNAMENTS--|',
  SO: '|SO--|',
  'Sound Bars': '|SOUND BARS--|',
  'Specialty Glues': '|SPECIALTY GLUES--|',
  'Sports Games': '|SPORTS GAMES--|',
  'Spray Adhesives': '|SPRAY ADHESIVES--|',
  Staples: '|STAPLES--|',
  'Starbucks Iced Coffee': '|STARBUCKS ICED COFFEE--|',
  'Stuffed Dog Toys': '|STUFFED DOG TOYS--|',
  'suncare and tanning': '|SUNCARE AND TANNING--|',
  'Sunflower Seeds': '|SUNFLOWER SEEDS--|',
  'Sweet Tea': '|SWEET TEA--|',
  'syrups, sugars and sweeteners': '|SYRUPS, SUGARS AND SWEETENERS--|',
  'Tanks & Camis': '|TANKS & CAMIS--|',
  'Target > Beauty > Makeup > Nails > Nail Polish & Removers':
    '|TARGET > BEAUTY > MAKEUP > NAILS > NAIL POLISH & REMOVERS--|',
  'Tech Accessories': '|TECH ACCESSORIES--|',
  '6" Hot Subs': '|6" HOT SUBS--|',
  Action: '|ACTION--|',
  'Active Tops': '|ACTIVE TOPS--|',
  Adhesives: '|ADHESIVES--|',
  'Ages 0-12 months': '|AGES 0-12 MONTHS--|',
  'Air Mattresses': '|AIR MATTRESSES--|',
  Alkaline: '|ALKALINE--|',
  'All Action Figure Playsets': '|ALL ACTION FIGURE PLAYSETS--|',
  'All Additives': '|ALL ADDITIVES--|',
  'all athletic shoes': '|ALL ATHLETIC SHOES--|',
  'All Bathroom Scales': '|ALL BATHROOM SCALES--|',
  'All Beef Jerky': '|ALL BEEF JERKY--|',
  'All Bike Components': '|ALL BIKE COMPONENTS--|',
  'All Boys Halloween Costumes': '|ALL BOYS HALLOWEEN COSTUMES--|',
  'All Cameras': '|ALL CAMERAS--|',
  'All Concert Drums': '|ALL CONCERT DRUMS--|',
  'All Dog Health Care': '|ALL DOG HEALTH CARE--|',
  'All Doormats': '|ALL DOORMATS--|',
  'All Fans': '|ALL FANS--|',
  'All Halloween Candy': '|ALL HALLOWEEN CANDY--|',
  'All Nuts, Trail Mix,& Seeds': '|ALL NUTS, TRAIL MIX,& SEEDS--|',
  'All Remote Control Toys': '|ALL REMOTE CONTROL TOYS--|',
  'All Soups': '|ALL SOUPS--|',
  'All Superfoods': '|ALL SUPERFOODS--|',
  'All Terminal Tackle': '|ALL TERMINAL TACKLE--|',
  'Ammunition Accessories': '|AMMUNITION ACCESSORIES--|',
  'Ariana Grande Perfume': '|ARIANA GRANDE PERFUME--|',
  'Armor All': '|ARMOR ALL--|',
  'Athletic Shoes': '|ATHLETIC SHOES--|',
  Awnings: '|AWNINGS--|',
  'Baby Powders': '|BABY POWDERS--|',
  'Backrest Pillows': '|BACKREST PILLOWS--|',
  'Baking Powder, Baking Soda & Yeast':
    '|BAKING POWDER, BAKING SODA & YEAST--|',
  'Barbie Princess & Fairy': '|BARBIE PRINCESS & FAIRY--|',
  'BB & Pellet Air Guns': '|BB & PELLET AIR GUNS--|',
  'Beauty & Personal Care': '|BEAUTY & PERSONAL CARE--|',
  'Beauty & Personal Care Deals': '|BEAUTY & PERSONAL CARE DEALS--|',
  'Beauty Sleep': '|BEAUTY SLEEP--|',
  'Beverages > Tea > Black': '|BEVERAGES-TEA-BLACK|',
  'Big Boys Athletic Pants': '|BIG BOYS ATHLETIC PANTS--|',
  'Big Boys Jeans': '|BIG BOYS JEANS--|',
  'Bissell Pet Vacuums': '|BISSELL PET VACUUMS--|',
  'Blue Gift Wrap Supplies': '|BLUE GIFT WRAP SUPPLIES--|',
  'Bluetooth Speakers': '|BLUETOOTH SPEAKERS--|',
  Blush: '|BLUSH--|',
  'Body Jewelry': '|BODY JEWELRY--|',
  'Body Sprays': '|BODY SPRAYS--|',
  'Bonnie Harvest Guarantee': '|BONNIE HARVEST GUARANTEE--|',
  'Bounty Paper Towels': '|BOUNTY PAPER TOWELS--|',
  "Boys' Sleepwear": "|BOYS' SLEEPWEAR--|",
  'Bras, Panties & Lingerie': '|BRAS, PANTIES & LINGERIE--|',
  Broth: '|BROTH--|',
  'Brownie Mix': '|BROWNIE MIX--|',
  'Bungee Cords and Ratchet Straps': '|BUNGEE CORDS AND RATCHET STRAPS--|',
  'Butter Burgers and Melts': '|BUTTER BURGERS AND MELTS--|',
  Butterball: '|BUTTERBALL--|',
  'Cabinet & Drawer Organization': '|CABINET & DRAWER ORGANIZATION--|',
  'Cabinet Pulls': '|CABINET PULLS--|',
  'Cables, Cords & Adapters': '|CABLES, CORDS & ADAPTERS--|',
  'Cafe Curtain Rods': '|CAFE CURTAIN RODS--|',
  'Candy, Chocolate & Gum': '|CANDY, CHOCOLATE & GUM--|',
  'Canvas Wall Art': '|CANVAS WALL ART--|',
  'Car Maintenance': '|CAR MAINTENANCE--|',
  'Carpenter Squares': '|CARPENTER SQUARES--|',
  'Cashew Nuts': '|CASHEW NUTS--|',
  'Cat Litter & Supplies': '|CAT LITTER & SUPPLIES--|',
  'Cat Tunnels': '|CAT TUNNELS--|',
  'Catering Beverages': '|CATERING BEVERAGES--|',
  Catnip: '|CATNIP--|',
  'Cereal - Cold': '|CEREAL - COLD--|',
  'Character Bedding': '|CHARACTER BEDDING--|',
  'Cheese Blends': '|CHEESE BLENDS--|',
  'Chicken Only': '|CHICKEN ONLY--|',
  "Children's Equate": "|CHILDREN'S EQUATE--|",
  "Children's Paint and Paint Supplies":
    "|CHILDREN'S PAINT AND PAINT SUPPLIES--|",
  'Childrens Medicine': '|CHILDRENS MEDICINE--|',
  'Chilled Puddings': '|CHILLED PUDDINGS--|',
  'Chocolate & Fudge Cookies': '|CHOCOLATE & FUDGE COOKIES--|',
  'Christmas Tree Lights': '|CHRISTMAS TREE LIGHTS--|',
  'Circular Saw Blades': '|CIRCULAR SAW BLADES--|',
  'Citrus & Lemon-Lime': '|CITRUS & LEMON-LIME--|',
  'Clothing > Boys > Bottoms': '|CLOTHING-BOYS-BOTTOMS|',
  'Clothing > Boys > Tops': '|CLOTHING-BOYS-TOPS|',
  'Coffee & Espresso Parts and Accessories':
    '|COFFEE & ESPRESSO PARTS AND ACCESSORIES--|',
  'Coffee, Tea & Cocoa': '|COFFEE, TEA & COCOA--|',
  'Cold Brew & Nitro': '|COLD BREW & NITRO--|',
  Cologne: '|COLOGNE--|',
  'Computer Accessories': '|COMPUTER ACCESSORIES--|',
  'Concealers & Correctors': '|CONCEALERS & CORRECTORS--|',
  'Concrete Blocks': '|CONCRETE BLOCKS--|',
  'Cookie Sheets': '|COOKIE SHEETS--|',
  'Cooling Towels': '|COOLING TOWELS--|',
  'Country Dinner Plates': '|COUNTRY DINNER PLATES--|',
  'Craft Sticks & Dowels': '|CRAFT STICKS & DOWELS--|',
  'Crayola Crayons': '|CRAYOLA CRAYONS--|',
  'Cruiser Bikes': '|CRUISER BIKES--|',
  'Cutting Tools': '|CUTTING TOOLS--|',
  'Daylight Savings': '|DAYLIGHT SAVINGS--|',
  Decor: '|DECOR--|',
  Dental: '|DENTAL--|',
  'Desk Supplies': '|DESK SUPPLIES--|',
  'Diabetic Supplies': '|DIABETIC SUPPLIES--|',
  'Diet & Nutrition': '|DIET & NUTRITION--|',
  'Dog Accessories & Cleaning': '|DOG ACCESSORIES & CLEANING--|',
  'Dog Health & Supplements': '|DOG HEALTH & SUPPLEMENTS--|',
  'Door Security': '|DOOR SECURITY--|',
  'Dorm Vacuum Essentials': '|DORM VACUUM ESSENTIALS--|',
  'Driver-Drills': '|DRIVER-DRILLS--|',
  'Drives & Storage': '|DRIVES & STORAGE--|',
  'Drywall Sanding Tools': '|DRYWALL SANDING TOOLS--|',
  'Easter Candy': '|EASTER CANDY--|',
  'Eat High Protein Diet': '|EAT HIGH PROTEIN DIET--|',
  'Educational & Learning Toys': '|EDUCATIONAL & LEARNING TOYS--|',
  Electrical: '|ELECTRICAL--|',
  'Electrical Tape': '|ELECTRICAL TAPE--|',
  'Entry Doors': '|ENTRY DOORS--|',
  'Equate Baby Care': '|EQUATE BABY CARE--|',
  'Equate Cough and Cold': '|EQUATE COUGH AND COLD--|',
  'Equate Sinus Congestion & Nasal Care':
    '|EQUATE SINUS CONGESTION & NASAL CARE--|',
  'Essential Oils & Aromatherapy': '|ESSENTIAL OILS & AROMATHERAPY--|',
  Eyewear: '|EYEWEAR--|',
  'Fabric Care': '|FABRIC CARE--|',
  'Fabric Paint': '|FABRIC PAINT--|',
  'Face Cosmetics': '|FACE COSMETICS--|',
  'Face Primer': '|FACE PRIMER--|',
  'Face Serums': '|FACE SERUMS--|',
  'Fan Heaters': '|FAN HEATERS--|',
  Farberware: '|FARBERWARE--|',
  'Farberware Tools and Gadgets': '|FARBERWARE TOOLS AND GADGETS--|',
  'Fashion Rings': '|FASHION RINGS--|',
  Fasteners: '|FASTENERS--|',
  Febreze: '|FEBREZE--|',
  'Fence Pickets': '|FENCE PICKETS--|',
  Fiber: '|FIBER--|',
  'Fiction & Literature': '|FICTION & LITERATURE--|',
  'Fills, Foam and Pillow Forms': '|FILLS, FOAM AND PILLOW FORMS--|',
  'Finishing Nails': '|FINISHING NAILS--|',
  Fishing: '|FISHING--|',
  FLATS: '|FLATS--|',
  flatware: '|FLATWARE--|',
  'FM Transmitters': '|FM TRANSMITTERS--|',
  'Food > Breakfast > Omelettes': '|FOOD-BREAKFAST-OMELETTES|',
  'Food > Seafood Entrees > Fish Entrees':
    '|FOOD-SEAFOOD ENTREES-FISH ENTREES|',
  'Food Strainers & Colanders': '|FOOD STRAINERS & COLANDERS--|',
  'Food/Breakfast & Cereal/Cold Cereal/Post Cereal':
    '|FOOD/BREAKFAST & CEREAL/COLD CEREAL/POST CEREAL--|',
  'Food/Fresh Food/Meat, Seafood & Poultry/Seafood':
    '|FOOD/FRESH FOOD/MEAT, SEAFOOD & POULTRY/SEAFOOD--|',
  'Fountain Drinks': '|FOUNTAIN DRINKS--|',
  'Fresh Mandarins': '|FRESH MANDARINS--|',
  'Fresh Salads': '|FRESH SALADS--|',
  'Fresh Sliced Cold Subs': '|FRESH SLICED COLD SUBS--|',
  'Frozen Appetizers & Snacks': '|FROZEN APPETIZERS & SNACKS--|',
  'Fruit & Vegetable Blends': '|FRUIT & VEGETABLE BLENDS--|',
  'FSA Shop All': '|FSA SHOP ALL--|',
  'Full Meal Deals': '|FULL MEAL DEALS--|',
  'Funko Collectibles': '|FUNKO COLLECTIBLES--|',
  'Funko POP': '|FUNKO POP--|',
  'Garage Shelving Units': '|GARAGE SHELVING UNITS--|',
  'Gas Cans': '|GAS CANS--|',
  Gelatin: '|GELATIN--|',
  'Gift Cards': '|GIFT CARDS--|',
  'Gift Wrap Supplies': '|GIFT WRAP SUPPLIES--|',
  'Girls Bras': '|GIRLS BRAS--|',
  'Glade Spray Air Fresheners': '|GLADE SPRAY AIR FRESHENERS--|',
  'Glitter Slime': '|GLITTER SLIME--|',
  'Gluten Free Foods': '|GLUTEN FREE FOODS--|',
  'Golf Balls': '|GOLF BALLS--|',
  'Gorilla Glue': '|GORILLA GLUE--|',
  'Gourmet Food & Gifts': '|GOURMET FOOD & GIFTS--|',
  'Graham Crackers': '|GRAHAM CRACKERS--|',
  'Granola & Muesli': '|GRANOLA & MUESLI--|',
  'Granola & Nutrition Bars': '|GRANOLA & NUTRITION BARS--|',
  'Great Value Powdered Drink Mixes': '|GREAT VALUE POWDERED DRINK MIXES--|',
  'Great Value Snack Bars': '|GREAT VALUE SNACK BARS--|',
  'Green Christmas Trees': '|GREEN CHRISTMAS TREES--|',
  'Grill Accessories & Tools': '|GRILL ACCESSORIES & TOOLS--|',
  'Grill Cleaning Tools': '|GRILL CLEANING TOOLS--|',
  'Grilled Meals': '|GRILLED MEALS--|',
  'Grocery > Condiment & Sauces > Glazes':
    '|GROCERY-CONDIMENT & SAUCES-GLAZES|',
  'Grooming & Bathing': '|GROOMING & BATHING--|',
  Grout: '|GROUT--|',
  'Hair Brushes': '|HAIR BRUSHES--|',
  'Hair Dryers': '|HAIR DRYERS--|',
  'Hair Styling Products & Treatments':
    '|HAIR STYLING PRODUCTS & TREATMENTS--|',
  'Halloween Favors & Wear': '|HALLOWEEN FAVORS & WEAR--|',
  'Ham & Pork': '|HAM & PORK--|',
  'Hamburger Buns': '|HAMBURGER BUNS--|',
  'Hamilton Beach Microwaves': '|HAMILTON BEACH MICROWAVES--|',
  'Hammers, Mallets & Sledges': '|HAMMERS, MALLETS & SLEDGES--|',
  'Handbags & Accessories': '|HANDBAGS & ACCESSORIES--|',
  'Handheld Shower Heads': '|HANDHELD SHOWER HEADS--|',
  Headaches: '|HEADACHES--|',
  'Health Deals': '|HEALTH DEALS--|',
  'Heart Healthy Supplements': '|HEART HEALTHY SUPPLEMENTS--|',
  'Heartburn & Antacids': '|HEARTBURN & ANTACIDS--|',
  'Heat Tools': '|HEAT TOOLS--|',
  'Herbal Essences Conditioners': '|HERBAL ESSENCES CONDITIONERS--|',
  Highlighters: '|HIGHLIGHTERS--|',
  'Home Decor': '|HOME DECOR--|',
  'Home improvement sealants and adhesives':
    '|HOME IMPROVEMENT SEALANTS AND ADHESIVES--|',
  'Home Storage Hooks': '|HOME STORAGE HOOKS--|',
  'Honey Butter Biscuits': '|HONEY BUTTER BISCUITS--|',
  'Hot & Cold Therapy': '|HOT & COLD THERAPY--|',
  'Hot Breakfast': '|HOT BREAKFAST--|',
  'Ice Cream & Frozen Yogurt': '|ICE CREAM & FROZEN YOGURT--|',
  'Ice Cream Signature Creations': '|ICE CREAM SIGNATURE CREATIONS--|',
  'Icicle Lights': '|ICICLE LIGHTS--|',
  Igloo: '|IGLOO--|',
  'Jelly Beans': '|JELLY BEANS--|',
  'Jojo Siwa Greeting Cards and Gift Wrap':
    '|JOJO SIWA GREETING CARDS AND GIFT WRAP--|',
  'Jolly Rancher': '|JOLLY RANCHER--|',
  'Juice & Nectars': '|JUICE & NECTARS--|',
  'Juice Boxes & Pouches': '|JUICE BOXES & POUCHES--|',
  'Juniors Capris': '|JUNIORS CAPRIS--|',
  'Juniors T-Shirts': '|JUNIORS T-SHIRTS--|',
  'Key Rings & Organizers': '|KEY RINGS & ORGANIZERS--|',
  'Kids & Family Games': '|KIDS & FAMILY GAMES--|',
  "Kids' Towels": "|KIDS' TOWELS--|",
  'Kitchen Utensils & Tools': '|KITCHEN UTENSILS & TOOLS--|',
  'Kitchens, Playfood & Housekeeping': '|KITCHENS, PLAYFOOD & HOUSEKEEPING--|',
  'Laffy Taffy': '|LAFFY TAFFY--|',
  'Lawn & Garden': '|LAWN & GARDEN--|',
  'LED Bulbs': '|LED BULBS--|',
  "Levi's": "|LEVI'S--|",
  'Life Savers': '|LIFE SAVERS--|',
  'Lighters, Fluid, & Matches': '|LIGHTERS, FLUID, & MATCHES--|',
  'Links & Bratwurst': '|LINKS & BRATWURST--|',
  'Lip Balm & Treatments': '|LIP BALM & TREATMENTS--|',
  'Literary Fiction': '|LITERARY FICTION--|',
  'Litter Accessories': '|LITTER ACCESSORIES--|',
  'Little Boys Socks': '|LITTLE BOYS SOCKS--|',
  'Locker Accessories': '|LOCKER ACCESSORIES--|',
  'Loose Leaf & Tea Bags': '|LOOSE LEAF & TEA BAGS--|',
  'Lubricants Top Brands': '|LUBRICANTS TOP BRANDS--|',
  'Magnets & Protective Discs': '|MAGNETS & PROTECTIVE DISCS--|',
  'Magnets, Tacks and Push Pins': '|MAGNETS, TACKS AND PUSH PINS--|',
  'Maintenance Chemicals': '|MAINTENANCE CHEMICALS--|',
  'Makeup Brushes & Tools': '|MAKEUP BRUSHES & TOOLS--|',
  'Manicure & Pedicure Tools': '|MANICURE & PEDICURE TOOLS--|',
  'Masking Tape': '|MASKING TAPE--|',
  'Mattress Pads & Protectors': '|MATTRESS PADS & PROTECTORS--|',
  'Mattress Toppers & Pads': '|MATTRESS TOPPERS & PADS--|',
  'Meatless Alternatives': '|MEATLESS ALTERNATIVES--|',
  'Medicines with Sleep Aids': '|MEDICINES WITH SLEEP AIDS--|',
  'Medium Trash Bags': '|MEDIUM TRASH BAGS--|',
  Melons: '|MELONS--|',
  'Memory Foam Mattress Topper': '|MEMORY FOAM MATTRESS TOPPER--|',
  "Men's Character Shop": "|MEN'S CHARACTER SHOP--|",
  "Men's Cologne New Arrivals": "|MEN'S COLOGNE NEW ARRIVALS--|",
  "Men's Fragrances": "|MEN'S FRAGRANCES--|",
  'Mens > Shoes > Athletic Shoes & Sneakers':
    '|MENS-SHOES-ATHLETIC SHOES & SNEAKERS|',
  'Mens Active Pants': '|MENS ACTIVE PANTS--|',
  'Mens Active Shorts': '|MENS ACTIVE SHORTS--|',
  'Mens Boxers': '|MENS BOXERS--|',
  'Mens Jackets & Outerwear': '|MENS JACKETS & OUTERWEAR--|',
  'Microfiber Sheets': '|MICROFIBER SHEETS--|',
  Microsoft: '|MICROSOFT--|',
  'Muffin & Bread Mix': '|MUFFIN & BREAD MIX--|',
  Mulch: '|MULCH--|',
  'Multi & Letter Vitamins': '|MULTI & LETTER VITAMINS--|',
  'Nail Sets & Accessories': '|NAIL SETS & ACCESSORIES--|',
  'New from General Mills': '|NEW FROM GENERAL MILLS--|',
  'Night Lights': '|NIGHT LIGHTS--|',
  'NUK Pacifiers': '|NUK PACIFIERS--|',
  'Oatmeal & Hot Cereal': '|OATMEAL & HOT CEREAL--|',
  'Office Chairs': '|OFFICE CHAIRS--|',
  'Old Spice Body Wash & Deodorants': '|OLD SPICE BODY WASH & DEODORANTS--|',
  'olives, pickles and relish': '|OLIVES, PICKLES AND RELISH--|',
  'Other Building Blocks': '|OTHER BUILDING BLOCKS--|',
  'Other Camera Accessories': '|OTHER CAMERA ACCESSORIES--|',
  'Outdoor Dinnerware': '|OUTDOOR DINNERWARE--|',
  'Outdoor Rugs': '|OUTDOOR RUGS--|',
  'Packaged Fresh Fruit': '|PACKAGED FRESH FRUIT--|',
  'Packaged Fruit': '|PACKAGED FRUIT--|',
  'Packing & Comfort': '|PACKING & COMFORT--|',
  Pajamas: '|PAJAMAS--|',
  'Pants & Leggings': '|PANTS & LEGGINGS--|',
  'Panty Liners': '|PANTY LINERS--|',
  'pastries, pies and donuts': '|PASTRIES, PIES AND DONUTS--|',
  'Pencil Eyeliner': '|PENCIL EYELINER--|',
  'Personal Care/Bath & Body/Body Wash & Shower Gel':
    '|PERSONAL CARE/BATH & BODY/BODY WASH & SHOWER GEL--|',
  'Personal Care/Foot Care': '|PERSONAL CARE/FOOT CARE--|',
  'Pet Bathing and Cleaning': '|PET BATHING AND CLEANING--|',
  'Pet Stain & Odor Remover': '|PET STAIN & ODOR REMOVER--|',
  'Phone Accessories': '|PHONE ACCESSORIES--|',
  Phones: '|PHONES--|',
  'Piggy Banks & Money Jars': '|PIGGY BANKS & MONEY JARS--|',
  'Pins & Tacks': '|PINS & TACKS--|',
  'Pipe And Fittings': '|PIPE AND FITTINGS--|',
  'Pipes and Fittings': '|PIPES AND FITTINGS--|',
  'Plastic Storage Bins & Baskets': '|PLASTIC STORAGE BINS & BASKETS--|',
  'Plastic Trash Cans': '|PLASTIC TRASH CANS--|',
  'Play and Chew Toys': '|PLAY AND CHEW TOYS--|',
  'Play Day Toys': '|PLAY DAY TOYS--|',
  'Play-Doh': '|PLAY-DOH--|',
  'Poopsie Slime Surprise': '|POOPSIE SLIME SURPRISE--|',
  'Portable Wireless & Bluetooth Speakers':
    '|PORTABLE WIRELESS & BLUETOOTH SPEAKERS--|',
  Posters: '|POSTERS--|',
  'Power Tool Combo Kits': '|POWER TOOL COMBO KITS--|',
  'Pre-Paid Cards': '|PRE-PAID CARDS--|',
  'Prepared Meals Sides': '|PREPARED MEALS SIDES--|',
  'Prescription Eyeglasses': '|PRESCRIPTION EYEGLASSES--|',
  PRIMROSE: '|PRIMROSE--|',
  'Private Brands Immunity': '|PRIVATE BRANDS IMMUNITY--|',
  'Project Panels': '|PROJECT PANELS--|',
  'Protective Gear': '|PROTECTIVE GEAR--|',
  Pudding: '|PUDDING--|',
  'Purina One Dry Dog Food': '|PURINA ONE DRY DOG FOOD--|',
  'Reciprocating Saw Blades': '|RECIPROCATING SAW BLADES--|',
  'Rectangular Plastic Tablecloths': '|RECTANGULAR PLASTIC TABLECLOTHS--|',
  'Red Gift Wrap Supplies': '|RED GIFT WRAP SUPPLIES--|',
  'Red Hair Dye': '|RED HAIR DYE--|',
  'Regular Burritos': '|REGULAR BURRITOS--|',
  'ReliOn Blood Glucose Test Strips': '|RELION BLOOD GLUCOSE TEST STRIPS--|',
  'Remote Control Toys': '|REMOTE CONTROL TOYS--|',
  'Repair & Maintenance': '|REPAIR & MAINTENANCE--|',
  Revlon: '|REVLON--|',
  Running: '|RUNNING--|',
  'Sandals & Flip Flops': '|SANDALS & FLIP FLOPS--|',
  'Sanding Sponges': '|SANDING SPONGES--|',
  'Sandwich & Pockets': '|SANDWICH & POCKETS--|',
  'Sandwiches & Wraps': '|SANDWICHES & WRAPS--|',
  Sauces: '|SAUCES--|',
  'Scents and Scent Eliminators': '|SCENTS AND SCENT ELIMINATORS--|',
  'School & Office Supplies': '|SCHOOL & OFFICE SUPPLIES--|',
  'Schwarzkopf Hair Color': '|SCHWARZKOPF HAIR COLOR--|',
  'Screw Eyes': '|SCREW EYES--|',
  'Seafood Meals': '|SEAFOOD MEALS--|',
  'Second Foods': '|SECOND FOODS--|',
  'Secret Treasures Bras': '|SECRET TREASURES BRAS--|',
  'Self-Drilling Screws': '|SELF-DRILLING SCREWS--|',
  'Sharp Cheddar Cheese': '|SHARP CHEDDAR CHEESE--|',
  'Shavers and Trimmers': '|SHAVERS AND TRIMMERS--|',
  'Sheet Masks': '|SHEET MASKS--|',
  'shipping and packing materials': '|SHIPPING AND PACKING MATERIALS--|',
  'Shoe Storage': '|SHOE STORAGE--|',
  Shooters: '|SHOOTERS--|',
  'Shop Beads by Type': '|SHOP BEADS BY TYPE--|',
  'Sinkers and Weights': '|SINKERS AND WEIGHTS--|',
  'Skinny It Up Pizzas': '|SKINNY IT UP PIZZAS--|',
  'Sleep Support Supplements': '|SLEEP SUPPORT SUPPLEMENTS--|',
  'Sliced & Singles': '|SLICED & SINGLES--|',
  'Small Animal Cages & Hutches': '|SMALL ANIMAL CAGES & HUTCHES--|',
  'Smart Watches': '|SMART WATCHES--|',
  Smartfood: '|SMARTFOOD--|',
  'Snuggle Dryer Sheets': '|SNUGGLE DRYER SHEETS--|',
  Soccer: '|SOCCER--|',
  'Socket Sets': '|SOCKET SETS--|',
  'Socks & Underwear': '|SOCKS & UNDERWEAR--|',
  'Soft & Chewy': '|SOFT & CHEWY--|',
  Soup: '|SOUP--|',
  'Specialty Insulation': '|SPECIALTY INSULATION--|',
  'Spring Valley Vitamin C': '|SPRING VALLEY VITAMIN C--|',
  'Sprinkles, Candles & Decorations': '|SPRINKLES, CANDLES & DECORATIONS--|',
  Stalwart: '|STALWART--|',
  Steakburgers: '|STEAKBURGERS--|',
  'Steaks and Ribs': '|STEAKS AND RIBS--|',
  'Stock up on Breakfast': '|STOCK UP ON BREAKFAST--|',
  'Stomach Remedies': '|STOMACH REMEDIES--|',
  Storage: '|STORAGE--|',
  Sunglasses: '|SUNGLASSES--|',
  'Sweepers & Supplies': '|SWEEPERS & SUPPLIES--|',
  'Sweet Cereal': '|SWEET CEREAL--|',
  'Syrups & Sauces': '|SYRUPS & SAUCES--|',
  Tableware: '|TABLEWARE--|',
  'Take Action': '|TAKE ACTION--|',
  'Target > Beauty > Bath & Body > Body Lotions & Creams':
    '|TARGET > BEAUTY > BATH & BODY > BODY LOTIONS & CREAMS--|',
  'Target > Beauty > Makeup > Face > Foundation':
    '|TARGET > BEAUTY > MAKEUP > FACE > FOUNDATION--|',
  'Target > Electronics > Target Tech > Protection Plans':
    '|TARGET > ELECTRONICS > TARGET TECH > PROTECTION PLANS--|',
  'Target > Grocery > Pantry > Canned & Packaged Foods > Canned Fruit':
    '|TARGET > GROCERY > PANTRY > CANNED & PACKAGED FOODS > CANNED FRUIT--|',
  'Target > Personal Care > Deodorant':
    '|TARGET > PERSONAL CARE > DEODORANT--|',
  Tarps: '|TARPS--|',
  'Tea Powder': '|TEA POWDER--|',
  'Tent Accessories': '|TENT ACCESSORIES--|',
  'Throw Blankets': '|THROW BLANKETS--|',
  'Tie Dye': '|TIE DYE--|',
  'Toddler Bedding Sets & Sheets': '|TODDLER BEDDING SETS & SHEETS--|',
  'Tool Bags': '|TOOL BAGS--|',
  'Tools & Brushes': '|TOOLS & BRUSHES--|',
  'Toothpaste & Mouthwash': '|TOOTHPASTE & MOUTHWASH--|',
  'Tortillas & Taco Shells': '|TORTILLAS & TACO SHELLS--|',
  'Trading Cards': '|TRADING CARDS--|',
  'Transducers & Cables': '|TRANSDUCERS & CABLES--|',
  'Travel Size': '|TRAVEL SIZE--|',
  'Treats & Chews': '|TREATS & CHEWS--|',
  'Tropical Plants': '|TROPICAL PLANTS--|',
  'TV & Movie Playsets': '|TV & MOVIE PLAYSETS--|',
  'Unicorn Decorations': '|UNICORN DECORATIONS--|',
  'Utensils, Tongs & Whisks': '|UTENSILS, TONGS & WHISKS--|',
  "Valentine's Day Card Packs": "|VALENTINE'S DAY CARD PACKS--|",
  "Valentine's Day Present": "|VALENTINE'S DAY PRESENT--|",
  'Valentine’s Day Beauty': '|VALENTINE’S DAY BEAUTY--|',
  Vans: '|VANS--|',
  Vegetarian: '|VEGETARIAN--|',
  'Vibrant Life Cat Litter': '|VIBRANT LIFE CAT LITTER--|',
  'Vibrant Life Grooming Tools': '|VIBRANT LIFE GROOMING TOOLS--|',
  'Water Care & Conditioning': '|WATER CARE & CONDITIONING--|',
  'Waverly Inspirations': '|WAVERLY INSPIRATIONS--|',
  'Wax Melts': '|WAX MELTS--|',
  'Whey Protein Powder': '|WHEY PROTEIN POWDER--|',
  'Wig Application Tools': '|WIG APPLICATION TOOLS--|',
  'Windows 10 Laptops': '|WINDOWS 10 LAPTOPS--|',
  'Windshield Covers': '|WINDSHIELD COVERS--|',
  'Wine Glasses': '|WINE GLASSES--|',
  'Wireless Keyboard & Mouse Combos': '|WIRELESS KEYBOARD & MOUSE COMBOS--|',
  Women: '|WOMEN--|',
  'Womens Bag Charms & Accessories': '|WOMENS BAG CHARMS & ACCESSORIES--|',
  'Wood Mulch': '|WOOD MULCH--|',
  'World Foods': '|WORLD FOODS--|',
  Wrangler: '|WRANGLER--|',
  'Wrigley Gum': '|WRIGLEY GUM--|',
  'Yogurt Drinks': '|YOGURT DRINKS--|',
  "Teens' Bedding Sets": "|TEENS' BEDDING SETS--|",
  'Telephone Accessories': '|TELEPHONE ACCESSORIES--|',
  'Tempera Paints': '|TEMPERA PAINTS--|',
  'Texas Size Combos': '|TEXAS SIZE COMBOS--|',
  'The Big One': '|THE BIG ONE--|',
  'Thin Crust Pizza Combos': '|THIN CRUST PIZZA COMBOS--|',
  'Tie-Down Straps & Bungee Cords': '|TIE-DOWN STRAPS & BUNGEE CORDS--|',
  Ties: '|TIES--|',
  'Tile Edge Trim': '|TILE EDGE TRIM--|',
  'Time and Tru Pants': '|TIME AND TRU PANTS--|',
  'Tire & Wheel Cleaners': '|TIRE & WHEEL CLEANERS--|',
  'Tire Changing Tools': '|TIRE CHANGING TOOLS--|',
  'Toddler Dishes and Utensils': '|TODDLER DISHES AND UTENSILS--|',
  'Toddler Girls Shirts & Blouses': '|TODDLER GIRLS SHIRTS & BLOUSES--|',
  Tofu: '|TOFU--|',
  'Toiletry Bags & Travel Containers': '|TOILETRY BAGS & TRAVEL CONTAINERS--|',
  Tortillas: '|TORTILLAS--|',
  'Toys for 2 year old boys': '|TOYS FOR 2 YEAR OLD BOYS--|',
  'Track Systems & Accessories': '|TRACK SYSTEMS & ACCESSORIES--|',
  'Trail & Snack Mixes': '|TRAIL & SNACK MIXES--|',
  'Travel Size Bottles & Storage': '|TRAVEL SIZE BOTTLES & STORAGE--|',
  'Trick-or-Treat Bags': '|TRICK-OR-TREAT BAGS--|',
  'U by Kotex Pantiliners': '|U BY KOTEX PANTILINERS--|',
  'Urban Pipeline': '|URBAN PIPELINE--|',
  'Vacuums & Floor Care': '|VACUUMS & FLOOR CARE--|',
  'Vegetable & Herb Seeds': '|VEGETABLE & HERB SEEDS--|',
  'Vibrant Life Pet Accessories': '|VIBRANT LIFE PET ACCESSORIES--|',
  'Wall Hooks': '|WALL HOOKS--|',
  'Waste & Sewage Equipment': '|WASTE & SEWAGE EQUIPMENT--|',
  'Wearable Technology Accessories': '|WEARABLE TECHNOLOGY ACCESSORIES--|',
  'Window Air Conditioners': '|WINDOW AIR CONDITIONERS--|',
  'Window and Shower Hardware': '|WINDOW AND SHOWER HARDWARE--|',
  'Womens Activewear': '|WOMENS ACTIVEWEAR--|',
  'Womens Activewear Tanks, Tops & Tees':
    '|WOMENS ACTIVEWEAR TANKS, TOPS & TEES--|',
  'Womens Capris': '|WOMENS CAPRIS--|',
  'Womens Shorts': '|WOMENS SHORTS--|',
  'Wood Fillers': '|WOOD FILLERS--|',
  'WWE All Action Figures': '|WWE ALL ACTION FIGURES--|',
  Zalads: '|ZALADS--|',
  'Zippered Mattress Protectors': '|ZIPPERED MATTRESS PROTECTORS--|',
  ' 2For Burritos': '| 2FOR BURRITOS--|',
  '2 for $5 Toys': '|2 FOR $5 TOYS--|',
  'Accent Rugs': '|ACCENT RUGS--|',
  'Activity Sets': '|ACTIVITY SETS--|',
  'air control accessories': '|AIR CONTROL ACCESSORIES--|',
  'Air Freshener Refills': '|AIR FRESHENER REFILLS--|',
  'Air Fresheners & Air Sanitizers': '|AIR FRESHENERS & AIR SANITIZERS--|',
  'All Acid Reflux': '|ALL ACID REFLUX--|',
  'All Anime & Animation': '|ALL ANIME & ANIMATION--|',
  'All Archery': '|ALL ARCHERY--|',
  'All Auto Accessories': '|ALL AUTO ACCESSORIES--|',
  'All Baby Teethers': '|ALL BABY TEETHERS--|',
  'All Brackets and Angle Irons': '|ALL BRACKETS AND ANGLE IRONS--|',
  'All Cat Flea & Tick Supplies': '|ALL CAT FLEA & TICK SUPPLIES--|',
  'All Departments > Grocery > Freezer': '|ALL DEPARTMENTS-GROCERY-FREEZER|',
  'All Dietary Supplements': '|ALL DIETARY SUPPLEMENTS--|',
  'All Dishwasher Parts': '|ALL DISHWASHER PARTS--|',
  'All Fresh Vegetables': '|ALL FRESH VEGETABLES--|',
  'All Glues': '|ALL GLUES--|',
  'All Headphones': '|ALL HEADPHONES--|',
  'All Kitchen Plumbing': '|ALL KITCHEN PLUMBING--|',
  "All Men's Shoes": "|ALL MEN'S SHOES--|",
  'All Nursery Storage': '|ALL NURSERY STORAGE--|',
  'All Other Digestion & Nausea': '|ALL OTHER DIGESTION & NAUSEA--|',
  'All Rock Music': '|ALL ROCK MUSIC--|',
  'All Seeds': '|ALL SEEDS--|',
  'All Spring Water': '|ALL SPRING WATER--|',
  'All Tablet Accessories': '|ALL TABLET ACCESSORIES--|',
  'All Toilet Paper': '|ALL TOILET PAPER--|',
  'All Toilet Seats': '|ALL TOILET SEATS--|',
  'All Toothpaste': '|ALL TOOTHPASTE--|',
  'All Tubes and Tire Accessories': '|ALL TUBES AND TIRE ACCESSORIES--|',
  'All Watches': '|ALL WATCHES--|',
  'Andis Trimmers & Groomers': '|ANDIS TRIMMERS & GROOMERS--|',
  Anker: '|ANKER--|',
  'Art Sets': '|ART SETS--|',
  'Art Stencils': '|ART STENCILS--|',
  'Artisan Cheese & Cured Meats': '|ARTISAN CHEESE & CURED MEATS--|',
  'Athletic Works Shoes': '|ATHLETIC WORKS SHOES--|',
  'Baby Care Treatments': '|BABY CARE TREATMENTS--|',
  'Baby Grooming': '|BABY GROOMING--|',
  'Baby Shower': '|BABY SHOWER--|',
  'Baby Tubs': '|BABY TUBS--|',
  'Back to School Lunch Ideas': '|BACK TO SCHOOL LUNCH IDEAS--|',
  'Back to School Shower Caddy Essentials':
    '|BACK TO SCHOOL SHOWER CADDY ESSENTIALS--|',
  'Backyard Camping': '|BACKYARD CAMPING--|',
  'Bags & Containers': '|BAGS & CONTAINERS--|',
  'Baking Bars & Chips': '|BAKING BARS & CHIPS--|',
  'Barbie Chelsea': '|BARBIE CHELSEA--|',
  'Barbie Fashionistas': '|BARBIE FASHIONISTAS--|',
  'Baseball Apparel': '|BASEBALL APPAREL--|',
  'Bathroom Cleaning': '|BATHROOM CLEANING--|',
  'batteries, chargers, and adapters': '|BATTERIES, CHARGERS, AND ADAPTERS--|',
  'BB & Pellet Gun Accessories': '|BB & PELLET GUN ACCESSORIES--|',
  BBQ: '|BBQ--|',
  'Beard Trimmers': '|BEARD TRIMMERS--|',
  'Beauty Clean Living Shop': '|BEAUTY CLEAN LIVING SHOP--|',
  'Beauty Face Masks': '|BEAUTY FACE MASKS--|',
  'Beauty/Fragrances/All Fragrances': '|BEAUTY/FRAGRANCES/ALL FRAGRANCES--|',
  'Bed & Bath': '|BED & BATH--|',
  'Beef Jerky': '|BEEF JERKY--|',
  'Beverages > Beer': '|BEVERAGES-BEER-|',
  'Big Boys Casual Outfit Sets': '|BIG BOYS CASUAL OUTFIT SETS--|',
  'Birthday Greeting Cards & Gift Wrap':
    '|BIRTHDAY GREETING CARDS & GIFT WRAP--|',
  'Black + Decker Toasters': '|BLACK + DECKER TOASTERS--|',
  'Black Mascara': '|BLACK MASCARA--|',
  Blackberries: '|BLACKBERRIES--|',
  Boards: '|BOARDS--|',
  'Bobble Heads': '|BOBBLE HEADS--|',
  'Bod Man': '|BOD MAN--|',
  'Body Fantasies': '|BODY FANTASIES--|',
  'Body Lotion': '|BODY LOTION--|',
  'Bones & Rawhides': '|BONES & RAWHIDES--|',
  Bounce: '|BOUNCE--|',
  'Bouncers & Rockers': '|BOUNCERS & ROCKERS--|',
  'Boys Socks': '|BOYS SOCKS--|',
  "Boys' Bedding": "|BOYS' BEDDING--|",
  "Boys' Halloween Costumes": "|BOYS' HALLOWEEN COSTUMES--|",
  Bracelets: '|BRACELETS--|',
  'Bread Aisle': '|BREAD AISLE--|',
  'Breakfast Anytime': '|BREAKFAST ANYTIME--|',
  'Broccoli & Cauliflower': '|BROCCOLI & CAULIFLOWER--|',
  Buckets: '|BUCKETS--|',
  'Building Sets & Blocks': '|BUILDING SETS & BLOCKS--|',
  'Bulbs & Lamps': '|BULBS & LAMPS--|',
  "Burt's Bees Lip Balm": "|BURT'S BEES LIP BALM--|",
  'Butter & Club Crackers': '|BUTTER & CLUB CRACKERS--|',
  'Butter and Yellow Cake Mix': '|BUTTER AND YELLOW CAKE MIX--|',
  'Cable Ties': '|CABLE TIES--|',
  Cakes: '|CAKES--|',
  'Cakes & Pies': '|CAKES & PIES--|',
  Calendars: '|CALENDARS--|',
  'Camera Film': '|CAMERA FILM--|',
  'Canon Holiday Printers': '|CANON HOLIDAY PRINTERS--|',
  'Car Interior Cleaners': '|CAR INTERIOR CLEANERS--|',
  'Car Leather, Vinyl & Upholstery Cleaners':
    '|CAR LEATHER, VINYL & UPHOLSTERY CLEANERS--|',
  'Car Power Adapters': '|CAR POWER ADAPTERS--|',
  'Cargo Mats and Liners': '|CARGO MATS AND LINERS--|',
  Cashews: '|CASHEWS--|',
  'Cat Teasers & Wands': '|CAT TEASERS & WANDS--|',
  'Ceiling Fans': '|CEILING FANS--|',
  'Cesar Wet Dog Food': '|CESAR WET DOG FOOD--|',
  'Charcoal & Lighter Fluid': '|CHARCOAL & LIGHTER FLUID--|',
  'Checked Suitcases': '|CHECKED SUITCASES--|',
  'Cheese Snacks': '|CHEESE SNACKS--|',
  'Cheetos Crunchy': '|CHEETOS CRUNCHY--|',
  'Chicken Legs, Thighs & Wings': '|CHICKEN LEGS, THIGHS & WINGS--|',
  'Chocolate and Yogurt Covered Pretzels':
    '|CHOCOLATE AND YOGURT COVERED PRETZELS--|',
  'Chopping & Slicing': '|CHOPPING & SLICING--|',
  'Christmas Candy': '|CHRISTMAS CANDY--|',
  'Christmas Ribbons & Bows': '|CHRISTMAS RIBBONS & BOWS--|',
  'Christmas Tree Stands & Skirts': '|CHRISTMAS TREE STANDS & SKIRTS--|',
  'Chutneys & Relishes': '|CHUTNEYS & RELISHES--|',
  'Cinder Blocks': '|CINDER BLOCKS--|',
  'Clamp On, Hand Helds & Stand-Up': '|CLAMP ON, HAND HELDS & STAND-UP--|',
  'Claw Hammers': '|CLAW HAMMERS--|',
  'Cleaning & Maintenance Supplies': '|CLEANING & MAINTENANCE SUPPLIES--|',
  'Cleaning Equipment': '|CLEANING EQUIPMENT--|',
  'Clearance and Rollback Hair Products':
    '|CLEARANCE AND ROLLBACK HAIR PRODUCTS--|',
  'Clothing > Girls > Bottoms': '|CLOTHING-GIRLS-BOTTOMS|',
  'Clothing > Womens > Dresses': '|CLOTHING-WOMENS-DRESSES|',
  'Clothing > Womens > Sleepwear': '|CLOTHING-WOMENS-SLEEPWEAR|',
  'Clothing Racks': '|CLOTHING RACKS--|',
  'Cocktail Mixers': '|COCKTAIL MIXERS--|',
  'Coffee Tea Cocoa': '|COFFEE TEA COCOA--|',
  'Coily Hair Care': '|COILY HAIR CARE--|',
  'College Hair Essentials': '|COLLEGE HAIR ESSENTIALS--|',
  'College Makeup Mirrors': '|COLLEGE MAKEUP MIRRORS--|',
  Colognes: '|COLOGNES--|',
  'Conduit Fittings': '|CONDUIT FITTINGS--|',
  'Contact Lens Solution': '|CONTACT LENS SOLUTION--|',
  'Convertible Car Seats': '|CONVERTIBLE CAR SEATS--|',
  Coolers: '|COOLERS--|',
  'Craft Adhesives & Glues': '|CRAFT ADHESIVES & GLUES--|',
  'Craft Beer': '|CRAFT BEER--|',
  'Crossbody Bags': '|CROSSBODY BAGS--|',
  Dartboards: '|DARTBOARDS--|',
  'Decoration Kits': '|DECORATION KITS--|',
  'Desk Lamps': '|DESK LAMPS--|',
  'Diapering Best Sellers': '|DIAPERING BEST SELLERS--|',
  'Digestion & Stomach': '|DIGESTION & STOMACH--|',
  'Dinner Steakhouse Salads': '|DINNER STEAKHOUSE SALADS--|',
  'Dips & Sauces': '|DIPS & SAUCES--|',
  'Dish Soap & Detergent': '|DISH SOAP & DETERGENT--|',
  'Disposable Gloves': '|DISPOSABLE GLOVES--|',
  'Dog Beds': '|DOG BEDS--|',
  'Dog Biscuits, Cookies & Snacks': '|DOG BISCUITS, COOKIES & SNACKS--|',
  'Dog Rope & Tug Toys': '|DOG ROPE & TUG TOYS--|',
  'Dog Waste Disposal': '|DOG WASTE DISPOSAL--|',
  'Dog Waste Management': '|DOG WASTE MANAGEMENT--|',
  'Door Knobs': '|DOOR KNOBS--|',
  'Door Mats': '|DOOR MATS--|',
  'Dorm Bath Essentials': '|DORM BATH ESSENTIALS--|',
  'Dragon Toys': '|DRAGON TOYS--|',
  'Drip Pans & Trim Rings': '|DRIP PANS & TRIM RINGS--|',
  Drops: '|DROPS--|',
  'DVD & Blu-ray Players': '|DVD & BLU-RAY PLAYERS--|',
  'DVD, VCR Players and Set-Top Boxes':
    '|DVD, VCR PLAYERS AND SET-TOP BOXES--|',
  'Earbuds & Headphones': '|EARBUDS & HEADPHONES--|',
  'Easter Stuffed Animals': '|EASTER STUFFED ANIMALS--|',
  Elastics: '|ELASTICS--|',
  'Electronic Games': '|ELECTRONIC GAMES--|',
  'Electronics > Photo Shop > Cameras': '|ELECTRONICS-PHOTO SHOP-CAMERAS|',
  'Equate First Aid Tools & Supplies': '|EQUATE FIRST AID TOOLS & SUPPLIES--|',
  'Equate Multivitamins': '|EQUATE MULTIVITAMINS--|',
  'Exterior Wall and Trim Paint': '|EXTERIOR WALL AND TRIM PAINT--|',
  'Extra Chewing Gum': '|EXTRA CHEWING GUM--|',
  'Eyebrow Pencil': '|EYEBROW PENCIL--|',
  'Fabric Deodorizers': '|FABRIC DEODORIZERS--|',
  'Face Masks & Coverings': '|FACE MASKS & COVERINGS--|',
  'Face Toners For Normal Skin': '|FACE TONERS FOR NORMAL SKIN--|',
  'Facial Tissue & Wipes': '|FACIAL TISSUE & WIPES--|',
  'Facial Treatments for Acne and Blemishes':
    '|FACIAL TREATMENTS FOR ACNE AND BLEMISHES--|',
  'Fashion Jewelry Sets': '|FASHION JEWELRY SETS--|',
  "Father's Day Electronics": "|FATHER'S DAY ELECTRONICS--|",
  'Felt Tip Pens': '|FELT TIP PENS--|',
  'Fish tanks, Aquariums & Bowls': '|FISH TANKS, AQUARIUMS & BOWLS--|',
  'Fishing Clothing': '|FISHING CLOTHING--|',
  'Fishing Floats': '|FISHING FLOATS--|',
  'Fishing Gloves & Accessories': '|FISHING GLOVES & ACCESSORIES--|',
  'Fishing Sinkers': '|FISHING SINKERS--|',
  'Flamin Hot Cheetos': '|FLAMIN HOT CHEETOS--|',
  'Flavored Tea': '|FLAVORED TEA--|',
  'Flavorful Favorites': '|FLAVORFUL FAVORITES--|',
  'Flush Mount Lights': '|FLUSH MOUNT LIGHTS--|',
  'Foil and Baking Paper': '|FOIL AND BAKING PAPER--|',
  'Food > Breakfast > Muffins': '|FOOD-BREAKFAST-MUFFINS|',
  'Food/Baking/Baking Mixes': '|FOOD/BAKING/BAKING MIXES--|',
  'Food/Chocolate, Candy & Gum/Jelly Beans & Gummy Candy/Gummy Candy':
    '|FOOD/CHOCOLATE, CANDY & GUM/JELLY BEANS & GUMMY CANDY/GUMMY CANDY--|',
  'Food/Condiments, Sauces & Spices/Jam, Jelly & Preserves':
    '|FOOD/CONDIMENTS, SAUCES & SPICES/JAM, JELLY & PRESERVES--|',
  'Food/Condiments, Sauces & Spices/Oil & Shortening':
    '|FOOD/CONDIMENTS, SAUCES & SPICES/OIL & SHORTENING--|',
  'Food/Condiments, Sauces & Spices/Sauces':
    '|FOOD/CONDIMENTS, SAUCES & SPICES/SAUCES--|',
  'Food/Meal Solutions, Grains & Pasta/Canned Goods/Canned Ready to Eat Meals':
    '|FOOD/MEAL SOLUTIONS, GRAINS & PASTA/CANNED GOODS/CANNED READY TO EAT MEALS--|',
  'Food/Snacks, Cookies & Chips/Pudding & Gelatin':
    '|FOOD/SNACKS, COOKIES & CHIPS/PUDDING & GELATIN--|',
  'Food/Snacks, Cookies & Chips/Snack Cakes':
    '|FOOD/SNACKS, COOKIES & CHIPS/SNACK CAKES--|',
  'Foot Care Deals': '|FOOT CARE DEALS--|',
  'Forms & Recordkeeping': '|FORMS & RECORDKEEPING--|',
  'Forti-Diet Pet Supplies': '|FORTI-DIET PET SUPPLIES--|',
  'Fortnite Action Figures': '|FORTNITE ACTION FIGURES--|',
  'Fresh Baked Goods': '|FRESH BAKED GOODS--|',
  'Fresh Fit for Kidsâ„¢ Meal': '|FRESH FIT FOR KIDSÂ„¢ MEAL--|',
  'Frozen Waffles & French Toast': '|FROZEN WAFFLES & FRENCH TOAST--|',
  'Fruit & Dessert': '|FRUIT & DESSERT--|',
  'Fruit Smoothies': '|FRUIT SMOOTHIES--|',
  'Full Air Mattresses': '|FULL AIR MATTRESSES--|',
  'Full Sheet': '|FULL SHEET--|',
  'Garden Soil': '|GARDEN SOIL--|',
  'Gas Relief': '|GAS RELIEF--|',
  GE: '|GE--|',
  'Get the Look- Pineapple Hair - Accessories':
    '|GET THE LOOK- PINEAPPLE HAIR - ACCESSORIES--|',
  'Gift Ideas For Teens': '|GIFT IDEAS FOR TEENS--|',
  "Girls' Hats & Gloves": "|GIRLS' HATS & GLOVES--|",
  'Glad Trash Bags': '|GLAD TRASH BAGS--|',
  'Grab & Go': '|GRAB & GO--|',
  'Graters, Peelers & Slicers': '|GRATERS, PEELERS & SLICERS--|',
  'Greek & High Protein Yogurt': '|GREEK & HIGH PROTEIN YOGURT--|',
  'Green Tea': '|GREEN TEA--|',
  'Grill Tools': '|GRILL TOOLS--|',
  Grits: '|GRITS--|',
  'Grocery > Snacks > Bulk - Granola & Snacks':
    '|GROCERY-SNACKS-BULK - GRANOLA & SNACKS|',
  'Gummy Vitamins': '|GUMMY VITAMINS--|',
  'Gun Holsters': '|GUN HOLSTERS--|',
  'Hair Ties': '|HAIR TIES--|',
  Hairspray: '|HAIRSPRAY--|',
  'Hand Sanitizers': '|HAND SANITIZERS--|',
  'Hanging File Folders': '|HANGING FILE FOLDERS--|',
  'Hard Toilet Seat': '|HARD TOILET SEAT--|',
  Hasps: '|HASPS--|',
  'Healthcare Instruments': '|HEALTHCARE INSTRUMENTS--|',
  'Heat Presses & Accessories': '|HEAT PRESSES & ACCESSORIES--|',
  'High Chairs & Booster Seats': '|HIGH CHAIRS & BOOSTER SEATS--|',
  'High Efficient Liquid Laundry Detergent':
    '|HIGH EFFICIENT LIQUID LAUNDRY DETERGENT--|',
  'Holiday Time Pet Supplies': '|HOLIDAY TIME PET SUPPLIES--|',
  'Hollow & Drywall Anchors': '|HOLLOW & DRYWALL ANCHORS--|',
  'Home & Garden > Floral': '|HOME & GARDEN-FLORAL-|',
  'Home Security': '|HOME SECURITY--|',
  'Hot Chocolate': '|HOT CHOCOLATE--|',
  'Hot Wheels': '|HOT WHEELS--|',
  'Household Batteries': '|HOUSEHOLD BATTERIES--|',
  'Household Essentials/Bathroom/Air Freshener & Deodorizer Refills':
    '|HOUSEHOLD ESSENTIALS/BATHROOM/AIR FRESHENER & DEODORIZER REFILLS--|',
  'Household Essentials/Laundry/Liquid Fabric Softeners':
    '|HOUSEHOLD ESSENTIALS/LAUNDRY/LIQUID FABRIC SOFTENERS--|',
  'HSA Eligible Pain Relievers': '|HSA ELIGIBLE PAIN RELIEVERS--|',
  'Ice Cube Trays': '|ICE CUBE TRAYS--|',
  'Ice Scrapers': '|ICE SCRAPERS--|',
  'Iced Tea': '|ICED TEA--|',
  'Icy Hot': '|ICY HOT--|',
  'Imported Beer': '|IMPORTED BEER--|',
  'Incandescent Christmas Lights': '|INCANDESCENT CHRISTMAS LIGHTS--|',
  'Interactive Toys': '|INTERACTIVE TOYS--|',
  'Interior Stain': '|INTERIOR STAIN--|',
  'Interior Stains': '|INTERIOR STAINS--|',
  'iPhone 11 Pro Cases': '|IPHONE 11 PRO CASES--|',
  'Irish Spring Bar Soap': '|IRISH SPRING BAR SOAP--|',
  'Jackets & Outerwear': '|JACKETS & OUTERWEAR--|',
  'Jasons Wraps': '|JASONS WRAPS--|',
  'Jerky & Meat Sticks': '|JERKY & MEAT STICKS--|',
  'Johnson & Johnson Baby Registry Collection':
    '|JOHNSON & JOHNSON BABY REGISTRY COLLECTION--|',
  Journals: '|JOURNALS--|',
  Joy: '|JOY--|',
  'Juice Blends': '|JUICE BLENDS--|',
  'Juniors Activewear Leggings, Pants & Capris':
    '|JUNIORS ACTIVEWEAR LEGGINGS, PANTS & CAPRIS--|',
  'Juniors Tank Tops': '|JUNIORS TANK TOPS--|',
  'Key Blanks': '|KEY BLANKS--|',
  "Kid's Little Bo Box Meals (12 and Under)":
    "|KID'S LITTLE BO BOX MEALS (12 AND UNDER)--|",
  "Kids' Comforters": "|KIDS' COMFORTERS--|",
  "Kids' Décor": "|KIDS' DÉCOR--|",
  "Kids' Lighting": "|KIDS' LIGHTING--|",
  "Kids' Multivitamins": "|KIDS' MULTIVITAMINS--|",
  "Kids' Portable Audio": "|KIDS' PORTABLE AUDIO--|",
  'King Mattresses': '|KING MATTRESSES--|',
  'Kitchen & Cooking Utensil Sets': '|KITCHEN & COOKING UTENSIL SETS--|',
  'Kitchen Accessories': '|KITCHEN ACCESSORIES--|',
  'Kitchen Appliances': '|KITCHEN APPLIANCES--|',
  'kitchen measuring tools': '|KITCHEN MEASURING TOOLS--|',
  'Lamp Bases': '|LAMP BASES--|',
  'Latino Foods': '|LATINO FOODS--|',
  'Laundry Additives': '|LAUNDRY ADDITIVES--|',
  'Laundry Sanitizers': '|LAUNDRY SANITIZERS--|',
  'Letter Vitamins': '|LETTER VITAMINS--|',
  'Lifestyle Sneakers': '|LIFESTYLE SNEAKERS--|',
  'Light Switches': '|LIGHT SWITCHES--|',
  'Lighters & Matches': '|LIGHTERS & MATCHES--|',
  Lights: '|LIGHTS--|',
  'Litter & Bedding': '|LITTER & BEDDING--|',
  'Little Girls Socks': '|LITTLE GIRLS SOCKS--|',
  'Livestock Feed': '|LIVESTOCK FEED--|',
  'Luxury Baby Tubs': '|LUXURY BABY TUBS--|',
  'Makeup Brush Sets': '|MAKEUP BRUSH SETS--|',
  'Makeup Brushes': '|MAKEUP BRUSHES--|',
  'Markers & Highlighters': '|MARKERS & HIGHLIGHTERS--|',
  Mats: '|MATS--|',
  Melatonin: '|MELATONIN--|',
  "Men's Facial Care": "|MEN'S FACIAL CARE--|",
  "Men's Multivitamins": "|MEN'S MULTIVITAMINS--|",
  "Men's Shorts": "|MEN'S SHORTS--|",
  "Men's Underwear": "|MEN'S UNDERWEAR--|",
  'Mens Athletic Shoes': '|MENS ATHLETIC SHOES--|',
  'Mens Big & Tall Everyday Socks': '|MENS BIG & TALL EVERYDAY SOCKS--|',
  'Mens New Sweaters': '|MENS NEW SWEATERS--|',
  'Mens Pajama Sets': '|MENS PAJAMA SETS--|',
  'Mens Underwear': '|MENS UNDERWEAR--|',
  Menthol: '|MENTHOL--|',
  'Meow Mix Wet Cat Food': '|MEOW MIX WET CAT FOOD--|',
  'Microfiber Cloths': '|MICROFIBER CLOTHS--|',
  'Mindful Beauty Cosmetics': '|MINDFUL BEAUTY COSMETICS--|',
  'Model Train Sets': '|MODEL TRAIN SETS--|',
  Mortar: '|MORTAR--|',
  Moscato: '|MOSCATO--|',
  'Mr. Potato Head': '|MR. POTATO HEAD--|',
  'Muffins and Donuts': '|MUFFINS AND DONUTS--|',
  'Multipet Dog Toys': '|MULTIPET DOG TOYS--|',
  'Multipurpose Adhesive': '|MULTIPURPOSE ADHESIVE--|',
  'My Sweet Love Baby Dolls': '|MY SWEET LOVE BABY DOLLS--|',
  'Nail Polish Remover': '|NAIL POLISH REMOVER--|',
  'Nerf Fortnite Blasters': '|NERF FORTNITE BLASTERS--|',
  'New and Trending': '|NEW AND TRENDING--|',
  'New Skin Care': '|NEW SKIN CARE--|',
  'Nightgowns & Sleep Shirts': '|NIGHTGOWNS & SLEEP SHIRTS--|',
  'NUK Sippy Cups': '|NUK SIPPY CUPS--|',
  'Nutro Wet Dog Food': '|NUTRO WET DOG FOOD--|',
  'Nuts & Trail Mix': '|NUTS & TRAIL MIX--|',
  'Oils & Fluids': '|OILS & FLUIDS--|',
  'Oils & Sprays': '|OILS & SPRAYS--|',
  'Oils, Lubricants & Additives': '|OILS, LUBRICANTS & ADDITIVES--|',
  'Oils, Vinegar & Shortening': '|OILS, VINEGAR & SHORTENING--|',
  'Ointments & Liquids': '|OINTMENTS & LIQUIDS--|',
  'Old Spice 2-in-1 Shampoo Conditioner':
    '|OLD SPICE 2-IN-1 SHAMPOO CONDITIONER--|',
  'On-the-Go Snacks': '|ON-THE-GO SNACKS--|',
  'Only at Walmart Electronics': '|ONLY AT WALMART ELECTRONICS--|',
  'onn. Cable Connectors and Splitters':
    '|ONN. CABLE CONNECTORS AND SPLITTERS--|',
  'onn. Tablets & Laptops': '|ONN. TABLETS & LAPTOPS--|',
  'OrangeJulius Smoothies': '|ORANGEJULIUS SMOOTHIES--|',
  'Oranges & Citrus': '|ORANGES & CITRUS--|',
  'Other Restaurant Plastic Gift Cards':
    '|OTHER RESTAURANT PLASTIC GIFT CARDS--|',
  "Other Women's Halloween Costumes": "|OTHER WOMEN'S HALLOWEEN COSTUMES--|",
  Others: '|OTHERS--|',
  'Outdoor Games': '|OUTDOOR GAMES--|',
  'Outlet Wall Plates': '|OUTLET WALL PLATES--|',
  'Oven Mitts & Pot Holders': '|OVEN MITTS & POT HOLDERS--|',
  'Ozark Trail Camping Accessories': '|OZARK TRAIL CAMPING ACCESSORIES--|',
  'Pacifiers and Teethers': '|PACIFIERS AND TEETHERS--|',
  'Packing Paper': '|PACKING PAPER--|',
  'Pads & Liners': '|PADS & LINERS--|',
  'Paint Applicator Kits': '|PAINT APPLICATOR KITS--|',
  'Paint Sealers': '|PAINT SEALERS--|',
  'Pajama Tops': '|PAJAMA TOPS--|',
  'Party Hats': '|PARTY HATS--|',
  'Pellets & BBs': '|PELLETS & BBS--|',
  'Pellets and Fish Flakes': '|PELLETS AND FISH FLAKES--|',
  'Pencil Erasers & Pen Erasers': '|PENCIL ERASERS & PEN ERASERS--|',
  'Pepper Jack Cheese': '|PEPPER JACK CHEESE--|',
  'Pepsi Beverages': '|PEPSI BEVERAGES--|',
  'Personal Care/Bath & Body/Deodorants & Antiperspirants':
    '|PERSONAL CARE/BATH & BODY/DEODORANTS & ANTIPERSPIRANTS--|',
  'Personal Care/Hair Care/Shampoo': '|PERSONAL CARE/HAIR CARE/SHAMPOO--|',
  'Pets/Dogs/Dog Food': '|PETS/DOGS/DOG FOOD--|',
  'Pickles Peppers Olives': '|PICKLES PEPPERS OLIVES--|',
  'Pie Dishes & Tart Pans': '|PIE DISHES & TART PANS--|',
  'Pie Dough & Crust': '|PIE DOUGH & CRUST--|',
  'Pizza Crust': '|PIZZA CRUST--|',
  'Plastic Baby Bottles': '|PLASTIC BABY BOTTLES--|',
  'Play Kitchens': '|PLAY KITCHENS--|',
  'Play Sand': '|PLAY SAND--|',
  'Play Vehicles by Price': '|PLAY VEHICLES BY PRICE--|',
  'PlayStation 4 (PS4) Controllers': '|PLAYSTATION 4 (PS4) CONTROLLERS--|',
  'PlayStation 4 (PS4) Games': '|PLAYSTATION 4 (PS4) GAMES--|',
  Plumbing: '|PLUMBING--|',
  PopSockets: '|POPSOCKETS--|',
  'Postage Stamps': '|POSTAGE STAMPS--|',
  'Potty Training': '|POTTY TRAINING--|',
  'Power Sander Accessories': '|POWER SANDER ACCESSORIES--|',
  'Power Sources': '|POWER SOURCES--|',
  'Power Strips': '|POWER STRIPS--|',
  'Power Tools': '|POWER TOOLS--|',
  'Pregnancy & Ovulation Tests': '|PREGNANCY & OVULATION TESTS--|',
  'Premium Kitten Food': '|PREMIUM KITTEN FOOD--|',
  'Premium Perfume': '|PREMIUM PERFUME--|',
  'Premium Salads': '|PREMIUM SALADS--|',
  'Prepared Cocktails': '|PREPARED COCKTAILS--|',
  'Presentation Boards': '|PRESENTATION BOARDS--|',
  'Pressure Cookers': '|PRESSURE COOKERS--|',
  'pretend battle toys': '|PRETEND BATTLE TOYS--|',
  'Pretzel Sticks': '|PRETZEL STICKS--|',
  'Projectors & Presentation Equipment':
    '|PROJECTORS & PRESENTATION EQUIPMENT--|',
  'Protein Powders': '|PROTEIN POWDERS--|',
  'Purex Scent Boosters': '|PUREX SCENT BOOSTERS--|',
  'Rain Umbrellas': '|RAIN UMBRELLAS--|',
  'Ready Mix Concrete': '|READY MIX CONCRETE--|',
  'Red Sauce': '|RED SAUCE--|',
  'Reduce Pain': '|REDUCE PAIN--|',
  'Refrigerated Baked Goods': '|REFRIGERATED BAKED GOODS--|',
  'Reign Energy Drinks': '|REIGN ENERGY DRINKS--|',
  'Religious Candles': '|RELIGIOUS CANDLES--|',
  'Rice Beans': '|RICE BEANS--|',
  'Rice, Potatoes & Stuffing': '|RICE, POTATOES & STUFFING--|',
  'Roblox Toys': '|ROBLOX TOYS--|',
  'Rope & Chain Connectors': '|ROPE & CHAIN CONNECTORS--|',
  "Ryan's World Toys": "|RYAN'S WORLD TOYS--|",
  'Safety and Security': '|SAFETY AND SECURITY--|',
  'Saline Wound Wash': '|SALINE WOUND WASH--|',
  'Salon Perfect Eyelashes': '|SALON PERFECT EYELASHES--|',
  'Salon Perfect Nails': '|SALON PERFECT NAILS--|',
  'Saltwater Cleaning & Maintenance': '|SALTWATER CLEANING & MAINTENANCE--|',
  'School Project Boards': '|SCHOOL PROJECT BOARDS--|',
  'Scrubs & Treatments': '|SCRUBS & TREATMENTS--|',
  'Shakes & Smoothies': '|SHAKES & SMOOTHIES--|',
  'Shea Moisture Facial Care': '|SHEA MOISTURE FACIAL CARE--|',
  'Sheer Curtains': '|SHEER CURTAINS--|',
  'Shelf Liners': '|SHELF LINERS--|',
  'Shelving Brackets & Accessories': '|SHELVING BRACKETS & ACCESSORIES--|',
  Shims: '|SHIMS--|',
  'Shop All Air Fryers': '|SHOP ALL AIR FRYERS--|',
  'Shop All Microwaves': '|SHOP ALL MICROWAVES--|',
  'Shop Nature Valley': '|SHOP NATURE VALLEY--|',
  'Shop Notebooks by Brand': '|SHOP NOTEBOOKS BY BRAND--|',
  'Shredded Cheeses': '|SHREDDED CHEESES--|',
  'Signature Flavors': '|SIGNATURE FLAVORS--|',
  'Silverware & Cutlery': '|SILVERWARE & CUTLERY--|',
  'Single Curtain Rods': '|SINGLE CURTAIN RODS--|',
  'Single Serve & Pods': '|SINGLE SERVE & PODS--|',
  'Skin Supplements': '|SKIN SUPPLEMENTS--|',
  'Small Animal Hay & Grass': '|SMALL ANIMAL HAY & GRASS--|',
  'Smart TVs': '|SMART TVS--|',
  Smoothies: '|SMOOTHIES--|',
  'Snack Menu': '|SNACK MENU--|',
  'Snuggle Liquid Fabric Softener': '|SNUGGLE LIQUID FABRIC SOFTENER--|',
  'Socks & Shoes': '|SOCKS & SHOES--|',
  'Soda, Juices, Ice': '|SODA, JUICES, ICE--|',
  Softener: '|SOFTENER--|',
  'Solar Landscape Lights': '|SOLAR LANDSCAPE LIGHTS--|',
  'Soldering Accessories': '|SOLDERING ACCESSORIES--|',
  'Soup & Stockpots': '|SOUP & STOCKPOTS--|',
  'Soup and Side Salads': '|SOUP AND SIDE SALADS--|',
  'Soup, Broth & Bouillon': '|SOUP, BROTH & BOUILLON--|',
  'Soup, Broth & Chili': '|SOUP, BROTH & CHILI--|',
  'Soup, Salad, & Breadsticks': '|SOUP, SALAD, & BREADSTICKS--|',
  'Special Kitty Cat Treats': '|SPECIAL KITTY CAT TREATS--|',
  'Specialties Beyond Steak': '|SPECIALTIES BEYOND STEAK--|',
  'Specialty Glue': '|SPECIALTY GLUE--|',
  'Specialty Laundry': '|SPECIALTY LAUNDRY--|',
  'Specialty Meats': '|SPECIALTY MEATS--|',
  'Spiked Seltzers': '|SPIKED SELTZERS--|',
  Sponges: '|SPONGES--|',
  'Sponges & Gloves': '|SPONGES & GLOVES--|',
  'Sports & Energy': '|SPORTS & ENERGY--|',
  'sports and nutritional drinks': '|SPORTS AND NUTRITIONAL DRINKS--|',
  'Stage 2 Gerber Baby Food': '|STAGE 2 GERBER BABY FOOD--|',
  'Stain Remover & Bleach': '|STAIN REMOVER & BLEACH--|',
  'Stain Remover & Boosters': '|STAIN REMOVER & BOOSTERS--|',
  Steak: '|STEAK--|',
  'Stock up on Cleaning Supplies': '|STOCK UP ON CLEANING SUPPLIES--|',
  'Stock up on Makeup': '|STOCK UP ON MAKEUP--|',
  'Storage & Organization': '|STORAGE & ORGANIZATION--|',
  'String Trimmer Line': '|STRING TRIMMER LINE--|',
  'Stud Finders': '|STUD FINDERS--|',
  Styling: '|STYLING--|',
  'Super Bulky Weight Yarn': '|SUPER BULKY WEIGHT YARN--|',
  'Sweet Biscuits and More': '|SWEET BISCUITS AND MORE--|',
  'Target > Beauty > Makeup > Eyes > Eyebrow Enhancers':
    '|TARGET > BEAUTY > MAKEUP > EYES > EYEBROW ENHANCERS--|',
  'Target > Beauty > Makeup > Eyes > Eyeshadow':
    '|TARGET > BEAUTY > MAKEUP > EYES > EYESHADOW--|',
  'Target > Beauty > Makeup > Lips > Lip Gloss':
    '|TARGET > BEAUTY > MAKEUP > LIPS > LIP GLOSS--|',
  'Target > Home > Home Decor > Candles & Candle Holders > Candles':
    '|TARGET > HOME > HOME DECOR > CANDLES & CANDLE HOLDERS > CANDLES--|',
  'Target > Household Essentials > Cleaning Supplies > All-purpose Cleaners':
    '|TARGET > HOUSEHOLD ESSENTIALS > CLEANING SUPPLIES > ALL-PURPOSE CLEANERS--|',
  'Target > Party Supplies > Gift Wrap, Bags & Accessories':
    '|TARGET > PARTY SUPPLIES > GIFT WRAP, BAGS & ACCESSORIES--|',
  'Target > Target Next Day Delivery > Shop All Target Next Day Delivery > School & Office Supplies > Arts & Crafts':
    '|TARGET > TARGET NEXT DAY DELIVERY > SHOP ALL TARGET NEXT DAY DELIVERY > SCHOOL & OFFICE SUPPLIES > ARTS & CRAFTS--|',
  'Targeted Facial Treatments': '|TARGETED FACIAL TREATMENTS--|',
  ' Cigarettes': '| CIGARETTES--|',
  ' Other Tobacco': '| OTHER TOBACCO--|',
  '$25 and Under': '|$25 AND UNDER--|',
  '2-Way Radios': '|2-WAY RADIOS--|',
  'Accessories Character Shop': '|ACCESSORIES CHARACTER SHOP--|',
  'Action Drama Movies': '|ACTION DRAMA MOVIES--|',
  'Active Pants': '|ACTIVE PANTS--|',
  'Adaptors & Chargers': '|ADAPTORS & CHARGERS--|',
  'Adult Backpacks': '|ADULT BACKPACKS--|',
  'AF Top Items': '|AF TOP ITEMS--|',
  'Air Pumps': '|AIR PUMPS--|',
  Algaecide: '|ALGAECIDE--|',
  'All Accessories': '|ALL ACCESSORIES--|',
  'All Action Role Play': '|ALL ACTION ROLE PLAY--|',
  'All Activewear': '|ALL ACTIVEWEAR--|',
  'All Camping Furniture': '|ALL CAMPING FURNITURE--|',
  'All Departments > Grocery > Meals': '|ALL DEPARTMENTS-GROCERY-MEALS|',
  'All Departments > Household Essentials > Paper & Plastic':
    '|ALL DEPARTMENTS-HOUSEHOLD ESSENTIALS-PAPER & PLASTIC|',
  'All Dog Beds': '|ALL DOG BEDS--|',
  'All Fire Safety': '|ALL FIRE SAFETY--|',
  'All Floral Arranging Supplies': '|ALL FLORAL ARRANGING SUPPLIES--|',
  'All Fresh Fruit': '|ALL FRESH FRUIT--|',
  'All Home Safes & Lock Boxes': '|ALL HOME SAFES & LOCK BOXES--|',
  'All Hunting Clothing': '|ALL HUNTING CLOTHING--|',
  'All Indoor Tubs and Whirlpools': '|ALL INDOOR TUBS AND WHIRLPOOLS--|',
  'All Journals': '|ALL JOURNALS--|',
  'All Minnie Mouse Toys': '|ALL MINNIE MOUSE TOYS--|',
  'All Notebooks': '|ALL NOTEBOOKS--|',
  'All PC Headsets': '|ALL PC HEADSETS--|',
  'All Preschool Action Figures & Playsets':
    '|ALL PRESCHOOL ACTION FIGURES & PLAYSETS--|',
  'All Protein Supplements': '|ALL PROTEIN SUPPLEMENTS--|',
  'All Sci-Fi & Fantasy': '|ALL SCI-FI & FANTASY--|',
  'All Scooters': '|ALL SCOOTERS--|',
  'All Setting Spray': '|ALL SETTING SPRAY--|',
  'All Star Wars Clothing': '|ALL STAR WARS CLOTHING--|',
  'All Wet Cat Food': '|ALL WET CAT FOOD--|',
  'All-in-One Printers': '|ALL-IN-ONE PRINTERS--|',
  'Alternative Rawhide': '|ALTERNATIVE RAWHIDE--|',
  'Animated Drama Movies': '|ANIMATED DRAMA MOVIES--|',
  'Anti-Theft Devices': '|ANTI-THEFT DEVICES--|',
  Antidiarrheals: '|ANTIDIARRHEALS--|',
  'Antifungal Creams': '|ANTIFUNGAL CREAMS--|',
  'Antiseptics & Anti-Itch': '|ANTISEPTICS & ANTI-ITCH--|',
  'Apple Cider': '|APPLE CIDER--|',
  'Apple Watch Bands': '|APPLE WATCH BANDS--|',
  Applicators: '|APPLICATORS--|',
  'Aquarium Ornaments': '|AQUARIUM ORNAMENTS--|',
  'Aquarium Plants': '|AQUARIUM PLANTS--|',
  AriZona: '|ARIZONA--|',
  'Arm and Hammer Liquid Laundry Detergent':
    '|ARM AND HAMMER LIQUID LAUNDRY DETERGENT--|',
  'Aroma Rice Cookers': '|AROMA RICE COOKERS--|',
  'As Seen on TV Health & Beauty': '|AS SEEN ON TV HEALTH & BEAUTY--|',
  'Athletic Tape': '|ATHLETIC TAPE--|',
  'Auto Maintenance': '|AUTO MAINTENANCE--|',
  'Automatic Air Freshener Dispensers':
    '|AUTOMATIC AIR FRESHENER DISPENSERS--|',
  'Automotive Bulk Buys': '|AUTOMOTIVE BULK BUYS--|',
  'Automotive Cleaning Chemicals': '|AUTOMOTIVE CLEANING CHEMICALS--|',
  'Baby Accessories': '|BABY ACCESSORIES--|',
  'Baby and Toddler Music Toys': '|BABY AND TODDLER MUSIC TOYS--|',
  'baby bottles and feeding': '|BABY BOTTLES AND FEEDING--|',
  'Baby Boys Bodysuits': '|BABY BOYS BODYSUITS--|',
  'Baby Clearance': '|BABY CLEARANCE--|',
  'Baby Gates for Top of Stairs': '|BABY GATES FOR TOP OF STAIRS--|',
  'Baby Girls Bodysuits': '|BABY GIRLS BODYSUITS--|',
  'Baby Girls Newborn Clothing': '|BABY GIRLS NEWBORN CLOTHING--|',
  'Baby Girls Socks': '|BABY GIRLS SOCKS--|',
  'Baby Towels & Washcloths': '|BABY TOWELS & WASHCLOTHS--|',
  'Bags, Backpacks & Duffles': '|BAGS, BACKPACKS & DUFFLES--|',
  'Bakeware Sets': '|BAKEWARE SETS--|',
  'Baking Chips & Cocoa': '|BAKING CHIPS & COCOA--|',
  'Baking Ingredients': '|BAKING INGREDIENTS--|',
  'Baking Mixes & Cookie Dough': '|BAKING MIXES & COOKIE DOUGH--|',
  'Baking Supplies': '|BAKING SUPPLIES--|',
  Balls: '|BALLS--|',
  Bandanas: '|BANDANAS--|',
  'Bar Soaps': '|BAR SOAPS--|',
  'Bar, Flats, Tubes & Rods': '|BAR, FLATS, TUBES & RODS--|',
  'Baseball Protective Gear': '|BASEBALL PROTECTIVE GEAR--|',
  'Baseball Training Equipment': '|BASEBALL TRAINING EQUIPMENT--|',
  'Basking Heat Lamps': '|BASKING HEAT LAMPS--|',
  'Bath Coordinates': '|BATH COORDINATES--|',
  'Bathroom Sink Faucets': '|BATHROOM SINK FAUCETS--|',
  'Bathroom Sinks': '|BATHROOM SINKS--|',
  Bathtubs: '|BATHTUBS--|',
  'Beading Cords and Threads': '|BEADING CORDS AND THREADS--|',
  'Bean Bag Chairs': '|BEAN BAG CHAIRS--|',
  'Beauty > Skin Care > Face\\nBeauty': '|BEAUTY-SKIN CARE-FACE\\NBEAUTY|',
  'Beauty Gift Ideas': '|BEAUTY GIFT IDEAS--|',
  'Bed & Bath > Bedding > Blankets & Throws':
    '|BED & BATH-BEDDING-BLANKETS & THROWS|',
  'Bed Frames': '|BED FRAMES--|',
  'Beef Specialties': '|BEEF SPECIALTIES--|',
  'Beverages > Energy Drinks': '|BEVERAGES-ENERGY DRINKS-|',
  'Beverages > Tea > Green Tea': '|BEVERAGES-TEA-GREEN TEA|',
  'Bike Baskets': '|BIKE BASKETS--|',
  'Bike Pumps': '|BIKE PUMPS--|',
  'Bike Tire Tubes': '|BIKE TIRE TUBES--|',
  'Bird Accessories': '|BIRD ACCESSORIES--|',
  'Bird Feeders': '|BIRD FEEDERS--|',
  'Birthday Gift Wrap': '|BIRTHDAY GIFT WRAP--|',
  'bladder, rectal, and genital treatments':
    '|BLADDER, RECTAL, AND GENITAL TREATMENTS--|',
  'Blankets & Throws': '|BLANKETS & THROWS--|',
  Blenders: '|BLENDERS--|',
  'blintzes, crepes, pancakes and waffles':
    '|BLINTZES, CREPES, PANCAKES AND WAFFLES--|',
  'Blood Pressure Monitors': '|BLOOD PRESSURE MONITORS--|',
  'Blue Party Decorations': '|BLUE PARTY DECORATIONS--|',
  Boats: '|BOATS--|',
  'Body Lotion & Creams': '|BODY LOTION & CREAMS--|',
  'Bones & Rawhide': '|BONES & RAWHIDE--|',
  'Botanical Blossom Body Wash': '|BOTANICAL BLOSSOM BODY WASH--|',
  'Bottle Nipples': '|BOTTLE NIPPLES--|',
  Bottles: '|BOTTLES--|',
  'Boxes & Brackets': '|BOXES & BRACKETS--|',
  "Boys' Character Shop": "|BOYS' CHARACTER SHOP--|",
  'Braces & Wraps': '|BRACES & WRAPS--|',
  'Brads & Finish Nails': '|BRADS & FINISH NAILS--|',
  'Bread Loaves': '|BREAD LOAVES--|',
  'Breakfast Sausage': '|BREAKFAST SAUSAGE--|',
  'Breakfast Specialties': '|BREAKFAST SPECIALTIES--|',
  'Breast Feeding': '|BREAST FEEDING--|',
  Bronzer: '|BRONZER--|',
  Brother: '|BROTHER--|',
  'Bulk Items': '|BULK ITEMS--|',
  'Bulletin Boards': '|BULLETIN BOARDS--|',
  'C Refrigerant R134A': '|C REFRIGERANT R134A--|',
  'Cafe Sandwiches': '|CAFE SANDWICHES--|',
  'Cake Pans & Pie Pans': '|CAKE PANS & PIE PANS--|',
  'Call of Duty Video Games': '|CALL OF DUTY VIDEO GAMES--|',
  "Candie's": "|CANDIE'S--|",
  'Canned Dog Food': '|CANNED DOG FOOD--|',
  'Canned Fruit & Applesauce': '|CANNED FRUIT & APPLESAUCE--|',
  'Canned Tomatoes': '|CANNED TOMATOES--|',
  Canning: '|CANNING--|',
  'Car Air Freshener': '|CAR AIR FRESHENER--|',
  'Car Battery Chargers': '|CAR BATTERY CHARGERS--|',
  'car seats': '|CAR SEATS--|',
  'Car Wax & Polish': '|CAR WAX & POLISH--|',
  'Carburetor Cleaners': '|CARBURETOR CLEANERS--|',
  'Cards Invitations and Stationery': '|CARDS INVITATIONS AND STATIONERY--|',
  Cardstock: '|CARDSTOCK--|',
  'Cargo Pants': '|CARGO PANTS--|',
  'Carpet Cleaner': '|CARPET CLEANER--|',
  Casters: '|CASTERS--|',
  'Cat Ball Toys': '|CAT BALL TOYS--|',
  'Cat Enzyme Remover': '|CAT ENZYME REMOVER--|',
  'Cat Food - Moist': '|CAT FOOD - MOIST--|',
  'Cat Grooming': '|CAT GROOMING--|',
  'Cat Scratching Posts': '|CAT SCRATCHING POSTS--|',
  'Caulk & Sealants': '|CAULK & SEALANTS--|',
  'Ceiling Paint': '|CEILING PAINT--|',
  'Celebrate Black History Month': '|CELEBRATE BLACK HISTORY MONTH--|',
  'Character Party Games': '|CHARACTER PARTY GAMES--|',
  Charcoal: '|CHARCOAL--|',
  Charcuterie: '|CHARCUTERIE--|',
  'Chemical Drain Openers': '|CHEMICAL DRAIN OPENERS--|',
  Chew: '|CHEW--|',
  'Chewy Treats': '|CHEWY TREATS--|',
  'Chicken Dinners': '|CHICKEN DINNERS--|',
  'Chicken Nuggets': '|CHICKEN NUGGETS--|',
  'Chicken Wings': '|CHICKEN WINGS--|',
  'Chilled Gelatins': '|CHILLED GELATINS--|',
  'Chocolate Candy Bags': '|CHOCOLATE CANDY BAGS--|',
  'Christmas Novelty Lights': '|CHRISTMAS NOVELTY LIGHTS--|',
  'Cleaners & Repair': '|CLEANERS & REPAIR--|',
  Cleaning: '|CLEANING--|',
  'Clearblue Pregnancy Tests': '|CLEARBLUE PREGNANCY TESTS--|',
  'Clothes Racks': '|CLOTHES RACKS--|',
  'Clothing > Girls > Tops': '|CLOTHING-GIRLS-TOPS|',
  "Clothing > Women's Plus > Bottoms": "|CLOTHING-WOMEN'S PLUS-BOTTOMS|",
  Coffeemakers: '|COFFEEMAKERS--|',
  'Color Correcting': '|COLOR CORRECTING--|',
  'Coloring & Activity Books': '|COLORING & ACTIVITY BOOKS--|',
  Combinations: '|COMBINATIONS--|',
  Comedy: '|COMEDY--|',
  'Common Nails': '|COMMON NAILS--|',
  'Condiments Sauces & Spices': '|CONDIMENTS SAUCES & SPICES--|',
  Conditioning: '|CONDITIONING--|',
  'Contractor Bags': '|CONTRACTOR BAGS--|',
  Cookies: '|COOKIES--|',
  'Corkscrews & Wine Openers': '|CORKSCREWS & WINE OPENERS--|',
  Cosco: '|COSCO--|',
  'Cosmetics Accessories': '|COSMETICS ACCESSORIES--|',
  'Cotton Candy Machines': '|COTTON CANDY MACHINES--|',
  'Cotton Sheets': '|COTTON SHEETS--|',
  'Cough & Chest Congestion': '|COUGH & CHEST CONGESTION--|',
  'Craft Accessories': '|CRAFT ACCESSORIES--|',
  'Craft Feathers': '|CRAFT FEATHERS--|',
  'Crib Sheets': '|CRIB SHEETS--|',
  'Crispy Chicken Tender Strips': '|CRISPY CHICKEN TENDER STRIPS--|',
  'Croutons & Toppings': '|CROUTONS & TOPPINGS--|',
  'Curtain Hardware': '|CURTAIN HARDWARE--|',
  'Deck Screws': '|DECK SCREWS--|',
  Degreasers: '|DEGREASERS--|',
  'Dental Care': '|DENTAL CARE--|',
  'Detangling Brush': '|DETANGLING BRUSH--|',
  'Detergent-Liquid': '|DETERGENT-LIQUID--|',
  'Diet Foods & Drinks': '|DIET FOODS & DRINKS--|',
  'Digestive Health': '|DIGESTIVE HEALTH--|',
  'Dinner Menu Freshly Made Sides (Served only 4 p.m. - 11 p.m., Monday - Friday; all day Saturday - Sunday)':
    '|DINNER MENU FRESHLY MADE SIDES (SERVED ONLY 4 P.M. - 11 P.M., MONDAY - FRIDAY; ALL DAY SATURDAY - SUNDAY)--|',
  'Dinnerware & Silverware': '|DINNERWARE & SILVERWARE--|',
  'Dips & Salsa': '|DIPS & SALSA--|',
  'Dish & Hand Soap': '|DISH & HAND SOAP--|',
  'Dishes & Utensils': '|DISHES & UTENSILS--|',
  'Disposable Steam Pans': '|DISPOSABLE STEAM PANS--|',
  'DIY Slime Kits': '|DIY SLIME KITS--|',
  'Dog Clothing, Costumes & Accessories':
    '|DOG CLOTHING, COSTUMES & ACCESSORIES--|',
  'Dog Collars, Harnesses & Leashes': '|DOG COLLARS, HARNESSES & LEASHES--|',
  'Dog Dewormers': '|DOG DEWORMERS--|',
  'Dog Training Treats': '|DOG TRAINING TREATS--|',
  'Domestic Beer': '|DOMESTIC BEER--|',
  'Door Lock Combo Packs': '|DOOR LOCK COMBO PACKS--|',
  'Dorm Storage Essentials': '|DORM STORAGE ESSENTIALS--|',
  Dowels: '|DOWELS--|',
  'Downy Laundry Detergent': '|DOWNY LAUNDRY DETERGENT--|',
  'Drawing Paper': '|DRAWING PAPER--|',
  'Dried Fruit': '|DRIED FRUIT--|',
  'Dry Shampoo & Restyling': '|DRY SHAMPOO & RESTYLING--|',
  'Drywall Anchors': '|DRYWALL ANCHORS--|',
  Easter: '|EASTER--|',
  'Educational Kits and Learning Tools':
    '|EDUCATIONAL KITS AND LEARNING TOOLS--|',
  'Electrical Outlets': '|ELECTRICAL OUTLETS--|',
  'Electrical Staples': '|ELECTRICAL STAPLES--|',
  'Electronic Toys': '|ELECTRONIC TOYS--|',
  'Electronics > Headphones & Portable Audio > Earbuds':
    '|ELECTRONICS-HEADPHONES & PORTABLE AUDIO-EARBUDS|',
  'Electronics > Photo Shop > Batteries': '|ELECTRONICS-PHOTO SHOP-BATTERIES|',
  'Equate Anti Itch Cream': '|EQUATE ANTI ITCH CREAM--|',
  'Equate Beauty Hair Care': '|EQUATE BEAUTY HAIR CARE--|',
  'Equate Digestive Health': '|EQUATE DIGESTIVE HEALTH--|',
  'Equate Eye Drops': '|EQUATE EYE DROPS--|',
  'Equate Thermometers': '|EQUATE THERMOMETERS--|',
  Erasers: '|ERASERS--|',
  'Evenflo Baby Gates': '|EVENFLO BABY GATES--|',
  'Extension Cords & Storage': '|EXTENSION CORDS & STORAGE--|',
  'Extra Stuff': '|EXTRA STUFF--|',
  'Face Wipes For Combination Skin': '|FACE WIPES FOR COMBINATION SKIN--|',
  'Family Comedy Movies': '|FAMILY COMEDY MOVIES--|',
  'Family Meal': '|FAMILY MEAL--|',
  "Fancy Fixin's": "|FANCY FIXIN'S--|",
  'Favor Bags': '|FAVOR BAGS--|',
  'Febreze Fabric Refresher Spray Air Fresheners':
    '|FEBREZE FABRIC REFRESHER SPRAY AIR FRESHENERS--|',
  'Febreze Plug In Air Fresheners Scented Oils':
    '|FEBREZE PLUG IN AIR FRESHENERS SCENTED OILS--|',
  'Felt Pads': '|FELT PADS--|',
  'Feminine Products': '|FEMININE PRODUCTS--|',
  'Fiberglass Insulation': '|FIBERGLASS INSULATION--|',
  'Fighting Games': '|FIGHTING GAMES--|',
  'Fine Earrings': '|FINE EARRINGS--|',
  'Fishing Hard Baits': '|FISHING HARD BAITS--|',
  'Flavored Creamers': '|FLAVORED CREAMERS--|',
  'Flush Mount Lighting': '|FLUSH MOUNT LIGHTING--|',
  'Foam Board Insulation': '|FOAM BOARD INSULATION--|',
  'Foam Boards': '|FOAM BOARDS--|',
  'Foam Tapes': '|FOAM TAPES--|',
  'Folding Chairs': '|FOLDING CHAIRS--|',
  'Food > Breakfast > Yogurts': '|FOOD-BREAKFAST-YOGURTS|',
  'Food > Meat Entrees > Other Meat Entrees':
    '|FOOD-MEAT ENTREES-OTHER MEAT ENTREES|',
  'Food > Pasta > Meat Pasta': '|FOOD-PASTA-MEAT PASTA|',
  'Food Network': '|FOOD NETWORK--|',
  'Food Savers & Accessories': '|FOOD SAVERS & ACCESSORIES--|',
  'Food/Baking/Syrups': '|FOOD/BAKING/SYRUPS--|',
  'Food/Beverages/Powdered Drink Mix/All Powdered Drink Mix':
    '|FOOD/BEVERAGES/POWDERED DRINK MIX/ALL POWDERED DRINK MIX--|',
  'Food/Breakfast & Cereal/Cold Cereal/All Cereal Brands':
    '|FOOD/BREAKFAST & CEREAL/COLD CEREAL/ALL CEREAL BRANDS--|',
  'Food/Fresh Food/Meat, Seafood & Poultry/Poultry':
    '|FOOD/FRESH FOOD/MEAT, SEAFOOD & POULTRY/POULTRY--|',
  'Food/Snacks, Cookies & Chips/Granola Bars & Snack Bars':
    '|FOOD/SNACKS, COOKIES & CHIPS/GRANOLA BARS & SNACK BARS--|',
  'Footie Pajamas & Union Suits': '|FOOTIE PAJAMAS & UNION SUITS--|',
  'formula and pediatric supplements': '|FORMULA AND PEDIATRIC SUPPLEMENTS--|',
  'Freestanding Shelving Units': '|FREESTANDING SHELVING UNITS--|',
  Fresh: '|FRESH--|',
  'Fresh Baked Bread': '|FRESH BAKED BREAD--|',
  'Freshwater Fish Food': '|FRESHWATER FISH FOOD--|',
  Fries: '|FRIES--|',
  'Friskies Dry Cat Food': '|FRISKIES DRY CAT FOOD--|',
  'Frosting & Decorations': '|FROSTING & DECORATIONS--|',
  'Frozen Cosmetics': '|FROZEN COSMETICS--|',
  'Frozen Single Serve Meals': '|FROZEN SINGLE SERVE MEALS--|',
  'Fruits & Vegetables': '|FRUITS & VEGETABLES--|',
  'Fuel Storage & Transfer': '|FUEL STORAGE & TRANSFER--|',
  'Garden & Patio': '|GARDEN & PATIO--|',
  'Gift Finder': '|GIFT FINDER--|',
  'Gifts with Purchase': '|GIFTS WITH PURCHASE--|',
  "Girls' Bedding": "|GIRLS' BEDDING--|",
  "Girls' Halloween Costumes": "|GIRLS' HALLOWEEN COSTUMES--|",
  'Globe Light Bulbs': '|GLOBE LIGHT BULBS--|',
  'Gloves & Scarves': '|GLOVES & SCARVES--|',
  'Gloves, Masks & Gowns': '|GLOVES, MASKS & GOWNS--|',
  'Gluten Free Pizza (Baking Required)':
    '|GLUTEN FREE PIZZA (BAKING REQUIRED)--|',
  Granola: '|GRANOLA--|',
  'Granola & Trail Mix Bars': '|GRANOLA & TRAIL MIX BARS--|',
  'Great Value Liquid Fabric Softener':
    '|GREAT VALUE LIQUID FABRIC SOFTENER--|',
  'Greek Style Yogurt': '|GREEK STYLE YOGURT--|',
  'Green Gift Wrap Supplies': '|GREEN GIFT WRAP SUPPLIES--|',
  'Green Party Decorations': '|GREEN PARTY DECORATIONS--|',
  'Grilling & Cooking': '|GRILLING & COOKING--|',
  'Grips & Wallets': '|GRIPS & WALLETS--|',
  'Grocery Deals': '|GROCERY DEALS--|',
  'Gummy & Sour Candy': '|GUMMY & SOUR CANDY--|',
  'Guttering & Drainage': '|GUTTERING & DRAINAGE--|',
  'Hair & Scalp Treatments': '|HAIR & SCALP TREATMENTS--|',
  'Hair Skin Nail Supplements': '|HAIR SKIN NAIL SUPPLEMENTS--|',
  'Hair Styling Aids': '|HAIR STYLING AIDS--|',
  'Halloween Baking & Cooking Supplies':
    '|HALLOWEEN BAKING & COOKING SUPPLIES--|',
  'Hamburger & Hot Dog Buns': '|HAMBURGER & HOT DOG BUNS--|',
  'Hamster Food': '|HAMSTER FOOD--|',
  'Hand & Foot Treatment': '|HAND & FOOT TREATMENT--|',
  'Handheld Massagers': '|HANDHELD MASSAGERS--|',
  'Hard Candy Lip Glosses': '|HARD CANDY LIP GLOSSES--|',
  'Hardware & Plumbing': '|HARDWARE & PLUMBING--|',
  'Hardware Fasteners': '|HARDWARE FASTENERS--|',
  'Headaches & Fever': '|HEADACHES & FEVER--|',
  'Health & Beauty > Personal Care > Anti - Smoking':
    '|HEALTH & BEAUTY-PERSONAL CARE-ANTI - SMOKING|',
  'Health & Wellness': '|HEALTH & WELLNESS--|',
  'HEALTH AND BEAUTY > Personal Wipes': '|HEALTH AND BEAUTY-PERSONAL WIPES-|',
  'Health Care': '|HEALTH CARE--|',
  'Health/Pain Reliever Brands/Orajel':
    '|HEALTH/PAIN RELIEVER BRANDS/ORAJEL--|',
  'Heat & Eat Meals': '|HEAT & EAT MEALS--|',
  'Heat Protection': '|HEAT PROTECTION--|',
  Heaters: '|HEATERS--|',
  'Heels & Pumps': '|HEELS & PUMPS--|',
  'Helmets & Protective Gear': '|HELMETS & PROTECTIVE GEAR--|',
  'Herbal Supplements': '|HERBAL SUPPLEMENTS--|',
  'High Protein Cat Food': '|HIGH PROTEIN CAT FOOD--|',
  'Hole Saws & Kits': '|HOLE SAWS & KITS--|',
  'Holiday Beauty Tech & Tools': '|HOLIDAY BEAUTY TECH & TOOLS--|',
  'Home & Yard Flea & Tick Treatments':
    '|HOME & YARD FLEA & TICK TREATMENTS--|',
  'Home Gym Equipment': '|HOME GYM EQUIPMENT--|',
  'Honey & Syrup': '|HONEY & SYRUP--|',
  'Household Essentials/Laundry/Bleach':
    '|HOUSEHOLD ESSENTIALS/LAUNDRY/BLEACH--|',
  'Household Essentials/Laundry/Dryer Sheets & Balls':
    '|HOUSEHOLD ESSENTIALS/LAUNDRY/DRYER SHEETS & BALLS--|',
  'Household Essentials/Paper & Plastic/Plastic Wrap & Foil':
    '|HOUSEHOLD ESSENTIALS/PAPER & PLASTIC/PLASTIC WRAP & FOIL--|',
  'Household Essentials/Paper & Plastic/Trash Bags':
    '|HOUSEHOLD ESSENTIALS/PAPER & PLASTIC/TRASH BAGS--|',
  'Household Essentials/Pest Control': '|HOUSEHOLD ESSENTIALS/PEST CONTROL--|',
  'Housekeeping Toys': '|HOUSEKEEPING TOYS--|',
  'Howie Rolls': '|HOWIE ROLLS--|',
  Hummus: '|HUMMUS--|',
  'Hyper Tough Hand Tool Sets': '|HYPER TOUGH HAND TOOL SETS--|',
  'Iams Dry Cat Food': '|IAMS DRY CAT FOOD--|',
  'Ice Breakers Chewing Gum': '|ICE BREAKERS CHEWING GUM--|',
  'Iced Coffee & Cold Brew': '|ICED COFFEE & COLD BREW--|',
  'Incipio Phone Cases': '|INCIPIO PHONE CASES--|',
  'Incontinence Pads': '|INCONTINENCE PADS--|',
  'Indoor Christmas Decor': '|INDOOR CHRISTMAS DECOR--|',
  'Indoor Grills': '|INDOOR GRILLS--|',
  'Indoor Plants': '|INDOOR PLANTS--|',
  'input devices': '|INPUT DEVICES--|',
  InstallerNet: '|INSTALLERNET--|',
  'Interior Repair & Restoration': '|INTERIOR REPAIR & RESTORATION--|',
  'International Delight': '|INTERNATIONAL DELIGHT--|',
  'iPhone 8 Cases': '|IPHONE 8 CASES--|',
  'iPods & MP3s': '|IPODS & MP3S--|',
  'Jump Ropes': '|JUMP ROPES--|',
  'Juniors Jeggings': '|JUNIORS JEGGINGS--|',
  'K-Cups & Single Serve Coffee': '|K-CUPS & SINGLE SERVE COFFEE--|',
  Keurig: '|KEURIG--|',
  'Kids Drinks': '|KIDS DRINKS--|',
  'Kids Mains': '|KIDS MAINS--|',
  "Kids' & Family Movies": "|KIDS' & FAMILY MOVIES--|",
  "Kids' Hair Care": "|KIDS' HAIR CARE--|",
  "Kids' Toy Storage": "|KIDS' TOY STORAGE--|",
  'Kinetic Sand Bulk': '|KINETIC SAND BULK--|',
  'Kitchen Faucets': '|KITCHEN FAUCETS--|',
  'Kitchen Mix, Blend, Chop and Slice Appliances':
    '|KITCHEN MIX, BLEND, CHOP AND SLICE APPLIANCES--|',
  'Kitchen Sink Strainers & Strainer Baskets':
    '|KITCHEN SINK STRAINERS & STRAINER BASKETS--|',
  'Kitchen spoons and spatulas': '|KITCHEN SPOONS AND SPATULAS--|',
  'Knick-Knacks': '|KNICK-KNACKS--|',
  "L'Oreal Beauty Forward": "|L'OREAL BEAUTY FORWARD--|",
  "L'Oreal Hair Care": "|L'OREAL HAIR CARE--|",
  'Label Makers': '|LABEL MAKERS--|',
  Laminators: '|LAMINATORS--|',
  Lanyards: '|LANYARDS--|',
  Laptops: '|LAPTOPS--|',
  'Lash Couture Luxtension Royal Silk':
    '|LASH COUTURE LUXTENSION ROYAL SILK--|',
  'Laundry Room Essentials': '|LAUNDRY ROOM ESSENTIALS--|',
  'Laundry, Paper & Cleaning > Paper Towels':
    '|LAUNDRY, PAPER & CLEANING-PAPER TOWELS-|',
  'Lawn & Garden Care': '|LAWN & GARDEN CARE--|',
  'Lawn Sprinklers': '|LAWN SPRINKLERS--|',
  'Leave-In Treatment': '|LEAVE-IN TREATMENT--|',
  'LED Night Light Bulbs': '|LED NIGHT LIGHT BULBS--|',
  'Lemon & Lime': '|LEMON & LIME--|',
  LG: '|LG--|',
  'Lifting Equipment': '|LIFTING EQUIPMENT--|',
  'Lighter Options': '|LIGHTER OPTIONS--|',
  'Limited Time Only': '|LIMITED TIME ONLY--|',
  'Lint Roller': '|LINT ROLLER--|',
  'Lipton Iced Tea': '|LIPTON ICED TEA--|',
  'Little Boys Pajama Sets': '|LITTLE BOYS PAJAMA SETS--|',
  Locks: '|LOCKS--|',
  Mailboxes: '|MAILBOXES--|',
  'Make Your Own Medium Sub': '|MAKE YOUR OWN MEDIUM SUB--|',
  'Malt Drinks': '|MALT DRINKS--|',
  'Markers and Crayons': '|MARKERS AND CRAYONS--|',
  'Mason Jars & Canning Supplies': '|MASON JARS & CANNING SUPPLIES--|',
  'Matches & Logs': '|MATCHES & LOGS--|',
  Mattresses: '|MATTRESSES--|',
  'Meats & Entrees': '|MEATS & ENTREES--|',
  'Medications & Treatments': '|MEDICATIONS & TREATMENTS--|',
  'Mega Construx': '|MEGA CONSTRUX--|',
  'Memory Foam Bath Mats': '|MEMORY FOAM BATH MATS--|',
  "Men's Fragrance": "|MEN'S FRAGRANCE--|",
  'Mens Big & Tall Active Shorts': '|MENS BIG & TALL ACTIVE SHORTS--|',
  'Mens Bootcut Jeans': '|MENS BOOTCUT JEANS--|',
  'Mens Dress Shirts': '|MENS DRESS SHIRTS--|',
  'Mens Slim Jeans': '|MENS SLIM JEANS--|',
  'Mens Slippers': '|MENS SLIPPERS--|',
  'Mens T-Shirts & Tank Tops': '|MENS T-SHIRTS & TANK TOPS--|',
  'Mens Thermal Underwear & Long Johns':
    '|MENS THERMAL UNDERWEAR & LONG JOHNS--|',
  'Mens Wallets & Card Cases': '|MENS WALLETS & CARD CASES--|',
  'Meow Mix': '|MEOW MIX--|',
  'Milani Face Makeup': '|MILANI FACE MAKEUP--|',
  Milkshakes: '|MILKSHAKES--|',
  'Mixed Nuts': '|MIXED NUTS--|',
  'Mixing Bowls': '|MIXING BOWLS--|',
  'Modular Storage': '|MODULAR STORAGE--|',
  Mops: '|MOPS--|',
  'Mounting Bolts & Bolt Caps': '|MOUNTING BOLTS & BOLT CAPS--|',
  'Mouthwash & Rinses': '|MOUTHWASH & RINSES--|',
  'Moving Blankets': '|MOVING BLANKETS--|',
  'Mr. Coffee Coffee Makers': '|MR. COFFEE COFFEE MAKERS--|',
  'Multipack & Aseptic Juice': '|MULTIPACK & ASEPTIC JUICE--|',
  'Musical Toys & Instruments': '|MUSICAL TOYS & INSTRUMENTS--|',
  Mustard: '|MUSTARD--|',
  'Nail Art & Design': '|NAIL ART & DESIGN--|',
  'Nasal Care': '|NASAL CARE--|',
  'Natural Water Flavoring': '|NATURAL WATER FLAVORING--|',
  'New Bright RC': '|NEW BRIGHT RC--|',
  'New Years Hanging Decorations': '|NEW YEARS HANGING DECORATIONS--|',
  'Next Day Eligible Drug Tests': '|NEXT DAY ELIGIBLE DRUG TESTS--|',
  'Non-Retail': '|NON-RETAIL--|',
  'Noodles and Pasta - Regular Size': '|NOODLES AND PASTA - REGULAR SIZE--|',
  'Number Candles': '|NUMBER CANDLES--|',
  'Odor & Stain Removers': '|ODOR & STAIN REMOVERS--|',
  'Office Products': '|OFFICE PRODUCTS--|',
  'Office Supplies Fan Shop': '|OFFICE SUPPLIES FAN SHOP--|',
  'OGX Conditioners': '|OGX CONDITIONERS--|',
  'OGX Shampoos': '|OGX SHAMPOOS--|',
  'One Lubricants': '|ONE LUBRICANTS--|',
  'Orange Party Decorations': '|ORANGE PARTY DECORATIONS--|',
  'Other Air Fresheners & Refills': '|OTHER AIR FRESHENERS & REFILLS--|',
  Ottomans: '|OTTOMANS--|',
  'Outdoor Play': '|OUTDOOR PLAY--|',
  'Outdoor String Lights': '|OUTDOOR STRING LIGHTS--|',
  'Outdoor Wall Lights': '|OUTDOOR WALL LIGHTS--|',
  'Over-Ear and On-Ear Headphones': '|OVER-EAR AND ON-EAR HEADPHONES--|',
  'Ozark Trail Sleeping Bags': '|OZARK TRAIL SLEEPING BAGS--|',
  Pacifiers: '|PACIFIERS--|',
  'Packaged Dinner Sides': '|PACKAGED DINNER SIDES--|',
  'Packaged Poultry': '|PACKAGED POULTRY--|',
  'Paint Liners & Grids': '|PAINT LINERS & GRIDS--|',
  'Pampers Diapers': '|PAMPERS DIAPERS--|',
  'Paper & Printable Media': '|PAPER & PRINTABLE MEDIA--|',
  'Paper Goods & Disposable Tableware':
    '|PAPER GOODS & DISPOSABLE TABLEWARE--|',
  'Party Dinnerware': '|PARTY DINNERWARE--|',
  'Party Games': '|PARTY GAMES--|',
  'Pasta & Rice': '|PASTA & RICE--|',
  'Pavers, Rocks & Stepping Stones': '|PAVERS, ROCKS & STEPPING STONES--|',
  'Paw Patrol Shop All': '|PAW PATROL SHOP ALL--|',
  'Pencil Grips': '|PENCIL GRIPS--|',
  'Pens, Pencils & Markers': '|PENS, PENCILS & MARKERS--|',
  Perennials: '|PERENNIALS--|',
  'Personal Care/Hair Care/Hair Color':
    '|PERSONAL CARE/HAIR CARE/HAIR COLOR--|',
  'Personal Care/Hair Care/Hair Treatments':
    '|PERSONAL CARE/HAIR CARE/HAIR TREATMENTS--|',
  'Personal Care/Oral Care/Crest': '|PERSONAL CARE/ORAL CARE/CREST--|',
  'Personal Care/Oral Care/Toothpaste':
    '|PERSONAL CARE/ORAL CARE/TOOTHPASTE--|',
  'Pet Bird Food': '|PET BIRD FOOD--|',
  'Pet Food Accessories': '|PET FOOD ACCESSORIES--|',
  'Pets/Dogs/Dog Grooming': '|PETS/DOGS/DOG GROOMING--|',
  'Pickles, Relish, & Olives': '|PICKLES, RELISH, & OLIVES--|',
  'Pies Desserts': '|PIES DESSERTS--|',
  'Pies, Tarts, & Cobblers': '|PIES, TARTS, & COBBLERS--|',
  'Pins & Magnets': '|PINS & MAGNETS--|',
  Pints: '|PINTS--|',
  'Pizza Crusts': '|PIZZA CRUSTS--|',
  'Plastic Sheeting & Film': '|PLASTIC SHEETING & FILM--|',
  'Plug-In Air Fresheners': '|PLUG-IN AIR FRESHENERS--|',
  'Plumbing Snakes & Augers': '|PLUMBING SNAKES & AUGERS--|',
  'Pokemon All Trading Cards': '|POKEMON ALL TRADING CARDS--|',
  'Pokemon Games': '|POKEMON GAMES--|',
  Polos: '|POLOS--|',
  'Pom Poms': '|POM POMS--|',
  'Pool Shock': '|POOL SHOCK--|',
  'Pop Rock Music on CD or Vinyl': '|POP ROCK MUSIC ON CD OR VINYL--|',
  'Portable Tool Boxes': '|PORTABLE TOOL BOXES--|',
  'Post-Game Clean Up': '|POST-GAME CLEAN UP--|',
  'Powdered Mixes': '|POWDERED MIXES--|',
  'Powdered Tea & Mixes': '|POWDERED TEA & MIXES--|',
  'Pre-Wash': '|PRE-WASH--|',
  'Premium Cologne': '|PREMIUM COLOGNE--|',
  'Prepaid Cell Phones': '|PREPAID CELL PHONES--|',
  'Prepared Produce': '|PREPARED PRODUCE--|',
  Protege: '|PROTEGE--|',
  Protein: '|PROTEIN--|',
  'PS4 Games': '|PS4 GAMES--|',
  "Pumpkin Decorating & Kids' Activities":
    "|PUMPKIN DECORATING & KIDS' ACTIVITIES--|",
  Putty: '|PUTTY--|',
  'Putty Knives': '|PUTTY KNIVES--|',
  Quarts: '|QUARTS--|',
  'Rabbit Food': '|RABBIT FOOD--|',
  'Rachael Ray Dry Dog Food': '|RACHAEL RAY DRY DOG FOOD--|',
  'Red Bull': '|RED BULL--|',
  'Renuzit Air Fresheners': '|RENUZIT AIR FRESHENERS--|',
  'Report Covers': '|REPORT COVERS--|',
  'Revlon Face Makeup': '|REVLON FACE MAKEUP--|',
  Roasts: '|ROASTS--|',
  'Robotic Vacuums': '|ROBOTIC VACUUMS--|',
  'Rod & Reel Combos': '|ROD & REEL COMBOS--|',
  'Rollerball Pens': '|ROLLERBALL PENS--|',
  'Root Beer & Cream Soda': '|ROOT BEER & CREAM SODA--|',
  Rope: '|ROPE--|',
  Ropes: '|ROPES--|',
  'Rotisserie Chicken': '|ROTISSERIE CHICKEN--|',
  'Rubberized Coatings': '|RUBBERIZED COATINGS--|',
  'Rust Preventative': '|RUST PREVENTATIVE--|',
  'Safety Glasses & Sunglasses': '|SAFETY GLASSES & SUNGLASSES--|',
  'Sakura Pens': '|SAKURA PENS--|',
  'Salted Butter': '|SALTED BUTTER--|',
  'Salted Snacks': '|SALTED SNACKS--|',
  Samsung: '|SAMSUNG--|',
  'Scalp Treatments': '|SCALP TREATMENTS--|',
  'Scent Booster': '|SCENT BOOSTER--|',
  'Scientific Calculators': '|SCIENTIFIC CALCULATORS--|',
  'Screen Door Accessories': '|SCREEN DOOR ACCESSORIES--|',
  'Sculpting & Carving Tools': '|SCULPTING & CARVING TOOLS--|',
  'Secret Treasures Shop All': '|SECRET TREASURES SHOP ALL--|',
  'Self-Tanning & Bronzing': '|SELF-TANNING & BRONZING--|',
  'Setting Spray & Powder': '|SETTING SPRAY & POWDER--|',
  'Shampoos & Conditioners': '|SHAMPOOS & CONDITIONERS--|',
  'Shea Moisture Conditioners': '|SHEA MOISTURE CONDITIONERS--|',
  'Sheet Protectors': '|SHEET PROTECTORS--|',
  'Shelf Paper': '|SHELF PAPER--|',
  'Shop all Baby Shark': '|SHOP ALL BABY SHARK--|',
  'Shop All Christmas Tree Lights by Color':
    '|SHOP ALL CHRISTMAS TREE LIGHTS BY COLOR--|',
  'Shop all Spider-Man': '|SHOP ALL SPIDER-MAN--|',
  'Shop Calculators by Brand': '|SHOP CALCULATORS BY BRAND--|',
  "Shop Kellogg's": "|SHOP KELLOGG'S--|",
  'Shop Lights': '|SHOP LIGHTS--|',
  'Shredded Cheese': '|SHREDDED CHEESE--|',
  'Shut-Off Valves': '|SHUT-OFF VALVES--|',
  'Sides and More': '|SIDES AND MORE--|',
  'Silverware Sets': '|SILVERWARE SETS--|',
  'Single Serve': '|SINGLE SERVE--|',
  'Size 5 Diapers': '|SIZE 5 DIAPERS--|',
  'Skin Tag Pens': '|SKIN TAG PENS--|',
  'Sleep Supplements': '|SLEEP SUPPLEMENTS--|',
  'Small Animal Cages & Habitats': '|SMALL ANIMAL CAGES & HABITATS--|',
  'Small Animal Cleanup & Odor Control':
    '|SMALL ANIMAL CLEANUP & ODOR CONTROL--|',
  'Small Appliances': '|SMALL APPLIANCES--|',
  'Smart Light Bulbs': '|SMART LIGHT BULBS--|',
  'Smart Lighting': '|SMART LIGHTING--|',
  'Smart Lighting Packs & Kits': '|SMART LIGHTING PACKS & KITS--|',
  'Smart Speakers': '|SMART SPEAKERS--|',
  Smartwatches: '|SMARTWATCHES--|',
  'Sneakers & Athletic Shoes': '|SNEAKERS & ATHLETIC SHOES--|',
  'Soccer Balls': '|SOCCER BALLS--|',
  Sockets: '|SOCKETS--|',
  'Sockets & Socket Sets': '|SOCKETS & SOCKET SETS--|',
  'Soil Amendments': '|SOIL AMENDMENTS--|',
  'Soldering Irons': '|SOLDERING IRONS--|',
  'Soup Mixes': '|SOUP MIXES--|',
  'soups, stews and broths': '|SOUPS, STEWS AND BROTHS--|',
  'Spark Create Imagine Toys': '|SPARK CREATE IMAGINE TOYS--|',
  'Specialty & Anti-Slip Tape': '|SPECIALTY & ANTI-SLIP TAPE--|',
  'Specialty Sodas': '|SPECIALTY SODAS--|',
  'Specialty Stores': '|SPECIALTY STORES--|',
  'Specialty Tools': '|SPECIALTY TOOLS--|',
  'Sponges & Scrub Brushes': '|SPONGES & SCRUB BRUSHES--|',
  'Sports Balls, Pucks and Flying Discs':
    '|SPORTS BALLS, PUCKS AND FLYING DISCS--|',
  'Sports Bras': '|SPORTS BRAS--|',
  'Sprays & Deodorizers': '|SPRAYS & DEODORIZERS--|',
  'Spring Makeup': '|SPRING MAKEUP--|',
  'Squeegees & Scrapers': '|SQUEEGEES & SCRAPERS--|',
  'Squishmallows Stuffed Animals': '|SQUISHMALLOWS STUFFED ANIMALS--|',
  'Standard (SAE) Hex Bolts': '|STANDARD (SAE) HEX BOLTS--|',
  'Standard Light Bulbs': '|STANDARD LIGHT BULBS--|',
  'Steak Sauce': '|STEAK SAUCE--|',
  'Steak Seasoning': '|STEAK SEASONING--|',
  'Step Stools': '|STEP STOOLS--|',
  'Stock up on Dog Health, Grooming & Wellness':
    '|STOCK UP ON DOG HEALTH, GROOMING & WELLNESS--|',
  'Stock up on Laundry Room Supplies': '|STOCK UP ON LAUNDRY ROOM SUPPLIES--|',
  'Storage Bins & Boxes': '|STORAGE BINS & BOXES--|',
  'Storage Totes': '|STORAGE TOTES--|',
  'Stuffed Crust Pizza': '|STUFFED CRUST PIZZA--|',
  'Styling Tools & Accessories': '|STYLING TOOLS & ACCESSORIES--|',
  Subs: '|SUBS--|',
  'Sugar Free Food': '|SUGAR FREE FOOD--|',
  'Summer Makeup': '|SUMMER MAKEUP--|',
  'Sun & Tanning': '|SUN & TANNING--|',
  'Sunglasses by Sunglass Hut': '|SUNGLASSES BY SUNGLASS HUT--|',
  'Super Mario Video Games': '|SUPER MARIO VIDEO GAMES--|',
  'Swedish Fish': '|SWEDISH FISH--|',
  'Sweet Snacks': '|SWEET SNACKS--|',
  'Table Runners': '|TABLE RUNNERS--|',
  'Tape, Adhesive, & Fasteners': '|TAPE, ADHESIVE, & FASTENERS--|',
  'Target > Baby > Diapering > Baby Wipes & Warmers':
    '|TARGET > BABY > DIAPERING > BABY WIPES & WARMERS--|',
  'Target > Beauty > Hair Care': '|TARGET > BEAUTY > HAIR CARE--|',
  'Target > Beauty > Makeup > Eyes > Eyeliner':
    '|TARGET > BEAUTY > MAKEUP > EYES > EYELINER--|',
  'Target > Beauty > Makeup > Nails > Fake Nails':
    '|TARGET > BEAUTY > MAKEUP > NAILS > FAKE NAILS--|',
  'Target > Beauty > Skin Care > Facial Cleansers':
    '|TARGET > BEAUTY > SKIN CARE > FACIAL CLEANSERS--|',
  'Target > Grocery > Chips, Snacks & Cookies > Snack Cakes':
    '|TARGET > GROCERY > CHIPS, SNACKS & COOKIES > SNACK CAKES--|',
  "Target > Kids > Kids' Accessories": "|TARGET > KIDS > KIDS' ACCESSORIES--|",
  'Target > Movies, Music & Books > Movies > New Movie Releases':
    '|TARGET > MOVIES, MUSIC & BOOKS > MOVIES > NEW MOVIE RELEASES--|',
  'Target > Personal Care > Hand Soap & Sanitizers':
    '|TARGET > PERSONAL CARE > HAND SOAP & SANITIZERS--|',
  'Target > Pets > Cat Supplies > Cat Food':
    '|TARGET > PETS > CAT SUPPLIES > CAT FOOD--|',
  'Target > Pets > Dog Supplies > Dog Food':
    '|TARGET > PETS > DOG SUPPLIES > DOG FOOD--|',
  "Target > Shoes > Women's Shoes > Sneakers":
    "|TARGET > SHOES > WOMEN'S SHOES > SNEAKERS--|",
  'Target > Toys > Dolls & Dollhouses > Doll Playsets':
    '|TARGET > TOYS > DOLLS & DOLLHOUSES > DOLL PLAYSETS--|',
  'Target > Toys > Electronics for Kids':
    '|TARGET > TOYS > ELECTRONICS FOR KIDS--|',
  'Target > Video Games > Nintendo Switch':
    '|TARGET > VIDEO GAMES > NINTENDO SWITCH--|',
  'Tea Party Decorations': '|TEA PARTY DECORATIONS--|',
  Teasers: '|TEASERS--|',
  "Teens' Bath Mats": "|TEENS' BATH MATS--|",
  "Teens' Lamps & Lighting": "|TEENS' LAMPS & LIGHTING--|",
  'Tek Gear': '|TEK GEAR--|',
  'Tent Stakes': '|TENT STAKES--|',
  'Theater Box Candy': '|THEATER BOX CANDY--|',
  'Toddler Boys Socks': '|TODDLER BOYS SOCKS--|',
  'Toddler Girls Outfit Sets': '|TODDLER GIRLS OUTFIT SETS--|',
  'Toddler Girls Tights': '|TODDLER GIRLS TIGHTS--|',
  'Toddler Plates': '|TODDLER PLATES--|',
  'Toilet Flanges': '|TOILET FLANGES--|',
  'Toilet Flappers': '|TOILET FLAPPERS--|',
  'Toilet Handles': '|TOILET HANDLES--|',
  'Toilet Repair Kits': '|TOILET REPAIR KITS--|',
  'Toilets & Showers': '|TOILETS & SHOWERS--|',
  'Tomato Cages': '|TOMATO CAGES--|',
  Toners: '|TONERS--|',
  'Tool Equipment & Storage': '|TOOL EQUIPMENT & STORAGE--|',
  'Tortillas & Dinner Kits': '|TORTILLAS & DINNER KITS--|',
  'Toys for age  2-4  girls': '|TOYS FOR AGE  2-4  GIRLS--|',
  'Toys for age  5-7  boys': '|TOYS FOR AGE  5-7  BOYS--|',
  'Toys for Kids 12 Years & Up': '|TOYS FOR KIDS 12 YEARS & UP--|',
  'Training Pads': '|TRAINING PADS--|',
  'Training Treats': '|TRAINING TREATS--|',
  'Trash Cans': '|TRASH CANS--|',
  'Treat Makers': '|TREAT MAKERS--|',
  'Treated Lumber': '|TREATED LUMBER--|',
  'Treatments Scrubs & Masks': '|TREATMENTS SCRUBS & MASKS--|',
  'Treats and Chews': '|TREATS AND CHEWS--|',
  'Tree Stands & Skirts': '|TREE STANDS & SKIRTS--|',
  'Trend Accessories': '|TREND ACCESSORIES--|',
  'Tresemme Conditioner': '|TRESEMME CONDITIONER--|',
  'Trident Gum': '|TRIDENT GUM--|',
  'Trolls Health & Beauty': '|TROLLS HEALTH & BEAUTY--|',
  'Tropical & Exotic Fruits': '|TROPICAL & EXOTIC FRUITS--|',
  Trucks: '|TRUCKS--|',
  'TVs & Home Theater': '|TVS & HOME THEATER--|',
  Twizzlers: '|TWIZZLERS--|',
  'ULTA Collection': '|ULTA COLLECTION--|',
  'Under 500 Calories': '|UNDER 500 CALORIES--|',
  'Under Armour': '|UNDER ARMOUR--|',
  'Under Sink Plumbing': '|UNDER SINK PLUMBING--|',
  Unisex: '|UNISEX--|',
  "Valentine's Day Gift Bags": "|VALENTINE'S DAY GIFT BAGS--|",
  "Valentine's Day Plush & Toys": "|VALENTINE'S DAY PLUSH & TOYS--|",
  'Value Sizes & Sets': '|VALUE SIZES & SETS--|',
  Variety: '|VARIETY--|',
  'Vegetable Medleys & Mixes': '|VEGETABLE MEDLEYS & MIXES--|',
  'Vegetable Oil': '|VEGETABLE OIL--|',
  'Vegetable Plants': '|VEGETABLE PLANTS--|',
  Vicks: '|VICKS--|',
  'Vitamins A-Z': '|VITAMINS A-Z--|',
  'Wall Accents': '|WALL ACCENTS--|',
  'Wall Calendars': '|WALL CALENDARS--|',
  'Wall Shelves': '|WALL SHELVES--|',
  'Wall Shelves Shop All': '|WALL SHELVES SHOP ALL--|',
  Watches: '|WATCHES--|',
  'Wedding Accessories': '|WEDDING ACCESSORIES--|',
  'Wedding Gift Wrap': '|WEDDING GIFT WRAP--|',
  'Weight Loss Supplements': '|WEIGHT LOSS SUPPLEMENTS--|',
  'Wellness Bars': '|WELLNESS BARS--|',
  'Wet Cat Food Variety Packs': '|WET CAT FOOD VARIETY PACKS--|',
  'Window & Door Moulding': '|WINDOW & DOOR MOULDING--|',
  'Window Screen': '|WINDOW SCREEN--|',
  'Window Screens': '|WINDOW SCREENS--|',
  'Wing Family Packs': '|WING FAMILY PACKS--|',
  'Wipes & Washcloths': '|WIPES & WASHCLOTHS--|',
  'Wire Strippers': '|WIRE STRIPPERS--|',
  "Women's Disposable Razors": "|WOMEN'S DISPOSABLE RAZORS--|",
  "Women's Shaving Needs": "|WOMEN'S SHAVING NEEDS--|",
  'Womens > Shoes > Athletic Shoes & Sneakers':
    '|WOMENS-SHOES-ATHLETIC SHOES & SNEAKERS|',
  'Womens Blouses': '|WOMENS BLOUSES--|',
  'Womens Casual & Fashion Sneakers': '|WOMENS CASUAL & FASHION SNEAKERS--|',
  'Womens Pants': '|WOMENS PANTS--|',
  'Womens Plus Loungewear': '|WOMENS PLUS LOUNGEWEAR--|',
  'Womens Plus Scrubs': '|WOMENS PLUS SCRUBS--|',
  'Womens Plus Swimsuits': '|WOMENS PLUS SWIMSUITS--|',
  'Womens Slippers': '|WOMENS SLIPPERS--|',
  'Womens Weekenders': '|WOMENS WEEKENDERS--|',
  'Wood Pallets': '|WOOD PALLETS--|',
  'Work Shirts': '|WORK SHIRTS--|',
  'Workout Tops': '|WORKOUT TOPS--|',
  'Wound Care': '|WOUND CARE--|',
  'Wraps & Tortillas': '|WRAPS & TORTILLAS--|',
  Wreaths: '|WREATHS--|',
  'Wrecking & Pry Bars': '|WRECKING & PRY BARS--|',
  'Xbox One Headsets': '|XBOX ONE HEADSETS--|',
  'Yoga & Pilates': '|YOGA & PILATES--|',
  '[OFFLINE HUB]': '|[OFFLINE HUB]--|',
  'Tees & Wyes': '|TEES & WYES--|',
  Terrariums: '|TERRARIUMS--|',
  'Textured Hair Care': '|TEXTURED HAIR CARE--|',
  Thermometers: '|THERMOMETERS--|',
  'Three Amigo Tacos': '|THREE AMIGO TACOS--|',
  'Tiger Balm': '|TIGER BALM--|',
  'Time and Tru Coats & Jackets': '|TIME AND TRU COATS & JACKETS--|',
  'Time and Tru Sweaters': '|TIME AND TRU SWEATERS--|',
  'Tissue Paper': '|TISSUE PAPER--|',
  'To-Go Boxes': '|TO-GO BOXES--|',
  'Toddler Boys Athletic Shorts': '|TODDLER BOYS ATHLETIC SHORTS--|',
  'Toddler Boys Jeans': '|TODDLER BOYS JEANS--|',
  'Toddler Boys Sweaters & Sweatshirts':
    '|TODDLER BOYS SWEATERS & SWEATSHIRTS--|',
  'Toddler Girls Hoodies And Sweatshirts':
    '|TODDLER GIRLS HOODIES AND SWEATSHIRTS--|',
  'Toddler Girls Pajama Sets': '|TODDLER GIRLS PAJAMA SETS--|',
  'Toilet Cleaners': '|TOILET CLEANERS--|',
  'Tommee Tippee Sippy Cups': '|TOMMEE TIPPEE SIPPY CUPS--|',
  'Tool Accessories': '|TOOL ACCESSORIES--|',
  'Tortilla & Corn': '|TORTILLA & CORN--|',
  'Tortillas, Shells, & Wraps': '|TORTILLAS, SHELLS, & WRAPS--|',
  'Towel Rings': '|TOWEL RINGS--|',
  'Toy Deals': '|TOY DEALS--|',
  'Toys > Toys & Accessories': '|TOYS-TOYS & ACCESSORIES-|',
  'Trains & Train Sets': '|TRAINS & TRAIN SETS--|',
  'Trash Can Liners': '|TRASH CAN LINERS--|',
  'Trays/Baskets/Platters': '|TRAYS/BASKETS/PLATTERS--|',
  'Treat Boxes & Tins': '|TREAT BOXES & TINS--|',
  'Trees Ornaments': '|TREES ORNAMENTS--|',
  'Trending Role Play': '|TRENDING ROLE PLAY--|',
  'Trims & Embellishments': '|TRIMS & EMBELLISHMENTS--|',
  Trolli: '|TROLLI--|',
  Tuna: '|TUNA--|',
  'Tunnels & Hideouts': '|TUNNELS & HIDEOUTS--|',
  'Turkey Legs & Thighs': '|TURKEY LEGS & THIGHS--|',
  'TV Antennas': '|TV ANTENNAS--|',
  'Underwear & Undershirts': '|UNDERWEAR & UNDERSHIRTS--|',
  'USB Cables': '|USB CABLES--|',
  'Valentine Candy': '|VALENTINE CANDY--|',
  "Valentine's Day Crafts": "|VALENTINE'S DAY CRAFTS--|",
  'Vanilla Cake Mix': '|VANILLA CAKE MIX--|',
  'Vanity Lights': '|VANITY LIGHTS--|',
  'Varathane Products': '|VARATHANE PRODUCTS--|',
  'Velcro and Mounting Products': '|VELCRO AND MOUNTING PRODUCTS--|',
  Vibrators: '|VIBRATORS--|',
  'Video Game Controllers': '|VIDEO GAME CONTROLLERS--|',
  'Video Game Gift Cards': '|VIDEO GAME GIFT CARDS--|',
  'video game hardware': '|VIDEO GAME HARDWARE--|',
  'view all brands': '|VIEW ALL BRANDS--|',
  'Vinyl Pipe & Tubing': '|VINYL PIPE & TUBING--|',
  Volleyballs: '|VOLLEYBALLS--|',
  'Walmart Gift Cards': '|WALMART GIFT CARDS--|',
  'Washer & Dryer Parts': '|WASHER & DRYER PARTS--|',
  'Washers & Washer Kits': '|WASHERS & WASHER KITS--|',
  'Water Pitchers & Faucet Filtration':
    '|WATER PITCHERS & FAUCET FILTRATION--|',
  'Watering Cans': '|WATERING CANS--|',
  'Waterproof Mascara': '|WATERPROOF MASCARA--|',
  'Waxes & Polishes': '|WAXES & POLISHES--|',
  'Weather Accessories': '|WEATHER ACCESSORIES--|',
  'Wedding Bands For Her': '|WEDDING BANDS FOR HER--|',
  'Wedding Flowers': '|WEDDING FLOWERS--|',
  'Weight Loss': '|WEIGHT LOSS--|',
  'Weight Sets': '|WEIGHT SETS--|',
  'Wet Brush': '|WET BRUSH--|',
  'wet n wild Foundation': '|WET N WILD FOUNDATION--|',
  'Whipped Toppings': '|WHIPPED TOPPINGS--|',
  'White Rice': '|WHITE RICE--|',
  'White Shower Curtains': '|WHITE SHOWER CURTAINS--|',
  'Window Film': '|WINDOW FILM--|',
  'Window Weatherization': '|WINDOW WEATHERIZATION--|',
  'Wiper Blades & Auto Accessories': '|WIPER BLADES & AUTO ACCESSORIES--|',
  Wire: '|WIRE--|',
  'Wire Rope': '|WIRE ROPE--|',
  'Wire-Free Bras': '|WIRE-FREE BRAS--|',
  "Women's Brands": "|WOMEN'S BRANDS--|",
  "Women's Watches": "|WOMEN'S WATCHES--|",
  'Womens Activewear Sets': '|WOMENS ACTIVEWEAR SETS--|',
  'Womens Activewear Shorts & Skorts': '|WOMENS ACTIVEWEAR SHORTS & SKORTS--|',
  'Womens Belts': '|WOMENS BELTS--|',
  'Womens Dresses': '|WOMENS DRESSES--|',
  'Womens Faux Fur Jackets': '|WOMENS FAUX FUR JACKETS--|',
  'Womens Nightshirts & Gowns': '|WOMENS NIGHTSHIRTS & GOWNS--|',
  'Womens Skirts': '|WOMENS SKIRTS--|',
  'Womens Swimsuits': '|WOMENS SWIMSUITS--|',
  'Womens T-Shirts': '|WOMENS T-SHIRTS--|',
  'Womens Tops & T-Shirts': '|WOMENS TOPS & T-SHIRTS--|',
  'Womens Wallets & Card Cases': '|WOMENS WALLETS & CARD CASES--|',
  'Wood Closet Shelves': '|WOOD CLOSET SHELVES--|',
  'Woodboring Drill Bits': '|WOODBORING DRILL BITS--|',
  'Wooden Toys': '|WOODEN TOYS--|',
  'Work Hoodies & Sweatshirts': '|WORK HOODIES & SWEATSHIRTS--|',
  'Workout Shorts': '|WORKOUT SHORTS--|',
  Wraps: '|WRAPS--|',
  'Writing Utensils': '|WRITING UTENSILS--|',
  '1 Inch Binders': '|1 INCH BINDERS--|',
  '1/2 lb. Big Mouth Burgers': '|1/2 LB. BIG MOUTH BURGERS--|',
  '10 Inch Android Tablets': '|10 INCH ANDROID TABLETS--|',
  '2-in-1 Hair and Body Care': '|2-IN-1 HAIR AND BODY CARE--|',
  '2nd Foods': '|2ND FOODS--|',
  '3 Person Tents': '|3 PERSON TENTS--|',
  '34A Bras': '|34A BRAS--|',
  '40V': '|40V--|',
  '5-Subject Spiral Notebooks': '|5-SUBJECT SPIRAL NOTEBOOKS--|',
  '6 Foot Christmas Trees': '|6 FOOT CHRISTMAS TREES--|',
  '7 Foot Christmas Trees': '|7 FOOT CHRISTMAS TREES--|',
  '9Lives Cat Food': '|9LIVES CAT FOOD--|',
  'A La Carte Breakfast': '|A LA CARTE BREAKFAST--|',
  AA: '|AA--|',
  Acetaminophen: '|ACETAMINOPHEN--|',
  'Acne & Blemish Treatments': '|ACNE & BLEMISH TREATMENTS--|',
  'Activity Play Toys': '|ACTIVITY PLAY TOYS--|',
  'Adhesive Removers': '|ADHESIVE REMOVERS--|',
  'Adhesive Vinyl': '|ADHESIVE VINYL--|',
  'Adult Incontinence': '|ADULT INCONTINENCE--|',
  'Adventure Force Playsets': '|ADVENTURE FORCE PLAYSETS--|',
  'air humidity and purity': '|AIR HUMIDITY AND PURITY--|',
  Aleve: '|ALEVE--|',
  'Algaecides & Water Clarity': '|ALGAECIDES & WATER CLARITY--|',
  'All As Seen on TV': '|ALL AS SEEN ON TV--|',
  'All Baby': '|ALL BABY--|',
  'All Baby & Toddler Shoes': '|ALL BABY & TODDLER SHOES--|',
  'All Baby Gear': '|ALL BABY GEAR--|',
  'All Bathroom Vanities': '|ALL BATHROOM VANITIES--|',
  'All Beyblade Toys': '|ALL BEYBLADE TOYS--|',
  'All Calendars': '|ALL CALENDARS--|',
  'All Camcorders': '|ALL CAMCORDERS--|',
  'All Deli Cheeses': '|ALL DELI CHEESES--|',
  'All Departments > Grocery > Canned & Packaged Foods':
    '|ALL DEPARTMENTS-GROCERY-CANNED & PACKAGED FOODS|',
  'All Departments > Pharmacy, Health & Beauty > Health':
    '|ALL DEPARTMENTS-PHARMACY, HEALTH & BEAUTY-HEALTH|',
  'All Departments > Pharmacy, Health & Beauty > Personal Care':
    '|ALL DEPARTMENTS-PHARMACY, HEALTH & BEAUTY-PERSONAL CARE|',
  "All Girl's Halloween Costumes": "|ALL GIRL'S HALLOWEEN COSTUMES--|",
  'All Girls Shoes': '|ALL GIRLS SHOES--|',
  'All Grab and Go Sandwiches, Salads and Snack Packs':
    '|ALL GRAB AND GO SANDWICHES, SALADS AND SNACK PACKS--|',
  'All Hasbro Games': '|ALL HASBRO GAMES--|',
  'All Herbal Supplements': '|ALL HERBAL SUPPLEMENTS--|',
  'All Horror': '|ALL HORROR--|',
  "All Men's Beard Care": "|ALL MEN'S BEARD CARE--|",
  'All Multivitamins': '|ALL MULTIVITAMINS--|',
  'All NERF': '|ALL NERF--|',
  'All Oral Accessories': '|ALL ORAL ACCESSORIES--|',
  'All Pedal & Push Ride Ons': '|ALL PEDAL & PUSH RIDE ONS--|',
  'All PJ Masks': '|ALL PJ MASKS--|',
  'All Preschool Vehicles & Playsets': '|ALL PRESCHOOL VEHICLES & PLAYSETS--|',
  'All Pretzels': '|ALL PRETZELS--|',
  'All Printer Paper': '|ALL PRINTER PAPER--|',
  'All Rice Cakes': '|ALL RICE CAKES--|',
  'All Rug Pads & Grippers': '|ALL RUG PADS & GRIPPERS--|',
  'All Snack Cakes': '|ALL SNACK CAKES--|',
  'All Sparkling Water': '|ALL SPARKLING WATER--|',
  'all styling tools': '|ALL STYLING TOOLS--|',
  'All Table Tennis': '|ALL TABLE TENNIS--|',
  'All Teeth Whitening': '|ALL TEETH WHITENING--|',
  'All Tool Storage': '|ALL TOOL STORAGE--|',
  'All Toy Story Toys': '|ALL TOY STORY TOYS--|',
  'All Wall Plates': '|ALL WALL PLATES--|',
  'All Weather Essentials': '|ALL WEATHER ESSENTIALS--|',
  'Amino Acids': '|AMINO ACIDS--|',
  'Android Auto Compatible Stereos': '|ANDROID AUTO COMPATIBLE STEREOS--|',
  'Android Prepaid Phones': '|ANDROID PREPAID PHONES--|',
  'Appetizers & Snacks': '|APPETIZERS & SNACKS--|',
  'Apple iOS Prepaid Phones': '|APPLE IOS PREPAID PHONES--|',
  'Apple Phone Cases': '|APPLE PHONE CASES--|',
  'Apple Watch': '|APPLE WATCH--|',
  'Apples & Pears': '|APPLES & PEARS--|',
  'Aqua Culture Aquarium Ornaments': '|AQUA CULTURE AQUARIUM ORNAMENTS--|',
  'Aromatherapy & Essential Oils': '|AROMATHERAPY & ESSENTIAL OILS--|',
  Arrow: '|ARROW--|',
  'Artificial Christmas Trees': '|ARTIFICIAL CHRISTMAS TREES--|',
  'Arts & Crafts for Kids': '|ARTS & CRAFTS FOR KIDS--|',
  'Arts, Crafts & Sewing': '|ARTS, CRAFTS & SEWING--|',
  'As Seen on TV Toys': '|AS SEEN ON TV TOYS--|',
  Aspirin: '|ASPIRIN--|',
  'Asthma Medicine': '|ASTHMA MEDICINE--|',
  'Athletic Jackets & Sweatshirts': '|ATHLETIC JACKETS & SWEATSHIRTS--|',
  Aussie: '|AUSSIE--|',
  'Aussie Kids': '|AUSSIE KIDS--|',
  'Auto Glass Cleaners': '|AUTO GLASS CLEANERS--|',
  'Auto Window Tint': '|AUTO WINDOW TINT--|',
  'Automotive Paint, Paint Tools & Accessories':
    '|AUTOMOTIVE PAINT, PAINT TOOLS & ACCESSORIES--|',
  'Avengers Action Figures': '|AVENGERS ACTION FIGURES--|',
  Avocados: '|AVOCADOS--|',
  'Baby and Toddler Bedding and Blankets':
    '|BABY AND TODDLER BEDDING AND BLANKETS--|',
  'Baby Apparel': '|BABY APPAREL--|',
  'baby boy clothes 0-24 months': '|BABY BOY CLOTHES 0-24 MONTHS--|',
  'Baby Character Shop': '|BABY CHARACTER SHOP--|',
  'Baby Food & Formula': '|BABY FOOD & FORMULA--|',
  'Baby Food Stage 3': '|BABY FOOD STAGE 3--|',
  'Baby Girls Outfit Sets': '|BABY GIRLS OUTFIT SETS--|',
  'Baby Health Care': '|BABY HEALTH CARE--|',
  'Baby Plush Blankets': '|BABY PLUSH BLANKETS--|',
  'Baby Rattles': '|BABY RATTLES--|',
  'Baby Robes': '|BABY ROBES--|',
  'Baby Shampoos & Body Washes': '|BABY SHAMPOOS & BODY WASHES--|',
  'Baby Shower Gift Bags': '|BABY SHOWER GIFT BAGS--|',
  'Baby Shower Party Supplies': '|BABY SHOWER PARTY SUPPLIES--|',
  'Baby Wipe Best Sellers': '|BABY WIPE BEST SELLERS--|',
  'Back to School Favorites': '|BACK TO SCHOOL FAVORITES--|',
  Bacon: '|BACON--|',
  'Bag Cereal': '|BAG CEREAL--|',
  'Bagged Mulch': '|BAGGED MULCH--|',
  'Bait Lures': '|BAIT LURES--|',
  'Baked Goods and Snacks': '|BAKED GOODS AND SNACKS--|',
  'Bakery C-Store & Vending': '|BAKERY C-STORE & VENDING--|',
  'Bakery Desserts': '|BAKERY DESSERTS--|',
  Bali: '|BALI--|',
  'Barbeque Sauce': '|BARBEQUE SAUCE--|',
  Baseballs: '|BASEBALLS--|',
  'Basketball Accessories': '|BASKETBALL ACCESSORIES--|',
  'Baskets, Bins, & Containers': '|BASKETS, BINS, & CONTAINERS--|',
  'Bath Rugs, Mats and Covers': '|BATH RUGS, MATS AND COVERS--|',
  'Bath Tissue': '|BATH TISSUE--|',
  'Bath Towels & Washcloths': '|BATH TOWELS & WASHCLOTHS--|',
  'Bathroom Rug Sets': '|BATHROOM RUG SETS--|',
  'Bathroom Vanities with Tops': '|BATHROOM VANITIES WITH TOPS--|',
  Batting: '|BATTING--|',
  'Beauty > Beauty Tools & Accessories > Cotton':
    '|BEAUTY-BEAUTY TOOLS & ACCESSORIES-COTTON|',
  'Beauty > Makeup > Concealer': '|BEAUTY-MAKEUP-CONCEALER|',
  'Beauty/Makeup/Tools & Accessories': '|BEAUTY/MAKEUP/TOOLS & ACCESSORIES--|',
  'Bed in a Bag': '|BED IN A BAG--|',
  'Bedding Basics': '|BEDDING BASICS--|',
  'Best Waffle Makers': '|BEST WAFFLE MAKERS--|',
  'Better For You': '|BETTER FOR YOU--|',
  'Better Homes & Gardens Bathroom Renovation':
    '|BETTER HOMES & GARDENS BATHROOM RENOVATION--|',
  'Beverage Dispensers': '|BEVERAGE DISPENSERS--|',
  'Beverages > Coffee > Cappuccino': '|BEVERAGES-COFFEE-CAPPUCCINO|',
  'Beverages > Liquor > Mixed Cocktail': '|BEVERAGES-LIQUOR-MIXED COCKTAIL|',
  'Beyblade Launchers': '|BEYBLADE LAUNCHERS--|',
  'Big Boys (8-20) Swim Trunks': '|BIG BOYS (8-20) SWIM TRUNKS--|',
  'Big Boys New Clothing Arrivals': '|BIG BOYS NEW CLOTHING ARRIVALS--|',
  'Big Boys Pajama Sets': '|BIG BOYS PAJAMA SETS--|',
  'Big Girls Dancewear': '|BIG GIRLS DANCEWEAR--|',
  'Big Girls Jeans': '|BIG GIRLS JEANS--|',
  'Bike Brakes': '|BIKE BRAKES--|',
  'Bike Helmets': '|BIKE HELMETS--|',
  'Binder Clips': '|BINDER CLIPS--|',
  'Bird Seed and Food': '|BIRD SEED AND FOOD--|',
  'Birthday Banners': '|BIRTHDAY BANNERS--|',
  'Black & White Party Tableware': '|BLACK & WHITE PARTY TABLEWARE--|',
  'Black Beads': '|BLACK BEADS--|',
  'Black Tea': '|BLACK TEA--|',
  'Blank Note Cards': '|BLANK NOTE CARDS--|',
  'Blender Bottles': '|BLENDER BOTTLES--|',
  Blocks: '|BLOCKS--|',
  'Blood Glucose Test Strips': '|BLOOD GLUCOSE TEST STRIPS--|',
  'Blu-Ray Movies': '|BLU-RAY MOVIES--|',
  'Blue Beads': '|BLUE BEADS--|',
  'Blue Buffalo Dog Treats': '|BLUE BUFFALO DOG TREATS--|',
  'Board Accessories': '|BOARD ACCESSORIES--|',
  'Body Moisturizer': '|BODY MOISTURIZER--|',
  'Bones, Bully Sticks & Antlers Dog Treats':
    '|BONES, BULLY STICKS & ANTLERS DOG TREATS--|',
  'Book Pre-orders': '|BOOK PRE-ORDERS--|',
  'Bookshelves & Bookcases': '|BOOKSHELVES & BOOKCASES--|',
  'Boys Character Shoes': '|BOYS CHARACTER SHOES--|',
  'Boys Underwear': '|BOYS UNDERWEAR--|',
  'Brass Fittings': '|BRASS FITTINGS--|',
  'Bread Mix': '|BREAD MIX--|',
  'BreadBowl Pasta': '|BREADBOWL PASTA--|',
  'Breakfast & Cereal Bars': '|BREAKFAST & CEREAL BARS--|',
  'Breakfast & Meal Replacement': '|BREAKFAST & MEAL REPLACEMENT--|',
  'Breakfast Food': '|BREAKFAST FOOD--|',
  'Breakfast Sandwiches': '|BREAKFAST SANDWICHES--|',
  'Breakfast Snacks': '|BREAKFAST SNACKS--|',
  'breast pumps': '|BREAST PUMPS--|',
  'Breath Fresheners & Mouthwash': '|BREATH FRESHENERS & MOUTHWASH--|',
  Broadheads: '|BROADHEADS--|',
  'Brown Sugar': '|BROWN SUGAR--|',
  'Bubble Gum': '|BUBBLE GUM--|',
  'Building Sets for Boys': '|BUILDING SETS FOR BOYS--|',
  'Bullet Journals': '|BULLET JOURNALS--|',
  'Burner Covers': '|BURNER COVERS--|',
  'Burritos & Wraps': '|BURRITOS & WRAPS--|',
  'Cabin Air Filters': '|CABIN AIR FILTERS--|',
  'Cabinet Hardware Accessories': '|CABINET HARDWARE ACCESSORIES--|',
  'Cabinet Hinges': '|CABINET HINGES--|',
  'Cables & Connectors': '|CABLES & CONNECTORS--|',
  'cables and cords': '|CABLES AND CORDS--|',
  'Cake Boxes': '|CAKE BOXES--|',
  'Camping Stove Fuels': '|CAMPING STOVE FUELS--|',
  'Candles & Candle Holders': '|CANDLES & CANDLE HOLDERS--|',
  Canned: '|CANNED--|',
  'Canned Fruit & Cups': '|CANNED FRUIT & CUPS--|',
  'Canon Back to School Printers': '|CANON BACK TO SCHOOL PRINTERS--|',
  'Canvas Art Shop All': '|CANVAS ART SHOP ALL--|',
  Caps: '|CAPS--|',
  'Car Mounts & Accessories': '|CAR MOUNTS & ACCESSORIES--|',
  'Car Seat Accessories': '|CAR SEAT ACCESSORIES--|',
  'Car Speakers': '|CAR SPEAKERS--|',
  'Car Wash Cloths, Mitts, & Microfiber Towels':
    '|CAR WASH CLOTHS, MITTS, & MICROFIBER TOWELS--|',
  Carabiners: '|CARABINERS--|',
  'Cards & Casino Games': '|CARDS & CASINO GAMES--|',
  'Carts & Wheelbarrows': '|CARTS & WHEELBARROWS--|',
  'Cat Beds & Carriers': '|CAT BEDS & CARRIERS--|',
  'Cat Care': '|CAT CARE--|',
  'Cat Food Pouches': '|CAT FOOD POUCHES--|',
  'Cat Health Care': '|CAT HEALTH CARE--|',
  'Cat Litter Box Mats': '|CAT LITTER BOX MATS--|',
  'Cat Scratchers, Trees & Towers': '|CAT SCRATCHERS, TREES & TOWERS--|',
  'Catnip Toys': '|CATNIP TOYS--|',
  'Caulk Guns': '|CAULK GUNS--|',
  'Ceiling Fans With Lights': '|CEILING FANS WITH LIGHTS--|',
  'Ceiling Fixtures': '|CEILING FIXTURES--|',
  'Celebrity Chef Cooking Utensils': '|CELEBRITY CHEF COOKING UTENSILS--|',
  'Centerset Bathroom Sink Faucets': '|CENTERSET BATHROOM SINK FAUCETS--|',
  'Ceramic Tile': '|CERAMIC TILE--|',
  'Cereal - Natural/Organic': '|CEREAL - NATURAL/ORGANIC--|',
  'CFL Bulbs': '|CFL BULBS--|',
  'Chain Connectors': '|CHAIN CONNECTORS--|',
  'Chairs & Stools': '|CHAIRS & STOOLS--|',
  Chalkboards: '|CHALKBOARDS--|',
  Champion: '|CHAMPION--|',
  Chaps: '|CHAPS--|',
  'Charcoal & Fire Starters': '|CHARCOAL & FIRE STARTERS--|',
  Cheddar: '|CHEDDAR--|',
  'Cheese Nachos': '|CHEESE NACHOS--|',
  'Chicken & Turkey Meals': '|CHICKEN & TURKEY MEALS--|',
  'Chicken Feed': '|CHICKEN FEED--|',
  'Chilled Dips': '|CHILLED DIPS--|',
  'Chinchilla and Degu Treats': '|CHINCHILLA AND DEGU TREATS--|',
  'Chocolate Bar': '|CHOCOLATE BAR--|',
  'Christmas & Advent': '|CHRISTMAS & ADVENT--|',
  'Christmas Jumbo & Irregular Gift Bags':
    '|CHRISTMAS JUMBO & IRREGULAR GIFT BAGS--|',
  'Christmas Tree Stands': '|CHRISTMAS TREE STANDS--|',
  Citrus: '|CITRUS--|',
  'Clamps & Vises': '|CLAMPS & VISES--|',
  'Classic Girl Nursery': '|CLASSIC GIRL NURSERY--|',
  'Clean & Clear': '|CLEAN & CLEAR--|',
  'Clean Beauty Skincare': '|CLEAN BEAUTY SKINCARE--|',
  'Cleaning Wipes & Pads': '|CLEANING WIPES & PADS--|',
  Clipboards: '|CLIPBOARDS--|',
  'Clippers & Shears': '|CLIPPERS & SHEARS--|',
  'Clips & Fasteners': '|CLIPS & FASTENERS--|',
  'Closet Organization': '|CLOSET ORGANIZATION--|',
  'Closet Shelves, Hooks & Accessories':
    '|CLOSET SHELVES, HOOKS & ACCESSORIES--|',
  'Cloth & Rag Dolls': '|CLOTH & RAG DOLLS--|',
  'Clothing > Boys > Activewear': '|CLOTHING-BOYS-ACTIVEWEAR|',
  'Clothing > Girls > One-Piece': '|CLOTHING-GIRLS-ONE-PIECE|',
  'Clothing > Tops > Tops & Tees': '|CLOTHING-TOPS-TOPS & TEES|',
  'Clothing Fan Shop': '|CLOTHING FAN SHOP--|',
  'Coconut Water': '|COCONUT WATER--|',
  'Coffee Filters & Accessories': '|COFFEE FILTERS & ACCESSORIES--|',
  'Coffee Grinders': '|COFFEE GRINDERS--|',
  'Coffee Pods': '|COFFEE PODS--|',
  Cognac: '|COGNAC--|',
  'Coin Roll Wrappers': '|COIN ROLL WRAPPERS--|',
  'Colanders & Food Strainers': '|COLANDERS & FOOD STRAINERS--|',
  'Collated Finishing Nails': '|COLLATED FINISHING NAILS--|',
  'College Sheets & Pillowcases': '|COLLEGE SHEETS & PILLOWCASES--|',
  'Color Care Conditioner': '|COLOR CARE CONDITIONER--|',
  'Coloring Supplies': '|COLORING SUPPLIES--|',
  'Combs & Brushes': '|COMBS & BRUSHES--|',
  'Command Hooks': '|COMMAND HOOKS--|',
  'Commercial Ice Machines': '|COMMERCIAL ICE MACHINES--|',
  'Composite Fasteners': '|COMPOSITE FASTENERS--|',
  'Condiment Servers': '|CONDIMENT SERVERS--|',
  Condiments: '|CONDIMENTS--|',
  'Condiments & Dressing': '|CONDIMENTS & DRESSING--|',
  'Confetti Poppers': '|CONFETTI POPPERS--|',
  'Constipation Relief': '|CONSTIPATION RELIEF--|',
  'Construction Adhesive': '|CONSTRUCTION ADHESIVE--|',
  'Construction Paper': '|CONSTRUCTION PAPER--|',
  Containers: '|CONTAINERS--|',
  'Containers Storage': '|CONTAINERS STORAGE--|',
  'Cookie Mixes': '|COOKIE MIXES--|',
  Cookware: '|COOKWARE--|',
  'Cooling Gear Accessories': '|COOLING GEAR ACCESSORIES--|',
  'Cordials & Liqueurs': '|CORDIALS & LIQUEURS--|',
  'Cork Tiles': '|CORK TILES--|',
  'Cough & Cold Must Haves': '|COUGH & COLD MUST HAVES--|',
  'Cough & Cold Pediatric': '|COUGH & COLD PEDIATRIC--|',
  'Cough and Cold': '|COUGH AND COLD--|',
  'Cough Drops & Lozenges': '|COUGH DROPS & LOZENGES--|',
  'Countertop Blenders': '|COUNTERTOP BLENDERS--|',
  'Craft Glues': '|CRAFT GLUES--|',
  'Craft Sand': '|CRAFT SAND--|',
  'Crayola Toddler': '|CRAYOLA TODDLER--|',
  'Cream Cheese & Spreads': '|CREAM CHEESE & SPREADS--|',
  'Creams & Moisturizers': '|CREAMS & MOISTURIZERS--|',
  'Create Your Own Pizza': '|CREATE YOUR OWN PIZZA--|',
  'Cricut Infusible Ink': '|CRICUT INFUSIBLE INK--|',
  'Crispy Tenders By The Piece': '|CRISPY TENDERS BY THE PIECE--|',
  'Crochet Hooks': '|CROCHET HOOKS--|',
  'Crock-Pot': '|CROCK-POT--|',
  'Crowd Pleasers': '|CROWD PLEASERS--|',
  'Curly Hair': '|CURLY HAIR--|',
  Cutlery: '|CUTLERY--|',
  'Cutlery Sets': '|CUTLERY SETS--|',
  'Cutting Boards': '|CUTTING BOARDS--|',
  Danskin: '|DANSKIN--|',
  'Deep Dish Pizza': '|DEEP DISH PIZZA--|',
  'Dental Dog Treats': '|DENTAL DOG TREATS--|',
  'Dental Floss & Wax': '|DENTAL FLOSS & WAX--|',
  'Dental Treats': '|DENTAL TREATS--|',
  'Dessert & Fortified Wine': '|DESSERT & FORTIFIED WINE--|',
  Detailing: '|DETAILING--|',
  Detox: '|DETOX--|',
  'Diaper Rash Creams': '|DIAPER RASH CREAMS--|',
  'Dickies Mens Work Jackets': '|DICKIES MENS WORK JACKETS--|',
  Diffusers: '|DIFFUSERS--|',
  Dining: '|DINING--|',
  'Dinner Bold Bites': '|DINNER BOLD BITES--|',
  'Dinner Menu Burgers and Sandwiches (Served only 4 p.m. - 11 p.m., Monday - Friday; all day Saturday - Sunday)':
    '|DINNER MENU BURGERS AND SANDWICHES (SERVED ONLY 4 P.M. - 11 P.M., MONDAY - FRIDAY; ALL DAY SATURDAY - SUNDAY)--|',
  'Dinner Menu Perfect Combos (Served only 4 p.m. - 11 p.m., Monday - Friday; all day Saturday - Sunday)':
    '|DINNER MENU PERFECT COMBOS (SERVED ONLY 4 P.M. - 11 P.M., MONDAY - FRIDAY; ALL DAY SATURDAY - SUNDAY)--|',
  Dinnerware: '|DINNERWARE--|',
  Dips: '|DIPS--|',
  'dips and salsas': '|DIPS AND SALSAS--|',
  'Dips Salsas': '|DIPS SALSAS--|',
  'Dish Wands & Scrub Brushes': '|DISH WANDS & SCRUB BRUSHES--|',
  'Disinfecting Spray': '|DISINFECTING SPRAY--|',
  'Disney Frozen Plush Toys': '|DISNEY FROZEN PLUSH TOYS--|',
  'Disney Movies': '|DISNEY MOVIES--|',
  'Disposable Bakeware': '|DISPOSABLE BAKEWARE--|',
  'Disposable Cutlery': '|DISPOSABLE CUTLERY--|',
  'Dog Apparel & Dog Shoes': '|DOG APPAREL & DOG SHOES--|',
  'Dog Bowls & Food Storage': '|DOG BOWLS & FOOD STORAGE--|',
  'Dog Carriers': '|DOG CARRIERS--|',
  'Dog Dental Chews': '|DOG DENTAL CHEWS--|',
  'Dog Food Trays, Cups & Tubs': '|DOG FOOD TRAYS, CUPS & TUBS--|',
  'Door Seals': '|DOOR SEALS--|',
  'Door Stops': '|DOOR STOPS--|',
  'Drains & Drain Parts': '|DRAINS & DRAIN PARTS--|',
  'Drawer Pulls': '|DRAWER PULLS--|',
  'Dual Shower Heads': '|DUAL SHOWER HEADS--|',
  'Duck Weatherproofing': '|DUCK WEATHERPROOFING--|',
  'Duncan Hines': '|DUNCAN HINES--|',
  "Dunkin' Donuts Iced Coffee": "|DUNKIN' DONUTS ICED COFFEE--|",
  'Dust Pans': '|DUST PANS--|',
  Dusters: '|DUSTERS--|',
  'Ear Candles': '|EAR CANDLES--|',
  'Easy-Bake Oven': '|EASY-BAKE OVEN--|',
  'EcoTools Skincare Tools': '|ECOTOOLS SKINCARE TOOLS--|',
  'Eczema Treatment': '|ECZEMA TREATMENT--|',
  'Egg Substitutes': '|EGG SUBSTITUTES--|',
  Elbows: '|ELBOWS--|',
  'Electric Grills, Griddles, Waffle Makers':
    '|ELECTRIC GRILLS, GRIDDLES, WAFFLE MAKERS--|',
  'Electric Space Heaters': '|ELECTRIC SPACE HEATERS--|',
  'Electrical & Lighting': '|ELECTRICAL & LIGHTING--|',
  'Electrical Essentials': '|ELECTRICAL ESSENTIALS--|',
  'Electrical Needs': '|ELECTRICAL NEEDS--|',
  'Electronic Cat Toys': '|ELECTRONIC CAT TOYS--|',
  'Electronics > Electronics Accessories > Mounts & Brackets':
    '|ELECTRONICS-ELECTRONICS ACCESSORIES-MOUNTS & BRACKETS|',
  'Elf on the Shelf': '|ELF ON THE SHELF--|',
  'Enfamil Baby Formula': '|ENFAMIL BABY FORMULA--|',
  'Equate Beauty Face Cleansers': '|EQUATE BEAUTY FACE CLEANSERS--|',
  'Equate Gas Relief': '|EQUATE GAS RELIEF--|',
  'Equate Hot & Cold Packs': '|EQUATE HOT & COLD PACKS--|',
  'Equate Meal Replacement': '|EQUATE MEAL REPLACEMENT--|',
  'Equipment & Accessories': '|EQUIPMENT & ACCESSORIES--|',
  'Ethernet Cables': '|ETHERNET CABLES--|',
  'Euphoria Makeup': '|EUPHORIA MAKEUP--|',
  EverStart: '|EVERSTART--|',
  'Everyday Breakfast Favorites': '|EVERYDAY BREAKFAST FAVORITES--|',
  'Everyday Family Values': '|EVERYDAY FAMILY VALUES--|',
  'Exercise Bikes': '|EXERCISE BIKES--|',
  Expo: '|EXPO--|',
  'extension and power cords': '|EXTENSION AND POWER CORDS--|',
  'Exterior Car Accessories': '|EXTERIOR CAR ACCESSORIES--|',
  'Eye Primer & Base': '|EYE PRIMER & BASE--|',
  'Fabric Baskets & Bins': '|FABRIC BASKETS & BINS--|',
  'Fabric by the Yard': '|FABRIC BY THE YARD--|',
  'Fabric Conditioners': '|FABRIC CONDITIONERS--|',
  'Fabric Refreshers': '|FABRIC REFRESHERS--|',
  'Face Powder': '|FACE POWDER--|',
  'Face Scrubs For Dry Skin': '|FACE SCRUBS FOR DRY SKIN--|',
  'Face Toners For Sensitive Skin': '|FACE TONERS FOR SENSITIVE SKIN--|',
  'Face Wash & Cleansers': '|FACE WASH & CLEANSERS--|',
  'Face Wash For Dry Skin': '|FACE WASH FOR DRY SKIN--|',
  'Face Wash For Oil & Shine Control': '|FACE WASH FOR OIL & SHINE CONTROL--|',
  'Face Wash For Oily Skin': '|FACE WASH FOR OILY SKIN--|',
  'Fairy & String Lights': '|FAIRY & STRING LIGHTS--|',
  Family: '|FAMILY--|',
  'Fancy Feast Wet Cat Food': '|FANCY FEAST WET CAT FOOD--|',
  'Fancy Feast Wet Cat Food Variety Packs':
    '|FANCY FEAST WET CAT FOOD VARIETY PACKS--|',
  Fans: '|FANS--|',
  'Fashion Backpacks': '|FASHION BACKPACKS--|',
  'Fashionable Cat Collars': '|FASHIONABLE CAT COLLARS--|',
  'Faucet Mounted Replacement Filters':
    '|FAUCET MOUNTED REPLACEMENT FILTERS--|',
  'Febreze Fabric Air Freshener': '|FEBREZE FABRIC AIR FRESHENER--|',
  'Febreze Plug Ins': '|FEBREZE PLUG INS--|',
  'Feel Good Beauty Skincare': '|FEEL GOOD BEAUTY SKINCARE--|',
  'Feminine Wipes': '|FEMININE WIPES--|',
  'File Boxes': '|FILE BOXES--|',
  'File Dividers': '|FILE DIVIDERS--|',
  'Filler Paper': '|FILLER PAPER--|',
  'Film Accessories': '|FILM ACCESSORIES--|',
  'Filter Media': '|FILTER MEDIA--|',
  'Filters and Maintenance': '|FILTERS AND MAINTENANCE--|',
  'Finish Nails': '|FINISH NAILS--|',
  'Fireplaces & Accessories': '|FIREPLACES & ACCESSORIES--|',
  'Fish Food': '|FISH FOOD--|',
  'Fisher Price Baby and Toddler Toys':
    '|FISHER PRICE BABY AND TODDLER TOYS--|',
  'Fishing Nets': '|FISHING NETS--|',
  'Fishing Rod & Reel Combos': '|FISHING ROD & REEL COMBOS--|',
  Fiskars: '|FISKARS--|',
  'Fixed Blade Knives': '|FIXED BLADE KNIVES--|',
  'Fixed Shower Heads': '|FIXED SHOWER HEADS--|',
  'Flags & Flag Poles': '|FLAGS & FLAG POLES--|',
  'Flasks & Botas': '|FLASKS & BOTAS--|',
  'Flavored Rice': '|FLAVORED RICE--|',
  'Flea & Tick Treatment': '|FLEA & TICK TREATMENT--|',
  'Flexible Duct': '|FLEXIBLE DUCT--|',
  'Flexible Ductwork': '|FLEXIBLE DUCTWORK--|',
  'Floor Scrapers': '|FLOOR SCRAPERS--|',
  'Flower Seeds': '|FLOWER SEEDS--|',
  'Folding Knives': '|FOLDING KNIVES--|',
  'Food > Pasta > Seafood Pasta': '|FOOD-PASTA-SEAFOOD PASTA|',
  'Food > Sandwiches & Wraps > Veggie Wrap':
    '|FOOD-SANDWICHES & WRAPS-VEGGIE WRAP|',
  'Food > Soups & Salads': '|FOOD-SOUPS & SALADS-|',
  'Food > Tacos & Burritos > Veggie Tacos':
    '|FOOD-TACOS & BURRITOS-VEGGIE TACOS|',
  'Food > Vegetarian Entrees': '|FOOD-VEGETARIAN ENTREES-|',
  'Food & Drink Storage': '|FOOD & DRINK STORAGE--|',
  'Food Prep & Storage': '|FOOD PREP & STORAGE--|',
  'Food Scales': '|FOOD SCALES--|',
  'Food/Baking/Extracts & Colorings': '|FOOD/BAKING/EXTRACTS & COLORINGS--|',
  'Food/Beverages/Beer, Wine & Spirits/Beer':
    '|FOOD/BEVERAGES/BEER, WINE & SPIRITS/BEER--|',
  'Food/Beverages/Beer, Wine & Spirits/Wine':
    '|FOOD/BEVERAGES/BEER, WINE & SPIRITS/WINE--|',
  'Food/Breakfast & Cereal/Oatmeal & Grits/Oatmeal':
    '|FOOD/BREAKFAST & CEREAL/OATMEAL & GRITS/OATMEAL--|',
  'Food/Snacks, Cookies & Chips/Fruit Cups & Fruit Sauces':
    '|FOOD/SNACKS, COOKIES & CHIPS/FRUIT CUPS & FRUIT SAUCES--|',
  'Food/Soups': '|FOOD/SOUPS--|',
  'Foot Creams & Lotions': '|FOOT CREAMS & LOTIONS--|',
  'For Men': '|FOR MEN--|',
  'Fragrance Sprays': '|FRAGRANCE SPRAYS--|',
  'Fresh Perfume': '|FRESH PERFUME--|',
  'Freshwater Sand, Gravel, & Substrates':
    '|FRESHWATER SAND, GRAVEL, & SUBSTRATES--|',
  'Friskies Wet Cat Food Variety Packs':
    '|FRISKIES WET CAT FOOD VARIETY PACKS--|',
  'Frozen Bedding': '|FROZEN BEDDING--|',
  'Frozen Pies': '|FROZEN PIES--|',
  'Fruit & Vegetable': '|FRUIT & VEGETABLE--|',
  'Fruit of the Loom Socks': '|FRUIT OF THE LOOM SOCKS--|',
  Fryers: '|FRYERS--|',
  'Furniture Accessories': '|FURNITURE ACCESSORIES--|',
  'Furniture and Small Project Paint': '|FURNITURE AND SMALL PROJECT PAINT--|',
  'Gag Gifts': '|GAG GIFTS--|',
  Gain: '|GAIN--|',
  'Gaming Gift Cards': '|GAMING GIFT CARDS--|',
  'Garage Storage Systems': '|GARAGE STORAGE SYSTEMS--|',
  'Garden Carts': '|GARDEN CARTS--|',
  'Garden Hose Nozzles & Wands': '|GARDEN HOSE NOZZLES & WANDS--|',
  'Gardening & Tools': '|GARDENING & TOOLS--|',
  'Garlic Salt': '|GARLIC SALT--|',
  'Garment irons and steamers': '|GARMENT IRONS AND STEAMERS--|',
  'Gas Cutting': '|GAS CUTTING--|',
  'Gas String Trimmers': '|GAS STRING TRIMMERS--|',
  'Gelatin & Pudding': '|GELATIN & PUDDING--|',
  'George Socks': '|GEORGE SOCKS--|',
  Gifts: '|GIFTS--|',
  'Gillette Venus Razors': '|GILLETTE VENUS RAZORS--|',
  'Gingerbread House Kits': '|GINGERBREAD HOUSE KITS--|',
  'Girls > Shoes > Athletic Shoes & Sneakers':
    '|GIRLS-SHOES-ATHLETIC SHOES & SNEAKERS|',
  'Girls Backpacks': '|GIRLS BACKPACKS--|',
  'Girls Bedding': '|GIRLS BEDDING--|',
  'Girls Character Clothing': '|GIRLS CHARACTER CLOTHING--|',
  'Glitter Paint': '|GLITTER PAINT--|',
  'Glue & Tape': '|GLUE & TAPE--|',
  'Glue for Slime': '|GLUE FOR SLIME--|',
  'Glue Guns': '|GLUE GUNS--|',
  'Golf Accessories': '|GOLF ACCESSORIES--|',
  'Goodie Bags': '|GOODIE BAGS--|',
  'Grab-N-Go Paint': '|GRAB-N-GO PAINT--|',
  'Graduation Balloon Accessories': '|GRADUATION BALLOON ACCESSORIES--|',
  'Grain Free Dog Food': '|GRAIN FREE DOG FOOD--|',
  'Grass Seed': '|GRASS SEED--|',
  'Green Beans': '|GREEN BEANS--|',
  'Green Burritos Combos': '|GREEN BURRITOS COMBOS--|',
  'Grill Covers': '|GRILL COVERS--|',
  Grilles: '|GRILLES--|',
  'Grinding Wheels & Cut-Off Wheels': '|GRINDING WHEELS & CUT-OFF WHEELS--|',
  'Grocery > Cleaning Products > Vacuum Supplies':
    '|GROCERY-CLEANING PRODUCTS-VACUUM SUPPLIES|',
  'Grocery > Frozen > Frozen Meals': '|GROCERY-FROZEN-FROZEN MEALS|',
  'Grocery > Snacks > Bulk Nuts & Candy': '|GROCERY-SNACKS-BULK NUTS & CANDY|',
  'Grooming Gifts for Him': '|GROOMING GIFTS FOR HIM--|',
  'Ground Turkey': '|GROUND TURKEY--|',
  'Guest Favorites': '|GUEST FAVORITES--|',
  'Guinea Pigs': '|GUINEA PIGS--|',
  'Gummy Worms': '|GUMMY WORMS--|',
  'Gun Accessories': '|GUN ACCESSORIES--|',
  'Habit Treatment': '|HABIT TREATMENT--|',
  'Hair Bleach': '|HAIR BLEACH--|',
  'Hair Removal': '|HAIR REMOVAL--|',
  'Half & Half': '|HALF & HALF--|',
  'Hallmark Birthday Cards': '|HALLMARK BIRTHDAY CARDS--|',
  'Halloween Decorations': '|HALLOWEEN DECORATIONS--|',
  'Halloween Movies': '|HALLOWEEN MOVIES--|',
  'Halloween Themed Clothing': '|HALLOWEEN THEMED CLOTHING--|',
  Hammers: '|HAMMERS--|',
  Hampers: '|HAMPERS--|',
  Hamster: '|HAMSTER--|',
  Handbags: '|HANDBAGS--|',
  'Handheld Showerheads': '|HANDHELD SHOWERHEADS--|',
  "Hanes Women's Panties": "|HANES WOMEN'S PANTIES--|",
  'Hanging Closet Organizers': '|HANGING CLOSET ORGANIZERS--|',
  'Hard Candy Lipstick & Lip Liner': '|HARD CANDY LIPSTICK & LIP LINER--|',
  Harnesses: '|HARNESSES--|',
  'HART Markers & Pencils': '|HART MARKERS & PENCILS--|',
  'Hartz Pet Supplies': '|HARTZ PET SUPPLIES--|',
  'Hats, Gloves & Scarves': '|HATS, GLOVES & SCARVES--|',
  'HDMI Cables': '|HDMI CABLES--|',
  'Headlight Restoration': '|HEADLIGHT RESTORATION--|',
  Health: '|HEALTH--|',
  'Health & Beauty > Diet & Vitamins > Diet Aides':
    '|HEALTH & BEAUTY-DIET & VITAMINS-DIET AIDES|',
  'Health & Safety': '|HEALTH & SAFETY--|',
  'Heating and Lighting': '|HEATING AND LIGHTING--|',
  Hello: '|HELLO--|',
  'Hex Bolts': '|HEX BOLTS--|',
  'Holiday & Seasonal Goods': '|HOLIDAY & SEASONAL GOODS--|',
  'Home & Garden > Workbench': '|HOME & GARDEN-WORKBENCH-|',
  'Home / Food, Household & Pet / 2-Day Delivery':
    '|HOME / FOOD, HOUSEHOLD & PET / 2-DAY DELIVERY--|',
  'Home / Food, Household & Pet / Water & Beverages / Water':
    '|HOME / FOOD, HOUSEHOLD & PET / WATER & BEVERAGES / WATER--|',
  'Home / Janitorial / Cleaning Chemicals / Disinfectants':
    '|HOME / JANITORIAL / CLEANING CHEMICALS / DISINFECTANTS--|',
  'Home / Janitorial / Cleaning Chemicals / Laundry':
    '|HOME / JANITORIAL / CLEANING CHEMICALS / LAUNDRY--|',
  'Home Audio and Docking': '|HOME AUDIO AND DOCKING--|',
  'Home Diagnostic Tests': '|HOME DIAGNOSTIC TESTS--|',
  'Home Fragrance': '|HOME FRAGRANCE--|',
  'Horse Feed, Forage & Treats': '|HORSE FEED, FORAGE & TREATS--|',
  'Hot Dogs & Sausages': '|HOT DOGS & SAUSAGES--|',
  'Hot Sides': '|HOT SIDES--|',
  'Household Essentials/Bathroom/Candles':
    '|HOUSEHOLD ESSENTIALS/BATHROOM/CANDLES--|',
  'Household Essentials/Cleaning Supplies/Cleaning Tools/Scrub Brushes':
    '|HOUSEHOLD ESSENTIALS/CLEANING SUPPLIES/CLEANING TOOLS/SCRUB BRUSHES--|',
  'Hoverboards for Boys': '|HOVERBOARDS FOR BOYS--|',
  'HP Printers': '|HP PRINTERS--|',
  'Huffy BMX Bikes': '|HUFFY BMX BIKES--|',
  'Hydrating Camo Concealer': '|HYDRATING CAMO CONCEALER--|',
  'Hydration & Recovery': '|HYDRATION & RECOVERY--|',
  'Hyper Tough Outdoor Power Equipment':
    '|HYPER TOUGH OUTDOOR POWER EQUIPMENT--|',
  'Ice Breaker Mints': '|ICE BREAKER MINTS--|',
  'Ice Breakers Gum': '|ICE BREAKERS GUM--|',
  'Impact Drivers': '|IMPACT DRIVERS--|',
  'In-Game Currency': '|IN-GAME CURRENCY--|',
  Incontinence: '|INCONTINENCE--|',
  'Incontinence Care': '|INCONTINENCE CARE--|',
  Indoor: '|INDOOR--|',
  'Indoor Cat Dry Cat Food': '|INDOOR CAT DRY CAT FOOD--|',
  'Infused Mattress Topper': '|INFUSED MATTRESS TOPPER--|',
  'Ink Cartridges & Printers': '|INK CARTRIDGES & PRINTERS--|',
  'Inline Skates': '|INLINE SKATES--|',
  Insoles: '|INSOLES--|',
  'Instant Cameras': '|INSTANT CAMERAS--|',
  'Instant Soup & Meals': '|INSTANT SOUP & MEALS--|',
  'Interior Wood Stain': '|INTERIOR WOOD STAIN--|',
  'iPad Kits & Accessories': '|IPAD KITS & ACCESSORIES--|',
  'Iridescent Favors & Bags': '|IRIDESCENT FAVORS & BAGS--|',
  'Jack Stands': '|JACK STANDS--|',
  'Jams & Jellies': '|JAMS & JELLIES--|',
  'Jewelry & Bead Kits': '|JEWELRY & BEAD KITS--|',
  Jockey: '|JOCKEY--|',
  'Johnson & Johnson': '|JOHNSON & JOHNSON--|',
  'K-Beauty': '|K-BEAUTY--|',
  'Keto Basics': '|KETO BASICS--|',
  'Kick Scooters': '|KICK SCOOTERS--|',
  'Kid Connection Toys': '|KID CONNECTION TOYS--|',
  "Kid's Stomach Remedies": "|KID'S STOMACH REMEDIES--|",
  'Kids Dinnerware': '|KIDS DINNERWARE--|',
  'Kids Sandals': '|KIDS SANDALS--|',
  "Kids' & Family": "|KIDS' & FAMILY--|",
  "Kids' Apparel": "|KIDS' APPAREL--|",
  "Kids' Curtains": "|KIDS' CURTAINS--|",
  "Kids' Wall Art": "|KIDS' WALL ART--|",
  "Kids' Water Bottles": "|KIDS' WATER BOTTLES--|",
  'kitchen thermometers and calculators':
    '|KITCHEN THERMOMETERS AND CALCULATORS--|',
  'Kitchen Utensil Sets': '|KITCHEN UTENSIL SETS--|',
  Kitten: '|KITTEN--|',
  'Knee Braces': '|KNEE BRACES--|',
  'Knee Pads': '|KNEE PADS--|',
  Kombucha: '|KOMBUCHA--|',
  Kotex: '|KOTEX--|',
  "Kristen & Dax's Baby Registry Picks":
    "|KRISTEN & DAX'S BABY REGISTRY PICKS--|",
  "L'Oreal Elvive": "|L'OREAL ELVIVE--|",
  "L'Oreal Face Moisturizers": "|L'OREAL FACE MOISTURIZERS--|",
  'Lab Safety': '|LAB SAFETY--|',
  'Laminate Flooring': '|LAMINATE FLOORING--|',
  'Laminating Pouches': '|LAMINATING POUCHES--|',
  'Landscaping Rock': '|LANDSCAPING ROCK--|',
  'Laundry Baskets & Bags': '|LAUNDRY BASKETS & BAGS--|',
  'Laundry Organization': '|LAUNDRY ORGANIZATION--|',
  'Laundry Room Organization': '|LAUNDRY ROOM ORGANIZATION--|',
  'Laundry Supplies': '|LAUNDRY SUPPLIES--|',
  'Lawn & Leaf Rakes': '|LAWN & LEAF RAKES--|',
  'Leaf Blowers': '|LEAF BLOWERS--|',
  'Leg and Foot Care': '|LEG AND FOOT CARE--|',
  'Leggings & Pants': '|LEGGINGS & PANTS--|',
  'Lemon-Lime': '|LEMON-LIME--|',
  'Lemonade Mixes': '|LEMONADE MIXES--|',
  'Libman Brooms': '|LIBMAN BROOMS--|',
  Lighters: '|LIGHTERS--|',
  'Lip Liners & Pencils': '|LIP LINERS & PENCILS--|',
  'Liquid Eyeliner': '|LIQUID EYELINER--|',
  'Litter and Bedding': '|LITTER AND BEDDING--|',
  'Litter Boxes': '|LITTER BOXES--|',
  'Lock Washers': '|LOCK WASHERS--|',
  'LOL Surprise Party Tableware': '|LOL SURPRISE PARTY TABLEWARE--|',
  'Loop Bands': '|LOOP BANDS--|',
  Loppers: '|LOPPERS--|',
  Lubricants: '|LUBRICANTS--|',
  'Lump Charcoal & Briquettes': '|LUMP CHARCOAL & BRIQUETTES--|',
  'Lunch 1/2 Pound Steak Burgers': '|LUNCH 1/2 POUND STEAK BURGERS--|',
  Maidenform: '|MAIDENFORM--|',
  'Mainstays Cooking Utensils': '|MAINSTAYS COOKING UTENSILS--|',
  'Mainstays Pillow Shams': '|MAINSTAYS PILLOW SHAMS--|',
  'Makeup Palettes & Sets': '|MAKEUP PALETTES & SETS--|',
  'Makeup Tools': '|MAKEUP TOOLS--|',
  Malden: '|MALDEN--|',
  'Male Body Wash': '|MALE BODY WASH--|',
  'Malt Beverages': '|MALT BEVERAGES--|',
  Manicure: '|MANICURE--|',
  'massage and spa devices': '|MASSAGE AND SPA DEVICES--|',
  Massagers: '|MASSAGERS--|',
  'Mattress Toppers': '|MATTRESS TOPPERS--|',
  'Maybelline Foundation Deep Shades': '|MAYBELLINE FOUNDATION DEEP SHADES--|',
  'Maybelline Lipstick': '|MAYBELLINE LIPSTICK--|',
  MDF: '|MDF--|',
  'Meal Replacement Shakes': '|MEAL REPLACEMENT SHAKES--|',
  'Measuring Cups & Measuring Spoons': '|MEASURING CUPS & MEASURING SPOONS--|',
  'Measuring Cups & Spoons': '|MEASURING CUPS & SPOONS--|',
  'Meat & Seafood': '|MEAT & SEAFOOD--|',
  'Media & Memory': '|MEDIA & MEMORY--|',
  Medicine: '|MEDICINE--|',
  'Medium Local Delights': '|MEDIUM LOCAL DELIGHTS--|',
  'Medium Pizzas': '|MEDIUM PIZZAS--|',
  "Meguiar's": "|MEGUIAR'S--|",
  'Memory Support': '|MEMORY SUPPORT--|',
  Men: '|MEN--|',
  "Men's Apparel": "|MEN'S APPAREL--|",
  "Men's Beard Care": "|MEN'S BEARD CARE--|",
  "Men's Deodorants": "|MEN'S DEODORANTS--|",
  "Men's Watches": "|MEN'S WATCHES--|",
  'Mending Plates': '|MENDING PLATES--|',
  'Mens Active Jackets': '|MENS ACTIVE JACKETS--|',
  'Mens Basketball Sneakers': '|MENS BASKETBALL SNEAKERS--|',
  'Mens Lace-Up Boots': '|MENS LACE-UP BOOTS--|',
  'Mens Sweaters': '|MENS SWEATERS--|',
  'Mens Swimsuits': '|MENS SWIMSUITS--|',
  'Menstrual Cup': '|MENSTRUAL CUP--|',
  'Meow Mix Wet Cat Food Variety Packs':
    '|MEOW MIX WET CAT FOOD VARIETY PACKS--|',
  Mice: '|MICE--|',
  'Mice & Keyboards': '|MICE & KEYBOARDS--|',
  'Mice & Plush Cat Toys': '|MICE & PLUSH CAT TOYS--|',
  'Milani Eye Makeup': '|MILANI EYE MAKEUP--|',
  'Mile High Burgers': '|MILE HIGH BURGERS--|',
  'Milk Replacers': '|MILK REPLACERS--|',
  'Mindful Beauty Hair Care': '|MINDFUL BEAUTY HAIR CARE--|',
  Minecraft: '|MINECRAFT--|',
  'Minecraft Action Figures': '|MINECRAFT ACTION FIGURES--|',
  'Mini Brands': '|MINI BRANDS--|',
  'Mini Fridges': '|MINI FRIDGES--|',
  'Mini Waffle Makers': '|MINI WAFFLE MAKERS--|',
  Mirrors: '|MIRRORS--|',
  Monopoly: '|MONOPOLY--|',
  'Mortal Kombat 11': '|MORTAL KOMBAT 11--|',
  'Mosaic Tile': '|MOSAIC TILE--|',
  'Motor Oil & Chemicals': '|MOTOR OIL & CHEMICALS--|',
  'Mountain Bikes': '|MOUNTAIN BIKES--|',
  'Mueller Wrist Supports': '|MUELLER WRIST SUPPORTS--|',
  'Muffin Mix': '|MUFFIN MIX--|',
  'Muffins & Donuts': '|MUFFINS & DONUTS--|',
  'Multi Game Tables': '|MULTI GAME TABLES--|',
  'Multi-Bit Screwdrivers': '|MULTI-BIT SCREWDRIVERS--|',
  'My Life As Dollhouses & Playsets': '|MY LIFE AS DOLLHOUSES & PLAYSETS--|',
  'My Sweet Love': '|MY SWEET LOVE--|',
  'Mylar Balloons': '|MYLAR BALLOONS--|',
  'Nail Treatments': '|NAIL TREATMENTS--|',
  'Natural & Organic Hair Care': '|NATURAL & ORGANIC HAIR CARE--|',
  'Natural Bones': '|NATURAL BONES--|',
  'Natural Diapers': '|NATURAL DIAPERS--|',
  'Natural Dog Food': '|NATURAL DOG FOOD--|',
  'Nature Valley Granola Bars': '|NATURE VALLEY GRANOLA BARS--|',
  'Nerf Rival': '|NERF RIVAL--|',
  'Neutrogena Makeup': '|NEUTROGENA MAKEUP--|',
  'New Bright': '|NEW BRIGHT--|',
  'New in Bath & Body': '|NEW IN BATH & BODY--|',
  'New in Skin Care': '|NEW IN SKIN CARE--|',
  'New Music': '|NEW MUSIC--|',
  'New Single Serve Cups & Pods': '|NEW SINGLE SERVE CUPS & PODS--|',
  'Newborn Essentials (Preemie - 9M)': '|NEWBORN ESSENTIALS (PREEMIE - 9M)--|',
  'NFL Accessories': '|NFL ACCESSORIES--|',
  'Nickelodeon Slime': '|NICKELODEON SLIME--|',
  'Novelty Ice Cream & Bars': '|NOVELTY ICE CREAM & BARS--|',
  'Nozzles & Wands': '|NOZZLES & WANDS--|',
  'Number Balloons': '|NUMBER BALLOONS--|',
  'Nursing Bra': '|NURSING BRA--|',
  'Nut Driver Sets': '|NUT DRIVER SETS--|',
  'NYX Professional Lip Makeup': '|NYX PROFESSIONAL LIP MAKEUP--|',
  'Odor Absorbers': '|ODOR ABSORBERS--|',
  'OGX Styling Products': '|OGX STYLING PRODUCTS--|',
  'Omega-3 from Fish Oils': '|OMEGA-3 FROM FISH OILS--|',
  'onn. Audio': '|ONN. AUDIO--|',
  'onn. Screen Protectors for iPhone 11':
    '|ONN. SCREEN PROTECTORS FOR IPHONE 11--|',
  'Oral Thermometers': '|ORAL THERMOMETERS--|',
  'Oral-B Electric Toothbrushes': '|ORAL-B ELECTRIC TOOTHBRUSHES--|',
  'Organic Onions & Garlic': '|ORGANIC ONIONS & GARLIC--|',
  'Other Gaming Gift Cards': '|OTHER GAMING GIFT CARDS--|',
  'Other Notions': '|OTHER NOTIONS--|',
  'Other Outdoor Christmas Decor': '|OTHER OUTDOOR CHRISTMAS DECOR--|',
  'Outdoor Doormats': '|OUTDOOR DOORMATS--|',
  'Outdoor Griddle Tools & Accessories':
    '|OUTDOOR GRIDDLE TOOLS & ACCESSORIES--|',
  'Outdoor Torches': '|OUTDOOR TORCHES--|',
  'Outlet Adapters': '|OUTLET ADAPTERS--|',
  'Oven & Ranges Parts': '|OVEN & RANGES PARTS--|',
  'Packaging Supplies': '|PACKAGING SUPPLIES--|',
  'Paint & Body Repair': '|PAINT & BODY REPAIR--|',
  'Paint Additives': '|PAINT ADDITIVES--|',
  'Paint Roller Extension Poles': '|PAINT ROLLER EXTENSION POLES--|',
  'Paint Samples': '|PAINT SAMPLES--|',
  'Painters Tape': '|PAINTERS TAPE--|',
  'Pancake Mixes, Waffle Mixes & Syrup':
    '|PANCAKE MIXES, WAFFLE MIXES & SYRUP--|',
  'Pantene Styling Products': '|PANTENE STYLING PRODUCTS--|',
  "Papa's Wings": "|PAPA'S WINGS--|",
  "Parent's Choice": "|PARENT'S CHOICE--|",
  'Party > Gift Wrapping': '|PARTY-GIFT WRAPPING-|',
  'Party & Occasions': '|PARTY & OCCASIONS--|',
  'Party Snacks': '|PARTY SNACKS--|',
  'Patching & Spackling Compound': '|PATCHING & SPACKLING COMPOUND--|',
  'Patio Makeover Items': '|PATIO MAKEOVER ITEMS--|',
  'PC Season Passes': '|PC SEASON PASSES--|',
  'Peaches, Pears, & Plums': '|PEACHES, PEARS, & PLUMS--|',
  'Peanut Butter Pretzels': '|PEANUT BUTTER PRETZELS--|',
  'Pediatric Electrolyte': '|PEDIATRIC ELECTROLYTE--|',
  'Pencil Cases': '|PENCIL CASES--|',
  'Pepperoni & Salami': '|PEPPERONI & SALAMI--|',
  'Personal Care/Bath & Body/Body Lotions & Creams':
    '|PERSONAL CARE/BATH & BODY/BODY LOTIONS & CREAMS--|',
  'Personal Care/Hair Care/Textured Hair Care/All Textured Hair':
    '|PERSONAL CARE/HAIR CARE/TEXTURED HAIR CARE/ALL TEXTURED HAIR--|',
  'Personal Care/Oral Care/Oral Rinse & Mouthwash':
    '|PERSONAL CARE/ORAL CARE/ORAL RINSE & MOUTHWASH--|',
  "Personal Care/Shaving/Harry's": "|PERSONAL CARE/SHAVING/HARRY'S--|",
  'Personal Deep Deep Dish Pizzas': '|PERSONAL DEEP DEEP DISH PIZZAS--|',
  'Pet Halloween Costumes': '|PET HALLOWEEN COSTUMES--|',
  'Pet Repellent': '|PET REPELLENT--|',
  'Pet Toys': '|PET TOYS--|',
  'Pet Toys & Accessories': '|PET TOYS & ACCESSORIES--|',
  'Pets/Cats/Litter Center': '|PETS/CATS/LITTER CENTER--|',
  'Pets/Dogs/Dog Bowls & Feeding Supplies':
    '|PETS/DOGS/DOG BOWLS & FEEDING SUPPLIES--|',
  'Pets/Dogs/Wet Dog Food': '|PETS/DOGS/WET DOG FOOD--|',
  'Pets/Fish/Aquarium Filter Media': '|PETS/FISH/AQUARIUM FILTER MEDIA--|',
  'Pets/Fish/Filtration, Heaters & Supplies':
    '|PETS/FISH/FILTRATION, HEATERS & SUPPLIES--|',
  'Pez Candy': '|PEZ CANDY--|',
  'Phone Cards': '|PHONE CARDS--|',
  'Photo Albums': '|PHOTO ALBUMS--|',
  'Pickled Goods': '|PICKLED GOODS--|',
  'Picture Frames and Display Boxes': '|PICTURE FRAMES AND DISPLAY BOXES--|',
  'Pill Organizers': '|PILL ORGANIZERS--|',
  'Pink Party Tableware': '|PINK PARTY TABLEWARE--|',
  'Pins & Pincushions': '|PINS & PINCUSHIONS--|',
  'Pizza Snacks': '|PIZZA SNACKS--|',
  'Plant Based Beverages': '|PLANT BASED BEVERAGES--|',
  'Plastic Closet Shelf Brackets': '|PLASTIC CLOSET SHELF BRACKETS--|',
  'Plastic Edging': '|PLASTIC EDGING--|',
  'Plastic Storage Totes': '|PLASTIC STORAGE TOTES--|',
  'Plastic Totes': '|PLASTIC TOTES--|',
  Playsets: '|PLAYSETS--|',
  'PlayStation 4': '|PLAYSTATION 4--|',
  'PlayStation 4 (PS4) Accessories': '|PLAYSTATION 4 (PS4) ACCESSORIES--|',
  'PlayStation 4 (PS4) Consoles': '|PLAYSTATION 4 (PS4) CONSOLES--|',
  'PlayStation 4 Accessories': '|PLAYSTATION 4 ACCESSORIES--|',
  Plungers: '|PLUNGERS--|',
  'Plus Size Swimsuits': '|PLUS SIZE SWIMSUITS--|',
  'Plus Size Tops': '|PLUS SIZE TOPS--|',
  'Plush Dog Toys': '|PLUSH DOG TOYS--|',
  'Plush Toys': '|PLUSH TOYS--|',
  Plywood: '|PLYWOOD--|',
  'Polaroid Film & Camera Film': '|POLAROID FILM & CAMERA FILM--|',
  'Polyester Yarn': '|POLYESTER YARN--|',
  'Pool Games and Toys': '|POOL GAMES AND TOYS--|',
  'Poop Bags': '|POOP BAGS--|',
  'Poptarts & Toaster Pastries': '|POPTARTS & TOASTER PASTRIES--|',
  'Portable Air Compressor Accessories':
    '|PORTABLE AIR COMPRESSOR ACCESSORIES--|',
  'Potato Chips & Crisps': '|POTATO CHIPS & CRISPS--|',
  Potties: '|POTTIES--|',
  'Powdered Drink Mixes': '|POWDERED DRINK MIXES--|',
  'Power Screwdrivers': '|POWER SCREWDRIVERS--|',
  'Power Toothbrushes': '|POWER TOOTHBRUSHES--|',
  'Premium Sprinkles': '|PREMIUM SPRINKLES--|',
  'Pretend Electronic Toys': '|PRETEND ELECTRONIC TOYS--|',
  'Propane Tanks': '|PROPANE TANKS--|',
  'Protein Bars & Bites': '|PROTEIN BARS & BITES--|',
  'Pumpkin Spice Latte and Seasonal Coffees':
    '|PUMPKIN SPICE LATTE AND SEASONAL COFFEES--|',
  'Pure Balance Wet Dog Food': '|PURE BALANCE WET DOG FOOD--|',
  'Pure Leaf Tea': '|PURE LEAF TEA--|',
  'Purina Alpo Dry Dog Food': '|PURINA ALPO DRY DOG FOOD--|',
  'Push Up Bras': '|PUSH UP BRAS--|',
  'PVC Dual Rated Pipe': '|PVC DUAL RATED PIPE--|',
  'PVC DWV Fittings': '|PVC DWV FITTINGS--|',
  'PVC Sch 40 Fittings': '|PVC SCH 40 FITTINGS--|',
  'Radar Detectors': '|RADAR DETECTORS--|',
  Rawhide: '|RAWHIDE--|',
  Razer: '|RAZER--|',
  'Razors, Shaving & Hair Removal': '|RAZORS, SHAVING & HAIR REMOVAL--|',
  'RCA Projectors': '|RCA PROJECTORS--|',
  'Real Christmas Trees': '|REAL CHRISTMAS TREES--|',
  Rechargable: '|RECHARGABLE--|',
  'Red Blend': '|RED BLEND--|',
  'Refresh Your Skin': '|REFRESH YOUR SKIN--|',
  'Refried Beans': '|REFRIED BEANS--|',
  'Repair Clamps': '|REPAIR CLAMPS--|',
  'Replacement Blades': '|REPLACEMENT BLADES--|',
  'Replacement Screens & Tools': '|REPLACEMENT SCREENS & TOOLS--|',
  'Replacement Utility Blades': '|REPLACEMENT UTILITY BLADES--|',
  'Reptile Deals': '|REPTILE DEALS--|',
  'Reptile Supplies': '|REPTILE SUPPLIES--|',
  'Rescue Vehicles': '|RESCUE VEHICLES--|',
  'Restock on Body Care': '|RESTOCK ON BODY CARE--|',
  'Revlon Makeup': '|REVLON MAKEUP--|',
  'Revlon Tools & Accessories': '|REVLON TOOLS & ACCESSORIES--|',
  Ribs: '|RIBS--|',
  'rice and grains': '|RICE AND GRAINS--|',
  Robes: '|ROBES--|',
  Robotics: '|ROBOTICS--|',
  'Robots & Electronic Pets': '|ROBOTS & ELECTRONIC PETS--|',
  'rugs, mats and grips': '|RUGS, MATS AND GRIPS--|',
  Runners: '|RUNNERS--|',
  "Ryan's World Novelty Toys": "|RYAN'S WORLD NOVELTY TOYS--|",
  'Sand Toys': '|SAND TOYS--|',
  'Sanding Discs': '|SANDING DISCS--|',
  'Sandpaper Sheets': '|SANDPAPER SHEETS--|',
  'Sandwich Crackers': '|SANDWICH CRACKERS--|',
  'Santa Hats & Wear': '|SANTA HATS & WEAR--|',
  'Scissors and Trimmers': '|SCISSORS AND TRIMMERS--|',
  Scooters: '|SCOOTERS--|',
  'Scosche Phone Car Holders': '|SCOSCHE PHONE CAR HOLDERS--|',
  'Scotch Brite': '|SCOTCH BRITE--|',
  'Scrapers & Sharpeners': '|SCRAPERS & SHARPENERS--|',
  'Screwdriver Sets': '|SCREWDRIVER SETS--|',
  'Scrub Tops': '|SCRUB TOPS--|',
  'Seafood Entrees': '|SEAFOOD ENTREES--|',
  'Sewing Machine Needles': '|SEWING MACHINE NEEDLES--|',
  'Sewing Tools': '|SEWING TOOLS--|',
  'Shampoo and Conditioner': '|SHAMPOO AND CONDITIONER--|',
  Shareable: '|SHAREABLE--|',
  Shareables: '|SHAREABLES--|',
  'Sharper Image Toys': '|SHARPER IMAGE TOYS--|',
  'Sheba Cat Food': '|SHEBA CAT FOOD--|',
  'Shin Guards': '|SHIN GUARDS--|',
  'Shipping Labels': '|SHIPPING LABELS--|',
  'Shoe Cleaners': '|SHOE CLEANERS--|',
  Shoelaces: '|SHOELACES--|',
  Shoes: '|SHOES--|',
  'Shop All Batman': '|SHOP ALL BATMAN--|',
  'shop all boys': '|SHOP ALL BOYS--|',
  'Shop All Flags': '|SHOP ALL FLAGS--|',
  'Shop All Hyper Tough': '|SHOP ALL HYPER TOUGH--|',
  'Shop all Nightstands': '|SHOP ALL NIGHTSTANDS--|',
  'Shop All Probiotics': '|SHOP ALL PROBIOTICS--|',
  'Shop All ReliOn': '|SHOP ALL RELION--|',
  'Shop All Superman': '|SHOP ALL SUPERMAN--|',
  'Shop All Wine Glasses': '|SHOP ALL WINE GLASSES--|',
  'Shoulder Bags': '|SHOULDER BAGS--|',
  'Shower Caddies': '|SHOWER CADDIES--|',
  'Shower Curtain Rings': '|SHOWER CURTAIN RINGS--|',
  'Shower Faucets': '|SHOWER FAUCETS--|',
  Shrimp: '|SHRIMP--|',
  'Shrimp, Lobster, and Crab': '|SHRIMP, LOBSTER, AND CRAB--|',
  Shrubs: '|SHRUBS--|',
  'Signature Combinations': '|SIGNATURE COMBINATIONS--|',
  'Silverware & Utensil Storage': '|SILVERWARE & UTENSIL STORAGE--|',
  'Silverware Single Pieces': '|SILVERWARE SINGLE PIECES--|',
  'Similac Baby Formula': '|SIMILAC BABY FORMULA--|',
  'Sinful Colors': '|SINFUL COLORS--|',
  'Single Din Car Stereos': '|SINGLE DIN CAR STEREOS--|',
  'Skin Care Sets': '|SKIN CARE SETS--|',
  'Skin Treatments': '|SKIN TREATMENTS--|',
  Skirts: '|SKIRTS--|',
  'Small Animal Chew Toys': '|SMALL ANIMAL CHEW TOYS--|',
  'Small Animal Hideaways': '|SMALL ANIMAL HIDEAWAYS--|',
  'Small Plates': '|SMALL PLATES--|',
  'Smart Bulbs': '|SMART BULBS--|',
  'Smart Choices': '|SMART CHOICES--|',
  'Smartwatch Chargers': '|SMARTWATCH CHARGERS--|',
  'Smoking Cessation': '|SMOKING CESSATION--|',
  'Snow Shovels': '|SNOW SHOVELS--|',
  "Snyder's of Hanover": "|SNYDER'S OF HANOVER--|",
  Soap: '|SOAP--|',
  'Soap & Lotion Dispensers': '|SOAP & LOTION DISPENSERS--|',
  'Softwood & Hardwood Boards': '|SOFTWOOD & HARDWOOD BOARDS--|',
  'Solid Dog Harnesses': '|SOLID DOG HARNESSES--|',
  'Spa Accessories': '|SPA ACCESSORIES--|',
  Sparklers: '|SPARKLERS--|',
  Sparkling: '|SPARKLING--|',
  'Sparkling Juice': '|SPARKLING JUICE--|',
  'Special K': '|SPECIAL K--|',
  'Specialized Kitchen Tools': '|SPECIALIZED KITCHEN TOOLS--|',
  'Specialty Flavors': '|SPECIALTY FLAVORS--|',
  'Specialty Pretzels': '|SPECIALTY PRETZELS--|',
  'Specialty Spray Paint': '|SPECIALTY SPRAY PAINT--|',
  'Specialty Wraps': '|SPECIALTY WRAPS--|',
  'Spiral & Wireless Notebooks': '|SPIRAL & WIRELESS NOTEBOOKS--|',
  'Sport Field Gear': '|SPORT FIELD GEAR--|',
  'Sports & Outdoor': '|SPORTS & OUTDOOR--|',
  Sprinkles: '|SPRINKLES--|',
  'Sprinkles & Decorations': '|SPRINKLES & DECORATIONS--|',
  'Squash & Pumpkins': '|SQUASH & PUMPKINS--|',
  'Squishies & Squeeze Toys': '|SQUISHIES & SQUEEZE TOYS--|',
  'Stainless Steel Trash Cans': '|STAINLESS STEEL TRASH CANS--|',
  'Stair Nosing': '|STAIR NOSING--|',
  Stamping: '|STAMPING--|',
  'Standard Level': '|STANDARD LEVEL--|',
  'Standard Spout Faucets': '|STANDARD SPOUT FAUCETS--|',
  'Staple Guns': '|STAPLE GUNS--|',
  Staplers: '|STAPLERS--|',
  'Star Wars Food & Grocery': '|STAR WARS FOOD & GROCERY--|',
  'Starbucks Instant Coffee': '|STARBUCKS INSTANT COFFEE--|',
  'Steak and Ribs': '|STEAK AND RIBS--|',
  Steaks: '|STEAKS--|',
  'Steam Cleaners': '|STEAM CLEANERS--|',
  'Steel Wool': '|STEEL WOOL--|',
  'Stock up on Allergy': '|STOCK UP ON ALLERGY--|',
  'Storage Bags': '|STORAGE BAGS--|',
  'Storage Bins & Baskets': '|STORAGE BINS & BASKETS--|',
  'Stove Fuels & Firestarters': '|STOVE FUELS & FIRESTARTERS--|',
  'Stoves & Burners': '|STOVES & BURNERS--|',
  Streamers: '|STREAMERS--|',
  'String Cheese & Cheese Snacks': '|STRING CHEESE & CHEESE SNACKS--|',
  'String Lights': '|STRING LIGHTS--|',
  'Strip Lights': '|STRIP LIGHTS--|',
  'Stroller & Car Seat Toys': '|STROLLER & CAR SEAT TOYS--|',
  'Stuffed Animals & Plush': '|STUFFED ANIMALS & PLUSH--|',
  'Stylus Pens': '|STYLUS PENS--|',
  'Suave Styling Product': '|SUAVE STYLING PRODUCT--|',
  'Suavitel Liquid Fabric Softener': '|SUAVITEL LIQUID FABRIC SOFTENER--|',
  'Summer Fragrance': '|SUMMER FRAGRANCE--|',
  'Summer of Ice Cream 2': '|SUMMER OF ICE CREAM 2--|',
  'Supply Lines': '|SUPPLY LINES--|',
  'Surge Protectors': '|SURGE PROTECTORS--|',
  'Surveillance Equipment': '|SURVEILLANCE EQUIPMENT--|',
  'Swiss Miss': '|SWISS MISS--|',
  'T Posts': '|T POSTS--|',
  'Tablet Cases, Covers & Keyboard Folios':
    '|TABLET CASES, COVERS & KEYBOARD FOLIOS--|',
  Tablets: '|TABLETS--|',
  'Tablets & E-readers': '|TABLETS & E-READERS--|',
  'Tailgating Supplies': '|TAILGATING SUPPLIES--|',
  Taquitos: '|TAQUITOS--|',
  'Target > Baby > Bath & Potty > Baby Toiletries':
    '|TARGET > BABY > BATH & POTTY > BABY TOILETRIES--|',
  'Target > Electronics > Cell Phones > Cell Phone Cases':
    '|TARGET > ELECTRONICS > CELL PHONES > CELL PHONE CASES--|',
  'Target > Grocery > Beverages > Wine, Beer & Liquor > Wine':
    '|TARGET > GROCERY > BEVERAGES > WINE, BEER & LIQUOR > WINE--|',
  'Target > Grocery > Meat & Seafood > Pork':
    '|TARGET > GROCERY > MEAT & SEAFOOD > PORK--|',
  'Target > Health > First Aid': '|TARGET > HEALTH > FIRST AID--|',
  'Target > Health > Medicines & Treatments > Cough, Cold & Flu':
    '|TARGET > HEALTH > MEDICINES & TREATMENTS > COUGH, COLD & FLU--|',
  'Target > Home > Arts & Crafts > Drawing & Coloring':
    '|TARGET > HOME > ARTS & CRAFTS > DRAWING & COLORING--|',
  'Target > Home > Arts & Crafts > Painting':
    '|TARGET > HOME > ARTS & CRAFTS > PAINTING--|',
  'Target > Household Essentials > Disposable Tableware':
    '|TARGET > HOUSEHOLD ESSENTIALS > DISPOSABLE TABLEWARE--|',
  'Target > Household Essentials > Laundry Care':
    '|TARGET > HOUSEHOLD ESSENTIALS > LAUNDRY CARE--|',
  'Target > Kitchen & Dining > Glassware & Drinkware > Coffee Mugs & Tea Cups':
    '|TARGET > KITCHEN & DINING > GLASSWARE & DRINKWARE > COFFEE MUGS & TEA CUPS--|',
  ' Cats': '| CATS--|',
  ' Diapering Needs': '| DIAPERING NEEDS--|',
  ' Entertainment': '| ENTERTAINMENT--|',
  ' Novelty': '| NOVELTY--|',
  '-': '|---|',
  '1 Pole Breakers': '|1 POLE BREAKERS--|',
  '1-Subject Spiral Notebooks': '|1-SUBJECT SPIRAL NOTEBOOKS--|',
  '20% off Shoes with Target Circle': '|20% OFF SHOES WITH TARGET CIRCLE--|',
  '2DS Games': '|2DS GAMES--|',
  '300 Piece Jigsaws': '|300 PIECE JIGSAWS--|',
  '4K TCL TVs': '|4K TCL TVS--|',
  AAA: '|AAA--|',
  'Ab Machines': '|AB MACHINES--|',
  'ABS DWV Fittings': '|ABS DWV FITTINGS--|',
  'Accent Lights': '|ACCENT LIGHTS--|',
  'Access Points, Hubs & Switches': '|ACCESS POINTS, HUBS & SWITCHES--|',
  'Accessory Hangers, Hooks, and Racks':
    '|ACCESSORY HANGERS, HOOKS, AND RACKS--|',
  'ACE Knee Braces': '|ACE KNEE BRACES--|',
  Acne: '|ACNE--|',
  'Acne & Blemishes': '|ACNE & BLEMISHES--|',
  'Acne Cleansers': '|ACNE CLEANSERS--|',
  'Acrylic Yarn': '|ACRYLIC YARN--|',
  'Action & Adventure TV Shows': '|ACTION & ADVENTURE TV SHOWS--|',
  'Action Thriller Movies': '|ACTION THRILLER MOVIES--|',
  'Active & Outdoor Play': '|ACTIVE & OUTDOOR PLAY--|',
  'Additional Electrical Tools': '|ADDITIONAL ELECTRICAL TOOLS--|',
  'Adhesives & Glue': '|ADHESIVES & GLUE--|',
  'adidas Metro IV OTC Soccer Socks': '|ADIDAS METRO IV OTC SOCCER SOCKS--|',
  'Adult Toys': '|ADULT TOYS--|',
  'Adventure Force Action Figures': '|ADVENTURE FORCE ACTION FIGURES--|',
  'Aerators and Adaptors': '|AERATORS AND ADAPTORS--|',
  'Air Conditioning Chemicals and Refrigerants':
    '|AIR CONDITIONING CHEMICALS AND REFRIGERANTS--|',
  'Air Freshener Scented Candles': '|AIR FRESHENER SCENTED CANDLES--|',
  'Air Gun Ammunition': '|AIR GUN AMMUNITION--|',
  'Air Pumps & Airstones': '|AIR PUMPS & AIRSTONES--|',
  Airheads: '|AIRHEADS--|',
  'Alarm & Digital Clocks': '|ALARM & DIGITAL CLOCKS--|',
  'Alcove Shower Kits': '|ALCOVE SHOWER KITS--|',
  'All Auto Paint': '|ALL AUTO PAINT--|',
  'All Bathroom Air Fresheners': '|ALL BATHROOM AIR FRESHENERS--|',
  'All Bleach': '|ALL BLEACH--|',
  'All Boxed > Grocery > Salty Snacks': '|ALL BOXED-GROCERY-SALTY SNACKS|',
  'All Cabinet and Furniture Hardware':
    '|ALL CABINET AND FURNITURE HARDWARE--|',
  'All Cat Trees': '|ALL CAT TREES--|',
  'All Cell Phones with Plans': '|ALL CELL PHONES WITH PLANS--|',
  'All Coconut Water': '|ALL COCONUT WATER--|',
  'All Computer Cables & Connectors': '|ALL COMPUTER CABLES & CONNECTORS--|',
  'All Country Music': '|ALL COUNTRY MUSIC--|',
  'All Disposable Tabletop': '|ALL DISPOSABLE TABLETOP--|',
  'All Door Hardware': '|ALL DOOR HARDWARE--|',
  'All Drama': '|ALL DRAMA--|',
  'All Dry Cat Food': '|ALL DRY CAT FOOD--|',
  'All Dry Shampoo': '|ALL DRY SHAMPOO--|',
  'All Eggs': '|ALL EGGS--|',
  'All Electric Shavers': '|ALL ELECTRIC SHAVERS--|',
  'All Eyeliner': '|ALL EYELINER--|',
  'All Fabric Spray': '|ALL FABRIC SPRAY--|',
  'All Face Masks': '|ALL FACE MASKS--|',
  'All Flavored Water': '|ALL FLAVORED WATER--|',
  'All Garnier': '|ALL GARNIER--|',
  'All Glad': '|ALL GLAD--|',
  'All Gold Jewelry': '|ALL GOLD JEWELRY--|',
  'All Hair Dryers': '|ALL HAIR DRYERS--|',
  'All Handbags & Accessories': '|ALL HANDBAGS & ACCESSORIES--|',
  'All Interior Paint': '|ALL INTERIOR PAINT--|',
  "All Kid's Cold and Flu": "|ALL KID'S COLD AND FLU--|",
  'All Kids Costumes & Dress-Up': '|ALL KIDS COSTUMES & DRESS-UP--|',
  "All Kids' Accessories": "|ALL KIDS' ACCESSORIES--|",
  "All Kids' Bikes": "|ALL KIDS' BIKES--|",
  'All LED Light Bulbs': '|ALL LED LIGHT BULBS--|',
  'All LEGO': '|ALL LEGO--|',
  'All Liquid Laundry Detergent': '|ALL LIQUID LAUNDRY DETERGENT--|',
  'All Mailers': '|ALL MAILERS--|',
  'All Manual Toothbrushes': '|ALL MANUAL TOOTHBRUSHES--|',
  'All Minions': '|ALL MINIONS--|',
  'All Nursing': '|ALL NURSING--|',
  'All Oil & Shortening': '|ALL OIL & SHORTENING--|',
  'All onn. Computer Accessories': '|ALL ONN. COMPUTER ACCESSORIES--|',
  'All Paper Shredders': '|ALL PAPER SHREDDERS--|',
  'All Pokemon': '|ALL POKEMON--|',
  'All Portable Speakers': '|ALL PORTABLE SPEAKERS--|',
  'All Razors': '|ALL RAZORS--|',
  'All Rice': '|ALL RICE--|',
  'All Rust-Oleum Paint': '|ALL RUST-OLEUM PAINT--|',
  'All Setting Powder': '|ALL SETTING POWDER--|',
  'All Shoe Care & Repair': '|ALL SHOE CARE & REPAIR--|',
  'All Snack Mixes': '|ALL SNACK MIXES--|',
  'All Sugar and Sweeteners': '|ALL SUGAR AND SWEETENERS--|',
  'All Target Clean': '|ALL TARGET CLEAN--|',
  'All Trading Cards': '|ALL TRADING CARDS--|',
  'All Wahl': '|ALL WAHL--|',
  'Almond Butter': '|ALMOND BUTTER--|',
  'Aloe Vera Juice': '|ALOE VERA JUICE--|',
  'Anaheim Ducks': '|ANAHEIM DUCKS--|',
  'Anchors & Docking Accessories': '|ANCHORS & DOCKING ACCESSORIES--|',
  'AND1 Shorts': '|AND1 SHORTS--|',
  'Angle Brooms': '|ANGLE BROOMS--|',
  'Animal Crackers': '|ANIMAL CRACKERS--|',
  'Animal Crossing Merchandise': '|ANIMAL CROSSING MERCHANDISE--|',
  'Animation & Cartoons': '|ANIMATION & CARTOONS--|',
  'Anker Phone Accessories': '|ANKER PHONE ACCESSORIES--|',
  'Anker Power Banks': '|ANKER POWER BANKS--|',
  'Antacid Tablets': '|ANTACID TABLETS--|',
  'Antibiotic & Antiseptic': '|ANTIBIOTIC & ANTISEPTIC--|',
  'Antifreeze & Coolants': '|ANTIFREEZE & COOLANTS--|',
  'Appliance Power Cords': '|APPLIANCE POWER CORDS--|',
  Aprons: '|APRONS--|',
  'Aqua Culture Aquarium Plants': '|AQUA CULTURE AQUARIUM PLANTS--|',
  'Aqua Culture Aquariums': '|AQUA CULTURE AQUARIUMS--|',
  'Aqua Culture Fish, Reptile & Bird Food':
    '|AQUA CULTURE FISH, REPTILE & BIRD FOOD--|',
  'Aquarium Filters': '|AQUARIUM FILTERS--|',
  'Aquarium Water Conditioners & Additives':
    '|AQUARIUM WATER CONDITIONERS & ADDITIVES--|',
  'Archery Targets': '|ARCHERY TARGETS--|',
  'Argan Oil': '|ARGAN OIL--|',
  'Ariel Laundry Detergent': '|ARIEL LAUNDRY DETERGENT--|',
  'Art Sketchbooks and Paper': '|ART SKETCHBOOKS AND PAPER--|',
  'Artificial Plants': '|ARTIFICIAL PLANTS--|',
  'Artwork Cases and Portfolios': '|ARTWORK CASES AND PORTFOLIOS--|',
  'Asian Meals': '|ASIAN MEALS--|',
  'Asian Sauces': '|ASIAN SAUCES--|',
  'Athletic & Sneakers': '|ATHLETIC & SNEAKERS--|',
  'Athletic Sweatshirts': '|ATHLETIC SWEATSHIRTS--|',
  'Attractants & Feeders': '|ATTRACTANTS & FEEDERS--|',
  Audio: '|AUDIO--|',
  'Auto Safety Accessories': '|AUTO SAFETY ACCESSORIES--|',
  'AutoDeliver Household': '|AUTODELIVER HOUSEHOLD--|',
  'Autodrive Car Floor Mats': '|AUTODRIVE CAR FLOOR MATS--|',
  'Automotive Radiator Additives': '|AUTOMOTIVE RADIATOR ADDITIVES--|',
  Axe: '|AXE--|',
  'Axe Body Wash': '|AXE BODY WASH--|',
  Axes: '|AXES--|',
  'Azelaic Acid Suspension 10%': '|AZELAIC ACID SUSPENSION 10%--|',
  'Baby & Toddler Underwear & Undershirts':
    '|BABY & TODDLER UNDERWEAR & UNDERSHIRTS--|',
  'Baby Bath & Skincare': '|BABY BATH & SKINCARE--|',
  'Baby Bottle Drying Rack': '|BABY BOTTLE DRYING RACK--|',
  'Baby Boys Newborn Clothing': '|BABY BOYS NEWBORN CLOTHING--|',
  'Baby Deals': '|BABY DEALS--|',
  'Baby Diapers, Wipes & Training Pants':
    '|BABY DIAPERS, WIPES & TRAINING PANTS--|',
  'Baby Food Stage 1': '|BABY FOOD STAGE 1--|',
  'Baby Laundry': '|BABY LAUNDRY--|',
  'Baby Monitors and Timers': '|BABY MONITORS AND TIMERS--|',
  'Baby Placemats': '|BABY PLACEMATS--|',
  'Baby Tableware': '|BABY TABLEWARE--|',
  'Baby Walkers': '|BABY WALKERS--|',
  'Back of the House Supplies': '|BACK OF THE HOUSE SUPPLIES--|',
  'backpacks & messenger bags': '|BACKPACKS & MESSENGER BAGS--|',
  'Badminton Nets': '|BADMINTON NETS--|',
  Bagged: '|BAGGED--|',
  'Bags & Organizers': '|BAGS & ORGANIZERS--|',
  'Baguettes, Loaves & Breadsticks': '|BAGUETTES, LOAVES & BREADSTICKS--|',
  'Bake Shop': '|BAKE SHOP--|',
  'Baked Beans': '|BAKED BEANS--|',
  'Baking & Spices': '|BAKING & SPICES--|',
  'Baking Mix': '|BAKING MIX--|',
  'baking mixes and flours': '|BAKING MIXES AND FLOURS--|',
  'Baking Sheets & Bakeware Sets': '|BAKING SHEETS & BAKEWARE SETS--|',
  'Balloon Pumps & Inflators': '|BALLOON PUMPS & INFLATORS--|',
  'Bambini (Kids) Menu': '|BAMBINI (KIDS) MENU--|',
  'Bandages & Tapes': '|BANDAGES & TAPES--|',
  'Banners & Streamers': '|BANNERS & STREAMERS--|',
  'Bar Stools': '|BAR STOOLS--|',
  'Bar Tools': '|BAR TOOLS--|',
  Barbie: '|BARBIE--|',
  'Barbie Doll Clothing & Accessories':
    '|BARBIE DOLL CLOTHING & ACCESSORIES--|',
  Barrettes: '|BARRETTES--|',
  'Baseball Accessories': '|BASEBALL ACCESSORIES--|',
  Baseboard: '|BASEBOARD--|',
  'Basic Office Supplies': '|BASIC OFFICE SUPPLIES--|',
  'Bath & Shower': '|BATH & SHOWER--|',
  'Bath & Shower Accessories': '|BATH & SHOWER ACCESSORIES--|',
  'Bathroom Air Fresheners': '|BATHROOM AIR FRESHENERS--|',
  'Bathroom Cleaning & Plumbing': '|BATHROOM CLEANING & PLUMBING--|',
  'Bathroom Sink Faucet Handles': '|BATHROOM SINK FAUCET HANDLES--|',
  'Bathroom Vanity Tops': '|BATHROOM VANITY TOPS--|',
  'Battery Accessories': '|BATTERY ACCESSORIES--|',
  'Battling Tops': '|BATTLING TOPS--|',
  'BBQ, Chili, Ribs & Pork': '|BBQ, CHILI, RIBS & PORK--|',
  'Beach Towels': '|BEACH TOWELS--|',
  'Beading Kits': '|BEADING KITS--|',
  'Beans, Peas, & Okra': '|BEANS, PEAS, & OKRA--|',
  'Beauty Under $10': '|BEAUTY UNDER $10--|',
  "Beauty/Beauty by Topic/Beauty New Arrivals/Fragrance New Arrivals/Men's Cologne New Arrivals":
    "|BEAUTY/BEAUTY BY TOPIC/BEAUTY NEW ARRIVALS/FRAGRANCE NEW ARRIVALS/MEN'S COLOGNE NEW ARRIVALS--|",
  'Beauty/Makeup/Face': '|BEAUTY/MAKEUP/FACE--|',
  'Beauty/Skin Care/Facial Cleanser/Face Wash/Face Wash For Dry Skin':
    '|BEAUTY/SKIN CARE/FACIAL CLEANSER/FACE WASH/FACE WASH FOR DRY SKIN--|',
  'Beauty/Skin Care/Lip Care': '|BEAUTY/SKIN CARE/LIP CARE--|',
  'Bed Skirts & Dust Ruffles': '|BED SKIRTS & DUST RUFFLES--|',
  'Bedding & Blankets': '|BEDDING & BLANKETS--|',
  'Bedding & Litter': '|BEDDING & LITTER--|',
  'Beef Jerky & Dried Meat': '|BEEF JERKY & DRIED MEAT--|',
  'Beef Meals': '|BEEF MEALS--|',
  'Beer (Must be 21 to purchase and show ID at time of delivery)':
    '|BEER (MUST BE 21 TO PURCHASE AND SHOW ID AT TIME OF DELIVERY)--|',
  'Belkin Phone Batteries Battery Packs and Power':
    '|BELKIN PHONE BATTERIES BATTERY PACKS AND POWER--|',
  Bernat: '|BERNAT--|',
  'Best Sellers': '|BEST SELLERS--|',
  'BFF Liquid Liner': '|BFF LIQUID LINER--|',
  'Bibs & Burp Cloths': '|BIBS & BURP CLOTHS--|',
  'Big Boys Coats & Jackets': '|BIG BOYS COATS & JACKETS--|',
  'Big Boys One-piece Pajamas': '|BIG BOYS ONE-PIECE PAJAMAS--|',
  'Big Girls Bras': '|BIG GIRLS BRAS--|',
  'bike accessories': '|BIKE ACCESSORIES--|',
  'Bike Bags & Carriers': '|BIKE BAGS & CARRIERS--|',
  'Bike GPS Trackers': '|BIKE GPS TRACKERS--|',
  'Bike Locks': '|BIKE LOCKS--|',
  'Bike Pads': '|BIKE PADS--|',
  'Bike Repair Stands & Storage': '|BIKE REPAIR STANDS & STORAGE--|',
  'Bike Tubes': '|BIKE TUBES--|',
  'Biography & Autobiography': '|BIOGRAPHY & AUTOBIOGRAPHY--|',
  Biore: '|BIORE--|',
  'Biore Cleansers': '|BIORE CLEANSERS--|',
  'Bird Seed & Suet': '|BIRD SEED & SUET--|',
  'Biscuits & Bakery': '|BISCUITS & BAKERY--|',
  'Biscuits, Cookies & Snacks': '|BISCUITS, COOKIES & SNACKS--|',
  Bissell: '|BISSELL--|',
  'Bissell Hard Floor Cleaners': '|BISSELL HARD FLOOR CLEANERS--|',
  'Black Forest Candy': '|BLACK FOREST CANDY--|',
  'Black Forest Fruit Snacks': '|BLACK FOREST FRUIT SNACKS--|',
  'Black Mirrors': '|BLACK MIRRORS--|',
  'Black Scrubs': '|BLACK SCRUBS--|',
  'Black+Decker Handheld Vacuums': '|BLACK+DECKER HANDHELD VACUUMS--|',
  'Blank Media': '|BLANK MEDIA--|',
  'Blazers & Sport Coats': '|BLAZERS & SPORT COATS--|',
  'Blind Bag Toys': '|BLIND BAG TOYS--|',
  'Blinds Parts': '|BLINDS PARTS--|',
  'Block Cheese': '|BLOCK CHEESE--|',
  'Blue Buffalo Wet Cat Food': '|BLUE BUFFALO WET CAT FOOD--|',
  'Blue Diamond Almonds': '|BLUE DIAMOND ALMONDS--|',
  "Blue's Clues Shop All": "|BLUE'S CLUES SHOP ALL--|",
  'Bluetooth Headsets': '|BLUETOOTH HEADSETS--|',
  'Bobby Pins & Clips': '|BOBBY PINS & CLIPS--|',
  Bodum: '|BODUM--|',
  'Body Makeup': '|BODY MAKEUP--|',
  'Body Powder & Spray': '|BODY POWDER & SPRAY--|',
  'Body Treatments': '|BODY TREATMENTS--|',
  'Body Wash & Soap': '|BODY WASH & SOAP--|',
  'Body Waxing': '|BODY WAXING--|',
  'Bonding and Stabilizers': '|BONDING AND STABILIZERS--|',
  Bones: '|BONES--|',
  'Boots, Shoes & Socks': '|BOOTS, SHOES & SOCKS--|',
  'Bottled Coffee': '|BOTTLED COFFEE--|',
  'Bottled Kombucha Tea': '|BOTTLED KOMBUCHA TEA--|',
  'Bottles & Cups': '|BOTTLES & CUPS--|',
  'Bottles & Travel Mugs': '|BOTTLES & TRAVEL MUGS--|',
  'Box Nails': '|BOX NAILS--|',
  'Boy Walkers': '|BOY WALKERS--|',
  'Boys > Shoes > Athletic Shoes & Sneakers':
    '|BOYS-SHOES-ATHLETIC SHOES & SNEAKERS|',
  'Boys Accessories Savings': '|BOYS ACCESSORIES SAVINGS--|',
  Bralette: '|BRALETTE--|',
  'Bread Crumbs': '|BREAD CRUMBS--|',
  'Breadcrumbs & Coatings': '|BREADCRUMBS & COATINGS--|',
  'Breads & Doughs': '|BREADS & DOUGHS--|',
  'Breakfast Breads, Donuts & More': '|BREAKFAST BREADS, DONUTS & MORE--|',
  'Breast Feeding Accessories': '|BREAST FEEDING ACCESSORIES--|',
  Breastfeeding: '|BREASTFEEDING--|',
  'Bricks & Blocks': '|BRICKS & BLOCKS--|',
  'Bridal Sets': '|BRIDAL SETS--|',
  'Briefcases & Padfolios': '|BRIEFCASES & PADFOLIOS--|',
  Broccoli: '|BROCCOLI--|',
  'Brochure Holders': '|BROCHURE HOLDERS--|',
  'Brow Cream': '|BROW CREAM--|',
  'Brown Eyeliner': '|BROWN EYELINER--|',
  Brownies: '|BROWNIES--|',
  'Brush Sets': '|BRUSH SETS--|',
  Bubly: '|BUBLY--|',
  'Bug Killer Spray': '|BUG KILLER SPRAY--|',
  'Bulk Sausage': '|BULK SAUSAGE--|',
  'Bully Sticks': '|BULLY STICKS--|',
  'Bungee Cords': '|BUNGEE CORDS--|',
  'Burritos & Taquitos': '|BURRITOS & TAQUITOS--|',
  'Buttermilk & Egg Nog': '|BUTTERMILK & EGG NOG--|',
  'Cabernet Sauvignon': '|CABERNET SAUVIGNON--|',
  'Cabinet Bumpers': '|CABINET BUMPERS--|',
  'Cabinet Organizers': '|CABINET ORGANIZERS--|',
  Cables: '|CABLES--|',
  'Cafe Salads': '|CAFE SALADS--|',
  'Caffeine-Free Sodas': '|CAFFEINE-FREE SODAS--|',
  'Cages & Care': '|CAGES & CARE--|',
  'Cake & Cobbler': '|CAKE & COBBLER--|',
  'Cake & Cupcake Supplies': '|CAKE & CUPCAKE SUPPLIES--|',
  'Cake Pans': '|CAKE PANS--|',
  'Cake Stands & Tiered Servers': '|CAKE STANDS & TIERED SERVERS--|',
  'Cakes, Pies & Desserts': '|CAKES, PIES & DESSERTS--|',
  'Cakes, Pies, & Desserts': '|CAKES, PIES, & DESSERTS--|',
  'Camouflage Fabric': '|CAMOUFLAGE FABRIC--|',
  'Camping Tables': '|CAMPING TABLES--|',
  'Candle Wax': '|CANDLE WAX--|',
  'Candles & Scents': '|CANDLES & SCENTS--|',
  'Candles & Wax Melts': '|CANDLES & WAX MELTS--|',
  'Candles Scents': '|CANDLES SCENTS--|',
  'Canned Meat': '|CANNED MEAT--|',
  'Canned Seafood': '|CANNED SEAFOOD--|',
  'Canning Supplies': '|CANNING SUPPLIES--|',
  'Canola Oil': '|CANOLA OIL--|',
  'Captain Marvel Toys': '|CAPTAIN MARVEL TOYS--|',
  'Car Electronics': '|CAR ELECTRONICS--|',
  'Car Fluids': '|CAR FLUIDS--|',
  'Car Seat Canopies': '|CAR SEAT CANOPIES--|',
  'Car Soap': '|CAR SOAP--|',
  'Car Speaker & Subwoofer Wiring': '|CAR SPEAKER & SUBWOOFER WIRING--|',
  'Car Wash Kit': '|CAR WASH KIT--|',
  'Car Wash on Advance Auto Parts Shop':
    '|CAR WASH ON ADVANCE AUTO PARTS SHOP--|',
  'Carbon Monoxide Detectors': '|CARBON MONOXIDE DETECTORS--|',
  'Card & Table Games': '|CARD & TABLE GAMES--|',
  'Cards & Decoration': '|CARDS & DECORATION--|',
  'Carlson Pet Products Dog Gates': '|CARLSON PET PRODUCTS DOG GATES--|',
  'Carnation Breakfast Essentials': '|CARNATION BREAKFAST ESSENTIALS--|',
  'Carpet & Fabric Cleaners': '|CARPET & FABRIC CLEANERS--|',
  'Carpet & Floor Cleaners': '|CARPET & FLOOR CLEANERS--|',
  'Carriage Bolts': '|CARRIAGE BOLTS--|',
  Cases: '|CASES--|',
  'Cases & Sleeves': '|CASES & SLEEVES--|',
  'Cash Registers, Rolls & POS Devices':
    '|CASH REGISTERS, ROLLS & POS DEVICES--|',
  Casing: '|CASING--|',
  'Casting Tools': '|CASTING TOOLS--|',
  'Cat Beds': '|CAT BEDS--|',
  'Cat Flea & Tick Spot Treatments': '|CAT FLEA & TICK SPOT TREATMENTS--|',
  'Cat Food Variety Packs': '|CAT FOOD VARIETY PACKS--|',
  'Cat Health & Grooming': '|CAT HEALTH & GROOMING--|',
  'Cat Litter & Accessories': '|CAT LITTER & ACCESSORIES--|',
  'Cat Litter Scoops': '|CAT LITTER SCOOPS--|',
  'Cat Litter, Boxes & Supplies': '|CAT LITTER, BOXES & SUPPLIES--|',
  'Catering Box Lunches': '|CATERING BOX LUNCHES--|',
  'Catnip & Grass': '|CATNIP & GRASS--|',
  'Catnip and Pet Grass': '|CATNIP AND PET GRASS--|',
  'Cave Club': '|CAVE CLUB--|',
  'Cell Phone & Tablet Accessories': '|CELL PHONE & TABLET ACCESSORIES--|',
  'Cell Phones With Plans': '|CELL PHONES WITH PLANS--|',
  'Cellucor Shop All': '|CELLUCOR SHOP ALL--|',
  'Ceramics & Pottery': '|CERAMICS & POTTERY--|',
  Cereal: '|CEREAL--|',
  'Cereal & Breakfast Food': '|CEREAL & BREAKFAST FOOD--|',
  'Cereal & Oatmeal': '|CEREAL & OATMEAL--|',
  'Cesar Dry Dog Food': '|CESAR DRY DOG FOOD--|',
  'Chair Pads & Chair Cushions': '|CHAIR PADS & CHAIR CUSHIONS--|',
  'Champagne & Sparkling': '|CHAMPAGNE & SPARKLING--|',
  'Charcoal Pencils': '|CHARCOAL PENCILS--|',
  Chardonnay: '|CHARDONNAY--|',
  'Cheddar & Jack Cheese': '|CHEDDAR & JACK CHEESE--|',
  Cheesecake: '|CHEESECAKE--|',
  'Chemicals Water Care': '|CHEMICALS WATER CARE--|',
  'Chest Decongestants': '|CHEST DECONGESTANTS--|',
  'Chest Safes': '|CHEST SAFES--|',
  'Chew Toys': '|CHEW TOYS--|',
  'Chicken and Seafood': '|CHICKEN AND SEAFOOD--|',
  'Chicken Health & Supplements': '|CHICKEN HEALTH & SUPPLEMENTS--|',
  'Children & Family': '|CHILDREN & FAMILY--|',
  'Chilled Platter': '|CHILLED PLATTER--|',
  'Chips & Dips': '|CHIPS & DIPS--|',
  'Chips - Potato': '|CHIPS - POTATO--|',
  'Chlorine Tablets': '|CHLORINE TABLETS--|',
  'Chocolate & Caramels': '|CHOCOLATE & CARAMELS--|',
  'Cholesterol Support': '|CHOLESTEROL SUPPORT--|',
  'Christmas Ball Ornaments': '|CHRISTMAS BALL ORNAMENTS--|',
  'Christmas Character Party': '|CHRISTMAS CHARACTER PARTY--|',
  'Christmas Garlands': '|CHRISTMAS GARLANDS--|',
  'Christmas Gift Wrap': '|CHRISTMAS GIFT WRAP--|',
  'Christmas Hooks & Hangers': '|CHRISTMAS HOOKS & HANGERS--|',
  'Christmas Inflatables': '|CHRISTMAS INFLATABLES--|',
  'Christmas Light Accessories': '|CHRISTMAS LIGHT ACCESSORIES--|',
  'Christmas Wreaths': '|CHRISTMAS WREATHS--|',
  'Christmas Wreaths & Garlands': '|CHRISTMAS WREATHS & GARLANDS--|',
  'Circulatory Support': '|CIRCULATORY SUPPORT--|',
  'Citronella Candles & Torches': '|CITRONELLA CANDLES & TORCHES--|',
  'Clairol Hair Color': '|CLAIROL HAIR COLOR--|',
  'Classic Briefs': '|CLASSIC BRIEFS--|',
  'Classic Pretzels': '|CLASSIC PRETZELS--|',
  'Cleaners & Cleaning Supplies': '|CLEANERS & CLEANING SUPPLIES--|',
  'Cleaning & Maintenance': '|CLEANING & MAINTENANCE--|',
  'Cleaning Essentials': '|CLEANING ESSENTIALS--|',
  'Cleaning Rags': '|CLEANING RAGS--|',
  'Clear Gel Deodorants': '|CLEAR GEL DEODORANTS--|',
  'Clips & Bobby Pins': '|CLIPS & BOBBY PINS--|',
  'Closet Organizers': '|CLOSET ORGANIZERS--|',
  'Cloth Diapers': '|CLOTH DIAPERS--|',
  'Clothes Pins': '|CLOTHES PINS--|',
  'Clothing > Girls >  Activewear': '|CLOTHING-GIRLS- ACTIVEWEAR|',
  'Clothing > Mens > Outerwear': '|CLOTHING-MENS-OUTERWEAR|',
  'Clothing > Mens > Underwear': '|CLOTHING-MENS-UNDERWEAR|',
  'Clothing > Womens > Activewear': '|CLOTHING-WOMENS-ACTIVEWEAR|',
  'Clothing > Womens > Socks & Hosiery': '|CLOTHING-WOMENS-SOCKS & HOSIERY|',
  'Clothing > Womens > Swimwear': '|CLOTHING-WOMENS-SWIMWEAR|',
  'Clothing Steamers': '|CLOTHING STEAMERS--|',
  'Clothing, Shoes & Jewelry': '|CLOTHING, SHOES & JEWELRY--|',
  'Cloths & Wipes': '|CLOTHS & WIPES--|',
  'Cloud Slime': '|CLOUD SLIME--|',
  Coasters: '|COASTERS--|',
  Coats: '|COATS--|',
  'Coca-Cola Zero': '|COCA-COLA ZERO--|',
  'Cocoa & Hot Drinks': '|COCOA & HOT DRINKS--|',
  'Coconut Milk': '|COCONUT MILK--|',
  'Coffee and Espresso Makers': '|COFFEE AND ESPRESSO MAKERS--|',
  'Coffee Prep': '|COFFEE PREP--|',
  'Coin Counters, Sorters & Changers': '|COIN COUNTERS, SORTERS & CHANGERS--|',
  'Colby/Monterey Jack Cheese': '|COLBY/MONTEREY JACK CHEESE--|',
  'Cold Heroes': '|COLD HEROES--|',
  'Cold Weather Beverages': '|COLD WEATHER BEVERAGES--|',
  'Collage Picture Frames': '|COLLAGE PICTURE FRAMES--|',
  'College Self-Care': '|COLLEGE SELF-CARE--|',
  'Cologne for Men': '|COLOGNE FOR MEN--|',
  'Color Care': '|COLOR CARE--|',
  'Colored Paper': '|COLORED PAPER--|',
  'Combination Wrenches': '|COMBINATION WRENCHES--|',
  Combo: '|COMBO--|',
  'Comedy TV Shows': '|COMEDY TV SHOWS--|',
};

export const ProductCategoryKeys = Object.keys(ProductCategories);
export const ProductCategoryValues = Object.values(ProductCategories);
export type ProductCategoryKey = typeof ProductCategoryKeys[number];
export type ProductCategoryValue = typeof ProductCategoryValues[number];

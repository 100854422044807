import React from 'react';
import * as Styled from './index.styles';

const NotFound: React.FC = () => {
  return (
    <Styled.NotFoundContainer>
      <Styled.NotFoundUnknown>
        <Styled.NotFoundUnknownText>
          404 Error: This page does not exist.
        </Styled.NotFoundUnknownText>
      </Styled.NotFoundUnknown>
    </Styled.NotFoundContainer>
  );
};

export default NotFound;

import styled from 'styled-components';
import tokens from 'tokens';

export const AbTestName = styled.div`
  max-width: 350px;
  & > * {
    margin-right: ${tokens.spacing.sm};
  }
  display: flex;
  font-size: 16px;
`;

export const InactiveAbTestIcon = styled.img`
  height: 25px;
  filter: invert(85%) sepia(1%) saturate(0%) hue-rotate(15deg) brightness(92%)
    contrast(86%);
`;

export const ActiveAbTestIcon = styled.img`
  height: 25px;
  filter: invert(54%) sepia(31%) saturate(665%) hue-rotate(115deg)
    brightness(93%) contrast(90%);
`;

export const ButtonSpacing = styled.div`
  display: flex;
  margin-left: -10px;
  float: inline-end;
  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const TableDataItem = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const RightAlignedTableDataItem = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 8px;
`;

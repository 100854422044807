// Error messages leveraging formik. See docs here... https://formik.org/docs/overview

import { ErrorMessage } from 'formik';
import * as Styled from './index.styles';

export interface ErrorMsgProps {
  name: string; // corresponds to name of of formik field
}

const ErrorMsg = ({ name }: ErrorMsgProps) => {
  return (
    <Styled.Wrapper>
      <ErrorMessage name={name} />
    </Styled.Wrapper>
  );
};

export default ErrorMsg;

//leveraging https://react-table.tanstack.com/docs/overview

import { TABLE_SORT_TOOLTIP_TEXT } from '../../constants';
import { TableInstance, useSortBy, useTable } from 'react-table';
import * as Styled from './index.styles';

const Table = ({
  columns,
  data,
  sortBy,
  onSort,
  operations,
  disabledColumns, // Add disabledColumns prop
  hiddenColumns, // Add Hidden Columns
}: TableInstance) => {
  const tableProps = {
    columns,
    data,
  };
  if (sortBy) {
    tableProps.initialState = {
      sortBy: sortBy || [],
    };
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(tableProps, useSortBy);

  return (
    <Styled.Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, j) => (
              <TableHeader
                operations={operations}
                onSort={onSort}
                column={column}
                j={j}
                disabled={
                  disabledColumns && disabledColumns.includes(column.id)
                }
                hidden={hiddenColumns && hiddenColumns.includes(column.id)}
              />
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Styled.TableBodyRow {...row.getRowProps()} key={i}>
              {row.cells.map((cell, j) => {
                const isHidden =
                  hiddenColumns && hiddenColumns.includes(cell.column.id);
                return (
                  <Styled.TableCell
                    {...cell.getCellProps()}
                    key={j}
                    style={{ display: isHidden ? 'none' : '' }} // Hide the cell if it's in hiddenColumns
                  >
                    {cell.render('Cell')}
                  </Styled.TableCell>
                );
              })}
            </Styled.TableBodyRow>
          );
        })}
      </tbody>
    </Styled.Table>
  );
};

const TableHeader = (props) => {
  const { operations, onSort, j, column, disabled, hidden } = props;
  const handleMultiSortBy = (column) => {
    // Check if column is not disabled
    if (!disabled) {
      onSort({ column: column.id });
    }
  };

  if (operations) {
    return (
      <Styled.TableHeader
        {...column.getHeaderProps(column.getSortByToggleProps())}
        onClick={() => onSort && handleMultiSortBy(column)}
        key={j}
        title={!disabled && TABLE_SORT_TOOLTIP_TEXT}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          display: hidden ? 'none' : '',
        }} // Change cursor style in case of column is disabled
      >
        <Styled.TableFlex>
          <Styled.HeaderName>{column.render('Header')}</Styled.HeaderName>
          <ColumnSortArrow {...props} />
        </Styled.TableFlex>
      </Styled.TableHeader>
    );
  } else {
    return (
      <Styled.TableHeader
        {...column.getHeaderProps(column.getSortByToggleProps())}
        key={j}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          display: hidden ? 'none' : '',
        }}
      >
        <Styled.TableFlex>
          <Styled.HeaderName>{column.render('Header')}</Styled.HeaderName>
          <ColumnSortArrow {...props} />
        </Styled.TableFlex>
      </Styled.TableHeader>
    );
  }
};

const ColumnSortArrow = (props) => {
  const { operations, column } = props;

  const renderSortIcon = (column) => {
    if (operations) {
      if (operations.sortBy === column.id) {
        return operations.sortOrder === 'ASC' ? '↑' : '↓';
      } else {
        return '';
      }
    } else {
      return column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : '↓';
    }
  };

  if (operations) {
    return <Styled.SortArrow>{renderSortIcon(column)}</Styled.SortArrow>;
  } else {
    return (
      <Styled.SortArrow
        style={{
          opacity: column.isSorted ? '1' : '0',
        }}
      >
        {renderSortIcon(column)}
      </Styled.SortArrow>
    );
  }
};

export default Table;

import React, { useState } from 'react';
import { Button, IconButton } from '@klover/attain-design-system';
import { exportSalesLiftStudy, setSalesLiftExportDialogOpen } from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'reactfire';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    divider: {
      borderLeft: '0.2em solid #ccc',
    },
    inputs: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
  },
});

const ExportDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const open = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.salesLiftExportDialogOpen
  );
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  const { data: user } = useUser();
  const currentUser = user || { email: '' };
  const [emails, setEmails] = useState(currentUser.email);
  const [mediaspend, setMediaSpend] = useState(null);

  const dispatchClose = () => {
    dispatch(setSalesLiftExportDialogOpen(false));
  };

  const handleEmailsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const emails = event.target.value as string;
    setEmails(emails);
  };

  const handleExport = () => {
    if (emails && emails !== '' && mediaspend && currentSalesLiftStudy) {
      dispatch(setSalesLiftExportDialogOpen(true));
      dispatch(
        exportSalesLiftStudy({
          emails,
          mediaspend,
          salesliftstudyid: currentSalesLiftStudy.salesliftstudyid,
        })
      );
      dispatchClose();
    }
  };

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          onClose={handleClose}
          open={open}
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.title}>
                  Export Sales Lift Study
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          {/* <Box m={3}> */}
          <DialogContent style={{ padding: '24px 32px 0px 32px' }}>
            <DialogContentText
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              Supply at least one email address (or multiple separated by
              commas) to send a CSV file with the Sales Lift Study you created.
            </DialogContentText>
            <TextField
              className={classes.inputs}
              onChange={handleEmailsChange}
              value={emails}
              fullWidth
              id="email"
              label="Email Address"
              type="email"
              variant="outlined"
            />
          </DialogContent>
          <DialogContent style={{ padding: '0px 32px 0px 32px' }}>
            <TextField
              className={classes.inputs}
              onChange={(e) => setMediaSpend(e.target.value)}
              value={mediaspend}
              fullWidth
              id="mediaspend"
              label="Media Spend"
              type="number"
              variant="outlined"
            />
          </DialogContent>
          {/* </Box> */}

          {/* Bottom Divider */}
          <Grid container spacing={0} style={{ marginTop: 50 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Actions */}
          <Box m={1}>
            <DialogActions>
              <Button color="primary" onClick={handleClose} variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                disableElevation
                disabled={!emails?.length || !mediaspend}
                onClick={handleExport}
                variant="contained"
              >
                Export Sales Lift
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default ExportDialog;

const creativeDetailsKloverPlus = {
  imageUrl: 'logourl',
  subline: 'headline',
  body: 'description',
  comparisonEmoji: 'comparisonemoji',
  comparisonText: 'comparisontext',
  callToAction: 'ctatext',
  clickthroughUrl: 'ctaurl',
  zIndex: 'zindex',
};

export default creativeDetailsKloverPlus;

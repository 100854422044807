import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import KloverPiggyLogo from 'assets/images/KloverPiggyLogo.png';
import KloverPlusLogo from 'assets/images/Klover+.svg';
import tokens from 'tokens';

const cardWidth = 415;
const cardHeight = 100;
const borderRadius = 10;
const emojiDiameter = 30;
const useStyles = makeStyles(() => ({
  cardContainer: {
    color: tokens.colorDefault,
    width: cardWidth,
    backgroundColor: tokens.colorSecondary,
    height: cardHeight,
    borderRadius: borderRadius,
    border: '1px solid ' + tokens.colorSuccess,
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  bottomCardStart: {
    width: '80%',
    // backgroundColor: 'magenta',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
  },
  bottomCardEnd: {
    width: '15.5%',
    // backgroundColor: 'green',
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'right',
    alignItems: 'center',
  },
  topCard: {
    height: (cardHeight * 2) / 3,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topPinkFlagContainer: {
    // backgroundColor: 'blue',
    display: 'flex',
    height: '40%',
    width: '100%',
    justifyContent: 'right',
  },
  topPinkFlag: {
    backgroundColor: tokens.colorSpecial,
    borderBottomLeftRadius: borderRadius,
    height: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1em',
  },
  topPinkFlagText: {
    color: 'black',
    fontSize: 10,
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
  },
  middleCard: {
    display: 'flex',
  },
  bottomCard: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: tokens.colorUnique,
    height: (cardHeight * 1.3) / 4,
    paddingLeft: 8,
    paddingRight: 8,
  },
  bottomCardStartText: {
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
    fontSize: 11,
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  arrowIcon: {
    fontSize: 17,
    display: 'flex',
  },
  comparisonEmoji: {
    textAlign: 'center',
    fontSize: 23,
  },
  comparisonEmojiContainer: {
    display: 'flex',
    width: emojiDiameter,
    height: emojiDiameter,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: tokens.colorDark,
    borderRadius: '50%',
  },
  kloverPlusImage: {
    paddingRight: '0.5em',
    paddingLeft: '0.5em',
  },
  comparisonEmojiPadding: {
    paddingLeft: '1em',
    paddingRight: '0.5em',
  },
  comparisonRate: {
    padding: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    width: '50%',
  },
  comparisonRateText: {
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
    fontWeight: 'bold',
  },
  textNextToEmoji: {
    fontSize: 15,
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
  },
  comparisonEmojiAndTextContainer: {
    // backgroundColor: 'red',
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  bottomLogoContainer: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  bottomLogo: {
    width: 40,
    height: 'auto',
  },
}));

interface KloverPlusPreviewCardProps {
  offername: string;
  ctatext: string;
  points: number;
  comparisonemoji: number;
  headline: string;
  logourl: string;
  comparisontext: string;
  description: string;
}

function KloverPlusDynamicPreviewCard({
  comparisonemoji,
  offername,
  ctatext,
  points,
  headline,
  logourl,
  comparisontext,
  description,
}: KloverPlusPreviewCardProps) {
  const classes = useStyles();

  const getDisplayComparisonEmoji = () => {
    try {
      return String.fromCodePoint(comparisonemoji);
    } catch (e) {}
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.topCard}>
        <div className={classes.topPinkFlagContainer}>
          <div className={classes.topPinkFlag}>
            <div className={classes.topPinkFlagText}>
              {ctatext ? `${ctatext} ` : null}
              {ctatext ? `🏆 ` : null}
              {points ? `${ctatext ? ' ' : ' Get'} ${points} points ` : null}
            </div>
          </div>
        </div>
        <div className={classes.middleCard}>
          <div className={classes.comparisonEmojiAndTextContainer}>
            <div className={classes.comparisonEmojiPadding}>
              <div className={classes.comparisonEmojiContainer}>
                <div className={classes.comparisonEmoji}>
                  {comparisonemoji && <div>{getDisplayComparisonEmoji()}</div>}
                </div>
              </div>
            </div>
            <div className={classes.textNextToEmoji}>{comparisontext}</div>
          </div>
          <div className={classes.comparisonRate}>
            <div className={classes.comparisonRateText}>{description}</div>
          </div>
        </div>
      </div>
      <div className={classes.bottomCard}>
        <div className={classes.bottomCardStart}>
          <img
            className={classes.kloverPlusImage}
            src={KloverPlusLogo}
            alt="KloverPlusLogo"
          />
          <div className={classes.bottomCardStartText}>{headline}</div>
        </div>
        <div className={classes.bottomCardEnd}>
          <div className={classes.bottomLogoContainer}>
            <img
              className={classes.bottomLogo}
              src={logourl || KloverPiggyLogo}
              alt={`${offername} logo`}
            />
          </div>
          <div>
            <ArrowForward className={classes.arrowIcon} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KloverPlusDynamicPreviewCard;

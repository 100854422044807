import React from 'react';
import tokens from 'tokens';
import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: tokens.colorBodynegative,
  },
}));

const Spinner: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default Spinner;

import Field from 'components/Field';
import React, { ChangeEvent, useState } from 'react';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { AxiosResponse } from 'axios';
import { Button, Dialog, Grid, theme } from '@klover/attain-design-system';
import { Formik } from 'formik';
import { KDSPageNames, LoadingStates } from 'constants/index';
import { addNotification } from 'pages/audienceManager/slice';
import { useAppDispatch } from 'redux/hooks';
import * as Styled from './index.styles';

interface Props {
  show: boolean;
  setShow: (bool: boolean) => void;
  setSelectedGroup: (group: any) => void;
  reFetchGroups: () => void;
}

const CreateCustomAudienceGroup = (props: Props) => {
  const dispatch = useAppDispatch();
  const { show, setShow, setSelectedGroup, reFetchGroups } = props;
  const [groupName, setGroupName] = useState('');

  type resData = {
    id: string;
    name: string;
  };
  const handleSubmit = async () => {
    try {
      const axiosInstance = await getAuthorizedAxiosInstance();
      if (axiosInstance) {
        const res: AxiosResponse<resData> = await axiosInstance.post(
          `${API_BASE_ADDRESS}/${KDSPageNames.CUSTOMAUDIENCES}/groups`,
          { name: groupName }
        );
        const resData = res.data;
        setSelectedGroup({ id: resData.id, name: resData.name });
        setGroupName('');
        setShow(false);
        reFetchGroups();
        dispatch(
          addNotification({
            state: LoadingStates.DONE,
            message: 'Created new custom audience group',
          })
        );
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}?groupId=${resData.id}`
        );
      } else {
        console.log('failed to authenticate axios instance');
        throw { message: 'Failed to authenticate axios instance' };
      }
    } catch (e) {
      console.log('error createing group: ', e);
      setGroupName('');
      setShow(false);
      dispatch(
        addNotification({
          state: LoadingStates.ERROR,
          message: 'Error creating group',
        })
      );
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  return (
    <Dialog
      fullWidth={true}
      aria-label="create-custom-audience-group"
      title="New Group"
      open={show}
      maxWidth="sm"
      onClose={handleClose}
      headerID="createCustomAudienceGroupHeader"
      header={
        <Styled.CardHeader>
          <Styled.CardTitle>New Group</Styled.CardTitle>
        </Styled.CardHeader>
      }
      bodyID="createCustomAudienceGroupBody"
      body={
        <Styled.StyledBodyContainer
          container
          spacing={theme.spacing.lg}
          direction="column"
        >
          <Grid item>
            <Styled.BodyHeader>
              What would you like the name of your group to be?
            </Styled.BodyHeader>
            <Formik>
              <Field
                value={groupName}
                onChange={handleNameChange}
                fullWidth
                required
                type="text"
              />
            </Formik>
          </Grid>
        </Styled.StyledBodyContainer>
      }
      footer={
        <>
          <span>
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              disabled={!groupName}
            >
              Create Group
            </Button>
          </span>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </>
      }
    ></Dialog>
  );
};

export default CreateCustomAudienceGroup;

import React from 'react';
import * as Styled from './index.styles';

export enum ButtonColors {
  Default = 'default',
  Negative = 'negative',
  Positive = 'positive',
}

export enum ButtonSizes {
  Default = 'default',
  Small = 'small',
}

export enum ButtonVariants {
  Default = 'default',
  Outlined = 'outlined',
}
export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  color?: ButtonColors;
  fullWidth?: boolean;
  icon?: React.ReactNode;
  size?: ButtonSizes;
  variant?: ButtonVariants;
}

const Button = ({
  color,
  fullWidth,
  icon,
  size,
  variant,
  ...props
}: ButtonProps) => {
  return (
    <Styled.Button
      fullWidth={fullWidth || false}
      className={`color-${color} variant-${variant} size-${size}`}
      {...props}
    >
      {icon ? <Styled.Icon>{icon}</Styled.Icon> : null}
      {props.children}
    </Styled.Button>
  );
};

export default Button;

import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'redux/hooks';

import _ from 'lodash';
import {
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
} from '@klover/attain-design-system';
// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import { PencilSimple, FloppyDiskBack } from '@phosphor-icons/react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Offer,
  addNotification,
  createOffer,
  saveOffer,
  toggleDuplicateOfferDialog,
  updateCurrentOffer,
} from '../slice';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import BootstrapTooltip from 'components/bootstrapTooltip';
import DraftStateSvg from '../draftStateIndicator';
import SaveOfferDisabledButtonMessage from '../saveDisabledButtonMessage';
import setSaveOfferButton from '../utils/setSaveOfferButton';
import tokens from 'tokens';
import ChangeLogDialog from '../../../components/ChangeLogDialog';
import { CHANGE_LOG, CLICKS, DUPLICATE, IMPRESSIONS, NO_CURRENT_GROUP, PLEASE_SAVE_TO_DUPLICATE, SHOW_OFFER_CHANGE_LOG, ZIP_CODE_TARGETING_NOT_DUPLICATED } from '../../../content';
import { SYSTEM_NAME } from '../../../constants';

import {
  CopySimple,
  DotsThreeVertical,
  ListMagnifyingGlass,
} from '@phosphor-icons/react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: `${theme.spacing(0, 1)} !important`,
    },
    changeLogButton: {
      margin: `${theme.spacing(0, 1)} !important`,
      textAlign: 'end'
    },
    draftStateIcon: {
      marginTop: 5,
      marginLeft: 10,
    },
    editButton: {
      marginLeft: theme.spacing(4),
    },
    offerName: {
      fontFamily: 'Calibre-Semibold, sans-serif',
      fontSize: 24,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 400,
      marginRight: 10,
    },
    editOfferName: {
      fontFamily: 'Calibre-Regular,sans-serif',
      width: 400,
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 5,
    },
    impressionClicks: {
      fontFamily: 'Calibre-Semibold, sans-serif',
      fontSize: 16,
      marginRight: 16,
      display: 'flex',
    },
    impressionClicksContainer: {
      position: 'absolute',
      right: '0px',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: 0,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

interface OfferNameProps {
  isOfferLoading: boolean;
  offerId: number;
}

const OfferName: React.FC<OfferNameProps> = (props) => {
  const classes = useStyles();
  const { offerId } = props;
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [openChangeLogDialog, setOpenChangeLogDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const currentGroup = useAppSelector(
    (state) => state.offerWallReducer.currentGroup
  );
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const originalOffer = useAppSelector(
    (state) => state.offerWallReducer.originalOffer
  );

  const offerLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.offerLoading
  );

  const isOfferLoading = offerLoading.state === 'loading';

  const handleCancel = () => {
    if (originalOffer) {
      const updatedOffer = {
        ...currentOffer,
        label: originalOffer.label,
      };
      dispatch(updateCurrentOffer(updatedOffer));
      setEditMode(false);
    } else {
      const updatedOffer = {
        ...currentOffer,
        label: '',
      };
      dispatch(updateCurrentOffer(updatedOffer));
    }
  };

  useEffect(() => {
    if (currentOffer && !currentOffer.offerid) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [currentOffer?.offerid]);

  const handleEditName = () => {
    setEditMode(true);
  };

  const handleNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const updatedOffer = {
      ...currentOffer,
      label: e.target.value,
    };
    dispatch(updateCurrentOffer(updatedOffer));
  };

  const isTheSame =
    currentOffer && originalOffer
      ? _.isEqual(
        _.omit(currentOffer, [
          'price',
          'backgroundimgurl',
          'categorytype',
          'zipCount',
          'zip_upload_status',
        ]),
        _.omit(originalOffer, [
          'price',
          'backgroundimgurl',
          'categorytype',
          'zipCount',
          'zip_upload_status',
        ])
      )
      : false;

  const handleSave = () => {
    const updatedOffer: Offer = {
      ...currentOffer,
    };
    if (originalOffer) {
      dispatch(saveOffer(updatedOffer));
      setEditMode(false);
    } else {
      if (currentGroup) {
        updatedOffer.offergroupid = currentGroup.id;
        dispatch(createOffer({ updatedOffer, currentGroup }));
        setEditMode(false);
      } else {
        dispatch(
          addNotification({
            state: 'error',
            message: NO_CURRENT_GROUP,
          })
        );
      }
    }
  };

  const openAudienceAction = Boolean(anchorEl);

  const duplicateHandler = () =>
    dispatch(toggleDuplicateOfferDialog({ open: true }));

  const notDuplicable = isOfferLoading || !isTheSame;

  const saveOfferButtonSettings = setSaveOfferButton(currentOffer);
  const { isDisabled } = saveOfferButtonSettings;
  let { SaveTitle = <SaveOfferDisabledButtonMessage /> } =
    saveOfferButtonSettings;
  SaveTitle =
    SaveTitle === null ? <SaveOfferDisabledButtonMessage /> : SaveTitle;

  return (
    <>
      <ThemeProvider theme={inputs}>
        <Box display="flex" alignItems="center">
          {editMode ? (
            <>
              <TextField
                id="offer-label"
                label="Offer Label"
                onChange={handleNameChange}
                value={currentOffer?.label}
                variant="outlined"
                className={classes.editOfferName}
                style={{ marginRight: 8 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="default"
                        onClick={handleSave}
                        size="small"
                        disabled={isDisabled}
                      >
                        <OfferNameSaveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton onClick={handleCancel}>
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Typography
                className={classes.offerName}
                component="h2"
                variant="h6">
                {currentOffer?.label}
              </Typography>

              <IconButton
                className={classes.editButton}
                color="default"
                onClick={handleEditName}
              >
                <PencilSimple />
              </IconButton>
            </>
          )}
        </Box>
        <BootstrapTooltip title={SaveTitle}>
          <span>
            <Button
              className={classes.button}
              disableElevation
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isDisabled}
            >
              Save
            </Button>
          </span>
        </BootstrapTooltip>
        <BootstrapTooltip title="Actions" placement="top">
          <span aria-describedby="audience-action-btn">
            <IconButton
              color="default"
              disabled={isOfferLoading}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <DotsThreeVertical size={24} weight="bold" />
            </IconButton>

            <Menu
              id="audience-action-btn"
              open={openAudienceAction}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onBlur={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={duplicateHandler} disabled={!isTheSame}>
                <span
                  title={
                    notDuplicable
                      ? PLEASE_SAVE_TO_DUPLICATE
                      : ZIP_CODE_TARGETING_NOT_DUPLICATED
                  }
                  className={classes.menuItem}
                >
                  <CopySimple size={22} />
                  {DUPLICATE}
                </span>
              </MenuItem>

              <MenuItem onClick={() => setOpenChangeLogDialog(true)}>
                <span
                  title={SHOW_OFFER_CHANGE_LOG}
                  className={classes.menuItem}
                >
                  <ListMagnifyingGlass size={22} />
                  {CHANGE_LOG}
                </span>
              </MenuItem>
            </Menu>
          </span>
        </BootstrapTooltip>
        <div className={classes.impressionClicksContainer}>
          {currentOffer?.impressions && (
            <div className={classes.impressionClicks}>
              <div style={{ paddingRight: 8, width: 90 }}>{IMPRESSIONS}:</div>
              <div>
                <Chip
                  style={{ height: '16px', paddingLeft: 0, paddingRight: 0 }}
                  label={currentOffer?.impressions}
                />
              </div>
            </div>
          )}
          {currentOffer?.clicks && (
            <div className={classes.impressionClicks}>
              <div style={{ paddingRight: 8, width: 90 }}>{CLICKS}:</div>
              <div>
                <Chip style={{ height: '16px' }} label={currentOffer?.clicks} />
              </div>
            </div>
          )}
        </div>
        {openChangeLogDialog &&
          <ChangeLogDialog isOpen={openChangeLogDialog} setOpenDialog={setOpenChangeLogDialog} entityId={offerId} systemName={SYSTEM_NAME.offer_wall_manager} />
        }
        <Box className={classes.draftStateIcon}>
          {!isOfferLoading && !isTheSame ? <DraftStateSvg /> : null}
        </Box>
      </ThemeProvider>
    </>
  );
};

const OfferNameSaveIcon = () => {
  return (
    <>{false ? <CircularProgress color="primary" size={20} /> : <FloppyDiskBack size={24} />}</>
  );
};

export default OfferName;

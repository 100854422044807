import { AgeRangeKeys } from './ageRanges';
import { Cities } from './cities';
import { Educations } from './education';
import { GenderKeys } from './genders';
import { Households } from './households';
import { InstitutionNames } from './institutions';
import { MerchantCategoryKeys } from './transaction_merchant_category';
import { Merchants } from './merchants';
import { NumberInHouseholds } from './numberInHouseholds';
import { Orientations } from './orientations';
import { ProductCategoryKeys } from './productCategories';
import { ProductMerchantNames } from './productMerchantNames';
import { Races } from './races';
import { Relationships } from './relationships';
import { States } from './states';

type CurrentConditionMapProp = {
  [key: string]: Array<string>;
};

export const currentConditionMap: CurrentConditionMapProp = {
  age: AgeRangeKeys,
  institutionName: InstitutionNames,
  gender: GenderKeys,
  race: Races,
  sexualOrientation: Orientations,
  householdType: Households,
  education: Educations,
  relationshipStatus: Relationships,
  state: States,
  numberInHousehold: NumberInHouseholds,
  city: Cities,
  merchantCategory: MerchantCategoryKeys,
  merchantName: Merchants,
  productCategory: ProductCategoryKeys,
  productMerchantName: ProductMerchantNames,
  upc: [],
  merchant: [],
};

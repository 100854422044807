export const Races = [
  '',
  'White',
  'Black, or African American',
  'Some other race',
  'Prefer not to answer',
  'American Indian or Alaska Native',
  'Asian - Filipino',
  'Asian - Other',
  'Asian - Asian Indian',
  'Pacific Islander - Native Hawaiian',
  'Asian - Korean',
  'Asian - Chinese',
  'Asian - Vietnamese',
  'Pacific Islander - Other Pacific Islander',
  'Pacific Islander - Samoan',
  'Asian - Japanese',
  'Pacific Islander - Guamanian',
];

export type Race = typeof Races[number];

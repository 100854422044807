import styled from 'styled-components';
import tokens from 'tokens';

interface DashboardProps {
  isDashBoard: boolean;
}

export const Preview = styled.div`
  background: #202123;
  border-radius: ${tokens.spacing.sm};
  overflow: hidden;
  width: 343px;
  height: fit-content;
  position: relative;
`;

export const PreviewImageWrapper = styled.div`
  background: ${tokens.colorGray80};
  height: 117px;
  overflow: hidden;
`;

export const GradientOverImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  height: 117px;
  width: 100%;
  position: absolute;
`;

export const PreviewImage = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
`;

export const LogoImage = styled(PreviewImage)`
  object-fit: scale-down;
`;

export const PreviewContent = styled.div`
  color: ${tokens.colorBodynegative};
  padding: ${tokens.spacing.md};
  font-family: 'roboto', 'helvetica', 'arial', 'sans-serif';

  * {
    overflow-wrap: break-word;
  }
`;

export const OfferWallHeadline = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 42px;
  justify-content: center;
  line-height: 21px;
  text-align: center;

  span {
    max-height: 42px;
    overflow: hidden;
  }
`;

export const OfferWallImageWrapper = styled.div`
  background: ${tokens.colorGray80};
  height: 117px;
  overflow: hidden;
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  border: black 1px solid;
  border-radius: 8px 8px 0px 0px;
`;

export const OfferWallPreviewImage = styled.img`
  height: 50%;
`;

export const DashboardHeaderWrapper = styled.div<DashboardProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: ${(props) => (props.isDashBoard ? 'absolute' : 'static')};
  top: ${(props) => (props.isDashBoard ? '64px' : 'unset')};
  text-shadow: ${(props) =>
    props.isDashBoard ? '1px 1px 1.5px #535556' : 'null'};
`;

export const PreviewAvatarWrapper = styled.div`
  background: ${tokens.colorPagebg};
  border-radius: 50%;
  flex-basis: 48px;
  height: 48px;
  margin-right: ${tokens.spacing.md};
  overflow: hidden;
`;

export const DashboardHeader = styled.div`
  flex-basis: 247px;
`;

export const DashboardHeadline = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  width: 247px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DashboardSubline = styled.div`
  font-size: 12px;
  line-height: 16px;
  max-height: 32px;
  overflow: hidden;
`;

export const DashboardPoints = styled.div`
  position: relative;
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 25px;
  border: 1px solid #ddd;
  background: #fff;
  color: #040d14;
  text-align: center;
  font-size: 12px;
  padding: 4px 16px;
  margin: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

export const DashboardPreviewAvatar = styled.div`
  background: ${tokens.colorPagebg};
  border-radius: 50%;
  height: 56px;
  width: 56px;
  margin-right: ${tokens.spacing.sm};
  overflow: hidden;
  position: relative;
  top: 26px;
`;

export const DashboardBody = styled.span`
  color: ${tokens.colorPagebg};
  display: inline;
`;

export const DashboardFooterCTA = styled.div`
  display: flex;
  width: 100%;
  height: 28px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #ff8166;
  color: black;
`;

export const OfferWallBody = styled.div`
  color: ${tokens.colorGray40};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 48px;
  justify-content: center;
  line-height: 16px;
  margin: ${tokens.spacing.md} 0 ${tokens.spacing.md};
  text-align: center;
  span {
    max-height: 48px;
    overflow: hidden;
  }
`;

export const DashboardPreviewBody = styled.div`
  color: hsla(354, 95%, 84%, 1);
  font-size: 14px;
  margin-bottom: ${tokens.spacing.md};
  width: 80%;
  margin-left: 64px;
`;

export const PreviewFooter = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 24px;
`;

export const DashboardPreviewFooter = styled(PreviewFooter)`
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const OfferWallPreviewFooter = styled(PreviewFooter)`
  justify-content: center;
  text-align: center;
`;

export const ClickThroughLink = styled.a`
  align-content: center;
  padding-bottom: 6px;
  height: 18px;
`;

export const ClickThroughContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PreviewCTA = styled.div`
  /* color: hsla(354, 95%, 84%, 1); */
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreviewEmoji = styled.span`
  /* margin-right: ${tokens.spacing.sm}; */
`;

export const PreviewInfoCOntainer = styled.div`
  margin-left: ${tokens.spacing.sm};
  padding-top: 2px;
`;

export const LinkImg = styled.img`
  height: 16px;
  margin-left: ${tokens.spacing.sm};
  width: 16px;
`;

export const StyledHr = styled.hr`
  border-color: #535556;
  margin-left: -16px;
  margin-right: -16px;
`;

export const comparisonText = styled.div`
  color: ${tokens.colorPagebg};
  display: inline;
`;

export const FlexDiv = styled.div`
  display: flex;
  max-height: 40px;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const bottomLogoContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
`;

export const bottomLogo = styled.img`
  width: 40px;
  margin-right: 8px;
`;

export const ArrowIcon = styled.div`
  font-size: 17px;
  display: flex;
`;

export const DisclosureContainer = styled.div`
  height: 0px;
  padding: 0px;
  font-family: 'roboto', 'helvetica', 'arial', 'sans-serif';
  display: flex;
  background: ${tokens.colorGray80};
`;

export const Disclosure = styled.div`
  margin-left: auto;
  height: 0px;
  color: ${tokens.colorDefault};
`;

export const OnboardingPreviewImageWrapper = styled.div`
  background: ${tokens.colorGray80};
  height: 250px;
  overflow: hidden;
`;

export const OnboardingGradientOverImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  height: 250px;
  width: 100%;
  position: absolute;
`;

export const OnboardingHeaderWrapper = styled.div`
  align-items: center;
  display: 'flex';
  flex-direction: row;
  position: absolute;
  top: 150px;
  text-shadow: '1px 1px 1.5px #535556';
`;

export const OnboardingPreviewAvatarWrapper = styled.div`
  background: ${tokens.colorPagebg};
  border-radius: 50%;
  flex-basis: 48px;
  height: 48px;
  width: 48px;
  margin-right: ${tokens.spacing.md};
  overflow: hidden;
`;

export const OnboardingHeadline = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  width: 300px;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const OnboardingSubLine = styled.div`
  color: white;
  width: 300px;
  opacity: 0.9;
`;

export const OnboardingBody = styled.span`
  color: white;
  font-weight: bold;
  opacity: 1;
`;

export const OnboardingPreviewBody = styled.div`
  height: auto;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: ${tokens.spacing.md};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OnboardingPreviewCTA = styled.div`
  display: flex;
  width: 310px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #ff8166;
`;

export const SkipPreviewCTA = styled.div`
  display: flex;
  width: 310px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const HowPointsWorks = styled.div`
  display: flex;
  width: 310px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ff8166;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  letter-spacing: -0.25px;
  text-decoration-line: underline;
`;

export const OnboardingPoints = styled.div`
  position: relative;
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 25px;
  border: 1px solid #ddd;
  background: #fff;
  color: #040d14;
  text-align: center;
  font-size: 12px;
  padding: 4px 16px;
  margin: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

export const OnboardingHeader = styled.div`
  display: block;
`;

// ------------ Style for Klover Plus starts ------------ //

export const KloverPlusPreview = styled(Preview)`
  height: fit-content;
  width: 415px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const KloverPlusPreviewContent = styled(PreviewContent)`
  padding: 0;
`;

export const KloverPlusCTA = styled.div`
  display: flex;
  width: 100%;
  height: 28px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #ff8166;
  color: black;
`;

export const KloverPlusFooter = styled.div`
    display: flex;
    margin: 16px 24px;
`;

export const KloverPlusInfoContainer = styled.div`
  margin-left: 16px;
  padding-top: 2px;
`;

export const KloverPlusPreviewAvatar = styled.div`
  background: ${tokens.colorPagebg};
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-right: ${tokens.spacing.sm};
  overflow: hidden;
  position: absolute;
  flex: 1;
`;

export const KloverPlusBody = styled.div`
    display: flex;
    margin: 16px 24px;
`;

export const KloverPlusPoints = styled.div`
  display: flex;
  flex: auto;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 25px;
  border: 2px solid white;
  color: white;
  text-align: center;
  font-size: 12px;
  padding: 4px 16px;
  margin: 0px 0px 0px 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  font-size: 14px;
  height: 32px;
  flex: 1;
`;

export const KloverPlusSubline = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  overflow: hidden;
`;

export const KloverPlusBodyText = styled.div`
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  margin-top: 4px;
`;

export const KloverPlusContent = styled.div`
  margin-left: 56px;
  flex: 5;
`;

// ------------ Style for Klover Plus ends ------------ //
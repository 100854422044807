import { fetchGroup } from '../../offerWallManager/slice';
import {
  fetchSalesLiftStudy,
  setTargetTransactions,
  setUIState,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';
import * as Styled from './index.styles';

// Components
import SalesLiftDeleteDialog from '../salesLiftDeleteDialog';
import SalesLiftDimensionDialog from '../salesLiftDimensionDialog';
import SalesLiftDimensions from '../salesLiftDimensions';
import SalesLiftExportDialog from '../salesLiftExportDialog';
import SalesLiftName from '../salesLiftName';

// Material UI Components
import SalesLiftDraftStateDialog from '../salesLiftDraftStateDialog';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  noSalesLiftStudies: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesLiftUnknown: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  salesLiftUnknownText: {
    fontSize: 20,
    textAlign: 'center',
  },
}));

const SalesLiftEditor = () => {
  const classes = useStyles();
  const history = useHistory();
  const { salesliftstudyid } = useParams();
  const dispatch = useAppDispatch();

  // get the current sales lift study from the redux store
  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  // const originalSalesLiftStudy = useAppSelector(
  //   (state) => state.salesLiftStudiesReducer.originalSalesLiftStudy
  // );

  const errorFetching = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.errorFetching
  );

  // get the sales lift study loading state from the redux store
  const salesLiftStudyLoading = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.salesLiftStudyLoading
  );

  // get the current sales lift study dimensions
  const currentSalesLiftStudyDimensions =
    currentSalesLiftStudy && currentSalesLiftStudy.dimensions
      ? currentSalesLiftStudy.dimensions
      : [];

  // check sales lift study loading state
  const isSalesLiftStudyLoading =
    salesLiftStudyLoading.state === 'loading' ? true : false;

  // getting the current sales lift study dimensions length
  const currentSalesLiftStudyDimensionsLength =
    currentSalesLiftStudyDimensions.length;

  const currentGroup = useAppSelector(
    (state) => state.offerWallReducer.currentGroup
  );

  useEffect(() => {
    const filteredSalesLiftStudyDimensionsForTargetTransactions =
      currentSalesLiftStudyDimensions.filter(
        (x) => x.category == 'targetTransactions'
      );
    if (
      currentSalesLiftStudyDimensionsLength &&
      filteredSalesLiftStudyDimensionsForTargetTransactions.length
    ) {
      const targetTransaction =
        filteredSalesLiftStudyDimensionsForTargetTransactions[0];
      dispatch(setTargetTransactions(targetTransaction));
    }
  }, [currentSalesLiftStudyDimensionsLength]);

  useEffect(() => {
    const currentSalesLiftStudyOffers =
      currentSalesLiftStudy &&
      currentSalesLiftStudy.offers &&
      currentSalesLiftStudy.offers.length
        ? currentSalesLiftStudy.offers
        : [];
    if (currentSalesLiftStudyOffers.length) {
      const id = currentSalesLiftStudyOffers[0].offergroupid;
      dispatch(fetchGroup({ id }));
    }
    // setting error state false because commencing fetching
    dispatch(
      setUIState({
        value: false,
        uiPropertyName: 'errorFetching',
      })
    );
    if (salesliftstudyid) {
      dispatch(fetchSalesLiftStudy({ salesliftstudyid }));
    } else if (!salesliftstudyid && currentGroup) {
      // when there is no sls id we should set error fetching true
      dispatch(
        setUIState({
          value: true,
          uiPropertyName: 'errorFetching',
        })
      );
    } else {
      history.push('/main/sales-lift-studies');
    }
    return () => {
      dispatch(
        setUIState({
          value: false,
          uiPropertyName: 'errorFetching',
        })
      );
    };
  }, []);

  return (
    <>
      {/* Checks for if there is an error fetching and if sales lift study is loading */}
      {errorFetching && !isSalesLiftStudyLoading ? (
        <Styled.SalesLiftContainer>
          <Styled.SalesLiftUnknown>
            <Styled.SalesLiftUnknownText>
              404 Error: This page does not exist.
            </Styled.SalesLiftUnknownText>
          </Styled.SalesLiftUnknown>
        </Styled.SalesLiftContainer>
      ) : (
        <div className={classes.root}>
          <>
            <SalesLiftName />
            {!isSalesLiftStudyLoading && <SalesLiftDimensions />}
            {isSalesLiftStudyLoading && <LoadingSalesLiftStudy />}
            <SalesLiftDimensionDialog />
            <SalesLiftExportDialog />
            <SalesLiftDeleteDialog />
            <SalesLiftDraftStateDialog />
          </>
        </div>
      )}
    </>
  );
};

const LoadingSalesLiftStudy = () => {
  const classes = useStyles();
  return (
    <Box flexGrow={1} className={classes.noSalesLiftStudies}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="primary" size={40} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalesLiftEditor;

import {
  LIVERAMP_SEGMENT_DETAILS,
  LIVERAMP_SEGMENT_FIELD_NAME,
} from '../../constants';
// Material UI Components
import tokens from 'tokens';
import { Grid, TextField } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationText: {
      marginTop: '1rem',
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    segmentDivider: {
      background: tokens.colorDividerLgBorder,
      height: '1px',
      border: 'none',
    },
    segmentInputFieldsContainer: {
      display: 'flex',
      padding: '1rem 1rem 0rem 1rem',
    },
    segmentInputFieldsLeft: {
      flex: 1,
      borderRight: '1px solid' + tokens.colorDividerLgBorder,
      paddingRight: '1rem',
      borderImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 30%, #CCCCCC 25%, #CCCCCC 75%, #CCCCCC 75%)',
      borderImageSlice: '1',
    },
    segmentInputFieldsRight: {
      flex: 1,
      marginTop: 53,
      paddingLeft: '1rem',
    },
    liveRampDataSegmentLabel: {
      paddingBottom: '1.5rem',
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    OrLabel: {
      fontSize: 18,
      fontWeight: 500,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const LiveRampExport = (props: any) => {
  const classes = useStyles();
  const {
    handleSegmentName,
    segmentName,
    handleDescription,
    description,
    handleCpm,
    cpm,
    handlePercentageOfMedia,
    percentageOfMedia,
    isSegmentNameValid,
    inValidSegmentNameErrorMessage,
    platformDestination,
    setPlatformDestination,
    advertiserDestination,
    setAdvertiserDestination,
    tvTargeting,
    handleTvTargeting,
    advertiserDirect,
    handleAdvertiserDirect
  } = props;

  return (
    <>
      <Grid container spacing={0} display="flex" >
        <Grid container xs={6} className={classes.segmentInputFieldsLeft}>
          <Grid item xs={12} className={classes.liveRampDataSegmentLabel}>
            {LIVERAMP_SEGMENT_DETAILS}
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleSegmentName}
              value={segmentName}
              fullWidth
              id="segmentName"
              label={LIVERAMP_SEGMENT_FIELD_NAME.CUSTOM_SEGMENT_NAME}
              type="text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              error={!isSegmentNameValid}
              helperText={inValidSegmentNameErrorMessage}
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 36 }}>
            <TextField
              onChange={handleDescription}
              value={description}
              fullWidth
              id="description"
              label={LIVERAMP_SEGMENT_FIELD_NAME.DESCRIPTION}
              type="text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          xs={6}
          display="flex"
          flexDirection="column"
          className={classes.segmentInputFieldsRight}
        >
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <TextField
                onChange={handleCpm}
                value={cpm}
                fullWidth
                id="cpm"
                label={LIVERAMP_SEGMENT_FIELD_NAME.CPM}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                onChange={handleTvTargeting}
                value={tvTargeting}
                fullWidth
                id="tvTargeting"
                label={LIVERAMP_SEGMENT_FIELD_NAME.TV_TARGETING}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: 36 }}>
            <Grid item xs={5}>
              <TextField
                onChange={handlePercentageOfMedia}
                value={percentageOfMedia}
                fullWidth
                id="percentageOfMedia"
                label={LIVERAMP_SEGMENT_FIELD_NAME.PROGRAMMATIC_PERCENT_OF_MEDIA}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                onChange={handleAdvertiserDirect}
                value={advertiserDirect}
                fullWidth
                id="advertiserDirect"
                label={LIVERAMP_SEGMENT_FIELD_NAME.ADVERTISER_DIRECT}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={0} style={{ marginTop: 36 }}>
            <Grid item xs={5}>
              <TextField
                onChange={(event) => { setPlatformDestination(event.target.value) }}
                value={platformDestination}
                fullWidth
                id="platformDestination"
                label={LIVERAMP_SEGMENT_FIELD_NAME.CHOOSE_INTEGRATION}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                onChange={(event) => { setAdvertiserDestination(event.target.value) }}
                value={advertiserDestination}
                fullWidth
                id="advertiserDestination"
                label={LIVERAMP_SEGMENT_FIELD_NAME.CHOOSE_ADVERTISER}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};
export default LiveRampExport;

/* Time range for exising cap to offer */
export const TimeRangeofConversion = {
  1: 'Daily',
  2: 'Weekly',
  3: 'Monthly',
  4: 'No time-range', //Not define range for cap
};

/* Help to show text on Conversion Cap column in Offer table column */
export const TimeRangeofConversionLabel = {
  1: 'per day',
  2: 'per week',
  3: 'per month',
  4: 'with no time-range',
};

import React from 'react';
import _ from 'lodash';
import { Button, IconButton } from '@klover/attain-design-system';
import { StringMap } from 'interfaces';
import { nanoid } from 'nanoid';
import {
  saveSalesLiftStudy,
  setCurrentSalesLiftStudy,
  setHasChanged,
  setHasNotChanged,
  setIsInvalid,
  setIsValid,
  setSalesLiftDimensionDialogOpen,
  setTargetTransactions,
  updateCurrentSalesLiftStudyProperty,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import 'date-fns';

// configs
import salesLiftDimensionsConfig from '../configs/salesLiftDimensionsConfig';

// components
import DaysInput from '../daysInput';
import OfferGroupSelect from '../offerGroupSelector';
import OffersSelect from '../offersSelect';
import StudyDates from '../studyDates';
import TargetTransactions from '../targetTransactions';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// all options for the select components

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignInputs: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    alignItems: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonFormat: {
      margin: '0em 0.5em',
    },
    formControl: {
      minWidth: 450,
    },
    close: {
      height: 24,
      width: 24,
    },
    columnTitles: {
      color: 'grey',
      display: 'flex',
      fontSize: 10,
      padding: 10,
      paddingTop: 30,
      paddingBottom: 0,
      marginLeft: 24,
    },
    columnNameAndValue: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      padding: 10,
      marginLeft: 24,
    },
    divider: {
      borderLeft: '3px solid #DADEE3',
      paddingLeft: 5,
      flexGrow: 1,
    },
    header: {
      padding: '10px 12px 10px 24px',
    },
    icon: {
      height: 24,
      width: 24,
    },
    inputColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputColumnSpacing: {
      paddingTop: 10,
    },
    moreVertIcon: {
      position: 'absolute',
      marginTop: -13,
      right: 55,
    },
    textInput: {
      paddingBottom: 15,
      width: 450,
    },
    textInputAndMoreVertIcons: {
      display: 'flex',
      alignItems: 'center',
    },
    root: {
      margin: theme.spacing(3),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 107,
      height: 22,
      marginBottom: 5,
    },
    offerGroupName: {
      fontSize: 15,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: '#5041AB',
      },
      containedPrimary: {
        backgroundColor: '#5041AB',
        '&:hover': {
          backgroundColor: '#5041AB',
        },
      },
    },
  },
});

const icon = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
});

export const InputType = ({
  inputLabel,
  inputType,
  keyName,
  options,
  required,
  helperText,
  className,
  type,
  min,
  max,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  const originalSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.originalSalesLiftStudy
  );

  const isInvalid = useAppSelector(
    (state) => state.salesLiftStudiesReducer.isInvalid
  );
  const handleUpdateSalesLiftStudy = (value) => {
    if (required) {
      if (!value) {
        dispatch(setIsInvalid({ keyName, helperText }));
      } else {
        dispatch(setIsValid({ keyName }));
      }
    }
    if (min) {
      if (value && value < min) {
        dispatch(
          setIsInvalid({
            keyName,
            helperText: 'Value is less than minimum allowed value',
          })
        );
      } else {
        dispatch(setIsValid({ keyName }));
      }
    }
    const updatedSalesLift = {
      ...currentSalesLiftStudy,
      [keyName]: value,
    };
    if (value) {
      if (originalSalesLiftStudy && originalSalesLiftStudy[keyName]) {
        const ogVal = originalSalesLiftStudy[keyName];
        if (ogVal != value) {
          dispatch(setHasChanged({ keyName }));
        } else {
          dispatch(setHasNotChanged({ keyName }));
        }
      }
    }
    dispatch(
      updateCurrentSalesLiftStudyProperty({
        updatedSalesLift,
        propertyName: keyName,
      })
    );
  };

  switch (inputType) {
    case 'daysInput':
      return <DaysInput inputLabel={inputLabel} keyName={keyName} min={min} />;
    case 'defaultGroupSelect':
      return <OfferGroupSelect />;
    case 'dates':
      return <StudyDates />;
    case 'select':
      return (
        <FormControl
          color="primary"
          variant="outlined"
          className={className || classes.formControl}
        >
          <InputLabel>{inputLabel}</InputLabel>
          <Select
            label={inputLabel}
            onChange={(e) => handleUpdateSalesLiftStudy(e.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            value={
              currentSalesLiftStudy && currentSalesLiftStudy[keyName]
                ? currentSalesLiftStudy[keyName]
                : 'click'
            }
          >
            {Object.keys(options).map((key) => {
              return (
                <MenuItem key={nanoid()} value={key}>
                  {options[key]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    case 'offergroup':
      return (
        <TextField
          variant="outlined"
          label={inputLabel}
          onBlur={(e) => handleUpdateSalesLiftStudy(e.target.value)}
          defaultValue={
            currentSalesLiftStudy && currentSalesLiftStudy[keyName]
              ? currentSalesLiftStudy[keyName]
              : ''
          }
          className={classes.textInput}
        />
      );
    case 'offersSelect':
      return <OffersSelect />;
    case 'textfield':
      return (
        <TextField
          error={!_.isEmpty(isInvalid) && isInvalid[keyName] ? true : false}
          variant="outlined"
          label={inputLabel}
          onBlur={(e) => handleUpdateSalesLiftStudy(e.target.value)}
          defaultValue={
            currentSalesLiftStudy && currentSalesLiftStudy[keyName]
              ? currentSalesLiftStudy[keyName]
              : ''
          }
          helperText={
            !_.isEmpty(isInvalid) && isInvalid[keyName]
              ? isInvalid[keyName]
              : ''
          }
          type={type || 'string'}
          InputProps={{ inputProps: { min, max } }}
          className={className || classes.textInput}
        />
      );
    case 'offerGroupSelect':
      return (
        <div className={classes.offerGroupName}>
          {currentSalesLiftStudy && currentSalesLiftStudy[keyName]
            ? currentSalesLiftStudy[keyName]
            : ''}
        </div>
      );
    case 'textblock':
      return (
        <TextField
          helperText={helperText}
          variant="outlined"
          label={inputLabel}
          multiline
          rows={4}
          onBlur={(e) => handleUpdateSalesLiftStudy(e.target.value)}
          defaultValue={
            currentSalesLiftStudy && currentSalesLiftStudy[keyName]
              ? currentSalesLiftStudy[keyName]
              : ''
          }
          className={classes.textInput}
        />
      );
    default:
      return null;
  }
};

const SalesLiftDimensionDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const open = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.salesLiftDimensionDialogOpen
  );
  const isInvalid = useAppSelector(
    (state) => state.salesLiftStudiesReducer.isInvalid
  );

  const hasChanged = useAppSelector(
    (state) => state.salesLiftStudiesReducer.hasChanged
  );

  const disabled = isInvalid && !_.isEmpty(isInvalid);

  const currentSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftStudy
  );
  const currentSalesLiftDimension = useAppSelector(
    (state) => state.salesLiftStudiesReducer.currentSalesLiftDimension
  );

  const originalSalesLiftStudy = useAppSelector(
    (state) => state.salesLiftStudiesReducer.originalSalesLiftStudy
  );

  const salesLiftDimension = salesLiftDimensionsConfig.filter(
    (dimension) => dimension.dimensionName === currentSalesLiftDimension
  );

  const isTargetTransactionsComponent =
    salesLiftDimension &&
    salesLiftDimension[0] &&
    salesLiftDimension[0].customComponentName === 'targetTransactions';

  const currentInputs =
    salesLiftDimension.length && salesLiftDimension[0].inputs
      ? salesLiftDimension[0].inputs
      : [];

  const handleClose = () => {
    dispatch(setSalesLiftDimensionDialogOpen(false));
  };

  const handleClearChanges = () => {
    if (salesLiftDimension && salesLiftDimension.length) {
      const customComponentName = salesLiftDimension[0].customComponentName
        ? salesLiftDimension[0].customComponentName
        : null;
      const inputs = salesLiftDimension[0].inputs;
      if (customComponentName) {
        if (
          customComponentName === 'targetTransactions' &&
          originalSalesLiftStudy
        ) {
          const dimensions = originalSalesLiftStudy.dimensions;
          const filteredDimensions = dimensions.filter(
            (x) => x.category === 'targetTransactions'
          );
          const originalTargetTransactions = filteredDimensions.length
            ? filteredDimensions[0]
            : null;
          if (originalTargetTransactions) {
            dispatch(setTargetTransactions(originalTargetTransactions));
          }
        }
      }
      if (inputs && inputs.length) {
        const cloneOfCurrentSalesLiftStudy = _.clone(currentSalesLiftStudy);
        if (cloneOfCurrentSalesLiftStudy && originalSalesLiftStudy) {
          for (const input of inputs) {
            const inputKeyName = input.keyName;
            dispatch(setIsValid({ keyName: inputKeyName }));
            dispatch(setHasNotChanged({ keyName: inputKeyName }));
            if (inputKeyName === 'studydates') {
              dispatch(setHasNotChanged({ keyName: 'enddate' }));
              dispatch(setHasNotChanged({ keyName: 'startdate' }));
              cloneOfCurrentSalesLiftStudy.startdate =
                originalSalesLiftStudy.startdate;
              cloneOfCurrentSalesLiftStudy.enddate =
                originalSalesLiftStudy.enddate;
            } else {
              if (inputKeyName === 'offers')
                dispatch(setHasNotChanged({ keyName: 'offersSelect' }));
              const originalValue = originalSalesLiftStudy[inputKeyName];
              cloneOfCurrentSalesLiftStudy[inputKeyName] = originalValue;
            }
          }
        }
        dispatch(setCurrentSalesLiftStudy(cloneOfCurrentSalesLiftStudy));
      }
    }
  };

  // const pickedFromOriginal = _.pick(originalOffer, currentInputs)
  // const pickedFromCurrent = _.pick(currentSalesLiftStudy, currentInputs)
  // const isTheSame =
  //   pickedFromCurrent && pickedFromOriginal
  //     ? _.isEqual(pickedFromOriginal, pickedFromCurrent)
  //     : false

  interface Input {
    options?: StringMap;
    keyName?: string;
    required?: boolean;
    inputType?: string;
    inputLabel?: string;
    helperText?: string;
    min?: number;
  }
  const dontClearTargetTransations =
    isTargetTransactionsComponent && !hasChanged.targetTransactions;
  const allInputNames = currentInputs.reduce((a, x) => {
    a = a.concat([x.keyName]);
    if (x.multipleKeyNames && x.multipleKeyNames.length)
      a = a.concat(x.multipleKeyNames);
    return a;
  }, []);
  let dontClearAnything = true;
  allInputNames.forEach((x) => {
    if (hasChanged[x]) {
      dontClearAnything = false;
    }
  });
  const shouldWeDisableButton =
    salesLiftDimension &&
    salesLiftDimension.length &&
    salesLiftDimension[0].customComponentName === 'targetTransactions'
      ? dontClearTargetTransations
      : dontClearAnything || false;

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          maxWidth="md"
          onClose={handleClose}
          open={open}
        >
          <Box className={classes.header}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Title dimensionName={currentSalesLiftDimension} />
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton onClick={handleClose}>
                  <CloseIcon className={classes.close} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          {currentInputs && currentInputs.length ? (
            <Grid className={classes.columnTitles}>
              <Grid item xs={6}>
                NAME
              </Grid>
              <Grid>VALUE</Grid>
            </Grid>
          ) : null}
          <Divider />
          {currentInputs && currentInputs.length ? (
            <Grid className={classes.inputColumnSpacing}>
              <Grid className={classes.inputColumn}>
                {currentInputs.map(
                  ({
                    options,
                    keyName,
                    required,
                    inputType,
                    inputLabel,
                    helperText,
                    min,
                  }: Input) => {
                    if (!inputType) return null;
                    return (
                      <Grid
                        key={nanoid()}
                        className={classes.columnNameAndValue}
                      >
                        <Grid item xs={6}>
                          <Typography>{inputLabel}</Typography>
                        </Grid>
                        <Grid>
                          <InputType
                            inputLabel={inputLabel}
                            inputType={inputType}
                            keyName={keyName}
                            options={options || {}}
                            helperText={helperText || ''}
                            required={required || null}
                            min={min}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>
          ) : null}
          {isTargetTransactionsComponent ? <TargetTransactions /> : null}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Box m={3} marginTop={2} marginBottom={2}>
            <Grid className={classes.buttonContainer}>
              <Grid>
                <span>
                  <Button
                    color="primary"
                    className={classes.buttonFormat}
                    variant="contained"
                    disableElevation
                    disabled={shouldWeDisableButton}
                    onClick={handleClearChanges}
                  >
                    Clear Changes
                  </Button>
                </span>
                <span>
                  <Button
                    color="primary"
                    className={classes.buttonFormat}
                    disableElevation
                    variant="contained"
                    disabled={disabled}
                    onClick={() =>
                      dispatch(saveSalesLiftStudy(currentSalesLiftStudy))
                    }
                  >
                    Save
                  </Button>
                </span>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

const Title = ({ dimensionName }: any) => (
  <ThemeProvider theme={icon}>
    <Grid container item xs={12} alignItems="center">
      <Grid item container direction="column" xs={11} style={{ padding: 5 }}>
        <Grid item>
          <Typography component="h3" variant="h6">
            {_.startCase(dimensionName)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </ThemeProvider>
);

export default SalesLiftDimensionDialog;

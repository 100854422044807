import React from 'react';
import { useLocation } from 'react-router-dom';
import * as Styled from './Tabs.styles';

export interface Tab {
  active?: boolean; //to make smart personas work
  activePath?: string;
  disabled?: boolean;
  element?: React.ReactNode;
  onClick?: any;
  title?: string;
}

const Tab = ({
  active,
  activePath,
  disabled,
  element,
  onClick,
  title,
}: Tab) => {
  const location = useLocation();

  return (
    <Styled.Tab
      onClick={onClick}
      $disabled={disabled}
      className={active || location.pathname === activePath ? 'active' : ''}
    >
      {element ? element : title}
    </Styled.Tab>
  );
};

interface TabsProps {
  tabs: Tab[];
  links?: boolean;
}

export const Tabs = ({ tabs }: TabsProps) => {
  return (
    <Styled.Wrapper>
      {tabs.map((tab, i) => (
        <Tab key={i} {...tab} />
      ))}
    </Styled.Wrapper>
  );
};

export default Tabs;

function fromStringWithBreakOrCommaToArray(value: string) {
  if (value) {
    if (value.includes('\n')) {
      return value
        .split(' ')
        .join('')
        .split('\n')
        .filter((x) => x);
    } else if (value.includes(',')) {
      return value
        .split(' ')
        .join('')
        .split(',')
        .filter((x) => x);
    } else {
      const valueWithoutEmptySpaces = value.split(' ').join('');
      if (valueWithoutEmptySpaces.length) return [valueWithoutEmptySpaces];
    }
  }
  return null;
}

export default fromStringWithBreakOrCommaToArray;
